export class CreateAdvance {
	baseAmount:any;
    categoryType: number;
	id:number
    expenseType:number;
	originalCurrencyId: number;
	originalAmount: number;
	originalCurrencyCode:string;
	userId: number;
	userGUID: string;
	legalEntityId: number;
	legalEntityGUID:string;
	action: number;	// 1 : save, 2 : submit
	recordType: number;	// 1 : Expense, 2 : Advance Expense
	reason: string;
	isOnTrip: boolean;
	tripId: number;
	tripName: string;
	tripFromDate: string
	tripToDate: string;
	description:string;
    expensedDate:string;
	content:string;
	fileName:string;
	expenseAttachments =[];
	tripTravellers =[];
	requestType:string;
	expenseTypeName:string;
	originalCurrencyIcon:string;
	claimTaggedTripList:any;
	tripTravellersList:any;
	claimIds: any;
	expenseId:any;
	claimName:any;
	editedExchangeRate:any;
	exchangeRate:any;
}