import { Component, NgZone, OnInit } from '@angular/core';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NativeGeocoder, NativeGeocoderResult, NativeGeocoderOptions } from '@ionic-native/native-geocoder/ngx';
import { BackgroundGeolocation, BackgroundGeolocationConfig, BackgroundGeolocationEvents, BackgroundGeolocationResponse } from '@ionic-native/background-geolocation/ngx';
import { ExpenseAndMileageComponent } from '../dashboard/expenseandmileage/expenseandmileage.component';
import { BackgroundMode } from "@ionic-native/background-mode/ngx";
import { TranslateService } from '@ngx-translate/core';
import { Observable, timer } from 'rxjs';
import haversine from 'haversine'
import { Platform } from '@ionic/angular';
import { ExpensesService } from 'src/app/services/expenses.service';
import * as moment from 'moment';

@Component({
  selector: 'app-gps-tracking',
  templateUrl: './gps-tracking.component.html',
  styleUrls: ['./gps-tracking.component.scss'],
})
export class GpsTrackingComponent implements OnInit {

  config: BackgroundGeolocationConfig = {

    startOnBoot: true,
    desiredAccuracy: 5,
    stationaryRadius: 10,
    distanceFilter: 10,
    debug: false, //  enable this hear sounds for background-geolocation life-cycle.
    stopOnTerminate: false,
    interval: 20000
    // enable this to clear background location settings when the app terminates
  };

  userInfo: any;
  getLatLong: any;
  totalDistanceVal: any = 0;
  totalDistanceValStored: any = 0;

  currLat: any = 0;
  currLong: any = 0;
  prevLat: any = 0;
  prevLong: any = 0;
  stopStart: boolean = true;

  startingLat: any = 0;
  startingLong: any = 0;
  address = [];
  backGroundTrack: boolean = false;
  foreGroundTrack: boolean = true;
  locationsVal: any;

  fromLat: any = 0;
  fromLong: any = 0;
  //totalRideTime: any = 0;
  speedOfRide: any = 0;

  startTime: any = 0;
  currentTime: any = 0;
  displayTime: any = 0;
  totalRideTm: any = 0;
  counter: any;
  discardType: any;
  fromTimeStr = new Date();
  currentTimeStr = new Date();
  claimStartDate: any;

  currentLocations: any = [];
  nativeGeocoderOptions: NativeGeocoderOptions = {
    useLocale: true,
    maxResults: 5
  };

  constructor(private router: NavController,
    public modalController: ModalController,
    private geolocation: Geolocation,
    private nativeGeocoder: NativeGeocoder,
    private backgroundGeolocation: BackgroundGeolocation,
    private backgroundMode: BackgroundMode,
    private translate: TranslateService,
    public alertController: AlertController,
    private zone: NgZone,
    public platform: Platform,
    private expenseService: ExpensesService) {
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

    let dist: number = 0;
    dist = Number(localStorage.getItem("gpsDistance"))
    //console.log('local storage values is', dist);
    if (dist && dist > 0) {
      let dist: number = 0;
      dist = Number(localStorage.getItem("gpsDistance"))
      this.totalDistanceVal = dist;
    } else {
      this.totalDistanceVal = 0.00;
      localStorage.setItem('gpsDistance', this.totalDistanceVal);
    }

    this.startTime = this.fromTimeStr.toLocaleTimeString('en-GB');

    this.getCurrentVals();
    this.foreGroundCall();

    this.platform.ready().then((e) => {
      this.backgroundGeolocation.start();
      this.backgroundGeolocation.configure(this.config).then(() => {
        this.backgroundGeolocation.on(BackgroundGeolocationEvents.location).subscribe((location: BackgroundGeolocationResponse) => {
         // this.getCurrentCoordinates();
        });
      });
    });
  }

  ngOnInit() {
  }

  foreGroundCall() {
    //  if (this.foreGroundTrack && !this.backGroundTrack) {
    this.getLatLong = setInterval(() => {
      this.getCurrentCoordinates();
    }, 20000);
    //  }
  }

  ionViewDidEnter() {
    this.claimStartDate = moment().format('DD-MM-YYYY');
    this.foreGroundTrack = true;
    this.backGroundTrack = false;
    this.totalRideTm = 0;
    // if (this.foreGroundTrack && !this.backGroundTrack) {
      this.startTimer();
    //}
  }

  startTimer() {
    this.counter = setTimeout(() => {
      this.displayTime = this.getTimerClock(this.totalRideTm);
      this.totalRideTm++;
      this.startTimer();
    }, 1000);
  }

  async getCurrentCoordinates() {

    let options = { timeout: 20000, maximumAge: 0, enableHighAccuracy: true };
    await this.geolocation.getCurrentPosition(options).then((resp) => {

      this.zone.run(() => {
        let lat1 = this.currLat;
        let long1 = this.currLong;
        this.currLat = resp.coords.latitude;
        this.currLong = resp.coords.longitude;
        this.prevLat = lat1 ? lat1 : resp.coords.latitude;
        this.prevLong = long1 ? long1 : resp.coords.longitude;
        const start = {
          latitude: this.currLat,
          longitude: this.currLong
        }
        const end = {
          latitude: this.prevLat,
          longitude: this.prevLong
        }
        let eachTerminalDist: any = 0;
        eachTerminalDist = haversine(start, end); //For KMs
        //eachTerminalDist = 0.25; // For Test 
        // let sec = 0.00416 // 10Sec to hours  10/3600=0.00278  // 15Sec to hours--> 15/3600=0.00416667
        // let sec =  0.00833333 // 30Sec to hours
        //let sec = 0.00555556  // 20Sec to hours
        let sec = 0.00416667 // 15Sec to hours
        this.speedOfRide = eachTerminalDist / sec;
        if (eachTerminalDist > 0) {  //&& this.speedOfRide > 2
          this.totalDistanceVal += (eachTerminalDist);   //For Kms  
        }
        localStorage.setItem('gpsDistance', this.totalDistanceVal);
        this.fromTime();
        // console.log('myspeed of ride :', this.speedOfRide);
        // console.log("distance via each terminal is :", eachTerminalDist);
        // console.log("total distance is :", this.totalDistanceVal);

        let currObj = {
          curLat: this.currLat.toFixed(8),
          curLong: this.currLong.toFixed(8),
          speed: this.speedOfRide.toFixed(4),
          currentTime: this.currentTime,
          termDist: eachTerminalDist.toFixed(6)
        }

        this.currentLocations.splice(0, 0, currObj);
        this.currentLocations = this.currentLocations.slice(0, 10);

        let reqBody = {
          legalEntityId: this.userInfo.legalEntityId,
          legalEntityGUID: this.userInfo.legalEntityGuId,
          sessionId: localStorage.getItem("sessionId"),
          latitude: this.currLat,
          longitude: this.currLong,
          distance: eachTerminalDist,
          action: null,
          //mode : this.backGroundTrack ? 'background' : 'foreground'
        }
        this.currentTimeVal();
        this.timeDiff(this.fromTimeStr, this.currentTimeStr);
        if (this.platform.is('android')) { } else {
          this.backgroundGeolocation.finish();
        }
        this.getCreateMileageAudit(reqBody);
      });

    }).catch((error) => {
      if (this.platform.is('android')) { } else {
        this.backgroundGeolocation.finish();
      }
      console.log('Error getting location', error);
    });
  }
 
  fromTime() {
    const date = new Date();
    const time = date.toLocaleTimeString('en-GB');
    this.currentTime = time;
  }

  getCurrentVals() {
    this.geolocation.getCurrentPosition().then((resp) => {
      this.fromLat = resp.coords.latitude;
      this.fromLong = resp.coords.longitude;
      localStorage.setItem('fromLat', this.fromLat);
      localStorage.setItem('fromLong', this.fromLong);
    }).catch((error) => {
      // console.log('Error getting location', error);
    });
  }
  createMileageExp() {
    //this.stopStart = false;
    this.confirmMileageCreate();

  }
  cancelBtn() {
    clearInterval(this.counter);
    this.modalController.dismiss({
      dismissed: true,
    });
    if(localStorage.getItem('fromTM') == 'dashboard'){
      this.router.navigateRoot(["dashboard"]);
    }else{
      this.router.navigateRoot(["dashboard"]);
    }
  }
  async addNewMileage(type) {
    const toPathObj = {
      url: "newreport",
      from: "gpsTracking",
      report: {
        isLinkedToAdvancePayment: false,
        isLinkedToPettyCashPayment: false,
        claimName: 'Mileage created on ' + moment(new Date()).format("DD MMM HH:mm:ss"),
        claimStartDate: this.claimStartDate,
        claimEndDate: moment().format('DD-MM-YYYY')
      },
      expenseList: [],
      appliedAmount: 0,
      editAppliedAmt: 0,
      isAdvLinkedBefore: false,
      claimTaggedTripList: [],
      walletAdvance: 0,
    };
    sessionStorage.setItem('toPathInfo', JSON.stringify(toPathObj)) 
    sessionStorage.setItem('fab', JSON.stringify(false));
    localStorage.setItem('toLat', this.currLat);
    localStorage.setItem('toLong', this.currLong);
    const modal = await this.modalController.create({
      component: ExpenseAndMileageComponent,
      cssClass: "my-custom-class",
      componentProps: {
        isEdit: false,
        type,
        isGPSShow: false,
        GPSData: {
          distance: this.totalDistanceValStored,
          fromLat: this.fromLat,
          fromLong: this.fromLong,
          toLat: this.currLat,
          toLong: this.currLong,
          expensedDate: moment().format('DD-MM-YYYY')
        }
      },
    });
    return await modal.present();
  }
  // address
  pretifyAddress(address) {
    let obj = [];
    let data = "";
    for (let key in address) {
      obj.push(address[key]);
    }
    obj.reverse();
    for (let val in obj) {
      if (obj[val].length)
        data += obj[val] + ', ';
    }
    return address.slice(0, -2);
  }
  getTimerClock(inputSeconds) {
    // console.log('passing time is', this.totalRideTm);
    var sec_num = parseInt(inputSeconds.toString(), 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);
    var hoursString = '';
    var minutesString = '';
    var secondsString = '';
    hoursString = (hours < 10) ? "0" + hours : hours.toString();
    minutesString = (minutes < 10) ? "0" + minutes : minutes.toString();
    secondsString = (seconds < 10) ? "0" + seconds : seconds.toString();
    return hoursString + ':' + minutesString + ':' + secondsString;
  }
  clearLocalStorage() {
    localStorage.setItem('fromGPS', '1');
    this.totalDistanceValStored = this.totalDistanceVal;
    this.totalDistanceVal = 0.00;
    clearInterval(this.getLatLong);
    if (this.platform.is('android')) { } else {
      this.backgroundGeolocation.finish();
    }
    this.backgroundGeolocation.stop();
    //this.backgroundMode.disable();
    this.totalDistanceVal = 0.00;
    localStorage.setItem('gpsDistance', this.totalDistanceVal);
    localStorage.removeItem("gpsDistance");
    localStorage.removeItem("startTime");
    localStorage.removeItem("sessionId");
    // console.log('timer is cleared******');
  }

  async confirmMileageCreate() {
    const alert = await this.alertController.create({
      mode: "md",
      message: "Would you like to stop tracking and proceed to creating the mileage expense?",
      backdropDismiss: false,
      buttons: [
        {
          text: 'No',
          role: "cancel",
        },
        {
          text: 'Yes',
          handler: () => {
            this.zone.run(() => {
              this.discardType = 'CREATE';
              this.getDiscardTrip();
              this.clearLocalStorage();
              this.addNewMileage(2);
              clearInterval(this.counter);
              this.currentLocations = [];
            });
          },
        },
      ],
    });
    await alert.present();
  }
  async cancelCall() {
    const alert = await this.alertController.create({
      mode: "md",
      message: "This action will discard the mileage readings taken so far. Would you like to proceed?",
      backdropDismiss: false,
      buttons: [
        {
          text: 'No',
          role: "cancel",
        },
        {
          text: 'Yes',
          handler: () => {
            this.zone.run(() => {
              this.discardType = 'DISCARD';
              this.getDiscardTrip();
              this.currentLocations = [];
              this.clearLocalStorage();
              this.cancelBtn();
            });
          },
        },
      ],
    });
    await alert.present();
  }

  async getCreateMileageAudit(reqBody?) {
    await this.expenseService.getMileageAuditList(reqBody).subscribe((data) => {
      if (data) {
      }
    });
  }

  getDiscardTrip() {
    let reqBody = {
      legalEntityId: this.userInfo.legalEntityId,
      legalEntityGUID: this.userInfo.legalEntityGuId,
      sessionId: localStorage.getItem("sessionId"),
      latitude: null,
      longitude: null,
      distance: null,
      action: this.discardType,
    }
    this.expenseService.getMileageAuditDiscard(reqBody).subscribe((data) => {
      if (data) {
        // console.log('mileage audit call is discarded successfully*******');
      }
    });
  }
  currentTimeVal() {
    this.currentTimeStr = new Date();
  }
  timeDiff(fromTime, toTime) {
    var sec = 0;
    this.totalRideTm = 0;
    var date1 = fromTime;
    var date2 = toTime;
    var diff = date2.getTime() - date1.getTime();
    sec = diff / 1000;
    this.totalRideTm += sec;
    //this.displayTime = this.getTimerClock(this.totalRideTm);
  }
}


// backGroundCall() {
//   this.backgroundMode.enable();
//   this.backgroundMode.wakeUp();
//   this.backgroundMode.unlock();
//   // this.backgroundMode.overrideBackButton();
//   this.backgroundMode.on("activate").subscribe(() => {
//     if (this.backGroundTrack && !this.foreGroundTrack) {
//     }
//   });
// }