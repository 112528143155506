import { Component, Input, NgZone, OnInit } from '@angular/core';
import { AlertController, ModalController, ToastController } from "@ionic/angular";
import { fadeInOut } from "../../animations";
import * as _ from 'lodash';
import { ToastrService } from "ngx-toastr";
import { TranslateService } from '@ngx-translate/core';
import {SettingsService} from "src/app/services/settings-services";
@Component({
    selector: 'app-custom-select-dropdown',
    templateUrl: './custom-select-dropdown.component.html',
    styleUrls: ['./custom-select-dropdown.component.scss'],
    animations: fadeInOut
})
export class CustomSelectDropdownComponent implements OnInit {
    @Input() mainList: any[];
    @Input() title: string;
    @Input() isMultiSelect: boolean;
    @Input() itemValueField: string;
    @Input() itemTextField: string;
    @Input() itemStateField:string;
    @Input() value: any;
    @Input() add: any;
    @Input() buttonName: string;
    items: any[];
    tempList: any[];
    searching = false;
    searchText: string;
    errorFlag = false;
    isUpdate = false;
    buttonDisabled: true;

    constructor(
        private modalCtrl: ModalController,
        private alertController: AlertController,
        private toastr: ToastrService,
        private zone: NgZone,
        private translate: TranslateService,
        private settingService: SettingsService
    ) {

    }

    ngOnInit() {
      if(!this.mainList && this.itemValueField == 'userId') {
        this.loadApiData('');
      }else {
          this.prepareUI();
      }
    }

    prepareUI() {
        if (this.value && !this.isMultiSelect ) {
              const obj = _.find(this.mainList, (listItem) => {
                  return listItem[this.itemTextField] === this.value;
              }); 
              if (obj) {
                  obj.isSelected = true;
              } else {
  
                  this.mainList.forEach(listItem => {
                      listItem.isSelected = false;
                  });
              }
        } else {
              if (!this.value) {
                  if (this.mainList && this.mainList.length) {
                      this.mainList.forEach(listItem => {
                          listItem.isSelected = false;
                      });
                  }
              }
              if (this.value && this.isMultiSelect) {
                  const arr = [];
                  const obj = _.filter(this.mainList, (listItem) => {
                      for (const arrVal of this.value) {
                          return listItem[this.itemValueField] === arrVal;
                      }
                  });
                  if (obj && obj.length) {
                      obj.forEach(selectItem => {
                          selectItem.isSelected = true;
                      });
                  } else {
                      this.mainList.forEach(listItem => {
                          listItem.isSelected = false;
                      });
                  }
              }
        }
        this.items = _.cloneDeep(this.mainList);
        this.tempList = _.cloneDeep(this.items);
    }

    selectItem(item: any) {
        this.tempList.forEach((obj) => {
            if (obj[this.itemValueField] === item[this.itemValueField]) {
                obj.isSelected = item.isSelected;
            }
        });
        if (!this.isMultiSelect) {
            const isSelectedItemObj = this.checkIsItemSelected(item);
            if (isSelectedItemObj) {
                this.tempList.forEach((obj) => {
                    if (obj[this.itemValueField] === isSelectedItemObj[this.itemValueField]) {
                        obj.isSelected = !isSelectedItemObj.isSelected;
                    }
                });
                this.items = _.cloneDeep(this.tempList);
            }
        }
        if(!this.isMultiSelect){
            this.modalCtrl.dismiss(true ? this.tempList : false);
        }
    }

    checkIsItemSelected(listItem: any): any {
        let item: any;
        this.tempList.forEach((obj: any) => {
            // TODO check isSelected is available for all types
            if (!item && listItem[this.itemValueField] !== obj[this.itemValueField] && obj.isSelected) {
                item = obj;
            }
        });
        return item;

    }

    onSearch() {
        this.searching = true;
        this.search(this.searchText);
        this.searching = false;
    }

    search(searchText: string) {
        if (this.tempList != null) {
            if (searchText !== "") {
                this.items = this.tempList.filter((data) => {
                    if (
                        data[this.itemTextField].toLowerCase().indexOf(searchText.toLowerCase()) >=
                        0
                    ) {
                        return data;
                    }
                });
            } else {
                this.items = this.tempList;
            }
        }
    }

    loadApiData(str: string) {
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        const departmentHeadRequestPayload = {
            "legalEntityId": userInfo.legalEntityId,
            "legalEntityGUID": userInfo.legalEntityGuId,
            "searchText": str

        };
       // if (str && str.length >= 3) {
            this.settingService.getUsersList(JSON.stringify(departmentHeadRequestPayload)).subscribe(res => {
                if (res.payload.users.length > 0) {
                    this.mainList = res.payload.users;
                    this.prepareUI();
                }
            });
       // }

    }

    get canDisabled(): boolean {
        let isSelected = true;
        if(this.tempList){
            this.tempList.forEach((obj) => {
                if (obj.isSelected) {
                    isSelected = false;
                }
            });
        }

        return isSelected;
    }

    clearSelected() {
        this.searchText = '';
        if (this.tempList) {
            this.tempList.forEach((obj) => obj.isSelected = false);
            this.items = _.cloneDeep(this.tempList);
        }
    }

    closeModal(data) {
        this.modalCtrl.dismiss(data ? this.tempList : false);
    }

    itemSelected(item: any) {
        this.closeModal(item);
    }

    showPrompt() {
        this.alertController.create({
            mode: "md",
            header: this.add.buttonName,
            cssClass: "style: border: 1px solid grey; border-radius: 5px; margin-top: 10px",
            inputs: [
                {
                    name: this.add.textFieldName,
                    placeholder: this.add.placeholder,

                },
            ],
            buttons: [
                {
                    text: this.translate.instant('common.cancel'),
                    handler: (data: any) => {
                    }
                },
                {
                    text: this.translate.instant('alerts.add'),
                    handler: (data: any) => {
                        if (data[this.add.textFieldName] !== null && data[this.add.textFieldName] !== '') {
                            this.modalCtrl.dismiss(data[this.add.textFieldName]);
                            return true;
                        } else {
                         //   this.presentToast(`please enter ${this.add.textFieldName} Name`);
                         this.zone.run(() => {
                             this.toastr.error(`please enter ${this.add.textFieldName} Name`, "Error");
                         });
                            return false;
                        }

                }
                }
            ]
        },
        ).then(res => {
            res.present();
        });
    }



}
