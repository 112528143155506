import { Component, OnInit, TemplateRef, ViewChild, ElementRef, EventEmitter, Output, OnDestroy, NgZone, ChangeDetectorRef } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { PlatformService } from "src/app/services/platformbase.service";
import { IonInfiniteScroll, IonInput, IonRouterOutlet, ModalController, NavController } from "@ionic/angular";
import { ActivatedRoute, Router } from "@angular/router";


import {
    FormControl,
    FormGroup,
    FormBuilder,
    Validators,
} from "@angular/forms";
import { TranslateconfigService } from "src/app/services/translateconfig.service";
import { ExpensesService } from "src/app/services/expenses.service";
import {
    ExpenseModel,
    ExpenseListModel,
    GetExpenseModels, DeleteExpenses, AddReportExpense, TripsListModel, NewTripModel, MyCardModel
} from "src/app/models/Expenses/expenses.model";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import {
    CategoryAndMerchantModel,
    CategoryList,
    DownloadModel,
    ExpenseAndReportModel,
    filterListModel,
    FilterModal,
    StatusListModel,
    TripFilterModal
} from "src/app/models/Common/common.model";
import { AlertController } from '@ionic/angular';
import { ReportListResModel, Report, FetchTripInfo, GetTripInfo, RemoveReportModal } from 'src/app/models/Reports/reports.model';
import { ReportsService } from 'src/app/services/reports.service';
import { ToastrService } from 'ngx-toastr';
// import { IonicSelectableComponent } from 'ionic-selectable';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { CommonService } from 'src/app/services/common-services/common.service';
import { DatechangeService } from 'src/app/services/common-services/datechange.service';
import { ChangeContext, LabelType, Options } from 'ng5-slider';
import { Subject } from 'rxjs';
import { Constants } from 'src/app/Constants';
import { AppConfig } from 'src/app/config/app.config';
import * as moment from 'moment';
import * as _ from "lodash";
import { SharedService } from 'src/app/services/common-services/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, first } from "rxjs/operators";
import { AnalyticsService } from "src/app/services/analytics.service";
import { TravelService } from "src/app/services/travel.service";
import { MatDatepicker } from "@angular/material/datepicker";
import { ExpenseAndMileageComponent } from "../../dashboard/expenseandmileage/expenseandmileage.component";
import { CustomDatePickerComponent } from "../../custom-date-picker/custom-date-picker.component";
import { CustomSelectDropdownComponent } from "../../custom-select-dropdown/custom-select-dropdown.component";
import { DateRangeComponent } from "../../date-range/date-range.component";

@Component({
  selector: 'app-mycards-list',
  templateUrl: './mycards-list.component.html',
  styleUrls: ['./mycards-list.component.scss'],
})
export class MycardsListComponent implements OnInit, OnDestroy {
    @ViewChild("downloadLoader", { static: false }) private downloadLoader;
    @ViewChild("reportfocusOff", { static: false }) reportfocusOff: ElementRef;
    // @ViewChild("reportModal") reportModal: IonicSelectableComponent;
    @Output() dateChange: EventEmitter<MatDatepickerInputEvent<any>>;
    @ViewChild("datefocusOff", { static: false }) datefocusOff: ElementRef;
    // @ViewChild("selectCatComponent") selectCatComponent: IonicSelectableComponent;
    @ViewChild('fromdate') fromdate: MatDatepicker<Date>;
    @ViewChild('todate') todate: MatDatepicker<Date>;
    @ViewChild("oneCard", { static: false }) private oneCard;
    // @ViewChild("selectstatusComponent") selectstatusComponent: IonicSelectableComponent;
    showExpFooter = false;
    segmentModel = "draft";
    modalRef: BsModalRef;
    dateRangeModalRef: BsModalRef;
    addReport = false;
    _addReport = false;
    addreportForm: FormGroup;
    platformType: number;
    dummyList = [1, 2, 3, 4, 5];
    expenseList: any = [];
    tripsList: any = [];
    expensetempList: any = [];
    isPageLoad = true;
    totalRecords = 0;
    showaddNewMileage = false;
    addExpenseListHide = false;
    showExpList = true;
    userInfo: any;
    showExpFilter = false;
    showExpSearch = false;
    showExpDetail = false;
    addExpenseHide = true;
    showFilter = false;
    langName: any;
    public selectControl = new FormControl();
    expDetailzs: any;
    expense: ExpenseListModel;
    summaryData: any;
    claimedIdAvailable: boolean = false;
    selectedExpense = [];
    selectedTrips = [];
    unReportExpenses = [];
    reportList: any;
    dropdownFlag = false;
    reportName: any;
    modelcloseFlag = false;
    hideColumns = ['noofexp', 'datesubmit'];
    claimsHideColumns = ['noofexp', 'datesubmit'];
    expensesHideColumns = ['reportname', 'username', 'dateadded', 'datesubmit'];
    tripsHideColumns = ['dateAdded', 'payment'];
    mycardsHideColumns = [];
    indeterminateState: boolean;
    checkAll: boolean;
    showDelete: boolean;
    addReportBtn: boolean;
    expenseId: number;
    expenseGUID: string;
    categoryList: any = [];
    travelIdsList: any;
    travelFormsData: any;
    categorytempList: any;
    checkAllCat: any;
    checkAllprod: boolean = false;
    checkAllIds: any;
    selectedcategory = [];
    selectedTripCategory = [];
    filterModal = new FilterModal();
    tripfilterModal = new TripFilterModal();
    checkAllStatus: any;
    checkAllTripStatus: any;
    statusList = [];
    filetrList: any;
    selectedStatus = [];
    statusState: boolean;
    categoryState: boolean;
    tripCategoryState: boolean;
    tripStatusState: boolean;
    idsState: boolean;
    value = 100;
    options: any;
    draft = true;
    pageNumber = 1;
    pageSize = 50;
    minPrice = 0;
    maxPrice = 0;
    statusArray = [];
    showLoading = true;
    showSummaryLoading = true;
    maxDate: any;
    minDate: any;
    public manualRefresh = new Subject<void>();
    frmDate: any;
    tDate: any;
    amount: any;
    mobMaxDate: any;
    selectedstatusmob = [];
    selectcatarraymob = [];
    showDetail = false;
    minAndMax: any;
    ExpenseorReport: string;
    status: string;
    categoryName: string;
    isFirstOpen = false;
    isFirstOpenIcon = false;
    isSecondOpen = false;
    isSecondOpenIcon = false;
    tableHide = true;
    totalAmt: any;
    isExpenseCreated: boolean;
    expBtns = false;
    mainBtn = true;
    nextvalue: any;
    buttonDisabled = false;
    validationMsg: any;
    addReportDisabled = false;
    isApicallinProgress = false;
    proofList = [];
    searchBar = false;
    searchExpense: string;
    showSearch = false;
    currentday: any;
    attachmentCount: any;
    expenseAndReportModel = new ExpenseAndReportModel();
    getExpenseModels = new GetExpenseModels();
    deleteExpenses = new DeleteExpenses();
    reportModel = new Report();
    addReportExpense = new AddReportExpense();
    CategoryMerchantModel = new CategoryAndMerchantModel();
    expenseModel = new ExpenseModel();
    downloadTime: any;
    downloadActionPoolCount = 0;
    downloadModel = new DownloadModel();
    downloadBtn = false;
    imgitem: any;
    fromcomp: string;
    showMobileDetails: boolean = true;
    downloadProof = [];
    proofid: any;
    private SearchListText: Subject<string> = new Subject();
    imgNum = 1;
    fromAnlyticsPage: number;
    // isEditExpense: any;
    hideNewExpBtn = true;
    showMoreViolations = false;
    displayViolations = [];
    fromPettyCash = false;
    fromAnalytics = false;
    maxDateStr: any = moment(new Date()).format('YYYY-MM-DD');
    minDateStr: any = moment(new Date().getTime()).subtract(2000, 'years').format('YYYY-MM-DD');
    isUserToggle: any;
    sharedSubsc: any;
    showTripDetails: boolean = false;
    isTripOpen = true;

    singleSelectedTripDetails: any;
    singleSelection: boolean = false;
    multiSelection: boolean = false;
    seletedTrip: any;
    associated: boolean = false;
    notassociated: boolean = false;
    selectedTripDetails: any;
    tripsTotalAmount = 0;

    pageNumberTrips = 1;
    pageSizeTrips = 50;
    isPageLoadTrips = true;
    isPageLoadMycards = true;
    showLoadingTrips = true;
    isApicallinProgressTrips = false;
    pdfData: any;
    defaultIndex = 0;
    invoiceDetails: any;
    isPdf: any;
    isZip: any;
    expenseCode: any;
    expeseID: any;
    selectObjExp: any;
    selectedExpenseids = [];
    invoiceDetailsForDetails: any;
    selectExpense: any;
    tripsRecoeds = 0;
    _selectedExpense: any;
    isShowpopup: boolean = false;
    showPop: any;
    showDownloadOptions: boolean = false;
    dropdownValue: any;
    downloadOptionSelection = 0;
    isFourthOpen = false;
    isFourthOpenIcon = false;
    selectedTripInvoiceArray: any[];
    pdfloader: boolean = false;
    filterCategoryList = [];
    filterListStatus = [];
    filterstatus: any;
    filterService: any;
    checkAllfilterStatus: any;
    isSelected: boolean = false;
    checkAllTripCategory: boolean = false;
    expcurrencyIcon: any;

    selectedReport = [];
    selectedList = [];
    draftCount = 1;
    submittedCount = 1;
    detailReport: any;
    showDetailreport: boolean = false;
    hideReporttable: boolean = true;
    isReportCreated: boolean;
    hideNewRepBtn = true;
    reportSearch: any;
    tripSearch: any;
    showRepList = true;
    showRepFilter = false;
    reportDraftArray = [];
    submitBtn = false;
    deltbuttonDisabled = false;
    fromPage: string;
    dropdownflag: boolean = false;
    indeterminateStatus: boolean;
    statusAll: any;
    indeterminateStates: boolean;
    checkedAll: boolean;
    claimId: any;
    claimGUID: any;
    claimName: any;
    AcknologmentBooleanCheck: boolean = false;
    showloader: boolean = false;
    selecteClaimId: any;
    AcknologmentViolationBooleanCheck: boolean = false;
    button_disable: boolean = false;
    downloadProofs = [];
    showViolationCheckBox: boolean = false;
    selected_filters: any = [];
    val: any
    approvesearch: string;
    openfromdate: boolean = false;
    opentodate: boolean = false;
    currentClaimIndex: number;
    reportListdata = [];
    claimFilterApplied = false;
    @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
    routerState: any;
    previewAttachmentsArray: any[];
    serviceList = [];
    selectedTripIndex: number;
    selecteTripIndex: any;

    isErrorcode10 = false;
    budgetCategoryItems = [];
    anomaliesCategoryItems = [];
    repushloading:boolean = true;    
    integrationHistory:Array<any> = [];
    mycardslist:any;
    mycardloader:boolean;
    selectedMycards = [];
    searchCard: string;

    showCardsScrollLoader:boolean = false
    ascending = 1;
    sortColumn = '';
    showExpenseList:boolean = false;
    showChildTab:boolean = false;
    showCardsChildTab:boolean = true;
    selectedTab: string = 'Claims'; // Default selected tab
    selReport: any;
    filterClaimTypeId: any;
    filterClaimTypeName: any;
    downloadForm: FormGroup;
    tripsTabLabel = 'My Trips';

    constructor(
        private title: Title,
        private platformService: PlatformService,
        private expenseService: ExpensesService,
        public navCtrl: NavController,
        public formBuilder: FormBuilder,
        private modalService: BsModalService,
        public modalController: ModalController,
        private translateconfigService: TranslateconfigService,
        public alertController: AlertController,
        private reportsService: ReportsService,
        private toaster: ToastrService,
        private router: Router,
        public toastr: ToastrService,
        private commonService: CommonService,
        private datechangeService: DatechangeService,
        public sharedService: SharedService,
        private zone: NgZone,
        private cdr: ChangeDetectorRef,
        private travelRequestService: TravelService,
        private route: ActivatedRoute,
        private routerOutlet: IonRouterOutlet,
        private translate: TranslateService, private analyticsService: AnalyticsService) {
        if (this.router.url.includes('petty-cash/expense')) {
            this.fromPettyCash = true;
        }
        this.fromPage = this.fromPettyCash ? 'petty-cash' : 'expense';
        // this.myCaractsArray = [{ ATWRT: "E"}, { ATWRT: "12"}]
        this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        this.tripsTabLabel = (this.userInfo?.roleId == 3 || this.userInfo.roleId == 4) && JSON.parse(sessionStorage.getItem('isadmin')) ? 'All Trips' : 'My Trips';
        this.val = JSON.parse(sessionStorage.getItem("toPathInfo"));
        this.expcurrencyIcon = this.userInfo.baseCurrency.currencyIcon;
        if ((this.userInfo.roleId == 2 || this.userInfo.roleId == 3) && JSON.parse(sessionStorage.getItem('isadmin'))) {
            this.hideNewExpBtn = false;
        }
        this.sharedService.getRefreshData().subscribe((value) => {
            if (value) {
                // this.draft = true;
                // this.segmentModel = 'draft';
                // this.pageNumber = 1;
                // this.getExpenseList(true);
                if (this.segmentModel === 'draft') {
                    this.draft = true;
                    this.segmentModel = 'draft';
                    this.pageNumber = 1;
                    this.getExpenseList(true);
                }
                else if (this.segmentModel === 'submitted') {
                    this.draft = false;
                    this.segmentModel = 'submitted';
                    this.pageNumber = 1;
                    this.getExpenseList(true);
                }
            }
        });
        this.routerState = this.router.getCurrentNavigation().extras.state;
        if (this.routerState && this.routerState.data) {
            if (this.routerState.data.from == 'dashboardFilter') {
                this.filterModal.statusIds = this.routerState.data.ids;
                this.claimFilterApplied = true;
                this.showclearAll = true;
            } else {
                this.filterModal.statusIds = [];
            }
        } else {
            this.getMycardlist(true);
        }
        this.getClaimTypes();
        this.downloadForm = this.formBuilder.group({
            fromDate: ['', [Validators.required]],
            toDate: ['', [Validators.required]]
        });
    }

    ngOnInit() {
        sessionStorage.removeItem('tripData');
        this.analyticsService.getExpStatus().pipe(first()).subscribe(res => {
            if (res) {
                this.fromAnlyticsPage = res;
                this.filterModal.statusIds = res.statuslist;
                this.filterModal.searchText = res.searchText;
                this.filterModal.forUserIds = res.forUserIds;
                this.filterModal.departmentIds = res.departmentIds;
                this.filterModal.fromDate = res.fromDate;
                this.filterModal.recordTypes = [1];
                this.filterModal.toDate = res.toDate
            }
        });
        this.SearchListText.pipe(
            debounceTime(500),
            distinctUntilChanged()).subscribe((term: string) => {
                this.ExpenseorReport = term;
                this.showLoading = true;
                // this.getExpenseList(false);
                if (this.matTabIndex == 0) {
                    this.getreportList(false);
                } else if (this.matTabIndex == 3) {
                    this.getExpenseList(false);
                } else if (this.matTabIndex == 1) {
                    this.getTripsList(false);
                }      else  if(this.matTabIndex ==2){
                    this.getMycardlist(false);
        
                }
            });

        // if (this.matTabIndex == 0) {
        //     this.SearchListText.pipe(
        //         debounceTime(500),
        //         distinctUntilChanged()).subscribe((term: string) => {
        //             this.ExpenseorReport = term;
        //             this.showLoading = true;
        //             this.getreportList(false);
        //         });
        // }
        // console.log('sdsdsds', this.val);
        this.val = JSON.parse(sessionStorage.getItem("toPathInfo"));
        if (this.val) {
            sessionStorage.setItem('toPathInfo', null);
            if (this.val["url"] && (this.val["url"] == 'analytics')) {
                this.fromAnalytics = true;
            } else if (this.val["url"] && (this.val["url"] == 'dashboard')) {
                if (this.val["pointerValue"] && this.val["pointerValue"] == 1) {
                    this.filterModal.statusIds = [2, 3];
                } else if (this.val["pointerValue"] && this.val["pointerValue"] == 2) {
                    this.filterModal.statusIds = [7];
                } else if (this.val["pointerValue"] && this.val["pointerValue"] == 3) {
                    this.filterModal.statusIds = [2, 3];
                } else if (this.val["pointerValue"] && this.val["pointerValue"] == 4) {
                    this.filterModal.statusIds = [1];
                }
            }
        }

        this.sharedSubsc = this.sharedService.getUserToggle().subscribe((val) => {
            this.isUserToggle = val;
        });

        if ((this.userInfo.roleId == 2 || this.userInfo.roleId == 3 || this.userInfo.roleId == 4) && JSON.parse(sessionStorage.getItem('isadmin'))) {
            this.hideNewRepBtn = false;
        }
    }

    checkInfiniteScrollStatus() {
        if (this.infiniteScroll) {
            const isDisabled = this.infiniteScroll.disabled;
            return isDisabled;
        }
    }

    dummyListLength: number = 5;
    setDummyList() {
        const list = [];
        const dummy_length = this.dummyListLength < 1 ? 1 : (this.dummyListLength < 15 ? this.dummyListLength : 15);
        for (let i = 1; i <= dummy_length; i++) {
            list.push(i)
        }
        this.dummyList = list;
    }

    matTabIndex = 2;
    childSelectedIndex:number;
    matTabLabel = 'My Cards';

    selectTab(tabName: string) {
        this.selectedTab = tabName;
      }

      onTabChanged(e) {
        this.pageNumber = 1;
        this.pageNumberTrips = 1;
        this.showCardsChildTab = false;
        if(e.tab.textLabel == 'Claims'){
            this.showExpenseList = false;
            this.showChildTab = false;
            this.matTabIndex = 0;
            this.matTabLabel = 'Claims';
            this.selectedTab = 'Claims';
        }else if(e.tab.textLabel == 'My Trips' || e.tab.textLabel == 'All Trips'){
            this.showExpenseList = false;
            this.showChildTab = true;
            this.matTabIndex = 1;
            this.matTabLabel = e.tab.textLabel;
            this.selectedTab = e.tab.textLabel;
            this.childSelectedIndex = 0;
            this.getTripsList(false);
        }else if(e.tab.textLabel == 'My Cards'){
            this.showExpenseList = false;
            this.showChildTab = false;
            this.showCardsChildTab = true;
            this.matTabIndex = 2;
            this.matTabLabel = 'My Cards';
            this.selectedTab = 'My Cards';
        }else if(e.tab.textLabel == 'Expenses'){
            this.showChildTab = false;
            this.matTabIndex = 3;
            this.matTabLabel = 'Expenses';
            this.selectedTab = 'Expenses';
        }


        this.mycardsHideColumns = [];
        this.showLoading = true;
        this.showFilter = false;
        this.pageNumber = 1;
        this.reportList = [];
        this.expenseList = [];
        this.tripsList = [];
        this.showclearAll = false;
        this.searchExpense = '';
        this.clearAllClaimFilters(true);
        this.resetAlltripFilters(true);
        this.resetAll();
        if (this.matTabIndex == 0) {
            this.getreportList(false, e);
            this.showSearch = false;
        } else if (this.matTabIndex == 1){
            this.tripfilterModal = new TripFilterModal();
            this.showLoading = true;
            // this.getTripsList(false, e);
            this.selectedTripCategory = [];
            this.isSelected = false;
            this.searchBar = false;
            this.resetProductFilters(this.filterCategoryList);
            this.resetTripFilters(this.filterListStatus);
            this.tripCategoryState = false;
        } else if (this.matTabIndex == 2){
            this.showLoading = true;
            this.mycardloader = true;
            this.getMycardlist(false);
        } else if (this.matTabIndex == 3){
            this.filterModal = new FilterModal();
            this.selectedExpense = [];
            this.selectedcategory = [];
            this.getExpenseList(false);
            this.checkAllStatus = false;
            this.checkAllCat = false;
            this.showSearch = false;
            this.statusList.forEach((item) => {
                item.isSelected = false;
            });
            this.categoryList.forEach((item) => {
                item.isSelected = false;
            });
        }
        this.resetClaimType();
    }
    
      getSelectedTabIndex(): number {
        switch (this.selectedTab) {
          case 'My Trips':
            return 1;
          case 'All Trips':
            return 1;
          case 'My Cards':
            return 2;
          case 'Expenses':
            return 3;
          default:
            return 0; // Defaults to 'Claims'
        }
      }

      handleIconClick(event){
        this.showExpenseList = false;
        this.selectTab('Claims');
      }


    gotoExpenses(e?){
        this.showExpenseList = true;
        this.selectTab('Expenses');
    }

    childTabSelection(event){
        this.pageNumberTrips = 1;
        this.tripsList = [];
        this.childSelectedIndex = event.index;
        this.tripfilterModal = new TripFilterModal();
        this.showLoading = true;
        this.getTripsList(false);
        this.selectedTripCategory = [];
        this.isSelected = false;
        this.searchBar = false;
        this.resetProductFilters(this.filterCategoryList);
        this.resetTripFilters(this.filterListStatus);
        this.tripCategoryState = false;
    }

    childTabLabel: any = 'Pending';
    childCardsTabSelection(event) {
        this.showLoading = true;
        this.mycardloader = true;
        this.pageNumber = 1;
        this.childTabLabel = event?.tab?.textLabel;
        this.getMycardlist(false, null, event?.tab?.textLabel);
    }

    refresh() {
        if (this.matTabIndex == 0) {
            this.getreportList(false);
        } else if (this.matTabIndex == 1) {
            // this.getExpenseList(false);
            this.getTripsList(false)
        } else if (this.matTabIndex == 2) {
            this.getMycardlist(false, null, this.childTabLabel)
        }
    }
    fabClick() {
        if (this.fromPettyCash) {
            this.addNewMileage(1);
        }
    }

    ionViewWillEnter() {
        this.pageNumber = 1;
        this.platformType = this.platformService.getPlatformType();
        this.isMobileDevice = this.platformService.isMobileDevice();
        this.validationMsg = AppConfig;
        // this.tableHide = true;
        // const current_modalObj = {
        //     name: 'claim',
        //     data: {
        //         currentClaimIndex: this.currentClaimIndex,
        //         reportListdata: this.reportListdata,
        //         detailReport: this.detailReport,
        //         fromPage: this.fromPage,
        //     }
        // }
        // sessionStorage.setItem('current_modal', JSON.stringify(current_modalObj));
        const current_modal = JSON.parse(sessionStorage.getItem('current_modal'));

        if (current_modal?.name == 'list') {
            this.tableHide = true;
            this.showDetailreport = false;
            this.hideReporttable = true;
        } else if (current_modal?.name == 'claim_detailview') {
            this.tableHide = false;
            this.showDetailreport = true;
            this.hideReporttable = false;
            this.currentClaimIndex = current_modal.data.currentClaimIndex;
            this.reportListdata = current_modal.data.reportListdata;
            this.detailReport = current_modal.data.detailReport;
            this.fromPage = current_modal.data.fromPage;
        }
        // sessionStorage.setItem('current_modal', 'list');

        // alert(this.tableHide)
        let applicationTitle = this.userInfo.applicationTitle;
        if (applicationTitle) {
            this.title.setTitle('Cards ' + ' | ' + applicationTitle);
        } else {
            this.title.setTitle("Cards | NoExpenses");
        }
        const date = new Date();
        this.maxDate = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate()
        );
        this.mobMaxDate = moment(new Date()).format(Constants.mobileCalenderFormat);
        this.maxDate = moment(new Date()).format(Constants.mobileCalenderFormat);
        this.currentday = moment(new Date()).format(Constants.mobileCalenderFormat);

        // recall of recurrent master category api call
        if (!JSON.parse(localStorage.getItem('recurrent-master-category'))) {
            this.getCategorylist();
        } else {
            this.categoryList = JSON.parse(localStorage.getItem('recurrent-master-category'));
        }

        //status list recall functionality
        if (JSON.parse(localStorage.getItem('common-status'))) {
            this.statusList = JSON.parse(localStorage.getItem('common-status'));
            this.afterStatusApiCall();
        } else {
            this.getStatusList();
        }

        this.addreportForm = this.formBuilder.group({
            claimId: [""],
            claimName: ["", [Validators.required]]
        });
        if (this.platformType === 2) {
            this.showSearch = true;
        }

        let val = JSON.parse(sessionStorage.getItem("fromPathInfo"));
        sessionStorage.setItem("fromPathInfo", null);
        if (val) {
            if (val["expenseId"] && val["expenseGUID"] && val["status"]) {
                this.showExpenseDetail(val);
            }
        }
    }

    resetSubscriptions() {
        if (this.modelcloseFlag) {
            this.modalRef.hide();
        }
        this.sharedService.setRefreshData(false);
        this.sharedService.setExpenseData(null);

        if (this.SearchListText) {
            this.SearchListText.unsubscribe();
        }

        let val = JSON.parse(sessionStorage.getItem("toPathInfo"));
        if (val && val["url"] && (val["url"] == 'analytics')) {
            sessionStorage.setItem('toPathInfo', null);
        }

        this.sharedService.unsubscribeServic(this.sharedSubsc);
    }

    openNewexpweb(expenseSelection?: TemplateRef<any>) {
        this.sharedService.setMapData(null);
        if (this.matTabIndex == 1) {
            if (!this.tableHide) {//if it is details view only
                this.dataForNewExp(false, false, this.expense);
                return;
            }
            var url = "expenses";
            if (this.router.url == '/petty-cash/expense') {
                url = 'petty-cash/expense'
            }
            let navInfo = {
                url: url
            };
            sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
            sessionStorage.setItem('fromPettyCash', String(this.fromPettyCash));
            this.navCtrl.navigateRoot(['newExpenseMileageBulk'], { state: { fromPettyCash: this.fromPettyCash } });
        } else if (this.matTabIndex == 2) {
            if (this.selectedTrips.length != 0) {
                this.singleSelection = false;
                this.multiSelection = true;
                this.totalAmount();
                const config = {
                    ignoreBackdropClick: true,
                    class: "modal-dialog-centered modal-lg baa",
                    keyboard: false,
                };
                this.modalRef = this.modalService.show(expenseSelection, config);
            } else {
                this.toaster.error('select atleast one', 'Error');
            }
        } else {
            if (!this.tableHide) {//if it is details view only
                this.dataForNewExp(false, false, this.expense);
                return;
            }
            var url = "expenses";
            if (this.router.url == '/petty-cash/expense') {
                url = 'petty-cash/expense'
            }
            let navInfo = {
                url: url
            };
            sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
            sessionStorage.setItem('fromPettyCash', String(this.fromPettyCash));
            this.navCtrl.navigateRoot(['newExpenseMileageBulk'], { state: { fromPettyCash: this.fromPettyCash } });
        }
    }

    loadData(event) {
        if (!this.isApicallinProgress) {
            this.pageNumber++;
            this.selectAllexpenses(event);
            if (this.matTabIndex == 0) {
                this.getreportList(false, event);
            } else if (this.matTabIndex == 3) {
                this.getExpenseList(false, event);
            } else if (this.matTabIndex == 2) {
                this.getMycardlist(false,event);
            }
        }
    }

    loadMoreTripsData(event) {
        this.pageNumberTrips++;
        this.getTripsList(false, event);
    }

    loadMore(event) {
        if (!this.isApicallinProgress) {
            if (this.expenseList.length < this.pageSize && event) {
                event.target.disabled = true;
            } else {
                this.pageNumber++;
                this.selectAllexpenses(event);
                this.getExpenseList(false, event);
                this.getreportList(false, event);

            }
        }
    }

    loadMoreTrips(event) {
    
        if (this.tripsList.length < this.pageSizeTrips && event) {
            event.target.disabled = true;
        } else {
            this.pageNumberTrips++;
            this.getTripsList(false, event);
        }
    }

    getExpenseList(isPageLoad, event?) {
        this.isPageLoad = isPageLoad;
        if (this.pageNumber == 1) {
            this.showLoading = true;
            this.expenseList = [];
        }
        if (JSON.parse(sessionStorage.getItem('isadmin')) == true) {
            this.expenseAndReportModel.viewType = this.userInfo.roleId;
        } else {
            this.expenseAndReportModel.viewType = 1;
        }
        if (this.userInfo.roleId == 2 && this.expenseAndReportModel.viewType == 2) {
            this.expenseAndReportModel.isAdminView = JSON.parse(sessionStorage.getItem('isadmin'));
        } else {
            this.expenseAndReportModel.isAdminView = false;
        }

        this.expenseAndReportModel.legalEntityId = this.userInfo.legalEntityId;
        this.expenseAndReportModel.legalEntityGUID = this.userInfo.legalEntityGuId;
        this.expenseAndReportModel.userId = this.userInfo.userId;
        this.expenseAndReportModel.userGUID = this.userInfo.userGuId;
        this.expenseAndReportModel.pageNumber = this.pageNumber;
        this.expenseAndReportModel.filterBy = this.filterModal;

        this.expenseAndReportModel.filterBy.isDraft = this.platformType == 2 ? this.draft : (this.fromAnlyticsPage ? false : null);
        this.expenseAndReportModel.pageSize = this.pageSize;
        this.expenseAndReportModel.recordType = this.fromPettyCash ? 4 : 1;
        this.isApicallinProgress = true;
        this.expenseAndReportModel.filterBy.statusIds = this.expenseAndReportModel.filterBy.statusIds[0] ? this.expenseAndReportModel.filterBy.statusIds : [];
        this.expenseService.getExpenseList(this.expenseAndReportModel)
            .subscribe((res) => {
                if (!res.isError && res.errorCode === 0) {
                    if (this.pageNumber == 1) {
                        this.expenseList = [];
                    }
                    this.showLoading = false;
                    this.totalRecords = res.payload.expensesCount;
                    this.isExpenseCreated = res.payload.isExpenseCreated;
                    this.minAndMax = res.payload;
                    this.selectedExpense = [];
                    this.selectedStatus = [];
                    this.selectedcategory = [];
                    this.indeterminateState = false;
                    this.changeOptions(
                        res.payload.expenseMinAmount,
                        res.payload.expenseMaxAmount
                    );
                    const listarray = res.payload.expenseResponseList;
                    this.expenseList = this.expenseList.concat((listarray.map((list) => new ExpenseListModel(list))));
                    this.dummyListLength = this.expenseList?.length;
                    this.setDummyList();
                    if (
                        this.pageNumber === 1 &&
                        this.isPageLoad === true &&
                        this.isExpenseCreated === true
                    ) {
                        this.isPageLoad = false;
                    }
                    this.returnExpSegment();

                    this.expenseList.forEach(elm => {
                        elm.expenseAttachments.forEach(obj => {
                            if (obj.attachmentType == null || obj.attachmentType == 1) {
                                elm.hasAttach = true;
                            }
                        });
                    });

                    if (event) {
                        event.target?.complete();
                    }
                    if (res.payload.expenseResponseList.length == 0 && event.target) {
                        event.target.disabled = true;
                    }
                    this.isApicallinProgress = false;
                } else {
                    this.showLoading = false;
                    this.isApicallinProgress = false;
                    this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
                    if (event && event.target) {
                        event.target.disabled = true;
                    }
                }
            });

    }

    getTripsList(isPageLoad, event?) {
        this.isPageLoadTrips = isPageLoad;
        if (this.pageNumberTrips == 1) {
            this.showLoadingTrips = true;
            this.tripsList = [];
        }

        this.expenseAndReportModel.isAdminView = JSON.parse(sessionStorage.getItem('isadmin'));
        if (JSON.parse(sessionStorage.getItem('isadmin')) == true) {
            this.expenseAndReportModel.viewType = this.userInfo.roleId;
        } else {
            this.expenseAndReportModel.viewType = 1;
        }

        let modl = new FetchTripInfo();
        modl.legalEntityId = this.userInfo.legalEntityId;
        modl.legalEntityGUID = this.userInfo.legalEntityGuId;
        modl.companyId = this.userInfo.companyId;
        modl.companyGUID = this.userInfo.companyGuId;
        if (JSON.parse(sessionStorage.getItem('isadmin')) == true) {
            modl.viewType = this.userInfo.roleId;
        } else {
            modl.viewType = 1;
        }
        modl.searchText = this.ExpenseorReport;
        modl.tripStartDate = '';
        modl.tripEndDate = '';
        modl.pageNumber = this.pageNumberTrips;
        modl.pageSize = this.pageSizeTrips;
                this.isApicallinProgressTrips = true;
        modl.filterBy = this.tripfilterModal;
        modl.filterBy.statusIds = modl.filterBy.statusIds[0] ? modl.filterBy.statusIds : [];
        (this.childSelectedIndex + 1 == 1 ? this.reportsService.fetchUnclaimedTrips(modl):this.reportsService.fetchClaimedTrips(modl)).subscribe((res) => {
            this.showLoading = false;
            if (!res.isError && res.errorCode == 0 && res?.payload) {
                this.showLoadingTrips = false;
                if (this.platformType == 1) {
                    if (JSON.parse(localStorage.getItem('trip-filter-data'))) {
                        this.tripfilterPatching(JSON.parse(localStorage.getItem('trip-filter-data')));
                    } else {
                        this.getfliterList();
                    }
                }
                if (this.pageNumberTrips == 1) {
                    this.tripsList = [];
                    this.selectedTrips = [];
                }
                this.minAndMax = res.payload;
                this.selectedExpense = [];
                this.selectedStatus = [];
                this.selectedTripCategory = [];
                this.changeFilterOptions(
                    res.payload.min,
                    res.payload.max,

                );
                const listarray = res.payload.tripList || [];
                console.log('before :',this.tripsList);
                this.tripsList = this.tripsList?.concat((listarray?.map((list) => new NewTripModel(list))));
                console.log('fileter :',this.tripsList,this.pageNumberTrips);
                this.dummyListLength = this.tripsList?.length;
                this.setDummyList();
                this.tripsRecoeds = this.tripsList.length;
                if (
                    this.pageNumberTrips === 1 &&
                    this.isPageLoadTrips === true
                ) {
                    this.isPageLoadTrips = false;
                }

                if (event) {
                    event.target?.complete();
                }
                
                this.isApicallinProgressTrips = false;

            } else {

                if (res.errorCode != 14) {
                    this.tripsList = [];
                    this.dummyListLength = this.tripsList?.length;
                    this.setDummyList();
                    this.toaster.error(res.errorMessage, 'Error');
                }
                this.showLoadingTrips = false;
                this.isApicallinProgressTrips = false;
                if (event && event.target) {
                    event.target.disabled = true;
                }
            }
        });

    }

    sorttype(event){
        console.log(event);
    }
    

    getMycardlist(isPageLoad,event?, cardType?){
        if(!cardType) { cardType = this.childTabLabel; }
        this.isPageLoad = isPageLoad;
        if (this.pageNumber == 1) {
            this.showLoading = true;
            this.mycardslist = [];
        }

        if(this.ascending == 1){
            this.ascending = 2
        }else if(this.ascending == 2){
            this.ascending = 1;
        }

        let request  = {
            "legalEntityGUID": this.userInfo.legalEntityGuId,
            "legalEntityId": this.userInfo.legalEntityId,
            "userId": this.userInfo.userId,
            "userGUID": this.userInfo.userGuId,
            "searchText" : this.searchCard,
            "pageNumber" : this.pageNumber,
            "sortColumn": this.sortColumn != '' ? this.sortColumn : '',
            'sortType': this.sortColumn != '' ? this.ascending : '',
            'pageSize': this.pageSize,
            "viewType": JSON.parse(sessionStorage.getItem('isadmin')) == true ? this.userInfo.roleId : 1
        }
        this.isApicallinProgress = true;
        let cardsService = (cardType == 'Claimed' ? this.expenseService.getClaimedCards(request) : this.expenseService.getUnClaimedCards(request));
        cardsService.subscribe(res => {
            if (this.pageNumber == 1) {
                this.mycardslist = [];
            }
            this.mycardloader = false;
            this.showLoading = false;
            this.showLoadingTrips = false;
            if (this.platformType == 1) {
                if (this.pageNumber == 1 && this.isPageLoad == true) {
                    this.isPageLoad = false;
                }
            } else {
                this.isPageLoad = false;
            }
            if (event) {
                event?.target?.complete();
            }
            res.payload?.cardsList?.forEach(element => {
                element.showCheckBox = false;
                element.visibility = false;
            });
            let listarray = res?.payload?.cardsList || [];
            this.mycardslist = this.mycardslist.concat((listarray.map((list) => new MyCardModel(list))));

            this.isApicallinProgress = false;
        })
    }

    showCheckbox(item): void {
        item.showCheckbox = true;
      }
    
      hideCheckbox(item): void {
        item.showCheckbox = false;
      }

      onChangeListener(e,index){
        this.mycardslist[index].visibility = e.detail.checked;
      }

      onChangeListenerMobile(e,index){
        this.mycardslist[index].showCheckbox = e.detail.checked;
      }

       maskedAccountNumber(accountNumber){
        // Mask all but the last four digits with 'X'
        if (accountNumber && accountNumber.length >= 4) {
            const lastFourDigits = accountNumber.slice(-4);
            return `XXXX-${lastFourDigits}`;
          }
          return accountNumber;
      }

      formattedAccountNumber(cardNumber){
        const visibleDigits = cardNumber.toString().slice(-4);
        const maskedDigits = 'X'.repeat(cardNumber.toString().length - 4);
          
          // Use regular expressions to add a dash every 4 characters
        const formatted = maskedDigits + visibleDigits;
        return formatted.replace(/(.{4})/g, '$1-');
      }

    getExpDetail(list) {
        this.getExpenseModels.expenseId = list.expenseId;
        this.getExpenseModels.expenseGUID = list.expenseGUID;
        this.getExpenseModels.userId = this.userInfo.userId;
        this.getExpenseModels.userGUID = this.userInfo.userGuId;
        this.getExpenseModels.legalEntityId = this.userInfo.legalEntityId;
        this.getExpenseModels.legalEntityGUID = this.userInfo.legalEntityGuId;
        this.expenseService.getExpense(this.getExpenseModels).subscribe((res) => {
            if (!res.isError && res.errorCode === 0) {
                const listarray = res.payload;
                this.expense = new ExpenseListModel(listarray);
                this.showaddNewMileage = true;
                this.totalAmt = this.expense.expenseItems
                    .map((t) => t.unformattedAmount)
                    .reduce((a, value) => a + value, 0);
            } else if (res.isError && res.errorCode === 2) {
            }
        });
    }

    getExpenseSumary(list) {
        this.getExpenseModels.claimId = list.claimId;
        this.getExpenseModels.claimGuid = list.claimGuid || null;
        this.getExpenseModels.expenseId = list.expenseId;
        this.getExpenseModels.expenseGUID = list.expenseGUID;
        this.getExpenseModels.userId = this.userInfo.userId;
        this.getExpenseModels.userGUID = this.userInfo.userGuId;
        this.getExpenseModels.legalEntityId = this.userInfo.legalEntityId;
        this.getExpenseModels.legalEntityGUID = this.userInfo.legalEntityGuId;
        this.expenseService
            .expenseSummary(this.getExpenseModels)
            .subscribe((res) => {
                if (!res.isError && res.errorCode === 0) {
                    const listarray = res.payload;
                    this.expense = new ExpenseListModel(listarray);
                    this.summaryData = res.payload;
                    this.expense.claimId ? this.claimedIdAvailable = true : this.claimedIdAvailable = false;
                    this.showMoreViolations = false;
                    this.displayViolations = [];
                    this.associated = this.expense.isAssociated ? true : false;
                    if (this.expense?.violationList) {
                        this.expense?.violationList.forEach((item, i) => {
                            if (i < 4) {
                                this.displayViolations.push(item);
                            }
                        });
                    }
                    this.invoiceDetails = [];
                    this.invoiceDetails = this.expense?.expenseAttachmentsmodel;

                    this.showaddNewMileage = true;
                    this.showSummaryLoading = false;
                    this.totalAmt = this.expense.expenseItems
                        .map((t) => t.unformattedAmount)
                        .reduce((a, value) => a + value, 0);
                } else if (res.isError && res.errorCode === 2) {
                    this.showSummaryLoading = false;
                }
            });
    }

    getExpenseAttachments(list) {
        this.selectedExpenseids = [];
        this.selectedExpenseids.push(list?.expenseId);
        this.selectedExpenseids = _.uniq(this.selectedExpenseids);
        this.getExpenseModels.expenseIds = this.selectedExpenseids;
        //this.getExpenseModels.expenseGUID = list.expenseGUID;
        this.getExpenseModels.legalEntityId = this.userInfo.legalEntityId;
        this.getExpenseModels.legalEntityGUID = this.userInfo.legalEntityGuId;

        this.showPop = false;
        this.invoiceDetails = [];
        this.invoiceDetailsForDetails = [];

        // this.invoiceDetails = res.payload.attachementList;
        if (list.expenseAttachments?.length > 0) {
            list.expenseAttachments.forEach(element => {
                // if(element.attachmentType == '1'){
                this.invoiceDetails.push(element);
                this.invoiceDetailsForDetails.push(element);
                // }
            });
        }

        // this.invoiceDetailsForDetails = res.payload.attachementList;
        if (this.invoiceDetails.length != 0) {
            let urlStr = this.invoiceDetails[this.defaultIndex].url;
            if (urlStr.split('?')[0].split('.').pop() == 'pdf') {
                // this.isPdf = true;
                // const linkSource = `data:application/pdf;base64,${this.invoiceDetails[this.defaultIndex].base64}`;
                // this.pdfData = linkSource;
                this.getPdfAsBase64(urlStr).then(base64 => {
                    this.isPdf = true;
                    this.isZip = false;
                    const linkSource = `data:application/pdf;base64,${base64}`;
                    this.pdfData = linkSource;
                })
            } else if(urlStr.split('?')[0].split('.').pop() == 'zip' || urlStr.split('?')[0].split('.').pop() == 'rar') {
                this.isZip = true;
                this.isPdf = false;
            } else {
                this.isPdf = false;
                this.isZip = false;
            }
        }

        // "legalEntityId": 396,
        // "legalEntityGUID": "9483d2d5382a1a7ee7057b2064d412cb"
        // this.expenseService.expenseAttachViewer(this.getExpenseModels).subscribe((res) => {
        //     if (!res.isError && res.errorCode === 0) {
        //         this.showPop = false;
        //         this.invoiceDetails = [];
        //         this.invoiceDetailsForDetails = [];

        //         // this.invoiceDetails = res.payload.attachementList;

        //         if (res.payload.attachementList?.length > 0) {
        //             res.payload.attachementList.forEach(element => {
        //                 // if(element.attachmentType == '1'){
        //                 this.invoiceDetails.push(element);
        //                 this.invoiceDetailsForDetails.push(element);
        //                 // }
        //             });
        //         }

        //         // this.invoiceDetailsForDetails = res.payload.attachementList;
        //         if (this.invoiceDetails.length != 0) {
        //             let urlStr = this.invoiceDetails[this.defaultIndex].url;
        //             if (urlStr.split('?')[0].split('.').pop() == 'pdf') {
        //                 this.isPdf = true;
        //                 const linkSource = `data:application/pdf;base64,${this.invoiceDetails[this.defaultIndex].base64}`;
        //                 this.pdfData = linkSource;
        //             } else {
        //                 this.isPdf = false;
        //             }
        //         }


        //     } else if (res.isError && res.errorCode === 2) {
        //     }
        // });
    }

    async editExpense(expense) {
        const modal = await this.modalController.create({
            component: ExpenseAndMileageComponent,
            cssClass: "my-custom-class",
            componentProps: {
                expense: this.expense,
            },
        });
        return await modal.present();
    }

    editExpDetail(expense) {
        this.dataForNewExp(false, true, expense);
    }


    dataForNewExp(isDuplicate, isEdit?, expense?) {
        // if(expense.expenseTaggedTripList.length != 0){
        //     expense.expenseTaggedTripList.forEach(element => {
        //         this.selectedTrips.push(element);
        //     });
        // }
        var url = "expenses";
        if (this.router.url == '/petty-cash/expense') {
            url = 'petty-cash/expense'
        }
        let navInfo = {
            url: url,
            expenseId: expense ? expense.expenseId : null,
            expenseGUID: expense ? expense.expenseGUID : null,
            status: expense ? expense.status : null,
            recordType: expense ? expense.recordType : this.fromPettyCash ? 4 : null,
            isEdit: isEdit,
            isDuplicate: isDuplicate,
            // expenseArray: this.selectedTrips,
            // singleSelection: this.singleSelection,
            // multiSelection: this.multiSelection,
            // singleExpense: this.selectedTrips,
            // isAssociated:this.associated,
            formType: expense ? expense.expenseType : null,
        };
        sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
        sessionStorage.setItem('fromPettyCash', String(this.fromPettyCash));
        this.navCtrl.navigateRoot(['newExpenseMileageBulk']);
    }


    duplicateExpDetail(expense) {
        this.dataForNewExp(true, false, expense);
    }

    opendetails(expense) {
        this.showMobileDetails = false;
        this.expenseId = expense.expenseId,
            this.expenseGUID = expense.expenseGUID,
            this.fromcomp = 'expense';
        this.expenseCode = expense?.categoryName;
        this.expeseID = expense?.expenseId;
    }

    returnDasbord() {
        let typeVal = JSON.parse(sessionStorage.getItem("fc365"));
        if (typeVal) {
            this.navCtrl.navigateRoot(["dashboard"]);
        } else {
            this.navCtrl.navigateRoot(["dashboard"]);
        }
    }

    async addNew(type) {
        const modal = await this.modalController.create({
            component: ExpenseAndMileageComponent,
            cssClass: "my-custom-class",
        });
        modal.onDidDismiss().then(res => {
            if (res) {
                this.getExpenseList(false);
            }
        })
        return await modal.present();
    }

    async addNewMileage(type) {

        sessionStorage.setItem('fab', JSON.stringify(true));

        if (this.fromPettyCash) {
            sessionStorage.setItem('fromPettyCash', JSON.stringify(true));
        }
        const modal = await this.modalController.create({
            component: ExpenseAndMileageComponent,
            cssClass: "my-custom-class",
            componentProps: {
                isEdit: false,
                type,
                fromPage: 'dashboard',
                isGPSShow: true
            },
        });
        return await modal.present();
    }

    async expFilter() {

        this.showExpFilter = true;
        this.showExpList = false;
        this.showExpDetail = false;
        this.showExpSearch = false;
        this.addExpenseHide = false;
        this.selectedExpense = [];
        this.showLoading = false;

        // const modal = await this.modalController.create({
        //     component: CommonFilterComponent,
        //     cssClass: "my-custom-class",
        //     componentProps: {
        //         mainList: this.filterModal
        //     },
        // });

        // modal.onDidDismiss()
        // .then((callback) => {
        //     if (callback && callback.data) {
        //        console.log("filters data is", callback.data);
        //        this.filterModal = callback.data;
        //        this.getExpenseList(false);
        //     }
        // });
        // return await modal.present();

    }

    returnExpSegment() {
        this.showExpFilter = false;
        this.showExpList = true;
    }

    returnExpense() {
        this.addExpenseHide = true;
        this.addExpenseListHide = false;
    }

    returnExpList() {
        this.showExpList = true;
        this.showExpSearch = false;
    }

    selectSingleexpenses(list, e) {
        list.isSelected = e.target.checked;
        if (list.isSelected) {
            this.selectedExpense.push(list.expenseId);
            if (list.claimId === null) {
                this.unReportExpenses.push(list.expenseId);
            }
            if (list.status === 1) {
                this.statusArray.push(list.expenseId);
            }
        } else {
            let index = _.indexOf(this.selectedExpense, list.expenseId);
            this.selectedExpense.splice(index, 1);
            if (list.claimId == null) {
                index = _.indexOf(this.unReportExpenses, list.expenseId);
                this.unReportExpenses.splice(index, 1);
            }
            if (list.status === 1) {
                index = _.indexOf(this.statusArray, list.expenseId);
                this.statusArray.splice(index, 1);
            }
        }
        if (
            this.expenseList.length !== this.selectedExpense.length &&
            this.selectedExpense.length > 0
        ) {
            this.checkAll = false;
            this.indeterminateState = true;
        } else if (this.selectedExpense.length === 0) {
            this.checkAll = false;
            this.indeterminateState = false;
        } else {
            this.checkAll = true;
            this.indeterminateState = false;
        }
        this.deleteExpenses.expenseIds = this.selectedExpense;
        this.addReportExpense.expenseIds = this.unReportExpenses;

    }

    selectSingleTrip(list, e) {
        list.isSelected = e.target.checked;
        if (list.isSelected) {
            this.selectedTrips.push(list);
        } else {
            let index = _.indexOf(this.selectedTrips, list.tripId);
            this.selectedTrips.splice(index, 1);
        }
    }

    selectAllexpenses(event?) {
        setTimeout(() => {
            this.expenseList.forEach((item) => {
                item.isSelected = event ? false : this.checkAll;
            });
        });
    }

    selectAllTrips(event?) {
        setTimeout(() => {
            this.tripsList.forEach((item) => {
                item.isSelected = event ? false : this.checkAll;
            });
        });
    }

    async removeExpensePopUp(list?) {

        let matchInd = this.expenseList.findIndex(exp => {
            if (exp.expenseId == this.selectedExpense[0]) {
                return exp;
            }
        });
        if (matchInd !== -1) {
            this.deleteExpenses.recordType = this.expenseList[matchInd]?.recordType;
            this.deleteExpenses.categoryType = this.expenseList[matchInd]?.categoryType;
        }

        if (list) {
            const expDeleteArry = [];
            expDeleteArry.push(list.expenseId);
            this.deleteExpenses.expenseIds = expDeleteArry;
        }

        this.deleteExpenses.recordType = list?.recordType;
        this.deleteExpenses.categoryType = list?.categoryType;

        const alert = await this.alertController.create({
            mode: "md",
            message: this.translate.instant('alerts.confirmDeleteGenrl'),
            backdropDismiss: false,
            buttons: [
                {
                    text: this.translate.instant('common.cancel'),
                    role: "cancel",
                },
                {
                    text: this.translate.instant('common.delete'),
                    handler: () => {
                        this.zone.run(() => {
                            this.buttonDisabled = true;
                            this.deleteExpenses.legalEntityId = this.userInfo.legalEntityId;
                            this.deleteExpenses.legalEntityGUID = this.userInfo.legalEntityGuId;
                            this.deleteExpenses.userId = this.userInfo.userId;
                            this.deleteExpenses.userGUID = this.userInfo.userGuId;

                            this.expenseService
                                .deleteExpense(this.deleteExpenses)
                                .subscribe((data) => {
                                    if (!data.isError && data.errorCode === 0) {

                                        this.buttonDisabled = false;
                                        this.backToexpense();
                                        this.getExpenseList(true);
                                        this.toastr.success(data?.payload, this.translate.instant('alerts.succes'));
                                    } else if (data.isError && data.errorCode === 2) {

                                        this.buttonDisabled = false;
                                        this.toastr.error(data.errorMessage, this.translate.instant('alerts.error'));
                                    }
                                });
                        });

                    },

                },
            ],
        });
        await alert.present();
    }

    getreportlist() {
        this.expenseAndReportModel.legalEntityId = this.userInfo.legalEntityId;
        this.expenseAndReportModel.legalEntityGUID = this.userInfo.legalEntityGuId;
        this.expenseAndReportModel.userId = this.userInfo.userId;
        this.expenseAndReportModel.userGUID = this.userInfo.userGuId;
        this.expenseAndReportModel.pageNumber = this.pageNumber;
        this.expenseAndReportModel.filterBy.isDraft = true;
        this.expenseAndReportModel.filterBy.statusIds = this.expenseAndReportModel.filterBy.statusIds[0] ? this.expenseAndReportModel.filterBy.statusIds : [];
        this.reportsService
            .reportList(this.expenseAndReportModel)
            .subscribe((res) => {
                if (!res.isError && res.errorCode === 0) {
                    const listArray = res.payload.reportResponseList;
                    listArray.map(data => {
                        const list = listArray.filter((obj) => {
                            return obj.claimId === data.claimId;
                        });
                        data.cliamNamecode = list[0].claimName + " - " + list[0].claimCode;
                    });
                    this.reportList = listArray.map(
                        (list) => new ReportListResModel(list)
                    );
                } else if (res.isError && res.errorCode === 2) {
                    this.toaster.error(res.errorMessage, this.translate.instant('alerts.error'));
                }
            });
    }

    addReportModal(selectReport: TemplateRef<any>, list?) {
        this.isShowpopup = true;
        if (list) {
            const unReportArry = [];
            unReportArry.push(list.expenseId);
            this.addReportExpense.expenseIds = unReportArry;
        }
        const config = {
            ignoreBackdropClick: true,
            class: "modal-dialog-centered",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(selectReport, config);
    }

    openReportModal() {
        // this.reportModal.open();
    }

    // onReportSearch() {

    // }

    selectReporttoadd(e) {
        this.addreportForm.controls.claimName.patchValue(e?.claimName);
        this.addReportExpense.claimId = e?.claimId;
    }
    validateInput(e) {
        let userinput = String.fromCharCode(e.which);
        let regularExpression = new RegExp(/[]/);
        let isGoodInput = regularExpression.test(userinput);
        if (!isGoodInput) {
            // alert('User typed bad input[ ' + userinput + ' ], so I am blocking input!');
            e.preventDefault();
        }
        else {
            // alert('User typed good input[ ' + userinput + ' ]');
        }
    }

    onSelect(event) {
        this.addReportExpense.claimId = event.item.claimId;
    }

    get f() {
        return this.addreportForm.controls;
    }

    reportSubmit() {
        this.addReportExpense.legalEntityId = this.userInfo.legalEntityId;
        this.addReportExpense.legalEntityGUID = this.userInfo.legalEntityGuId;
        this.addReportExpense.userId = this.userInfo.userId;
        this.addReportExpense.userGUID = this.userInfo.userGuId;
        if (this._addReport) {
            this.addReportExpense.claimName = this.addreportForm.controls.claimName.value;
            this.addReportExpense.claimId = null;
        }
        this.addReport = true;
        if (this.platformType === 1) {
            if (!this.addreportForm.valid) {
                this.toaster.warning(this.translate.instant('alerts.selectreport'));
                return;
            }
        } else {
            if (!this.addReportExpense.claimId && this.addReportExpense.claimName == "") {
                this.toaster.warning(this.translate.instant('alerts.selectreport'));
                return;
            }
        }
        this.addReportDisabled = true;
        this.expenseService.addToReport(this.addReportExpense).subscribe((res) => {
            if (!res.isError && res.errorCode === 0) {
                if (this.platformType === 2) {
                    // this.reportModal.close();
                } else {
                    this.modalRef.hide();
                }
                this.addreportForm.reset();
                this.getExpenseList(false);
                let val = {
                    expenseId: this.getExpenseModels.expenseId,
                    expenseGUID: this.getExpenseModels.expenseGUID,
                    status: true
                };
                if (val) {
                    if (val["expenseId"] && val["expenseGUID"] && val["status"]) {
                        this.showExpenseDetail(val);
                    }
                }

                this.addReport = false;
                this.addReportDisabled = false;
                this.unReportExpenses = [];
                this.selectedExpense = [];
                this.reportName = "";
                this.toastr.success(res.payload, this.translate.instant('alerts.succes'));
                this.indeterminateState = false;
                this.checkAll = false;
                this._addReport = false;
                this.claimedIdAvailable = true;
            } else if (res.isError === true) {
                this.addReportDisabled = false;
                this.addReport = false;
                this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
                this.addReport = false;
                this._addReport = false;
            }
        });
    }

    dismiss() {
        this.modalController.dismiss({
            dismissed: true,
        });
    }

    async presentModal() {

        const modal = await this.modalController.create({
            component: ExpenseAndMileageComponent,
            cssClass: "my-custom-class",
        });
        return await modal.present();
    }

    addNewExpense() {

        this.addExpenseHide = false;
        this.addExpenseListHide = true;
    }

    showFiltersection() {
        if (this.showFilter) {
            this.showFilter = false;
            // this.applyFilter();
        } else {
            this.showFilter = true;
            this.selected_filters = [];
            this.selectedcategory = [];
        }
        this.dropdownFlag = false;
    }

    expenseSegment(e) {
        this.segmentModel = e.target.value;
        if (e.target.value === "draft") {
            this.draft = true;
            this.pageNumber = 1;
            this.getExpenseList(false);
            this.selectedcategory = [];
            this.selectedStatus = [];
            this.resetAll();
        } else {
            this.draft = false;
            this.pageNumber = 1;
            this.selectedExpense = [];
            this.getExpenseList(false);
            this.selectedcategory = [];
            this.selectedStatus = [];
            this.resetAll();
        }
    }

    toggleColumns(e, val: string, type?) {
        // alert(e.target.checked)
        if (e.target.checked === true) {
            if (type == 'claim' && this.claimsHideColumns.indexOf(val) < 0) {
                this.claimsHideColumns.push(val);
            } else if (type == 'expense' && this.expensesHideColumns.indexOf(val) < 0) {
                this.expensesHideColumns.push(val);
            } else if (type == 'trip' && this.tripsHideColumns.indexOf(val) < 0) {
                this.tripsHideColumns.push(val);
            } else if (type == 'card' && this.mycardsHideColumns.indexOf(val) < 0) {
                this.mycardsHideColumns.push(val);
            }
            // if (this.hideColumns.indexOf(val) < 0) {
            //     this.hideColumns.push(val);
            // }
        } else {
            if (type == 'claim') {
                const index = this.claimsHideColumns.indexOf(val);
                if (index > -1) { this.claimsHideColumns.splice(index, 1) }
            } else if (type == 'expense') {
                const index = this.expensesHideColumns.indexOf(val);
                if (index > -1) { this.expensesHideColumns.splice(index, 1) }
            } else if (type == 'trip') {
                const index = this.tripsHideColumns.indexOf(val);
                if (index > -1) { this.tripsHideColumns.splice(index, 1) }
            } else if (type == 'card') {
                const index = this.mycardsHideColumns.indexOf(val);
                if (index > -1) { this.mycardsHideColumns.splice(index, 1) }
            }
            // const index = this.hideColumns.indexOf(val);
            // if (index > -1) {
            //     this.hideColumns.splice(index, 1);
            // }
        }
    }

    showhideColumns(val: string, type?) {
        let column = true;
        if (type == 'claim' && this.claimsHideColumns.indexOf(val) > -1) {
            column = false;
        } else if (type == 'expense' && this.expensesHideColumns.indexOf(val) > -1) {
            column = false;
        } else if (type == 'trip' && this.tripsHideColumns.indexOf(val) > -1) {
            column = false;
        } else if (type == 'card' && this.mycardsHideColumns.indexOf(val) > -1) {
            column = false;
        }
        // if (this.hideColumns.indexOf(val) > -1) {
        //     column = false;
        // }        
        return column;
    }

    setLang(lang, name) {
        this.langName = name;
        this.translateconfigService.setLanguage(lang);
    }

    getCategorylist() {
        // this.CategoryMerchantModel.companyId = this.userInfo.companyId;
        // this.CategoryMerchantModel.companyGUID = this.userInfo.companyGuId;
        this.CategoryMerchantModel.legalEntityId = this.userInfo.legalEntityId;
        this.CategoryMerchantModel.legalEntityGUID = this.userInfo.legalEntityGuId;
        if (this.fromPettyCash) {
            this.CategoryMerchantModel.recordType = 4;
        } else {
            this.CategoryMerchantModel.recordType = 1;
        }
        // this.CategoryMerchantModel.status = 1;
        // this.CategoryMerchantModel.categoryType = null;
        this.commonService
            .getCatgryMasterlist(this.CategoryMerchantModel)
            .subscribe((res) => {
                if (!res.isError && res.errorCode === 0) {
                    const listarray = res.payload;
                    if (this.fromPettyCash) {
                        this.categoryList = listarray.pettyCashCategoryList.map((list) => new CategoryList(list));
                    } else {
                        this.categoryList = listarray.expenseCategoryList.map((list) => new CategoryList(list));
                    }
                    localStorage.setItem('recurrent-master-category', JSON.stringify(this.categoryList));

                } else if (res.isError === true) {
                    this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
                }
            });
    }

    selectAllCategory() {
        setTimeout(() => {
            this.categoryList.forEach((item) => {
                item.isSelected = this.checkAllCat;
            });
        });
    }

    selectAllStatus() {
        setTimeout(() => {
            this.statusList.forEach((item) => {
                item.isSelected = this.checkAllStatus;
            });
        });
    }


    selectCategorymob(e) {
        const selected = e.isSelected;
        if (selected === true) {
            this.selectcatarraymob.push(e.item.categoryId);
        } else {
            const index = _.indexOf(this.selectcatarraymob, e.item.categoryId);
            this.selectcatarraymob.splice(index, 1);
        }
        this.filterModal.categoryIds = this.selectcatarraymob;
    }

    selectstatusmob(e) {
        const selected = e.isSelected;
        if (selected === true) {
            this.selectedstatusmob.push(e.item.statusId);
            this.filterModal.statusIds = this.selectedstatusmob;
        } else {
            const index = _.indexOf(this.selectedstatusmob, e.item.statusId);
            this.selectedstatusmob.splice(index, 1);
            this.filterModal.statusIds = this.selectedstatusmob;
        }

    }

    selectSingleCategory(list, e) {
        if (list.isSelected) {
            this.selectedcategory.push(list.categoryId);
            this.selected_filters.push(list.categoryName);
        } else {
            const index = _.indexOf(this.selectedcategory, list.categoryId);
            const index_value = _.indexOf(this.selected_filters, list.categoryName);
            this.selectedcategory.splice(index, 1);
            this.selected_filters.splice(index_value, 1);
        }
        this.filterModal.categoryIds = this.selectedcategory;
        if (this.categoryList.length !== this.selectedcategory.length && this.selectedcategory.length > 0) {
            this.checkAllCat = false;
            this.categoryState = true;
        } else if (this.selectedcategory.length === 0) {
            this.checkAllCat = false;
            this.categoryState = false;
        } else {
            this.checkAllCat = true;
            this.categoryState = false;
        }

    }

    showclearAll = false;
    clearAllClaimFilters(tabChange?) {
        this.filterFromDate = undefined;
        this.filterToDate = undefined;
        this.frmDate = undefined;
        this.tDate = undefined;
        this.filterMinPrice = undefined;
        this.filterMaxPrice = undefined;
        this.filterModal.minPrice = this.minPrice;
        this.filterModal.maxPrice = this.maxPrice;
        this.statusAll = false;
        this.checkAllCat = false;
        this.checkAllStatus = false;
        this.showclearAll = false;
        this.pageNumber = 1;
        this.reportList = [];
        this.expenseList = [];
        this.tripsList = [];
        this.statusList.forEach(s => {
            s.isSelected = false;
            s.showInFilter = false;
        })
        this.categoryList.forEach(c => {
            c.isSelected = false;
            c.showInFilter = false;
        })
        this.filterModal.statusIds = [];
        this.filterModal.categoryIds = [];
        this.resetClaimType();
        if (!tabChange) {
            this.applyClaimFilter(false, true);
        }
    }

    showCreallAllButton() {
        let price = false;
        let date = false;
        let status = false;
        let category = false;
        if (this.filterMinPrice || this.filterMaxPrice) {
            price = true;
        }
        if (this.filterFromDate || this.filterToDate) {
            date = true;
        }
        this.statusList.forEach(s => {
            if (s.showInFilter && !status) {
                status = true;
            }
        })
        this.categoryList.forEach(c => {
            if (c.showInFilter && !category) {
                category = true;
            }
        })

        if (!price && !date && !status && !category) {
            this.statusAll = false;
            this.showclearAll = false;
        } else {
            this.showclearAll = true;
        }
    }


    showCreallAllButtonTrips() {
        let price = false;
        let date = false;
        let status = false;
        let category = false;
        if (this.filterMinPrice || this.filterMaxPrice) {
            price = true;
        }
        if (this.filterFromDate || this.filterToDate) {
            date = true;
        }
        this.filterListStatus.forEach(s => {
            if (s.showInFilter && !status) {
                status = true;
            }
        })
        this.filterCategoryList.forEach(c => {
            if (c.showIncatFilter && !category) {
                category = true;
            }
        })
        if (!price && !date && !status && !category) {
            this.statusAll = false;
            this.showclearAll = false;
        } else {
            this.showclearAll = true;
        }
    }


    removeDateFilter() {
        this.filterFromDate = undefined;
        this.filterToDate = undefined;
        this.frmDate = undefined;
        this.tDate = undefined;
        let removeAmount = false;
        if (!this.filterMaxPrice && !this.filterMinPrice) {
            removeAmount = true;
        }
        this.applyClaimFilter(false, removeAmount);
    }

    removeAmountFilter() {
        this.filterMinPrice = undefined;
        this.filterMaxPrice = undefined;
        this.filterModal.minPrice = this.minPrice;
        this.filterModal.maxPrice = this.maxPrice;
        this.applyClaimFilter(false, true);
    }


    removeFilter(list) {
        list.isSelected = false;
        this.filterModal.statusIds = [];
        this.filterModal.categoryIds = [];
        this.statusList.forEach(s => {
            if (s.isSelected) {
                this.filterModal.statusIds.push(s.statusId)
            }
        })
        this.categoryList.forEach(c => {
            if (c.isSelected) {
                this.filterModal.categoryIds.push(c.categoryId)
            }
        })
        // console.log(this.filterModal.statusIds);
        let removeAmount = false;
        if (!this.filterMaxPrice && !this.filterMinPrice) {
            removeAmount = true;
        }
        this.applyClaimFilter(false, removeAmount);
    }


    selectSingleStatus(list, e) {
        if (list.isSelected) {
            this.selectedStatus.push(list.statusId);
            this.selected_filters.push(list.statusDescription);
            this.filterModal.statusIds = this.selectedStatus;
        } else {
            const index = _.indexOf(this.selectedStatus, list.statusId);
            const index_value = _.indexOf(this.selected_filters, list.statusDescription);
            this.selectedStatus.splice(index, 1);
            this.selected_filters.splice(index_value, 1);
            this.filterModal.statusIds = this.selectedStatus;
        }
        this.filterModal.statusIds = this.selectedStatus;
        if (
            this.statusList.length !== this.selectedStatus.length &&
            this.selectedStatus.length > 0
        ) {
            this.checkAllStatus = false;
            this.statusState = true;
        } else if (this.selectedStatus.length === 0) {
            this.checkAllStatus = false;
            this.statusState = false;
        } else {
            this.checkAllStatus = true;
            this.statusState = false;
        }
    }

    selectSingleTripStatus(list) {
        if (list.isSelected) {
            this.selectedStatus.push(list.statusId);
            this.tripfilterModal.statusIds = this.selectedStatus;
        } else {
            const index = _.indexOf(this.selectedStatus, list.statusId);
            this.selectedStatus.splice(index, 1);
            this.tripfilterModal.statusIds = this.selectedStatus;
        }
        this.tripfilterModal.statusIds = this.selectedStatus;
        if (
            this.statusList.length !== this.selectedStatus.length &&
            this.selectedStatus.length > 0
        ) {
            this.checkAllTripStatus = false;
            this.tripStatusState = true;
        } else if (this.selectedStatus.length === 0) {
            this.checkAllTripStatus = false;
            this.tripStatusState = false;
        } else {
            this.checkAllTripStatus = true;
            this.tripStatusState = false;
        }
    }

    remove_selected_filter(index) {
        this.showFilter = !this.showFilter;
        const selected_status_value = this.statusList.find((object) => object.statusDescription == this.selected_filters[index]);
        const selected_category_value = this.categoryList.find((object) => object.categoryName == this.selected_filters[index]);
        if (selected_status_value) {
            this.filterModal.statusIds = this.filterModal.statusIds.filter((obj) => { return obj != selected_status_value.statusId });
            // FND INDEX , TO SET AS FALSE IN STATUS LIST //
            let index_value = this.statusList.findIndex(x => x.statusId === selected_status_value.statusId);
            this.statusList[index_value].isSelected = false;
        } else {
            this.filterModal.categoryIds = this.filterModal.categoryIds.filter((obj) => { return obj != selected_category_value.categoryId });
            this.selectedcategory = this.selectedcategory.filter((obj) => { return obj != selected_category_value.categoryId });
            // FND INDEX , TO SET AS FALSE IN CATEGORY LIST //
            let index_value = this.categoryList.findIndex(x => x.categoryId === selected_category_value.categoryId);

            this.categoryList[index_value].isSelected = false;

        }
        this.selected_filters.splice(index, 1);
        // this.applyFilter();
    }
    clear_filter_values() {
        this.showFilter = !this.showFilter;
        this.filterModal.categoryIds = [];
        this.filterModal.statusIds = [];
        this.selectedStatus = [];
        this.selectedcategory = [];
        this.selected_filters = [];
        this.categoryList.forEach(element => {
            element.isSelected = false;
        });
        this.statusList.forEach(element => {
            element.isSelected = false;
        });
        // this.applyFilter();
    }

    getfromDate(e) {

        this.frmDate = e.target.value;
        const date = this.datechangeService.convertCalendarToAPI(e.target.value);
        this.filterModal.fromDate = date;
        this.minDate = new Date(this.frmDate);
    }

    gettodate(e) {
        this.tDate = e.target.value;
        const date = this.datechangeService.convertCalendarToAPI(e.target.value);
        this.filterModal.toDate = date;
        this.maxDate = new Date(this.tDate);
    }

    fromDateMobile(e) {
        if (e.detail.value !== "") {
            this.frmDate = e.detail.value;
            this.filterModal.fromDate = moment(new Date(this.frmDate)).format(Constants.mobileDateFormat);
            this.minDate = moment(new Date(this.frmDate)).format(Constants.mobileCalenderFormat);
        } else {
            this.filterModal.fromDate = undefined;
        }
    }
    getFromDateCall(ev) {

        this.frmDate = moment(ev.detail.value).format("D MMM YYYY");
        this.filterModal.fromDate = moment(ev.detail.value).format("MM-DD-YYYY");
        this.minDate = moment(ev.detail.value).format("DD-MM-YYYY");
        this.minDateStr = moment(ev.detail.value).format("YYYY-MM-DD");

    }
    getToDateCall(ev) {

        this.tDate = moment(ev.detail.value).format("D MMM YYYY");
        this.filterModal.toDate = moment(ev.detail.value).format("MM-DD-YYYY");
        this.mobMaxDate = moment(ev.detail.value).format("DD-MM-YYYY");
    }

    toDateMobile(e) {
        if (e.detail.value !== "") {
            this.tDate = e.detail.value;
            this.filterModal.toDate = moment(new Date(this.tDate)).format(Constants.mobileDateFormat);
            this.mobMaxDate = moment(new Date(this.tDate)).format(Constants.mobileCalenderFormat);
        } else {
            this.filterModal.fromDate = undefined;
        }

    }

    reportexpsearch(e) {
        this.filterModal.searchText = e.detail.value;
    }

    applyfilter() {
        this.showFilter = false;
        this.resetPaging();
        if (this.frmDate !== undefined && this.tDate === undefined) {
            this.tDate = this.mobMaxDate;
            this.filterModal.toDate = moment(new Date()).format(Constants.mobileDateFormat);
        }
        if (this.frmDate === undefined && this.tDate !== "") {
            this.frmDate = this.tDate;
            this.filterModal.fromDate = this.tDate;
        }
        // this.createFilters();
        this.filterModal.fromDate = this.frmDate ? moment(new Date(this.frmDate)).format(Constants.mobileDateFormat) : undefined;
        this.filterModal.toDate = this.tDate ? moment(new Date(this.tDate)).format(Constants.mobileDateFormat) : undefined;

        if (this.matTabIndex == 1) {
            // this.statusList = [];
            this.getExpenseList(false);
            this.returnExpSegment();
        } else if (this.matTabIndex == 2) {
            // this.statusList = [];
            this.getTripsList(false, '');
            this.returnExpSegment();
        }
        // this.maxDate = new Date();

        //  this.mobMaxDate = moment(new Date()).format(Constants.mobileCalenderFormat);
    }

    openNewReport(editreport?) {
        // this.navCtrl.navigateRoot(["newreport"]);
        let navInfo = {
            url: 'reports',
            report: editreport
        };
        sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
        sessionStorage.setItem('fromPettyCash', String(this.fromPettyCash));
        if (this.fromPettyCash) {
            this.navCtrl.navigateRoot(["petty-cash/new-report"]);
        } else {
            this.navCtrl.navigateRoot(["newreport"]);
        }
    }

    changeOptions(minValue, maxValue) {
        const newOptions: Options = Object.assign({}, this.options);
        newOptions.floor = minValue;
        newOptions.ceil = maxValue;
        this.minPrice = minValue;
        this.maxPrice = maxValue;
        this.options = newOptions;
    }

    resetAmount() {
        this.filterModal.minPrice = this.minAndMax.expenseMinAmount;
        this.filterModal.maxPrice = this.minAndMax.expenseMaxAmount;
    }

    priceSlider(changeContext: ChangeContext) {
        this.filterModal.minPrice = changeContext.value;
        this.filterModal.maxPrice = changeContext.highValue;
    }

    resetDates() {
        this.frmDate = undefined;
        this.tDate = undefined;
        this.filterModal.fromDate = undefined;
        this.filterModal.toDate = undefined;
        this.minDate = undefined;
        this.maxDate = new Date();
    }

    resetAll() {
        this.ExpenseorReport = "";
        this.frmDate = undefined;
        this.tDate = null;
        this.filterModal.fromDate = null;
        this.filterModal.toDate = null;
        this.filterModal.minPrice = null;
        this.filterModal.maxPrice = null;
        this.status = "";
        this.categoryName = "";
        this.minDate = "";
        this.mobMaxDate = moment(new Date()).format(Constants.mobileCalenderFormat);
        // this.resetAmount();
    }

    resetSinglefilter(type) {
        if (type) {
            switch (type) {
                case 1:
                    this.frmDate = undefined;
                    this.filterModal.fromDate = this.frmDate;
                    this.minDate = undefined;
                    this.maxDate = new Date();
                    break;
                case 2:
                    this.tDate = undefined;
                    this.filterModal.toDate = this.tDate;
                    this.minDate = undefined;
                    this.maxDate = new Date();
                    break;
                case 3:
                    this.status = "";
                    this.selectedStatus = [];
                    this.filterModal.statusIds = this.selectedStatus;
                    break;
                case 4:
                    this.categoryName = "";
                    this.selectedcategory = [];
                    this.filterModal.categoryIds = this.selectedcategory;
                    break;
            }
        }
    }

    apply() {
        // this.selectCatComponent.confirm();
        // this.selectCatComponent.close();
    }

    reportapply() {
        // this.reportModal.confirm();
        // this.reportModal.close();
    }

    statusApply() {
        // this.selectstatusComponent.confirm();
        // this.selectstatusComponent.close();
    }

    /* createFilters() {
       for (var i = 0; i < this.categoryList.length; i++) {
         if (this.categoryList[i].isSelected == true)
           this.selectedcategory.push(this.categoryList[i].categoryId);
       }
       this.filterModal.categoryIds = this.selectedcategory;
     } */
    onClear() {

    }
    backToExpenses() {
        this.showTripDetails = false;
        this.showLoading = false;
        this.hideReporttable = true;
        this.tableHide = true;
        this.showExpenseSummary = false;
    }
    tripDetails(obj) {
        this.selectedTrips = [];
        this.tripsList.forEach(element => {
            element.isSelected = false;
        });
        this.showTripDetails = true;
        this.showExpFilter = true;
        this.showExpList = true;
        this.showExpDetail = false;
        this.showExpSearch = true;
        this.addExpenseHide = true;
        this.hideReporttable = false;
        this.showDetailreport = false;
        // this.navCtrl.navigateRoot(['tripDetails']);
    }

    currentExpenseIndex: number;
    gotoShowExpenseDetail(list) {
        this.currentExpenseIndex = this.expenseList.findIndex(e => e.claimCode == list.claimCode);
        this.showExpenseDetail(list);
    }

    nextDetail() {
        if (this.currentExpenseIndex < this.expenseList.length - 1) {
            ++this.currentExpenseIndex;
            this.showExpenseDetail(this.expenseList[this.currentExpenseIndex]);
        }

    }

    preDetail() {
        if (this.currentExpenseIndex > 0) {
            --this.currentExpenseIndex;
            this.showExpenseDetail(this.expenseList[this.currentExpenseIndex]);
        }
    }
    showExpenseSummary = false;
    showExpenseDetail(list) {
        this.selectAllexpenses();
        this.indeterminateState = false;
        this.selectedExpense = [];
        this.tableHide = false;
        this.showExpenseSummary = true;
        this.showSummaryLoading = true;
        this.getExpenseSumary(list);
        if (list.status === 1) {
            this.expBtns = true;
        } else {
            this.expBtns = false;
        }

        const expDeleteArry = [];
        this.expenseModel.expenseId = list.expenseId;
        expDeleteArry.push(list.expenseId);
        this.deleteExpenses.expenseIds = expDeleteArry;
        if (this.platformType == 1) {
            this.nextvalue = this.expenseList.indexOf(list);
        }
        this.getExpenseAttachments(list);
        this.selectExpense = list;
    }

    getStatusList() {
        const type = { type: +1 };
        this.commonService.statusList(type).subscribe((res) => {
            if (!res.isError && res.errorCode === 0) {
                const listarray = res.payload;
                this.statusList = listarray.map((list) => new StatusListModel(list));
                localStorage.setItem('common-status', JSON.stringify(this.statusList));
                this.afterStatusApiCall();

            } else if (res.isError === true) {
                this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
            }
        });
    }

    afterStatusApiCall() {
        if (this.fromAnlyticsPage) {
            this.statusList.filter(res => {
                if (res.statusId == this.fromAnlyticsPage) {
                    res.isSelected = true;
                }

            });
        }
        if (this.val && this.val["url"] && (this.val["url"] == 'dashboard')) {
            if (this.val["pointerValue"] && this.val["pointerValue"] == 1) {
                this.statusList.forEach(element => {
                    if (element.statusId == 2 || element.statusId == 3) {
                        this.selected_filters.push(element.statusDescription);
                        element.isSelected = true;
                    } else {
                        element.isSelected = false;
                    }
                });
            } else if (this.val["pointerValue"] && this.val["pointerValue"] == 2) {
                this.statusList.forEach(element => {
                    if (element.statusId == 7) {
                        this.selected_filters.push(element.statusDescription);
                        element.isSelected = true;
                    } else {
                        element.isSelected = false;
                    }
                });
            } else if (this.val["pointerValue"] && this.val["pointerValue"] == 3) {
                this.statusList.forEach(element => {
                    if (element.statusId == 2 || element.statusId == 3) {
                        this.selected_filters.push(element.statusDescription);
                        element.isSelected = true;
                    } else {
                        element.isSelected = false;
                    }
                });
            } else if (this.val["pointerValue"] && this.val["pointerValue"] == 4) {
                this.statusList.forEach(element => {
                    if (element.statusId == 1) {

                        this.selected_filters.push(element.statusDescription);
                        element.isSelected = true;
                    } else {
                        element.isSelected = false;
                    }
                });
            }
        }

        if (this.routerState && this.routerState.data) {
            if (this.routerState.data.from == 'dashboardFilter') {
                this.statusList.forEach(element => {
                    if(this.routerState.data.ids.includes(element.statusId)) {
                        element.isSelected = true;
                    } else {
                        element.isSelected = false;
                    }
                });
            }
            this.applyClaimFilter(true);
        }
    }

    filterlist = [];
    getfliterList() {
        let requestModal = {
            legalEntityId: this.userInfo.legalEntityId,
            legalEntityGUID: this.userInfo.legalEntityGuId,
        }
        this.commonService.fliterList(requestModal).subscribe((res) => {
            if (res.payload) {
                localStorage.setItem('trip-filter-data', JSON.stringify(res.payload));
                this.tripfilterPatching(res.payload);
            } else if (res.isError === true) {
                this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
            }
        });
    }

    tripfilterPatching(res) {
        res?.forEach(element => {
            this.filterService = element.services
        });
        res?.forEach(element => {
            this.filterstatus = element.tripStatus;

        });
        this.filterListStatus = this.filterstatus;
        this.filterCategoryList.forEach(element => {
            element.isSelected = false
        });
        this.filterCategoryList = this.filterService;
    }
    backToexpense() {
        this.tableHide = true;
        this.dropdownValue = null;
        this.showMobileDetails = true;
        this.selectedExpense = [];
        this.unReportExpenses = [];
    }


    onsearchExpense(val) {
        this.ExpenseorReport = val.target.value;
        this.searchCard = val.target.value;
        this.checkAll = false;
        if (this.ExpenseorReport?.length >= 0 ||this.searchCard?.length >= 0) {
            this.pageNumber = 1;
            this.pageNumberTrips = 1;
            this.resetPaging();
          
            this.SearchListText.next(this.searchCard);
            this.SearchListText.next(this.ExpenseorReport);
        }
        if (this.searchExpense == "") {
            this.pageNumber = 1;
            this.resetPaging();
            if (this.matTabIndex == 0) {
                this.getreportList(false);
            } else if (this.matTabIndex == 3) {
                this.getExpenseList(false);
            } else if (this.matTabIndex == 1) {
                this.getTripsList(false);
            }else if (this.matTabIndex == 2) {
                this.isPageLoad = false;
                this.getMycardlist(false);
            }
        }
        this.expenseAndReportModel.filterBy.searchText = this.ExpenseorReport;
    }

    searchbar() {
        this.showSearch = !this.showSearch;
        if (this.searchExpense != undefined && this.searchExpense != '') {
            this.searchExpense = '';
            this.expenseAndReportModel.filterBy.searchText = '';
            this.showLoading = true;
            this.getExpenseList(false);
        }
    }
    searchbartrip() {
        this.showSearch = !this.showSearch;
        if (this.tripSearch != undefined && this.tripSearch != '') {
            this.tripSearch = '';
            this.expenseAndReportModel.filterBy.searchText = '';
            this.showLoading = true;
            this.getTripsList(false);
        }
    }

    clearSearch() {
        this.searchExpense = "";
        this.ExpenseorReport = "";
        if (this.expenseAndReportModel.filterBy.searchText?.length >= 3) {
            this.getExpenseList(false);
        }
    }
    clearSearchtrip() {
        this.approvesearch = "";
        this.ExpenseorReport = "";
        this.getTripsList(false);
        // if (this.ExpenseorReport.searchText?.length >= 3) {
        //     this.getExpenseList(false);
        // }
    }

    ngOnDestroy() {
        this.resetSubscriptions();
    }

    ionViewWillLeave() {
        clearInterval(this.downloadTime);
        this.resetSubscriptions();
        this.analyticsService.setExpStatus(undefined);
    }

    async openDateSheet(isFromDate: boolean) {
        const modal = await this.modalController.create({
            component: CustomDatePickerComponent,
            componentProps: {
                selectedDate: isFromDate ? this.frmDate : this.tDate,
                fromDate: isFromDate ? new Date('1981-01-01') : this.frmDate,
                toDate: isFromDate ? this.tDate ? this.tDate : new Date() : new Date()
            },
            cssClass: 'custom-bottom-sheet'
        });
        modal.onDidDismiss().then(result => {
            if (result.role === 'changed' && result.data) {
                if (isFromDate) {
                    this.frmDate = result.data.displayDate;
                } else {
                    this.tDate = result.data.displayDate;
                }
            }
        });
        return await modal.present();
    }

    async openModal(value: string, type: string, isCategory?: boolean) {
        const componentProps = this.getComponentProps(type);
        if (value && value !== '') {
            componentProps.value = this.filterModal[componentProps.setValueKey];
        }
        const searchItemModal = await this.modalController.create({
            component: CustomSelectDropdownComponent,
            componentProps,
            keyboardClose: false,
        });
        searchItemModal.onDidDismiss()
            .then((callback) => {
                if (callback && callback.data) {
                    // TODO give onchange methods for status and category
                    if (typeof callback.data !== 'string') {
                        this[componentProps.listName] = callback.data;
                        const arr = [];
                        if (isCategory) {
                            this.categoryName = null;
                        } else {
                            this.status = null;
                        }
                        callback.data.forEach((obj: any) => {
                            if (obj.isSelected) {
                                arr.push(obj[componentProps.itemValueField]);
                                if (isCategory) {
                                    if (this.categoryName && this.categoryName !== '') {
                                        this.categoryName = `${this.categoryName}, ${obj[componentProps.itemTextField]}`;
                                    } else {
                                        this.categoryName = obj[componentProps.itemTextField];
                                    }
                                } else {
                                    if (this.status && this.status !== '') {
                                        this.status = `${this.status}, ${obj[componentProps.itemTextField]}`;
                                    } else {
                                        this.status = obj[componentProps.itemTextField];
                                    }
                                }
                            }
                        });
                        if (arr && arr.length) {
                            this.filterModal[componentProps.setValueKey] = arr;
                        }
                    }
                }
            });
        return await searchItemModal.present();
    }

    getComponentProps(type: string) {
        switch (type) {
            case 'categoryName':
                return {
                    title: 'Categories',
                    value: null,
                    mainList: this.categoryList,
                    isMultiSelect: true,
                    itemValueField: 'categoryId',
                    itemTextField: 'categoryName',
                    listName: `categoryList`,
                    setValueKey: 'categoryIds'
                };
            case 'status':
                return {
                    title: 'Status',
                    value: null,
                    mainList: this.statusList,
                    isMultiSelect: true,
                    itemValueField: 'statusId',
                    itemTextField: 'statusDescription',
                    listName: `statusList`,
                    setValueKey: 'statusIds'
                };
            case 'claimName':
                return {
                    title: 'Reports',
                    value: null,
                    mainList: this.reportList,
                    isMultiSelect: false,
                    itemValueField: 'claimId',
                    itemTextField: 'cliamNamecode',
                    listName: `reportList`,
                    buttonName: this.translate.instant('common.save'),
                    add: {
                        buttonName: this.translate.instant('reports.addreportbutton'),
                        textFieldName: 'Report',
                        placeholder: this.translate.instant('reports.placeholdrepo')
                    }
                };
        }
    }

    async openAddToReport() {
        const componentProps = this.getComponentProps('claimName');
        const searchItemModal = await this.modalController.create({
            component: CustomSelectDropdownComponent,
            componentProps,
            keyboardClose: false,
        });
        searchItemModal.onDidDismiss()
            .then((callback) => {
                if (callback && callback.data) {
                    if (typeof callback.data !== 'string') {
                        this[componentProps.listName] = callback.data;
                        callback.data.forEach((obj: any) => {
                            if (obj.isSelected) {
                                this.addReportExpense[componentProps.itemTextField] = obj[componentProps.itemTextField];
                                this.addReportExpense[componentProps.itemValueField] = obj[componentProps.itemValueField];
                                this.reportSubmit();
                            }
                        });
                    } else {
                        // TODO change itemValueField to null after updating the api
                        this.addReportExpense.claimName = callback.data;
                        this.addReportExpense.claimId = null;
                        this.reportSubmit();
                    }
                }
            });
        return await searchItemModal.present();
    }

    resetBlur(eleInput: IonInput) {
        eleInput.getInputElement().then((ele) => ele.blur());
    }
    resetClose() {
        this.modalRef.hide();
        this.removeReportModal.skipAnomolies = false;
        this.removeReportModal.skipBudgetCheckMessage = false;
    }
    downloadCancel() {
        this.modalRef.hide();
    }
    bulkDownload(downloadOptTemp?: TemplateRef<any>, list?) {
        if (list) {
            this.dropdownValue = list;
            const config = {
                ignoreBackdropClick: true,
                class: "modal-md modal-dialog-centered",
                keyboard: false,
            };
            this.modalRef = this.modalService.show(downloadOptTemp, config);

        } else {
            if (this.selectedExpense.length == 0 || this.selectedExpense.length > 1) {
                const config = {
                    ignoreBackdropClick: true,
                    class: "modal-md modal-dialog-centered",
                    keyboard: false,
                };
                this.modalRef = this.modalService.show(this.downloadLoader, config);
                this.downloadApiCall();
            } else {
                const config = {
                    ignoreBackdropClick: true,
                    class: "modal-md modal-dialog-centered",
                    keyboard: false,
                };
                this.modalRef = this.modalService.show(downloadOptTemp, config);
            }
        }
    }

    closeDownloadOptTemp() {
        this.modalRef.hide();
    }

    downloadOptSelector(value) {
        switch (value) {
            case 'excel':
                this.showDownloadOptions = true;
                this.downloadOptionSelection = 1;
                this.downloadApiCall(null);
                break;
            case 'pdf':
                this.showDownloadOptions = true;
                this.downloadOptionSelection = 2;
                this.downloadApiCall('PDF');
                break;
        }
    }

    downloadApiCall(value?) {
        this.downloadBtn = true;
        this.isShowpopup == true
        if (this.selectedExpense.length) {
            this.downloadModel.referenceIds = this.selectedExpense.toString();
        } else {
            this.downloadModel.referenceIds = null;
        }
        if (this.dropdownValue) {
            this.downloadModel.referenceIds = this.dropdownValue.expenseId;
            // this.downloadBtn = false;
        }
        this.filterModal.recordTypes = this.fromPettyCash ? [4] : [1]
        this.filterModal.isDraft = null;
        this.downloadModel.filterBy = this.filterModal;
        this.downloadModel.userId = this.userInfo.userId;
        this.downloadModel.userGUID = this.userInfo.userGuId;
        this.downloadModel.entityType = 1;
        this.downloadModel.isAdminView = JSON.parse(sessionStorage.getItem('isadmin'));
        this.downloadModel.legalEntityId = this.userInfo.legalEntityId;
        this.downloadModel.legalEntityGUID = this.userInfo.legalEntityGuId;
        this.downloadModel.docType = value;
        this.downloadModel.isSummaryView = false;
        // this.downloadModel.viewType = this.userInfo.roleId;
        this.downloadModel.viewType = this.expenseAndReportModel.viewType;
        this.downloadModel.downloadActionType = this.matTabLabel;

        this.downloadModel.isApprover = false;
        this.commonService.downloadList(this.downloadModel).subscribe(res => {
            if (!res.error && res.payload) {
                this.downloadPoolingStart(res.payload, 3000);
            }
        });

    }

    downloadPoolingStart(request, time) {
        this.downloadActionPoolCount++;
        this.downloadModel.jobId = request.jobId;
        this.downloadModel.jobGUID = request.jobGUID;
        this.commonService.downloadpoolStart(this.downloadModel).subscribe(res => {
            if (!res.isError && !res.payload?.resultFileURL && this.downloadActionPoolCount < 20) {

                this.downloadTime = setTimeout(() => {
                    this.downloadPoolingStart(res.payload, time);
                }, time);
            } else if (!res.isError && res.payload.status == 3) {
                this.downloadBtn = false;
                window.open(res['payload']['resultFileURL'], '_self');
                this.expenseList.forEach(element => {
                    element.isSelected = false;
                });
                this.downloadOptionSelection = 0;
                this.selectedExpense = [];
                this.toaster.success(this.translate.instant('alerts.downloadsucces'), this.translate.instant('alerts.succes'));
                this.downloadActionPoolCount = 0;
                this.showDownloadOptions = false;
                this.modalRef.hide();
            } else {
                this.toaster.error(res.errorMessage, this.translate.instant('alerts.error'));
                this.downloadBtn = false;
                this.downloadOptionSelection = 0;
                this.selectedExpense = [];
                this.expenseList.forEach(element => {
                    element.isSelected = false;
                });
                this.modalRef.hide();
                this.downloadActionPoolCount = 0;
                this.showDownloadOptions = false;
            }
        });
    }

    downloadImage() {

        if (!this.proofid) {
            this.proofid = this.proofList[0].attachmentId;
        }
        let attachObj = { attachmentIds: [this.proofid] };
        this.expenseService.downloadAttachment(attachObj).subscribe(res => {
            if (!res.isError && res.errorCode === 0) {
                this.downloadProofs = res.payload.downloadFileResponseDTO;
                this.downloadProofs.forEach(element => {
                    if (element) {
                        window.open(element.url);
                    }
                });
                // this.downloadProof = res['payload']['url'];
                // window.open(this.downloadProof);
                this.toaster.success(this.translate.instant('alerts.downloadsucces'), this.translate.instant('alerts.succes'));
            } else {
                this.toaster.error(res.errorMessage, this.translate.instant('alerts.error'));
            }
        });

    }

    onSlide(item) {

        const imageIndex = parseInt(item.current.replace("slideId_", ""), 10);
        this.proofid = this.proofList[parseInt(item.current.replace("slideId_", ""), 10)].attachmentId;
        this.imgNum = imageIndex + 1;

    }

    openProofModal(proofPopup: TemplateRef<any>, list) {
        if (list.expenseAttachments[0].url.split('?')[0].split('.').pop() == 'pdf') {
            window.open(list.expenseAttachments[0].url);
        } else {

            list.expenseAttachmentsmodel.forEach(element => {
                if (element.url.split('?')[0].split('.').pop() == 'pdf') {

                } else {
                    this.proofList.push(element);
                }
            });

            const config = {
                ignoreBackdropClick: true,
                class: "modal-dialog-centered modal-lg",
                keyboard: false,

            };
            this.modalRef = this.modalService.show(proofPopup, config);
        }
    }

    resetPaging() {
        this.pageNumber = 1;
        this.expenseList = [];
        this.expenseList.length = 0;
    }

    hideUnhideViolations() {
        this.showMoreViolations = !this.showMoreViolations;
        this.displayViolations = [];
        if (this.showMoreViolations) {
            this.displayViolations = JSON.parse(JSON.stringify(this.expense?.violationList));
        } else {
            this.expense?.violationList.forEach((item, i) => {
                if (i < 4) {
                    this.displayViolations.push(item);
                }
            });
        }
    }

    goToAnalytics() {
        this.navCtrl.navigateRoot(['analytics']);
    }


    pdfchecking(value) {
        if (value.url.split('?')[0].split('.').pop() == 'pdf') {
            return 'pdf'
        } else if(value.url.split('?')[0].split('.').pop() == 'zip' || value.url.split('?')[0].split('.').pop() == 'rar') {
            return 'zip'
        } else {
            return 'png'
        }
    }


    createNewExpense(expenseSelection: TemplateRef<any>, list,index) {
        this.selecteTripIndex = index;
        if (list.serviceList.length == 0) {
            this.reportsService.getTripServiceList({
                tripId: list.tripId,
                tripGUID: list.tripGUID,
                legalEntityGuid: this.userInfo?.legalEntityGuId,
                legalEntityId: this.userInfo?.legalEntityId,
            }).subscribe((res) => {
                if (!res.isError && res.errorCode == 0 && res?.payload) {
                    console.log('sdsddsdsd',res);
                    this.tripsList[index].serviceList = res?.payload.serviceList;
                    this.tripsList[index].advanceSummary = res?.payload.advanceSummary;
                    this.tripsList[index].tripEstimateDetails = res?.payload?.tripEstimateDetails;
                }
            });
        }
        this.selectedTripDetails = list;
        this.selectedTrips = [];
        this.selectedTrips.push(list);
        this.singleSelection = true;
        this.multiSelection = false;
        const config = {
            ignoreBackdropClick: true,
            class: "modal-dialog-centered modal-lg baa",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(expenseSelection, config);
    }

    closeExpenseSelection() {
        this.modalRef.hide();
        this.associated = false;
        this.notassociated = false;
        this.selectedTrips = [];
        this.tripsList.forEach(element => {
            element.isSelected = false;
        });
    }

    associatedClick() {
        this.associated = true;
        this.notassociated = false;
    }

    notassociatedClick() {
        this.notassociated = true;
        this.associated = false;
    }

    proceed() {
        this.selectedTrips.forEach(element => {
            element.isAssociated = this.associated ? this.associated : false;
        });
        this.modalRef.hide();
        var currentIndx = 0;
        var url = 'expensesTrip';
        let navInfo = {
            url: url,
            fromPage: 'viewSlide',
            singleSelection: this.singleSelection,
            multiSelection: this.multiSelection,
            singleExpense: this.selectedTrips,
            expenseArray: this.selectedTrips,
            claimTaggedTripList: [],
            fromTrips: true,
            isAssociated: this.associated ? this.associated : false,
            isReadOnly: false,
            currentIndx: currentIndx,
            goToReportDetails: true,
            tripData: this.tripData,
            report: {
                fromMytrips: true,
                claimName: this.selectedTrips[0]?.tripName,
                claimEndDate: this.selectedTrips[0]?.tripEndDate,
                claimStartDate: this.selectedTrips[0]?.tripStartDate
            },
            advanceSummary:this.tripsList[this.selectedTripIndex]?.advanceSummary
        };
        sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
        this.navCtrl.navigateRoot(["newExpenseMileageBulk"]);
    }

    totalAmount() {
        this.tripsTotalAmount = this.selectedTrips.map(item => parseInt(item.tripCost))
            .reduce((prev, curr) => prev + curr, 0);
    }
    pdfSelectionListCall(pdfSelection: TemplateRef<any>, obj) {
        this.defaultIndex = 0;
        this._selectedExpense = obj;
        //  this.showPop = true;
        //this.getExpenseAttachments(obj);
        this.selectedExpenseids = [];
        this.selectedExpenseids.push(obj?.expenseId);
        this.selectedExpenseids = _.uniq(this.selectedExpenseids);
        this.getExpenseModels.expenseIds = this.selectedExpenseids;
        //this.getExpenseModels.expenseGUID = list.expenseGUID;
        this.getExpenseModels.legalEntityId = this.userInfo.legalEntityId;
        this.getExpenseModels.legalEntityGUID = this.userInfo.legalEntityGuId;

        this.invoiceDetails = [];
        this.invoiceDetailsForDetails = [];

        this._selectedExpense.expenseAttachments.forEach(element => {
            if (element.attachmentType == '1') {
                this.invoiceDetails.push(element);
                this.invoiceDetailsForDetails.push(element);
            }
        });

        if (this.invoiceDetails) {
            let urlStr = this.invoiceDetails[this.defaultIndex].url;
            if (urlStr.split('?')[0].split('.').pop() == 'pdf') {
                this.getPdfAsBase64(urlStr).then(base64 => {
                    this.isPdf = true;
                    this.isZip = false;
                    const linkSource = `data:application/pdf;base64,${base64}`;
                    this.pdfData = linkSource;
                })
            } else if(urlStr.split('?')[0].split('.').pop() == 'zip' || urlStr.split('?')[0].split('.').pop() == 'rar') {
                this.isZip = true;
                this.isPdf = false;
            } else {
                this.isPdf = false;
                this.isZip = false;
            }
        }
        if (!this.showPop) {
            const config = {
                ignoreBackdropClick: false,
                class: "modal-dialog-centered modal-lg baa",
                keyboard: false,
            };
            this.modalRef = this.modalService.show(pdfSelection, config);
        }

        // "legalEntityId": 396,
        // "legalEntityGUID": "9483d2d5382a1a7ee7057b2064d412cb"
        // this.expenseService.expenseAttachViewer(this.getExpenseModels).subscribe((res) => {
        //     if (!res.isError && res.errorCode === 0) {
        //         //  this.showPop = false;
        //         this.invoiceDetails = [];
        //         this.invoiceDetailsForDetails = [];
        //         // this.invoiceDetails = res.payload.attachementList;

        //         res.payload.attachementList.forEach(element => {
        //             if (element.attachmentType == '1') {
        //                 this.invoiceDetails.push(element);
        //                 this.invoiceDetailsForDetails.push(element);
        //             }
        //         });


        //         // this.invoiceDetailsForDetails = res.payload.attachementList;
        //         if (this.invoiceDetails) {
        //             let urlStr = this.invoiceDetails[this.defaultIndex].url;
        //             if (urlStr.split('?')[0].split('.').pop() == 'pdf') {
        //                 this.isPdf = true;
        //                 const linkSource = `data:application/pdf;base64,${this.invoiceDetails[this.defaultIndex].base64}`;
        //                 this.pdfData = linkSource;
        //             } else {
        //                 this.isPdf = false;
        //             }
        //         }
        //         if (!this.showPop) {
        //             const config = {
        //                 ignoreBackdropClick: false,
        //                 class: "modal-dialog-centered modal-lg baa",
        //                 keyboard: false,
        //             };
        //             this.modalRef = this.modalService.show(pdfSelection, config);
        //         }


        //     } else if (res.isError && res.errorCode === 2) {
        //     }
        // });

    }

    pdfSelectionCall(pdfSelection: TemplateRef<any>, attachments, trip, type, attachmentIndex?) {
        this.defaultIndex = attachmentIndex || 0;
        // const linkSource = `data:application/pdf;base64,${this.invoiceDetails[this.defaultIndex].base64}`;
        // this.pdfData =  linkSource;
        if (type == 1) {
            this.invoiceDetails = [];
            this.invoiceDetailsForDetails.forEach(element => {
                if (element.referenceId == trip.tripId && (element.attachmentType == "1" || element.attachmentType == "2")) {
                    this.invoiceDetails.push(element);
                }
            });
        } else {
            this.invoiceDetails = [];
            this.invoiceDetailsForDetails.forEach(element => {
                if (element.attachmentType == "1") {
                    this.invoiceDetails.push(element);
                }
            });
        }

        let urlStr = this.invoiceDetails[this.defaultIndex].url;
        if (urlStr.split('?')[0].split('.').pop() == 'pdf') {
            // this.isPdf = true;
            // const linkSource = `data:application/pdf;base64,${this.invoiceDetails[this.defaultIndex].base64}`;
            // this.pdfData = linkSource;
            this.getPdfAsBase64(urlStr).then(base64 => {
                this.isPdf = true;
                this.isZip = false;
                const linkSource = `data:application/pdf;base64,${base64}`;
                this.pdfData = linkSource;
            })
        } else if(urlStr.split('?')[0].split('.').pop() == 'zip' || urlStr.split('?')[0].split('.').pop() == 'rar') {
            this.isZip = true;
            this.isPdf = false;
        } else {
            this.isPdf = false;
            this.isZip = false;
        }

        const config = {
            ignoreBackdropClick: false,
            class: "modal-dialog-centered modal-lg baa",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(pdfSelection, config);
    }
    closeSelection() {
        this.defaultIndex = 0;
        this.modalRef.hide();
    }

    addNewReportweb() { this._addReport = true; }
    closeReport() { this._addReport = false; }

    prevBtn() {

        if (this.defaultIndex != 0) {
            this.defaultIndex = this.defaultIndex - 1;
            let urlStr = this.invoiceDetails[this.defaultIndex].url;
            if (urlStr.split('?')[0].split('.').pop() == 'pdf') {
                this.isPdf = true;
                this.isZip = false;
                const linkSource = `data:application/pdf;base64,${this.invoiceDetails[this.defaultIndex].base64}`;
                this.pdfData = linkSource;
            } else if(urlStr.split('?')[0].split('.').pop() == 'zip' || urlStr.split('?')[0].split('.').pop() == 'rar') {
                this.isZip = true;
                this.isPdf = false;
            } else {
                this.isPdf = false;
                this.isZip = false;
            }
            this.zoom = 1.0;
        }

    }

    nextBtn() {

        if ((this.defaultIndex + 1) == this.invoiceDetails.length) {

        } else {
            this.defaultIndex = this.defaultIndex + 1;
            let urlStr = this.invoiceDetails[this.defaultIndex].url;

            if (urlStr.split('?')[0].split('.').pop() == 'pdf') {
                // this.isPdf = true;
                // const linkSource = `data:application/pdf;base64,${this.invoiceDetails[this.defaultIndex].base64}`;
                // this.pdfData = linkSource;

                this.getPdfAsBase64(urlStr).then(base64 => {
                    this.isPdf = true;
                    this.isZip = false;
                    const linkSource = `data:application/pdf;base64,${base64}`;
                    this.pdfData = linkSource;
                })
            } else if(urlStr.split('?')[0].split('.').pop() == 'zip' || urlStr.split('?')[0].split('.').pop() == 'rar') {
                this.isZip = true;
                this.isPdf = false;
            } else {
                this.isPdf = false;
                this.isZip = false;
            }
            this.zoom = 1.0;
        }

    }
    downloadBtnCall() {

        // if (!this.proofid) {
        //     this.proofid = this.invoiceDetails[this.defaultIndex].attachmentId;
        // }
        let attachObj = { attachmentIds: [this.invoiceDetails[this.defaultIndex].attachmentId] };
        this.expenseService.downloadAttachment(attachObj).subscribe(res => {
            if (!res.isError && res.errorCode === 0) {
                this.downloadProofs = res.payload.downloadFileResponseDTO;
                this.downloadProofs.forEach(element => {
                    if (element) {
                        window.open(element.url);
                    }
                });
                //window.open(this.downloadProof);
                this.toaster.success(this.translate.instant('alerts.downloadsucces'), this.translate.instant('alerts.succes'));
            } else {
                this.defaultIndex = this.defaultIndex + 1;
                let urlStr = this.invoiceDetails[this.defaultIndex].url;

                if (urlStr.split('?')[0].split('.').pop() == 'pdf') {
                    this.isPdf = true;
                    this.isZip = false;
                    const linkSource = `data:application/pdf;base64,${this.invoiceDetails[this.defaultIndex].base64}`;
                    this.pdfData = linkSource;
                } else if(urlStr.split('?')[0].split('.').pop() == 'zip' || urlStr.split('?')[0].split('.').pop() == 'rar') {
                    this.isZip = true;
                    this.isPdf = false;
                } else {
                    this.isPdf = false;
                    this.isZip = false;
                }
            }
        });
    }

    tripsPdfCall(pdfSelection1: TemplateRef<any>, trip) {
        this.selectedTripInvoiceArray = [];
        this.pdfloader = true;
        this.getTripDetails(trip);
        const config = {
            ignoreBackdropClick: true,
            class: "modal-dialog-centered modal-lg baa",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(pdfSelection1, config);
    }

    getTripDetails(expenseDetails) {
        // this.defaultBoolean = true;
        let getTripModl = new GetTripInfo();
        getTripModl.legalEntityId = this.userInfo.legalEntityId;
        getTripModl.legalEntityGUID = this.userInfo.legalEntityGuId;
        getTripModl.requestId = expenseDetails.requestId;
        getTripModl.requestGUID = expenseDetails.requestGUID;
        getTripModl.productId = expenseDetails.productId;
        getTripModl.tripId = expenseDetails.tripId;
        getTripModl.tripGUID = expenseDetails.tripGUID;
        this.reportsService.getTripDetails(getTripModl).subscribe((res) => {
            if (!res.isError && res.errorCode === 0 && res?.payload) {
                if (expenseDetails.productId == "1") {
                    res?.payload.flightDetails.flights.forEach((element) => {
                        element.invoiceDetails?.forEach((innerelement) => {
                            if (innerelement.base64Invoice != null && innerelement.invoiceUrl != null) {
                                let object_data = {
                                    fileName: "file." + innerelement.invoiceUrl.split("?")[0].split(".").pop(),
                                    content: innerelement.base64Invoice,
                                    url: innerelement.invoiceUrl,
                                    isDeleted: "",
                                    remarks: expenseDetails.productName,
                                    referenceId: expenseDetails.tripId,
                                };
                                // this.expenseModel.expenseAttachments.push(object_data);
                                this.selectedTripInvoiceArray.push(object_data);
                            }

                        });
                    });
                }
                this.pdfloader = false;
            }
        });
    }

    returnExtension(filename) {
        let text = filename.split("?")[0].split(".").pop();
        return text.toLowerCase();
    }

    base64dataRe(incomingD) {
        const linkSource = `data:application/pdf;base64,${incomingD}`;
        return linkSource;
    }

    preBtn() {
        if (this.defaultIndex != 0) {
            this.defaultIndex = this.defaultIndex - 1;

            let urlStr = this.invoiceDetails[this.defaultIndex].url;
            if (urlStr.split('?')[0].split('.').pop() == 'pdf') {
                // this.isPdf = true;
                // const linkSource = `data:application/pdf;base64,${this.invoiceDetails[this.defaultIndex].base64}`;
                // this.pdfData = linkSource;
                this.getPdfAsBase64(urlStr).then(base64 => {
                    this.isPdf = true;
                    this.isZip = false;
                    const linkSource = `data:application/pdf;base64,${base64}`;
                    this.pdfData = linkSource;
                })
            } else if(urlStr.split('?')[0].split('.').pop() == 'zip' || urlStr.split('?')[0].split('.').pop() == 'rar') {
                this.isZip = true;
                this.isPdf = false;
            } else {
                this.isPdf = false;
                this.isZip = false;
            }
        }
    }
    selectAllFilterStatus(e) {
        if (e.detail.checked) {
            this.filterListStatus.forEach(element => {
                element.isSelected = true;
            });
        } else {
            this.filterListStatus.forEach(element => {
                element.isSelected = false;
            });
        }
    }
    selectAllTripProducts(e) {
        if (e.detail.checked) {
            this.filterCategoryList.forEach(element => {
                element.isSelected = true;
            });
        } else {
            this.filterCategoryList.forEach(element => {
                element.isSelected = false;
            });
        }

    }
    getFiltertodate(e) {
        this.tDate = e.target.value;
        const date = this.datechangeService.convertCalendarToAPI(e.target.value);
        this.tripfilterModal.toDate = date;
        this.maxDate = new Date(this.tDate);
    }
    getFiltefromrDate(e) {
        this.frmDate = e.target.value;
        const date = this.datechangeService.convertCalendarToAPI(e.target.value);
        this.tripfilterModal.fromDate = date;
        this.minDate = new Date(this.frmDate);
    }
    changeFilterOptions(minValue, maxValue) {
        const newOptions: Options = Object.assign({}, this.options);
        newOptions.floor = minValue;
        newOptions.ceil = maxValue;
        this.minPrice = minValue;
        this.maxPrice = maxValue;
        this.options = newOptions;
    }
    resetFilterAmount() {
        // this.tripfilterModal.minPrice = this.minAndMax.min;
        // this.tripfilterModal.maxPrice = this.minAndMax.max;
    }
    // applyTripfilter() {
    //     this.showFilter = false;
    //     this.resetFilterPaging();
    //     if (this.frmDate !== undefined && this.tDate === undefined) {
    //         this.tDate = this.mobMaxDate;
    //         this.tripfilterModal.toDate = moment(new Date()).format(Constants.mobileDateFormat);
    //     }
    //     if (this.frmDate === undefined && this.tDate !== "") {
    //         this.frmDate = this.tDate;
    //         this.tripfilterModal.fromDate = this.tDate;
    //     }
    //     this.tripfilterModal.fromDate = this.frmDate ? moment(new Date(this.frmDate)).format(Constants.mobileDateFormat) : undefined;
    //     this.tripfilterModal.toDate = this.tDate ? moment(new Date(this.tDate)).format(Constants.mobileDateFormat) : undefined;
    //     this.getTripsList(false, '');
    //     this.returnExpSegment();
    // }

    applyTripfilter(closeModal?, removeAmount?) {
        this.showFilter = false;
        // this.filterMinPrice = removeAmount ? undefined : this.tripfilterModal.minPrice;
        // this.filterMaxPrice = removeAmount ? undefined : this.tripfilterModal.maxPrice;
        if (this.frmDate && this.tDate) {
            this.filterFromDate = this.frmDate;
            this.filterToDate = this.tDate;
        } else if (this.frmDate && !this.tDate) {
            this.filterFromDate = this.frmDate;
            this.filterToDate = moment().format();
        } else if (!this.frmDate && this.tDate) {
            this.filterFromDate = this.tDate;
            this.filterToDate = this.tDate;
        }
        this.filterListStatus.forEach(s => {
            if (s.isSelected) {
                s.showInFilter = true;
            } else {
                s.showInFilter = false;
            }
        })
        this.filterCategoryList.forEach(s => {
            if (s.isSelected) {
                s.showIncatFilter = true;
            } else {
                s.showIncatFilter = false;
            }
        })
        this.showCreallAllButtonTrips();

        //this.resetPaging();
        // if (this.frmDate !== undefined && this.tDate == undefined) {
        //   this.tDate = this.maxDate;
        //   this.tripfilterModal.toDate = moment(new Date()).format(Constants.mobileDateFormat);
        // }
        // if (this.frmDate == undefined && this.tDate !== undefined) {
        //   this.frmDate = this.tDate;
        //   this.tripfilterModal.fromDate = this.tDate;
        // }
        this.tripfilterModal.fromDate = this.frmDate ? moment(new Date(this.frmDate)).format(Constants.mobileDateFormat) : undefined;
        this.tripfilterModal.toDate = this.tDate ? moment(new Date(this.tDate)).format(Constants.mobileDateFormat) : undefined;
        this.maxDate = new Date();
        if (closeModal) {
            this.modalRef?.hide();
        }

        this.getTripsList(false, '');
        this.returnExpSegment();
    }


    priceSliderFilter(changeContext: ChangeContext) {
        // this.tripfilterModal.minPrice = changeContext.value;
        // this.tripfilterModal.maxPrice = changeContext.highValue;
    }
    resetFilterPaging() {
        this.tripsList = [];
        this.tripsList.length = 0;
    }
    selectSingleProduct(list) {
        if (list.isSelected) {
            this.selectedTripCategory.push(list.productId);
        } else {
            const index = _.indexOf(this.selectedTripCategory, list.productId);
            this.selectedTripCategory.splice(index, 1);
        }
        this.tripfilterModal.productIds = this.selectedTripCategory;
        if (this.filterCategoryList.length !== this.selectedTripCategory.length && this.selectedTripCategory.length > 0) {
            this.checkAllprod = false;
            this.tripCategoryState = true;
        } else if (this.selectedTripCategory.length === 0) {
            this.checkAllprod = false;
            this.tripCategoryState = false;
        } else {
            this.checkAllprod = true;
            this.tripCategoryState = false;
        }

    }
    resetFilters(list) {
        this.statusAll = false;
        list.forEach((item) => {
            item.isSelected = false;
        });
    }
    resetTripFilters(list) {
        this.isSelected = false;
        list.forEach((item) => {
            item.isSelected = false;
        });
    }
    resetProductFilters(list) {
        this.checkAllprod = false;
        list.forEach((item) => {
            item.isSelected = false;
        });
    }

    showReportDetail(list, index?) {
        this.currentClaimIndex = index || 0;
        this.selectAllreports();
        this.indeterminateState = false;
        this.selectedReport = [];
        this.detailReport = list;
        this.showDetailreport = true;
        sessionStorage.setItem('selected-value', JSON.stringify(this.detailReport));
        sessionStorage.setItem('selectedValue', JSON.stringify(this.detailReport));
        let approvalData = {
            'position': this.matTabIndex,
            'approveList': this.reportList,
            'index': index,
        }
        sessionStorage.setItem('approvalData', JSON.stringify(approvalData));

        this.hideReporttable = false;
        this.tableHide = false;
        const current_modalObj = {
            name: 'claim_detailview',
            data: {
                currentClaimIndex: this.currentClaimIndex,
                reportListdata: this.reportListdata,
                detailReport: this.detailReport,
                fromPage: this.fromPage,
            }
        }
        sessionStorage.setItem('current_modal', JSON.stringify(current_modalObj));
        // this.router.navigate([`expenses/${list.claimId}/${list.claimGUID}`]);
    }
    backToreport(event?) {
        this.tableHide = true;
        this.hideReporttable = true;
        this.showDetailreport == false;
        this.showMobileDetails = true;

        if (this.matTabIndex == 0 && event) {
            this.reportList.splice(this.currentClaimIndex, 1);
        }
        const current_modalObj = {
            name: 'list',
            data: {}
        }
        sessionStorage.setItem('current_modal', JSON.stringify(current_modalObj));
    }
    getreportList(isPageLoad, event?) {
        this.isPageLoad = isPageLoad;
        if (this.pageNumber == 1) {
            this.showLoading = true;
            this.reportList = [];
        }
        this.expenseAndReportModel.recordType = this.fromPettyCash ? 4 : 1;
        if (JSON.parse(sessionStorage.getItem('isadmin')) == true) {
            this.expenseAndReportModel.viewType = this.userInfo.roleId;
        } else {
            this.expenseAndReportModel.viewType = 1;
        }
        if (this.userInfo.roleId == 2 && this.expenseAndReportModel.viewType == 2) {
            this.expenseAndReportModel.isAdminView = JSON.parse(sessionStorage.getItem('isadmin'));
        } else {
            this.expenseAndReportModel.isAdminView = false;
        }

        this.expenseAndReportModel.legalEntityId = this.userInfo.legalEntityId;
        this.expenseAndReportModel.legalEntityGUID = this.userInfo.legalEntityGuId;
        this.expenseAndReportModel.userId = this.userInfo.userId;
        this.expenseAndReportModel.userGUID = this.userInfo.userGuId;
        this.expenseAndReportModel.filterBy = this.filterModal;
        this.expenseAndReportModel.filterBy.searchText = this.ExpenseorReport;
        this.expenseAndReportModel.filterBy.isDraft = this.platformType == 2 ? this.draft : null;
        this.expenseAndReportModel.pageNumber = this.pageNumber;
        this.expenseAndReportModel.pageSize = this.pageSize;
        this.isApicallinProgress = true;
        this.reportsService
            .reportList(this.expenseAndReportModel)
            .subscribe((res) => {
                if (!res.isError && res.errorCode === 0) {
                    if (this.pageNumber == 1) {
                        this.reportList = [];
                    }
                    this.showLoading = false;
                    this.totalRecords = res.payload.reportsCount;
                    this.isReportCreated = res.payload.isReportCreated;
                    this.minAndMax = res.payload;
                    this.changeclaimOptions(
                        res.payload.reportMinAmount,
                        res.payload.reportMaxAmount
                    );
                    const listarray = res.payload.reportResponseList;
                    this.reportList = this.reportList.concat(listarray.map((list) => new ReportListResModel(list)));
                    this.dummyListLength = this.reportList?.length;
                    this.setDummyList();
                    // this.setDummyList();
                    if (this.reportListdata?.length == 0) {
                        // this.reportListdata = this.reportList.concat(listarray.map((list) => new ReportListResModel(list)));
                        this.reportListdata = [...this.reportList];
                    }

                    this.reportList.forEach(element => {
                        if (element.hasViolation) {
                            this.showViolationCheckBox = true;

                        }else{
                            this.showViolationCheckBox =false;
                        }
                    });
                    if (this.platformType == 1) {
                        if (this.pageNumber == 1 && this.isPageLoad == true && this.isReportCreated == true) {
                            this.isPageLoad = false;
                        }
                    } else {
                        this.isPageLoad = false;
                    }
                    if (this.draft) {
                        this.draftCount = res.payload.reportsCount;
                    } else {
                        this.submittedCount = res.payload.reportsCount;
                    }
                    this.selectedReport = [];
                    this.selectedStatus = [];
                    this.selectedList = [];
                    if (event) {
                        event.target?.complete();
                    }
                    if (res.payload.reportResponseList.length == 0 && event?.target) {
                        event.target.disabled = true;
                    }
                    this.isApicallinProgress = false;

                    let val = JSON.parse(sessionStorage.getItem("fromPathInfo"));
                    if (val?.url && val?.url == 'reports' && val?.goToReportDetails == true && val?.detailReport) {
                        let ind = this.reportList.findIndex(ele => ele.claimId == (val?.detailReport?.claimId));
                        if (ind != -1) {
                            this.showReportDetail(this.reportList[ind]);
                        }
                        sessionStorage.setItem('fromPathInfo', null);
                    }

                } else {
                    this.selectedList = [];
                    this.selectedStatus = [];
                    this.selectedReport = [];
                    this.isApicallinProgress = false;
                    this.showLoading = false;
                    this.toaster.error(res.errorMessage, this.translate.instant('alerts.error'));
                    if (event && event.target) {
                        event.target.disabled = true;
                    }
                }
            });
    }
    openNewclaim(editreport?) {

        console.log('edit report', editreport);
        sessionStorage.setItem('fromPettyCash', String(this.fromPettyCash));

        if (this.matTabIndex == 2 && this.mycardslist.length != 0) {
            this.selectedMycards = _.filter(this.mycardslist, { showCheckbox: true });
        }
        this.selectedMycards.forEach(s => {
            if(s.transactionType == 2) {
                s.amount = -s.amount;
            }
        })

        if(this.selectedMycards.length == 0) {
            const config = {
                ignoreBackdropClick: true,
                class: "modal-lg modal-dialog-centered",
                keyboard: false
            };
            this.modalRef = this.modalService.show(this.oneCard, config);
            return;
        }

        if (this.selectedMycards.length != 0) {
            let navInfo = {
                url: 'newreport',
                mycards: this.selectedMycards,
                claimTaggedTripList: [],
                expenseList: []

            };
            sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
            this.navCtrl.navigateRoot(["newreport"]);
            // this.navCtrl.navigateRoot(["newExpenseMileageBulk"]);
        } else {
            let navInfo = {
                url: 'reports',
                report: editreport
            };
            sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
            if (this.fromPettyCash) {
                this.navCtrl.navigateRoot(["petty-cash/new-report"]);
            } else {
                this.navCtrl.navigateRoot(["newreport"]);
            }
        }
    }
    // ONEEEE
    openNewclaim1(list1){
        this.selectedMycards = [];
        this.selectedMycards.push(list1);
        this.selectedMycards.forEach(s => {
            if(s.transactionType == 2) {
                s.amount = -s.amount;
            }
        })
        let navInfo = {
            url: 'newreport',
            openTabIndex: 0,
            mycards:this.selectedMycards,
            claimTaggedTripList:[],
            expenseList: []
        };
        sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
        sessionStorage.setItem('fromPettyCash', JSON.stringify(this.fromPettyCash));
        this.navCtrl.navigateRoot(["newreport"]);
        // if(this.isMobileDevice) {
        //     this.navCtrl.navigateRoot(["newExpenseMileageBulk"]);
        // } else {
        //     this.openExpenseCreationForm();
        // }
    }
    isMobileDevice: boolean = false;
    async openExpenseCreationForm() {
        const modal = await this.modalController.create({
            component: ExpenseAndMileageComponent,
            cssClass: "auto-height"
        });
        modal.onDidDismiss().then(res => {
        })
        return await modal.present();
    }

    reportVal(e) {
        this.ExpenseorReport = e.target.value;
        this.checkAll = false;
        if (this.ExpenseorReport?.length >= 0) {
            this.resetPaging();
            this.SearchListText.next(this.ExpenseorReport);
        }
        if (this.ExpenseorReport === "") {
            this.resetPaging();
            this.getreportList(false);
        }
    }
    addSearchBar() {
        this.searchBar = !this.searchBar;
    }

    approvalSearch(val) {
        this.ExpenseorReport = val.currentTarget.value.toLowerCase();
        if (this.ExpenseorReport?.length >= 0) {
            this.resetPage();
            this.SearchListText.next(this.ExpenseorReport);
        }
        if (this.ExpenseorReport) {
            this.resetPage();
            this.getTripsList(true, val);
        }
    }
    resetPage() {
        this.pageNumber = 1;
        this.tripsList = [];
        this.tripsList.length = 0;
    }

    searchReport() {
        this.getreportList(false);
    }
    searchbarclaim() {
        this.showSearch = !this.showSearch;
    }

    selectSinglereport(list, e) {
        list.isSelected = e.target.checked;
        if (list.isSelected) {
            this.selectedReport.push(list.claimId);
        } else {
            let index = _.indexOf(this.selectedReport, list.claimId);
            this.selectedReport.splice(index, 1);
        }

        this.downloadModel.referenceIds = this.selectedReport.toString();
        if (list.isSelected && list.status == 1) {
            this.submitBtn = true;
            this.reportDraftArray.push(list.claimId);
            this.removeReportModal.claimIds = this.reportDraftArray;
        } else {
            let index = _.indexOf(this.reportDraftArray, list.claimId);
            this.reportDraftArray.splice(index, 1);
        }
        if (
            this.reportList.length != this.selectedReport.length &&
            this.selectedReport.length > 0
        ) {
            this.checkAll = false;
            this.indeterminateState = true;
        } else if (this.selectedReport.length == 0) {
            this.checkAll = false;
            this.indeterminateState = false;
        } else {
            this.checkAll = true;
            this.indeterminateState = false;
        }
    }

    selectAllreports(event?) {
        setTimeout(() => {
            this.reportList.forEach((item) => {
                item.isSelected = event ? false : this.checkAll;
            });
        });
    }

    removeReport(list?) {
        this.removeReportPopUp(list);
    }

    removeReportModal = new RemoveReportModal();
    async removeReportPopUp(list?) {

        const alert = await this.alertController.create({
            mode: "md",
            message: this.translate.instant('alerts.reportdelete'),
            backdropDismiss: false,
            buttons: [
                {
                    text: this.translate.instant('common.cancel'),
                    role: "cancel",
                    handler: () => {
                    },
                },
                {
                    text: this.translate.instant('common.delete'),
                    handler: () => {
                        this.zone.run(() => {
                            this.removeReportModal.legalEntityId = this.userInfo.legalEntityId;
                            this.removeReportModal.legalEntityGUID = this.userInfo.legalEntityGuId;
                            this.removeReportModal.userId = this.userInfo.userId;
                            this.removeReportModal.userGUID = this.userInfo.userGuId;
                            this.removeReportModal.recordType = list?.recordType;
                            this.removeReportModal.categoryType = 3;
                            if (list) {
                                this.selectedReport.push(list.claimId);
                            }
                            this.removeReportModal.claimIds = this.selectedReport;
                            this.deltbuttonDisabled = true;
                            this.reportsService.removeReport(this.removeReportModal).subscribe((data) => {
                                if (!data.isError && data.errorCode == 0) {
                                    this.deltbuttonDisabled = false;
                                    this.checkAll = false;
                                    this.indeterminateState = false;

                                    this.toastr.success(data?.payload, this.translate.instant('alerts.succes'));
                                    this.reportDraftArray = [];
                                    this.getreportList(false);
                                } else if (data.isError && data.errorCode == 2) {
                                    this.deltbuttonDisabled = false;
                                    this.toastr.error(data.errorMessage, this.translate.instant('alerts.error'));
                                }
                            });
                        });
                    },
                },
            ],
        });

        await alert.present();
    }


    filterToDate: any;
    filterFromDate: any;
    filterMaxPrice: any;
    filterMinPrice: any;
    applyClaimFilter(closeModal?, removeAmount?) {
        this.filterMinPrice = removeAmount ? undefined : this.filterModal.minPrice;
        this.filterMaxPrice = removeAmount ? undefined : this.filterModal.maxPrice;

        if (this.frmDate && this.tDate) {
            this.filterFromDate = this.frmDate;
            this.filterToDate = this.tDate;
        } else if (this.frmDate && !this.tDate) {
            this.filterFromDate = this.frmDate;
            this.filterToDate = moment().format();
        } else if (!this.frmDate && this.tDate) {
            this.filterFromDate = this.tDate;
            this.filterToDate = this.tDate;
        }
        this.statusList.forEach(s => {
            if (s.isSelected) {
                s.showInFilter = true;
            } else {
                s.showInFilter = false;
            }
        })

        this.categoryList.forEach(c => {
            if (c.isSelected) {
                c.showInFilter = true;
            } else {
                c.showInFilter = false;
            }
        })

        this.showCreallAllButton();

        this.showFilter = false;
        this.showRepList = true;
        this.showRepFilter = false;
        this.resetPaging();
        // if (this.frmDate !== undefined && this.tDate == undefined) {
        //     this.tDate = this.maxDate;
        //     this.filterModal.toDate = moment(new Date()).format(Constants.mobileDateFormat);
        // }
        // if (this.frmDate == undefined && this.tDate !== undefined) {
        //     this.frmDate = this.tDate;
        //     this.filterModal.fromDate = this.tDate;
        // }
        this.filterModal.fromDate = this.frmDate ? moment(new Date(this.frmDate)).format(Constants.mobileDateFormat) : undefined;
        this.filterModal.toDate = this.tDate ? moment(new Date(this.tDate)).format(Constants.mobileDateFormat) : undefined;
        this.filterModal.claimTypeId = this.filterClaimTypeId;
        this.filterModal.claimTypeName = this.filterClaimTypeName;
        if (this.matTabIndex == 0) {
            this.getreportList(false);
        } else if (this.matTabIndex == 3) {
            this.getExpenseList(false);
        }
        this.maxDate = new Date();
        if (closeModal) {
            this.modalRef?.hide();
        }
    }
    changeclaimOptions(minValue, maxValue) {
        const newOptions: Options = Object.assign({}, this.options);
        newOptions.floor = minValue;
        newOptions.ceil = maxValue;
        this.minPrice = minValue;
        this.maxPrice = maxValue;
        this.options = newOptions;
    }
    resetAmountclaim() {
        this.filterModal.minPrice = this.minAndMax.reportMinAmount;
        this.filterModal.maxPrice = this.minAndMax.reportMaxAmount;
    }

    resetClaimType(removeFilter?) {
        this.filterModal.claimTypeId = null;
        this.filterModal.claimTypeName = null;
        this.filterClaimTypeId = null;
        this.filterClaimTypeName = null;
        if(removeFilter) {
            this.applyClaimFilter(true);
        }
    }

    setFilterClaimType(type) {
        this.filterClaimTypeId = type.id;
        this.filterClaimTypeName = type.claimType;
        this.filterModal.claimTypeId = this.filterClaimTypeId;
        this.filterModal.claimTypeName = this.filterClaimTypeName;
    }



    submitSingleReport(claimId, AcknologmentForm?: TemplateRef<any>) {
        this.isErrorcode10 = false;
        this.budgetCategoryItems = [];
        this.anomaliesCategoryItems = [];
        this.selectedReport = [];
        this.selecteClaimId = claimId;
       this.showViolationCheckBox = claimId.hasViolation;
        // if(this.userInfo.isEnableBudgetCheck && this.userInfo.isEnableBudgetUser){
        //     this.Confirmacknologment(AcknologmentForm,claimId);
        // }else{
            const config = {
                ignoreBackdropClick: true,
                class: "modal-lg modal-dialog-centered",
                keyboard: false,
                claimId: claimId,
            };
            this.modalRef = this.modalService.show(AcknologmentForm, config);
        // }
    }
    addExpenseModal(addExpensetemp, report) {
        let navInfo = {
            url: 'reports',
            report: report
        };
        sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
        this.navCtrl.navigateRoot(['newExpenseMileageBulk']);
    }

    submitReport() {
        this.reportSubmitPopUp();
    }

    async reportSubmitPopUp() {
        const alert = await this.alertController.create({
            mode: "md",
            //         message: `<b>${this.translate.instant('alerts.submitto')} <hr class="mt-1"> ${this.userInfo?.reportingManagerId === null ? `` : `[${this.userInfo?.reportingManagerId}]`}  ${this.userInfo?.reportingManager ? this.userInfo?.reportingManager : this.translate.instant('alerts.manager')} </b>?<br>
            //   <small>${this.userInfo?.reportingManagerEmail ? this.userInfo?.reportingManagerEmail : ''}</small>
            //   <hr class="mt-2 mb-3">`,
            message: this.translate.instant('alerts.confirmSubmit'),
            backdropDismiss: false,
            buttons: [
                {
                    text: this.translate.instant('common.cancel'),
                    role: "cancel",
                    handler: () => {
                    },
                },
                {
                    text: this.translate.instant('common.submit'),
                    handler: () => {
                        this.zone.run(() => {
                            this.removeReportModal.userId = this.userInfo.userId;
                            this.removeReportModal.userGUID = this.userInfo.userGuId;
                            this.removeReportModal.legalEntityId = this.userInfo.legalEntityId;
                            this.removeReportModal.legalEntityGUID = this.userInfo.legalEntityGuId;
                            this.removeReportModal.claimIds = this.selectedReport;
                            this.buttonDisabled = true;
                            this.reportsService
                                .draftReport(this.removeReportModal)
                                .subscribe((res) => {
                                    if (!res.isError && res.errorCode == 0) {
                                        this.buttonDisabled = false;
                                        this.reportDraftArray = [];
                                        this.selectedReport = [];
                                        this.indeterminateState = false;
                                        // if (this.platformType == 2) {
                                        //     this.presentAlert();
                                        // }
                                        this.checkAll = false;
                                        this.getreportList(false);

                                        this.toastr.success(res?.payload, this.translate.instant('alerts.succes'));
                                    } else if (res.isError == true) {
                                        this.buttonDisabled = false;
                                        this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
                                    }
                                });
                        });
                    },
                },
            ],
        });

        await alert.present();
    }

    closeModal() {
        this.dateRangeModalRef?.hide();
    }

    tripsDwnladDateSelection(tripdownloadDateTemp?: TemplateRef<any>) {
        this.downloadForm.reset();
        this.filterModal.fromDate = null;
        this.filterModal.toDate = null;
        const config = {
            ignoreBackdropClick: true,
            class: "modal-md modal-dialog-centered",
            keyboard: false,
        };
        this.dateRangeModalRef = this.modalService.show(tripdownloadDateTemp, config);
    }

    checkDateFormat(date) {
        let cleanedDate = date?.toString().replace(/\(.*\)/, '').trim();
        return (!moment.utc(cleanedDate, 'DD-MM-YYYY', true).isValid() && !moment(cleanedDate, 'ddd MMM DD YYYY HH:mm:ss ZZ', true).isValid())
    }

    downloadTripsData() {
        if(this.downloadForm.invalid) {
            return;
        }

        this.dateRangeModalRef?.hide();

        let fDate = this.downloadForm.value?.fromDate;
        let tDate = this.downloadForm.value?.toDate;
        if(fDate && !moment.utc(fDate, 'DD-MM-YYYY', true).isValid() || moment.isMoment(fDate) || this.checkDateFormat(fDate)) {
            this.filterModal.fromDate = moment(fDate).format('DD-MM-YYYY');
        }
        if(tDate && !moment.utc(tDate, 'DD-MM-YYYY', true).isValid() || moment.isMoment(tDate) || this.checkDateFormat(tDate)) {
            this.filterModal.toDate = moment(tDate).format('DD-MM-YYYY');
        }

        const config = {
            ignoreBackdropClick: true,
            class: "modal-md modal-dialog-centered",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(this.downloadLoader, config);
        this.claimdownloadApiCall(null, null, true);
    }

    async dateRange() {
        const modal = await this.modalController.create({
            component: DateRangeComponent,
            cssClass: "date-range",
            presentingElement: this.routerOutlet.nativeEl,
            backdropDismiss: false
        });
        modal.onDidDismiss().then(res => {
            if(res?.data) {
                this.filterModal.fromDate = res?.data?.fDate;
                this.filterModal.toDate = res?.data?.tDate;
                this.claimbulkDownload();
            }
            this.downloadBtn = false;
        })
        return await modal.present();
    }


    claimbulkDownload(claimdownloadOptTemp?: TemplateRef<any>, list?) {

        console.log('sdsd',this.selectedReport.length, list);
        if (list) {
            this.selReport = list
            this.dropdownValue = list;
            const config = {
                ignoreBackdropClick: true,
                class: "modal-md modal-dialog-centered",
                keyboard: false,
            };
            this.modalRef = this.modalService.show(claimdownloadOptTemp, config);

        } else {
            if (this.selectedReport.length == 0 || this.selectedReport.length > 1) {
                const config = {
                    ignoreBackdropClick: true,
                    class: "modal-md modal-dialog-centered",
                    keyboard: false,
                };
                this.modalRef = this.modalService.show(this.downloadLoader, config);
                this.claimdownloadApiCall();
            } else {
                const config = {
                    ignoreBackdropClick: true,
                    class: "modal-md modal-dialog-centered",
                    keyboard: false,
                };
                this.modalRef = this.modalService.show(claimdownloadOptTemp, config);
            }
        }


    }

    claimdownloadPoolingStart(request, time) {
        this.downloadActionPoolCount++;
        this.downloadModel.jobId = request.jobId;
        this.downloadModel.jobGUID = request.jobGUID;
        this.commonService.downloadpoolStart(this.downloadModel).subscribe(res => {
            if (!res.isError && !res.payload?.resultFileURL && this.downloadActionPoolCount < 20) {
                this.downloadTime = setTimeout(() => {
                    this.downloadPoolingStart(res.payload, time);
                }, time);
            } else if (!res.isError && res.payload.status == 3) {
                this.downloadBtn = false;
                this.showDownloadOptions = false;
                this.selectedReport = [];
                this.dropdownValue = null;
                this.reportList.forEach(element => {
                    element.isSelected = false;
                });
                this.downloadActionPoolCount = 0;
                window.open(res['payload']['resultFileURL'], '_self');
                this.toaster.success('Download successfully', 'Success');
                this.modalRef.hide();
            } else {
                this.downloadBtn = false;
                this.showDownloadOptions = false;
                this.selectedReport = [];
                this.dropdownValue = null;
                this.reportList.forEach(element => {
                    element.isSelected = false;
                });
                this.modalRef.hide();
                this.toaster.error(res.errorMessage, 'Error');
                this.downloadActionPoolCount = 0;
            }
        });
    }

    claimdownloadApiCall(value?, list?, isTripDwnld?) {
        this.downloadBtn = true;
        if (this.selectedReport.length) {
            this.downloadModel.referenceIds = this.selectedReport.toString();
        } else {
            this.downloadModel.referenceIds = null;
        }
        if (this.dropdownValue) {
            this.downloadModel.referenceIds = this.dropdownValue.claimId;
            this.downloadBtn = false;
        }
        this.filterModal.isDraft = null;
        this.filterModal.recordTypes = value ? [] : (this.fromPettyCash ? [4] : [1]);
        this.downloadModel.filterBy = this.filterModal;
        this.downloadModel.userId = this.userInfo.userId;
        this.downloadModel.userGUID = this.userInfo.userGuId;
        this.downloadModel.entityType = 2;
        // if (this.userInfo.roleId == 2){
        //     this.downloadModel.isAdminView = JSON.parse(sessionStorage.getItem('isadmin'));
        // }else{
        // }
        //  this.downloadModel.isAdminView = JSON.parse(sessionStorage.getItem('isadmin'));
        this.downloadModel.legalEntityId = this.userInfo.legalEntityId;
        this.downloadModel.legalEntityGUID = this.userInfo.legalEntityGuId;
        this.downloadModel.docType = value;
        this.downloadModel.viewType = this.expenseAndReportModel.viewType;
        this.downloadModel.isSummaryView = false;
        this.downloadModel.isApprover = false;
        this.downloadModel.recordType = this.selReport?.recordType ? this.selReport?.recordType : 1;
        this.downloadModel.downloadActionType = this.matTabLabel;
        if(isTripDwnld && (this.matTabLabel == 'My Trips' || this.matTabLabel == 'All Trips')) {
            this.downloadModel.downloadActionType = 'Trips';
        }
        if(this.matTabLabel == 'My Trips' || this.matTabLabel == 'All Trips') {
            this.commonService.downloadTripsList(this.downloadModel).subscribe(res => {
                if (!res.error && res.payload) {
                    this.claimdownloadPoolingStart(res.payload, 3000);
                } else if(res.error && res.errorCode == 2) {
                    this.modalRef.hide();
                    this.toaster.error(res.errorMessage, 'Error');
                }
            });
        } else {
            this.commonService.downloadList(this.downloadModel).subscribe(res => {
                if (!res.error && res.payload) {
                    this.claimdownloadPoolingStart(res.payload, 3000);
                }
            });
        }
    }
    claimdownloadOptSelector(value) {
        switch (value) {
            case 'excel':
                this.showDownloadOptions = true;
                this.claimdownloadApiCall('NULL');
                break;
            case 'pdf':
                this.showDownloadOptions = true;
                this.claimdownloadApiCall('PDF');
                break;
        }
    }
    fromDate(event) {
        this.frmDate = event.target.value;
        let date = this.datechangeService.convertCalendarToAPI(event.target.value);
        this.filterModal.fromDate = date;
        this.minDate = new Date(this.frmDate);
    }

    toDate(event) {
        this.tDate = event.target.value;
        let date = this.datechangeService.convertCalendarToAPI(event.target.value);
        this.filterModal.toDate = date;
        this.maxDate = new Date(this.tDate);
    }

    selectMultiStatus() {
        setTimeout(() => {
            this.statusList.forEach((status) => {
                status.isSelected = this.statusAll;
            });
        });
    }

    selectCategoryFilters() {
        setTimeout(() => {
            this.categoryList.forEach((item) => {
                item.isSelected = this.checkedAll;
            });
        });
    }
    oncancelClick() {
        if (this.platformType == 1) {
            this.modalRef.hide();
        }
    }
    onSaveClick() {
        this.pageNumber = 1;
        this.getreportList(true);
        this.oncancelClick();
    }
    isChecked(event) {
        this.AcknologmentBooleanCheck = event.detail.checked;
        if (this.AcknologmentBooleanCheck) {
            if (this.showViolationCheckBox) {
                if (this.AcknologmentViolationBooleanCheck) {
                    this.button_disable = true;
                } else {
                    this.button_disable = false;
                }
            } else {
                this.button_disable = true;
            }
        } else {
            this.button_disable = false;
        }
    }

    resetclose() {
        this.modalRef.hide();
        this.removeReportModal.skipAnomolies = true;
        this.removeReportModal.skipBudgetCheckMessage = true;
        this.reportList.forEach(element => {
            if (element.hasViolation) {
                this.showViolationCheckBox = true;
   
            }else{
                this.showViolationCheckBox =false;
            }
        });
        //   this.button_disable=false;
        // this.showViolationCheckBox=false;
    }
    isViolationChecked(event) {
        this.AcknologmentViolationBooleanCheck = event.detail.checked;
        if (this.AcknologmentViolationBooleanCheck) {
            if (this.AcknologmentBooleanCheck) {
                this.button_disable = true;
            } else {
                this.button_disable = false;
            }
        } else {
            this.button_disable = false;
        }
    }

    RepushHistoryClick(RepushHistory:TemplateRef<any>,report){
        const config = {
            ignoreBackdropClick: false,
            class: "modal-xl modal-dialog-centered",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(RepushHistory, config);

        this.expenseService.getIntegrationHistory({claimId:report.claimId,claimGUID:report.claimGUID}).subscribe((res) =>{
            if(res.errorCode == 0){
                this.integrationHistory = [];
                if(res.payload.integrationHistory.length != 0){
                    res.payload.integrationHistory.forEach(element => {
                       element.repushSpinner = false;
                    });
                // res.payload.integrationHistory = _.map(res.payload.integrationHistory, (item) => ({ ...item, repushSpinner: false }));
                }
                this.repushloading = false;
                this.integrationHistory = [...res.payload.integrationHistory];
            }

        })
    }

    RepushRequest(item,index){
        this.integrationHistory[index].repushSpinner = true;
        this.expenseService.repushRequest({claimId:item.claimId,claimGUID:item.claimGUID,legalEntityGuId:this.userInfo.legalEntityGuId,legalEntityId:this.userInfo.legalEntityId}).subscribe(res =>{
            this.integrationHistory[index].repushSpinner = false;
            this.integrationHistory[index].showRepushButton = false;
            this.toaster.success('Success',res.message);
        })
    }

    Confirmacknologment(AcknologmentForm:TemplateRef<any>,claimId?) {

        if(this.isErrorcode10 && this.budgetCategoryItems.length != 0){
            const isRestrictV = this.budgetCategoryItems.find((object) => object.isRestrict == true);
            if(isRestrictV?.length > 0){
                this.toastr.error('Budget not available, unable to proceed', this.translate.instant('alerts.error'));
                return;
            }else{
                this.removeReportModal.skipBudgetCheckMessage = true;
                this.removeReportModal.skipAnomolies = true;
            }
        }

        this.showloader = true;
        this.zone.run(() => {
            this.selectedReport.push(this.selecteClaimId);
            this.removeReportModal.userId = this.userInfo.userId;
            this.removeReportModal.userGUID = this.userInfo.userGuId;
            this.removeReportModal.legalEntityId = this.userInfo.legalEntityId;
            this.removeReportModal.legalEntityGUID = this.userInfo.legalEntityGuId;
            // this.removeReportModal.claimIds = _.uniq(this.selectedReport);

            this.removeReportModal.claimIds = [];
            this.removeReportModal.claimIds = [this.selectedReport[0].claimId];

            this.reportsService.draftReport(this.removeReportModal).subscribe((res) => {
                if(res.errorCode == 10){
                    this.showloader = false;
                    this.isErrorcode10 = true;
                    this.removeReportModal.skipAnomolies = true;
                    this.removeReportModal.skipBudgetCheckMessage = true;
                    this.budgetCategoryItems = [];
                    this.anomaliesCategoryItems = [];
                    this.budgetCategoryItems = [...res.payload.budgetResponse];
                    this.anomaliesCategoryItems = [...res.payload.anomalies];
                    if (!document.querySelector('.modal-backdrop')) {
                        const config = {
                            ignoreBackdropClick: true,
                            class: "modal-lg modal-dialog-centered",
                            keyboard: false,
                            claimId: claimId,
                        };
                        this.modalRef = this.modalService.show(AcknologmentForm, config);
                    }
                } else if (!res.isError && res.errorCode == 0) {
                    this.showloader = false;
                    this.AcknologmentBooleanCheck = false;
                    this.modalRef?.hide();
                    this.reportDraftArray = [];
                    this.selectedReport = [];
                    this.indeterminateState = false;
                    this.checkAll = false;
                    this.removeReportModal.skipAnomolies = false;
                    this.removeReportModal.skipBudgetCheckMessage = false;
                    this.getreportList(false);
                    this.toastr.success(res?.payload, this.translate.instant('alerts.succes'));
                } else if (res.isError == true) {
                    this.showloader = false;
                    this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
                    this.removeReportModal.skipAnomolies = false;
                    this.removeReportModal.skipBudgetCheckMessage = false;
                }
            });
        });
    }
    backTodashboard() {
        this.router.navigate(["/dashboard"]);
    }
    fromDatev2() {
        this.openfromdate = true;
        this.opentodate = false;
    }
    onClickedOutsideCalenderfrom(e) {
        let el = <HTMLElement>e?.target;
        if (this.openfromdate && (el.className == "cdk-overlay-backdrop mat-overlay-transparent-backdrop" || el.className == "mat-calendar-next-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused"
            || el.className == "mat-calendar-previous-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused" || el.className == "mat-calendar-arrow mat-calendar-invert" || el.className == "mat-calendar-body-cell-content mat-calendar-body-today"
            || el.className == "mat-calendar-period-button mat-button mat-button-base" || el.className == "mat-button-wrapper" || el.className == "mat-calendar-body-cell-content" || el.className == "mat-calendar-arrow" || el.className == "mat-calendar-header" || el.className == "cdk-overlay-container" || el.className == "mat-calendar-body-cell-content mat-calendar-body-selected mat-calendar-body-today" || el.className == "mat-focus-indicator mat-calendar-next-button mat-icon-button mat-button-base" || el.className == "mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base" || el.className == "mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused" || el.className == "mat-calendar-spacer")) {
            this.fromdate?.open();
        }
        else {
            this.fromdate?.close();

        }

    }
    toDatev2() {
        this.opentodate = true;
        this.openfromdate = false;
    }
    onClickedOutsideCalendertodate(e) {
        let el = <HTMLElement>e?.target;
        if (this.opentodate && (el.className == "cdk-overlay-backdrop mat-overlay-transparent-backdrop" || el.className == "mat-calendar-next-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused"
            || el.className == "mat-calendar-previous-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused" || el.className == "mat-calendar-arrow mat-calendar-invert" || el.className == "mat-calendar-body-cell-content mat-calendar-body-today"
            || el.className == "mat-calendar-period-button mat-button mat-button-base" || el.className == "mat-button-wrapper" || el.className == "mat-calendar-body-cell-content" || el.className == "mat-calendar-arrow" || el.className == "mat-calendar-header" || el.className == "cdk-overlay-container" || el.className == "mat-calendar-body-cell-content mat-calendar-body-selected mat-calendar-body-today" || el.className == "mat-focus-indicator mat-calendar-next-button mat-icon-button mat-button-base" || el.className == "mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base" || el.className == "mat-focus-indicator mat-calendar-previous-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused" || el.className == "mat-calendar-spacer")) {
            this.todate?.open();


        }
        else {
            this.openfromdate = false;
            this.todate?.close();

        }

    }

    showClaimFilterTemplate(filtertemplate: TemplateRef<any>) {
        const config = {
            ignoreBackdropClick: false,
            class: "modal-xl modal-dialog-centered",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(filtertemplate, config);
    }

    showFilterTemplate(filtertemplate: TemplateRef<any>) {
        const config = {
            ignoreBackdropClick: false,
            class: "modal-xl modal-dialog-centered",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(filtertemplate, config);
    }

    resetClosefilter() {
        this.modalRef.hide();
        this.removeReportModal.skipAnomolies = false;
        this.removeReportModal.skipBudgetCheckMessage = false;
    }

    // formatDate(date) {
    //     if (date) {
    //         return moment(date, 'DD-MM-YYYY').format('MMM DD y');
    //     }
    // }


    resetAlltripFilters(tabChange?) {
        this.filterFromDate = undefined;
        this.filterToDate = undefined;
        this.frmDate = undefined;
        this.tDate = undefined;
        this.filterMinPrice = undefined;
        this.filterMaxPrice = undefined;
        // this.tripfilterModal.minPrice = this.minPrice;
        // this.tripfilterModal.maxPrice = this.maxPrice;
        this.statusAll = false;
        this.showclearAll = false;
        this.filterListStatus.forEach(s => {
            s.isSelected = false;
            s.showInFilter = false;
        })
        this.filterCategoryList.forEach(s => {
            s.isSelected = false;
            s.showIncatFilter = false;
        })
        this.tripfilterModal.statusIds = [];
        this.tripfilterModal.productIds = [];
        this.resetClaimType();
        if (!tabChange) {
            this.getTripsList(false, '');
        }
    }
    showIncatFilter(list) {
        list.isSelected = false;
        this.tripfilterModal.productIds = [];
        this.filterCategoryList.forEach(s => {
            if (s.isSelected) {
                this.tripfilterModal.productIds.push(s.productId)
            }
        })
        let removeAmount = false;
        if (!this.filterMaxPrice && !this.filterMinPrice) {
            removeAmount = true;
        }
        this.applyTripfilter(false, removeAmount);
    }
    removetripFilter(list) {
        list.isSelected = false;
        this.tripfilterModal.statusIds = [];
        this.filterListStatus.forEach(s => {
            if (s.isSelected) {
                this.tripfilterModal.statusIds.push(s.statusId)
            }
        })
        let removeAmount = false;
        if (!this.filterMaxPrice && !this.filterMinPrice) {
            removeAmount = true;
        }
        this.applyTripfilter(false, removeAmount);
    }
    removetipDateFilter() {
        this.filterFromDate = undefined
        this.filterToDate = undefined;
        this.frmDate = undefined;
        this.tDate = undefined;
        let removeAmount = false;
        if (!this.filterMaxPrice && !this.filterMinPrice) {
            removeAmount = true;
        }
        this.applyTripfilter(false, removeAmount);
    }
    removetripAmountFilter() {
        this.filterMinPrice = undefined;
        this.filterMaxPrice = undefined;
        // this.tripfilterModal.minPrice = this.minAndMax.min;
        // this.tripfilterModal.maxPrice = this.minAndMax.max;
        this.applyTripfilter(false, true);
    }

    removeReportList: any;
    openDeleteConfirmModal(template: TemplateRef<any>, list) {
        this.removeReportList = list;
        const config = {
            ignoreBackdropClick: false,
            class: "modal-md modal-dialog-centered",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(template, config);
    }

    removeReportFromList() {
        this.pageNumber = 1;
        this.removeReportModal.legalEntityId = this.userInfo.legalEntityId;
        this.removeReportModal.legalEntityGUID = this.userInfo.legalEntityGuId;
        this.removeReportModal.userId = this.userInfo.userId;
        this.removeReportModal.userGUID = this.userInfo.userGuId;
        this.removeReportModal.recordType = this.removeReportList?.recordType;
        this.removeReportModal.categoryType = 3;
        if (this.removeReportList) {
            this.selectedReport.push(this.removeReportList.claimId);
        }
        this.removeReportModal.claimIds = this.selectedReport;
        this.deltbuttonDisabled = true;

        this.reportsService.removeReport(this.removeReportModal).subscribe((data) => {
            if (!data.isError && data.errorCode == 0) {
                this.deltbuttonDisabled = false;
                this.checkAll = false;
                this.indeterminateState = false;
                this.modalRef.hide();
                this.toastr.success(data?.payload, this.translate.instant('alerts.succes'));
                this.reportDraftArray = [];
                this.getreportList(false);
            } else if (data.isError && data.errorCode == 2) {
                this.deltbuttonDisabled = false;
                this.modalRef.hide();
                this.toastr.error(data.errorMessage, this.translate.instant('alerts.error'));
            }
        });
    }


    pdfSelectionCalltripview(pdfSelection: TemplateRef<any>, trip) {
        this.previewAttachmentsArray = [];
        this.pdfloader = true;
        this.getSingleTripDeatils(trip);
        // this.selectedTripInvoiceArray = trip.attchements;
        const config = {
            ignoreBackdropClick: false,
            class: "modal-dialog-centered modal-lg baa",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(pdfSelection, config);
    }
    getSingleTripDeatils(selectedTrip?) {
        let getTripModl = new GetTripInfo();
        // this.getReportModal.legalEntityId = this.reportSummary.legalEntityId;
        // this.getReportModal.legalEntityGUID = this.reportSummary.legalEntityGUID;
        getTripModl.legalEntityId = this.userInfo.legalEntityId;
        getTripModl.legalEntityGUID = this.userInfo.legalEntityGUID;
        getTripModl.companyId = this.userInfo.companyId;
        getTripModl.companyGUID = this.userInfo.companyGuId;
        getTripModl.tripId = selectedTrip.tripId;
        getTripModl.tripGUID = selectedTrip.tripGUID;
        getTripModl.productId = selectedTrip.productId;
        getTripModl.requestId = selectedTrip.requestId;
        getTripModl.requestGUID = selectedTrip.requestGUID;

        this.reportsService.getTripDetails(getTripModl).subscribe((res) => {
            if (!res.isError && res.errorCode === 0 && res?.payload) {
                res?.payload.flightDetails?.flights[0]?.invoiceDetails?.forEach((innerelement) => {
                    if (innerelement.base64Invoice != null && innerelement.invoiceUrl != null) {
                        let object_data = {
                            fileName: "file." + innerelement.invoiceUrl.split("?")[0].split(".").pop(),
                            content: innerelement.base64Invoice,
                            url: innerelement.invoiceUrl,
                            isDeleted: "",
                            attachmentType: 1,
                            referenceId: res?.payload.tripId,
                        };
                        this.previewAttachmentsArray.push(object_data);
                    }
                });
            }

            this.pdfloader = false;
        });
    }

    async getPdfAsBase64(url: string): Promise<string> {
        try {
            const response = await fetch(url);
            const buffer = await response.arrayBuffer();

            // Convert the response data to base64
            const base64 = btoa(
                new Uint8Array(buffer)
                    .reduce((data, byte) => data + String.fromCharCode(byte), '')
            );
            return base64;
        } catch (error) {
            throw error;
        }
    }

    gotoMore() {
        this.navCtrl.navigateRoot(['dashboard']);
    }
    formatDate(date) {
        if (date) {
            return moment(date, 'DD-MM-YYYY').format('DD-MMM-YY');

        }
    }
    dateTimeFormat(date) {
        return moment(date, 'DD-MM-YYYY HH:mm:ss').format('DD-MMM-YY HH:mm:ss')
    }



    tripData: any = {
        bookingType: '',
        customFields: '',
        costCenterCode: '',
        reasonForTravel: '',
        bookingTypeName: ''
    }
    toggleExpanded(index: number, item,iconClick?) {
        this.selectedTripIndex = index;
        this.seletedTrip = item;
        if(iconClick){
            this.tripsList[index].isExpanded = !this.tripsList[index].isExpanded;
        }
        this.tripsList.forEach((element, inx) => {
            if (index != inx) {
                element.isExpanded = false;
            }
        });
        if (item.serviceList.length == 0) {
            this.reportsService.getTripServiceList({
                tripId: item.tripId,
                tripGUID: item.tripGUID,
                legalEntityGuid: this.userInfo?.legalEntityGuId,
                legalEntityId: this.userInfo?.legalEntityId,
            }).subscribe((res) => {
                if (!res.isError && res.errorCode == 0 && res?.payload) {
                    console.log('sdsdsd',res);
//                     claimTypeId
// : 
// 13
// claimTypeName

                    res?.payload?.serviceList?.forEach(element => {
                        element.claimTypeId = res?.payload?.claimTypeId;
                        element.claimTypeName = res?.payload?.claimTypeName;
                    });
                    this.tripsList[index].serviceList = res?.payload.serviceList;
                    this.tripsList[index].advanceSummary = res?.payload.advanceSummary;
                    this.tripsList[index].tripTravellers = res?.payload.tripTravellers;
                    this.seletedTrip.tripEstimateDetails = res?.payload.tripEstimateDetails;
                    this.tripData.customFields = res.payload.customFields || null;
                    this.tripData.reasonForTravel = res.payload.reasonForTravel || null;
                    this.tripData.bookingType = res.payload.bookingType || null;
                    this.tripData.bookingTypeName = res.payload.bookingTypeName || null;
                    this.tripData.costCenterCode = res.payload.costCenterCode || null;
                    this.seletedTrip.tripEstimateCost = res?.payload?.serviceList[0]?.tripEstimateCost || null;
                    this.seletedTrip.tripEstimateInUSD = res?.payload?.serviceList[0]?.tripEstimateInUSD || null;
                    sessionStorage.setItem('tripData', JSON.stringify(this.tripData));
                }
                if(!item.isMultiService || !iconClick){
                    this.tripDetails(this.tripsList[index].serviceList[0]);
                }

            });
        }else{
            if(!item.isMultiService || !iconClick){
                this.tripDetails(this.tripsList[index].serviceList[0]);
            }
        }
    }

    withdraw_claim: any;
    confirmWithdraw(withdrawlConfirmation: TemplateRef<any>, claim) {
        this.withdraw_claim = claim;
        const config = {
            ignoreBackdropClick: false,
            class: "modal-dialog-centered modal-md baa",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(withdrawlConfirmation, config);
    }

    withdraw() {
        const obj = {
            claimId: this.withdraw_claim.claimId,
            claimGUID: this.withdraw_claim.claimGUID,
            isWithdraw: true
        }
        this.expenseService.getSendBackRequest(obj).subscribe(res => {
            if (!res.isError && res.errorCode === 0) {
                this.toastr.success(res.payload, this.translate.instant('alerts.succes'));
                this.modalRef?.hide();
                this.getreportList(false);
            } else {
                this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
                this.modalRef?.hide();
            }
        });
    }

    claimTypes: any = [];
    getClaimTypes() {
      const obj = {
        "action": "claim_types",
        "legalEntityGuid": this.userInfo?.legalEntityGuId,
        "legalEntityId": this.userInfo?.legalEntityId,
        "companyId": this.userInfo?.companyId,
        "companyGUID": this.userInfo?.companyGuId,
      }
      this.expenseService.getClaimTypes(obj).subscribe(res => {
        this.claimTypes = res?.payload || [];
      })
    }

    openTripsPopup(tripPopup: TemplateRef<any>) {
        const config = {
            class: "modal-xl modal-dialog-centered",
            keyboard: false
        };
        this.modalRef = this.modalService.show(tripPopup, config);
    }

    zoom = 1.0;
    zoomIn() {
      this.zoom += 0.25;
    }
  
    zoomOut() {
      if (this.zoom > 0.25) {
        this.zoom -= 0.25;
      }
    }

}
