import { Component, OnInit, ViewChild } from '@angular/core';
import { PlatformService } from 'src/app/services/platformbase.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AccountConfigService } from 'src/app/services/account-config.service';
import { ToastrService } from 'ngx-toastr';
import { AccountConfig, AccountConfigResponse } from 'src/app/models/Settings/accountconfig.model';
// import { IonicSelectableComponent } from 'ionic-selectable';
import { Title } from "@angular/platform-browser";
import { SharedService } from 'src/app/services/common-services/shared.service';
import { Category, CategoryList } from 'src/app/models/Settings/category.model';
import { SettingsService } from 'src/app/services/settings-services';

@Component({
  selector: 'app-account-configuration',
  templateUrl: './account-configuration.component.html',
  styleUrls: ['./account-configuration.component.scss'],
})
export class AccountConfigurationComponent implements OnInit {
  // @ViewChild("selectBaseLanguage") selectBaseLanguage: IonicSelectableComponent;
  // @ViewChild("selectDateFormat") selectDateFormat: IonicSelectableComponent;
  // @ViewChild("selectNumberFormat") selectNumberFormat: IonicSelectableComponent;

  platformType: number;
  accountConfigForm: FormGroup;
  buttonDisabled: boolean = false;
  baseLanguageList: any;
  dateFormatList: any;
  accountConfigModel = new AccountConfig();
  userInfo: any;
  loaderList = [1,2,3,4];
  showLoader: boolean = true;
  currencyFormatList: any;
  selectedLegalEntity: any;
  viewAdvanceOption = [{ show:false, name: "Hide" }, { show:true, name: "Expand" }];
  selectType: any;
  openOptions: boolean = false;

  categoryModel = new Category();

  constructor(private platformService: PlatformService, private title: Title, public sharedService: SharedService,
     private fb: FormBuilder, private accountConfigService: AccountConfigService, private toastr: ToastrService,private settingService:SettingsService) {
    this.accountConfigForm = this.fb.group({
      expensePrefix: [''],
      reportPrefix: [''],
      advancePrefix: [''],
      localConveyancePrefix: [''],
      pettyCashExpensePrefix: [''],
      pettyCashRequestPrefix: [''],
      pettyCashReportPrefix: [''],
      travelPrefix: [''],
      baseLanguage: [''],
      dateFormat: [''],
      numberFormat: ['']
    })
   }

  ngOnInit() {
  }

  ionViewWillEnter(){
    this.platformType = this.platformService.getPlatformType();
    this.baseLanguageList = JSON.parse(sessionStorage.getItem('baseLanguageJson'));
    this.dateFormatList = JSON.parse(sessionStorage.getItem('dateFormatJson'));
    this.currencyFormatList = JSON.parse(sessionStorage.getItem('currencyFormatJson'));
    this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
    this.selectedLegalEntity = JSON.parse(sessionStorage.getItem('selectedLegalEntity'));
    this.accountConfigModel.legalEntityId = this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId;
    this.accountConfigModel.legalEntityGUID = this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId;
    let applicationTitle = this.userInfo.applicationTitle;
    if(applicationTitle){
        this.title.setTitle('Account Configurations | Settings' + ' | ' + applicationTitle);
    }else{ 
        this.title.setTitle("Account Configurations | Settings | NoExpenses");
    }
    this.sharedService.setCompanyInfo(this.selectedLegalEntity);
    this.getCategoryList();
    this.getAccountConfiguration();
    
  }

  getCategoryList(event?) {
    let navInfo = JSON.parse(sessionStorage.getItem("toPathInfo"));

    this.categoryModel.legalEntityGUID = this.selectedLegalEntity?.legalEntityGuId;
    this.categoryModel.legalEntityId = this.selectedLegalEntity?.legalEntityId;
    this.categoryModel.pageNumber = 1;
    this.categoryModel.pageSize = 100;
    this.categoryModel.categoryType = 1;
    if (navInfo && navInfo.categoryType) {
        this.categoryModel.categoryType = navInfo.categoryType;
    }
    this.settingService.categoryList(this.categoryModel).subscribe(data => {
      if (!data.isError && data.payload.categoryList.length > 0) {
        let categoryArr = data.payload.categoryList;
        let categoryList = categoryArr.map((list) => new CategoryList(list));
        sessionStorage.setItem('parent-categoryList',JSON.stringify(categoryList))
      }
    })
  }

  getAccountConfiguration(){
    this.accountConfigService.getAccountConfig(this.accountConfigModel).subscribe(res => {
      if(!res.isError && res.payload){
        this.showLoader = false;
        this.accountConfigModel = new AccountConfigResponse(res.payload);
        // if(this.platformType == 2){
        //   this.accountConfigForm.controls.baseLanguage.patchValue({
        //     value: this.accountConfigModel.baseLanguage,
        //   });
        //   this.accountConfigForm.controls.dateFormat.patchValue({
        //     format: this.accountConfigModel.dateFormate,
        //   });
        //   this.accountConfigForm.controls.numberFormat.patchValue({
        //     format: this.accountConfigModel.numberFormate,
        //   });
        // }
      } else {
        this.toastr.error(res.errorMessage, "Error");
      }
    })
  }

  updateAccountConfig(){
     this.buttonDisabled = true;
     this.accountConfigService.saveAccountConfig(this.accountConfigModel).subscribe(data => {
      if(!data.isError){
        this.buttonDisabled = false;
        this.toastr.success(data.payload, 'Success');
        if(this.userInfo.legalEntityId == this.selectedLegalEntity.legalEntityId) {
          sessionStorage.setItem("current-lang",this.accountConfigModel.baseLanguage);
        }
        this.getAccountConfiguration();
      }else {
        this.buttonDisabled = false;
        this.toastr.error(data.errorMessage, 'Error');
        this.getAccountConfiguration();
      }
     })
  }

  // baseLanguageChange(e) {
  //   if (e != null) this.accountConfigModel.baseLanguage = e.value;
  // }

  // applyBaseLanguage(){
  //   this.selectBaseLanguage.confirm();
  //   this.selectBaseLanguage.close();
  // }

  // dateFormatChange(e){
  //   if (e != null) this.accountConfigModel.dateFormate = e.format;
  // }

  // applyDateFormat(){
  //   this.selectDateFormat.confirm();
  //   this.selectDateFormat.close();
  // }

  // numberFormatChange(e){
  //   if (e != null) this.accountConfigModel.numberFormate = e.format;
  // }

  // applyNumberFormat(){
  //   this.selectNumberFormat.confirm();
  //   this.selectNumberFormat.close();
  // }
}
