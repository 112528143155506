import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-custom-pop-over',
  templateUrl: './custom-pop-over.component.html',
  styleUrls: ['./custom-pop-over.component.scss'],
})
export class CustomPopOverComponent implements OnInit {
  @Input() actionsObj: any;

  constructor(
    private popover: PopoverController
  ) { }

  closePopover(actionObj: any) {
    this.popover.dismiss(actionObj);
  }

  ngOnInit() {
  }

}
