import { Component, OnInit } from '@angular/core';
import { PlatformService } from 'src/app/services/platformbase.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-manage-notifications',
  templateUrl: './manage-notifications.component.html',
  styleUrls: ['./manage-notifications.component.scss'],
})
export class ManageNotificationsComponent implements OnInit {
  platformType: number;
  notificationForm: FormGroup;
  buttonDisabled: boolean = false;
  
  constructor(private platformService: PlatformService, private fb: FormBuilder) { }

  ngOnInit() {
    this.platformType = this.platformService.getPlatformType();
  }

}
