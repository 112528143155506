import { AbstractControl } from "@angular/forms";
import { FormGroup } from "@angular/forms";

function htmlValidator(control: AbstractControl): { [key: string]: boolean } | null {
  if (control.value !== "" && control.value !== undefined && control.value !== null && control.value.match(/<[^>]/)) {
    return { 'htmlscript': true };
  }
}

export { htmlValidator };


function MustMatch(controlName: string, matchingControlName: string) {
  return (formgroup: FormGroup) => {
    const control = formgroup.controls[controlName];
    const matchingcontrol = formgroup.controls[matchingControlName];

    if (matchingcontrol.errors && !matchingcontrol.errors.mustMatch) {
      return false;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingcontrol.value) {
      matchingcontrol.setErrors({ mustMatch: true });
    }
    else {
      matchingcontrol.setErrors(null);
    }
  };

}

export { MustMatch };
