import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  APP_INITIALIZER,
} from "@angular/core";
import {
  BrowserModule,
  HammerGestureConfig,
  HAMMER_GESTURE_CONFIG,
  HammerModule,
} from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { IonicModule, IonicRouteStrategy, NavParams } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { HttpAuthInterceptor } from "./services/interceptor.service";
import { MatTreeModule } from "@angular/material/tree";
import { MatIconModule } from "@angular/material/icon";
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import {
  AppDateAdapter,
  APP_DATE_FORMATS,
} from "./services/common-services/commonmethods.service";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from "@angular/common/http";
import { CameraComponent } from "./components/camera/camera.component";
import { LoginComponent } from "./components/login/login.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PlatformService } from "./services/platformbase.service";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { MainHeaderComponent } from "./components/main-header/main-header.component";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { ToastrModule } from "ngx-toastr";
import { CookieService } from "ngx-cookie-service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ReportsComponent } from "./components/reports/reports.component";
import { NewreportComponent } from "./components/newreport/newreport.component";
import { AdvanceComponent } from "./components/advance/advance.component";
import { AnalyticsComponent } from "./components/analytics/analytics.component";
import { ApprovalsComponent } from "./components/approvals/approvals.component";
import { ExpensesComponent } from "./components/expenses/expenses.component";
import { ExpenseAndMileageComponent } from "./components/dashboard/expenseandmileage/expenseandmileage.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatTabsModule } from "@angular/material/tabs";
import { MatInputModule } from "@angular/material/input";
import { MatNativeDateModule } from "@angular/material/core";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { ModalModule } from "ngx-bootstrap/modal";
import { Camera } from "@ionic-native/camera/ngx";
import { MatSelectModule } from "@angular/material/select";
import { FilterPipe } from "src/app/pipes/report.pipe";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { BaseComponent } from "./components/base/base.component";
// import { SelectDropDownModule } from 'ngx-select-dropdown';
import { NgbModule, NgbPopoverModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxSelectModule } from "ngx-select-ex";
// import { IonicSelectableModule } from "ionic-selectable";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatExpansionModule } from "@angular/material/expansion";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
// import { IonicStorageModule } from "@ionic/storage";
import { CommonDirectivesModule } from "./directives/common-directives.module";
import { ImageViewComponent } from "./components/dashboard/image-view/image-view.component";
import { DashboardService } from "./services/dashboard.service";
import { DatePipe } from "@angular/common";
import { DetailreportComponent } from "./components/reports/detailreport/detailreport.component";
// import { InfiniteScrollModule } from "ngx-infinite-scroll";
// import { AgmCoreModule } from "@agm/core"; // @agm/core
// import { AgmDirectionModule } from "agm-direction"; // agm-direction
import { PdfViewerModule } from "ng2-pdf-viewer";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

import { Ng5SliderModule } from "ng5-slider";
import { AngularSplitModule } from "angular-split";
import { ExpenseDetailviewComponent } from "./components/expenses/expense-detailview/expense-detailview.component";
import {
  FinanceModalPage,
  RejectModalComponent,
  ReportDetailviewComponent,
} from "./components/reports/report-detailview/report-detailview.component";

import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { ChartsModule } from "ng2-charts";
import { ColorPickerModule } from "ngx-color-picker";
import { DetailapprovalComponent } from "./components/approvals/detailapproval/detailapproval.component";
import { CameraPreview } from "@ionic-native/camera-preview/ngx";
import { SettingModule } from "./components/settings/setting.module";
import { CustomDatePickerComponent } from "./components/custom-date-picker/custom-date-picker.component";
import { UtcDate } from "./pipes/utcDate.pipe";
import * as Hammer from "hammerjs";
import { CustomSelectDropdownComponent } from "./components/custom-select-dropdown/custom-select-dropdown.component";
import { MyPipesModule } from "./pipes/my-pipes.module";
import { CustomPopOverComponent } from "./components/custom-pop-over/custom-pop-over.component";
import { NewAdvanceComponent } from "./components/advance/new-advance/new-advance.component";
import { CustomDateFilterComponent } from "./components/custom-date-filter/custom-date-filter.component";
import {
  MatSlideToggleModule,
  MatSlideToggle,
} from "@angular/material/slide-toggle";
import { LcListComponent } from "./components/Local Conveyance/lc-list.component";
import { NewLcComponent } from "./components/Local Conveyance/new-lc/new-lc.component";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { Constants } from "./Constants";
import { SelectLocationComponent } from "./components/select-location/select-location.component";
import { RecordPaymentComponent } from "./components/reports/record-payment/record-payment.component";
import { PettyCashComponent } from "./components/petty-cash/petty-cash.component";
import { AddPettyCashComponent } from "./components/petty-cash/add-petty-cash/add-petty-cash.component";
import { MatButtonModule } from "@angular/material/button";
import { MatMenuModule } from "@angular/material/menu";
import { ChangePasswordComponent } from "./components/change-password/change-password.component";
import { TravelComponent } from "./components/travel/travel.component";
import { CustomTraveldetailComponent } from "./components/custom-traveldetail/custom-traveldetail.component";
import { TripDetailsComponent } from "./components/travel/trip-details/trip-details.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

import * as CanvasJSAngularChart from '../assets/canvasjs/canvasjs.angular.component';
var CanvasJSChart = CanvasJSAngularChart.CanvasJSChart;
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
import { BackgroundGeolocation } from '@ionic-native/background-geolocation/ngx';
import { GpsTrackingComponent } from "./components/gps-tracking/gps-tracking.component";
import { BackgroundMode } from "@ionic-native/background-mode/ngx";
import { AndroidPermissions } from "@ionic-native/android-permissions/ngx";
import { Diagnostic } from "@ionic-native/diagnostic/ngx";
import { MileageDefaultsComponent } from "./components/mileage-defaults/mileage-defaults.component";
import { CommonFilterComponent } from "./components/common-filter/common-filter.component";
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DashboardFc365Component } from "./components/dashboard-fc365/dashboard-fc365.component";
import { MoreOption365Component } from "./components/more-option365/more-option365.component";
import { TravelEvaComponent } from "./components/travel-eva/travel-eva.component";
import { ClickOutsideModule } from 'ng-click-outside';
import { MatChipsModule } from "@angular/material/chips";
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatBadgeModule } from '@angular/material/badge';
import { CustomFieldsComponent } from "./components/settings/custom-fields/custom-fields.component";
import { MatCheckboxModule } from '@angular/material/checkbox';
import { GoogleChartsModule } from 'angular-google-charts';
import {MatRadioModule} from '@angular/material/radio';
import { CustomMatDatepickerComponent } from "./components/custom-mat-datepicker/custom-mat-datepicker.component";
import { CustomTooltipComponent } from "./components/custom-tooltip/custom-tooltip.component";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { NgSelectModule } from '@ng-select/ng-select';
import { DeeplinkComponent } from "./components/deeplink/deeplink.component";
import { SettlementOtpVerificationComponent } from "./components/settlement-otp-verification/settlement-otp-verification.component";
import { TripsPopupComponent } from "./components/expenses/trips-popup/trips-popup.component";
import { MatDialogModule } from '@angular/material/dialog';
import { MytripsListComponent } from "./components/expenses/mytrips-list/mytrips-list.component";
import { MycardsListComponent } from "./components/expenses/mycards-list/mycards-list.component";
import { ForeignCurrencyComponent } from "./components/foreign-currency/foreign-currency.component";
import { DateRangeComponent } from "./components/date-range/date-range.component";
import { AppVersion } from "@ionic-native/app-version/ngx";
import { NgxEchartsModule } from 'ngx-echarts';

export function initializeApp(appConfig: Constants) {
  return () => appConfig.load();
}

export class CustomHammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: {
      enable: true,
      direction: Hammer.DIRECTION_ALL,
    },
    pan: {
      enable: true,
      direction: Hammer.DIRECTION_ALL,
    },
  };
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    CameraComponent,
    MainHeaderComponent,
    DashboardComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    ResetPasswordComponent,
    ReportsComponent,
    NewreportComponent,
    AdvanceComponent,
    AnalyticsComponent,
    ApprovalsComponent,
    ExpensesComponent,
    FilterPipe,
    BaseComponent,
    ExpenseAndMileageComponent,
    ImageViewComponent,
    DetailreportComponent,
    DetailapprovalComponent,
    ExpenseDetailviewComponent,
    ReportDetailviewComponent,
    RejectModalComponent,
    FinanceModalPage,
    CustomDatePickerComponent,
    PettyCashComponent,
    AddPettyCashComponent,
    CustomSelectDropdownComponent,
    LcListComponent,
    NewLcComponent,
    CustomPopOverComponent,
    NewAdvanceComponent,
    CustomDateFilterComponent,
    CustomMatDatepickerComponent,
    SelectLocationComponent,
    RecordPaymentComponent,
    TravelComponent,
    CustomTraveldetailComponent,
    TripDetailsComponent,
    CanvasJSChart,
    GpsTrackingComponent,
    MileageDefaultsComponent,
    CommonFilterComponent,
    DashboardFc365Component,
    MoreOption365Component,
    TravelEvaComponent,
    CustomFieldsComponent,
    CustomTooltipComponent,
    DeeplinkComponent,
    SettlementOtpVerificationComponent,
    TripsPopupComponent,
    MytripsListComponent,
    MycardsListComponent,
    ForeignCurrencyComponent,
    DateRangeComponent
  ],
  entryComponents: [
    CustomDatePickerComponent,
    RejectModalComponent,
    FinanceModalPage,
    SelectLocationComponent,
    ReportDetailviewComponent,
    ImageViewComponent,
    RecordPaymentComponent,
    CustomSelectDropdownComponent,
    CustomPopOverComponent,
    CustomTooltipComponent,
    SettlementOtpVerificationComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonDirectivesModule,
    FormsModule,
    ReactiveFormsModule,
    MatTreeModule,
    MatTabsModule,
    MatChipsModule,
    MatIconModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: "toast-top-center",
    }),
    ColorPickerModule,
    MatAutocompleteModule,
    MatExpansionModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    NgxSelectModule,
    MatBadgeModule,
    AccordionModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    // IonicStorageModule.forRoot(),
    IonicModule.forRoot({ animated: false }),
    AppRoutingModule,
    HttpClientModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    NgbModule,
    // InfiniteScrollModule,
    Ng5SliderModule,
    AngularSplitModule.forRoot(),
    MatTooltipModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    ChartsModule,
    SettingModule,
    MyPipesModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    // AgmCoreModule.forRoot({
    //   // @agm/core
    //   apiKey: "AIzaSyBtN4sKTgq8qlg0cbYleWy2iQglWwye6lQ",
    // }),
    // AgmDirectionModule,
    HammerModule,
    GooglePlaceModule,
    MyPipesModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatMenuModule,
    PdfViewerModule,
    ClickOutsideModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatRadioModule,
    NgbPopoverModule,
    // Ng2OdometerModule.forRoot()
    GoogleChartsModule,
    // Ng2OdometerModule.forRoot()
    NgSelectModule,
    MatDialogModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    })
  ],
  providers: [
    //     mmi,
    DatePipe,
    StatusBar,
    SplashScreen,
    Camera,
    CameraPreview,
    PlatformService,
    CookieService,
    DashboardService,
    Storage,
    NavParams,
    Constants,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [Constants],
      multi: true,
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig },
    { provide: HTTP_INTERCEPTORS, useClass: HttpAuthInterceptor, multi: true },
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    Geolocation,
    BackgroundMode,
    NativeGeocoder,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    BackgroundGeolocation,
    AndroidPermissions,
    Diagnostic,
    InAppBrowser,
    AppVersion
  ],
  exports: [MatAutocompleteModule, MatInputModule],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
