import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SettingsService } from 'src/app/services/settings-services';

@Component({
  selector: 'app-budget',
  templateUrl: './budget.component.html',
  styleUrls: ['./budget.component.scss'],
})
export class BudgetComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  dataSource: MatTableDataSource<any>;
  modalRef: BsModalRef;
  userInfo: any;
  loadersList: any = [1,2,3,4];
  showLoading = false;
  selectedIndex = -1;
  budgetList: any;
  // [
  //   {bName: 'Budget Name - 1', bType: 'Budget Type - 1', fYear: '2022-2023', budgetId: '1', budgetGUID: 'udygsfdfwgvdjiwuyf'},
  //   {bName: 'Budget Name - 2', bType: 'Budget Type - 2', fYear: '2023-2024', budgetId: '2', budgetGUID: 'poiuifdfwgvdjkjhgg'}
  // ];
  displyColumns: any[] = ['Budget Name', 'Budget Type', 'Fiscal Year', 'Status', '3Dots'];
  pageSize = 25;
  pageNumber = 1;
  currentPage = 0;
  searchText: any = '';
  pageEvent: PageEvent;
  pageSizeOptions: number[] = [1, 5, 10, 25, 100];
  selectedLegalEntity: any;

  constructor(
    private router: NavController,
    public toastr: ToastrService,
    private translate: TranslateService,
    private modalService: BsModalService,
    private settingsService: SettingsService
  ) {
  }

  ngOnInit() {
    this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
    this.selectedLegalEntity = JSON.parse(sessionStorage.getItem('selectedLegalEntity'));
    this.getBudgets();

    // TESTING
    this.dataSource = new MatTableDataSource(this.budgetList);
    this.dataSource.paginator = this.paginator;
    // console.log(this.dataSource);
    // TESTING
  }

  handlePage(e) {
    console.log("page index :",e);
    this.currentPage = e.pageIndex;
    if(!this.dataSource.paginator.hasNextPage()){
      // this.paginatorStatus = true;
      this.pageNumber++;
      // this.financialPolAPI();
    }
  }

  toggleStatusDropdown(index, r?) { 
    if (this.selectedIndex != index)
      this.selectedIndex = index;
    else
      this.selectedIndex = -1;
  }

  hideOptions() {
    if (this.selectedIndex !== -1) {
      this.selectedIndex = -1
    }
  }
  
  addBudget() {
    let navInfo = {
      url:'settings/budget',
      budgetId:null,
      budgetGUID:null
    };
    sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
    this.router.navigateRoot(['/settings/budget/add']); 
  }

  editBudget(budget:any) {
    let navInfo = {
      url:'settings/budget',
      budgetId:budget?.budgetId,
      budgetGUID:budget?.budgetGUID
    };
    this.hideOptions();
    sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
    this.router.navigateRoot(['/settings/budget/add']);
  }

  deleteBudgetObj: any;
  deleteConfirmationModal(budget, deleteTemp: TemplateRef<any>) {
    this.deleteBudgetObj = budget;
    this.hideOptions();
    const config = {
      ignoreBackdropClick: false,
      class: "modal-md modal-dialog-centered",
      keyboard: false
    };
    this.modalRef = this.modalService.show(deleteTemp, config);
  }

  deleteBudget() {
    console.log(this.deleteBudgetObj);
    let obj = {
      "budgetId": this.deleteBudgetObj.budgetId,
      "budgetGUID": this.deleteBudgetObj.budgetGUID,
    }

    if(this.deleteBudgetObj.statusId == 1) {
      this.settingsService.disableBudgetActual(obj).subscribe(res => {
        if (!res.isError && res.errorCode == 0) {
          this.getBudgets();
          this.closeModal();
          this.toastr.success(res?.payload, this.translate.instant("alerts.succes"));
        } else if (res.isError == true) {
          this.closeModal();
          this.toastr.error(res.errorMessage, this.translate.instant("alerts.error", { timeOut: 3000 }));
        }
      })
    } else {
      this.settingsService.enableBudgetActual(obj).subscribe(res => {
        if (!res.isError && res.errorCode == 0) {
          this.getBudgets();
          this.closeModal();
          this.toastr.success(res?.payload, this.translate.instant("alerts.succes"));
        } else if (res.isError == true) {
          this.closeModal();
          this.toastr.error(res.errorMessage, this.translate.instant("alerts.error", { timeOut: 3000 }));
        }
      })
    }
  };

  closeModal() {
    this.deleteBudgetObj = null;
    this.modalRef?.hide();
  }

  duplicateBudget(budget) {
    console.log(budget);

    this.hideOptions();
  };

  toggleStatus(ev?) {
    this.hideOptions();
  }

  getBudgets() {
    this.showLoading = true;
    let obj = {
      "legalEntityId":this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId,
      "legalEntityGUID":this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId,
      "pageNumber": this.pageNumber,
      "pageSize": this.pageSize,
      "searchText": this.searchText
    }
    this.settingsService.getBudgetActualList(obj).subscribe(res => {
      if (!res.isError && res.errorCode == 0) {
        this.showLoading = false;
        this.budgetList = res?.payload || [];
        this.dataSource = new MatTableDataSource(this.budgetList);
        // this.dataSource.paginator = this.paginator;
      } else if (res.isError == true) {
        this.showLoading = false;
        this.toastr.error(res.errorMessage, this.translate.instant("alerts.error", { timeOut: 3000 }));
      }

    }, err => {
      this.showLoading = false;
    })


    // TESTING
    // setTimeout(() => {
    //   this.showLoading = false;
    //   this.budgetList = this.res.budgetList || [];
    //   this.dataSource = new MatTableDataSource(this.budgetList);
    //   this.dataSource.paginator = this.paginator;
    // }, 1000)
    // TESTING
  }


  // res = {
  //   "budgetList" : [{
  //     "budgetId" : 23,
  //     "budgetGUID" : "f7589e7f5af9f559cf7eac4d5729729a",
  //     "budgetName" : "budget 001",
  //     "budgetType" : 1,
  //     "budgetTypeName" : "Cost Center",
  //     "fiscalYear" : "2023-2024",
  //   }, {
  //     "budgetId" : 24,
  //     "budgetGUID" : "78f09fc56824295d494328523aae2d35",
  //     "budgetName" : "budget 002",
  //     "budgetType" : 2,
  //     "budgetTypeName" : "Categories",
  //     "fiscalYear" : "2023-2024"
  //   }]
  // }

}
