import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PhotoService } from 'src/app/services/photo.service';
import { PlatformService } from 'src/app/services/platformbase.service';
import { CameraPreview } from '@ionic-native/camera-preview/ngx';
import { ThemeService } from 'src/app/services/theme.service';
import { AlertController, IonSlides, ModalController, NavController, Platform } from '@ionic/angular';
import { ExpenseAndMileageComponent } from '../dashboard/expenseandmileage/expenseandmileage.component';
import { ExpensesService } from 'src/app/services/expenses.service';
import { ExpenseAttachments, ExpenseAttachmentsModel, ExpenseModel, ocrAttachment, ocrReqModel } from 'src/app/models/Expenses/expenses.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { CommonService } from 'src/app/services/common-services/common.service';
import * as moment from 'moment';
import { Location } from '@angular/common';

@Component({
    selector: 'app-camera',
    templateUrl: './camera.component.html',
    styleUrls: ['./camera.component.scss'],

})
export class CameraComponent implements OnInit, OnDestroy {

    @ViewChild(IonSlides) slides: IonSlides;
    photos = this.photoService.photos;
    capturedImage: any;
    setZoom = 1;
    platformType: number;
    expPicArray = [];
    slideOpts = {
        zoom: false,
        slidesPerView: 1,
        centeredSlides: true,
        spaceBetween: 10,
        initialSlide: 0,
    };
    activeIndex: number = 0;
    userInfo: any;
    createExpTab = false;
    imgUpload = false;
    fromLat: any = 0;
    fromLong: any = 0;
    totalDistanceVal: any = 0;
    sessionId: any = 0;
    checkUserPreferance: boolean = false;
    // cameraStarted = false;
    takePictureExpenses = false;
    expensesList: any = [];
    expenseIndex: any;
    tabIndex: any;
    claimObj = {
        name: '',
        startDate: '',
        endDate: '',
        claimTypeId: null,
        claimTypeName: null,
        claimCategory: null,
        claimCategoryId: null,
        claimStartTime: null,
        claimEndTime: null,
        trips: []
    }
    fromPath: any;
    advanceObj: any;

    constructor(
        private cameraPreview: CameraPreview,
        private router: NavController,
        private photoService: PhotoService,
        private platformService: PlatformService,
        public modalController: ModalController,
        public themeService: ThemeService,
        public expensesService: ExpensesService,
        public toastr: ToastrService,
        private translate: TranslateService,
        private geolocation: Geolocation,
        private androidPermissions: AndroidPermissions,
        private platform: Platform,
        public alertController: AlertController,
        private zone: NgZone,
        private diagostic: Diagnostic,
        private userPreferance: CommonService,
        private navCtrl: NavController,
    ) {
        /*document.querySelector('body').style.background = 'transparent';*/
    }

    goToDashBoard() {
        sessionStorage.removeItem('cameraRetake');
        this.router.navigateRoot(['dashboard']);
    }
    goToGPStraking() {
        sessionStorage.removeItem('cameraRetake');
        localStorage.setItem('fromUP', 'camera');
        localStorage.setItem('fromTM', 'camera');
        this.geolocation.getCurrentPosition().then((resp) => {
            this.sessionId = this.generateSessionKey();
            localStorage.setItem('sessionId', this.sessionId);
            if (localStorage.getItem('fromGPS')) {
                this.totalDistanceVal = 0.00;
                localStorage.setItem('gpsDistance', this.totalDistanceVal);
                localStorage.removeItem('fromGPS')
                // this.router.navigateForward(['gpstracking']);
                if(this.checkUserPreferance){
                    this.router.navigateForward(['gpstracking']);
                }else{
                    this.router.navigateForward(['mileagedefaults']);
                }
               // this.router.navigateForward(['mileagedefaults']);
            } else {
                if(this.checkUserPreferance){
                    this.router.navigateForward(['gpstracking']);
                }else{
                    this.router.navigateForward(['mileagedefaults']);
                }
                // this.router.navigateForward(['gpstracking']);
            }
        }).catch((error) => {
            this.allowLocationsFromSettings();
        });
    }

    async ionViewWillEnter() {
        if(sessionStorage.getItem('toPathInfo') && sessionStorage.getItem('toPathInfo') != 'undefined') {
            let pathInfo =  JSON.parse(sessionStorage.getItem('toPathInfo'));
            this.fromPath = pathInfo?.from || '';
            this.takePictureExpenses = (pathInfo?.from == 'takePicture-Expenses' || pathInfo?.from == 'takePicture-Advance') ? true : false;
        }
        if(!this.takePictureExpenses) {
            sessionStorage.removeItem('toPathInfo');
        } else {
            let pathInfo = JSON.parse(sessionStorage.getItem('toPathInfo'));
            this.advanceObj = pathInfo?.advance;
            this.claimObj.name = pathInfo?.claimName;
            this.claimObj.startDate = pathInfo?.claimStartDate;
            this.claimObj.endDate = pathInfo?.claimEndDate;
            this.claimObj.trips = pathInfo?.claimTaggedTripList || [];
            this.claimObj.claimTypeId = pathInfo?.claimTypeId;
            this.claimObj.claimTypeName = pathInfo?.claimTypeName;
            this.claimObj.claimCategory = pathInfo?.claimCategory;
            this.claimObj.claimCategoryId = pathInfo?.claimCategoryId;
            this.claimObj.claimStartTime = pathInfo?.claimStartTime;
            this.claimObj.claimEndTime = pathInfo?.claimEndTime;
            this.tabIndex = pathInfo?.tabIndex;
            this.expenseIndex = pathInfo?.expenseIndex;
            this.expensesList = pathInfo?.expensesList || [];
        }
        sessionStorage.removeItem('cameraRetake');
        // this.setBase64()
        // await this.startCameraBelow();
        // await this.cameraPreview.show();
        // await this.themeService.setTheme('camerabody');
        // this.testingRoute();

        this.capturedImage = null;
        if (this.platform.is('android')) {
            this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.CAMERA).then(
                result => this.checkLocationStatus(result.hasPermission),
                err => console.log('error')
            );
        } else {
            await this.startCameraBelow();
            await this.cameraPreview.show();
            await this.themeService.setTheme('camerabody');
        }
    }

    ngOnInit() {
        this.platformType = this.platformService.getPlatformType();
        this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        this.getUserPreferance();

        /* setTimeout(() => {
             document.querySelector('app-camera >ion-content').
             shadowRoot.querySelectorAll('#background-content')[0].style.background = 'none';
         }, 13000);
         this.cameraPreview.show().then();
        this.startCameraBelow();
        */
    }
    async startCameraBelow() {
        // if (this.cameraStarted) {
        //     await this.cameraPreview.stopCamera();
        // }

        await this.cameraPreview.startCamera({
            x: 0,
            y: 0,
            width: window.screen.width,
            height: window.screen.height,
            camera: 'rear',
            tapPhoto: true,
            previewDrag: false,
            toBack: true,
            alpha: 1
        }).then(() => {
            this.overrideBackButton();
        });

        // this.cameraStarted = true;
    }

    startCameraAbove() {
        /* this.cameraPreview.stopCamera().then(() => {
             this.isToBack = false;
             this.cameraPreview.startCamera({ x: 80, y: 450, width: 250, height: 300, toBack: false, previewDrag: true, tapPhoto: true });
         })*/
        this.cameraPreview.startCamera({ x: 0, y: 0, width: 250, height: 300, toBack: false, previewDrag: true, tapPhoto: true });
    }


    stopCamera() {
        return this.cameraPreview.stopCamera();
    }
    expenseAttachmentsModel = new ExpenseAttachmentsModel();
    expenseModel = new ExpenseModel();
    takePhoto() {
        // if (this.cameraStarted) {
        this.cameraPreview.takePicture({
            width: 1280,
            height: 1280,
            quality: 85
        }).then((imageData) => {
            this.stopCamera();
            let fileName = Math.random().toString(36).substr(2, 15) + ".png";
            this.capturedImage = 'data:image/png;base64,' + imageData;
            this.expPicArray.push(this.capturedImage);
            this.expenseAttachmentsModel.content = this.capturedImage;
            this.expenseAttachmentsModel.fileName = fileName;
            this.expenseModel.expenseAttachments.push(this.expenseAttachmentsModel);
            let arr = this.expenseModel.expenseAttachments;
            this.expenseModel.expenseAttachments = arr.map(item => new ExpenseAttachments(item));

            const byteCharacters = atob(imageData);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'image/jpeg' });
            const file = new File([blob], 'photo.jpg', { type: 'image/jpeg' });
            // console.log('Image taken', file);
            this.expensesService.cameraImageFile = file;

            // let navInfo = {
            //     url: 'dashboard',
            //     attachment: '',
            //     extractedData: '',
            //     fileName: Math.random().toString(36).substr(2, 15) + ".png",
            //     from: 'camera',
            //     report: {
            //         isLinkedToAdvancePayment: false,
            //         isLinkedToPettyCashPayment: false,
            //         claimName: this.takePictureExpenses? this.claimObj.name : 'Expense created on ' + moment().format("DD MMM HH:mm:ss"),
            //         claimStartDate: this.takePictureExpenses? this.claimObj.startDate : moment().format('DD-MM-YYYY'),
            //         claimEndDate: this.takePictureExpenses? this.claimObj.endDate : moment().format('DD-MM-YYYY'),
            //         tabIndex: this.tabIndex,
            //         expenseIndex: this.expenseIndex,
            //         expenseList: this.takePictureExpenses? this.expensesList : [],
            //         claimTaggedTripList: this.claimObj.trips
            //     },
            // };
            // sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
            sessionStorage.setItem('toPathInfo', JSON.stringify(this.createNavInfo()));
        }, (err) => {
            this.stopCamera();
            this.capturedImage = 'assets/img/test.jpg';
        });
    // }
    }

    ocrReqModel = new ocrReqModel();
    ocrAttachment = new ocrAttachment();

    getOcrData() {
        this.ocrReqModel.attachments = [];
        let resp = this.expenseModel.expenseAttachments[this.expenseModel.expenseAttachments.length - 1];
        this.ocrAttachment.fileName = resp.fileName;
        this.ocrAttachment.base64Image = resp.content;
        this.ocrReqModel.attachments.push(this.ocrAttachment);
        this.ocrReqModel.extract = true;
        this.ocrReqModel.uploadType = 'expenses';
        this.imgUpload = true;
        this.expensesService.ocrDataPulling(this.ocrReqModel).subscribe(res => {
            this.imgUpload = false;
            if (!res.isError) {
                let attachedFile = this.expenseModel.expenseAttachments[this.expenseModel.expenseAttachments.length - 1];
                attachedFile.url = res.payload.attachments[0].uploadedURL;
                delete attachedFile.content;
                if (res.payload?.extractedData) {
                    this.expenseModel.originalAmount = res.payload.extractedData.invoiceTotal;
                    //   this.bindingOCrData(res.payload.extractedData);
                }
            } else {
                this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
            }
        });
    }

    createExpense() {
        if (this.imgUpload) {
            return;
        }
        this.expenseModel.legalEntityId = this.userInfo.legalEntityId;
        this.expenseModel.legalEntityGUID = this.userInfo.legalEntityGuId;
        this.expenseModel.userId = this.userInfo.userId;
        this.expenseModel.userGUID = this.userInfo.userGuId;
        this.expenseModel.categoryType = 1;
        this.createExpTab = true;
        this.expensesService.createImageExpense(this.expenseModel).subscribe(res => {
            if (!res.isError && res.errorCode === 0) {
                this.createExpTab = false;
                this.capturedImage = null;
                this.themeService.setTheme('camerabody');
                this.startCameraBelow();
                this.toastr.success("Expenses created successfully", "Success");
                this.expenseModel.expenseAttachments = [];
                this.expPicArray = [];

            }
            else {
                this.createExpTab = false;
                this.toastr.error(res.errorMessage, "Error");
            }

        });
    }
    nextPic() {
        this.createExpense();
        //  this.startCameraBelow();       
    }

    onClose() {
        this.startCameraBelow();
        this.themeService.setTheme('camerabody');
        this.capturedImage = null;
        this.expPicArray = [];
    }

    switchCamera() {
        this.cameraPreview.switchCamera();
    }

    show() {
        this.cameraPreview.show();
    }

    hide() {
        this.cameraPreview.hide();
    }

    changeZoom() {
        this.cameraPreview.setZoom(this.setZoom);
    }

    addPhotoToGallery() {
        this.photoService.addNewToGallery();
    }

    async addNewMileage(type) {
        this.capturedImage = null;
        this.stopCamera();
        const modal = await this.modalController.create({
            component: ExpenseAndMileageComponent,
            cssClass: "my-custom-class",
            componentProps: {
                type: type,
                imageArray: this.expPicArray
            },
        });
        return await modal.present();
    }
    getIndex() {
        this.slides.getActiveIndex().then((index) => {
            this.activeIndex = index;
        });
    }

    deletePhoto() {
        this.expPicArray.splice(this.activeIndex, 1);
    }

    ngOnDestroy() {
        this.themeService.removeTheme('camerabody');
        this.stopCamera();
    }
    async ionViewWillLeave() {
        this.capturedImage = null;
        await this.cameraPreview.hide();
        await this.stopCamera();
        await this.themeService.removeTheme('camerabody');
    }
    generateSessionKey() {
        var result, i, j;
        result = '';
        for (j = 0; j < 32; j++) {
            if (j == 8 || j == 12 || j == 16 || j == 20)
                result = result;
            i = Math.floor(Math.random() * 16).toString(16).toUpperCase();
            result = result + i;
        }
        return result;
    }

    async checkLocationStatus(value: boolean) {
        if (!value) {
            this.androidPermissions.requestPermissions([this.androidPermissions.PERMISSION.CAMERA]).then(
                result => this.showSettingsPopup(result.hasPermission),
                error => console.log('final return error')
            )
        } else {
            this.finalCallingMethods();
        }
    }

    showSettingsPopup(value: boolean) {
        if (!value) {
            this.allowCameraFromSettings();
        } else {
            this.finalCallingMethods();
        }
    }

    async finalCallingMethods() {
        await this.startCameraBelow();
        await this.cameraPreview.show();
        await this.themeService.setTheme('camerabody');
    }

    async allowLocationsFromSettings() {
        const alert = await this.alertController.create({
            mode: "md",
            message: "Location permission is must to use the Track Mileage functionality. Please enable your location permission in settings.",
            backdropDismiss: false,
            buttons: [
                {
                    text: 'No',
                    role: "cancel",
                },
                {
                    text: 'Yes',
                    handler: () => {
                        this.zone.run(() => {
                            //this.diagostic.switchToLocationSettings();
                            // this.goToDashBoard();
                            // window.location.reload();
                            // this.ionViewWillEnter();
                            this.diagostic.switchToSettings();
                        });
                    },
                },
            ],
        });
        await alert.present();
    }
    async allowCameraFromSettings() {
        const alert = await this.alertController.create({
            mode: "md",
            message: "Camera permission not enabled, Enable your camera permission in settings",
            backdropDismiss: false,
            buttons: [
                {
                    text: 'No',
                    role: "cancel",
                    handler: () => {
                        this.router.navigateRoot(['dashboard']);
                    }
                },
                {
                    text: 'Yes',
                    handler: () => {
                        this.zone.run(() => {
                            //this.diagostic.switchToLocationSettings();
                            this.goToDashBoard();
                            // window.location.reload();
                            // this.ionViewWillEnter();
                            this.diagostic.switchToSettings();
                        });
                    },
                },
            ],
        });
        await alert.present();
    }
    getUserPreferance() {
        let requestObj = {
            userId: this.userInfo.userId,
            userGUID: this.userInfo.userGuId,
        }
        this.userPreferance.getUserPreferance(requestObj).subscribe((res) => {
            if (!res.isError && res.errorCode === 0) {
                // console.log('user preferance setting data is', res.payload.mileageCategoryId);
                if (res.payload.mileageCategoryId){
                    this.checkUserPreferance = true;
                }else{
                    this.checkUserPreferance = false;
                }
            } else if (res.isError && res.errorCode === 2) {
                this.toastr.error(res.errorMessage, "Fail");
            }

        });
    }


    usePhoto() {
        // const byteCharacters = atob(this.base64);
        // const byteNumbers = new Array(byteCharacters.length);
        // for (let i = 0; i < byteCharacters.length; i++) {
        //   byteNumbers[i] = byteCharacters.charCodeAt(i);
        // }
        // const byteArray = new Uint8Array(byteNumbers);
        // const blob = new Blob([byteArray], { type: 'image/jpeg' });
        // const file = new File([blob], 'photo.jpg', { type: 'image/jpeg' });
        // this.expensesService.cameraImageFile = file;
        // // console.log(this.claimObj);
        
        // sessionStorage.setItem('toPathInfo', JSON.stringify(this.createNavInfo()));
        // console.log('fromPath _____ ', this.fromPath);
        if(this.fromPath == 'takePicture-Advance') {
            this.router.navigateRoot(['newAdvance']);
        } else {
            this.router.navigateRoot(['newExpenseMileageBulk']);
        }
    }

    retake() {
        sessionStorage.setItem('cameraRetake', 'true');
        this.router.navigateRoot(['dashboard']);
    }

    overrideBackButton() {
        this.platform.backButton.subscribeWithPriority(10, () => {
          if (this.takePictureExpenses) {
            // let navInfo = {
            //     url: 'dashboard',
            //     attachment: '',
            //     extractedData: '',
            //     fileName: Math.random().toString(36).substr(2, 15) + ".png",
            //     from: 'camera',
            //     backButton: true,
            //     report: {
            //         isLinkedToAdvancePayment: false,
            //         isLinkedToPettyCashPayment: false,
            //         claimName: this.takePictureExpenses? this.claimObj.name : 'Expense created on ' + moment().format("DD MMM HH:mm:ss"),
            //         claimStartDate: this.takePictureExpenses? this.claimObj.startDate : moment().format('DD-MM-YYYY'),
            //         claimEndDate: this.takePictureExpenses? this.claimObj.endDate : moment().format('DD-MM-YYYY'),
            //         tabIndex: this.tabIndex,
            //         expenseIndex: this.expenseIndex,
            //         expenseList: this.takePictureExpenses? this.expensesList : [],
            //         claimTaggedTripList: this.claimObj.trips
            //     },
            // };
            // sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
            sessionStorage.setItem('toPathInfo', JSON.stringify(this.createNavInfo()));
            if(this.fromPath == 'takePicture-Advance') {
                this.router.navigateRoot(['newAdvance']);
            } else {
                this.router.navigateRoot(['newExpenseMileageBulk']);
            }
          } else {
            this.router.navigateRoot(['dashboard']);
          }
        });
    }

    createNavInfo() {
        let navInfo: any;
        if(this.fromPath == 'takePicture-Advance') {
            this.router.navigateRoot(['newAdvance']);
            navInfo = {
                url: 'advance',
                fileName: Math.random().toString(36).substr(2, 15) + ".png",
                from: 'camera',
                advance: this.advanceObj,
                isReadOnly: false
            };
        } else {
            navInfo = {
                url: 'dashboard',
                attachment: '',
                extractedData: '',
                fileName: Math.random().toString(36).substr(2, 15) + ".png",
                from: 'camera',
                report: {
                    isLinkedToAdvancePayment: false,
                    isLinkedToPettyCashPayment: false,
                    claimName: this.takePictureExpenses? this.claimObj.name : 'Expense created on ' + moment().format("DD MMM HH:mm:ss"),
                    claimStartDate: this.takePictureExpenses? this.claimObj.startDate : moment().format('DD-MM-YYYY'),
                    claimEndDate: this.takePictureExpenses? this.claimObj.endDate : moment().format('DD-MM-YYYY'),
                    claimTypeId: this.claimObj?.claimTypeId,
                    claimTypeName: this.claimObj?.claimTypeName,
                    claimCategory: this.claimObj?.claimCategory,
                    claimCategoryId: this.claimObj?.claimCategoryId,
                    claimStartTime: this.claimObj?.claimStartTime,
                    claimEndTime: this.claimObj?.claimEndTime,
                    tabIndex: this.tabIndex,
                    expenseIndex: this.expenseIndex,
                    expenseList: this.takePictureExpenses? this.expensesList : [],
                    claimTaggedTripList: this.claimObj.trips
                },
            };
        }
        return navInfo;
    }
}
