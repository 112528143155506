import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, MinLengthValidator } from '@angular/forms';
import { PlatformService } from 'src/app/services/platformbase.service';
import { MenuController, NavController } from '@ionic/angular';
import { RestPwdModel } from 'src/app/models/Login/login.model';
import { AppConfig } from 'src/app/config/app.config';
import { Title } from '@angular/platform-browser';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  showreset = true;
  resetForm: FormGroup;
  platformType: number;
  validationMsg: any;
  submitted: boolean;
  guid: any;
  forgotPasswordId: any;
  buttonDisabled: boolean = false;
  resetPwdModel = new RestPwdModel();
  showErrorMessage:boolean = false;
  userInfo: any;

  constructor(
    public formBuilder: FormBuilder,
    public platformService: PlatformService,
    public authenticationService: AuthenticationService,
    public menuCtrl: MenuController,
    private title: Title,
    private toaster: ToastrService,
    private router: NavController,
    private activatedRoute: ActivatedRoute
  ) {
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
   }

  ngOnInit() {
    this.platformType = this.platformService.getPlatformType();
    this.validationMsg = AppConfig;
    this.guid = this.activatedRoute.snapshot.params['guid'];
    this.forgotPasswordId = this.activatedRoute.snapshot.params['forgotPasswordId'];
    this.resetForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
    });
  }

  ionViewWillEnter() {
    this.menuCtrl.enable(false);
    this.submitted = false;
    let applicationTitle = this.userInfo.applicationTitle;
    if(applicationTitle){
        this.title.setTitle('Reset Password' + ' | ' + applicationTitle);
    }else{ 
      this.title.setTitle('Reset Password | NoExpenses');
    }
  
  }

  get f() { return this.resetForm.controls; }

  resetFormSubmit(val) {
    this.submitted = true;
    if (!this.resetForm.valid) {
      return;
    } else {
      if (val.password === val.confirmPassword) {
        this.buttonDisabled = true;
        this.resetPwdModel.forgot_Password_Id = this.forgotPasswordId;
        this.resetPwdModel.forgot_Password_Guid = this.guid;
        this.authenticationService.resetEmail(this.resetPwdModel).subscribe(res => {
          if (!res.isError && res.errorCode === 0) {
            this.toaster.success(res.payload, 'Success');
            this.showErrorMessage = false;
            this.buttonDisabled = false;
            this.showreset = false;
          } else if (res.isError) {
            //this.toaster.error(res.errorMessage, 'Error');
            this.buttonDisabled = false;
            this.showErrorMessage = true;
            this.showreset = true;
          }
        });
      } else {
        this.toaster.error(
          "Create password and Confirm password should be same",
          "Error"
        );
      }
    }
  }

  retrunLogin() {
    this.router.navigateRoot(['login']);
  }

}
