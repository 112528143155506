import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AlertController, LoadingController, MenuController, NavController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { PlatformService } from './services/platformbase.service';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { ThemeService } from './services/theme.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslateconfigService } from './services/translateconfig.service';
// import { Storage } from '@ionic/storage';
import { SharedService } from './services/common-services/shared.service';
import { Location } from '@angular/common';
import { addWeekParseToken } from "ngx-bootstrap/chronos/parse/token";
import { AuthenticationService } from './services/authentication.service';
import { SelectLegalEntity } from './models/Settings/selecteLegalEntity.model';
import { CommonService } from './services/common-services/common.service';
import { LoginModel } from './models/Login/login.model';
import { ConnectivityService } from './services/connectivity.service';
import { SnackbarService } from './services/snackbar.service';
import { environment } from 'src/environments/environment';
import { log } from 'console';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { AppVersion } from '@ionic-native/app-version/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';


@Component({
    selector: "app-root",
    templateUrl: "app.component.html",
    styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
    pages: any;
    platformType: number;
    isAuthenticate: any;
    userInfo: any;
    refreshTokenTime: any;
    refreshTokenCount: number;
    selectLegalEntityModel = new SelectLegalEntity();
    profileImgPath: any;
    isAdmin = false;
    loginModel = new LoginModel();
    isPettyCashEnabled: boolean;
    isLocalConveyanceEnabled: boolean;
    connectionError: boolean = false;
    isFC365: boolean;
    favIcon: HTMLLinkElement = document.querySelector('#appIcon');
    isAndroidIOS: any;
    appLogo: any;
    maintainenceModal: BsModalRef;
    
    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private platformService: PlatformService,
        public router: NavController,
        public auth: AuthService,
        private theme: ThemeService,
        private translateconfig: TranslateconfigService,
        // public storage: Storage,
        public sharedService: SharedService,
        public menuCtrl: MenuController,
        public alertController: AlertController,
        private location: Location,
        private loadingController: LoadingController,
        private refreshToken: AuthenticationService,
        private snakbar: SnackbarService,
        private connectivity: ConnectivityService,
        private active_router: Router,
        private translate: TranslateService, private commonService: CommonService,
        private modalService: BsModalService,
        private appVersion: AppVersion,
        private iab: InAppBrowser
        // private authenticationService: AuthenticationService,
    ) {
        this.platformType = this.platformService.getPlatformType();
        this.isAndroidIOS = this.platformService.isAndroidIOS();
        this.sharedService.getUserInfo().subscribe((val) => {
            if (val) {
                this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
                this.getProfilePath();
            } else {
                this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
                this.getProfilePath();
            }
            this.isFC365 = JSON.parse(localStorage.getItem("fc365"));
            this.pages = [];
            if(this.isFC365){
                this.pages = [
                    {
                        id: 1,
                        show: true,
                        title: "Dashboard",
                        url: "dashboardfc365",
                        imgs: "assets/svg/more365.svg",
                    },
                    {
                        id: 2,
                        show: true,
                        title: "Travel",
                        url: "traveleva",
                        imgs: "assets/svg/travelblack.svg",
                    },
                    {
                        id: 3,
                        show: true,
                        title: "Expenses",
                        url: "dashboard",
                        imgs: "assets/svg/expenseblack.svg",
                    },
                    // {
                    //     id: 6,
                    //     show: true,
                    //     title: "Change Password",
                    //     url: "changepassword",
                    //     imgs: "assets/svg/changepw365.svg",
                    // },
                    {
                        id : 8,
                        title : 'Sign Out',
                        show: true,
                        imgs : 'assets/svg/logoutblack.svg',
                        url: "login"
                    }]

            }else{
                this.pages = [
                    {
                        id: 1,
                        show: true,
                        title: "Expense Home",
                        url: "dashboard",
                        imgs: "assets/svg/home-dark.svg",
                    },
                    {
                        id: 2,
                        show: true,
                        title: "Expenses",
                        url: "expenses",
                        imgs: "assets/svg/expenses.svg",
                    },
                    // {
                    //     id: 3,
                    //     show: true,
                    //     title: "Reports",
                    //     url: "reports",
                    //     imgs: "assets/svg/reports.svg",
                    // },
                    {
                        id: 4,
                        show: true,
                        title: "Advance",
                        url: "advance",
                        imgs: "assets/svg/menuAdvance.svg",
                    },
                    {
                        id: 5,
                        show: true,
                        title: "Approvals",
                        url: "approvals",
                        imgs: "assets/svg/approval.svg",
                    },
                    // {
                    //     id: 6,
                    //     title: "Petty Cash",
                    //     show: this.userInfo?.isPettyCashEnabled ? this.userInfo?.isPettyCashEnabled : false,
                    //     imgs: "assets/svg/menuPeetycash.svg",
                    //     "childMenu": [
                    //         {
                    //             id: 12,
                    //             title: "Request",
                    //             url: "petty-cash",
                    //             imgs: "assets/svg/approval.svg"
                    //         },
                    //         {
                    //             id: 13,
                    //             title: "Expense",
                    //             url: "petty-cash/expense",
                    //             imgs: "assets/svg/approval.svg"
                    //         },
                    //         {
                    //             id: 14,
                    //             title: "Reports",
                    //             url: "petty-cash/report",
                    //             imgs: "assets/svg/approval.svg"
                    //         }
                    //     ]
                    // },
                    // {
                    //     id:7,
                    //     show:true,
                    //     title: "Analytics",
                    //     url: "analytics",
                    //     imgs: "assets/svg/approval.svg",
                    // },
                    // {
                    //     id: 8,
                    //     show: this.userInfo?.isLocalConveyanceEnabled ? this.userInfo?.isLocalConveyanceEnabled : false,
                    //     title: "Local Conveyance",
                    //     url: "localconveyance",
                    //     imgs: "assets/svg/menuLC.svg",
                    // },
                    // {
                    //     id:9,
                    //     show:this.userInfo?.roleId == 2,
                    //     title: "Settings",
                    //     url: "settings/accountconfig",
                    //     imgs: "assets/svg/advance.svg",
                    // },
                    // {
                    //     id: 10,
                    //     show: true,
                    //     title: "Change Password",
                    //     url: "changepassword",
                    //     imgs: "assets/svg/menuChangePW.svg",
                    // },
                    // {
                    //     id: 12,
                    //     show: true,
                    //     title: "Vehicle Preferences",
                    //     url: "mileagedefaults",
                    //     imgs: "assets/svg/edit-icon.svg",
                    // },
                    {
                        id: 11,
                        show: true,
                        title: "Sign Out",
                        url: "login",
                        imgs: "assets/svg/logout.svg",
                    }
                ];
            }
        });
        this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        if (this.userInfo && (this.platform.is('android') || this.platform.is('ios'))) {
            this.refreshingToken();
        }
        this.initializeApp();
        this.setUserTheme();
        this.setLanguage();

    }
    ngOnInit() {
        this.appLogo = environment.appLogo;
        if(this.userInfo?.favIconUrl){
            this.favIcon.href = this.userInfo.favIconUrl;
        }else{
            this.favIcon.href = './assets/images/Noexpfav.svg';
        }
        this.changeUser(this.isAdmin);
        this.sharedService.getUserToggle().subscribe((val) => {
            this.isAdmin = !val;
        });
        this.refreshToken.AuthenticateStatus().subscribe(status => {
            if (status === true) {
                this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
                //this.refreshTokenTime = setInterval(() => this.refreshingToken(), 30000);
                this.refreshTokenTime = setInterval(() => this.refreshingToken(), 60000);
            } else if (status === false) {
                clearInterval(this.refreshTokenTime);
                this.refreshTokenCount = 0;
            }
        });

        this.connectivity.appIsOnline$.subscribe(online => {
            if (online && this.connectionError) {
                this.connectionError = false;
                this.snakbar.success();
            } else if (!online && !this.connectionError) {
                this.connectionError = true;
                this.snakbar.error();
            }
        });
    }

    refreshingToken() {


        const tokenCount = JSON.parse(sessionStorage.getItem('refreshTokenCount'));
        this.refreshTokenCount = tokenCount + 1;
        sessionStorage.setItem('refreshTokenCount', JSON.stringify(this.refreshTokenCount));
        if (this.refreshTokenCount >= 55) {
            this.tokenRefresh(this.userInfo);
        }
    }

    clearInterval() {
        clearInterval(this.refreshTokenTime);
    }

    menutoggle() {
        this.menuCtrl.enable(true, 'mainMenu');
        this.menuCtrl.enable(false, 'subMenu');
    }

    setUserTheme() {
        if (sessionStorage.getItem("theme") != null) {
            this.theme.applyTheme(JSON.parse(sessionStorage.getItem("theme")));
        } else {
            this.theme.applyTheme(null);
        }
    }

    setLanguage() {
        if (sessionStorage.getItem("current-lang") != null) {
            this.translateconfig.setLanguage(sessionStorage.getItem("current-lang"));
        } else {
            this.translateconfig.setLanguage("en");
        }
    }
    initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.overlaysWebView(false);
            this.splashScreen.hide();

            if (this.platform.is('android') || this.platform.is('ios')) {
                // this.checkLogin();
                this.versionCheck();
                // this.tokenRefresh(this.userInfo);
            }
        });
        this.platform.backButton.subscribeWithPriority(10, (processNextHandler) => {
            if (this.location.isCurrentPathEqualTo('/dashboard')) {

                this.showExitConfirm();
                processNextHandler();

            } else {
                this.router.navigateRoot(["/dashboard"]);
            }
        });
        this.platform.backButton.subscribeWithPriority(5, () => {
            this.alertController.getTop().then(r => {
                if (r) {
                    navigator['app'].exitApp();
                }
            }).catch(e => {
            });
        });
    }

    async versionCheck() {
        Promise.all([
            this.appVersion.getVersionNumber(),
            this.appVersion.getAppName(),
            this.appVersion.getPackageName()
        ]).then(([version, appName, packageName]) => {
            const platformName = this.platform.is('ios') ? 'iOS' : 'Android';

            this.commonService.getMobileAppVersion({
                currentVersion: version,
                mobileAppName: `${appName}_${platformName}`
            }).subscribe(
                (response) => {
                    if (!response.isError && response.errorCode === 0) {
                        if (this.versionToFloat(version) < this.versionToFloat(response.payload.mobileVersion)) {
                            this.showUpdatePopup(packageName);
                        }
                    }
                });
        }).catch((error) => {
            console.log('App Info Error:' + error);
        });
    }

    async showUpdatePopup(packageName: any) {
        // Mapping of package names to iOS app IDs
        const appIdMap: { [key: string]: string } = {
            "com.mobile.tvista": "6462688267",
            "com.evasotc.fastcollab": "6443447020",
            "com.noexpensesprod.fastcollab": "6444086013",
            "com.fastcollab.travelLounge": "6503349396",
            "com.fastcollab.evatravel": "6450987393",
            "com.mobile.pawpl": "6462118411",
            "com.Berry.fastcollab": "6444359560",
            "com.Ivyclub.fastcollab": "6450501921",
            "com.fastcollab365.fc": "1665838930",
            "com.RxTravelEase.fastcollab": "6484507381",
            "com.satguru.fastcollab": "6451042846",
            "com.iosapp.evatravel": "1580365489",
            "com.jetrix.fastcollab": "6451114683",
        };

        // Get the app ID based on the package name
        const appID = appIdMap[packageName];
        const alert = await this.alertController.create({
            cssClass: 'ios-centered-alert',
            header: 'Update Required',
            subHeader: "Please Update to Continue",
            message: "We've made important updates to enhance your app experience. Please update to the latest version to continue using the app.",
            backdropDismiss: false, // Ensure the user cannot dismiss it if the update is mandatory
            mode: 'ios',
            buttons: [
                {
                    text: "Update Now",
                    handler: () => {
                        this.logout();
                        const appStoreUrl = this.platform.is('ios')
                            ? "https://apps.apple.com/app/id" + appID
                            : "https://play.google.com/store/apps/details?id=" + packageName;
                        if(this.platform.is('ios')) {
                            this.iab.create(appStoreUrl, '_blank', 'location=yes');
                        } else {
                            window.open(appStoreUrl, '_system');
                        }
                    },
                },
            ],
        });

        await alert.present();
    }

    versionToFloat(version: string): number {
        return parseFloat(version.replace(/\./g, ''));
    }

    async showExitConfirm() {
        const alert = await this.alertController.create({
            cssClass: '',
            mode: "md",
            id: "hardwareConform",
            header: this.translate.instant('common.conform'),
            message: `<strong>${this.translate.instant('common.exitapp')}</strong>`,
            backdropDismiss: true,
            buttons: [
                {
                    text: this.translate.instant('common.cancel'),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (blah) => {
                        this.close();
                    }
                }, {
                    text: this.translate.instant('common.okay'),
                    handler: () => {
                        navigator['app'].exitApp();
                    }
                }
            ]
        });
        this.commonService.storeModal(alert);
        await alert.present();
    }

    close() {
        for (var i = 0; i < this.commonService.modalInst.length; i++) {
            this.commonService.modalInst[i].dismiss();
        }
    }

    async checkLogin() {
        const userData = JSON.parse(localStorage.getItem('userInfo'));
        if (userData) {
            const loading = await this.loadingController.create({
                message: `Checking login session...`
            });
            await loading.present();
            //this.tokenRefresh(userData);
            sessionStorage.setItem("isAuthenticated", JSON.stringify(true));
            this.sharedService.setUserInfo(true);
            await this.menuCtrl.enable(true, 'mainMenu');
            await this.menuCtrl.enable(false, 'subMenu');
            sessionStorage.setItem("current-lang", userData.baseLanguage);
            const obj = {
                primary: userData.primaryColor,
                secondary: userData.secondaryColor,
                fontFamily: userData.font
            };
            sessionStorage.setItem('theme', JSON.stringify(obj));
            this.theme.applyTheme(obj);
            localStorage.setItem('userInfo', JSON.stringify(userData));
            sessionStorage.setItem('userInfo', JSON.stringify(userData));
            this.loadingController.dismiss();
            localStorage.removeItem("gpsDistance");
            // this.router.navigateRoot(["dashboard"]).then();

            let fc365Obj = userData.appsUrl;
            if (fc365Obj) {
                let obj365url = fc365Obj[0].value;
                localStorage.setItem('fc365', JSON.stringify(true));
                localStorage.setItem('fc365url', JSON.stringify(obj365url));
                // this.router.navigateRoot(['dashboardfc365']).then();
                // this.router.navigateRoot(['dashboard']).then();
                if (environment.appLogo == "fc365") {
                    this.router.navigateRoot(['dashboardfc365']).then();
                } else {
                    if(this.active_router.url.includes('/emailApproval/')){
                    }else{
                        this.router.navigateRoot(['dashboard']).then();
                    }
                }
            } else {

                if(this.active_router.url.includes('/emailApproval/')){
                }else{
                    this.router.navigateRoot(['dashboard']).then();
                }
            }


            // this.router.navigateRoot(["dashboard"]).then();
        } else if (localStorage.getItem("intr") == "true") {
            this.router.navigateRoot(["login"]).then();
            await this.menuCtrl.enable(false, 'mainMenu');
            await this.menuCtrl.enable(false, 'subMenu');
        }
    }

    tokenRefresh(userInfo) {
        if (userInfo != null) {
            const requestbody = {
                email: userInfo.email,
                refreshToken: userInfo.refreshToken
            };
            this.refreshToken.refreshToken(requestbody).subscribe(res => {
                if (res.payload) {
                    this.userInfo = userInfo;
                    this.userInfo.accessToken = res.payload.sessionInfo.accessToken;
                    const userData = JSON.stringify(this.userInfo);
                    sessionStorage.setItem('userInfo', userData);
                    localStorage.setItem('userInfo', userData);
                    sessionStorage.setItem('token', res.payload.sessionInfo.accessToken);
                    localStorage.setItem('token', res.payload.sessionInfo.accessToken);
                    this.refreshTokenCount = 0;
                    sessionStorage.setItem('refreshTokenCount', JSON.stringify(this.refreshTokenCount));
                    this.refreshToken.status.next(true);
                    this.clearInterval();
                    // if (this.platformType == 2) {
                    //     this.refreshToken.status.next(true);
                    // }
                } else {
                    localStorage.removeItem("gpsDistance");
                    //this.router.navigateRoot(["login"]);
                    this.refreshToken.status.next(false);
                    this.clearInterval();
                    // if (this.platformType == 1) {
                    //     window.location.reload();
                    // }
                }
                if(res?.payload?.maintenanceCode == 1 || res?.payload?.maintenanceCode == 2) {
                    this.sharedService.setMaintenanceflag(true);
                }
            }, err => {
                localStorage.removeItem("gpsDistance");
                // this.router.navigateRoot(["login"]);
                this.refreshToken.status.next(false);
                this.clearInterval();
                // if (this.platformType == 1) {
                //     window.location.reload();
                // }
            });
        } else {
            this.refreshToken.status.next(false);
        }
    }

    navigateTo(page) {
        if(page.url == 'traveleva' || page.url == 'dashboardfc365') {
            this.refreshToken.setShowHeaderEV(false);
        } else {
            this.refreshToken.setShowHeaderEV(true);
        }

        if (page.url == 'mileagedefaults') {
            localStorage.setItem('fromUP', 'menu');
        }
        if ((page.url == 'traveleva')) {

            this.router.navigateRoot(["/" + page.url]);

            //    var options = ['location=no','hardwareback=yes','toolbarposition=top', 'toolbar=yes','enableViewportScale=yes',
            // 'transitionstyle=fliphorizontal',
            // 'closebuttoncaption=Back to the App'];

            // const options: InAppBrowserOptions= {
            //     zoom: 'no',
            //     hideurlbar: 'yes', // hide the url toolbar
            //     hidenavigationbuttons: 'yes', // hide navigation buttons back/forward
            //     location : 'yes',
            //     toolbar : 'yes',
            //     // toolbarcolor : 'black',
            //     // closebuttoncolor : 'white',
            //     hardwareback : 'yes',
            //     toolbarposition : 'top',
            //     enableViewportScale : 'yes' ,
            //    transitionstyle : 'fliphorizontal',
            //    closebuttoncaption : 'Dashboard'
            //   }
            // // this.theInAppBrowser.create('https://devdemo.eva.travel/login', '_self',  options );
            // this.theInAppBrowser.create('https://www.google.com/', '_self',  options );

        }else{
            this.router.navigateRoot(["/" + page.url]);
        }

    }

    signOut(page) {
        if (page.title === 'Sign Out') {
            sessionStorage.clear();
            localStorage.removeItem('userInfo');
            localStorage.removeItem('token');
            localStorage.removeItem('fc365');
            localStorage.removeItem('fc365url');
            this.sharedService.setUserInfo(false);
            this.sharedService.setRefreshData(false);
            this.sharedService.setExpenseData(null);
            this.theme.applyTheme(null);
            this.router.navigateRoot(["login"]);
            this.menuCtrl.enable(false, 'mainMenu');
            this.menuCtrl.enable(false, 'subMenu');
        }
    }

    setSettings(page) {
        if (page.title === 'Settings') {
            this.menuCtrl.enable(false, 'mainMenu');
            this.menuCtrl.enable(true, 'subMenu');
            this.selectLegalEntityModel.companyId = this.userInfo.companyId;
            this.selectLegalEntityModel.companyGuId = this.userInfo.companyGuId;
            this.selectLegalEntityModel.legalEntityId = this.userInfo.legalEntityId;
            this.selectLegalEntityModel.legalEntityGuId = this.userInfo.legalEntityGuId;
            this.selectLegalEntityModel.legalEntityName = this.userInfo.companyName;
            sessionStorage.setItem('selectedLegalEntity', JSON.stringify(this.selectLegalEntityModel));
        }
    }

    navigateToProfile() {
        this.menuCtrl.close();
        if (this.userInfo.roleId === 1) {
            this.router.navigateRoot(['/userpersonalInfo']);
        } else {
            this.router.navigateRoot(['/personalInfo']);
        }
    }

    getProfilePath() {
        this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
        this.sharedService.getUserLogo().subscribe((data => {
            if (!data) {
                if (this.userInfo) {
                    if (this.userInfo.profilePicPath) {
                        this.profileImgPath = this.userInfo.profilePicPath;
                    } else if (!this.userInfo.profilePicPath) {
                        if (this.userInfo.gender) {
                            if (this.userInfo.gender === "Male") {
                                this.profileImgPath = "./assets/images/userdefaultmale.svg";
                            } else if (this.userInfo.gender === "Female") {
                                this.profileImgPath = "./assets/images/userdefaultfemale.svg";
                            }
                        } else if (!this.userInfo.gender) {
                            this.profileImgPath = "./assets/images/userdefaultmale.svg";
                        }
                    }
                }
            } else {
                this.profileImgPath = data;
            }
        }));
    }

    changeUser(admin: any) {
        this.isAdmin = admin;
        //sessionStorage.setItem("isadmin", JSON.stringify(this.isAdmin));
        this.sharedService.setUserToggle(!this.isAdmin);
    }

    factCollab365Submit() {

        // this.submitted = true;
        // this.loginModel.email = null;
        // this.loginModel.password = null;
        // this.loginModel.token = 'qAAx5bx2PspnoDTUkf9LS5pg7zswnGyTEsJ2Op6v27bDhVBM6PKdbxVRZWEi2bvENWV6Dxn1M2Wfi17ENJa9/yPr4eVPm09e5vTSJIYN1FDO9SmcgiCnowNuLgMZdwBnw1Axp1tfxAdiUvYStl0NftmgvF0kxX5DJ0z61DOPsQ8='
        // this.buttonDisabled = true;

        this.refreshToken.authenticateUser(this.loginModel).subscribe(res => {
            if (!res.isError && res.errorCode === 0 && res.payload) {
                sessionStorage.setItem("isAuthenticated", JSON.stringify(true));
                sessionStorage.setItem("isAttachmentMandatory", JSON.stringify(res.payload.isAttachmentMandatory))
                sessionStorage.setItem("userInfo", JSON.stringify(res.payload));
                localStorage.setItem("userInfo", JSON.stringify(res.payload));
                this.sharedService.setUserInfo(true);
                this.menuCtrl.enable(true, 'mainMenu');
                this.menuCtrl.enable(false, 'settingMenu');
                // this.isRememberedMe();
                //  this.buttonDisabled = false;
                sessionStorage.setItem("current-lang", res.payload.baseLanguage);
                // this.authenticationService.status.next(true);

                const obj = {
                    primary: res.payload.primaryColor,
                    secondary: res.payload.secondaryColor,
                    fontFamily: res.payload.font
                };
                sessionStorage.setItem('theme', JSON.stringify(obj));
                this.theme.applyTheme(obj);
                if (res.payload.forceChangePassword) {
                    this.router.navigateRoot(['changepassword']);
                } else {
                    //this.router.navigateRoot(['dashboard']);
                    if (this.platformType == 1) {
                        this.router.navigateRoot(['dashboard']);
                    } else if (this.platformType == 2) {
                        this.router.navigateRoot(['camera']);
                    }
                }
            } else if (res.isError && res.errorCode === 2) {
                // this.buttonDisabled = false;
                // this.toaster.error(res.errorMessage, 'Error');
            }
        });
    }

    closeMaintainceModal() {
        if (this.userInfo && this.userInfo?.maintenanceCode == 2) {
            sessionStorage.removeItem('isAuthenticated');
            this.logout();
        } else {
            this.maintainenceModal?.hide();
        }
    }


    logout() {
        this.router.navigateRoot(["login"]);
        sessionStorage.clear();
        localStorage.clear();
        // this.sharedService.setUserInfo(false);
        this.sharedService.setRefreshData(false);
        this.sharedService.setExpenseData(null);
        this.sharedService.setCompanyInfo(null);
        this.sharedService.setMapData(null);
        this.sharedService.setPreCategory(null);
        this.sharedService.setPeningApprovalCount(null);
        // this.theme.applyTheme(null);
    }

    ngAfterViewInit() {
        this.sharedService.getMaintenanceflag().subscribe(value => {
            this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
            if (value || (this.userInfo && (this.userInfo?.maintenanceCode == 1 || this.userInfo?.maintenanceCode == 2))) this.maintaincePopup();
        });
    }

    @ViewChild("alertPopUpForMaintenance", { static: false }) private alertPopUpForMaintenance;
    maintenanceMessage: any;
    maintaincePopup() {
        this.maintainenceModal?.hide();
        if (this.userInfo && this.userInfo?.maintenanceCode == 2) {
            localStorage.removeItem('isAuthenticated');
            sessionStorage.removeItem('isAuthenticated');
        }
        this.maintenanceMessage = this.userInfo?.maintenanceMessage;
        const config = {
            ignoreBackdropClick: true,
            class: "modal-lg modal-dialog-centered",
            keyboard: false,
        };
        this.maintainenceModal = this.modalService.show(this.alertPopUpForMaintenance, config);
    }
}
