import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
// import { Storage } from '@ionic/storage';
import { NavController } from '@ionic/angular';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(public auth: AuthService, public router: NavController, 
    // public storage: Storage
  ) { }
  canActivate(): boolean {
    if (this.auth.isAuthenticated()) {
      sessionStorage.clear();
      // this.storage.clear();
      this.router.navigateRoot(['/login']);
      return false;
    }
    return true;
  }

  // canActivate(): boolean {
  //   if(this.auth.isAuthenticate()){
  //     this.storage.remove('isAuthenticated');
  //     this.router.navigateRoot(['/login']);
  //     return false;
  //   }
  //   else{
  //     return true;
  //   }
  //   return this.storage.get('isAuthenticated').then(result => {
  //     if(result) {
  //       return true;
  //     }
  //     this.router.navigateRoot(['/login']);
  //     return false;
  //   })
  //  }
}
