import { Component, OnInit, ViewChild } from '@angular/core';
import { PlatformService } from 'src/app/services/platformbase.service';
import { FormGroup } from '@angular/forms';
import { Title } from "@angular/platform-browser";
import { NavController } from '@ionic/angular';
import { MatTableDataSource } from '@angular/material/table';
import { SettingsService } from 'src/app/services/settings-services';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { EnableDisableModel } from 'src/app/models/Settings/approval-workflow.model';

@Component({
  selector: 'app-approval-workflow',
  templateUrl: './approval-workflow.component.html',
  styleUrls: ['./approval-workflow.component.scss'],
})
export class ApprovalWorkflowComponent implements OnInit {
  platformType: number;
  // workflowForm: FormGroup;
  buttonDisabled: boolean = false;
  showLoader: boolean = true;
  showTable: boolean = false;
  loadersList = [1, 2, 3, 4];
  userInfo: any;
  status: Array<number> = [];
  currentPage = 0;
  pageSize = 25;
  selectedIndex: any = -1;
  displayedColumns: any[] = ['servicetype', 'workflowname', 'description', 'status', 'disablestatus'];
  dataSource: MatTableDataSource<any>;
  tableDataList: any;
  statusDisable: boolean = false;
  approvals = [
    { 'productName': 'Report', 'workflowName': "Milage", 'workflowDesc': "Report created", "status": 1 },
    { 'productName': 'Advance', 'workflowName': "amount", 'workflowDesc': "Advance created", "status": 2 }
  ]
  selectedLegalEntity: any;
  requestBody: { legalEntityId: any; legalEntityGUID: any; type: any };
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  showingStatus: boolean = false;
  approvalObj: any;
  statusFlag: any;
  modalRef: BsModalRef;
  statusMessage: string;
  isApicallinProgress: boolean = false;
  pageNumber: number = 1;
  PageLimit: number[] = [5, 10, 25, 100];
  typeStr: string;
  paginatorStatus:boolean = false;
  policiesList: any;

  constructor(private platformService: PlatformService, private modalService: BsModalService,
    private title: Title, private router: NavController, private settingsService: SettingsService

  ) { }

  ngOnInit() {
    this.typeStr = sessionStorage.getItem('typeStr');
  }

  ionViewWillEnter() {
    this.platformType = this.platformService.getPlatformType();
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    let applicationTitle = this.userInfo.applicationTitle;
    if(applicationTitle){
      this.title.setTitle('Manager Workflow | Settings' + ' | ' + applicationTitle);
    }else{ 
      this.title.setTitle("Manager Workflow | Settings | NoExpenses");
    }
    this.getApprovalFlow();


  }
  addNewFlow() {
    this.router.navigateRoot(['/settings/approvalworkflow/add'])
  }
  navigateToEditWorkFlow(data) {
    this.router.navigateRoot(['/settings/approvalworkflow/update', data.categoryId, data.categoryGUID]);
  }

  getApprovalFlow() {
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.selectedLegalEntity = JSON.parse(sessionStorage.getItem('selectedLegalEntity'));
    // this.requestBody = {
    //   "legalEntityId": this.selectedLegalEntity.legalEntityId,
    //   "legalEntityGUID": this.selectedLegalEntity.legalEntityGuId,
    //   "type": this.typeStr,
    // };
    let requestBody = {
      legalEntityId:this.selectedLegalEntity.legalEntityId,
      legalEntityGUID: this.selectedLegalEntity.legalEntityGuId,
      type: this.typeStr,
      pageNumber:this.pageNumber,
      pageSize: this.pageSize
    }
    this.settingsService.getApprovalWorkflows(requestBody).subscribe(data => {
      if (data.payload.length > 0) {
        // this.dataSource = new MatTableDataSource(data.payload);
        // this.dataSource.paginator = this.paginator;
        // this.dataSource.sort = this.sort;
        this.tableDataList = data.payload;
        data.payload.forEach((value, index) => {
          this.status[index] = value.statusId;
        });
        if(this.paginatorStatus){
          let listitems = data?.payload;
          listitems.forEach(element => {this.policiesList.push(element);});
          this.dataSource = new MatTableDataSource(this.policiesList);
          this.dataSource.paginator = this.paginator;
        }else{
          this.policiesList = data?.payload;
          this.dataSource = new MatTableDataSource(this.policiesList);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          if(this.pageNumber == 1){
            this.paginatorStatus = true;
            this.pageNumber++;
            this.getApprovalFlow();
          }
        }
        this.pageNumber != 2 ? this.paginatorStatus = false : '';
        this.showLoader = false;
        this.showTable = true;
        this.isApicallinProgress = false;
      } else {
        this.showLoader = false;
        this.showTable = true;
        this.policiesList.length != 0 ? this.tableDataList = this.policiesList : this.tableDataList = null;
      }
    });
  }

  hideDropdown() {
    if (this.selectedIndex !== -1) {
      this.selectedIndex = -1;
    }
  }

  navigateToUpdateWorkflow(row: any) {
    this.router.navigateRoot(['/settings/approvalworkflow/update', row.approvalWorkflowId, row.approvalWorkflowGUID,row.statusId])
  }
  toggleStatusDropdown(index) {
    if (this.selectedIndex != index)
      this.selectedIndex = index;
    else
      this.selectedIndex = -1;
  }

  handlePage(e) {
    this.currentPage = e.pageIndex;
    // this.pageSize = e.pageSize;
    if(!this.dataSource.paginator.hasNextPage()){
      this.paginatorStatus = true;
      this.pageNumber++;
      this.getApprovalFlow();
    }
  }

  loadMore(event) {
    if (!this.isApicallinProgress) {
      if (this.tableDataList.length < this.pageSize && event) {
        event.target.disabled = true;
      } else {
        this.pageNumber++;
        this.getApprovalFlow();
      }
    }
  }

  toggleStatus(data, content) {
    this.approvalObj = data;
    this.modalRef = this.modalService.show(content, { class: 'modal-md modal-dialog-centered' });
    switch (this.approvalObj.statusId) {
      case 1: this.statusMessage = "Are you sure you want to disable Approval?";
        break;
      case 2: this.statusMessage = "Are you sure you want to enable Approval?";
        break;
    }
    this.hideDropdown();
  }
  enableDisableModel = new EnableDisableModel();
  statusAction() {
    this.statusDisable = true;
    this.showingStatus = true;
    this.enableDisableModel.approvalWorkflowId = this.approvalObj.approvalWorkflowId;
    this.enableDisableModel.approvalWorkflowGUID = this.approvalObj.approvalWorkflowGUID;
    this.selectedIndex = -1;
    this.enableDisableModel.type=this.typeStr;
    if (this.approvalObj.statusId == 2) {
      this.settingsService.enableApproval(JSON.stringify(this.enableDisableModel)).subscribe(data => {
        if (data.errorCode == 0) {
          this.approvalObj.statusId = 1;
          this.showingStatus = false;
          this.closeWindow();
          this.statusDisable = false;
        } else {
          this.showingStatus = false;
          this.closeWindow();
        }
      });
    } else {

      this.settingsService.disableApproval(JSON.stringify(this.enableDisableModel)).subscribe(data => {
        if (data.errorCode == 0) {
          this.approvalObj.statusId = 2;
          this.closeWindow();
          this.showingStatus = false;
        } else {
          this.closeWindow();
          this.showingStatus = false;
        }
      });

    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.policiesList=this.dataSource.filteredData
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  closeWindow() {
    this.modalRef.hide();
  }
}
