export class  ExchangeRate {
    currencyExchangeId: number;
    currencyExchangeGuid: string;
    legalEntityId: number;
    legalEntityGuid: string;
    fromCurrencyId: number;
    toCurrencyId: number;
    fromCurrency: string;
    toCurrency: string;
    rate: number;
    fromDate: any;
    toDate: any;
    statusId: number;
    pageNumber: any;
    pageSize: any;
    searchText:string;
    sortColumn:any;
    sortType: string;
  }
  export class  ExchangeRateList {
    currencyExchangeId: number;
    currencyExchangeGuid: string;
    legalEntityId: number;
    legalEntityGuid: string;
    fromCurrencyId: number;
    toCurrencyId: number;
    fromCurrency: string;
    toCurrency: string;
    rate: number;
    fromDate: any;
    toDate: any;
    statusId: number;
    pageNumber: any;
    pageSize: any;
    searchText:any;
    sortColumn:any;
    sortType: string;
    constructor(List){
      this.currencyExchangeId = List.currencyExchangeId;
      this.currencyExchangeGuid = List.currencyExchangeGuid;
      this.legalEntityId = List.legalEntityId;
      this.legalEntityGuid = List.legalEntityGuid;
      this.fromCurrencyId = List.fromCurrencyId;
      this.toCurrencyId = List.toCurrencyId;
      this.toCurrency = List.toCurrency;
      this.rate = List.rate;
      this.fromDate = List.fromDate;
      this.statusId = List.statusId;
      this.toDate  = List.toDate;
      this.fromCurrency = List.fromCurrency
      this.statusId = List.statusId
    }
  }