import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlphaNumericDirective, alphabetsDirective, numbersDirective, AlphaNumericSpaceDirective, alphabetsSpecialChar, emailPatter, numbersSpacesDirective, DesimalDirective, specialCharectersDirective, ConvertToTitleCasePipe, } from 'src/app/directives/alpha-numeric.directive';
import { AutoFocusDirective, ClickOutsideDirective } from './custom.directive';
import { ToolTipRendererDirectiveDirective } from './tool-tip-renderer-directive.directive';
@NgModule({
  declarations: [
    ClickOutsideDirective,
    AlphaNumericDirective,
    alphabetsDirective,
    numbersDirective,
    alphabetsSpecialChar,
    emailPatter,
    numbersSpacesDirective,
    AlphaNumericSpaceDirective,
    DesimalDirective,
    AutoFocusDirective,
    ToolTipRendererDirectiveDirective,
    specialCharectersDirective,
    ConvertToTitleCasePipe
  ],
  imports: [
    CommonModule
  ],
  exports:[
    ClickOutsideDirective,
    AlphaNumericDirective,
    alphabetsDirective,
    numbersDirective,
    alphabetsSpecialChar,
    emailPatter,
    numbersSpacesDirective,
    AlphaNumericSpaceDirective,
    DesimalDirective,
    AutoFocusDirective,
    ToolTipRendererDirectiveDirective,
    specialCharectersDirective,
    ConvertToTitleCasePipe
  ]
})
export class CommonDirectivesModule { }
