import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CategoryAndMerchantModel } from 'src/app/models/Common/common.model';
import { CommonService } from 'src/app/services/common-services/common.service';
import { ExpensesService } from 'src/app/services/expenses.service';
import { SettingsService } from 'src/app/services/settings-services';

@Component({
  selector: 'app-add-budget',
  templateUrl: './add-budget.component.html',
  styleUrls: ['./add-budget.component.scss'],
})
export class AddBudgetComponent implements OnInit {
  userInfo: any;
  addBudgetForm: FormGroup;
  modalRef: BsModalRef;
  editData = {budgetId: null, budgetGUID: null };
  loadersList = [1, 2, 3, 4];
  budgetObj: any = {
    bName: '',
    bType: '',
    fYear: '',
    sMonth: '',
  };
  budgetLimitsList: any = [];
  showLoaders = false;
  // selected_bType: any;
  bTypes = [
    { id: 1, name: 'Cost Center' },
    { id: 2, name: 'Band' },
    { id: 3, name: 'Department' },
    { id: 4, name: 'Designation' },
    { id: 5, name: 'Location' },
    { id: 6, name: 'Categories' }
  ];
  startMonth: any;
  endMonth: any;
  fYears: any;
  sMonths = ['Jan', 'Apr'];
  current_fYear: any = [];
  carryForwardRemainingBudget = false;
  child_cats = [];
  allCategories = [];
  selectedBudgetTypes: any;
  showLoader = false;
  navInfoData: any;
  budgetActualList: any = [];
  historyList = []
  selectedLegalEntity: any;

  constructor(
    public formBuilder: FormBuilder,
    private router: NavController,
    private modalService: BsModalService,
    private commonService: CommonService,
    private settingService: SettingsService,
    public toastr: ToastrService,
    private translate: TranslateService,
    private expenseService: ExpensesService
  ) {
    this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
    this.selectedLegalEntity = JSON.parse(sessionStorage.getItem('selectedLegalEntity'));
    this.startMonth = this.userInfo?.financeYearStartMonth || 4;
    this.setBudgetForm();
    this.setFiscalYears();
  }

  ngOnInit() {
    this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
    this.startMonth = this.userInfo?.financeYearStartMonth || 4;
    this.navInfoData = JSON.parse(sessionStorage.getItem('toPathInfo'));
    this.editData.budgetId = this.navInfoData?.budgetId || null;
    this.editData.budgetGUID = this.navInfoData?.budgetGUID || null;
    if(!this.editData.budgetId) {
      this.addCostCenter();
    } else {
      this.getBudgetData();
    }
    this.getEndMonth();
    this.getCategories();
    this.getLocations();
  }

  removeCostCenter(i) {
    this.budgetLimitsList.splice(i, 1);
  }

  backToBudget() {
    this.router.navigateRoot(["/settings/budget"]);
  }

  getTotalFiscalAmt(budgetLimt) {
    let total = 0;
    budgetLimt?.monthlyLimitsList?.forEach(fY => {
      total += parseFloat(fY.monthLimit?.toString().replace(/,/g, '') || 0);
    });
    budgetLimt.total = total;
  }

  showHistoryClaimsLoader: boolean = false;
  historyMonthObj: any;
  openHistory(history: TemplateRef<any>, month?) {
    this.historyMonthObj = month;
    this.budgetActualList = [];
    this.showHistoryClaimsLoader = true;
    const config = {
      ignoreBackdropClick: false,
      class: "modal-dialog-centered modal-xl baa",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(history, config);
    let obj = {
      "monthNumber": month?.monthNumber,
      "monthLimitId": month?.monthLimitId,
      "monthLimitGuid": month?.monthLimitGuid
    }
    this.settingService.getBudgetActualClaims(obj).subscribe(res => {
      this.budgetActualList = res?.payload || [];
      this.showHistoryClaimsLoader = false;
    }, err => {
      this.showHistoryClaimsLoader = false;
    })
  }

  onTabChanged(ev) {
    if(ev?.index == 1 && this.historyMonthObj) {
      let obj = {
        "monthNumber": this.historyMonthObj?.monthNumber,
        "monthLimitId": this.historyMonthObj?.monthLimitId,
        "monthLimitGuid": this.historyMonthObj?.monthLimitGuid
      }
      this.settingService.getBudgetActualHistory(obj).subscribe(res => {
        this.historyList = res?.payload || [];
      })
    }
  }

  closeModal() {
    this.modalRef?.hide();
  }

  setBudgetForm() {
    this.addBudgetForm = this.formBuilder.group({
      budgetName: ['', [Validators.required]],
      budgetTypeName: ['', [Validators.required]],
      budgetTypeId: [''],
      fYear: ['', [Validators.required]]
    });
  }

  get bForm() {
    return this.addBudgetForm?.controls;
  }

  addCostCenter() {
    this.budgetLimitsList.push({
      budgetLimitId: null,
      budgetTypeName: '',
      budgetTypeId: '',
      categoryIds: [],
      categoryNames: [],
      categoryBudgetLimitId: null,
      monthlyLimitsList: [
        { monthlyLimitId: null, monthLimit: 0 }, { monthlyLimitId: null, monthLimit: 0 }, 
        { monthlyLimitId: null, monthLimit: 0 }, { monthlyLimitId: null, monthLimit: 0 },
        { monthlyLimitId: null, monthLimit: 0 }, { monthlyLimitId: null, monthLimit: 0 },
        { monthlyLimitId: null, monthLimit: 0 }, { monthlyLimitId: null, monthLimit: 0 },
        { monthlyLimitId: null, monthLimit: 0 }, { monthlyLimitId: null, monthLimit: 0 },
        { monthlyLimitId: null, monthLimit: 0 }, { monthlyLimitId: null, monthLimit: 0 }
      ],
      total: 0,
      totalBlocked: 0,
      totalConsumed: 0
    });
  }

  setBudgetTypeId(ev, bType?) {
    const index = this.bTypes.findIndex(b => b.name == ev);
    this.addBudgetForm.controls.budgetTypeId.patchValue(this.bTypes[index]?.id || null);
    this.getBudgetTypes(ev);
  }
  
  save() {
    this.showLoader = true;
    const filteredItems = this.budgetLimitsList.filter(item => item.budgetTypeName == null || item.budgetTypeName == '');
    if(filteredItems?.length > 0) {
      this.toastr.error('Fill in all mandatory fields.', this.translate.instant("alerts.error", { timeOut: 2000 }));
      this.showLoader = false;
      return;
    }
    this.budgetLimitsList.forEach(b => {
      let monthNumber = this.startMonth;
      if(!b.categoryNames || b.categoryNames?.length == 0) {
        b.categoryNames = ['All'];
        b.categoryIds = ['-1'];
      }
      b.monthlyLimitsList?.forEach(f => {
        f.monthNumber = monthNumber;
        monthNumber == 12 ? monthNumber = 1 : monthNumber++;
      });
    });

    let obj = {
      legalEntityId:this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId,
      legalEntityGUID:this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId,
      budgetId: this.editData?.budgetId || null,
      budgetGUID: this.editData?.budgetGUID || null,
      budgetName: this.addBudgetForm.value.budgetName,
      budgetType: this.addBudgetForm.value.budgetTypeId,
      budgetTypeName: this.addBudgetForm.value.budgetTypeName,
      fiscalYear: this.addBudgetForm.value.fYear,
      startMonth: this.startMonth,
      carryForwardRemainingBudget: this.carryForwardRemainingBudget,
      budgetLimitsList: this.budgetLimitsList
    }
    
    console.log(obj);

    this.settingService.createBudgetActual(obj).subscribe(res => {
      if (!res.isError && res.errorCode == 0) {
        this.showLoader = false;
        this.toastr.success(
          res?.payload,
          this.translate.instant("alerts.succes")
        );
        this.backToBudget();
      } else if (res.isError == true) {
        this.showLoader = false;
        this.toastr.error(
            res.errorMessage,
            this.translate.instant("alerts.error", { timeOut: 3000 })
        );
      }
    }, err => {
      this.showLoader = false;
    })
  }

  CategoryModel = new CategoryAndMerchantModel();
  getCategories() {
      this.CategoryModel.recordType = 1;
      this.CategoryModel.legalEntityId = this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId;
      this.CategoryModel.legalEntityGUID = this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId;
      this.commonService.getCatgryMasterlist(this.CategoryModel).subscribe((res) => {
          if (!res.isError && res.errorCode == 0) {
              this.allCategories = res.payload?.expenseCategoryList || [];
              this.allCategories.unshift({categoryId: '-1', categoryName: 'All', categoryCode: null});
              // this.child_cats = this.getSubCategories(this.allCategories);
              this.child_cats = [...this.allCategories];
              this.availableCategories = [...this.child_cats];
          } else if (res.isError == true) {
              this.toastr.error(
                  res.errorMessage,
                  this.translate.instant("alerts.error", { timeOut: 3000 })
              );
          }
      });
  }

  getSubCategories(categories: any) {
      let subCategories = [];
      categories.forEach(c => { c.children = [] });
      categories.forEach(c => {
          if (c.parentCategoryId) {
              const index = categories.findIndex(c_i => c_i.categoryId == c.parentCategoryId);
              if (index >= 0) {
                  categories[index].children.push(c);
              }
          }
      })
      subCategories = categories.filter((c) => c.parentCategoryId == null);
      return subCategories;
  }

  selectedClaimCategory(ev, index) {
    this.budgetLimitsList[index].budgetTypeId = ev.categoryId;
    this.budgetLimitsList[index].budgetTypeName = ev.categoryName;
    // this.duplicateLimitCheck(ev.categoryId, index);
  }

  setSelectedClaimCategory(ev, index) {
    this.budgetLimitsList[index].budgetTypeId = ev.id;
    this.budgetLimitsList[index].budgetTypeName = ev.name;
    // this.duplicateLimitCheck(ev.id, index);
  }

  selectedBudgetCategories(ev, index) {
    // console.log(ev);
    this.budgetLimitsList[index].categoryIds = ev?.map(e => `${e.categoryId}`) || [];
    this.budgetLimitsList[index].categoryNames = ev?.map(e => `${e.categoryName}`) || [];
  }

  getEndMonth() {
    if (this.startMonth == 1) {
      this.endMonth = 12;
    } else {
      this.endMonth = this.startMonth - 1;
    }
  }

  getBudgetData() {
    this.showLoaders = true;
    let obj = {
      "budgetId": this.editData.budgetId,
      "budgetGUID": this.editData.budgetGUID,
      "legalEntityId":this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId,
      "legalEntityGuid":this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId
    }

    this.settingService.getBudgetActual(obj).subscribe(res => {
      if (!res.isError && res.errorCode == 0) {
        console.log(res?.payload);
        
        this.addBudgetForm?.controls?.budgetName?.patchValue(res?.payload?.budgetName || null);
        this.addBudgetForm?.controls?.budgetTypeId?.patchValue(res?.payload?.budgetType || null);
        this.addBudgetForm?.controls?.budgetTypeName?.patchValue(res?.payload?.budgetTypeName || null);
        this.addBudgetForm?.controls?.categoryNames?.patchValue(res?.payload?.categoryNames || []);
        this.addBudgetForm?.controls?.categoryIds?.patchValue(res?.payload?.categoryIds || []);
        this.addBudgetForm?.controls?.fYear?.patchValue(res?.payload?.fiscalYear || null);
        this.carryForwardRemainingBudget = res?.payload?.carryForwardRemainingBudget;
        this.budgetLimitsList = res?.payload?.budgetLimitsList || [];

        this.budgetLimitsList?.forEach(b => {
          let total = 0;
          let totalBlocked = 0;
          let totalConsumed = 0;
          b.monthlyLimitsList?.forEach(m => {
            total += m.monthLimit;
            totalBlocked += m.monthBlockedAmount;
            totalConsumed += m.monthConsumedAmount;
          });
          b.total = total;
          b.totalBlocked = totalBlocked;
          b.totalConsumed = totalConsumed;
        });

        this.getFiscalYear();
        this.getBudgetTypes(this.addBudgetForm?.value?.budgetTypeName);
        this.showLoaders = false;
      } else if (res.isError == true) {
        this.showLoaders = false;
        this.toastr.error(res.errorMessage, this.translate.instant("alerts.error", { timeOut: 3000 }));
      }
    }, err => {
      this.showLoaders = false;
    })
  }

  duplicateLimitCheck(id, ind) {
    // console.log(this.budgetLimitsList);
    // this.budgetLimitsList.forEach((b, bi) => {
    //   if(b.budgetTypeId == id && bi != ind) {
    //     console.log(320, b);
    //   } else if(bi == ind) {
    //     console.log(323, b);
    //   }
    // });
    // const index = this.budgetLimitsList.findIndex((b, bi) => b.budgetTypeId == id && bi != ind);
    // this.budgetLimitsList[ind].isDuplicate = index >= 0 ? true : false;
    // console.log(index, this.budgetLimitsList);
  }

  setFiscalYears() {
    if(this.startMonth == 1) {
      this.fYears =['2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030', '2031', '2032', '2033', '2034', '2035', '2036', '2037', '2038', '2039', '2040'];
    } else {
      this.fYears =['2023-2024', '2024-2025', '2025-2026', '2026-2027', '2027-2028', '2028-2029', '2029-2030', '2030-2031', '2031-2032', '2032-2033', '2033-2034', '2034-2035', '2035-2036', '2036-2037', '2037-2038', '2038-2039', '2039-2040'];
    }
  }

  getFiscalYear() {
    const startMonth = this.startMonth;
    const endMonth = this.endMonth;
    const [startYear, endYear] = this.addBudgetForm?.value?.fYear?.split('-')?.map(year => parseInt(year));

    const months: string[] = [];

    if (startMonth <= endMonth) {
      // Case where start month is before or equal to end month
      for (let month = startMonth; month <= endMonth; month++) {
        months.push(`${this.getMonthName(month)} ${startYear?.toString()?.slice(2)}`);
      }
    } else {
      // Case where end month is in the next year
      for (let month = startMonth; month <= 12; month++) {
        months.push(`${this.getMonthName(month)} ${startYear?.toString()?.slice(2)}`);
      }
      for (let month = 1; month <= endMonth; month++) {
        months.push(`${this.getMonthName(month)} ${endYear?.toString()?.slice(2)}`);
      }
    }

    this.current_fYear = months;
  }

  getMonthName(month: number): string {
    const monthNames: string[] = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return monthNames[month - 1];
  }

  getBudgetTypes(type) {
    if (type == 'Cost Center') {
      this.getCostCenterList();
    }
    else if (type == 'Department') {
      this.getDepartmentsist();
    }
    else if (type == 'Designation') {
      this.getDesignationsList();
    }
    else if (type == 'Band') {
      this.getBandsList();
    }
    else if (type == 'Location') {
      this.selectedBudgetTypes = this.locationsList || [];
    }
  }

  getCostCenterList() {
    const requestModel = {
      "companyId": this.userInfo.companyId,
      "companyGUID": this.userInfo.companyGuId,
      "legalEntityId":this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId,
      "legalEntityGuid":this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId
    }
    this.selectedBudgetTypes = [];
    this.settingService.getCostCenterList(requestModel).subscribe((res) => {
      res?.payload?.costCenterList?.forEach(c => {
        this.selectedBudgetTypes.push({
          id: c.costCenterId,
          name: `${c.costCenterName} - ${c.costCenterCode}`
        })
      });

    })
  }

  getDepartmentsist() {
    const requestModel = {
      "companyId": this.userInfo.companyId,
      "companyGUID": this.userInfo.companyGuId,
      "legalEntityId":this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId,
      "legalEntityGuid":this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId
    }
    this.selectedBudgetTypes = [];
    this.settingService.getDepartmentsist(requestModel).subscribe((res) => {
      res?.payload?.departmentList?.forEach(d => {
        this.selectedBudgetTypes.push({
          id: d.departmentId,
          name: `${d.departmentName} - ${d.departmentCode}`
        })
      });
    })
  }

  getBandsList() {
    const requestModel = {
      "companyId": this.userInfo.companyId,
      "companyGUID": this.userInfo.companyGuId,
      "legalEntityId":this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId,
      "legalEntityGuid":this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId
    }
    this.selectedBudgetTypes = [];
    this.settingService.getBrandsList(requestModel).subscribe((res) => {
      res?.payload?.bandList?.forEach(d => {
        this.selectedBudgetTypes.push({
          id: d.bandId,
          name: `${d.bandName} - ${d.bandCode}`
        })
      });
    })
  }

  getDesignationsList() {
    const requestModel = {
      "companyId": this.userInfo.companyId,
      "companyGUID": this.userInfo.companyGuId,
      "legalEntityId":this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId,
      "legalEntityGuid":this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId
    }
    this.selectedBudgetTypes = [];
    this.settingService.getDesignationsList(requestModel).subscribe((res) => {
      res?.payload?.designationList?.forEach(d => {
        this.selectedBudgetTypes.push({
          id: d.designationId,
          name: `${d.designationName} - ${d.designationCode}`
        })
      });
    })
  }

  locationsList:any = [];
  getLocations() {
    let requestObj = {
      "legalEntityId":this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId,
      "legalEntityGuid":this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId
    };

    
    this.settingService.getAllLocations(requestObj).subscribe((res) => {
      res?.payload?.locationList?.forEach(l => {
        this.locationsList.push({
          id: l.locationId,
          name: `${l.locationName} - ${l.locationCode}`
        })
      });
    });
  }

  availableCategories = [];
  disableCategories(cIndex) {
    this.availableCategories = [...this.child_cats];
    this.availableCategories?.forEach(a => {
      a.disabled = false;
    })
    const filteredList = this.budgetLimitsList.filter((b, bi) => b.budgetTypeId == this.budgetLimitsList[cIndex]?.budgetTypeId && bi != cIndex);
    if (this.budgetLimitsList[cIndex]?.categoryIds?.includes('-1')) {
      this.availableCategories?.forEach(a => {
        a.disabled = a.categoryId == '-1' ? false : true;
      })
    } else if(this.budgetLimitsList[cIndex]?.categoryIds?.length > 0 && filteredList?.length <= 0) {
      this.availableCategories?.forEach(a => {
        if(a.categoryId == '-1') {
          a.disabled = true;
        }
      })
    } else {
      const isAllSelected = filteredList?.findIndex(ff => ff?.categoryIds?.includes('-1'));
      if (isAllSelected >= 0) {
        this.availableCategories?.forEach(a => {
          a.disabled = true;
        })
      } else {
        filteredList?.forEach(fl => {
          fl.categoryIds?.forEach(fc => {
            const cat_index = this.availableCategories?.findIndex(ac => ac.categoryId == fc);
            if (cat_index >= 0) {
              this.availableCategories[cat_index].disabled = true;
              this.availableCategories[0].disabled = true;
            }
          });
        });
      }
    }
  }

  isOptionDisabled(option: any): boolean {
    return option.disabled;
  }
}
