import {Component, NgZone, OnInit, TemplateRef} from '@angular/core';
import {ExpenseAndReportModel, FilterModal, StatusListModel, UserModel} from "../../models/Common/common.model";
import {Subject} from "rxjs";
import {AddReportExpense, DeleteExpenses, ExpenseListModel, ExpenseModel, GetExpenseModels} from "../../models/Expenses/expenses.model";
import {PlatformService} from "../../services/platformbase.service";
import {ExpensesService} from "../../services/expenses.service";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {CommonService} from "../../services/common-services/common.service";
import {DatechangeService} from "../../services/common-services/datechange.service";
import {AlertController, IonInput, ModalController, NavController, NavParams} from "@ionic/angular";
import {ActivatedRoute} from "@angular/router";
import {SharedService} from "../../services/common-services/shared.service";
import {ReportsService} from "../../services/reports.service";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {AppConfig} from "../../config/app.config";
import * as moment from "moment";
import * as _ from "lodash";
import {ChangeContext, Options} from "ng5-slider";
import {RemoveReportModal} from "../../models/Reports/reports.model";
import {CustomDatePickerComponent} from "../custom-date-picker/custom-date-picker.component";
import {CustomSelectDropdownComponent} from "../custom-select-dropdown/custom-select-dropdown.component";
import {Constants} from 'src/app/Constants';
import {CreatePettyCash} from "../../models/PettyCash/petty-cash.model";
import { BsModalService } from 'ngx-bootstrap/modal';


@Component({
    selector: 'app-petty-cash',
    templateUrl: './petty-cash.component.html',
    styleUrls: ['./petty-cash.component.scss'],
})
export class PettyCashComponent implements OnInit {
    platformType: number;
    hideColumns = [];
    isPageLoad = true;
    pageNumber = 1;
    pageSize: 1;
    showLoading = true;
    expenseList = [];
    expenseAndReportModel = new ExpenseAndReportModel();
    filterModal = new FilterModal();
    userInfo: any;
    ExpenseorReport: string;
    draft = true;
    isApicallinProgress = false;
    totalRecords = 0;
    isExpenseCreated: boolean;
    minAndMax: any;
    selectedExpense = [];
    selectedStatus = [];
    selectedcategory = [];
    indeterminateState: boolean;
    addExpenseListHide = false;
    options: any;
    minPrice = 0;
    maxPrice = 0;
    showExpFilter = false;
    showExpFooter = false;
    showExpList = true;
    showSearch = false;
    tableHide = true;
    showExpSearch = false;
    showExpDetail = false;
    checkAll: boolean;
    public SearchListText: Subject<string> = new Subject();
    searchExpense: string;
    mobMaxDate: string;
    currentday: string;
    maxDate: Date;
    validationMsg: any;
    showFilter: boolean;
    dropdownFlag: boolean;
    statusList: any;
    checkAllStatus: boolean;
    statusState: boolean;
    status: string;
    frmDate: any;
    minDate: Date;
    minDatee: any;
    tDate: any;
    showSummaryLoading = true;
    expense: ExpenseListModel;
    showaddNewMileage = false;
    totalAmt: number;
    sub: any;
    draftBtns = false;
    btnSubDisabled = false;
    buttonDisabled = false;
    deleteExpenses = new DeleteExpenses();
    expenseModel = new ExpenseModel();
    addReportExpense = new AddReportExpense();
    nextvalue: number;
    unReportExpenses: any[];
    statusArray = [];
    createPettyCashModel = new CreatePettyCash();
    dummyList = [1, 2, 3];
    isFirstOpen = true;
    isThirdOpen = true;
    isSecondOpen = true;
    walletPettyCash: any;

    showMobileDetails: boolean = true;
    addExpenseHide = true;
    segmentModel = "draft";
    expenseId: number;
    expenseGUID: string;
    claimID: number;
    fromcomp: string;
    searchBar = false;
    hideAdvBtn = true;
    isUserToggle:any;
    sharedSubsc:any;

    maxDateStr: any = moment(new Date()).format('YYYY-MM-DD');
    minDateStr: any = moment(new Date().getTime()).subtract(2000, 'years').format('YYYY-MM-DD');
    proofList: any;
    modalRef: any;
    proofid: any;
    downloadProof: any;
    imgNum: number;

    constructor(private modalService: BsModalService, private platformService: PlatformService, private expenseService: ExpensesService,
                public toastr: ToastrService, private translate: TranslateService, private commonService: CommonService,
                private datechangeService: DatechangeService, private router: NavController,
                private route: ActivatedRoute, public navParams: NavParams, private sharedService: SharedService, private zone: NgZone,
                public alertController: AlertController, private reportsService: ReportsService, public modalController: ModalController) {

        this.sharedService.getRefreshData().subscribe((value) => {
            if (value) {
                this.draft = true;
                this.segmentModel = 'draft';
                this.pageNumber = 1;
                this.getExpenseList(true);
            }
        });

    }

    ngOnInit() {
        this.platformType = this.platformService.getPlatformType();
        this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        this.pettyCashAmtApi();
        this.SearchListText.pipe(
            debounceTime(500),
            distinctUntilChanged()).subscribe((term: string) => {
            this.ExpenseorReport = term;
            this.showLoading = true;
            this.getExpenseList(false);
        });

        if ((this.userInfo.roleId == 2 || this.userInfo.roleId == 3) && JSON.parse(sessionStorage.getItem('isadmin'))) {
            this.hideAdvBtn = false;
        }

        this.sharedSubsc = this.sharedService.getUserToggle().subscribe((val) => {
            this.isUserToggle = val;
        });
    }

    returnDasbord() {
        this.router.navigateRoot(["dashboard"]);
    }

    ionViewWillEnter() {
        this.pageNumber = 1;
        this.platformType = this.platformService.getPlatformType();
        this.validationMsg = AppConfig;
        this.tableHide = true;
        let statusid = this.navParams.get('status');
        //  this.title.setTitle("Expenses | NoExpenses");
        const date = new Date();
        this.maxDate = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate()
        );
        this.mobMaxDate = moment(new Date()).format(Constants.mobileCalenderFormat);
        this.currentday = moment(new Date()).format(Constants.mobileCalenderFormat);
        this.getExpenseList(true);
        // this.getreportlist();
        // this.getCategorylist();
        console.log("testing");
        this.getStatusList();
        if (this.platformType === 2) {
            this.showSearch = true;
        }

        let val = JSON.parse(sessionStorage.getItem("fromPathInfo"));
        // this.navigationSubcr = this.sharedService.getFromPathInfo().subscribe((val) => {
        if (val) {
            if (val["expenseId"] && val["expenseGUID"] && val["status"]) {
                this.showExpenseDetail(val);
            }
        }
        // });

    }

    getExpenseList(isPageLoad, event?) {
        this.isPageLoad = isPageLoad;
        if (this.pageNumber == 1) {
            this.showLoading = true;
            this.expenseList = [];
        }
        this.expenseAndReportModel.isAdminView = JSON.parse(sessionStorage.getItem('isadmin'));
        if((JSON.parse(sessionStorage.getItem('isadmin')) == true) && (this.userInfo.roleId == 2) ) {
            this.expenseAndReportModel.viewType = this.userInfo.roleId;
          } else {
            this.expenseAndReportModel.viewType = 1;
          }
        this.expenseAndReportModel.recordType = 5;
        this.expenseAndReportModel.legalEntityId = this.userInfo.legalEntityId;
        this.expenseAndReportModel.legalEntityGUID = this.userInfo.legalEntityGuId;
        this.expenseAndReportModel.userId = this.userInfo.userId;
        this.expenseAndReportModel.userGUID = this.userInfo.userGuId;
        this.expenseAndReportModel.pageNumber = this.pageNumber;
        this.expenseAndReportModel.filterBy = this.filterModal;
        this.expenseAndReportModel.filterBy.searchText = this.ExpenseorReport;
        this.expenseAndReportModel.filterBy.isDraft =
            this.platformType === 2 ? this.draft : null;
        this.expenseAndReportModel.pageSize = this.pageSize;
        this.isApicallinProgress = true;
        this.expenseService
            .getExpenseList(this.expenseAndReportModel)
            .subscribe((res) => {

                if (!res.isError && res.errorCode === 0) {
                    if (this.pageNumber == 1) {
                        this.expenseList = [];
                    }
                    this.showLoading = false;
                    this.totalRecords = res.payload.expensesCount;
                    this.isExpenseCreated = res.payload.isExpenseCreated;
                    this.minAndMax = res.payload;
                    this.selectedExpense = [];
                    this.selectedStatus = [];
                    this.selectedcategory = [];
                    this.indeterminateState = false;
                    this.changeOptions(
                        res.payload.expenseMinAmount,
                        res.payload.expenseMaxAmount
                    );
                    const listarray = res.payload.expenseResponseList;
                    this.expenseList = this.expenseList.concat((listarray.map((list) => new ExpenseListModel(list))));
                    if (
                        this.pageNumber === 1 &&
                        this.isPageLoad === true &&
                        this.isExpenseCreated === true
                    ) {
                        this.isPageLoad = false;
                    }
                    this.returnExpSegment();

                    if (event) {
                        event.target.complete();
                    }
                    if (res.payload.expenseResponseList.length < this.pageSize && event) {
                        event.target.disabled = true;
                    }
                    this.isApicallinProgress = false;
                } else {
                    this.showLoading = false;
                    this.isApicallinProgress = false;
                    this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
                    if (event) {
                        event.target.disabled = true;
                    }
                }
            });

    }


    getreportList() {
        if (this.pageNumber == 1) {
          this.showLoading = true;
          this.expenseList = [];
        }
        this.expenseAndReportModel.recordType = 4;
        this.expenseAndReportModel.isAdminView = JSON.parse(sessionStorage.getItem('isadmin'));
        if ((JSON.parse(sessionStorage.getItem('isadmin')) == true) && (this.userInfo.roleId == 2)) {
          this.expenseAndReportModel.viewType = this.userInfo.roleId;
        } else {
          this.expenseAndReportModel.viewType = 1;
        }
        this.expenseAndReportModel.legalEntityId = this.userInfo.legalEntityId;
        this.expenseAndReportModel.legalEntityGUID = this.userInfo.legalEntityGuId;
        this.expenseAndReportModel.userId = this.userInfo.userId;
        this.expenseAndReportModel.userGUID = this.userInfo.userGuId;
        this.expenseAndReportModel.filterBy = this.filterModal;
        this.expenseAndReportModel.filterBy.searchText = this.ExpenseorReport;
        this.expenseAndReportModel.filterBy.isDraft =
          this.platformType == 2 ? this.draft : null;
        this.expenseAndReportModel.pageNumber = this.pageNumber;
        this.expenseAndReportModel.pageSize = this.pageSize;
        this.isApicallinProgress = true;
        this.expenseAndReportModel.linkedWithAdvance = true;
        this.reportsService
          .reportList(this.expenseAndReportModel)
          .subscribe((res) => {
            if (!res.isError && res.errorCode === 0) {

              this.showLoading = false;
              this.totalRecords = res.payload.reportsCount;
              this.isExpenseCreated = res.payload.isReportCreated;
              this.minAndMax = res.payload;
              this.selectedExpense = [];
              this.selectedStatus = [];
              this.selectedcategory = [];
              this.indeterminateState = false;
              this.changeOptions(
                res.payload.reportMinAmount,
                res.payload.reportMaxAmount
              );
              const listarray = res.payload.reportResponseList;
              this.expenseList = this.expenseList.concat((listarray.map((list) => new ExpenseListModel(list))));
              if (
                this.pageNumber === 1 &&
                this.isPageLoad === true &&
                this.isExpenseCreated === true
              ) {
                this.isPageLoad = false;
              }
              this.returnExpSegment();


            } else {
              // this.selectedStatus = [];
              // this.selectedReport = [];
              // this.isApicallinProgress = false;
              // this.showLoading = false;
              // this.toaster.error(res.errorMessage, this.translate.instant('alerts.error'));
              // if (event) {
              //     event.target.disabled = true;
              // }
            }
          });
      }

    opendetails(expense) {
        //TODO change fromcomp to pettycash check once
        this.showMobileDetails = false;
        this.expenseId = expense.expenseId,
            this.expenseGUID = expense.expenseGUID,
            this.claimID = expense.claimId,
            this.fromcomp = 'petty-cash';
    }

    expFilter() {
        this.showExpFilter = true;
        this.showExpList = false;
        this.showExpDetail = false;
        this.showExpSearch = false;
        this.addExpenseHide = false;
        this.selectedExpense = [];
        this.showLoading = false;
    }

    changeOptions(minValue, maxValue) {
        const newOptions: Options = Object.assign({}, this.options);
        newOptions.floor = minValue;
        newOptions.ceil = maxValue;
        this.minPrice = minValue;
        this.maxPrice = maxValue;
        this.options = newOptions;
    }

    expenseSegment(e) {
        this.segmentModel = e.target.value;
        if (e.target.value === "draft") {
            this.draft = true;
            this.pageNumber = 1;
            this.getExpenseList(false);
            this.selectedcategory = [];
            this.selectedStatus = [];
            //this.resetAll();
        } else {
            this.draft = false;
            this.pageNumber = 1;
            this.selectedExpense = [];
            this.getExpenseList(false);
            this.selectedcategory = [];
            this.selectedStatus = [];
            //this.resetAll();
        }
    }

    resetAll() {
        this.ExpenseorReport = "";
        this.frmDate = undefined;
        this.tDate = null;
        this.filterModal.fromDate = null;
        this.filterModal.toDate = null;
        this.status = "";
        this.minDatee = "";
        this.mobMaxDate = moment(new Date()).format(Constants.mobileCalenderFormat);
        this.resetAmount();
    }

    reportexpsearch(e) {
        this.filterModal.searchText = e.detail.value;
    }

    toggleColumns(e, val: string) {
        if (e.target.checked === true) {
            this.hideColumns.push(val);
        } else {
            const index = this.hideColumns.indexOf(val);
            if (index > -1) {
                this.hideColumns.splice(index, 1);
            }
        }
    }


    showhideColumns(val: string) {
        let column = true;
        if (this.hideColumns.indexOf(val) > -1) {
            column = false;
        }
        return column;
    }

    showFiltersection() {
        this.showFilter = !this.showFilter;
        this.dropdownFlag = false;
    }

    returnExpSegment() {
        this.showExpFilter = false;
        this.showExpList = true;
    }

    searchbar() {
        this.showSearch = !this.showSearch;
    }

    onsearchExpense(val) {
        this.ExpenseorReport = val.target.value;
        this.checkAll = false;
        if (this.ExpenseorReport?.length >= 0) {
            this.resetPaging();
            this.SearchListText.next(this.ExpenseorReport);
        }
        if (this.searchExpense === '') {
            this.resetPaging();
            this.getExpenseList(false);
        }
    }

    loadMore(event) {
        if (!this.isApicallinProgress) {
            if (this.expenseList.length < this.pageSize && event) {
                event.target.disabled = true;
            } else {
                this.pageNumber++;
                this.selectAllexpenses(event);
                this.getExpenseList(false, event);
            }
        }
    }

    selectSingleexpenses(list, e?) {
        if (e) {
            list.isSelected = e?.target?.checked;
        }

        if (list.isSelected) {
            this.selectedExpense.push(list.expenseId);
            if (list.claimId === null) {
                this.unReportExpenses.push(list.expenseId);
            }
            if (list.status === 1) {
                this.statusArray.push(list.claimId);
            }
        } else {
            let index = _.indexOf(this.selectedExpense, list.expenseId);
            this.selectedExpense.splice(index, 1);
            if (list.claimId == null) {
                index = _.indexOf(this.unReportExpenses, list.expenseId);
                this.unReportExpenses.splice(index, 1);
            }
            if (list.status === 1) {
                index = _.indexOf(this.statusArray, list.claimId);
                this.statusArray.splice(index, 1);
            }
        }
        if (
            this.expenseList.length !== this.selectedExpense.length &&
            this.selectedExpense.length > 0
        ) {
            this.checkAll = false;
            this.indeterminateState = true;
        } else if (this.selectedExpense.length === 0) {
            this.checkAll = false;
            this.indeterminateState = false;
        } else {
            this.checkAll = true;
            this.indeterminateState = false;
        }
        this.removeReportModal.claimIds = this.statusArray;
        this.deleteExpenses.expenseIds = this.selectedExpense;
        this.addReportExpense.expenseIds = this.unReportExpenses;

    }

    selectAllexpenses(event?) {
        setTimeout(() => {
            this.expenseList.forEach((item) => {
                item.isSelected = event ? false : this.checkAll;
            });
        });
    }

    resetPaging() {
        this.pageNumber = 1;
        this.expenseList = [];
        this.expenseList.length = 0;
    }

    getStatusList() {
        const type = {type: +1};
        this.commonService.statusList(type).subscribe((res) => {
            if (!res.isError && res.errorCode === 0) {
                const listarray = res.payload;
                this.statusList = listarray.map((list) => new StatusListModel(list));
            } else if (res.isError === true) {
                this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
            }
        });
    }

    selectSingleStatus(list, e?) {
        if (e) {
            list.isSelected = e?.target?.checked;
        }
        if (list.isSelected) {
            this.selectedStatus.push(list.statusId);
            this.filterModal.statusIds = this.selectedStatus;
        } else {
            const index = _.indexOf(this.selectedStatus, list.statusId);
            this.selectedStatus.splice(index, 1);
            this.filterModal.statusIds = this.selectedStatus;
        }
        this.filterModal.statusIds = this.selectedStatus;
        if (
            this.statusList.length !== this.selectedStatus.length &&
            this.selectedStatus.length > 0
        ) {
            this.checkAllStatus = false;
            this.statusState = true;
        } else if (this.selectedStatus.length === 0) {
            this.checkAllStatus = false;
            this.statusState = false;
        } else {
            this.checkAllStatus = true;
            this.statusState = false;
        }
    }

    selectAllStatus() {
        setTimeout(() => {
            this.statusList.forEach((item) => {
                item.isSelected = this.checkAllStatus;
            });
        });
    }

    getfromDate(e) {
        this.frmDate = e.target.value;
        const date = this.datechangeService.convertCalendarToAPI(e.target.value);
        this.filterModal.fromDate = date;
        this.minDate = new Date(this.frmDate);
    }

    gettodate(e) {
        this.tDate = e.target.value;
        const date = this.datechangeService.convertCalendarToAPI(e.target.value);
        this.filterModal.toDate = date;
        this.maxDate = new Date(this.tDate);

    }

    resetFilters(list) {
        list.forEach((item) => {
            item.isSelected = false;
        });
    }

    resetAmount() {
        this.filterModal.minPrice = this.minAndMax.expenseMinAmount;
        this.filterModal.maxPrice = this.minAndMax.expenseMaxAmount;
    }

    priceSlider(changeContext: ChangeContext) {
        this.filterModal.minPrice = changeContext.value;
        this.filterModal.maxPrice = changeContext.highValue;
    }

    resetDates() {
        this.frmDate = undefined;
        this.tDate = undefined;
        this.filterModal.fromDate = undefined;
        this.filterModal.toDate = undefined;
        this.minDate = undefined;
        this.maxDate = new Date();
    }

    applyfilter() {
        this.showFilter = false;
        this.resetPaging();
        if (this.frmDate !== undefined && this.tDate === undefined) {
            this.tDate = this.mobMaxDate;
            this.filterModal.toDate = moment(new Date()).format(Constants.mobileDateFormat);
        }
        if (this.frmDate === undefined && this.tDate !== "") {
            this.frmDate = this.tDate;
            this.filterModal.fromDate = this.tDate;
        }
        // this.createFilters();
        this.filterModal.fromDate = this.frmDate ? moment(new Date(this.frmDate)).format(Constants.mobileDateFormat) : undefined;
        this.filterModal.toDate = this.tDate ? moment(new Date(this.tDate)).format(Constants.mobileDateFormat) : undefined;

        this.getExpenseList(false);
        this.returnExpSegment();
        // this.maxDate = new Date();

        //  this.mobMaxDate = moment(new Date()).format(Constants.mobileCalenderFormat);
    }

    openNewPettyCash() {
        this.router.navigateRoot(["add-petty-cash"]);
    }

    showExpenseDetail(list) {
        // this.selectAllexpenses();
        // this.indeterminateState = false;
        // this.selectedExpense = [];
        this.tableHide = false;
        this.showSummaryLoading = true;
        this.getExpenseSumary(list);
        if (list.status === 1) {
            this.draftBtns = true;
        } else {
            this.draftBtns = false;
        }

        const expDeleteArry = [];
        this.expenseModel.expenseId = list.claimId;
        expDeleteArry.push(list.claimId);
        this.deleteExpenses.expenseIds = expDeleteArry;
        this.removeReportModal.claimIds = expDeleteArry;
        if (this.platformType == 1) {
            this.nextvalue = this.expenseList.indexOf(list);
        }
    }

    backToexpense() {
        this.tableHide = true;
        this.showMobileDetails = true;
        this.selectedExpense = [];
        this.statusArray = [];
    }

    getExpenseModels = new GetExpenseModels();

    getExpenseSumary(list) {

        this.getExpenseModels.expenseId = list.expenseId;
        this.getExpenseModels.expenseGUID = list.expenseGUID;
        this.getExpenseModels.userId = this.userInfo.userId;
        this.getExpenseModels.userGUID = this.userInfo.userGuId;
        this.getExpenseModels.legalEntityId = this.userInfo.legalEntityId;
        this.getExpenseModels.legalEntityGUID = this.userInfo.legalEntityGuId;
        this.expenseService
            .expenseSummary(this.getExpenseModels)
            .subscribe((res) => {
                if (!res.isError && res.errorCode === 0) {
                    const listarray = res.payload;
                    this.expense = new ExpenseListModel(listarray);
                    this.showaddNewMileage = true;
                    this.showSummaryLoading = false;
                    this.totalAmt = this.expense.expenseItems
                        .map((t) => t.amount)
                        .reduce((a, value) => a + value, 0);
                    //   if (this.platformType === 1) {
                    //    this.editExpense(this.expense);
                    //  }
                } else if (res.isError && res.errorCode === 2) {
                    this.showSummaryLoading = false;
                }
            });
    }

    ngOnDestroy() {
        this.resetSubscriptions();
    }

    ionViewWillLeave() {
        this.resetSubscriptions();
    }

    resetSubscriptions() {
         this.sharedService.unsubscribeServic(this.sharedSubsc);
    }
    // EditPettyCash(editreport?) {
    //     this.sharedService.setPettyCashData(editreport);
    //     this.router.navigateRoot(["add-petty-cash"]);
    // }

    EditPettyCash(editreport?) {
        let navInfo = {
          url:'petty-cash',
          advance:editreport
        };
        sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
        this.router.navigateRoot(["add-petty-cash"]);
      }

    async removePettyCashPopUp(list?) {
        if (list) {
            const expDeleteArry = [];
            expDeleteArry.push(list.expenseId);
            this.deleteExpenses.expenseIds = expDeleteArry;
        }

        const alert = await this.alertController.create({
            mode: "md",
            message: this.translate.instant('alerts.pcConfirmdelete'),
            backdropDismiss: false,
            buttons: [
                {
                    text: this.translate.instant('common.cancel'),
                    role: "cancel",
                },
                {
                    text: this.translate.instant('common.delete'),
                    handler: () => {
                        this.zone.run(() => {
                            this.buttonDisabled = true;
                            this.deleteExpenses.legalEntityId = this.userInfo.legalEntityId;
                            this.deleteExpenses.legalEntityGUID = this.userInfo.legalEntityGuId;
                            this.deleteExpenses.userId = this.userInfo.userId;
                            this.deleteExpenses.userGUID = this.userInfo.userGuId;
                            this.deleteExpenses.recordType = 5;
                            this.expenseService
                                .deleteExpense(this.deleteExpenses)
                                .subscribe((data) => {
                                    if (!data.isError && data.errorCode === 0) {
                                        this.buttonDisabled = false;
                                        this.toastr.success(data?.payload, this.translate.instant('alerts.succes'));
                                        this.backToexpense();
                                        this.getExpenseList(true);
                                    } else if (data.isError && data.errorCode === 2) {
                                        this.buttonDisabled = false;
                                        this.toastr.error(data.errorMessage, this.translate.instant('alerts.error'));
                                    }
                                });
                        });

                    },

                },
            ],
        });
        await alert.present();
    }

    submitReport() {
        this.reportSubmitPopUp();
    }

    removeReportModal = new RemoveReportModal();

    async reportSubmitPopUp() {
        const alert = await this.alertController.create({
            mode: "md",
           message: this.translate.instant('alerts.confirmSubmit'),
            backdropDismiss: false,
            buttons: [
                {
                    text: this.translate.instant('common.cancel'),
                    role: "cancel",
                    handler: () => {
                    },
                },
                {
                    text: this.translate.instant('common.submit'),
                    handler: () => {
                        this.zone.run(() => {

                            this.removeReportModal.userId = this.userInfo.userId;
                            this.removeReportModal.userGUID = this.userInfo.userGuId;
                            this.removeReportModal.legalEntityId = this.userInfo.legalEntityId;
                            this.removeReportModal.legalEntityGUID = this.userInfo.legalEntityGuId;

                            this.removeReportModal.recordType = 5;
                            this.btnSubDisabled = true;
                            this.reportsService
                                .draftReport(this.removeReportModal)
                                .subscribe((res) => {
                                    this.btnSubDisabled = false;
                                    if (!res.isError && res.errorCode == 0) {
                                        this.unReportExpenses = [];
                                        this.selectedExpense = [];
                                        this.indeterminateState = false;
                                        this.statusArray = [];
                                        this.backToexpense();
                                        this.getExpenseList(true);
                                        this.checkAll = false;
                                        this.toastr.success(res.payload, this.translate.instant('alerts.succes'));
                                    } else if (res.isError == true) {
                                        this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
                                    }
                                });
                        });
                    },
                },
            ],
        });

        await alert.present();
    }

    async addNewPettyCash() {
        this.router.navigateRoot(["add-petty-cash"]);
    }

    clearSearch() {
        this.searchExpense = "";
        this.ExpenseorReport = "";
        if (this.expenseAndReportModel.filterBy.searchText?.length >= 3) {
            this.getExpenseList(false);
        }
    }

    matTabIndex = 0;

    onTabChanged(e) {
        this.matTabIndex = e.index;
        if (e.index == 0) {
            this.resetFilters(this.statusList);
            this.selectedStatus = [];
            this.filterModal.statusIds = this.selectedStatus;
            this.getExpenseList(true);
        } else {
            this.getreportList();
            // let paidList = this.statusList.filter(function(status) {
            //     return status.statusId === 7;
            // });

            // if (paidList.length > 0) {
            //     this.resetFilters(this.statusList);
            //     this.selectedStatus = [];
            //     paidList.forEach((item) => {
            //         item.isSelected = true;
            //         this.selectSingleStatus(item);
            //     });
            // }
        }


    }

    pettyCashAmtApi() {
        let walletModel = new UserModel();
        walletModel.userId = this.userInfo.userId;
        walletModel.userGUID = this.userInfo.userGuId;
        this.reportsService.walletRequest(walletModel).subscribe((res) => {
            if (!res.isError && res.errorCode === 0 && res?.payload) {
                this.walletPettyCash = res.payload?.formattedPettyCashAdvanceBalance;
            }
        });
    }

    async openDateSheet(isFromDate: boolean) {
        const modal = await this.modalController.create({
            component: CustomDatePickerComponent,
            componentProps: {
                selectedDate: isFromDate ? this.frmDate : this.tDate,
                fromDate: isFromDate ? new Date('1981-01-01') : this.frmDate,
                toDate: isFromDate ? this.tDate ? this.tDate : new Date() : new Date()
            },
            cssClass: 'custom-bottom-sheet'
        });
        modal.onDidDismiss().then(result => {
            if (result.role === 'changed' && result.data) {
                if (isFromDate) {
                    this.frmDate = result.data.displayDate;
                } else {
                    this.tDate = result.data.displayDate;
                }
            }
        });
        return await modal.present();
    }

    fromDateMobile(e) {
        if (e.detail.value !== "") {
            this.frmDate = e.detail.value;
            this.filterModal.fromDate = moment(new Date(this.frmDate)).format(Constants.mobileDateFormat);
            this.minDatee = moment(new Date(this.frmDate)).format(Constants.mobileCalenderFormat);
        } else {
            this.filterModal.fromDate = undefined;
        }
    }

    toDateMobile(e) {
        if (e.detail.value !== "") {
            this.tDate = e.detail.value;
            this.filterModal.toDate = moment(new Date(this.tDate)).format(Constants.mobileDateFormat);
            this.mobMaxDate = moment(new Date(this.tDate)).format(Constants.mobileCalenderFormat);
        } else {
            this.filterModal.fromDate = undefined;
        }
    }

    getFromDateCall(ev) {

        this.frmDate = moment(ev.detail.value).format("D MMM YYYY");
        this.filterModal.fromDate = moment(ev.detail.value).format("MM-DD-YYYY");
        this.minDatee = moment(ev.detail.value).format("DD-MM-YYYY");
        this.minDateStr = moment(ev.detail.value).format("YYYY-MM-DD");
    }
    getToDateCall(ev) {

        this.tDate = moment(ev.detail.value).format("D MMM YYYY");
        this.filterModal.toDate = moment(ev.detail.value).format("MM-DD-YYYY");
        this.mobMaxDate = moment(ev.detail.value).format("DD-MM-YYYY");
        this.minDateStr = moment(new Date().getTime()).subtract(2000, 'years').format('YYYY-MM-DD');

    }


    resetSinglefilter(type) {
        if (type) {
            switch (type) {
                case 1:
                    this.frmDate = undefined;
                    this.filterModal.fromDate = this.frmDate;
                    this.minDatee = undefined;
                    this.maxDate = new Date();
                    break;
                case 2:
                    this.tDate = undefined;
                    this.filterModal.toDate = this.tDate;
                    this.minDatee = undefined;
                    this.maxDate = new Date();
                    break;
                case 3:
                    this.status = "";
                    this.selectedStatus = [];
                    this.filterModal.statusIds = this.selectedStatus;
                    break;

            }
        }
    }

    resetBlur(eleInput: IonInput) {
        eleInput.getInputElement().then((ele) => ele.blur());
    }

    async openModal(value: string, type: string, isCategory?: boolean) {
        const componentProps = this.getComponentProps(type);
        if (value && value !== '') {
            componentProps.value = this.filterModal[componentProps.setValueKey];
        }
        const searchItemModal = await this.modalController.create({
            component: CustomSelectDropdownComponent,
            componentProps,
            keyboardClose: false,
        });
        searchItemModal.onDidDismiss()
            .then((callback) => {
                if (callback && callback.data) {
                    // TODO give onchange methods for status and category
                    if (typeof callback.data !== 'string') {
                        this[componentProps.listName] = callback.data;
                        const arr = [];
                        this.status = null;
                        callback.data.forEach((obj: any) => {
                            if (obj.isSelected) {
                                arr.push(obj[componentProps.itemValueField]);
                                if (this.status && this.status !== '') {
                                    this.status = `${this.status} , ${obj[componentProps.itemTextField]}`;
                                } else {
                                    this.status = obj[componentProps.itemTextField];
                                }
                            }
                        });
                        if (arr && arr.length) {
                            this.filterModal[componentProps.setValueKey] = arr;
                        }
                    }
                }
            });
        return await searchItemModal.present();
    }

    getComponentProps(type: string) {
        switch (type) {
            case 'status':
                return {
                    title: 'Status',
                    value: null,
                    mainList: this.statusList,
                    isMultiSelect: true,
                    itemValueField: 'statusId',
                    itemTextField: 'statusDescription',
                    listName: `statusList`,
                    setValueKey: 'statusIds'
                };

        }
    }

    openProofModal(proofPopup: TemplateRef<any>, list) {

        this.proofList = list?.expenseAttachmentsmodel;
        const config = {
          ignoreBackdropClick: true,
          class: "modal-dialog-centered modal-lg",
          keyboard: false,
    
        };
        this.modalRef = this.modalService.show(proofPopup, config);
      }
      onSlide(item) {
        const imageIndex = parseInt(item.current.replace("slideId_", ""), 10);
        this.proofid = this.proofList[parseInt(item.current.replace("slideId_", ""), 10)].attachmentId;
        this.imgNum = imageIndex + 1;
      }
      modalClose() {
        this.modalRef.hide();
      }
      downloadImage() {
        // if (!this.proofid) {
        //   this.proofid = this.proofList[0].attachmentId;
        // }
        let attachObj = { attachmentIds: [this.proofList[0].attachmentId] };
        this.expenseService.downloadAttachment(attachObj).subscribe(res => {
          if (!res.isError && res.errorCode === 0) {
            this.downloadProof = res.payload.downloadFileResponseDTO;
                this.downloadProof.forEach(element => {
                    if (element) {
                        window.open(element.url);
                    }
                });
            // this.downloadProof = res['payload']['url'];
            // window.open(this.downloadProof);
            this.toastr.success(this.translate.instant('alerts.downloadsucces'), this.translate.instant('alerts.succes'));
          } else {
            this.toastr.error(res.errorMessage, 'Error');
          }
        });

       
    
      }

}
