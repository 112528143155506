// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// PRODUCTION URLS
// BASE_URL:  "https://api.fastcollab.com/gs/",
// DOMAIN_URL: "https://api.noexpenses.com/ne/",

export const environment = {
  production: false,
  globalSettingsUrl: 'https://dev.global.fastcollab.com/',
  BASE_URL:  "https://dev.api.noexpenses.com/gs/",
  DOMAIN_URL: "https://dev.api.noexpenses.com/ne/",
  Environment: "DEV",
  calendarDateFormat:  "yyyy-MM-dd",
  apiDateFormat:  "dd-MM-yyyy",
  mobileDateFormat: "DD-MM-YYYY",
  mobileCalenderFormat: "YYYY-MM-DD",
  appLogo : 'expenses', //fc365 //expenses
  eva_dashboard_url : 'https://dev.api.eva.travel/tripbox/v1/dashboard/tripbox-stats',
  eva_auth: 'https://dev.api.eva.travel/',
  eva_sso: 'https://uat.sso.eva.travel/eva-sso/sso-login/uat',
  EncryptionKey: 'Usdb5aPIHV0TL8Dv'
};