import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NavController } from '@ionic/angular';
import { PlatformService } from 'src/app/services/platformbase.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SettingsService } from 'src/app/services/settings-services';
import { SharedService } from 'src/app/services/common-services/shared.service';
import { polEnableEntityModels, PolicyListModel, PolyUpdateModel } from 'src/app/models/Settings/policy.model';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AccountConfig } from 'src/app/models/Settings/accountconfig.model';
import { AccountConfigService } from 'src/app/services/account-config.service';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-local-conveyance-policy',
  templateUrl: './local-conveyance-policy.component.html',
  styleUrls: ['./local-conveyance-policy.component.scss'],
})
export class LocalConveyancePolicyComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  platformType: number;
  showLoader: boolean = true;
  currentPage = 0;
  pageSize = 25;
  tableDataList: any;
  pageLimit: number[] = [25, 100];
  dataSource: MatTableDataSource<any>;
  displyColumns: any[] = ['Policy Name', 'Description', 'Status', 'disablestatus'];
  loadersList: any = [1, 2, 3, 4];
  selectedIndex = -1;
  policyObj: any;
  statusMessage: string;
  modalRef: any;
  categoryListPageLimit: number[] = [25, 100];
  showingStatus: boolean = false;
  status: Array<number> = [];
  searchTxt: any;
  policiesList = [];
  polyEnablModel = new PolyUpdateModel();
  isLcMainEnabled = false;
  userInfo: any;
  accountConfigModel = new AccountConfig();
  hasConfigData = false;
  selectedLegalEntity: any;

  constructor(private platformService: PlatformService,
    private router: NavController,
    private settingService: SettingsService, private modalService: BsModalService,
    public sharedService: SharedService,
    private accountConfigService: AccountConfigService,
    private authenticationService: AuthenticationService) { }

  ngOnInit() { 
    this.selectedLegalEntity = JSON.parse(sessionStorage.getItem('selectedLegalEntity'));
  }

  ionViewWillEnter() {
    this.platformType = this.platformService.getPlatformType();
    this.dataSource = new MatTableDataSource();
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    // this.isLcMainEnabled = this.userInfo?.isLocalConveyanceEnabled;
    this.LCPolicyAPi();
    this.getAccountConfiguration();
  }

  LCPolicyAPi() {
    this.showLoader = true;
    let modl = new PolicyListModel();
    modl.legalEntityId = this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId;
    modl.legalEntityGUID = this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId;
    modl.searchText = this.searchTxt;
    modl.recordType = 3;
    this.settingService.financialPoliciesList(modl).subscribe(res => {
      this.showLoader = false;
      if (!res.isError && res?.payload?.policyResponseDTOList) {
        this.policiesList = res?.payload?.policyResponseDTOList;
        this.dataSource = new MatTableDataSource(this.policiesList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    });
  }

  getAccountConfiguration() {
    var modl = new AccountConfig();
    this.hasConfigData = false;
    let selectedEntity = JSON.parse(sessionStorage.getItem('selectedLegalEntity'));
    modl.legalEntityId = selectedEntity.legalEntityId;
    modl.legalEntityGUID = selectedEntity.legalEntityGuId;
    this.accountConfigService.getAccountConfig(modl).subscribe(res => {
      this.hasConfigData = true;
      if (!res.isError && res.payload) {
        this.isLcMainEnabled = res.payload?.isLocalConveyanceEnabled;
      }
    })
  }

  applyFilter(filterValue: string) {
    this.searchTxt = filterValue.trim().toLowerCase();
    this.LCPolicyAPi();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  addNewFinance() {
    let navInfo = {
      url: 'settings/localconveyancepolicy',
      policyId: null,
      policyGUID: null
    };
    sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
    this.router.navigateRoot(['/settings/localconveyancepolicy/add']);
  }

  hideDropdown() {
    if (this.selectedIndex !== -1) {
      this.selectedIndex = -1
    }
  }

  toggleStatusDropdown(index, row) {
    if (this.selectedIndex != index)
      this.selectedIndex = index;
    else
      this.selectedIndex = -1;
  }

  handlePage(e) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
  }

  toggleStatus(data, content) {
    this.policyObj = data;
    this.modalRef = this.modalService.show(content, { class: 'modal-md modal-dialog-centered' });
    switch (this.policyObj.statusId) {
      case 1: this.statusMessage = "Are you sure you want to disable policy?";
        break;
      case 2: this.statusMessage = "Are you sure you want to enable policy?";
        break;
    }
    this.hideDropdown();
  }

  statusAction() {
    this.showingStatus = true;
    this.polyEnablModel.policyId = this.policyObj.policyId;
    this.polyEnablModel.policyGUID = this.policyObj.policyGUID;
    this.selectedIndex = -1;
    if (this.policyObj.statusId == 2) {
      this.settingService.enablePolicy(JSON.stringify(this.polyEnablModel)).subscribe(data => {
        if (data.errorCode == 0) {
          this.policyObj.statusId = 1;
        }
        this.processStatusApi();
      });
    } else {
      this.settingService.disablePolicy(JSON.stringify(this.polyEnablModel)).subscribe(data => {
        if (data.errorCode == 0) {
          this.policyObj.statusId = 2;
        }
        this.processStatusApi();
      });

    }
  }

  processStatusApi() {
    this.showingStatus = false;
    this.closeWindow();
  }

  closeWindow() {
    this.modalRef.hide();
  }

  editPolicy(pol: any) {
    let navInfo = {
      url: 'settings/localconveyancepolicy',
      policyId: pol?.policyId,
      policyGUID: pol?.policyGUID
    };
    sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
    // this.router.navigateRoot(['/settings/financepolicy/addLC']);
    this.router.navigateRoot(['/settings/localconveyancepolicy/add']);
  }

  lcMainPolicyToggle(e: any) {
    if (e?.detail.checked) {
      this.enableLCMainPolicy();
    } else {
      this.disableLCMainPolicy();
    }
  }

  enableLCMainPolicy() {
    let modl = new polEnableEntityModels();
    modl.legalEntityId = this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId;
    modl.legalEntityGUID = this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId;
    this.settingService.settingsLCEnable(modl).subscribe(res => {
      if (!res.isError) {
        this.isLcMainEnabled = true;
        this.tabsccess(); 
      }
    });
  }

  
  tabsccess() {
    let modl = new polEnableEntityModels();
    modl.legalEntityId = this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId;
    modl.legalEntityGUID = this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId;
    this.settingService.settingsTabsAccess(modl).subscribe(res => {
      if (!res.isError) {
        this.userInfo.isLocalConveyanceEnabled = res.payload.isLocalConveyanceEnabled
        this.userInfo.isPettyCashEnabled = res.payload.isPettyCashEnabled
        this.userInfo.isTravelEnabled = res.payload.isTravelEnabled
        sessionStorage.setItem("userInfo", JSON.stringify(this.userInfo));
        // localStorage.setItem("userInfo", JSON.stringify(this.userInfo));
        this.authenticationService.setisLocalConveyanceEnabled(res.payload.isLocalConveyanceEnabled);
        this.authenticationService.setisPettyCashEnabled(res.payload.isPettyCashEnabled);
        this.authenticationService.setisTravelEnabled(res.payload.isTravelEnabled);

        // window.location.reload();


      }
    });
  }

  disableLCMainPolicy() {
    let modl = new polEnableEntityModels();
    modl.legalEntityId = this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId;
    modl.legalEntityGUID = this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId;
    this.settingService.settingsLCDisable(modl).subscribe(res => {
      if (!res.isError) {
        this.isLcMainEnabled = false;
        this.tabsccess();
      }
    });
  }

}
