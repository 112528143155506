import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';

import { EmailConfigurationService } from '../../../services/email-configuration.service';
import { emailConfig, getEmailConfigRequest } from './emailconfig';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SettingsService } from 'src/app/services/settings-services';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
@Component({
  selector: 'app-email-configurations',
  templateUrl: './email-configurations.component.html',
  styleUrls: ['./email-configurations.component.scss']
})
export class EmailConfigurationsComponent implements OnInit {
  displayedColumns: any[] = [
    "emailtemplate",
    "emaildisplayname",
    'status',
    'disablestatus'
  ];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('searchInput', {static: false}) searchInput;
  pageTitle: string = "Settings | Email Configurations";
  userInfo: any;
  emailDataList: any;
  showLoader: boolean = true;
  showTable: boolean = false;
  emailConfig = new emailConfig();
  private emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  selectedLegalEntityInfo: any;
  isbuttonDisabled=[];
  loaderArr = [1,2,3,4];
  currentPage: number = 0;
  pageSize: number = 25;
  pageNumber: number = 1;
  isActiveTab: string = 'air';
  productId = 1;
  emailProductId: string;
  selectedIndex: any = -1;
  ind: any;
  statusFlag: any;
  statusMessage: string;
  statusConfirmDialog: any;
  isDisable: boolean = false;
  statusDisable: boolean = false;
  status: Array<number> = [];
  emailId: Array<number> = [];
  emailGuid: Array<number> = [];
  emailTypeId: Array<number> = [];
  emailTypeGuid: Array<number> = [];
  getEmailConfigRequestModel = new getEmailConfigRequest();
  serviceTypeList= [];

  constructor( private title: Title, private renderer: Renderer2,private emailconfigservice: EmailConfigurationService,
    private toastr: ToastrService, private router: Router,private modalService: NgbModal,
    private appSettingsService: SettingsService) {
    this.renderer.addClass(document.body, "emailconfigurationpage");
  }
  ngOnInit() {
    this.productId = sessionStorage.getItem('emailProductId')?parseInt(sessionStorage.getItem('emailProductId')):this.productId;

    this.title.setTitle(this.pageTitle);
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.selectedLegalEntityInfo = JSON.parse(sessionStorage.getItem("selectedLegalEntity"));
  }

  ionViewWillEnter() {
    this.getEmailConfigurations();
}

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if(this.dataSource.filteredData.length > 0){
      this.emailDataList = this.dataSource.filteredData;
      this.dataSource.filteredData.forEach((value, index) => {
          this.status[index] = value.status;
          this.emailId[index] = value.emailConfigurationId;
          this.emailGuid[index] = value.emailConfigurationId;
          this.emailTypeId[index] = value.emailTypeId;
          this.emailTypeGuid[index] = value.emailTypeGUID;
      });
    }else{
      this.emailDataList = null;
    }
  }
  getEmailConfigurations(){
    this.showLoader = true;
    this.showTable = false;
    this.dataSource = new MatTableDataSource();
    const requestBody = {
      "legalEntityId": this.selectedLegalEntityInfo.legalEntityId || this.userInfo.legalEntityId,
      "legalEntityGUID": this.selectedLegalEntityInfo.legalEntityGuId || this.userInfo.legalEntityGuId,
      "searchText": null
    }
    this.emailconfigservice.getEmailConfigurations(JSON.stringify(requestBody)).subscribe(data => {
      if (data.payload) {
        this.dataSource = new MatTableDataSource(data.payload);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.emailDataList = data.payload;
        data.payload.forEach((value, index) => {
          this.status[index] = value.status;
          this.emailId[index] = value.emailConfigurationId;
          this.emailGuid[index] = value.emailConfigurationGUID;
          this.emailTypeId[index] = value.emailTypeId;
          this.emailTypeGuid[index] = value.emailTypeGUID;
        });
        this.showLoader = false;
        this.showTable = true;
        data.payload.forEach(index=>{
          this.isbuttonDisabled[index]=false;
        })
      }else {
        this.emailDataList = null;
        this.showLoader = false;
        this.showTable = true;
      }
    });

  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, "emailconfigurationpage");
  }


    handlePage(e) {
      this.currentPage = e.pageIndex;
      this.pageSize = e.pageSize;
    }
    navigateToEditEmailConfig(data){
      let emailConfigData = {emailConfigId: data.emailConfigurationId,
         emailConfigGuid: data.emailConfigurationGUID,
          emailTypeId: data.emailTypeId,
           emailTypeGuid: data.emailTypeGUID};
      sessionStorage.setItem('emailConfigDetails', JSON.stringify(emailConfigData));
      this.router.navigate(['settings/emailconfigurations/editemailconfig']);

    }

    toggleStatusDropdown(index) {
      if (this.selectedIndex != index)
        this.selectedIndex = index;
      else
        this.selectedIndex = -1;
    }

    // Status Changing to Active/Inactive
    toggleStatus(index,content,flag) {
      this.ind = index;
      this.statusFlag = flag;
        this.statusConfirmDialog = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg', centered:true });
        switch(this.statusFlag){
          case 1: this.statusMessage = "Are you sure you want to enable Email Configuration ?";
          break;
          case 2: this.statusMessage = "Are you sure you want to disable Email Configuration ?";
          break;
        }
    }

    statusAction() {
      this.isDisable = true;
      this.statusDisable = true;
      this.getEmailConfigRequestModel.emailConfigurationId = this.emailId[this.ind]
      this.getEmailConfigRequestModel.emailConfigurationGUID = this.emailGuid[this.ind]
      this.getEmailConfigRequestModel.emailTypeId = this.emailTypeId[this.ind]
      this.getEmailConfigRequestModel.emailTypeGUID = this.emailTypeGuid[this.ind]
      this.getEmailConfigRequestModel.legalEntityId = this.selectedLegalEntityInfo?.legalEntityId || this.userInfo.legalEntityId;
      this.getEmailConfigRequestModel.legalEntityGUID = this.selectedLegalEntityInfo?.legalEntityGuId || this.userInfo.legalEntityGuId;

      if (this.status[this.ind] == 1) {
        this.emailconfigservice.changeStatusToInactive(JSON.stringify(this.getEmailConfigRequestModel)).subscribe(data => {
          if (data.errorCode == 0) {
            this.isDisable = false;
            this.statusDisable = false;
            this.toastr.success(data.payload, 'Success');
            this.closeWindow();
            this.searchInput.nativeElement.value = "";
            this.getEmailConfigurations();
          }else if (data.errorCode == 1) {
            this.isDisable = false;
            this.statusDisable = false;
            this.toastr.error(data.payload, 'Error');
            this.closeWindow();
            this.searchInput.nativeElement.value = "";
            this.getEmailConfigurations();
          }
        });
      } else {
        this.emailconfigservice.changeStatusToActive(JSON.stringify(this.getEmailConfigRequestModel)).subscribe(data => {
          if (data.errorCode == 0) {
            this.isDisable = false;
            this.statusDisable = false;
            this.toastr.success(data.payload, 'Success');
            this.closeWindow();
            this.searchInput.nativeElement.value = "";
            this.getEmailConfigurations();
          }else if (data.errorCode == 1) {
            this.isDisable = false;
            this.statusDisable = false;
            this.toastr.error(data.payload, 'Error');
            this.closeWindow();
            this.searchInput.nativeElement.value = "";
            this.getEmailConfigurations();
          }
        });
      }
    }

    closeWindow() {
      this.statusConfirmDialog.close();
    }

    hideDropdown() {
      if (this.selectedIndex !== -1) {
        this.selectedIndex = -1;
      }
    }


}
