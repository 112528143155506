import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import * as _ from "lodash";
import { hierarchyInfo, policyData } from 'src/app/models/Settings/approvalpolicies.modal';
import { SettingsService } from 'src/app/services/settings-services';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-add-approval-policies',
  templateUrl: './add-approval-policies.component.html',
  styleUrls: ['./add-approval-policies.component.scss'],
})
export class AddApprovalPoliciesComponent implements OnInit {

  @ViewChild('band') BandAutoCompleteReference: ElementRef;
  @ViewChild('designation') DesingnationAutoCompleteReference: ElementRef;
  @ViewChild('team') TeamsAutoCompleteReference: ElementRef;
  @ViewChild('user') UsersAutoCompleteReference: ElementRef;
  @ViewChild('dept') DeptAutoCompleteReference: ElementRef;
  @ViewChild('costCenter') CostCenterAutoCompleteReference: ElementRef;
  @ViewChild('close') descriptionRe;

  approvalPolicyCreateForm: FormGroup;
  loadProducts: boolean = true;
  serviceTypeList: any = [];
  selectedLegalEntity: any;
  approvalPolicyName: string;
  approvalPolicyType: any;
  productData: any = [];
  bookingData: any = [];
  selectedEmulatedAcc: any;
  bookingTypeList: any;
  products: any;
  recordType: any;
  createView: boolean = true;
  setValuesView: boolean = false;
  hierachyList = [{ hierarchyTypeName: 'Legal Entity', hierarchyTypeId: 1 }, { hierarchyTypeName: 'Cost Center', hierarchyTypeId: 2 }, { hierarchyTypeName: 'Departments', hierarchyTypeId: 3 }, { hierarchyTypeName: 'Bands', hierarchyTypeId: 4 }, { hierarchyTypeName: 'Teams', hierarchyTypeId: 5 }, { hierarchyTypeName: 'Designations', hierarchyTypeId: 6 }, { hierarchyTypeName: 'User', hierarchyTypeId: 7 }]
  policyData = new policyData('', '', '');
  filteredOptions: Observable<any[]>;
  IncludeExclude: any = [{ "id": 1, "value": "Include" }, { "id": 2, "value": "Exclude" }]
  airPortList: any;
  airLineData: any;
  bandList: any;
  bandData: any;
  designationList: any;
  teamList: any;
  userData: any;
  userList: any;
  airLineList: any;
  selectedValues = [];
  designationData: any;
  teams: any;
  departmentData: any;
  costCenterList: any;
  departmentList: any;
  costCenterData: any;
  railQuotaList: any;
  publishView: boolean;
  approvalPolicyId: any;
  approvalPolicyGUID: any;
  sub: any;
  rootTravelType: any;
  isPolicyDataLoaded: boolean;
  publishLoader: boolean;
  invalid: boolean;
  selectedRecords = [];
  recordTypeList = [{ name: "Claim", recordType: 1 }, { name: "Advance", recordType: 2 }, { name: "Local Conveyance", recordType: 3 }
    , { name: "Petty Cash Claim", recordType: 4 }, { name: "Petty Cash Request", recordType: 5 }, { name: "Travel", recordType: 6 }]
  workflowListType = [{ id: 1, name: "Default" }, { id: 2, name: "Custom" }]
  description: any;
  recordIdArray: any;
  recordNameArray: any;
  userInfo: any;
  selectedStatusId: any;
  selectedRecordsNames: any;
  teamsdata: any;
  filterSearch: any;
  onuserSearchLoading: boolean;
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toaster: ToastrService,
    private settingService: SettingsService,
    private renderer: Renderer2
  ) {
    this.renderer.addClass(document.body, "approvalpolicyadd");
    this.policyData.approvalPolicyId = this.route.snapshot.params["id"];
    this.policyData.approvalPolicyGUID = this.route.snapshot.params["guid"];
    this.selectedStatusId = this.route.snapshot.params["statusId"];
  }

  ngOnInit() {
    this.selectedLegalEntity = JSON.parse(sessionStorage.getItem('selectedLegalEntity'));
    this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
    this.approvalPolicyCreateForm = this.fb.group({
      approvalPolicyName: ['', Validators.required],
      approvalPolicyType: ['', Validators.required],
      description: [''],
      recordType: ["", Validators.required],
    });
    this.getDesignations();
    this.getCostCenter();
    this.getDepartment();
    this.getBands();
    this.getUsers();
    this.getTeamsApi();
    if (this.policyData.approvalPolicyId) {
      this.getApprovalPolicyData()
    }
  }

  //get Approval policy data
  getApprovalPolicyData() {
    let obj = {
      "approvalPolicyId": this.policyData.approvalPolicyId,
      "approvalPolicyGUID": this.policyData.approvalPolicyGUID
    };
    this.settingService.getApprovalPolicyData(JSON.stringify(obj)).subscribe(data => {
      if (data.errorCode == 0) {
        this.policyData = new policyData(data['payload'], this.selectedLegalEntity.legalEntityId, "update");
        this.approvalPolicyCreateForm.patchValue({
          approvalPolicyName: this.policyData.approvalPolicyName,
          description: this.policyData.description,
          approvalPolicyType: this.policyData.approvalPolicyType
        });
        this.patchingFormDetails();
      }
    })

  }
  patchingFormDetails() {
    this.selectedRecordsNames = this.policyData.recordTypeNames.split(',');
    for (let i = 0; i < this.recordTypeList.length; i++) {
      for (let j = 0; j < this.selectedRecordsNames.length; j++) {
        if (this.recordTypeList[i].name == this.selectedRecordsNames[j]) {
          this.selectedRecords.push(this.recordTypeList[i]);

        }
      }
    }
    this.approvalPolicyCreateForm.patchValue({
      recordType: this.policyData.recordTypeNames,
    });
  }
  getUsers() {
    const departmentHeadRequestPayload = {
      "legalEntityId": this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId,
      "legalEntityGUID":this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId,
      "statusId": 1,
      "searchText": this.filterSearch,
    };
    this.settingService
      .getUsersList(departmentHeadRequestPayload)
      .subscribe((response) => {
        if (response?.payload?.users.length > 0) {
          this.userList = response.payload.users;
          if (!this.userList.length) {
            this.userList = response.payload.users;
          }
          this.userList.forEach(element => {
            if (this.userInfo.userId == element.userId) {
              element.userdisabled = true;
            } else {
              element.userdisabled = false;
            }
          });
          this.onuserSearchLoading = false;
        }else{
          this.userList =[];
        }
      });
  }

  getTeamsApi() {
    const requestObj = {
      "legalEntityId": this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId,
      "legalEntityGUID":this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId,
      "statusId": 1,
      "searchText": this.filterSearch,
    };
    this.settingService.getTeamssList(requestObj).subscribe(res => {
      if (res.errorCode == 0) {
        this.teamsdata = res.payload.teamsList;
        if (!this.teamList.length) {
          this.teamList = res.payload.teamsList;
        }
        this.teamsdata.forEach(element => {
          if (this.userInfo.userId == element.userId) {
            element.userdisabled = true;
          } else {
            element.userdisabled = false;
          }
        });
        this.onuserSearchLoading = false;
        // this.userList.unshift({ 'firstName': "All Users", 'lastName': '', 'userId': "All" })
      }else{
        this.teamsdata =[];
      }
    });
  }


  getDesignations() {
    const desigRequest = {
      "legalEntityId": this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId,
      "legalEntityGuid":this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId,
      "statusId": 1,
      "searchText": this.filterSearch,
    }
    this.settingService.getDesignationsList(JSON.stringify(desigRequest)).subscribe(data => {
      if (data.errorCode == 0) {
        this.designationData = data.payload.designationList;
        if (!this.designationList.length) {
          this.designationList = data.payload.designationList;
        }
        this.designationData.forEach(element => {
          if (this.userInfo.userId == element.userId) {
            element.userdisabled = true;
          } else {
            element.userdisabled = false;
          }
        });
        this.onuserSearchLoading = false;
        //this.designationData.unshift({ 'designationName': "All Designations", 'designationId': "All" })
      }else{
        this.designationData =[];
      }
    });
  }
  getDepartment() {
    const deptRequest = {
      "legalEntityId":this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId,
      "legalEntityGuid":this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId,
      "statusId": 1,
      "searchText": this.filterSearch,
    };
    this.settingService.getDepartmentsist(JSON.stringify(deptRequest)).subscribe(data => {
      // if (data.errorCode == 0) {
      //   this.departmentData = data.payload.departmentList;
      //   // this.departmentData.unshift({ 'departmentName': "All Departments", 'departmentId': "All" });
      // }
      if (data.errorCode == 0) {
        this.departmentData = data.payload.departmentList;
        if (!this.departmentList.length) {
          this.departmentList = data.payload.departmentList;
        }
        this.departmentData.forEach(element => {
          if (this.userInfo.userId == element.userId) {
            element.userdisabled = true;
          } else {
            element.userdisabled = false;
          }
        });
        this.onuserSearchLoading = false;
        //this.designationData.unshift({ 'designationName': "All Designations", 'designationId': "All" })
      }else{
        this.departmentData =[];
      }
    })
  }
  getBands() {
    const bandRequest = {
      "legalEntityId": this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId,
      "legalEntityGuid":this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId,
      "statusId": 1,
      "searchText": this.filterSearch,
      "pageSize":1000
    };
    this.settingService.getBrandsList(JSON.stringify(bandRequest)).subscribe(data => {

      if (data.errorCode == 0) {
        this.bandData = data.payload.bandList;
        if (!this.bandList.length) {
          this.bandList = data.payload.bandList;
        }
        this.bandData.forEach(element => {
          if (this.userInfo.userId == element.userId) {
            element.userdisabled = true;
          } else {
            element.userdisabled = false;
          }
        });
        this.onuserSearchLoading = false;
        // this.bandData.unshift({ 'bandName': "All Bands", 'bandId': "All" });
      }else{
        this.bandData=[];
      }
    })
  }
  get f() {
    return this.approvalPolicyCreateForm.controls
  }

  navigateToSetValueView() {
    this.recordIdArray = Array.prototype.map.call(this.selectedRecords, function (item) { return item.recordType; }).join();
    this.recordNameArray = Array.prototype.map.call(this.selectedRecords, function (item) { return item.name; }).join();
    this.policyData.recordTypes = this.recordIdArray;
    this.policyData.recordTypeNames = this.recordNameArray;
    this.policyData.approvalPolicyType = this.approvalPolicyCreateForm.controls.approvalPolicyType.value;
    this.policyData.approvalPolicyName = this.approvalPolicyName;
    this.policyData.description = this.description;
    this.createView = false;
    this.setValuesView = true;
  }
  navigateToCreateView() {
    this.createView = true;
    this.setValuesView = false;
  }
  backToList() {

    // this.router.navigate(['settings/approvalpolicies']);

    if (this.createView) {
      this.createView = true;
      this.setValuesView = false;
      this.router.navigate(['settings/approvalpolicies']);
    } else {
      // this.policyData = new policyData('', '', ''); 
      this.createView = true;
      this.setValuesView = false;
    }

  }
  addNew(index, hierachyId) {
    let hierachy_name = '';
    switch (hierachyId) {
      case 2:
        hierachy_name = ''
        break;
      case 3:
        hierachy_name = ''
        break;
      case 5:
        hierachy_name = ''
        break;
      case 6:
        hierachy_name = ''
        break;
      case 4:
        hierachy_name = ''
        break;
      default:
        break;
    }

    let list = new hierarchyInfo();
    if (this.policyData.entityMapList[index].hierarchyInfo.length == 0) {
      list.id = null;
      list.hierarchyText = hierachy_name;
      list.hierarchyValue = "All";
      list.isManagerApprovalRequired = false;
      list.isFinanceApprovalRequired = false;
    } else {
      list.hierarchyText = '';
      list.hierarchyValue = "";
    }
    this.policyData.entityMapList[index].hierarchyInfo.push(list);
  }
  removePolicyRow(index, i) {
    this.policyData.entityMapList[index].hierarchyInfo.splice(i, 1)
  }
  manageToggle(event, value, type?) {
    switch (type) {
      case 'isManagerApprovalRequired':
        if (event.checked) value.isManagerApprovalRequired = true;
        else value.isManagerApprovalRequired = false;
        break;
      case 'isFinanceApprovalRequired':
        if (event.checked) value.isFinanceApprovalRequired = true;
        else value.isFinanceApprovalRequired = false;
        break;
    }

  }
  userSelection(event, item, val) {
    let self = this;
    _.forEach(this.policyData.entityMapList, function (hierachy, key) {
      if (hierachy.hierarchyTypeId == item.hierarchyTypeId) {
        let objArr = _.filter(hierachy.hierarchyInfo, { 'hierarchyValue': event.option.value.userId.toString() });
        if (objArr.length > 0) {
          //event.option.value.userName = '';
          self.toaster.error(event.option.value.userName + ' already Selected, please select another one');
        } else {
          val.hierarchyText = event.option.value.userName + ' '  + '[' + event.option.value.employeeId + ']';
          val.hierarchyValue = event.option.value.userId.toString();

        }

      }
    })
  }




  teamSelection(event, item, val) {
    let self = this;
    _.forEach(this.policyData.entityMapList, function (hierachy, key) {
      if (hierachy.hierarchyTypeId == item.hierarchyTypeId) {
        let objArr = _.filter(hierachy.hierarchyInfo, { 'hierarchyValue': event.option.value.teamId.toString() });
        if (objArr.length > 0) {
          //event.option.value.designationName = '';
          self.toaster.error(event.option.value.teamName + ' already Selected, please select another one');
        } else {
          val.hierarchyText = event.option.value.teamName + ' '  + '[' + event.option.value.teamCode + ']';
          val.hierarchyValue = event.option.value.teamId.toString();
        }
      }
    })
  }


  designationSelection(event, item, val) {
    let self = this;
    _.forEach(this.policyData.entityMapList, function (hierachy, key) {
      if (hierachy.hierarchyTypeId == item.hierarchyTypeId) {
        let objArr = _.filter(hierachy.hierarchyInfo, { 'hierarchyValue': event.option.value.designationId.toString() });
        if (objArr.length > 0) {
          //event.option.value.designationName = '';
          self.toaster.error(event.option.value.designationName + ' already Selected, please select another one');
        } else {
          val.hierarchyText = event.option.value.designationName + ' '  + '[' + event.option.value.designationCode + ']';
          val.hierarchyValue = event.option.value.designationId.toString();
        }
      }
    })
  }
  bandSelection(event, item, val) {
    let self = this;
    _.forEach(this.policyData.entityMapList, function (hierachy, key) {
      if (hierachy.hierarchyTypeId == item.hierarchyTypeId) {
        let objArr = _.filter(hierachy.hierarchyInfo, { 'hierarchyValue': event.option.value.bandId.toString() });
        if (objArr.length > 0) {
          //event.option.value.bandName = '';
          self.toaster.error(event.option.value.bandName + ' already Selected, please select another one');
        } else {
          val.hierarchyText = event.option.value.bandName + ' '  + '[' + event.option.value.bandCode + ']';
          val.hierarchyValue = event.option.value.bandId.toString();
        }
      }
    })
  }
  departmentSelection(event, item, val) {
    let self = this;
    _.forEach(this.policyData.entityMapList, function (hierachy, key) {
      if (hierachy.hierarchyTypeId == item.hierarchyTypeId) {
        let objArr = _.filter(hierachy.hierarchyInfo, { 'hierarchyValue': event.option.value.departmentId.toString() });
        if (objArr.length > 0) {
          //event.option.value.departmentName = '';
          self.toaster.error(event.option.value.departmentName + ' already Selected, please select another one');
        } else {
          val.hierarchyText = event.option.value.departmentName+ ' '  + '[' + event.option.value.departmentCode + ']';
          val.hierarchyValue = event.option.value.departmentId.toString();
        }
      }
    })
  }
  costCenterSelection(event, item, val) {
    let self = this;
    _.forEach(this.policyData.entityMapList, function (hierachy, key) {
      if (hierachy.hierarchyTypeId == item.hierarchyTypeId) {
        let objArr = _.filter(hierachy.hierarchyInfo, { 'hierarchyValue': event.option.value.costCenterId.toString() });
        if (objArr.length > 0) {
          //event.option.value.costCenterName = ""
          self.toaster.error(event.option.value.costCenterName + ' already Selected, please select another one');
        } else {
          val.hierarchyText = event.option.value.costCenterName + ' '  + '[' + event.option.value.costCenterCode + ']';
          val.hierarchyValue = event.option.value.costCenterId.toString();
        }
      }
    })
  }
  selectPolicyType() {
    this.createSturcture(this.hierachyList, this.selectedLegalEntity.legalEntityId, 'create'); // create model based on policy type
    //product dropdown

    this.policyData.legalEntityGUID = this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId;
    this.policyData.legalEntityId = this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId;
    this.policyData.statusId = 1;

  }
  createSturcture(obj, id, type) {
    this.policyData = new policyData(obj, id, type);
  }
  // user select start
  selectUsers(e) {
    this.filterSearch =''
    this.getUsers();
    this.userData = this.userList;
    e.preventDefault();
    e.stopPropagation();
    setTimeout(() => {
      this.UsersAutoCompleteReference.nativeElement.focus();
    }, 0);
  }
  onUsersSearch(val) {
    //let searchText = val.currentTarget.value.trim();
    this.onuserSearchLoading = true;
    this.filterSearch = val.currentTarget.value.trim();
    if(this.filterSearch?.length >2){
    this.getUsers()
    }
    // let filterLegalParentEntityData = this.userList.filter(userData => {
    //   if (userData.userName.toLowerCase().indexOf(searchText.toLowerCase()) >= 0 ||
    //   userData.employeeId.toLowerCase().indexOf(searchText.toLowerCase()) >= 0
    //   ) {
    //     return userData;
    //   }
    // });
    // this.userData = filterLegalParentEntityData;
  }
  onFocusUsers() {
    setTimeout(() => {
      this.userData = this.userList;
    }, 0);
  }
  // user select end
  // designation start
  selectDesignation(e) {
    this.filterSearch ='';
    this.getDesignations();
    this.designationList = this.designationData;
    e.preventDefault();
    e.stopPropagation();
    setTimeout(() => {
      this.DesingnationAutoCompleteReference.nativeElement.focus();
    }, 0);
  }
  selectTeam(e) {
    this.filterSearch ='';
    this.getTeamsApi();
    this.teamList = this.teamsdata;
    e.preventDefault();
    e.stopPropagation();
    setTimeout(() => {
      this.TeamsAutoCompleteReference.nativeElement.focus();
    }, 0);
  }
  onDesignationSearch(val) {
    this.onuserSearchLoading = true;
    this.filterSearch = val.currentTarget.value.trim();
    if(this.filterSearch?.length >2){
    this.getDesignations()
    }

    // let searchText = val.currentTarget.value.trim();
    // let filterLegalParentEntityData = this.designationData.filter(designationList => {
    //   if (designationList.designationName.toLowerCase().indexOf(searchText.toLowerCase()) >= 0 ||
    //   designationList.designationCode.toLowerCase().indexOf(searchText.toLowerCase()) >= 0) {
    //     return designationList;
    //   }
    // });
    // this.designationList = filterLegalParentEntityData;
  }
  onTeamSearch(val) {
    this.onuserSearchLoading = true;
    this.filterSearch = val.currentTarget.value.trim();
    if(this.filterSearch?.length >2){
    this.getTeamsApi()
    }
    // let searchText = val.currentTarget.value.trim();
    // let filterLegalParentEntityData = this.teamsdata.filter(teamList => {
    //   if (teamList.teamName.toLowerCase().indexOf(searchText.toLowerCase()) >= 0 ||
    //   teamList.teamCode.toLowerCase().indexOf(searchText.toLowerCase()) >= 0) {
    //     return teamList;
    //   }
    // });
    // this.teamList = filterLegalParentEntityData;
  }
  onFocusDesingation() {
    setTimeout(() => {
      this.designationList = this.designationData;
    }, 0);
  }
  onFocusTeam() {
    setTimeout(() => {
      this.teamList = this.teamsdata;
    }, 0);
  }
  //designation end
  // band start
  selectBand(e) {
    this.filterSearch ='';
    this.getBands();
    this.bandList = this.bandData;
    e.preventDefault();
    e.stopPropagation();
    setTimeout(() => {
      this.BandAutoCompleteReference.nativeElement.focus();
    }, 0);
  }
  onBandSearch(val) {
    this.onuserSearchLoading = true;
    this.filterSearch = val.currentTarget.value.trim();
    if(this.filterSearch?.length >1){
    this.getBands()
    }

    // let searchText = val.currentTarget.value.trim();
    // let filterLegalParentEntityData = this.bandData.filter(bandList => {
    //   if (bandList.bandName.toLowerCase().indexOf(searchText.toLowerCase()) >= 0 ||
    //   bandList.bandCode.toLowerCase().indexOf(searchText.toLowerCase()) >= 0) {
    //     return bandList;
    //   }
    // });
    // this.bandList = filterLegalParentEntityData;
  }
  onFocusBand() {
    setTimeout(() => {
      this.bandList = this.bandData;
    }, 0);
  }
  //band end

  //dept start
  selectDept(e) {
    this.filterSearch ='';
    this.getDepartment()
    this.departmentList = this.departmentData;
    e.preventDefault();
    e.stopPropagation();
    setTimeout(() => {
      this.DeptAutoCompleteReference.nativeElement.focus();
    }, 0);
  }
  onDeptSearch(val) {
    // let searchText = val.currentTarget.value.trim();
    // let filterLegalParentEntityData = this.departmentData.filter(departmentList => {
    //   if (departmentList.departmentName.toLowerCase().indexOf(searchText.toLowerCase()) >= 0||
    //   departmentList.departmentCode.toLowerCase().indexOf(searchText.toLowerCase()) >= 0) {
    //     return departmentList;
    //   }
    // });
    // this.departmentList = filterLegalParentEntityData;
    this.onuserSearchLoading = true;
    this.filterSearch = val.currentTarget.value.trim();
    if(this.filterSearch?.length >2){
    this.getDepartment()
    }
  }
  onFocusDept() {
    setTimeout(() => {
      this.departmentList = this.departmentData;
    }, 0);
  }
  //dept end

  //cost center start
  selectCostCenter(e) {
    this.filterSearch ='';
    this.getCostCenter();
    this.costCenterList = this.costCenterData;
    e.preventDefault();
    e.stopPropagation();
    setTimeout(() => {
      this.CostCenterAutoCompleteReference.nativeElement.focus();
    }, 0);
  }
  onCostCenterSearch(val) {
    this.onuserSearchLoading = true;
    this.filterSearch = val.currentTarget.value.trim();
    if(this.filterSearch?.length >2){
      this.getCostCenter()
    }
    // let searchText = val.currentTarget.value.trim();
    // let filterLegalParentEntityData = this.costCenterData.filter(costCenterList => {
    //   if (costCenterList.costCenterName.toLowerCase().indexOf(searchText.toLowerCase()) >= 0 ||
    //   costCenterList.cost.toLowerCase().indexOf(searchText.toLowerCase()) >= 0) {
    //     return costCenterList;
    //   }
    // });
    // this.costCenterList = filterLegalParentEntityData;
  }
  onFocusCostCenter() {
    setTimeout(() => {
      this.costCenterList = this.costCenterData;
    }, 0);
  }
  //cost center end
  selectedone: boolean = false;
  publish() {
    let count = 0, self = this;
    if (this.policyData) {
      _.forEach(this.policyData.entityMapList, function (hierachy, key) {
        if (hierachy.hierarchyInfo.length > 0) {
          _.forEach(hierachy.hierarchyInfo, function (value, key) {
            if (value.hierarchyValue && !value.validationError) {
              value.validationError = false;
            } else {
              value.validationError = true;
              value.error = 'Required';
              count++;
            }
          })
        }
      })
    }
    let isactivetoogle = false;
    this.policyData.entityMapList.forEach(element => {
      if (element.isActive) {
        isactivetoogle = true
      }

    });
    if (!isactivetoogle) {
      this.toaster.error("Please select atleast one mapping")
    } else {
      if (!count) {
        this.setValuesView = true;
        this.createView = false;
        this.publishLoader = true;
        _.forEach(this.policyData.entityMapList, function (value, key) {
        })
        this.settingService.saveOrUpdateApprovalPolicy(this.policyData).subscribe((data) => {
          if (data.errorCode == 0) {
            this.toaster.success(data.payload);
            this.publishLoader = false;
            this.createView = true;
            this.setValuesView = false;
            this.router.navigate(['settings/approvalpolicies']);
            //this.backToList();
          } else {
            this.toaster.error(data.errorMessage);
            this.publishLoader = false;
            this.router.navigate(['settings/approvalpolicies']);
            //this.backToList();
          }
        });
      } else {
        this.invalid = true;
        this.toaster.error('Required')
      }
      //this.toaster.success("please selected")
    }



  }
  getCostCenter() {
    const costCenterRequest = {
      "legalEntityId":this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId,
      "legalEntityGuid":this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId,
      "statusId": 1,
      "searchText": this.filterSearch,
    }
    this.settingService.getCostCenterList(JSON.stringify(costCenterRequest)).subscribe(data => {

      if (data.errorCode == 0) {
        this.costCenterData = data.payload.costCenterList;
        if (!this.costCenterList.length) {
          this.costCenterList = data.payload.costCenterList;
        }
        this.costCenterData.forEach(element => {
          if (this.userInfo.userId == element.userId) {
            element.userdisabled = true;
          } else {
            element.userdisabled = false;
          }
        });
        this.onuserSearchLoading = false;
        
        // this.costCenterData.unshift({'costCenterName':'All Cost Center','costCenterId':"All"})
      }
    })
  }

  getProductsNames() {
    return _.map(this.selectedRecords, 'name').join(',');
  }



  @ViewChild('recordInput', { static: false }) serviceInput: ElementRef<HTMLInputElement>;
  selected(event: MatAutocompleteSelectedEvent): void {
    if (this.selectedRecords.indexOf(event.option.value) == -1) this.selectedRecords.push(event.option.value);
    this.approvalPolicyCreateForm.patchValue({
      recordType: this.getProductsNames()
    });
    this.serviceInput.nativeElement.blur();
  }

  add(event: MatChipInputEvent): void {

    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.selectedRecords.push(value.trim());
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.approvalPolicyCreateForm.patchValue({
      requestType: null
    })
  }

  remove(e: string): void {
    const index = this.selectedRecords.indexOf(e);
    if (index >= 0) {
      this.selectedRecords.splice(index, 1);
    }
  }
}
