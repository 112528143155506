import { Directive, HostListener, ElementRef } from '@angular/core';
import { Pipe, PipeTransform } from '@angular/core';

@Directive({
  selector: '[alphaNumeric]'
})
export class AlphaNumericDirective {
  constructor(private _el2: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this._el2.nativeElement.value;
    this._el2.nativeElement.value = initalValue.replace(/[^a-zA-Z0-9 ]/g, '');
    if (initalValue !== this._el2.nativeElement.value) {
      event.stopPropagation();
    }
  }
}



@Pipe({
  name: 'convertToTitleCase'
})
export class ConvertToTitleCasePipe implements PipeTransform {
  transform(value: string): string {
    // Remove underscores and replace with spaces
    let stringWithSpaces = value.replace(/_/g, ' ');

    // Convert to title case
    return stringWithSpaces.toLowerCase().split(' ').map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(' ');
  }
}
@Directive({
  selector: '[alphaNumericSpace]'
})
export class AlphaNumericSpaceDirective {
  constructor(private _el2: ElementRef) { }
  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this._el2.nativeElement.value;
    this._el2.nativeElement.value = initalValue.replace(/[^a-zA-Z0-9 ]/g, '');
    if (initalValue !== this._el2.nativeElement.value) {
      event.stopPropagation();
    }
  }
}



@Directive({
  selector: '[alphabets]'
})
export class alphabetsDirective {
  constructor(private _el2: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this._el2.nativeElement.value;
    this._el2.nativeElement.value = initalValue.replace(/[^a-zA-Z ]/g, '');
    if (initalValue !== this._el2.nativeElement.value) {
      event.stopPropagation();
    }
  }
}

@Directive({
  selector: '[numbersOnly]'
})
export class numbersDirective {
  constructor(private _el2: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event) {

    const initalValue = this._el2.nativeElement.value;
    this._el2.nativeElement.value = initalValue.replace(/[^0-9]/g, '');
    if (initalValue !== this._el2.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
@Directive({
  selector: '[numbersSpacesOnly]'
})
export class numbersSpacesDirective {
  constructor(private _el2: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event) {

    const initalValue = this._el2.nativeElement.value;
    this._el2.nativeElement.value = initalValue.replace(/[^0-9\s]/g, '');
    if (initalValue !== this._el2.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
/*============Address Fields====================*/
@Directive({
  selector: '[alphabetsChar]'
})
export class alphabetsSpecialChar {
  constructor(private _el2: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this._el2.nativeElement.value;
    this._el2.nativeElement.value = initalValue.replace(/[^a-zA-Z0-9 ",-]/g, '');
    if (initalValue !== this._el2.nativeElement.value) {
      event.stopPropagation();
    }
  }
}

/*================Email Fields=================*/
@Directive({
  selector: '[emailPatter]'
})
export class emailPatter {
  constructor(private _el2: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this._el2.nativeElement.value;
    this._el2.nativeElement.value = initalValue.replace(/[^a-zA-Z0-9._@]/g, '');
    if (initalValue !== this._el2.nativeElement.value) {
      event.stopPropagation();
    }
  }
}

@Directive({
  selector: '[desimalnumbersOnly]'
})
export class DesimalDirective {

  constructor(private _el:ElementRef) { }

  @HostListener('input',['$event']) onInputChange (event) {
    const initalValue = this._el.nativeElement.value
    this._el.nativeElement.value = initalValue.replace(/[^0-9.]/g, '');
    if(initalValue !== this._el.nativeElement.value){
      event.stopPropagation();
    }
  }

}

@Directive({
  selector: '[specialCharecters]'
})
export class specialCharectersDirective {

  constructor(private _el:ElementRef) { }

  @HostListener('input',['$event']) onInputChange (event) {
    const initalValue = this._el.nativeElement.value
    this._el.nativeElement.value = initalValue.replace(/[^a-zA-Z0-9\/-]+$/g, '');
    if(initalValue !== this._el.nativeElement.value){
      event.stopPropagation();
    }
  }

}
  // @Directive({
  //   selector: 'input[plainText]'
  // })
  // export class plaintextDirective {
  //   constructor(private _el2:ElementRef) { }

  //   @HostListener('input',['$event']) onInputChange (event) {
  //     const initalValue = this._el2.nativeElement.value;
  //     this._el2.nativeElement.value = initalValue.replace(/(<([^>]+)>)/g, '');
  //     if(initalValue !== this._el2.nativeElement.value){
  //       event.stopPropagation();
  //     }
  //   }
  // }