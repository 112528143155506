import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ToastrService } from 'ngx-toastr';
import { GetReportModal, ReportListResModel } from 'src/app/models/Reports/reports.model';
import { PlatformService } from 'src/app/services/platformbase.service';
import { ReportsService } from 'src/app/services/reports.service';

@Component({
  selector: 'app-detailapproval',
  templateUrl: './detailapproval.component.html',
  styleUrls: ['./detailapproval.component.scss'],
})
export class DetailapprovalComponent implements OnInit {
  userInfo: any;
  @Input() report;
  getReport: any;
  showLoading = true;
  platformType: number;
  detailReport: any;
  fromPage = "approvePage";
  showMobileDetails: boolean;
  dummyList = [1, 2, 3, 4];
  getReportModal = new GetReportModal();

  constructor(
    public modalController: ModalController,
    private reportService: ReportsService,
    private platformService: PlatformService,
    public toastr: ToastrService
  ) {
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  }

  ngOnInit() {
    this.platformType = this.platformService.getPlatformType();
    this.getReportDetails();
  }

  getReportDetails() {
    this.getReportModal.claimId = this.report.claimId;
    this.getReportModal.claimGUID = this.report.claimGUID;
    this.getReportModal.legalEntityId = this.userInfo.legalEntityId;
    this.getReportModal.legalEntityGUID = this.userInfo.legalEntityGuId;
    this.getReportModal.userId = this.userInfo.userId;
    this.getReportModal.userGUID = this.userInfo.userGuId;
    this.reportService.getReportDetails(this.getReportModal).subscribe(data => {
      if (!data.isError && data.errorCode === 0) {
        this.showLoading = false;
        this.getReport = new ReportListResModel(data.payload);
      } else {
        this.showLoading = false;
        this.toastr.error(data.errorMessage, 'Error');
      }
    });

  }

  reportDetailView() {
    this.detailReport = this.getReport;
    this.fromPage = this.fromPage;
    this.showMobileDetails = false;
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
