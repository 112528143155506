import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Constants } from 'src/app/Constants';

@Injectable({
  providedIn: 'root'
})
export class DatechangeService {

  constructor(private pipe: DatePipe) { }

  convertAPIToCalendar(val: string) {
    const displaydate = this.pipe.transform(val, Constants.calendarDateFormat);
    return displaydate;
  }

  convertCalendarToAPI(val: string) {
    const displaydate = this.pipe.transform(val, Constants.apiDateFormat);
    return displaydate;
  }

  convertCalendarDate(val: string) {
    console.log(val);
    const displaydate = this.pipe.transform(val, Constants.mobileDateFormat);
    return displaydate;
  }
}


