import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from "@angular/core"
import { Observable, throwError } from "rxjs";
import { retry, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
// import { Storage } from '@ionic/storage';
import { NavController } from '@ionic/angular';

@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {
  userInfo:any;
  reHeaders:any
  constructor(public http: HttpClient, private router: NavController,
    // public storage: Storage
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
    let reHeaders=sessionStorage.getItem('s3uploadUrl')
    let authReq;
    if (!request.url.includes("validate-user") && !request.url.includes("refresh-token") && userInfo && !request.url.includes(reHeaders)) {

      // Parse the body if it's a string
      let requestBody = request.body;
      if (typeof requestBody === 'string') {
        try {
          requestBody = JSON.parse(requestBody);
        } catch (e) {
          console.log('Failed to parse request body:', e);
        }
      }

      authReq = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + userInfo.accessToken
        },
        // body: { ...request.body, 'emulatedUserId': sessionStorage.getItem('showEmulate') ? userInfo?.userId : null}
        body: { ...requestBody, 'emulatedUserId': userInfo?.userId, 'emulatedUserGuId': userInfo?.userGuId}
      });
    } else {
      authReq = request.clone({});
    }
    return next.handle(authReq).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status == 401) {
          this.router.navigateRoot(["/login"]);
        }
        return throwError(null);
      })
    );
  }
}
