import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PlatformService } from 'src/app/services/platformbase.service';
import { Title } from '@angular/platform-browser';
import { ForgotModel } from 'src/app/models/Login/login.model';
import { AppConfig } from '../../config/app.config';
import { htmlValidator } from '../../validations/custom-validation';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { MenuController, NavController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  show = true;
  forgotForm: FormGroup;
  platformType: number;
  submitted = false;
  validationMsg: any;
  buttonDisabled: boolean = false;
  forgotModel = new ForgotModel();
  applogo: string;
  userInfo: any;
  pathlogo: any;
  appLogo: any;
  isAndroidIOS: any;

  constructor(
    private formBuilder: FormBuilder,
    private platformService: PlatformService,
    private router: NavController,
    private toaster: ToastrService,
    private authenticationService: AuthenticationService,
    private title: Title,
    public menuCtrl: MenuController
  ) { 
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  }

  ionViewWillEnter() {
    this.menuCtrl.enable(false);
    this.submitted = false;
    let applicationTitle = this.userInfo?.applicationTitle;
    if(applicationTitle){
        this.title.setTitle('Forgot Password ' + ' | ' + applicationTitle);
    }else{ 
      this.title.setTitle('Forgot Password | NoExpenses');
    }
    this.forgotForm.reset();
  }

  ngOnInit() {
    this.appLogo = environment.appLogo;
    this.isAndroidIOS = this.platformService.isAndroidIOS();
    if (environment.appLogo == "expenses") {
      this.applogo = "noexp";
      this.pathlogo = './assets/images/Logo-EP.svg';
    } else if (environment.appLogo == "fc365") {
      this.applogo = "FC365logo";
      this.pathlogo = './assets/svg/FC365logo.svg';
    } else {
      this.applogo = "noexp";
      this.pathlogo = './assets/images/Logo-EP.svg';
    }
    let path = window.location.origin;
    // (path == 'http://localhost:8100')
    if (path == 'https://www.byjusezclaim.com') {
        this.pathlogo = './assets/images/byjuslogo.svg';
    } else {
        // this.pathlogo = './assets/images/Logo-EP.svg';
    }


    this.platformType = this.platformService.getPlatformType();
    this.validationMsg = AppConfig;
    this.forgotForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, htmlValidator]],
    });
  }

  retrunLogin() {
    this.router.navigateRoot(['login']);
  }

  get f() { return this.forgotForm.controls; }

  sendLink() {
    this.submitted = true;
    if (!this.forgotForm.valid) {
      return;
    } else {
      this.buttonDisabled = true;
      this.authenticationService.forgotEmail(this.forgotModel).subscribe(res => {
        if (res.isError === true) {
          this.toaster.error(res.errorMessage, 'Error');
          this.forgotForm.reset();
          this.show = true;
          this.buttonDisabled = false;
        } else if (!res.isError) {
          this.toaster.success("Email Sent Successfully", 'Success');
          this.buttonDisabled = false;
          this.show = false;

        }
      });
    }
  }
  tryAgain() {
    this.buttonDisabled = false;
    this.show = true;
  }

}
