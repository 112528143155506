export interface IataList {
    iataGroupId: number;
    iataGroupName: string;
}

export interface ProductList {
    productId: number;
    productName: string;
}

export class Banner {
    bannerId: number;
    isDeleted: boolean;
    url: string;
    bannerUrlLink:string;
    redirectionURL:string;
}

export class BannerObject {
    companyId: number;
    companyGuid: string;
    legalEntityId: number;
    legalEntityGuId: string;
    banners: Banner[] = [];
    showBanner:boolean;
}

export class GetBanner {
    companyId: number;
    companyGuid: string;
    legalEntityId: number;
    legalEntityGUID: string;
}


