import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { PlatformService } from 'src/app/services/platformbase.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
})
export class BaseComponent implements OnInit {
  showHeader = true;
  platformType: number;
  url: any;

  constructor(private title: Title, private platformService: PlatformService, public authService: AuthenticationService) { }

  ngOnInit() {
    this.platformType = this.platformService.getPlatformType();
    this.title.setTitle('Dashboard');
    this.authService.showHeaderEV.subscribe((res) => {
      this.showHeader = (environment.appLogo == 'fc365' && this.platformService.isAndroidIOS() == 2) ? res : true;
    })
    this.showHeader = (environment.appLogo == 'fc365' && this.platformService.isAndroidIOS() == 2) ? false : true;
  }
}


