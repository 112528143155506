import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { IonInput, IonSlides, ModalController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Constants } from 'src/app/Constants';
import { Camera, CameraOptions } from "@ionic-native/camera/ngx";
import { CreateAdvance } from 'src/app/models/Advance/advance.model';
import { CategoryAndMerchantModel, CurrencyList, TripFilterModal } from 'src/app/models/Common/common.model';
import { advanceItemModel, advanceNewModel, BulkModel, bulkOCRAttachment, CapturedImageModal, ExpenseAttachments, ExpenseListModel, ExpenseModel, GetExpenseModels, MultiAdvanceModel, NewTripModel, ocrAttachment, ocrReqModel, TripsListModel } from 'src/app/models/Expenses/expenses.model';
import { CommonService } from 'src/app/services/common-services/common.service';
import { DatechangeService } from 'src/app/services/common-services/datechange.service';
import { ExpensesService } from 'src/app/services/expenses.service';
import { PlatformService } from 'src/app/services/platformbase.service';
import * as _ from "lodash";
import { SharedService } from 'src/app/services/common-services/shared.service';
import { CustomSelectDropdownComponent } from '../../custom-select-dropdown/custom-select-dropdown.component';
import { CustomDatePickerComponent } from '../../custom-date-picker/custom-date-picker.component';
import { ImageViewComponent } from '../../dashboard/image-view/image-view.component';
import { ActivatedRoute } from '@angular/router';
import { FetchTripInfo, GetTripInfo } from 'src/app/models/Reports/reports.model';
import { ReportsService } from 'src/app/services/reports.service';
@Component({
  selector: 'app-new-advance',
  templateUrl: './new-advance.component.html',
  styleUrls: ['./new-advance.component.scss'],
})
export class NewAdvanceComponent implements OnInit, OnDestroy {
  isTripOpen = true;
  isTripOpenIcon = true;
  platformType: any;
  modalRef: any;
  // requestArray = [{ name: 'imprest', id: 3 }]
  requestArray1 = [{ categoryName: 'Imprest', categoryId: 3 }]
  userInfo: any;
  currencytempList: any;
  currencyList: any;
  selectedBaseCurr: any;
  exchangeAmt: number;
  maxFromDate: Date;
  minToDate: Date;
  maxToDate: Date;
  saveBtnDisable = false;
  submitBtnDisabled = false;
  submitted = false;
  @ViewChild('fileUpload', { static: false }) fileUploadInput: ElementRef;
  @ViewChild("currency", { static: false }) public currencyAutoCompleteReference: ElementRef;
  @ViewChild("advanceTypefocusOff", { static: false }) advanceTypefocus: ElementRef;
  @ViewChild("dynamicTemp", { static: false }) private dynamicTemp;
  //fileName: any;
  // loaded: boolean;
  // imgContent: any;
  // imageLoaded: boolean;
  editAdvance: any;
  expense: any;
  expenselist = [];
  FromselectedDate: string;
  minToDateMobile: String;
  ToselectedDate: string;
  selectedDate: any;
  activeIndex: any = 0;
  expcurrencySymbol: string;
  @ViewChild(IonSlides) slides: IonSlides;

  capturedImage: any;
  showText = false;
  expConvRate: any;

  editlist: any;
  total: number = 0;
  policyList: any;

  receiptWarning = false;
  receiptCatWarning = false;
  categDesc = false;
  expenseWarning = false;
  expenseCatWarning = false;
  showCurrencyList: boolean = false;
  baseCurrencyList: any;
  minDate: any = moment(new Date()).format('YYYY-MM-DD');
  private scrollChangeCallback: () => void;
  isEditAdvance: boolean = false;
  loginUserAttchementChecking: boolean = false;
  tripsSearchtxt: string = "";


  slideOpts = {
    zoom: false,
    slidesPerView: 1,
    centeredSlides: true,
    spaceBetween: 10,
    initialSlide: 0,
  };
  sliderOpts = {
    zoom: false,
    slidesPerView: 1.2,
    centeredSlides: false,
    spaceBetween: 10,
    initialSlide: 0,
  };

  showLoading = false;
  listLoaderDic = { noApi: true, loader: false };

  maxDateStr: any = moment(new Date().getTime()).add(2000, 'years').format('YYYY-MM-DD');
  minDateStr: any = moment(new Date().getTime()).subtract(2000, 'years').format('YYYY-MM-DD');

  maxDateToStr: any = moment(new Date().getTime()).add(2000, 'years').format('YYYY-MM-DD');
  minDateToStr: any = moment(new Date().getTime()).subtract(2000, 'years').format('YYYY-MM-DD');

  //newcode
  imgUpload = false;
  dragging: boolean = false;
  loaded: boolean = false;
  imageLoaded: boolean = false;
  imgContent: string = "";
  fileName: any;
  uploadedfiles = [];
  imagesCount = 0;
  totalBulkModel = new BulkModel();
  active = "";
  expenseModel = new ExpenseModel();
  bulkUploadRejectStatus: boolean = false;
  proofid: any;
  imgNum = 1;
  imageIndex: number;
  isAssociated: boolean = false;
  isAssociatedAttchments: any;
  associatedAttchements = [];
  buttonDisabled: boolean = false;
  _bulkProgressState: boolean = false;
  defaultIndex = 0;
  getExpenseIds = new GetExpenseModels();
  commaSeperatorArray: any;
  pageTo: string;
  tripfilterModal = new TripFilterModal();
  claimTaggedTripList: any;
  expenseArray: any;
  selectedTripInvoiceArray: any[];
  currentIndex = 0;
  expenseId: any;
  expenseGUID: any;
  private _expenseTypeVerification: any;
  type: number;
  isReadOnly: boolean;
  singleTravelTrip: boolean = false;
  multiTravelTrip: boolean = false;
  continuoeDisable: boolean = false;
  tripsList: any;
  selectedTrips = [];
  tripdata: any[];
  dummyList = [1, 2, 3, 4, 5, 6];
  tripList: any;
  pdfloader: boolean = false;
  value: any;
  index: any;
  closeOptionsPopup: boolean = false;
  mouseover: boolean = false;
  currentField: string;
  attachmentIndex = 0;
  draftBtns: boolean = false;
  selectedTrip:any;
  tripsdummyList = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  serviceList =[];
  invoiceDetails: any[];
  pdfData: string;
  isPdf: boolean;
  tripServiceAttachments =[];
  selectedTripsdata=[];
  isAndroid: any;
  isAdvanceReadOnly: boolean;
  navinfopath: any;
  tripAttachment: boolean;
  advancebtns: boolean;
  tripdetails: any;
  deleteexpenseGuid: any;
  deleteexpenseId: any;
  tripTravellers=[];
  hideTripDetails: any;
  selectedIndex: any;
  currencyCode: any;
  editexchange: any;
  userexpConvRate: any;

  addAdvance: FormGroup;
  companyForm: FormGroup;
  advanceSummary: FormGroup;
  multiAdvanceModel = new MultiAdvanceModel();
  advanceNewModel = new advanceNewModel();
  globalIndex = 0;
  isAdmin: any;
  isAdvanceEditableUser = true;
  isAdvanceEditableFinance = false;
  modalRefDynamic: BsModalRef;
  dTempHeader: string;
  dTempBody: string;

  deleteAdvanceID:any;

  constructor(private platformService: PlatformService, private modalService: BsModalService,
    private toastr: ToastrService,
    private router: NavController, private translate: TranslateService, public sharedService: SharedService,
    private commonService: CommonService,
    private camera: Camera,
    public modalController: ModalController,
    private formBuilder: FormBuilder,
    private reportsService: ReportsService,
    private datechangeService: DatechangeService, private expensesService: ExpensesService, 
    private activateRouter: ActivatedRoute,
    private expenseService: ExpensesService ) {
      this.isAndroid = this.platformService.isAndroid();
    }


  ngOnInit() {
    this.platformType = this.platformService.getPlatformType();
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.isAdmin = JSON.parse(sessionStorage.getItem('isadmin'));
    this.expcurrencySymbol = this.userInfo.baseCurrency.currencyCode;
    this.advanceSummary = this.formBuilder.group({
      advanceName: ["", [Validators.required]],
      advanceTypeId: [""],
      advanceTypeName: [""],
    });
    this.getClaimTypes();
    this.getCurrencylist();
    this.form();
    const date = new Date();
    this.maxFromDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
    this.maxToDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
    this.addCompanyForm();
    this.getEditAdvData();
    // this.sharedService.getAdvanceData().subscribe((value) => {
    //   if (value) {
    //     this.editAdvance = value;
    //     this.getExpenseSumary(this.editAdvance)
    //   }

    // });
    this.NoTripform();
    // this.addAdvance.controls.isTrip.patchValue(false)
    // this.createAdvanceModel.isOnTrip = false;
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (this.userInfo.attachmentFormat != null || this.userInfo.attachmentFormat != '') {
      this.commaSeperatorArray = this.userInfo.attachmentFormat.split(',');
    }
    this.hideTripDetails=(sessionStorage.getItem("hideTripDetails"));
    console.log('mt trips :',this.hideTripDetails);
  }


  ionViewWillEnter() {
    this.activateRouter.queryParams.subscribe(res => {
      this.pageTo = res.fromPage;
    });
  }
  ngAfterViewInit() {
    this.scrollChangeCallback = () => this.onContentScrolled(event);
    window.addEventListener('scroll', this.scrollChangeCallback, true);
  }
  getEditAdvData() {
    let val = JSON.parse(sessionStorage.getItem("toPathInfo"));
    this.navinfopath = JSON.parse(sessionStorage.getItem("toPathInfo"));
    if (val) {
      if (val["url"]) {
        if ((val["url"] == 'detailexpense') || (val["url"] == 'advance') || (val["url"] == 'advance-duplicate')||(val["url"] == 'approvals')||  (val["url"] == 'selectedTrips') || (val["url"] == 'expensesTrip')) {
          this.selectedTrip = val['advance']?.claimTaggedTripList || [];
          // let requestobject = {
          //   id: null,
          //   expenseId: null,
          //   requestId: this.selectedTrip.requestId ?this.selectedTrip.requestId:'',
          //   requestGUID: this.selectedTrip.requestGUID ?this.selectedTrip.requestGUID :'',
          //   tripId: this.selectedTrip.tripId,
          //   tripGUID: this.selectedTrip.tripGUID,
          //   startDate: this.selectedTrip.tripStartDate,
          //   endDate: this.selectedTrip.tripEndDate,
          //   fromLocation: this.selectedTrip.tripName,
          //   toLocation: "",
          //   totalAmount: this.selectedTrip.tripCost,
          //   paymentMode: this.selectedTrip.paymentMode,
          //   productId: this.selectedTrip.productId,
          //   isAssociated: true,
          //   productName: this.selectedTrip.productName
          // };
          if(val["url"] == 'advance' && val["from"] == "camera") {
            this.selectedTrip = val['advance']?.claimTaggedTripList || [];
            this.tripTravellers = val['advance']?.tripTravellersList || []
          }
          this.selectedTrip.forEach(list => {
            let requestobject = {
              id: list.id || null,
              expenseId: list.expenseId || null,
              requestId: list.requestId ?list.requestId:'',
              requestGUID: list.requestGUID ?list.requestGUID :'',
              tripId: list.tripId,
              tripGUID: list.tripGUID,
              startDate: list.tripStartDate?list.tripStartDate :list.startDate,
              endDate: list.tripEndDate,
              fromLocation: list.tripName?list.tripName :list.fromLocation,
              toLocation: "",
              totalAmount: list.tripCost?list.tripCost:list.totalAmount,
              paymentMode: list.paymentMode,
              productId: list.productId,
              isAssociated: true,
              productName: list.productName?list.productName:list.serviceName,
              invoiceList:list.invoiceList
            };
            this.selectedTrips.push(requestobject);
          });
          
          if ((val["url"] == 'advance' ||val["url"] == 'approvals') && val["from"] != "camera") {
            this.isReadOnly = val["isReadOnly"]
            this.isAdvanceReadOnly =val["isAdvanceReadOnly"];
            this.advancebtns =val["advancebtns"]
            this.isEditAdvance = true;
            this.editAdvance = val["advance"];
            console.log('rajesh calling edit functionality :',this.editAdvance);
            this.getExpenseSumary(this.editAdvance);
          } else {
            this.isEditAdvance = true;
            let returnData = val["advance"];
            this.createAdvanceModel = returnData;
            this.createAdvanceModel.originalAmount = val["advance"]?.unformattedOriginalAmount || val["advance"]?.originalAmount;
            this.createAdvanceModel.baseAmount = val["advance"]?.unformattedBaseAmount;

            this.addAdvance.controls.requestType.patchValue(returnData.claimName);
            this.addAdvance.controls.fromDate.patchValue(
              this.convertDateToUtc(this.createAdvanceModel.tripFromDate)
            );

            this.addAdvance.controls.toDate.patchValue(
              this.convertDateToUtc(this.createAdvanceModel.tripToDate)
            );

            if(val["from"] == "camera" && val['advance'].claimId) {
              this.isEditAdvance = true;
              this.editAdvance = val["advance"];
            }
          }
          if (val["url"] == "advance" && val["from"] == "camera") {
            this.gets3UploadUrl(val['fileName'], this.expenseService.cameraImageFile, val['advance']?.expenseAttachments || []);
          }
        }
      } else {

        this.currencyList = _.uniqBy(this.currencyList, 'currencyId');
        this.currencyList.some((item, i) => {
          if (item.currencyId === this.userInfo.baseCurrency.currencyId) {
            this.currencyList.splice(i, 1);
            this.currencyList.unshift(item);
            if (!this.editAdvance) {
              this.createAdvanceModel.originalCurrencyIcon = item.currencyIcon;
              this.createAdvanceModel.originalCurrencyCode = item.currencyCode;
              this.createAdvanceModel.originalCurrencyId = item.currencyId;
            }
          }
          return item.currencyId === 64
        });
      }

      console.log('incoming data :',this.navinfopath,this.selectedTrips,this.claimTaggedTripList);
    }

    console.log('edit advance ',this.navinfopath,this.editAdvance);
  }

  form() {
    this.addAdvance = this.formBuilder.group({
      admins: this.formBuilder.array([])
    })
  }

  companyFormInit(){
    return this.companyForm = this.formBuilder.group({
      requestType: [""],
      originalCurrencyCode: [this.userInfo.baseCurrency.currencyCode],
      originalCurrencyId: [this.userInfo.baseCurrency.countryId],
      originalAmount: ["", [Validators.required, Validators.maxLength(8), Validators.min(1)]],
      reason: ["", this.userInfo?.isDescriptionMandatory ? [Validators.required] : []],
      isTrip: ["",],
      tripId: ["",],
      tripName: ["",],
      description: [""],
      fromDate: [""],
      toDate: [""],
    });
  }

  get admins() {
    return this.addAdvance.controls["admins"] as FormArray;
  }

  addCompanyForm(adv?: advanceItemModel){
    this.admins.push(this.companyFormInit());
    let advanceItem = new advanceItemModel();
    advanceItem.description = adv ? adv.description :'';
    advanceItem.fromDate = '';
    advanceItem.isTrip = adv ? adv.isTrip :'';
    advanceItem.originalAmount = adv ? adv.originalAmount :'';
    advanceItem.originalCurrencyCode = adv ? adv.originalCurrencyCode : this.userInfo.baseCurrency.currencyCode;
    advanceItem.originalCurrencyId = adv ? adv.originalCurrencyId : this.userInfo.baseCurrency.countryId;
    advanceItem.originalCurrencyIcon = adv ? adv.originalCurrencyIcon : this.userInfo.baseCurrency.currencyIcon;
    
    advanceItem.exchangeRate = adv ? adv.exchangeRate : 1;
    advanceItem.editedExchangeRate = adv ? adv.editedExchangeRate : null;
    advanceItem.reason = adv ? adv.reason :'';
    advanceItem.toDate = '';
    advanceItem.isTrip = false
    advanceItem.tripId = adv ? adv.tripId :'';
    advanceItem.tripName = adv ? adv.tripName :'';
    advanceItem.expenseId = adv ? adv.expenseId : null;
    advanceItem.expenseGUID = adv ? adv.expenseGUID :'';
    advanceItem.claimId = adv ? adv?.claimId : ''
    advanceItem.claimGUID = adv ? adv?.claimGUID : ''
    advanceItem.claimCode = adv ?  adv?.claimCode : ''
    advanceItem.expenseAttachments = adv ? adv.expenseAttachments :[];
    this.multiAdvanceModel.multipleAdvanceItem.push(advanceItem);

  }

  ToReportModal(template: TemplateRef<any>) {

    const config = {
      ignoreBackdropClick: true,
      class: "modal-md modal-dialog-centered",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(template, config);
  }

  backToReport() {
    if(this.navinfopath?.url == 'approvals' || (this.navinfopath?.url == 'advance' && this.navinfopath?.fromPage == 'approvePage')){
      this.router.navigateRoot(["/approvals"]);
    }else{
      this.router.navigateRoot(["/advance"]);
    }
   
    // if(this.pageTo == 'dashboard'){
    //   this.router.navigateRoot(["/dashboard"]);
    // }else{
    //   if(this.platformType == 1){
    //     this.modalRef.hide();
    //   }

    // }
  }

  dismiss() {
    this.resetSubscrptns();
    //this.router.navigateRoot(["advance"]);
    if (this.pageTo == 'dashboard') {
      this.router.navigateRoot(["/dashboard"]);
    } else {
      this.router.navigateRoot(["/advance"]);
    }
  }

  async openDateSheet(string: any) {
    const modal = await this.modalController.create({
      component: CustomDatePickerComponent,
      componentProps: {
        selectedDate: string == '1' ? (this.FromselectedDate ? this.FromselectedDate : new Date()) : (this.ToselectedDate ? this.ToselectedDate : new Date()),
        fromDate: new Date('1981-01-01'),
        toDate: new Date()
      },
      cssClass: 'custom-bottom-sheet'
    });
    modal.onDidDismiss().then(result => {
      if (result.role === 'changed' && result.data) {

        if (string == "1") {
          this.FromselectedDate = result.data.displayDate;
          this.minToDateMobile = this.FromselectedDate;
          this.createAdvanceModel.tripFromDate = result.data.formattedDate;
        } else {
          this.ToselectedDate = result.data.displayDate;
          this.createAdvanceModel.tripToDate = result.data.formattedDate;
        }

        //this.createAdvanceModel.tripToDate = result.data.formattedDate;
      }
    });
    return await modal.present();
  }

  getFromDateCall(ev) {

    this.FromselectedDate = moment(ev.detail.value).format("MM-DD-YYYY");
    this.minToDateMobile = this.FromselectedDate;
    this.createAdvanceModel.tripFromDate = moment(ev.detail.value).format("DD-MM-YYYY");
    this.minDateStr = moment(ev.detail.value).format("YYYY-MM-DD");
    this.minDateToStr = this.minDateStr;
  }

  getToDateCall(ev) {
    this.ToselectedDate = moment(ev.detail.value).format("MM-DD-YYYY");
    this.createAdvanceModel.tripToDate = moment(ev.detail.value).format("DD-MM-YYYY");
    this.maxDateStr = moment(new Date().getTime()).add(2000, 'years').format('YYYY-MM-DD');
    this.minDateStr = moment(new Date().getTime()).subtract(2000, 'years').format('YYYY-MM-DD');

  }


  selectrequestOption(e) {
    if (this.platformType == 1) {
      this.addAdvance.controls.requestType.patchValue(e.categoryName);
      this.createAdvanceModel.expenseType = e.categoryId
    }
  }

  onCurrencySearch(val) {
    // if (this.listLoaderDic?.noApi) {
    //   this.listLoaderDic.loader = true;
    // }
    let searchText = val.currentTarget.value.trim();
    if (this.currencytempList != null) {
      if (searchText != "") {
        let filterdata = this.currencytempList.filter((data) => {
          if (
            data.currencyCode.toLowerCase().indexOf(searchText.toLowerCase()) >=
            0
          ) {
            return data;
          }
        });
        this.currencyList = filterdata;
      } else {
        this.currencyList = this.currencytempList;
      }
    }
  }
  categoryAndMerchantModel = new CategoryAndMerchantModel();
  getCurrencylist() {
    this.categoryAndMerchantModel.legalEntityId = this.userInfo.legalEntityId;
    this.categoryAndMerchantModel.legalEntityGUID = this.userInfo.legalEntityGuId;
    this.commonService.getCurrencylist(this.categoryAndMerchantModel).subscribe((res) => {

      if (this.listLoaderDic?.noApi) {
        this.listLoaderDic.noApi = false;
      }
      if (!res.isError && res.errorCode == 0) {
        let listarray = res.payload;
        this.currencytempList = listarray.map((list) => new CurrencyList(list));
        this.currencyList = listarray.map((list) => new CurrencyList(list));
        this.currencyList = _.uniqBy(this.currencyList, 'currencyId');
        this.currencyList.some((item, i) => {
          if (item.currencyId === this.userInfo.baseCurrency.currencyId) {
            this.currencyList.splice(i, 1);
            this.currencyList.unshift(item);
            if (!this.editAdvance) {
              this.createAdvanceModel.originalCurrencyIcon = item.currencyIcon;
              this.createAdvanceModel.originalCurrencyCode = item.currencyCode;
              this.createAdvanceModel.originalCurrencyId = item.currencyId;
              this.createAdvanceModel.exchangeRate = item.conversionRate?item.conversionRate:1;
            }
          }
          return item.currencyId === 64

        });

        // if(this.isEditAdvance){
        //   let originalCurrencyId = this.createAdvanceModel.originalCurrencyId;
        //   let currencyDetails = _.find(this.currencyList, function (object) { return object.currencyId == originalCurrencyId; });
        //   this.expConvRate = currencyDetails.conversionRate;
        //   this.expcurrencySymbol = currencyDetails.currencyCode;
        //   // originalCurrencyCode: "INR"
        //   // originalCurrencyIcon: "fa-inr"
        //   // originalCurrencyId: 64

        // }

      } else if (!res.isError && res.errorCode == 2) {
        this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
      }
    });
  }

  get expenseItemsArray() {
    return this.addAdvance.get("expenseItems") as FormArray;
  }

  removeExpenseItems(index) {
    this.expenseItemsArray.removeAt(index);
    this.convertAmt();
  }
  convertAmt() {
    let userBaseCurr = this.userInfo.baseCurrency.currencyId;
    //edit
    if (this.editlist) {
      if (this.editlist.exchangeRate) {
        this.exchangeAmt = this.total * this.editlist.exchangeRate;
      }
      if (userBaseCurr && !this.editlist.exchangeRate) {
        this.exchangeAmt = this.total;
      }

    }
    // If we don't select and enter money direct
    if (userBaseCurr && !this.editlist) {
      this.exchangeAmt = this.total;

    }
    // if we select same currenct and enter
    if (this.selectedBaseCurr !== undefined) {
      if (userBaseCurr == this.selectedBaseCurr.currencyId) {
        this.exchangeAmt = this.total;
      }
      // if we select diffrent currency.
      if (this.selectedBaseCurr) {
        if (userBaseCurr !== this.selectedBaseCurr.currencyId) {
          this.exchangeAmt = this.total * this.selectedBaseCurr.conversionRate;
        }
      }
      // for null conversion rate -- for temporary
      if (this.selectedBaseCurr.conversionRate == null) {
        this.exchangeAmt = this.total;
      }
    }
    if (this.exchangeAmt > this.policyList.receiptRequiredLimit) {
      this.receiptWarning = true;
    } else {
      this.receiptWarning = false;
    }
    // this.itemWiseCatRecept();
    if (this.exchangeAmt > this.policyList.expenseAmountLimit) {
      this.expenseWarning = true;
    } else {
      this.expenseWarning = false;
    }
  }

  selectCurrencyOption(e,i?) {
    this.selectedBaseCurr = e;
    this.exchangeAmt = 0;
    if (e != null) {
      this.multiAdvanceModel.multipleAdvanceItem[i].originalCurrencyCode = e.currencyCode;
      this.multiAdvanceModel.multipleAdvanceItem[i].originalCurrencyId = e.currencyId;
      this.expcurrencySymbol = e.currencyCode;
      this.multiAdvanceModel.multipleAdvanceItem[i].expConvRate = e.conversionRate ? e.conversionRate : 1;
      this.multiAdvanceModel.multipleAdvanceItem[i].editedExchangeRate = null;
      this.multiAdvanceModel.multipleAdvanceItem[i].exchangeRate = e.conversionRate ? e.conversionRate : 1;
      this.multiAdvanceModel.multipleAdvanceItem[i].originalCurrencyIcon = e.currencyIcon;
      this.multiAdvanceModel.multipleAdvanceItem[i].showCurrencyList = false;
    }

  }

  getIndex() {
    this.slides.getActiveIndex().then((index) => {
      this.activeIndex = index;
    });
  }

  openPreview() {
    this.modalController
      .create({
        component: ImageViewComponent,
        componentProps: { photo: this.caputredImageModal.capturedImages[this.activeIndex] },
      })
      .then((modal) => modal.present());
  }

  deletePhoto() {
    this.caputredImageModal.capturedImages.splice(this.activeIndex, 1);
  }

  addTripform() {
    this.addAdvance.controls['tripName']?.setValidators(Validators.required);
    this.addAdvance.controls['tripId']?.setValidators(Validators.required);
    this.addAdvance.controls['tripName']?.updateValueAndValidity();
    this.addAdvance.controls['tripId']?.updateValueAndValidity();
    this.createAdvanceModel.isOnTrip = true;
  }

  NoTripform() {

    if (!this.editAdvance) {
      // this.addAdvance.controls['tripName'].setValidators(null);
      // this.addAdvance.controls['tripId'].setValidators(null);
      // this.addAdvance.controls['tripName'].updateValueAndValidity();
      // this.addAdvance.controls['tripId'].updateValueAndValidity();
      // this.addAdvance.controls.tripName.patchValue('');
      // this.addAdvance.controls.tripId.patchValue('');
    }
    this.createAdvanceModel.isOnTrip = false;

  }

  resetFormControlAndBlur(form: FormGroup, formControlName: string, eleInput: IonInput) {
    form.controls[formControlName].reset();
    eleInput.getInputElement().then((ele) => ele.blur());
    if (formControlName === 'categoryName' && form.contains(formControlName)) {
      this.addAdvance.controls.merchantName.patchValue(null);
    }
  }

  advFromDate(e) {
    if (this.platformType == 1) {
      let fromDate = this.datechangeService.convertCalendarToAPI(e.target.value);
      this.createAdvanceModel.tripFromDate = fromDate;

      if (fromDate > this.createAdvanceModel.tripToDate) {
        this.createAdvanceModel.tripToDate = fromDate;
        this.addAdvance.controls.toDate.patchValue(
          this.convertDateToUtc(this.createAdvanceModel.tripToDate)
        );
      }
      this.minToDate = e.target.value;
    } else {
      this.createAdvanceModel.tripFromDate = moment(new Date(e.detail.value)).format(Constants.mobileDateFormat);
    }
  }
  advToDate(e) {
    if (this.platformType == 1) {
      let date = this.datechangeService.convertCalendarToAPI(e.target.value);
      this.createAdvanceModel.tripToDate = date;
    } else {
      this.createAdvanceModel.tripToDate = moment(new Date(e.detail.value)).format(Constants.mobileDateFormat);
    }
  }
  createAdvanceModel = new CreateAdvance();
  caputredImageModal = new CapturedImageModal();

  takePhoto() {
    const options: CameraOptions = {
      quality: 50,
      targetWidth: 400,
      targetHeight: 500,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.PNG,
      mediaType: this.camera.MediaType.PICTURE,
      sourceType: this.camera.PictureSourceType.CAMERA,
      correctOrientation: true,
    };
    this.camera.getPicture(options).then(
      (imageData) => {
        // this.capturedImage = (<any>window).Ionic.WebView.convertFileSrc(imgFileUri);
        let fileName = Math.random().toString(36).substr(2, 15) + ".png";
        this.capturedImage = "data:image/png;base64," + imageData;
        this.caputredImageModal.capturedImages.push(this.capturedImage);
        this.caputredImageModal.capturedImages.reverse();
        //this.uploadImage(this.capturedImage, fileName);
      },
      (err) => {
        // Handle error
      }
    );
  }

  async openModal(value: string, type: string, isExpenseForm?: boolean) {
    const componentProps = this.getComponentProps(type);
    if (value && value !== '') {
      componentProps.value = value;
    }
    const searchItemModal = await this.modalController.create({
      component: CustomSelectDropdownComponent,
      componentProps,
      keyboardClose: false,
    });
    searchItemModal.onDidDismiss()
      .then((callback) => {
        if (callback && callback.data) {
          if (typeof callback.data !== 'string') {
            this[componentProps.listName] = callback.data;
            callback.data.forEach((obj: any) => {
              if (obj.isSelected) {
                this.addAdvance.controls[type].setValue(obj[componentProps.itemTextField]);
                if (type === 'requestType') {
                  this.createAdvanceModel.expenseType = obj.categoryId;
                } else if (type === 'originalCurrencyCode') {
                  this.createAdvanceModel[componentProps.itemTextField] = obj[componentProps.itemTextField];
                  this.createAdvanceModel[componentProps.itemValueField] = obj[componentProps.itemValueField];
                  if (obj != null) {
                    this.createAdvanceModel.originalCurrencyCode = obj.currencyCode;
                    this.createAdvanceModel.originalCurrencyId = obj.currencyId;
                    this.expcurrencySymbol = obj.currencyCode;
                    this.expConvRate = obj.conversionRate != null ? obj.conversionRate : 0;
                    this.createAdvanceModel.originalCurrencyIcon = obj.currencyIcon;
                  }
                  //this.selectCurrencyOption(obj);
                }
              }
            });
          } else {
            this.addAdvance.controls[type].setValue(callback.data);
            this.createAdvanceModel[componentProps.itemTextField] = callback.data;
          }
        }
      });
    return await searchItemModal.present();
  }

  getComponentProps(type: string) {

    switch (type) {
      case 'requestType':
        return {
          title: this.translate.instant('advance.requesttype'),
          value: null,
          mainList: this.requestArray1,
          isMultiSelect: false,
          itemValueField: 'categoryId',
          itemTextField: 'categoryName',
          listName: `requestArray1`,
        };
      case 'originalCurrencyCode':
        return {
          title: this.translate.instant('common.currency'),
          value: null,
          mainList: this.currencyList,
          isMultiSelect: false,
          itemValueField: 'currencyId',
          itemTextField: 'currencyCode',
          listName: `currencyList`
        };
    }
  }

  createAdvance(action) {

    console.log('mode is :',this.multiAdvanceModel);

    this.submitted = true;
    if (!this.addAdvance.valid) {
      return;
    }
    if (this.expense) {
      this.expense.claimName = this.createAdvanceModel.requestType
    }

    if (this.userInfo?.isAttachmentMandatoryForAdvance) {
      
      const index = this.multiAdvanceModel.multipleAdvanceItem.findIndex(s => s.expenseAttachments?.length == 0);
      this.loginUserAttchementChecking = true;
      console.log('attachment checking :',index);
      if(index != -1){
        return;
      }
    } else {
      this.loginUserAttchementChecking = false;
    }

    this.multiAdvanceModel?.multipleAdvanceItem.forEach(element => {
      element.userId = this.expense?.userId ? this.expense?.userId:this.userInfo?.userId;
      element.userGUID = this.expense?.userGuId ? this.expense?.userGuId:this.userInfo.userGuId;
      element.legalEntityId = this.userInfo.legalEntityId;
      element.legalEntityGUID = this.userInfo.legalEntityGuId;
      element.originalAmount = Number(element.originalAmount)
      element.recordType = 2;
      element.action = action;
      element.categoryType = null;
      element.claimTaggedTripList=[];
    });

    // this.createAdvanceModel.userId = this.expense?.userId?this.expense?.userId:this.userInfo?.userId;
    // this.createAdvanceModel.userGUID = this.userInfo.userGuId;
    // this.createAdvanceModel.legalEntityId = this.userInfo.legalEntityId;
    // this.createAdvanceModel.legalEntityGUID = this.userInfo.legalEntityGuId;
    // this.createAdvanceModel.originalAmount = Number(this.addAdvance.value.originalAmount);
    // this.createAdvanceModel.reason = this.addAdvance.value.reason;
    // this.createAdvanceModel.description = this.addAdvance.value.description;
    // this.createAdvanceModel.recordType = 2;
    // this.createAdvanceModel.tripId = this.addAdvance.value.tripId;
    // this.createAdvanceModel.tripName = this.addAdvance.value.tripName;
    // this.createAdvanceModel.expenseAttachments = [];
    // this.expenseModel.expenseAttachments.forEach(element => {
    //   let request={
    //     "fileName":element.fileName,
    //     "url":element.url
    //   }
    //   this.createAdvanceModel.expenseAttachments.push(request)
    // });
    // this.createAdvanceModel.action = action;
    // this.createAdvanceModel.categoryType = null;
    //   if(this.createAdvanceModel.claimTaggedTripList?.length > 0) {
    //     this.selectedTripsdata=this.createAdvanceModel.claimTaggedTripList;
    //     this.tripTravellers=this.createAdvanceModel.tripTravellersList
    //   }  else {
    //     console.log(this.selectedTrips);
    this.selectedTripsdata = [];  
    this.selectedTrips?.forEach(element => {
      element.tripCost = parseFloat((element?.tripCost || 0).toString().replace(/,/g, ''));
      element.formattedTotalAmount = parseFloat((element?.formattedTotalAmount || 0).toString().replace(/,/g, ''));
      element.invoiceList?.forEach(childelement => {
        let requestobj={
          fileURL : childelement.invoiceUrl
        }
        this.tripServiceAttachments.push(requestobj);
      });
      let requestobject = {
        id: element.id,
        expenseId: element.expenseId,
        requestId: element.requestId,
        requestGUID: element.requestGUID,
        tripId: element.tripId,
        tripGUID: element.tripGUID,
        startDate: element.tripStartDate?element.tripStartDate:element.startDate,
        endDate: element.tripEndDate,
        fromLocation: element.tripName?element.tripName:element.fromLocation,
        toLocation: "",
        totalAmount: element.tripCost?element.tripCost:element.formattedTotalAmount,
        paymentMode: element.paymentMode,
        productId: element.productId,
        isAssociated: true,
        productName: element.productName,
        tripServiceAttachments:this.tripServiceAttachments
      };
      if(!element.id) {
        this.selectedTripsdata.push(requestobject)
      }else{
        this.selectedTripsdata.push(requestobject)
      }
    });

    this.multiAdvanceModel?.multipleAdvanceItem.forEach(element => {
      element.claimTaggedTripList = [...this.selectedTripsdata];
      
    });
    // this.createAdvanceModel.claimTaggedTripList = [...this.selectedTripsdata]
    console.log(this.multiAdvanceModel);
    

    if (action == 1) {
      this.saveBtnDisable = true;
    } else {
      this.submitBtnDisabled = true;
    }
    // console.log("multiAdvanceModel",this.multiAdvanceModel)
    this.multiAdvanceModel.multipleAdvanceItem.forEach(m => {
      m.claimName = this.advanceSummary?.controls?.advanceName?.value || null;
      m.advanceTypeId = this.advanceSummary?.controls?.advanceTypeId?.value || null;
      m.advanceTypeName = this.advanceSummary?.controls?.advanceTypeName?.value || null;
    })

    this.advanceNewModel.action = action;
    this.advanceNewModel.claimId = this.editAdvance?.claimId || null;
    this.advanceNewModel.claimName = this.advanceSummary?.controls?.advanceName?.value || null;
    this.advanceNewModel.claimGUID = this.editAdvance?.claimGUID || null;
    this.advanceNewModel.legalEntityId = this.userInfo?.legalEntityId;
    this.advanceNewModel.legalEntityGUID = this.userInfo?.legalEntityGuId;
    this.advanceNewModel.userId = this.navinfopath?.advance?.userId || this.userInfo?.userId;
    this.advanceNewModel.userGUID = this.navinfopath?.advance?.userGUID || this.userInfo?.userGuId;
    this.advanceNewModel.advanceTypeId = this.advanceSummary?.controls?.advanceTypeId?.value || null;
    this.advanceNewModel.advanceTypeName = this.advanceSummary?.controls?.advanceTypeName?.value || null;
    this.advanceNewModel.claimTaggedTripList = [...this.selectedTripsdata];

    if(this.hideTripDetails == 'true' && this.advanceNewModel?.claimTaggedTripList?.length <= 0) {
      this.dTempHeader = 'Add Trip';
      this.dTempBody = 'Please Add Consolidated Trip to Raise Travel Advance.';
      const config = {
          ignoreBackdropClick: false,
          class: "modal-md modal-dialog-centered",
          keyboard: false,
      };
      this.modalRefDynamic = this.modalService.show(this.dynamicTemp, config);
      this.submitBtnDisabled = false;
      this.saveBtnDisable = false;
      return;
    }

    this.advanceNewModel.advanceList = [];
    this.multiAdvanceModel.multipleAdvanceItem.forEach(m => {
      this.advanceNewModel.advanceList.push({
        "expenseId": m.expenseId || null,
        "expenseGUID": m.expenseGUID || null,
        "originalCurrencyId": m.originalCurrencyId,
        "originalAmount": m.originalAmount,
        "exchangeRate": m.exchangeRate || 1,
        "reason": m.reason,
        "editetedExchangeRate": m.editedExchangeRate || null,
        "expenseAttachments": m.expenseAttachments,
        "requestType": m.requestType
      })
    })
    console.log('advanceNewModel _____ ', this.advanceNewModel);

    this.expensesService.createAdvance(this.advanceNewModel).subscribe(res => {

      if (!res.isError && res.errorCode == 0) {
        var alert = this.translate.instant('alerts.createAdv');
        if (action == 2) {
          alert = alert + this.translate.instant('alerts.submitSuccess');
        } else {
          if (this.editAdvance) {
            alert = alert + this.translate.instant('alerts.updateSuccess');
          } else {
            alert = alert + this.translate.instant('alerts.createsuccess');
          }
        }
        this.toastr.success(alert, this.translate.instant('alerts.succes'));
        this.saveBtnDisable = false;
        this.submitBtnDisabled = false;
        this.deleteexpenseId=res.payload
        //this.deleteexpenseGuid = res.payload
        this.getExpenseSumary(res.payload);
        if (this.pageTo == 'dashboard') {
          this.router.navigateRoot(["/dashboard"]);
        } else {
          if (res.payload?.claimTaggedTripList && res.payload.claimTaggedTripList.length > 0) {
            sessionStorage.setItem('Triplist', 'true');
          } else {
            sessionStorage.setItem('Triplist', 'false');
          }
          if(this.navinfopath?.url == 'approvals' || (this.navinfopath?.url == 'advance' && this.navinfopath?.fromPage == 'approvePage')){
            this.router.navigateRoot(["/approvals"]);
          }else{
            this.router.navigateRoot(["/advance"]);
          }
          // if(this.createAdvanceModel.action ==2){
          //   this.router.navigateRoot(["/advance"]);
          // }
        }
      } else if (res.isError && res.errorCode == 2) {
        this.toastr.error(res.errorMessage);
        this.saveBtnDisable = false;
        this.submitBtnDisabled = false;

      }
    }, err => {
      this.toastr.error(JSON.stringify(err));
      this.saveBtnDisable = false;
      this.submitBtnDisabled = false;
    })

  }
  getExpenseModels = new GetExpenseModels();
  getExpenseSumary(list?) {
    // this.getExpenseModels.expenseId = list?.expenseId;
    // this.getExpenseModels.expenseGUID = list?.expenseGUID;
    // this.getExpenseModels.userId = this.userInfo.userId;
    // this.getExpenseModels.userGUID = this.userInfo.userGuId;
    // this.getExpenseModels.legalEntityId = this.userInfo.legalEntityId;
    // this.getExpenseModels.legalEntityGUID = this.userInfo.legalEntityGuId;
    if(this.editAdvance){
      this.showLoading = true;
    }

    let viewType: any;
    if(sessionStorage.getItem('fromApprovalsPage')) {
      viewType = sessionStorage.getItem('fromApprovalsPage') == 'true' ? this.userInfo?.roleId : 1
    }

    let requestModel = {
      'claimId':list?.claimId,
      'claimGUID':list?.claimGUID,
      'viewType': viewType
    }
    this.reportsService.getReportSummary(requestModel)
      .subscribe((res) => {
        if(this.editAdvance){
          this.showLoading = false;
        }
        if (!res.isError && res.errorCode === 0) {
          const listarray = res.payload;
          this.expense = new ExpenseListModel(res.payload); 

          this.isAdvanceEditableUser = res.payload.reportSummary?.isAdvanceEditableUser;
          this.isAdvanceEditableFinance = res.payload.reportSummary?.isAdvanceEditableFinance;

          console.log('isAdvanceEditableUser ____', this.isAdvanceEditableUser);
          console.log('isAdvanceEditableFinance ____', this.isAdvanceEditableFinance);
          

          if(res.payload?.reportSummary?.claimName) {
            this.advanceSummary?.controls?.advanceName.patchValue(res.payload?.reportSummary?.claimName || '');
          }
          if(res.payload?.reportSummary?.advanceTypeId) {
            this.advanceSummary?.controls?.advanceTypeId?.patchValue(parseInt(res.payload?.reportSummary?.advanceTypeId) || '');
            this.advanceSummary?.controls?.advanceTypeName?.patchValue(res.payload?.reportSummary?.advanceTypeName || '');
          }
          this.multiAdvanceModel.multipleAdvanceItem.splice(0,1);
          this.admins.removeAt(0);

          this.advanceSummary?.controls?.advanceName?.patchValue(res.payload?.reportSummary?.claimName)

          res.payload?.reportSummary?.expenseList.forEach(element => {
            if(element.status != 4 && element.status != 5) {
              let advanceItem = new advanceItemModel();
              advanceItem.description = element?.reason;
              advanceItem.fromDate = element?.createdDate;
              advanceItem.isTrip = element?.isTrip;
              advanceItem.originalAmount = element?.unformattedOriginalAmount;
              advanceItem.originalCurrencyCode = element?.originalCurrencyCode ? element?.originalCurrencyCode : this.userInfo.baseCurrency.currencyCode;
              advanceItem.originalCurrencyId = element?.originalCurrencyId ? element?.originalCurrencyId : this.userInfo.baseCurrency.countryId;
              advanceItem.originalCurrencyIcon = element?.originalCurrencyIcon ? element?.originalCurrencyIcon : this.userInfo.baseCurrency.currencyIcon;
              advanceItem.exchangeRate = element?.exchangeRate ? element?.exchangeRate : 1;
              advanceItem.editedExchangeRate = element?.editedExchangeRate ? element?.editedExchangeRate : 0;
              advanceItem.beforeExchangeRate = element?.editedExchangeRate ? element?.editedExchangeRate : 0;
              advanceItem.reason = element?.reason;
              advanceItem.toDate = element?.toDate;
              advanceItem.tripId = element?.tripId;
              advanceItem.tripName = element?.tripName;
              advanceItem.claimId = element?.claimId;
              advanceItem.claimCode = element?.claimCode;
              advanceItem.claimGUID = res.payload?.reportSummary?.claimGUID;
              advanceItem.expenseId = element?.expenseId;
              advanceItem.expenseGUID = element?.expenseGUID;
              advanceItem.expenseAttachments = element?.expenseAttachments || [];
              console.log('sdsdsd',advanceItem);
              this.addCompanyForm(advanceItem);
            }
          });
          // this.createAdvanceModel = this.expense;
          // this.createAdvanceModel.requestType = this.expense.claimName
          // this.createAdvanceModel.originalAmount = this.expense?.unformattedOriginalAmount;
          // this.createAdvanceModel.baseAmount = this.expense?.unformattedBaseAmount;
          // let originalAmount = this.expense?.originalCurrencyId;
          //  this.expenseModel.expenseAttachments = this.expense.expenseAttachments;
          // this.addAdvance.controls.requestType.patchValue(
          //   this.createAdvanceModel.claimName
          // );
          // this.selectedTrips = this.createAdvanceModel.claimTaggedTripList || [];
          // this.tripTravellers=this.createAdvanceModel.tripTravellersList ||[];
          // this.selectedTrips.forEach(element => {
          //   element.baseCurrencyIcon = this.expense?.baseCurrencyIcon
          // });
          // this.tripdetails=this.expense?.claimTaggedTripList||[];
          // this.tripdetails.forEach(element => {
          //   if(element?.tripServiceAttachments){
          //     element?.tripServiceAttachments.forEach(childelement => {
          //       if(childelement?.fileURL !=null){
          //         this.tripAttachment =true
          //       }else{
          //         this.tripAttachment =false
          //       }
          //     });
          //   }
          // });
          // let currencyDetails = _.find(this.currencyList, function (object) { return object.currencyId == originalAmount; });
          // this.expConvRate = currencyDetails?.conversionRate;
          // this.expcurrencySymbol = currencyDetails?.currencyCode;
          // if (this.createAdvanceModel.tripId) {
          //   this.addTripform();
          //   this.addAdvance.controls.isTrip.patchValue(true)
          // } else {
          //   this.NoTripform();
          //   this.addAdvance.controls.isTrip.patchValue(false)
          // }

          // this.addAdvance.controls.fromDate.patchValue(
          //   this.convertDateToUtc(this.createAdvanceModel.tripFromDate)
          // );

          // this.addAdvance.controls.toDate.patchValue(
          //   this.convertDateToUtc(this.createAdvanceModel.tripToDate)
          // );
        } else if (res.isError && res.errorCode === 2) {
          //  this.showSummaryLoading = false;
        }
      });
  }
  convertDateToUtc(date) {
    this.selectedDate = moment(moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD')).utc(true).toISOString(false);
    return this.selectedDate;
  }

  goingOnaTrip(event) {
    if (event.detail.value == true) {
      this.addTripform();
    } else {
      this.NoTripform();
    }
  }

  ngOnDestroy() {
    //this.sharedService.setAdvanceData(null);
    window.removeEventListener('scroll', this.scrollChangeCallback, true);
    this.resetSubscrptns();
  }

  ionViewWillLeave() {
    sessionStorage.removeItem('fromPettyCash');
    this.resetSubscrptns();
  }

  resetSubscrptns() {
    if (this.editAdvance && !this.isTakePictureClicked) {
      sessionStorage.setItem('toPathInfo', null);
    }
  }

  numberValidation(event: any, isTime?) {
    const pattern = /[0-9.,]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  openCurrencyDropDown(e,i) {
    e.preventDefault();
    e.stopPropagation();
    if(this.multiAdvanceModel.multipleAdvanceItem[i].showCurrencyList == true){
      this.multiAdvanceModel.multipleAdvanceItem[i].showCurrencyList = false;
    }else{
      this.multiAdvanceModel.multipleAdvanceItem[i].showCurrencyList = true;
    }
    // setTimeout(() => {
    //   this.currencyAutoCompleteReference.nativeElement.focus();
    // }, 0);
  }
  openAdvanceTypes(e) {
    e.preventDefault();
    e.stopPropagation();
    setTimeout(() => {
      document.getElementById("advanceType").focus()
    }, 0);
  }

  onCurrencyEventFocus() {
    setTimeout(() => {
      this.currencyList = this.currencytempList;
    }, 0);
  }

  showSubMenu(field?) {
    this.closeOptionsPopup = false;
    this.currentField = field;
    this.mouseover = false;
    if(field == 'advanceType') {
      this.showAdvanceTypes = true;
      setTimeout(() => {
        this.advanceTypefocus?.nativeElement?.focus();
      }, 100);
    }
  }

  onContentScrolled(e) {
    this.closeOptionsPopup = this.mouseover ? false : true;
    //this.showCurrencyList = false;
  }
  mainDivClick() {
    this.showCurrencyList = false;
  }

  handleDragEnter() {
    this.dragging = true;
  }

  handleDragLeave() {
    this.dragging = false;
  }

  handleDrop(e) {
    e.preventDefault();
    this.dragging = false;
    e.target.files = e.dataTransfer.files;
    this.handleInputChange(e);
  }

  handleImageLoad() {
    this.imageLoaded = true;
  }

  handleInputChange(event,index?) {
    if(index >= 0){
      this.globalIndex = index;
    }
    if (!event.target) {
      return;
    }
    if (!event.target.files) {
      return;
    }
    if (this.userInfo.maxAttachementCount < event.target.files.length) {
      this.toastr.error('Attchement count Should not greater than max count', 'Error');
      return;
    }
    if (this.userInfo.maxAttachementCount == this.expenseModel.expenseAttachments.length) {
      this.toastr.error('you have reached attchement limit', 'Error');
      return;
    }

    var file = event.target.files;
    // if (!this.totalBulkModel.isCSVFile) {
    //   var expensetion = file[0].name.split("?")[0].split("#")[0].split('.').pop();
    //   if (this.userInfo.attachmentFormat) {
    //     if (this.commaSeperatorArray?.indexOf(expensetion.toLowerCase()) > -1) {
    //     } else {
    //       this.toastr.error('invalid extension. Extension Should be ' + this.commaSeperatorArray.join(', ') + '', 'Error');
    //       this.buttonDisabled = false;
    //       return;
    //     }
    //   }
    // }
    //old code for uploading image
    // var file = event.target.files;
    // let filesCount = event.target.files.length;
     this.imagesCount = event.target.files.length;
    // for (let i = 0; i < filesCount; i++) {
    //   var reader = new FileReader();
    //   var imgfile = event.dataTransfer ? event.dataTransfer.files[i] : file[i];
    //   if (this.totalBulkModel.isCSVFile) {
    //     this.totalBulkModel.csvFileName = imgfile.name;
    //   }
    //   this.fileName = imgfile.name;
    //   reader.onload = this._handleReaderLoaded.bind(this);
    //   reader.readAsDataURL(imgfile);
    // }



    //new code

    const obj = {
      "legalEntityGUID": this.userInfo.legalEntityGuId,
      "legalEntityId": this.userInfo.legalEntityId,
      "fileName": file[0].name,
      "uploadFor": "EXPENSE-ATTACHMMENTS"
    }

    //  this.imgUpload = true;
    this.multiAdvanceModel.multipleAdvanceItem[this.globalIndex].imgUpload = true;
    this.expenseService.getUploadUrl(obj).subscribe((res: any) => {
      sessionStorage.setItem('s3uploadUrl', res.payload?.s3Url)

      this.expenseService.uploadFileInS3(file[0], res.payload?.preSignedS3URL).subscribe(response => {
        this._handleReaderLoadedS3(file[0].name, res.payload.s3Url, this.globalIndex);
        if (response['status'] == 200) {
          // this.imgUpload = false;
          this.multiAdvanceModel.multipleAdvanceItem[this.globalIndex].imgUpload = false;
          sessionStorage.removeItem('s3uploadUrl');
            let request = {
              fileName:file[0].name,
              url: res.payload.s3Url,
              content:res.payload.s3Url
            };
            this.multiAdvanceModel.multipleAdvanceItem[this.globalIndex].expenseAttachments.push(request);
        } else {
          sessionStorage.removeItem('s3uploadUrl');
          this.toastr.error('Unable to submit file');
          // this.imgUpload = false;
          this.multiAdvanceModel.multipleAdvanceItem[this.globalIndex].imgUpload = false;
        }
      }, err => {
        sessionStorage.removeItem('s3uploadUrl');
        this.toastr.error('Unable to submit file');
        // this.imgUpload = false;
        this.multiAdvanceModel.multipleAdvanceItem[this.globalIndex].imgUpload = false;

        // this.totalBulkModel.expenseRequestDTOList[index].loaderBoolean = false;
      })
    }, err => {
      // this.setLoaderToFalse(index);
      
      sessionStorage.removeItem('s3uploadUrl');
      this.toastr.error('Unable to submit file');
      // this.imgUpload = false;
      this.multiAdvanceModel.multipleAdvanceItem[this.globalIndex].imgUpload = false;
    })


  }

  _handleReaderLoadedS3(filename, s3AttachmentUrl, index) {
    // var reader = e.target;
    // this.imgContent = reader.result;
  
    let _request = {
      fileName: filename,
      s3AttachmentUrl: s3AttachmentUrl,
    };
    this.uploadedfiles.push(_request);  
    if (this.imagesCount == this.uploadedfiles.length) {
      this.uploadImage(s3AttachmentUrl, filename, index);
    }
  }

  _handleReaderLoaded(e) {
    var reader = e.target;
    this.imgContent = reader.result;
    let _request = {
      fileName:
        this.active == ""
          ? this.fileName
          : "image." +
          reader.result.substring(
            reader.result.indexOf("/") + 1,
            reader.result.indexOf(";base64")
          ),
      content: reader.result,
    };
    this.uploadedfiles.push(_request);
    if (this.imagesCount == this.uploadedfiles.length) {
      this.uploadImage(this.imgContent, this.fileName);
    }
  }
  // uploadImage(img, name,index?) {
  //   this.getOcrData(img, name,index);
  // }
  uploadImage(url, name, index?) {
    
    // if (this.totalBulkModel?.isCSVFile) {
    //   this.getBulkFormOCR();
    // } else {
      //this.getOcrData(url, name,index);
    //}
  }
  ocrReqModel = new ocrReqModel();
  ocrAttachment = new ocrAttachment();


  getOcrData(url, name,index?) {
    this.ocrReqModel.attachments = [];
    this.uploadedfiles.forEach((element) => {
      // let request = {
      //   fileName: element.fileName,
      //   base64Image: element.content,
      // };
      let request = {
        fileName: element.fileName,
        s3AttachmentUrl: element.s3AttachmentUrl,
        // base64Image: element.content,
      };
      this.ocrReqModel.attachments.push(request);
      if (this.expenseModel.expenseAttachments.length != 0) {
        this.ocrReqModel.extract = false;
      } else {
        this.ocrReqModel.extract = false;
      } this.ocrReqModel.uploadType = "expenses";
    });
    this.imgUpload = true;
    this.expensesService.ocrDataPulling(this.ocrReqModel).subscribe((res) => {
      if (!res.isError) {
        this.buttonDisabled = false;
        this.loginUserAttchementChecking = false;
        this.toastr.success("Attachments added successfully", "Success");
        this.imgUpload = false;
        this.uploadedfiles = [];
        res.payload.attachments.forEach((element) => {
          let request = {
            fileName: element.fileName,
            url: element.uploadedURL,
            content: element.base64,
            attachmentType: "1",
          };
          this.expenseModel.expenseAttachments.push(request);
          this.associatedAttchements.push(request);
        });
        if (res.payload?.extractedData) {
          if (this.active == "bottom") {
            let attachedFile =
              this.expenseModel.expenseAttachments[
              this.expenseModel.expenseAttachments.length - 1
              ];
            attachedFile.url = res.payload.attachments[0].uploadedURL;
            delete attachedFile.content;
            return;
          }
        }
      } else if (res.isError && res.errorCode == 2) {
        this._bulkProgressState = false;
        this.loginUserAttchementChecking = false;
        this.buttonDisabled = false;
        this.uploadedfiles = [];
        this.imgUpload = false;
        this.imgContent = "";
        this.toastr.error(
          res.errorMessage,
          this.translate.instant("alerts.error")
        );
      } else {
        this.loginUserAttchementChecking = false;
        this._bulkProgressState = false;
        this.buttonDisabled = false;
        this.uploadedfiles = [];
        this.imgUpload = false;
        this.uploadedfiles = [];
        this.imgContent = "";
      }
    }, err =>{
      this.uploadedfiles = [];
      this.imgUpload=false;
    }
    );
  }
  resetInputFile() {
    this.fileUploadInput.nativeElement.value = "";
  }
  onSlide(item) {
    this.imageIndex = parseInt(item.current.replace("slideId_", ""), 10);
    this.proofid = this.isAssociated
      ? this.associatedAttchements[
        parseInt(item.current.replace("slideId_", ""), 10)
      ].attachmentId
      : this.expenseModel.expenseAttachments[
        parseInt(item.current.replace("slideId_", ""), 10)
      ].attachmentId;
    this.imgNum = this.imageIndex + 1;
  }

  previewAttachments: any = [];
  advanceIndex: any;
  imagePrevioeCall(imagePreview: TemplateRef<any>, list,advanceIndex,index?, isPdf?, expense?) {
    this.previewAttachments = list?.expenseAttachments || [];
    this.advanceIndex = advanceIndex;
    this.attachmentIndex=index;
    //this.expenseModel.expenseAttachments = [];
    if (isPdf) {
      this.getPdfAsBase64(expense.url).then((baseRes) => {
        let requestM = new GetExpenseModels();
        requestM.expenseIds = [expense?.expenseId];
        requestM.legalEntityId = this.userInfo.legalEntityId;
        requestM.legalEntityGUID = this.userInfo.legalEntityGuId;
        expense.content = baseRes;
            
      })
    }else{
      // expense.content = baseRes;
    }
    const config = {
      ignoreBackdropClick: false,
      class: "modal-dialog-centered modal-lg baa",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(imagePreview, config);
  }
  async getPdfAsBase64(url: string, thumbnail?): Promise<string> {
    try {
      const response = await fetch(url);
      const buffer = await response.arrayBuffer();
  
      // Convert the response data to base64
      const base64 = btoa(
        new Uint8Array(buffer)
          .reduce((data, byte) => data + String.fromCharCode(byte), '')
      );    
      return base64;
    } catch (error) {
      throw error;
    }
  }
  closeSelection() {
    // setTimeout(() => {
    //   this.modalRef.close();
    // }, 100);
    // this.modalRef.close();
    this.modalRef.hide();
  }

  preBtn() {
    if (this.defaultIndex != 0) {
      this.defaultIndex = this.defaultIndex - 1;
    }
    if (this.attachmentIndex != 0) {
      this.attachmentIndex = this.attachmentIndex - 1;
    }
    if(this.returnExtension(this.expenseModel.expenseAttachments[this.attachmentIndex]?.fileName) == 'pdf') {
      this.getPdfAsBase64(this.expenseModel.expenseAttachments[this.attachmentIndex]?.url).then((base64) => {
        this.expenseModel.expenseAttachments[this.attachmentIndex].content = base64;
      })
      this.zoom = 1.0;
    }
  }

  nextBtn() {
    if (
      this.defaultIndex + 1 ==
      this.expenseModel.expenseAttachments.length
    ) {
    } else {
      this.defaultIndex = this.defaultIndex + 1;
      this.zoom = 1.0;
    }

  }
  ImgnextBtn() {
    if (
      this.defaultIndex + 1 ==
      this.expenseModel.expenseAttachments.length
    ) {
    } else {
      this.defaultIndex = this.defaultIndex + 1;
      this.zoom = 1.0;
    }
    if (this.attachmentIndex + 1 == this.expenseModel.expenseAttachments.length) {
    } else {
      this.attachmentIndex = this.attachmentIndex + 1;
    }
    if(this.returnExtension(this.expenseModel.expenseAttachments[this.attachmentIndex]?.fileName) == 'pdf') {
      this.getPdfAsBase64(this.expenseModel.expenseAttachments[this.attachmentIndex]?.url).then((base64) => {
        this.expenseModel.expenseAttachments[this.attachmentIndex].content = base64;
      })
      this.zoom = 1.0;
    }
    // if (
    //   this.defaultIndex + 1 ==
    //   (this.isAssociated
    //     ? this.associatedAttchements.length
    //     : this.expenseModel.expenseAttachments.length)
    // ) {
    // } else {
    //   this.defaultIndex = this.defaultIndex + 1;
    // }

  }
  base64dataRe(incomingD) {
    const linkSource = `data:application/pdf;base64,${incomingD}`;
    return linkSource;
  }
  returnExtension(filename) {
    let text = filename?.split("?")[0].split(".").pop();
    return text;

  }

  removeImage(deleteTemp?: TemplateRef<any>) {
    if (deleteTemp) {
      const config = {
        ignoreBackdropClick: false,
        class: "modal-md modal-dialog-centered",
        keyboard: false,
      };
      this.modalRef = this.modalService.show(deleteTemp, config);
    }
  }
  deleteAttchement() {
    if (this.imageIndex == undefined) {
      this.imageIndex = 0;
    }
    // this.expenseModel.expenseAttachments.splice(this.imageIndex, 1);
    if (this.isAssociated) {
      this.expenseModel.expenseAttachments.splice(
        this.expenseModel.expenseAttachments.length -
        this.associatedAttchements.length,
        1
      );
      this.associatedAttchements.splice(this.imageIndex, 1);
    } else {
      this.expenseModel.expenseAttachments.splice(this.imageIndex, 1);
    }
    this.imgContent = "";
    this.fileName = "";
    if (this.imageIndex != 0) {
      this.imgNum = this.imgNum - 1;
      this.imageIndex = this.imageIndex - 1;
    } else {
      this.imgNum = 1;
    }
    this.modalRef.hide();
  }
  resetClose() {
    this.modalRef.hide();
  }
  getBase64ImageSrc(photo) {

    // var pdfView: any;
    // if (photo.content) {
    //   pdfView = photo.content;
    // } else {
    //   pdfView = photo.base64;
    // }
    return 'data:image/png;base64,' + photo;
  }
  getExpenseAttachmentsCall() {

    this.getExpenseIds.expenseIds = [this.expense.expenseId];
    this.getExpenseIds.legalEntityId = this.userInfo.legalEntityId;
    this.getExpenseIds.legalEntityGUID = this.userInfo.legalEntityGuId;
    this.expensesService
      .expenseAttachViewer(this.getExpenseIds)
      .subscribe((res) => {
        if (!res.isError && res.errorCode === 0 && res.payload.attachementList) {
          this.expenseModel.expenseAttachments = res.payload.attachementList;
          this.expenseModel.expenseAttachments.forEach(att => {
            att.content = att.base64
          })
          // if (this.expenseModel.expenseAttachments) {
          //   let urlStr = this.expenseModel.expenseAttachments[0].fileName;
          //   if (urlStr.split("?")[0].split(".").pop() == 'pdf') {
          //     this.expenseModel.expenseAttachments[this.defaultIndex].base64 = `data:application/pdf;base64,${this.expenseModel.expenseAttachments[this.defaultIndex].base64}`;
          //   } else {

          //   }
          // }

        } else if (res.isError && res.errorCode === 2) {
        }
      });
  }
  nodatafound: boolean = false;
  getTripsList() {
    let modl = new FetchTripInfo();
    modl.legalEntityId = this.userInfo.legalEntityId;
    modl.legalEntityGUID = this.userInfo.legalEntityGuId;
    modl.companyId = this.userInfo.companyId;
    modl.companyGUID = this.userInfo.companyGuId;
    modl.searchText = this.tripsSearchtxt;
    modl.tripStartDate = "";
    modl.tripEndDate = "";
    modl.filterBy = this.tripfilterModal;
    modl.pageSize = 100;
    modl.pageNumber = 1;
    modl.filterBy.statusIds = modl.filterBy.statusIds[0] ? modl.filterBy.statusIds : [];
    this.reportsService.fetchTripDetails(modl).subscribe((res) => {
      if (!res.isError && res.errorCode == 0 && res?.payload?.tripList) {
        this.nodatafound = false;
        this.tripsList = this.tripsList?.concat(
          res.payload.tripList?.map((list) => new NewTripModel(list))
        );
        for (let i = 0; i < this.tripsList?.length; i++) {
          for (let j = 0; j < this.selectedTrips?.length; j++) {
            if (this.tripsList[i].tripId == this.selectedTrips[j].tripId) {
              this.tripsList[i].isDisable = true;
            }
          }
        }
      } else {
        this.nodatafound = true;
        this.toastr.error(res.errorMessage, "Error");
        this.tripsList = [];
      }
    });
  }

  AddMore(tripSelection: TemplateRef<any>) {
    this.selectedTripsRadio=false;
    this.tripsList = [];
   this.selectedTrips = [];
    this.getTripsList();
    const config = {
      ignoreBackdropClick: false,
      class: "modal-xl modal-dialog-centered",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(tripSelection, config);

  }
  closeExpenseSelection() {
    this.selectedTripsRadio =false;
    this.selectedTrips =[]
    this.modalRef.hide();
  }
  searchBtn() {
    this.tripsList = [];
    //this.selectedTrips = [];
    if(this.tripsSearchtxt.length >2){
      this.getTripsList();
    }
   
  }



  removeTrip(value, index, tripdeleteTemp?: TemplateRef<any>) {
    if (tripdeleteTemp) {
      const config = {
        ignoreBackdropClick: false,
        class: "modal-md modal-dialog-centered",
        keyboard: false,
      };
      this.modalRef = this.modalService.show(tripdeleteTemp, config);
    }
    this.value = value;
    this.index = index;
    // let objectIndex = this.selectedTrips.findIndex(
    //   (e) => e.tripId == value.tripId
    // );
    // if (objectIndex != -1) {
    //   this.selectedTrips.splice(objectIndex, 1); // Remove one element from array
    //  this.expenseArray.splice(index, 1);
    // }
  }
  tripdeleteAttchement() {
    this.selectedTripsdata = []
    this.selectedTrips = [];
    this.modalRef.hide();
  }
  deleteTrip(trip) {
    const obj = {
      "id": trip.id,
      "GUID": this.userInfo.legalEntityGuId,
      "claimId": trip.claimId,
      "claimGUID": trip.claimGUID,
      "tripId": trip.tripId,
      "tripGUID": trip.tripGUID
    }
    this.expenseService.deleteClaimTrip(obj).subscribe((res: any) => {
      if(!res.isError) {
        this.getExpenseSumary(this.editAdvance?this.editAdvance:this.deleteexpenseId);
        this.selectedTripsdata=[];
       // this.selectedTrips.splice(this.index, 1);
      }
    })
  }
  // selectSingleTrip(list, e) {
  //   list.isSelected = e.target.checked;
  //   if (list.isSelected) {
  //     let requestobject = {
  //       id: null,
  //       expenseId: null,
  //       requestId: list.requestId,
  //       requestGUID: list.requestGUID,
  //       tripId: list.tripId,
  //       tripGUID: list.tripGUID,
  //       startDate: list.tripStartDate,
  //       endDate: list.tripEndDate,
  //       fromLocation: list.tripName,
  //       toLocation: "",
  //       totalAmount: list.tripCost,
  //       paymentMode: list.paymentMode,
  //       productId: list.productId,
  //       isAssociated: true,
  //       productName: list.productName
  //     };
  //     this.selectedTrips.push(requestobject);
  //   } else {
  //     let index = _.indexOf(this.selectedTrips, list.tripId);
  //     this.selectedTrips.splice(index, 1);
  //   }
  // }
  selectSingleTrip(list) {
    this.selectedTripsRadio =true
    this.serviceList = [];
    this.tripsList.forEach(triplist => {
      triplist.isSelected = triplist === list;
    });
    let requestobject = {
      id: null,
      expenseId: null,
      requestId: list.requestId,
      requestGUID: list.requestGUID,
      tripId: list.tripId,
      tripGUID: list.tripGUID,
      startDate: list.tripStartDate,
      endDate: list.tripEndDate,
      fromLocation: list.tripName,
      toLocation: "",
      totalAmount: list.tripCost,
      paymentMode: list.paymentMode,
      productId: list.productId,
      isAssociated: true,
      productName: list.serviceName,
      invoiceList:list.invoiceList,
      currencyIcon:list.currencyIcon
    };
     this.serviceList.push(requestobject)
    // let requestobj = {
    //   tripId: list.tripId,
    //   tripGUID:list.tripGUID
    // }
    // this.reportsService.getTripServiceList(requestobj).subscribe((res) => {
    //   if (!res.isError && res.errorCode == 0 && res?.payload) {
    //     this.serviceList = res?.payload.serviceList
    //     this.serviceList.forEach(element => {
    //       let requestobject = {
    //         id: null,
    //         expenseId: null,
    //         requestId: element.requestId,
    //         requestGUID: element.requestGUID,
    //         tripId: element.tripId,
    //         tripGUID: element.tripGUID,
    //         startDate: element.tripStartDate,
    //         endDate: element.tripEndDate,
    //         fromLocation: element.tripName,
    //         toLocation: "",
    //         totalAmount: element.tripCost,
    //         paymentMode: element.paymentMode,
    //         productId: element.productId,
    //         isAssociated: true,
    //         productName: element.productName,
    //         invoiceList:element.invoiceList
    //       };
    //        this.selectedTrips.push(requestobject)
    //        console.log(this.selectedTrips)
    //      });
    //     console.log("selectedTrip", this.selectedTrips);
    //   } else {
    //     this.serviceList=[];
    //   }
    // });
 
  }
  
  hideColumns = [];
  showhideColumns(val: string) {
    let column = true;
    if (this.hideColumns.indexOf(val) > -1) {
      column = false;
    }
    return column;
  }

  trippdfSelectionCall(pdfSelection1: TemplateRef<any>, trip) {
    this.selectedTripInvoiceArray = [];
    this.pdfloader = true;
    this.getpdfTripDetails(trip);
       const config = {
      ignoreBackdropClick: false,
      class: "modal-dialog-centered modal-lg baa",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(pdfSelection1, config);
  }
  slideExpense(index) {
    if (index) {
      if (this.currentIndex < this.expenseArray.length - 1) {
        this.currentIndex = this.currentIndex + 1;
      } else {
        return;
      }
    } else {
      if (this.currentIndex > 0) {
        this.currentIndex = this.currentIndex - 1;
      } else {
        return;
      }
    }
    var categoryType = 1;
    if (this.expenseArray[this.currentIndex]?.categoryType) {
      categoryType = this.expenseArray[this.currentIndex]?.categoryType;
    }
    if (this.expenseArray[this.currentIndex]?.expenseType == 5) {

      this._expenseTypeVerification = 5;
    } else {
    }

    // this.imgNum = 1;
    this.expenseId = this.expenseArray[this.currentIndex].expenseId;
    this.expenseGUID = this.expenseArray[this.currentIndex].expenseGUID;
    if (!this.isAssociated) {
      this.expenseModel.originalAmount =
        this.expenseArray[this.currentIndex].tripCost;
    }
    this.expenseModel.description = this.expenseArray[this.currentIndex].tripName;
    // if ((this.singleTravelTrip || this.multiTravelTrip) && !this.isAssociated) {
    //   let e = this.categoryList.find((o) => o.categoryName === this.expenseArray[this.currentIndex].productName);
    //   if (e) {
    //     this.expenseForm.controls.categoryName.patchValue(e.categoryName);
    //     this.expenseModel.categoryId = e.categoryId;
    //   }
    //   this.merchantList = e.merchantList;
    // }
    this.expenseModel.expenseAttachments = [];
    this.getTripDetails(this.expenseArray[this.currentIndex]);
    if (!this.singleTravelTrip && !this.multiTravelTrip) {
      // this.getexpenseDetails();
    }
  }

  selectedTripsRadio = false;
  getpdfTripDetails(expenseDetails) {
    this.invoiceDetails = [];
    this.invoiceDetails = (expenseDetails.invoiceList?expenseDetails.invoiceList:expenseDetails.tripServiceAttachments) ||[];
    if (this.invoiceDetails.length > 0 &&( this.invoiceDetails[0]?.invoiceUrl || this.invoiceDetails[0]?.fileURL) && 
      (this.invoiceDetails[0]?.invoiceUrl !== 'null' ||this.invoiceDetails[0]?.fileURL !== 'null')) {
      const urlStr = this.invoiceDetails[0].invoiceUrl?this.invoiceDetails[0].invoiceUrl:this.invoiceDetails[0].fileURL;
      if (urlStr.split("?")[0].split(".").pop() == "pdf") {
        this.isPdf = true;
        this.getPdfAsBase64(urlStr).then((baseRes) => {
          const linkSource = `data:application/pdf;base64,${baseRes}`;
          this.pdfData = linkSource;
          this.invoiceDetails[0].content = baseRes;
          this.pdfloader = false;
        });
      } else {
        this.pdfloader = false;
        this.isPdf = false;
      }
    } else {
      // Handle the case when invoiceDetails is an empty array or URL is null or 'camw'
      this.pdfloader = false;
      this.isPdf = false;
    }
  }


  getTripDetails(expenseDetails) {
    // this.defaultBoolean = true;
    let getTripModl = new GetTripInfo();
    getTripModl.legalEntityId = this.userInfo.legalEntityId;
    getTripModl.legalEntityGUID = this.userInfo.legalEntityGuId;
    getTripModl.requestId = expenseDetails.requestId;
    getTripModl.requestGUID = expenseDetails.requestGUID;
    getTripModl.productId = expenseDetails.productId;
    getTripModl.tripId = expenseDetails.tripId;
    getTripModl.tripGUID = expenseDetails.tripGUID;
    this.reportsService.getTripDetails(getTripModl).subscribe((res) => {
      if (!res.isError && res.errorCode === 0 && res?.payload) {
        // if (expenseDetails.productId == "1") {
        //     res?.payload.flightDetails.flights.forEach((element) => {
        //         element.invoiceDetails?.forEach((innerelement) => {
        //             if (innerelement.base64Invoice != null && innerelement.invoiceUrl != null) {
        //                 let object_data = {
        //                     fileName: "file." + innerelement.invoiceUrl.split("?")[0].split(".").pop(),
        //                     content: innerelement.base64Invoice,
        //                     url: innerelement.invoiceUrl,
        //                     isDeleted: "",
        //                     remarks: expenseDetails.productName,
        //                     referenceId: expenseDetails.tripId,
        //                 };
        //                 // this.expenseModel.expenseAttachments.push(object_data);
        //                 this.selectedTripInvoiceArray.push(object_data);
        //             }

        //         });
        //     });
        // }
        if (expenseDetails.productId == "1") {
          let AssociateTripAttchements = [];
          res?.payload.flightDetails.flights.forEach((element) => {
            element.invoiceDetails.forEach((innerelement) => {
              if (innerelement.base64Invoice != null && innerelement.invoiceUrl != null) {
                let object_data = {
                  fileName: "file." + innerelement.invoiceUrl.split("?")[0].split(".").pop(),
                  content: innerelement.base64Invoice,
                  url: innerelement.invoiceUrl,
                  isDeleted: "",
                  remarks: expenseDetails.productName,
                  attachmentType: this.isAssociated ? 2 : 1,
                  referenceId: expenseDetails.tripId,
                };

                // this.createAdvanceModel.expenseAttachments.push(object_data);

                AssociateTripAttchements.push(object_data);
              }

            });
          });
          this.selectedTripInvoiceArray = AssociateTripAttchements;

          // if (!this.isAssociated) {
          //   this.expenseModel.invoiceNumber = res?.payload.flightDetails.flights[0].invoiceDetails[0].invoiceNumber;

          // }
        }
        this.pdfloader = false;
      }
    });
  }




  advanceclose() {
    this.router.navigateRoot(["/advance"]);
    this.modalRef.close();
  }
  removebtn(deleteConfirmation: TemplateRef<any>) {
    const config = {
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-md baa",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(deleteConfirmation, config);
    // if(this.expenseModel.expenseAttachments.length == 0) {
    //   this.expenseModel.expenseAttachments = [];
    // } else {
    //   this.expenseModel.expenseAttachments.splice(this.attachmentIndex, 1)
    // }
    // if(this.expenseModel.expenseAttachments.length >= 1) {
    //   --this.attachmentIndex;
    // } else {
    //   this.closeSelection();
    // }
  }
  resetCloseadv() {
    this.modalRef.hide();
  }
  deleteOption() {
    // if (this.expenseModel.expenseAttachments.length == 0) {
    //   this.expenseModel.expenseAttachments = [];
    // } else {
    //   this.expenseModel.expenseAttachments.splice(this.attachmentIndex, 1)
    // }
    // if (this.expenseModel.expenseAttachments.length >= 1) {
    //   --this.attachmentIndex;
    // } else {
    // }
    // this.expenseModel.expenseAttachments.splice(this.attachmentIndex, 1)
    //this.createAdvanceModel.expenseAttachments=this.expenseModel.expenseAttachments
    this.multiAdvanceModel.multipleAdvanceItem[this.advanceIndex].expenseAttachments.splice(this.attachmentIndex, 1);
    this.toastr.success("attchement removed successfully", "Success");
    this.closeAllModals();
    this.modalRef.hide();

  }
  closeAllModals() {
    for (let i = 1; i <= this.modalService.getModalsCount(); i++) {
      this.modalService.hide(i);
    }
    // if(openPreview) {
    // this.imagePrevioeCall(this.currentFormTemplate, this.attachmentIndex);
    // }
  }

  bulkformreset() {
    // this.addAdvance.reset();
    this.admins.controls[this.globalIndex].reset();
    let advanceItem = new advanceItemModel();
    advanceItem.description = '';
    advanceItem.fromDate = '';
    advanceItem.isTrip = '';
    advanceItem.originalAmount = '';
    advanceItem.originalCurrencyCode = this.userInfo.baseCurrency.currencyCode;
    advanceItem.originalCurrencyId = this.userInfo.baseCurrency.countryId;
    advanceItem.reason = '';
    advanceItem.toDate = '';
    advanceItem.tripId = '';
    advanceItem.tripName = '';
    advanceItem.expenseAttachments = [];    
    this.multiAdvanceModel.multipleAdvanceItem[this.globalIndex] = advanceItem;
    console.log('reset called',this.multiAdvanceModel.multipleAdvanceItem,this.admins);
    this.modalRef.hide();
  }
  removedataform(removeformTemp: TemplateRef<any>) {

    const config = {
      ignoreBackdropClick: false,
      class: "modal-md modal-dialog-centered",
      keyboard: false,
      //stringtype: type
    };
    this.modalRef = this.modalService.show(removeformTemp, config);
  }



deleteAdvanceform(deleteAdvanceTemp: TemplateRef<any>, item) {
  this.deleteAdvanceID = item?.expenseId;
    const config = {
      ignoreBackdropClick: false,
      class: "modal-md modal-dialog-centered",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(deleteAdvanceTemp, config);
  }

  advanceItemreset(){
    if(this.deleteAdvanceID != '' && this.deleteAdvanceID) {
      this.deleteExpense();
    } else {
      this.multiAdvanceModel.multipleAdvanceItem.splice(this.globalIndex, 1);
      this.admins.removeAt(this.globalIndex);
      this.resetClose();
    }
  }

  deleteExpense(id?) {
    const obj = {
      "expenseIds": [this.deleteAdvanceID ? this.deleteAdvanceID : ''],
      "userId": this.userInfo.userId,
      "userGUID": this.userInfo.legalEntityGuId,
      "legalEntityId": this.userInfo.legalEntityId,
      "legalEntityGUID": this.userInfo.legalEntityGuId
    }
    this.expensesService.deleteExpense(obj).subscribe((res: any) => {
      if(!res.isError) {
        console.log('deleted',res);
        this.multiAdvanceModel.multipleAdvanceItem.splice(this.globalIndex, 1);
        this.admins.removeAt(this.globalIndex);
        this.resetClose();
        this.toastr.success("advance removed successfully", "Success");
      }

    })
  }

  formatDate(date) {
    if (date) {
      return moment(date, 'DD-MM-YYYY').format('DD-MMM-YY');

    }
  }
//   toggleExpanded(index: number) {
//   this.tripsList.forEach((list, i) => {
//     if (i === index) {
//        list.isExpanded = !list.isExpanded;
//       let requestobj = {
//         tripId: list.tripId,
// 	      tripGUID:list.tripGUID
//       }
//       this.reportsService.getTripServiceList(requestobj).subscribe((res) => {
//         if (!res.isError && res.errorCode == 0 && res?.payload) {
//           this.serviceList = res?.payload.serviceList
//         } else {
//           this.nodatafound = true;
//         }
//       });
//     } else {
//       // Collapse other child tables when expanding a new one
//       list.isExpanded = false;
//     }
//   });
// }
  toggleExpanded(index: number, item: any) {
  this.selectedTripsRadio =true
   
    if (this.tripsList[index].isMultiService == true) {
      if (this.tripsList[index].isExpanded == true) {
        this.tripsList[index].isExpanded = false;
        this.tripsList[index].isSelected = false;
        this.selectedTrips = [];
        // this.claimTripCost = 0;
      } else {
        this.tripsList[index].isExpanded = true;
        this.tripsList[index].isSelected = true;
        this.selectedTrips = [];
        // this.claimTripCost = 0;
        this.selectedTrips.push(item);
        // this.claimTripCost = parseFloat(item?.tripCost?.toString());
      }
    } else {
      //this.selectedTrips = item;
      this.tripsList[index].isExpanded = false;
      this.tripsList[index].isSelected = true;
    }

    this.tripsList.forEach((element, indexof) => {
      if (indexof != index) {
        element.isExpanded = false;
        element.isSelected = false;
      }
    });

    if (item.serviceList.length == 0) {
      this.reportsService.getTripServiceList({ 
        tripId: item.tripId,
        tripGUID: item.tripGUID,
        legalEntityGuid: this.userInfo?.legalEntityGuId,
        legalEntityId: this.userInfo?.legalEntityId
      }).subscribe(res => {
        if (!res.isError && res.errorCode == 0 && res?.payload) {
          this.serviceList = res?.payload.serviceList;
          this.tripTravellers = res?.payload.tripTravellers;
        }else{
          this.serviceList =[];
          //this.toastr.error(res.errorMessage, "Error");
        }
      });
    } else{
    }

  }
continueBtn() {
  //this.continuoeDisable = true;
  // this.selectedTripsRadio =true
  this.selectedTrips =this.serviceList;
  this.createAdvanceModel.tripTravellers =this.tripTravellers;
  this.selectedTrips.forEach(element => {
    if(element?.invoiceList){
      element?.invoiceList.forEach(chileelement => {
        if(chileelement?.invoiceUrl){
          this.tripAttachment =true
  
        }else{
          this.tripAttachment =false
        }
        
      });
    }
   
  });
 // this.selectedTrips=this.serviceList
//  this.serviceList.forEach(element => {
//   let requestobject = {
//     id: null,
//     expenseId: null,
//     requestId: element.requestId,
//     requestGUID: element.requestGUID,
//     tripId: element.tripId,
//     tripGUID: element.tripGUID,
//     startDate: element.tripStartDate,
//     endDate: element.tripEndDate,
//    // fromLocation: element.tripName,
//     toLocation: "",
//     totalAmount: element.tripCost,
//     paymentMode: element.paymentMode,
//     productId: element.productId,
//     isAssociated: true,
//     productName: element.productName
//   };
//    this.selectedTrips.push(requestobject)
//    console.log(this.selectedTrips)
//  });
 
  this.modalRef.hide();
}

  onPanelClosed(event: Event): void {
  }
  closeTripSelection(){
    this.selectedTrip=[];
    this.modalRef.hide()
  }

  open_files = false;
  chooseImage() {
    this.open_files = true;
  }

  isTakePictureClicked = false;
  takeImage() {
    this.createAdvanceModel.claimTaggedTripList = this.selectedTrips;
    this.createAdvanceModel.tripTravellers = this.tripTravellers;
    if(this.createAdvanceModel?.expenseAttachments?.length < this.expenseModel.expenseAttachments.length) {
      this.createAdvanceModel.expenseAttachments = this.expenseModel.expenseAttachments;
    }
    let obj = {
      from: 'takePicture-Advance',
      advance: this.createAdvanceModel
    }
    this.isTakePictureClicked = true;
    sessionStorage.setItem('toPathInfo', JSON.stringify(obj));
    this.router.navigateRoot(['camera']);
  }

  gets3UploadUrl(filename, file, attachments, index?){
    const obj = {
      "legalEntityGUID": this.userInfo.legalEntityGuId,
      "legalEntityId": this.userInfo.legalEntityId,
      "fileName": filename,
      "uploadFor": "EXPENSE-ATTACHMMENTS"
    }

    this.imgUpload = true;
    this.expenseService.getUploadUrl(obj).subscribe((res: any) => {
      sessionStorage.setItem('s3uploadUrl', res.payload?.s3Url);
      this.expenseService.uploadFileInS3(file, res.payload?.preSignedS3URL).subscribe(response => {
        this._handleReaderLoadedS3(filename, res.payload.s3Url, index);
        if (response['status'] == 200) {
          this.imgUpload = false;
          sessionStorage.removeItem('s3uploadUrl');
            let request = {
              fileName: filename,
              url: res.payload.s3Url,
              content:res.payload.s3Url
            };
            
            this.expenseModel.expenseAttachments = attachments || [];
            this.expenseModel.expenseAttachments.push(request);
        } else {
          sessionStorage.removeItem('s3uploadUrl');
          this.toastr.error('Unable to submit file');
          this.imgUpload = false;
        }
      }, err => {
        sessionStorage.removeItem('s3uploadUrl');
        this.toastr.error('Unable to submit file');
        this.imgUpload = false;
      })
    }, err => {      
      sessionStorage.removeItem('s3uploadUrl');
      this.toastr.error('Unable to submit file');
      this.imgUpload = false;
    })
  }

  downloadedProofs = [];
  downloadingAttachment = false;
  downloadAttachment(id) {
    this.downloadingAttachment = true;
    let attachObj = {
      attachmentIds: [id]
    }
    this.expenseService.downloadAttachment(attachObj).subscribe(res => {
      if (!res.isError && res.errorCode === 0) {
        console.log(res);
        this.downloadedProofs = res.payload.downloadFileResponseDTO;
        this.downloadedProofs.forEach(element => {
          if (element) {
            window.location.href = element.url;
          }
        });
        this.downloadingAttachment = false;
        this.toastr.success(this.translate.instant('alerts.downloadsucces'), this.translate.instant('alerts.succes'));
      } else {
        this.downloadingAttachment = false;
        this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
      }
    }, err => {
      this.downloadingAttachment = false;
    });
  }

  editechangecurrencypopup(i, editechangecurrencypop?: TemplateRef<any>) {
    this.selectedIndex = i;
    const config = {
      ignoreBackdropClick: false,
      class: "modal-md modal-dialog-centered",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(editechangecurrencypop, config);
  }


  updatecurrencyexchange() {
    const editedExchangeRate = parseFloat(this.multiAdvanceModel.multipleAdvanceItem[this.selectedIndex].beforeExchangeRate.toString());

    if (!isNaN(editedExchangeRate)) {
      const formattedRate = editedExchangeRate.toFixed(2);
      const editexchange = parseFloat(formattedRate);
      this.multiAdvanceModel.multipleAdvanceItem[this.selectedIndex].editedExchangeRate = editexchange;
      this.modalRef.hide();
    } else {
      console.error("Invalid editedExchangeRate value.");
    }
  }

  claimTypes: any = [];
  claimTypesTemp: any = [];
  getClaimTypes() {
    const obj = {
      "action": "claim_types",
      "legalEntityGuid": this.userInfo.legalEntityGuId,
      "legalEntityId": this.userInfo.legalEntityId,
      "companyId": this.userInfo.companyId,
      "companyGUID": this.userInfo.companyGuId,
    }
    this.expenseService.getClaimTypes(obj).subscribe(res => {
      this.claimTypes = res.payload || [];
      this.claimTypesTemp = [...this.claimTypes];
    })
  }

  showAdvanceTypes = false;
  selectedAdvanceType(type) {
    // console.log(type);
    this.advanceSummary.controls.advanceTypeId.patchValue(type.id);
    this.advanceSummary.controls.advanceTypeName.patchValue(type.claimType);
    this.showAdvanceTypes = false;
    this.closeOptionsPopup = true;
    // console.log(this.advanceSummary.controls);
  }

  closeDynamicModal() {
    this.modalRefDynamic?.hide();
  }

  zoom = 1.0;
  zoomIn() {
    this.zoom += 0.25;
  }

  zoomOut() {
    if (this.zoom > 0.25) {
      this.zoom -= 0.25;
    }
  }
}

