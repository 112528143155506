import { MatDateFormats, NativeDateAdapter } from '@angular/material/core';

export class AppDateAdapter extends NativeDateAdapter {
    format(date: Date, displayFormat: Object): string {
        var months    = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
      if (displayFormat === 'input') {
        let day: string = ('0' + date.getDate().toString()).slice(-2);
        let month = months[date.getMonth()];
        let year = date.getFullYear();
        return `${day} ${month} ${year}`;
      }
      return date.toDateString();
    }
  }

  export const APP_DATE_FORMATS: MatDateFormats = {
    parse: {
      dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
    },
    display: {
      dateInput: 'input',
      monthYearLabel: { year: 'numeric', month: 'numeric' },
      dateA11yLabel: { year: 'numeric', month: 'short', day: 'numeric' },
      monthYearA11yLabel: { year: 'numeric', month: 'short' },
    }
  };



