import { Injectable } from '@angular/core';
import { Constants } from '../Constants';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private expStatus = new BehaviorSubject<any>('');
  constructor(public httpClient: HttpClient) { }
  
  setExpStatus(flag: any) {
    this.expStatus.next(flag);
  }

  getExpStatus(): Observable<any> {
    return this.expStatus.asObservable();
  }

  getAnalyticsData(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/analytics/get";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  getAnalyticsOverviewData(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/analytics/year-data";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getAdvanceData(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/analytics/advance-data";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getMilegeData(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/analytics/mileage-data";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getEmpwiseRprtData(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/analytics/employee-data";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getActiveLogsData(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/analytics/active-logs";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getApprovalData(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/analytics/distribution-vs-time";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  
  getLimtsUtilisationReport(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/analytics/limitUtilisation";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getExpenseClaimReport(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/analytics/expenseClaimSummary";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getCardClaimReport(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/analytics/cardClaimSummary";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  downloadReports(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/bulk-jobs/export/reports";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

}
