import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Merchant, MerchantList } from 'src/app/models/Settings/merchant.model';
import { PlatformService } from 'src/app/services/platformbase.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Category } from 'src/app/models/Settings/category.model';
import { SettingsService } from '../../../services/settings-services';
// import { IonicSelectableComponent } from "ionic-selectable";
import { CustomSelectDropdownComponent } from "../../custom-select-dropdown/custom-select-dropdown.component";
import { ModalController, IonInput, NavController } from "@ionic/angular";
import { CommonService } from 'src/app/services/common-services/common.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import * as _ from "lodash";

@Component({
    selector: 'app-add-master-merchants',
    templateUrl: './add-master-merchants.component.html',
    styleUrls: ['./add-master-merchants.component.scss'],
})
export class AddMasterMerchantsComponent implements OnInit {
    @ViewChild('category', { static: false }) categoryAutoCompleteReference: ElementRef;
    @ViewChild('category', { static: false }) autoCat: ElementRef;
    // @ViewChild("selectComponent") selectComponent: IonicSelectableComponent;
    @ViewChild('close') descriptionRe;
    @ViewChild("country", { static: false }) countryAutoCompleteReference: ElementRef;
    @ViewChild("state", { static: false }) stateAutoCompleteReference: ElementRef;
    @ViewChild("city", { static: false }) cityAutoCompleteReference: ElementRef;
    platformType: number;
    merchantForm: FormGroup;
    displayTitle: string = "Add New";
    merchantModel = new Merchant();
    showLoader: boolean = false;
    submitted: boolean = false;
    buttonDisabled: boolean = false;
    validationMsg: any;
    loaderList: any = [1, 2, 3];
    showCategoryList: boolean = false;
    showCategory: boolean = false;
    selectedCategory: any;
    categoryList: any;
    categoryData: any;
    categoryModel = new Category();
    selectedLegalEntity: any;
    userInfo: any;
    stateList: any;
    countryData: any;
    selectedCountry: any;
    countryList: any;
    stateData: any;
    selectedState: any;
    request = {};
    selectedcity: any;
    cityList: any;
    cityData: any;
    showCountryList: boolean = false;
    showStateList: boolean = false;
    showCityList: boolean = false;
    cityId: any;
    state_id: any;
    selectedStatusId: any;
    categorylists = [];
    removable: boolean = true;
    categoryNameArray: any;
    listNames: any;
    categoryTemList: any;
    closeOptionsPopup: boolean = false;
    mouseover: boolean = false;
    currentField: string;
    openOptions: boolean = false;
    private scrollChangeCallback: () => void;

    constructor(
        private settingService: SettingsService,
        private platformService: PlatformService,
        private router: NavController,
        private fb: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private toastr: ToastrService,
        private modalController: ModalController,
        private commonService: CommonService,
    ) {
        this.merchantForm = this.fb.group({
            merchantName: ['', Validators.required],
            categoryName: ['', Validators.required],
            categoryId: [''],
            description: [''],
            taxNumber: [''],
            taxPercentage: ['', [Validators.min(0), Validators.max(100)]],
            merchantCode: [''],
            location: [''],
            addressLine1: [''],
            addressLine2: [''],
            postalCode: [''],
            country: ["",],
            state: ["",],
            city: [''],
        });

    }

    ngOnInit() {
        this.getCountriesList();
        this.countryList = JSON.parse(sessionStorage.getItem("country"))
    }

    ngOnDestroy() {
        window.removeEventListener("scroll", this.scrollChangeCallback, true);
      }
    
      ngAfterViewInit() {
        this.scrollChangeCallback = () => this.onContentScrolled(event);
        window.addEventListener("scroll", this.scrollChangeCallback, true);
      }
    
      onContentScrolled(e) {
        this.closeOptionsPopup = this.mouseover ? false : true;
      }
    
      showSubMenu(field?) {
        this.closeOptionsPopup = false;
        this.currentField = field;
        this.mouseover = false;
      }

    getCountriesList() {
        this.commonService.getCountries(this.request).subscribe(data => {
            this.countryList = data.payload;
            sessionStorage.setItem('country', JSON.stringify(this.countryList));
        });
    }

    ionViewWillEnter() {
        this.platformType = this.platformService.getPlatformType();
        this.selectedLegalEntity = JSON.parse(sessionStorage.getItem('selectedLegalEntity'));
        this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        this.merchantModel.merchantId = this.activatedRoute.snapshot.params["id"];
        this.merchantModel.merchantGUID = this.activatedRoute.snapshot.params["guid"];
        this.selectedStatusId = this.activatedRoute.snapshot.params["statusId"];
        this.getCategoryList();
        if (this.merchantModel.merchantId) {
            this.displayTitle = "Update";
            this.getSingleMerchant();
        }
    }

    getSingleMerchant() {
        this.showLoader = true;
        this.settingService.getSingleMerchant(this.merchantModel).subscribe((data) => {
            if (!data.isError) {
                this.showLoader = false;
                this.merchantModel = new MerchantList(data.payload);
                this.merchantForm.controls.categoryName.patchValue(
                    this.merchantModel.categoryName
                );
                if (this.merchantModel.countryId) {
                    const statesBody = {
                        countryId: this.merchantModel.countryId
                    };
                    this.getStates(statesBody);
                }
                if (this.merchantModel.stateId) {
                    const cityBody = {
                        stateId: this.merchantModel.stateId
                    };
                    this.getCityList(cityBody);
                }
                this.patchingFormDetails();
            } else {
                this.toastr.error(data.errorMessage, "Error");
            }
        });
    }

    getCategoryList() {
        this.categoryModel.legalEntityGUID = this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId;
        this.categoryModel.legalEntityId = this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId;
        this.categoryModel.categoryType = 1;
        let navInfo = JSON.parse(sessionStorage.getItem("toPathInfo"));
        if (navInfo && navInfo.categoryType) {
            this.categoryModel.categoryType = navInfo.categoryType;
        }

        this.categoryModel.status = 1;
        this.settingService.categoryList(this.categoryModel).subscribe(data => {
            this.categoryList = data.payload.categoryList;
            this.categoryTemList = data.payload.categoryList
        });
    }

    openCategoryDropdown(e) {
        e.preventDefault();
        e.stopPropagation();
        this.showCategoryList = true;
        setTimeout(() => {
            this.categoryAutoCompleteReference.nativeElement.focus();
        }, 0);
    }

    onCategoryEventFocus() {
        setTimeout(() => {
            this.categoryList = this.categoryTemList;
        }, 0);
    }

    onCategorySearch(val: any) {
        const searchText = val.currentTarget.value.trim();
        const filterdata = this.categoryList.filter(data => {
            return data.categoryName.toLowerCase().indexOf(searchText.toLowerCase()) >= 0;
        });
        this.categoryData = filterdata;
    }
    selectEventCategory(e) {
        this.selectedCategory = e;
        this.merchantForm.patchValue({
            categoryName: this.selectedCategory.categoryName,
        });
        this.merchantModel.categoryId = this.selectedCategory.categoryId;
        this.showCategoryList = false;
    }

    selectCategoryOption(e) {
        if (e != null) {
            this.merchantModel.categoryId = e.categoryId;
            this.merchantModel.categoryName = e.categoryName;
        }
    }

    // clearData() {
    //     this.merchantForm.controls.categoryName.patchValue("");
    //     this.merchantForm.controls.categoryId.patchValue("");
    //     this.merchantModel.categoryId = '';
    //     this.merchantModel.categoryName = '';
    //     this.selectComponent.close();
    // }

    apply() {
        // this.selectComponent.confirm();
        // this.selectComponent.close();
    }

    onClickedOutsideCategory() {
        this.showCategoryList = false;

    }
    category = [];
    addorUpdateMerchant() {
        this.merchantModel.legalEntityGUID = this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId;
        this.merchantModel.legalEntityId = this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId;
        this.merchantModel.status = this.selectedStatusId;
        let navInfo = JSON.parse(sessionStorage.getItem("toPathInfo"));

        if (navInfo && navInfo.categoryType) {
            this.merchantModel.categoryType = navInfo.categoryType;
        }
        this.submitted = true;
        if (!this.merchantForm.valid) {
            return;
        } else {
            this.buttonDisabled = true;
            this.settingService.saveMerchant(this.merchantModel).subscribe((data) => {
                if (!data.isError) {
                    this.buttonDisabled = false;
                    this.toastr.success(data.payload, 'Success');
                    this.backToMerchant();
                } else {
                    this.buttonDisabled = false;
                    this.toastr.error(data.errorMessage, 'Error');
                    this.backToMerchant();
                }
            });
        }
    }

    get f() {
        return this.merchantForm.controls;
    }

    backToMerchant() {
        this.router.navigateRoot(['/settings/merchant']);
    }

    async openModal(value: string) {
        const componentProps = {
            title: 'Category',
            value: null,
            mainList: this.categoryList,
            isMultiSelect: false,
            itemValueField: 'categoryId',
            itemTextField: 'categoryName',
            listName: `categoryList`,
        };
        if (value && value !== '') {
            componentProps.value = value;
        }
        const searchItemModal = await this.modalController.create({
            component: CustomSelectDropdownComponent,
            componentProps,
            keyboardClose: false,
        });
        searchItemModal.onDidDismiss()
            .then((callback) => {
                if (callback && callback.data) {
                    if (typeof callback.data !== 'string') {
                        this[componentProps.listName] = callback.data;
                        callback.data.forEach((obj: any) => {
                            if (obj.isSelected) {
                                this.merchantForm.controls.categoryName.setValue(obj[componentProps.itemTextField]);
                                this.merchantModel[componentProps.itemTextField] = obj[componentProps.itemTextField];
                                this.merchantModel[componentProps.itemValueField] = obj[componentProps.itemValueField];
                            }
                        });
                    }
                }
            });
        return await searchItemModal.present();
    }

    resetFormControlAndBlur(form: FormGroup, formControlName: string, eleInput: IonInput) {
        form.controls[formControlName].reset();
        eleInput.getInputElement().then((ele) => ele.blur());
        this.merchantModel.categoryId = '';
        this.merchantModel.categoryName = '';
    }
    numberValidation(event: any, isTime?) {
        const pattern = /^[0-9!.@#$%^&*()]+$/;
        let inputChar = String.fromCharCode(event.charCode);

        if (!pattern.test(inputChar)) {
            // invalid character, prevent input
            event.preventDefault();
        }
    }

    //city,state,country list dropdown
    onClickedOutsideCountry() {
        this.showCountryList = false;
    }
    openCountryDropdown(e) {
        this.closeAll();
        e.preventDefault();
        e.stopPropagation();
        this.showCountryList = true;
        setTimeout(() => {
            this.countryAutoCompleteReference.nativeElement.focus();
        }, 0);
    }
    closeAll() {
        this.showCountryList = false;
        this.showStateList = false;
        this.showCityList = false;
    }
    onCountryEventFocus() {
        setTimeout(() => {
            this.countryData = this.countryList;
        }, 0);
    }
    selectEventCountry(e) {
        this.selectedCountry = e;
        this.merchantForm.patchValue({
            country: this.selectedCountry.countryName,
            state: "",
            city: ""
        });
        this.merchantModel.countryId = this.selectedCountry.countryId;
        this.merchantModel.stateId = "";
        this.merchantModel.cityId = "";
        const statesBody = {
            countryId: this.selectedCountry.countryId
        };
        this.getStates(statesBody);
        this.showCountryList = false;
    }
    onCountrySearch(val: any) {
        let searchText = val.currentTarget.value.trim();
        let filterCountryData = this.countryList.filter(countryData => {
            return countryData.countryName.toLowerCase().indexOf(searchText.toLowerCase()) >= 0
        });
        this.countryData = filterCountryData;
    }
    getStates(statesBody) {
        this.commonService.getStates(statesBody).subscribe(data => {
            this.stateList = data.payload;
        })
    }
    onClickedOutsideState() {
        this.showStateList = false;
    }
    openStateDropDown(e) {
        this.closeAll();
        e.preventDefault();
        e.stopPropagation();
        this.showStateList = true;
        setTimeout(() => {
            this.stateAutoCompleteReference.nativeElement.focus();
        }, 0);
    }
    onStateEventFocus() {
        setTimeout(() => {
            this.stateData = this.stateList;
        }, 0)
    }
    onStateSearch(val: any) {
        let searchText = val.currentTarget.value.trim();
        let filterStateData = this.stateList.filter(stateData => {
            return stateData.stateName.toLowerCase().indexOf(searchText.toLowerCase()) >= 0
        });
        this.stateData = filterStateData;
    }

    selectEventState(e) {
        this.selectedState = e;
        this.merchantForm.patchValue({
            state: this.selectedState.stateName,
            city: ""
        });
        const cityBody = {
            stateId: this.selectedState.stateId
        };
        this.merchantModel.stateId = this.selectedState.stateId;
        this.getCityList(cityBody);
        this.showStateList = false;
    }
    getCityList(cityBody) {
        this.state_id = cityBody.stateId
        let requestobj = {
            "state_id": this.state_id
        }
        this.commonService.getCities(requestobj).subscribe(data => {
            this.cityList = data.payload;
        });
    }
    onClickedOutsideCity() {
        this.showCityList = false;
    }
    openCityDropDown(e) {
        this.closeAll();
        e.preventDefault();
        e.stopPropagation();
        this.showCityList = true;
        setTimeout(() => {
            this.cityAutoCompleteReference.nativeElement.focus();
        }, 0);
    }
    onCityEventFocus() {
        setTimeout(() => {
            this.cityData = this.cityList;
        }, 0)
    }
    onCitySearch(val) {

        // this.cityData = [...this.cityList]; 
        let searchText = val.currentTarget.value.trim();
        // if (this.cityData != null) {
        if (searchText != "") {
            let filterdata = this.cityList.filter((data) => {
                if (
                    data.city_Name.toLowerCase().indexOf(searchText.toLowerCase()) >=
                    0
                ) {
                    return data;
                }
            });
            this.cityData = filterdata;

        } else {

        }
    }

    selectEventCity(e) {
        this.selectedcity = e;
        this.merchantForm.patchValue({
            city: this.selectedcity.city_Name,
        });
        this.merchantModel.cityId = this.selectedcity.city_ID;
        this.showCityList = false;
    }
    patchingFormDetails() {
        var myVal: any;
        myVal = [];
        this.merchantModel.categoryList.forEach((obj: any) => {
            if (obj) {
                myVal.push(obj.categoryName);
            }
        });
        this.listNames = myVal;
        let categories = []
        for (let i = 0; i < this.merchantModel.categoryList.length; i++) {
            for (let j = 0; j < this.listNames.length; j++) {
                if (this.merchantModel.categoryList[i].categoryName == this.listNames[j]) {
                    categories.push(this.listNames[j])
                    this.categorylists.push(this.listNames[j]);
                }
            }
        }
        this.merchantForm.patchValue({
            categoryName: this.listNames,
        });
    }
    // addCategory(event: MatChipInputEvent): void {
    //     this.showCategory = true;
    //     const input = event.input;
    //     const value = event.value;
    //     if ((value || '').trim()) {
    //         this.categorylists.push(value.trim());
    //     }
    //     if (input) {
    //         input.value = '';
    //     }
    // }
    addCategory(e): void {
        this.showCategory = true;
        const input = e.input;
        const value = e.value;
        if ((value || '').trim()) {
          this.categorylists.push(value.trim());
        }
        if (input) {
          input.value = '';
        }
        e.preventDefault();
        e.stopPropagation();
            this.showCategory = true;
            setTimeout(() => {
                this.autoCat.nativeElement.focus();
            }, 0);
      }
     

    remove(i: string): void {
        const index = this.categorylists.indexOf(i);
        if (index >= 0) {
            this.categorylists.splice(index, 1);
            this.merchantModel.categoryList.splice(index, 1)
            this.merchantForm.patchValue({
                categoryName: this.categorylists
            });
        }
    }
    getProductsNames() {
        return _.map(this.categorylists, 'categoryName').join(',');
    }
    selected(event: MatAutocompleteSelectedEvent): void {
        if (this.categorylists.indexOf(event.option.value.categoryName) == -1) {
            this.categorylists.push(event.option.value.categoryName);
            let categorydata = {
                categoryName: event.option.value.categoryName,
                categoryId: event.option.value.categoryId
            };
            this.merchantModel.categoryList.push(categorydata);
        }
       // this.serviceInput.nativeElement.blur();
        this.merchantForm.patchValue({
            categoryName: this.categorylists
        });
        this.showCategory = false;

    }
    onClickedCategory() {
        this.showCategory = false;
    }
    oncategorySearch(val) {
        let searchText = val.currentTarget.value.trim();
        if (searchText != "") {
            let filterdata = this.categoryTemList.filter((data) => {
                if (
                    data.categoryName.toLowerCase().indexOf(searchText.toLowerCase()) >=
                    0
                ) {
                    return data;
                }else{
                }
            });
            this.categoryList = filterdata;

        } else {
            this.categoryList = this.categoryTemList
        }
    }

}
