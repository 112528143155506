import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { RecordAdvancePayment, RecordReportPaymentModel } from 'src/app/models/Reports/reports.model';
import { ReportsService } from 'src/app/services/reports.service';

@Component({
  selector: 'app-record-payment',
  templateUrl: './record-payment.component.html',
  styleUrls: ['./record-payment.component.scss'],
})
export class RecordPaymentComponent implements OnInit {
  @Input() reportSummary;
  @Input() advanceAmt;
  @Input() avalAmt;
  @Input() advorReport;
  advanceForm:FormGroup;
  paymentModeList = [{id:1, name:"Cash"},{id:2, name:"Cheque"}]
  submitted = false;
  userInfo: any;
  AdvanceSubmitDis = false;
  reportSubmitDis = false;
  recordPaymentbtns: boolean;
  reportPymtForm:FormGroup;
  recordRptPymntModel = new RecordReportPaymentModel();
  expensesAmt:any = 0;
  totalReportAmt: any = 0;
  appliedAmt:any = 0;
  finanaceBtns: any;
  reportSubmit = false;


  constructor(private modalCtrl: ModalController, public formBuilder: FormBuilder,
    public reportsService: ReportsService, public toastr: ToastrService,private translate: TranslateService) { }

  ngOnInit() {
    console.log("record options selected***");
    this.validateAdvance();
    this.validateReport();
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.recordRptPymntModel.isLinkedToAdvancePayment = this.reportSummary.isLinkedToAdvancePayment;
    if(this.reportSummary.isLinkedToAdvancePayment ){
      this.appliedAmt =  this.reportSummary.advanceAmount;
    } 
    this.recordRptPymntModel.isLinkedToPettyCashPayment = this.reportSummary.isLinkedToPettyCashPayment;
    if(this.reportSummary.isLinkedToPettyCashPayment ){
      this.appliedAmt =  this.reportSummary.pettyCashAmount;
    } 
    this.expensesAmt = this.reportSummary.approvedAmount;
  }
  closeModal(data) {
    this.modalCtrl.dismiss(data ? data : false);
  }

  linkedAdvnce(linked:any,avalableAmt) {
    this.advanceAmt = avalableAmt;
    this.expensesAmt = this.reportSummary.approvedAmount;
  
    if(linked) {
      if(this.expensesAmt>0) {
        if((this.expensesAmt>this.advanceAmt) || (this.expensesAmt==this.advanceAmt) ) {
            this.totalReportAmt = this.expensesAmt - this.advanceAmt;
          this.appliedAmt = this.advanceAmt;
          this.advanceAmt = 0;
        } else if(this.expensesAmt<this.advanceAmt) {
          this.advanceAmt = this.advanceAmt - this.expensesAmt;
          this.totalReportAmt = 0;
          this.appliedAmt = this.expensesAmt;
        } 
      } 
    } else {
      this.totalReportAmt =this.reportSummary.approvedAmount;
      this.appliedAmt = 0;
    }
  }

  validateAdvance() {
    this.advanceForm = this.formBuilder.group({
      paymentMode: ["", Validators.required],
      comment: [""],
      reference: [""],
    });
   
  }

  get advf(){
    return this.advanceForm.controls;
  }

  
    validateReport() {
      this.reportPymtForm = this.formBuilder.group({
        paymentMode: ["", Validators.required],
        isLinkedToAdvancePayment:["", Validators.required],
        comment: [""],
        reference: [""],
      });
    
    }

  get reportf(){
      return this.reportPymtForm.controls;
    }

  recordAdvancePaymentModel = new RecordAdvancePayment();

  recordAdvForm(){
   
      this.submitted = true;
      if (!this.advanceForm.valid) {
        return;
      }
      this.recordAdvancePaymentModel.userId = this.userInfo.userId;
      this.recordAdvancePaymentModel.userGUID = this.userInfo.userGuId;
      this.recordAdvancePaymentModel.recordType = 2;
      this.recordAdvancePaymentModel.isAdminView = JSON.parse(sessionStorage.getItem('isadmin'));
      if((JSON.parse(sessionStorage.getItem('isadmin')) == true) && (this.userInfo.roleId == 2) ) {
        this.recordAdvancePaymentModel.viewType = this.userInfo.roleId;
      } else {
        this.recordAdvancePaymentModel.viewType = 1;
      }
      this.recordAdvancePaymentModel.reimbursedAmount = this.reportSummary.totalBaseAmount.replace(/,/g, "");
      this.recordAdvancePaymentModel.claimId = this.reportSummary.claimId;
      this.recordAdvancePaymentModel.claimGUID = this.reportSummary.claimGUID;
      this.AdvanceSubmitDis = true;
      this.reportsService.recordAdvanceAPI(this.recordAdvancePaymentModel).subscribe(res=>{
          if (!res.isError && res.errorCode == 0) {
              this.recordPaymentbtns =false;
              this.AdvanceSubmitDis = false;
              this.modalCtrl.dismiss();
              this.advanceForm.reset();
              this.toastr.success(res.payload, this.translate.instant('alerts.succes'));
              this.modalCtrl.dismiss();
            
          } else if (res.isError && res.errorCode == 2) {
              this.recordPaymentbtns =false;
              this.AdvanceSubmitDis = false;
              this.toastr.error(res.errorMessage,this.translate.instant('alerts.error'));
              this.modalCtrl.dismiss();
          }
      })
  }
  selectAdvanceModeOption(ev){
      this.advanceForm.controls.paymentMode.patchValue(ev.name)
  }

  recordReportForm(){
    this.reportSubmit = true;
    if (!this.reportPymtForm.valid) {
      return;
    }
    this.recordRptPymntModel.userId = this.userInfo.userId;
    this.recordRptPymntModel.userGUID = this.userInfo.userGuId;
    this.recordRptPymntModel.recordType = 1;
    this.recordRptPymntModel.paymentMode = "Cash"
    this.recordRptPymntModel.isAdminView = JSON.parse(sessionStorage.getItem('isadmin'));
    if((JSON.parse(sessionStorage.getItem('isadmin')) == true) && (this.userInfo.roleId == 2) ) {
      this.recordRptPymntModel.viewType = this.userInfo.roleId;
    } else {
      this.recordRptPymntModel.viewType = 1;
    }
    this.recordRptPymntModel.claimId = this.reportSummary.claimId;
    this.recordRptPymntModel.claimGUID = this.reportSummary.claimGUID;
    this.recordRptPymntModel.advanceApplied = this.appliedAmt;

    if(this.recordRptPymntModel.isLinkedToAdvancePayment){
        this.recordRptPymntModel.reimbursedAmount = this.totalReportAmt;
    }else{
        this.recordRptPymntModel.reimbursedAmount = this.expensesAmt;
    }  
    this.reportSubmitDis = true;
    this.reportsService.recordAdvanceAPI(this.recordRptPymntModel).subscribe(res=>{
        if (!res.isError && res.errorCode == 0) {
            this.recordPaymentbtns =false;
            this.reportSubmitDis = false;
            new RecordAdvancePayment();
            this.toastr.success(res.payload, this.translate.instant('alerts.succes'));
            this.modalCtrl.dismiss();
          
        } else if (res.isError && res.errorCode == 2) {
            this.reportSubmitDis = false;
            this.recordPaymentbtns =false;
            this.toastr.error(res.errorMessage,this.translate.instant('alerts.error'));
            this.modalCtrl.dismiss();
        }
    })
}


}
