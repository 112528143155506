import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IonInput, ModalController, NavController } from '@ionic/angular';
// import { IonicSelectableComponent } from 'ionic-selectable';
import { ToastrService } from 'ngx-toastr';
import { Category, CategoryList } from 'src/app/models/Settings/category.model';
import { Merchant, MerchantList } from 'src/app/models/Settings/merchant.model';
import { tax, taxList } from 'src/app/models/Settings/tax.model';
import { PlatformService } from 'src/app/services/platformbase.service';
import { SettingsService } from 'src/app/services/settings-services';
import { CustomSelectDropdownComponent } from '../../custom-select-dropdown/custom-select-dropdown.component';

@Component({
  selector: 'app-add-tax',
  templateUrl: './add-tax.component.html',
  styleUrls: ['./add-tax.component.scss'],
})
export class AddTaxComponent implements OnInit {
  platformType: number;
  taxForm: FormGroup;
  displayTitle: string = "Add New";
  displayTitlle: string = "Add"
  taxModel = new tax();
  showLoader: boolean = false;
  submitted: boolean = false;
  buttonDisabled: boolean = false;
  validationMsg: any;
  loaderList: any = [1, 2, 3];
  selectedLegalEntity: any;
  selectedStatusId: any;

  constructor(
    private platformService: PlatformService,
    private router: NavController,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private settingsService: SettingsService,
    private toastr: ToastrService
  ) {
    this.taxForm = this.fb.group({
      taxName: ["", Validators.required],
      taxcode: [""],
    });
  }

  ngOnInit() { }

  ionViewWillEnter() {
    this.platformType = this.platformService.getPlatformType();
    this.selectedLegalEntity = JSON.parse(sessionStorage.getItem('selectedLegalEntity'));
    this.taxModel.taxId = this.activatedRoute.snapshot.params["id"];
    this.taxModel.taxGUID = this.activatedRoute.snapshot.params["guid"];
    this.selectedStatusId = this.activatedRoute.snapshot.params["statusId"];
    this.taxModel.legalEntityGUID = this.selectedLegalEntity.legalEntityGuId;
    this.taxModel.legalEntityId = this.selectedLegalEntity.legalEntityId;
    if (this.taxModel.taxId) {
      this.displayTitle = "Update";
      this.displayTitlle = "Update"
      this.getSingleCategory();
    }
  }

  getSingleCategory() {
    this.showLoader = true;
    this.settingsService.getSingletax(this.taxModel).subscribe((data) => {
      if (!data.isError) {
        this.showLoader = false;
        this.taxModel = new taxList(data.payload);
      } else {
        this.toastr.error(data.errorMessage, "Error");
      }
    });
  }

  addorUpdateTax() {
    let navInfo = JSON.parse(sessionStorage.getItem("toPathInfo"));
    this.taxModel.categoryType = 1;
    if (navInfo && navInfo.categoryType) {
      this.taxModel.categoryType = navInfo.categoryType;
    }
    this.taxModel.statusId = this.selectedStatusId;
    //this.taxModel.isDefault = false,
      this.submitted = true;
    if (!this.taxForm.valid) {
      return;
    } else {
      this.buttonDisabled = true;
      this.settingsService.saveTax(this.taxModel).subscribe((data) => {
        if (!data.isError) {
          this.buttonDisabled = false;
          this.toastr.success(data.payload, 'Success');
          this.backToTax();
        } else {
          this.buttonDisabled = false;
          this.toastr.error(data.errorMessage, 'Error');
          this.backToTax();
        }
      });
    }
  }

  backToTax() {
    this.router.navigateRoot(["/settings/tax"]);
  }

  get f() {
    return this.taxForm.controls;
  }

}
