import { Injectable } from '@angular/core';
import { Constants } from '../Constants';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public status = new BehaviorSubject<boolean>(false);
  public authenticate = this.status.asObservable();
  public isLocalConveyanceEnabled = new BehaviorSubject<boolean>(false);
  public isPettyCashEnabled = new BehaviorSubject<boolean>(false);
  public isTravelEnabled = new BehaviorSubject<boolean>(false);
  public showHeaderEV = new BehaviorSubject<any>(true);
  constructor(public httpClient: HttpClient) { }

  authenticateUser(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/authentication/validate-user";
    const body = requestBody;
    try {
      return this.httpClient.post(url, body);
    } catch (ex) {
    }
  }

  authenticateEvaUser(requestBody): Observable<any> {
    const url = Constants.EVA_URL + "authentication/v1/ext-sso-validate-user";
    const body = requestBody;
    try {
      return this.httpClient.post(url, body);
    } catch (ex) {
    }
  }

  getSSOurl(requestBody): Observable<any> {
    const url = Constants.EVA_SSO_URL;
    const body = requestBody;
    try {
      return this.httpClient.post(url, body);
    } catch (ex) {
    }
  }

  setisLocalConveyanceEnabled(data: boolean) {
    this.isLocalConveyanceEnabled.next(data);
  }
  getisLocalConveyanceEnabled() {
    return this.isLocalConveyanceEnabled.asObservable();
  }

  setisPettyCashEnabled(data: boolean) {
    this.isPettyCashEnabled.next(data);
  }
  getisPettyCashEnabled() {
    return this.isPettyCashEnabled.asObservable();
  }

  setisTravelEnabled(data: boolean) {
    this.isTravelEnabled.next(data);
  }
  getisTravelEnabled() {
    return this.isTravelEnabled.asObservable();
  }

  forgotEmail(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/authentication/forgot-password";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  resetEmail(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/authentication/reset-password";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  signupEmail(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/registration/subscribe";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  signupOTP(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/registration/validate-otp";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  postSignup(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/company/create";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  getUserConfiguration(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/general-policy/getUserConfiguration";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  //https://api.fastcollab.com/gs/v1/refresh-token/get
  refreshToken(requestbody): Observable<any> {
    const url = Constants.BASE_URL + 'v1/refresh-token/get'; //Dev/uat
  //  const url = 'https://api.fastcollab.com/gs/' + 'v1/refresh-token/get'; //Prod
    const body = requestbody;
    return this.httpClient.post(url, body);
  }

  public AuthenticateStatus(): Observable<any> {
    const AuthenticateStatus = JSON.parse(sessionStorage.getItem('isAuthenticated'));
    if (!AuthenticateStatus) {
      this.status.next(false);
    }
    else {
      this.status.next(true);
    }
    return this.status.asObservable();
  }

  setShowHeaderEV(data: boolean) {
    this.showHeaderEV.next(data);
  }
}
