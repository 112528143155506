export class OrganizationStructure {
    legalEntityId: any;
    legalEntityGuid: any;
    parentId: any;
    legaEntityName: any;
    companyId: any;
    companyGuid: any;
    baseCurrencyId: number;
    addressLine1: any;
    addressLine2: any;
    countryId: any;
    countryName: any;
    stateId: any;
    stateName: any;
    city: any;
    postalCode: any;
    createdBy: number;
    searchText: any;
    pageSize: number;
    pageNumber: number;
    sortColumn: any;
    sortType: any;
    legalEntityCode: any;
    logoPath: any;
    parentName: any;
    isPrimary: any;
    currencyName: any;
    currencyCode: any;
    subLegalEntityCount: any;
}
