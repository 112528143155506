import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NavController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common-services/common.service';
import { SettingsService } from 'src/app/services/settings-services';

@Component({
  selector: 'app-add-city-group',
  templateUrl: './add-city-group.component.html',
  styleUrls: ['./add-city-group.component.scss'],
})
export class AddCityGroupComponent implements OnInit, OnDestroy {
  cityGroupForm: FormGroup;
  userInfo: any;
  selectedLegalEntity: any;
  cityGroupId: any;
  cityGroupGUID: any;

  constructor(
    private fb: FormBuilder, private navCtrl: NavController,
    private settingService: SettingsService,
    private translate: TranslateService,
    public toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    public toastController: ToastController
  ) {
    this.cityGroupForm = this.fb.group({
      cityGroupName: [null, Validators.required]
    });
  }

  ngOnInit(): void {
    this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
    this.selectedLegalEntity = JSON.parse(sessionStorage.getItem('selectedLegalEntity'));
    this.cityGroupId = this.activatedRoute.snapshot.params["id"] || null;
    this.cityGroupGUID = this.activatedRoute.snapshot.params["guid"] || null;
    if (this.cityGroupId && this.cityGroupGUID) {
      this.getCityGroup();
    }
    this.getCountries();
    this.setupCitySearch();
  }

  generateList(IDs, Names) {
    // Split the strings into arrays
    const idsArray = IDs?.split(",")?.map(id => parseInt(id, 10)); // Convert IDs to numbers
    const namesArray = Names?.split(",");

    // Combine the arrays into the required list
    return idsArray.map((id, index) => {
      return {
        id: id,
        name: namesArray[index]
      };
    });
  }

  get f() {
    return this.cityGroupForm.controls;
  }

  getCityGroup() {
    let obj = {
      cityGroupId: this.cityGroupId,
      cityGroupGUID: this.cityGroupGUID,
      legalEntityId: this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId,
      legalEntityGUID: this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId
    }
    this.settingService.getCityGroup(obj).subscribe(res => {
      if (!res.isError && res.errorCode == 0) {
        this.cityGroupForm.setValue({
          cityGroupName: res?.payload?.cityGroupName
        })
        this.selectedCountries = this.generateList(res?.payload?.countryIds, res?.payload?.countryNames) || [];
        this.selectedCities = this.generateList(res?.payload?.cityIds, res?.payload?.cityNames) || [];
      } else if (res.isError == true) {
        this.toastr.error(res.errorMessage, this.translate.instant("alerts.error", { timeOut: 3000 }));
      }
    })
  }

  submitted = false;
  savingCityGroup = false;

  goBack() {
    this.navCtrl.back();
  }

  // Countries management
  countryList: any = [];
  selectedCountries: any[] = [];

  // Cities management
  citiesList: any = [];
  selectedCities: any[] = [];
  searchTerm: string = '';
  isLoading: boolean = false;

  // Form data
  cityGroupName: string = '';

  // Cleanup subject
  private destroy$ = new Subject<void>();
  private searchSubject = new Subject<string>();
  getCountries() {
    this.commonService.getCountries({}).subscribe(data => {
      this.countryList = data.payload;
      sessionStorage.setItem('country', JSON.stringify(this.countryList));
    });
  }

  getCities(searchedText, selectedCountryIds) {
    let obj = {
      countryIds: selectedCountryIds.join(','),
      searchText: searchedText
    };
    this.settingService.getCities(obj).subscribe((res: any) => {
      this.citiesList = res.payload || [];
    })
    return true;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setupCitySearch() {
    this.searchSubject.pipe(
      takeUntil(this.destroy$),
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(searchTerm => {
      if (this.selectedCountries.length > 0) {
        this.getCities(searchTerm, this.selectedCountries.map(country => country.id));
      }
    });
  }

  countryIDs = [];
  countryNames = [];
  onCountrySelect(event: any) {
    const index = this.selectedCountries?.findIndex(sc => sc.id == event.countryId);
    if (index >= 0) {
      this.showToast('Country name already added.');
      return;
    }
    this.selectedCountries.push({
      id: event.countryId,
      name: event.countryName
    });
  }

  onCitySelect(event: any) {
    const index = this.selectedCities?.findIndex(sc => sc.id == event.cityId);
    if (index >= 0) {
      this.showToast('City name already added.');
      return;
    }
    this.selectedCities.push({
      id: event.cityId,
      name: event.cityName
    });
  }

  removeCountry(i) {
    this.selectedCountries.splice(i, 1);
  }

  removeCity(i) {
    this.selectedCities.splice(i, 1);
  }

  onCitySearch(event) {
    if (event?.term?.length >= 2 && this.selectedCountries.length > 0) {
      this.searchSubject.next(event.term);
    }
  }

  searchCity() {
    if (this.citiesList?.length == 0 && this.selectedCountries?.length > 0) {
      this.searchSubject.next(null);
    }
  }

  async showToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
    });
    toast.present();
  }

  onSubmit() {
    const countryIds = this.selectedCountries.map(country => country?.id).join(",");
    const countryNames = this.selectedCountries.map(country => country?.name).join(",");
    const cityIds = this.selectedCities.map(city => city?.id).join(",");
    const cityNames = this.selectedCities.map(city => city?.name).join(",");

    this.submitted = true;
    this.savingCityGroup = true;
    if (this.cityGroupForm.invalid) {
      this.cityGroupForm.markAllAsTouched();
      return;
    }

    let obj = {
      cityGroupId: this.cityGroupId,
      cityGroupGUID: this.cityGroupGUID,
      legalEntityId: this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId,
      legalEntityGUID: this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId,
      cityGroupName: this.cityGroupForm.value.cityGroupName,
      countryIds: countryIds,
      countryNames: countryNames,
      cityIds: cityIds,
      cityNames: cityNames,
    }

    this.settingService.createCityGroup(obj).subscribe(res => {
      if (!res.isError && res.errorCode == 0) {
        this.savingCityGroup = false;
        this.toastr.success(res?.payload, this.translate.instant("alerts.succes"));
        this.goBack();
      } else if (res.isError == true) {
        this.savingCityGroup = false;
        this.toastr.error(res.errorMessage, this.translate.instant("alerts.error", { timeOut: 3000 }));
      }
    }, err => {
      this.savingCityGroup = false;
    })
  }
}
