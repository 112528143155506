import { Component, OnInit } from '@angular/core';
import { PlatformService } from 'src/app/services/platformbase.service';
import { ExchangeRate, ExchangeRateList } from 'src/app/models/Settings/exchange-rate.model';
import { SettingsService } from 'src/app/services/settings-services';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-exchange-rate',
  templateUrl: './exchange-rate.component.html',
  styleUrls: ['./exchange-rate.component.scss'],
})
export class ExchangeRateComponent implements OnInit {
  platformType: number;
  selectedLegalEntity: any;
  exchnageRate = new ExchangeRate();
  pageSize: number = 25;
  pageNumber: number = 1;
  exchangeListTotalLength: any;
  exchangeList: any = [];
  showLoader : boolean = true;
  loadersList = [1,2,3];
  isApicallinProgress: boolean = false;
  buttonDisabled: Array<boolean> = [];
  userInfo: any;
  baseCurrencyCode: any;
  showSearch: boolean = true;
  filterValue: any = '';

  constructor(private platformService: PlatformService, private settingService: SettingsService, private router: NavController, private toastr: ToastrService ) { }

  ngOnInit() {}

  ionViewWillEnter(){
    this.selectedLegalEntity = JSON.parse(sessionStorage.getItem("selectedLegalEntity"));
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.baseCurrencyCode = this.userInfo.baseCurrency.currencyCode;
    this.platformType = this.platformService.getPlatformType();
    this.getExchangeList();
  }

  loadMore(event){
    if(!this.isApicallinProgress){
      if( this.exchangeList.length < this.pageSize && event){
        event.target.disabled = true;
      }else{
        this.pageNumber++;
        this.getExchangeList(event);
      } 
    }
  }

  getExchangeList(event?){
    this.exchnageRate.legalEntityId = this.selectedLegalEntity.legalEntityId
    this.exchnageRate.legalEntityGuid = this.selectedLegalEntity.legalEntityGuId
    this.exchnageRate.pageSize= this.pageSize;
    this.exchnageRate.pageNumber = this.pageNumber;
    this.exchnageRate.sortType = "asc";
    this.exchnageRate.searchText = this.filterValue;
    this.settingService.getExchangeRateList(this.exchnageRate).subscribe((data)=>{
    this.exchangeListTotalLength = data.payload.noOfRecords;
    if (this.exchangeListTotalLength > 0) {
      let exchangeRateArr = data.payload.responseList;
      this.exchangeList = exchangeRateArr.map((list) => new ExchangeRateList(list));
      this.showLoader = false;
      if(event){
        event.target.complete();
      }
      if( this.exchangeList.length < this.pageSize && event){
        event.target.disabled = true;
      }
      this.isApicallinProgress = false;
    } else {
      this.showLoader = false;
      if(event) event.target.disabled = true;
    }
   })
  }

  searchbar(){
    this.showSearch = !this.showSearch
  }

  applyFilter(filterValue){
    this.filterValue = filterValue;
    this.pageNumber = 1;
    this.getExchangeList();
  }

  statusAction(data,ind){
    this.buttonDisabled[ind] = true;
    this.exchnageRate.currencyExchangeId = data.currencyExchangeId;
    this.exchnageRate.currencyExchangeGuid = data.currencyExchangeGuid;
     if (data.statusId == 1){
      this.settingService.changeExchangeStatusToInactive( JSON.stringify(this.exchnageRate)).subscribe((data)=>{
      if (!data.isError) {
        this.toastr.success(data.payload, 'Success');
        this.buttonDisabled[ind] =  false;
        this.getExchangeList();
      }else if (data.isError) {
        this.toastr.error(data.errorMessage, 'Error');
        this.buttonDisabled[ind] =  false;
        this.getExchangeList();
      }
    });
  } else if (data.statusId == 2){
    this.settingService.changeExchangeStatusToActive( JSON.stringify(this.exchnageRate)).subscribe((data)=>{
      if (!data.isError) {
        this.toastr.success(data.payload, 'Success');
        this.buttonDisabled[ind] =  false;
        this.getExchangeList();
      }else if (data.isError) {
        this.toastr.error(data.errorMessage, 'Error');
        this.buttonDisabled[ind] =  false;
        this.getExchangeList();
      }
     });
  }
  }

  addNewExchangeRate(){
    this.router.navigateRoot(['/settings/exchangeRate/add']);
  }

  navigateToAddExchange(data){
    this.router.navigateRoot(['/settings/exchangeRate/update', data.currencyExchangeId, data.currencyExchangeGuid]);
  }

}
