import { Component, OnInit, EventEmitter, Input, Output, NgZone} from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import * as _ from "lodash";
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ReportsService } from 'src/app/services/reports.service';
import { SharedService } from 'src/app/services/common-services/shared.service';

@Component({
  selector: 'app-custom-traveldetail',
  templateUrl: './custom-traveldetail.component.html',
  styleUrls: ['./custom-traveldetail.component.scss'],
})
export class CustomTraveldetailComponent implements OnInit {

  dummyList = [1,2,3,4,5,6,7];
  paxUITitles = ['Title*', 'First Name*', 'Last Name*', 'Primary Mobile Number*',
                  'Primary Email ID*','DOB'];
  @Input() detailReport:any;
  @Input() expSummary:any;
  @Input() isReports:any;
  @Output() backtoDetail: EventEmitter<number> = new EventEmitter<number>();
  showJourneyTabl = true;
  showPaxTabl = true;
  isHistoryOpen = true;
  fromEmail = false
  testNow = true;

  constructor(public sharedService: SharedService) { }

    ngOnInit() {
     // console.log("riririr",this.expSummary);
    }

    clickdBack() {
     
      this.backtoDetail.emit();
      //this.sharedService.setRefreshData(true);
    }
  }

