import { FilterModal } from "../Common/common.model";

export class DashboardModel {
    legalEntityId: number;
    legalEntityGUID: string;
    userId: number;
    userGUID: string;
    isAdminView:boolean;
    viewType:number;
    filterBy:FilterModal;
}

export class categoryList {
    category: string;
    currency: number;
    currencyIcon: string;
    amount: number;
    unformattedAmount:any;

    constructor(catlist: any) {
        if (catlist) {
            this.category = catlist.category;
            this.currency = catlist.currency;
            this.currencyIcon = catlist.currencyIcon;
            this.amount = catlist.amount;
            this.unformattedAmount = catlist.unformattedAmount;
        }
    }
}

export class GetDashboardData {
    totalAmountProcessed: number;
    totalAmountReimbursed: string;
    noOfApprovalRequest: number;
    noOfPendingRequest: number;
    categoryList: categoryList[];
    cardNo: string;
    availableBalance: number;
    totalExpenses: number;
    currencyCode: string;
    currencyIcon: string;
    submittedExpensesCount: number;
    draftExpenseCount: number;
    totalReportAmount: number;
    submittedReportCount: number;
    draftReportCount: number;
    isExpenseCreated: boolean;
    totalReportCount: number;
    approvalDraftCount: number;
    noOfApprovedRequest:number;
    approvalProcessedCount: number;
    approvalTotalCount: number;
    submittedAdvanceCount:number;
    draftAdvanceCount:number;
    draftLocalConveyanceCount: number;
    submittedLocalConveyanceCount: number;

    submittedPettyCashExpenseCount: number;
    submittedPettyCashRequestCount: number;
    draftPettyCashExpenseCount: number;
    draftPettyCashRequestCount: number;
    draftPettyCashReportCount: number;
    submittedPettyCashReportCount: number;
    totalApprovedPettyCashRequestAmount: number;
    totalPettyCashReportAmount: number;

    draftAmount:any;
    claimedAmount:any;
    pendingAmount:any;
    approvedAmount:any;
    rejectedAmount:any;
    pendingCount:number;

    constructor(list: any) {
        const catlist = list.categoryList;
        this.categoryList = catlist ? catlist.map(catlistItem => new categoryList(catlistItem)) : null;
        this.totalAmountProcessed = list.totalAmountProcessed;
        this.totalAmountReimbursed = list.totalAmountReimbursed;
        this.noOfApprovalRequest = list.noOfApprovalRequest;
        this.noOfPendingRequest = list.noOfPendingRequest;
        this.cardNo = list.cardNo;
        this.availableBalance = list.availableBalance;
        this.totalExpenses = list.totalExpenses;
        this.currencyCode = list.currencyCode;
        this.currencyIcon = list.currencyIcon;
        this.submittedExpensesCount = list.submittedExpensesCount;
        this.draftExpenseCount = list.draftExpenseCount;
        this.totalReportAmount = list.totalReportAmount;
        this.submittedReportCount = list.submittedReportCount;
        this.draftReportCount = list.draftReportCount;
        this.isExpenseCreated = list.isExpenseCreated;
        this.totalReportCount = list.totalReportCount;
        this.approvalTotalCount = list.approvalTotalCount;
        this.approvalDraftCount = list.approvalDraftCount;
        this.noOfApprovedRequest = list.noOfApprovedRequest;
        this.approvalProcessedCount = list.approvalProcessedCount;
        this.submittedAdvanceCount = list.submittedAdvanceCount;
        this.draftAdvanceCount = list.draftAdvanceCount;
        this.draftLocalConveyanceCount = list.draftLocalConveyanceCount;
        this.submittedLocalConveyanceCount = list.submittedLocalConveyanceCount;

        this.submittedPettyCashExpenseCount = list.submittedPettyCashExpenseCount;
        this.submittedPettyCashRequestCount = list.submittedPettyCashRequestCount;
        this.draftPettyCashExpenseCount = list.draftPettyCashExpenseCount;
        this.draftPettyCashRequestCount = list.draftPettyCashRequestCount;
        this.draftPettyCashReportCount = list.draftPettyCashReportCount;
        this.submittedPettyCashReportCount = list.submittedPettyCashReportCount;
        this.totalApprovedPettyCashRequestAmount = list.totalApprovedPettyCashRequestAmount;
        this.totalPettyCashReportAmount = list.totalPettyCashReportAmount;

        this.pendingCount = this.pendingCount;
    }
}
