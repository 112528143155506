import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {

  constructor(public platform: Platform) { }

  getPlatformType(): number {
    // if (this.platform.is('desktop')) {
    //   return 1;
    // } 
    if (this.platform.is('android') || this.platform.is('ios')) {
      return 1;
    } else {
      return 1;
    }
  }

  isAndroidIOS() {    
    if (this.platform.is('android') || this.platform.is('ios')) {
      return 2;
    } else {
      return 1;
    }
  }

  isAndroid()  {
    if (this.platform.is('android')) {
      return 1;
    } else {
      return 2;
    }
  }
  
  isMobileDevice() {    
    if (this.platform.is('android') || this.platform.is('ios')) {
      return true;
    } else {
      return false;
    }
  }
}
