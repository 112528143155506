import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from '../Constants';

@Injectable({
  providedIn: 'root'
})
export class TravelService {

  constructor(private httpClient: HttpClient) { }

  travelRequestList(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/expense/travel-list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  travelFormsList(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/recurrent/travel-request/forms/get";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  travelFormIdsList(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/travel/ids/list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getCitiesCall(requestbody): Observable<any> {
    const url = Constants.DOMAIN_URL + 'v1/airport-city/list';
    return this.httpClient.get(url, requestbody);
  }
  travelReportSummary(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/report/report-summary";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getCorporateUsers(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/corporate-users/list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  flightCreate(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/travel/flight/create";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  hotelCreate(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/travel/hotel/create";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getCabinClass(requestbody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/recurrent/flight/cabin-classes";
    return this.httpClient.post(url, requestbody);
  }
}
