import { Component, OnInit,ViewContainerRef,Renderer2,ViewChild,ElementRef } from '@angular/core';
import { ColorPickerService, Cmyk } from 'ngx-color-picker';
import { PlatformService } from 'src/app/services/platformbase.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { ThemesModel } from '../../../models/themes.model';
import { ToastrService } from 'ngx-toastr';
import { SettingsService } from '../../../services/settings-services'
import { ThemeService } from 'src/app/services/theme.service';
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'app-themes',
  templateUrl: './themes.component.html',
  styleUrls: ['./themes.component.scss'],
})
export class ThemesComponent implements OnInit {
  @ViewChild('font', {static: false}) fontAutoCompleteReference: ElementRef;

public colorList = [
    { key:"darkblue", value:"#003559", friendlyName:"darkblue"},
    { key: "silver", value: "#C0C0C0", friendlyName: "Silver" },
    { key: "orange", value: "#fa761e", friendlyName: "Orange" },
    { key: "infrared", value: "#ef486e", friendlyName: "Infrared" },
    { key: "blue", value: "#0770E8", friendlyName: "blue" },
    { key: "female", value: "#ff44aa", friendlyName: "Female Color" },
    { key: "paleyellow", value: "#ffd165", friendlyName: "Pale Yellow" },
    { key: "gargoylegas", value: "#fde84e", friendlyName: "Gargoyle Gas" },
    { key: "androidgreen", value: "#9ac53e", friendlyName: "Android Green" },
    { key: "carribeangreen", value: "#05d59e", friendlyName: "Carribean Green" },
    { key: "bluejeans", value: "#5bbfea", friendlyName: "Blue Jeans" },
		{ key: "cyancornflower", value: "#1089b1", friendlyName: "Cyan Cornflower" },
];

public fontList =[{name:"Arial",value:1},{name:"Helvetica",value:2},{name:'-apple-system, BlinkMacSystemFont, sans-serif',value:3},{name:'Lato Regular',value:4}, {name:"Mulish",value:5}]


  public presetValues : string[] = [];

  public selectedColor: string = 'color1';

  public cmykColor: Cmyk = new Cmyk(0, 0, 0, 0);
  platformType: number;
  themeForm: FormGroup;
  colorValue: any;
  secondColorValue: any;
  themes = new ThemesModel();
  userInfo: any;
  buttonDisabled: boolean = false;
  // showFontList: boolean;
  // selectedFont: any;
  showLoader: boolean = false;
  loaderList = [1,2,3,4];
  selectedLegalEntity: any;

  constructor(public vcRef: ViewContainerRef, private cpService: ColorPickerService,private settingService : SettingsService,
    private toastr: ToastrService,private title: Title,
    private platformService:PlatformService,private fb:FormBuilder,private renderer: Renderer2,private theme: ThemeService) {
    this.presetValues = this.getColorValues();
    this.renderer.addClass(document.body, "themes");
    
  }

  ngOnDestroy() {}

  ionViewWillLeave(){
    this.renderer.removeClass(document.body, "themes");
  }

 ngOnInit(){}

 ionViewWillEnter(){
  this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
  this.selectedLegalEntity = JSON.parse(sessionStorage.getItem('selectedLegalEntity'));
  this.platformType = this.platformService.getPlatformType();
  let applicationTitle = this.userInfo.applicationTitle;
    if(applicationTitle){
        this.title.setTitle('Theme | Settings ' + ' | ' + applicationTitle);
    }else{ 
      this.title.setTitle("Theme | Settings | NoExpenses")
    }
   this.themeForm = this.fb.group({
    fontType:[''],
   })
   this.getTheme();
 }

  getColorValues(){
  return this.colorList.map(c => c.value);
  }


  public onEventLog(event: string, data: any): void {
  }

  public onChangeColorCmyk(color: string): Cmyk {
    const hsva = this.cpService.stringToHsva(color);

    if (hsva) {
      const rgba = this.cpService.hsvaToRgba(hsva);

      return this.cpService.rgbaToCmyk(rgba);
    }

    return new Cmyk(0, 0, 0, 0);
  }

  public onChangeColorHex8(color: string): string {
    const hsva = this.cpService.stringToHsva(color, true);
    if (hsva) {
      return this.cpService.outputFormat(hsva, 'rgba', null);
    }

    return '';
  }
  onEventColor(val){
    this.themes.primaryColor= val.value;
  }
  onSecondaryEventColor(val){
    this.themes.secondaryColor = val.value;
  }

  updateThemes(){
    this.buttonDisabled = true;
    this.themes.legalEntityId = this.selectedLegalEntity.legalEntityId;
    this.themes.legalEntityGUID = this.selectedLegalEntity.legalEntityGuId;
    this.settingService.saveThemes(this.themes).subscribe((data)=>{
      if(!data.isError){
        this.buttonDisabled = false;
        this.toastr.success(data.payload, 'Success');
        if(this.userInfo.legalEntityId == this.selectedLegalEntity.legalEntityId) {
          let obj = {
            primary : this.themes.primaryColor,
            secondary : this.themes.secondaryColor,
            fontFamily : this.themes.font 
          }
          sessionStorage.setItem('theme',JSON.stringify(obj));
          this.theme.applyTheme(obj);
        }
      }else {
        this.buttonDisabled = false;
        this.toastr.error(data.errorMessage, 'Error');
      }

    })
  }
getTheme(){
    this.showLoader = true;
    this.themes.legalEntityId = this.selectedLegalEntity.legalEntityId;
    this.themes.legalEntityGUID = this.selectedLegalEntity.legalEntityGuId;
    this.settingService.getThemes(this.themes).subscribe((data)=>{
      if(data.isError == false){
      this.showLoader = false;
      this.themes.primaryColor = data.payload.primaryColor;
      this.themes.secondaryColor = data.payload.secondaryColor;
      this.themes.font = data.payload.font;

    }else if(data.isError == true){
      this.toastr.error(data.errorMessage, 'Error');

    }
      })
}

}
