import { Component, OnInit, ViewChild, TemplateRef, NgZone, ElementRef, ViewContainerRef, ViewChildren, QueryList } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NavController } from '@ionic/angular';
import { PlatformService } from 'src/app/services/platformbase.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SettingsService } from 'src/app/services/settings-services';
import {
    categryPolyModel,
    criteriaM,
    entityModels,
    mappedModel,
    mileagePolyModel,
    newPerDiemForm,
    perDiemExpenseItem,
    PolyCreateModel,
    polyEntityMapModel,
    polyInternlModel,
    polyPerDiemModel,
    polyRulesModel,
    PolyUpdateModel
} from 'src/app/models/Settings/policy.model';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DatechangeService } from 'src/app/services/common-services/datechange.service';
import { ToastrService } from "ngx-toastr";
import { CategoryAndMerchantModel, CategoryList, CurrencyList, UserModel, UserRcrdModel } from 'src/app/models/Common/common.model';
import { CommonService } from "src/app/services/common-services/common.service";
import { SharedService } from 'src/app/services/common-services/shared.service';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { CriteriModel, stateListModel } from 'src/app/models/Expenses/expenses.model';
import { ExpensesService } from 'src/app/services/expenses.service';
import * as _ from "lodash";
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
    selector: 'app-add-policy',
    templateUrl: './add-policy.component.html',
    styleUrls: ['./add-policy.component.scss'],
})
export class AddPolicyComponent implements OnInit {
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild('dateOfBirthUser', { read: undefined, static: false }) dateOfBirthUser: MatDatepicker<Date>;
    @ViewChild('perDiemStartDate', { read: undefined, static: false }) perDiemStartDate: MatDatepicker<Date>;
    @ViewChild('perDiemEndDate', { read: undefined, static: false }) perDiemEndDate: MatDatepicker<Date>;
    @ViewChild("_categoryList", { static: false }) categoryAutoCompleteReference: ElementRef;
    @ViewChild("innercategoryList", { static: false, read: ViewContainerRef }) innercategoryAutoCompleteReference: ElementRef;
    @ViewChild('close') descriptionRe;
    @ViewChild('close') descriptionRee;
    @ViewChild('categorySearchInput') categorySearchInput: ElementRef<HTMLInputElement>;
    @ViewChild('innercategorySearchInput') innercategorySearchInput: ElementRef<HTMLInputElement>;
    platformType: number;

    pageSize = 25;
    tableDataList: any;
    pageLimit: number[] = [5, 10, 25, 100];
    createView: boolean = true;
    designView: boolean = false;
    publishView: boolean = false;
    hideMileage = false;
    waitClose: boolean;

    expDataSource: MatTableDataSource<any>;
    dataSource: MatTableDataSource<any>;
    mileageSource: MatTableDataSource<any>;
    expCols: any[] = ['ExpLimt', 'RectLimt', 'isDescReq'];
    perDiemCols: any[] = ['LOCATION', 'DATE', 'RATE', 'RATE WITHOUT RECEIPT', 'STATUS', 'Disable'];
    selectedPerDiem = -1;

    rulesSource: MatTableDataSource<any>;
    rulesCols: any[] = ['Name', 'Category', 'Rule Type', 'Limit Type', 'Expense Count', 'Action Type', 'Disable'];
    rulesMCols: any[] = ['Name', 'Vehicle', 'Rule Type', 'Limit Type', 'Mileage Limit(KM)', 'Action Type', 'Disable'];
    yesNoList = [true, false];
    allEntitysLists = [];
    mappedList = new mappedModel();

    showLoader: boolean = false; //
    disableMode: boolean = false;
    currentPage = 0;//
    currentStage = 0;//
    loadersList: any = [1, 2, 3, 4];
    modalRef: any;
    currentMatTab = 0;  //
    isEditPolicy: any;//
    fromPathSubscr: any;
    minDate: Date;//
    maxExpire: Date;//
    userInfo: any;
    selectedLegalEntity: any;
    categoryList: any;//
    dummyCategoryList: any = [];
    categoryList_one: any
    dummyCategoryList_one: any;
    vehiclesList: any;//
    totalPolcyForm: FormGroup;
    totalPolcyModel = new PolyCreateModel();
    rulesModel: any; // = new polyRulesModel();
    rulesForm: FormGroup;
    PerDiemNewForm: FormGroup;
    addPerDiemForm: FormGroup;
    allUIInfo = new polyInternlModel();
    entityMapForm: FormGroup;
    legalEntityMapped = false;
    canEditLegalEntity = true;
    buttonDisabled = false;
    hasVal = true;
    locationId: any;
    updatePolicy: string = "save";

    private scrollChangeCallback: () => void;
    currentPosition: any;
    startPosition: number;
    typeStr: any;
    newPerDiemModel = new newPerDiemForm();
    baseCurrencyCode: any;
    isPerDiemEnabled: boolean = true;
    hideColumns = [];
    editPerdiemObj: any;
    seperateStr: any;
    searchCountyData = [];
    saveType: boolean = true;
    countryNameStr: boolean;
    cityStr: any;

    stateList: any;
    stateData: any;
    stateTempList: any;
    milageCategoryList: any;
    stateIds: any;
    countryId: any;
    selectedCoutry: any;
    CategoryMerchantModel = new CategoryAndMerchantModel();
    default_category = {
        categoryName: 'Default',
        categoryId: null,
        categoryGUID: '',
        categoryDescription: 'No Description',
        categoryType: 1,
        status: 1,
        isDefault: true,
        isSelected: false
    }

    workFlowWarning: boolean = false;
    mileageCountryList: any;
    showCategoryList: boolean = false;
    showInnerCategoryList: boolean = false;
    dummyEntitydata: any;
    dummyCostCenter: any;
    dummyDepartment: any;
    dummyBands: any;
    dummyTeams: any;
    dummyDesignations: any;
    dummyUsers: any;
    dummyValue: string = '';

    selectedBands = [];
    selectedCostcenter = [];
    selectedDepartments = [];
    selectedteams = [];
    selectedUsers = [];
    selectedDesignations = [];
    globalIndex = 0;
    filterSearch: any;
    child_cats = [];
    showCTList = false;

    currencyList = [];
    mouseover: any;
    showList: any;

    setInitialData1() {
        this.allUIInfo.showLoader = true;
        this.allUIInfo.loadersList = [1, 2, 3, 4];
        this.allUIInfo.currentPage = 0;
        this.allUIInfo.currentStage = 0;
        this.allUIInfo.currentMatTab = 0;
        this.allUIInfo.currentRuleIndex = -1;
        this.allUIInfo.showDotBtns = -1;
    }


    criteriForm: FormGroup;
    submitCriteria: boolean = false;
    conditions = [{ 'conditionType': '1', 'conditionName': '=' },
    { 'conditionType': '2', 'conditionName': '<=' },
    { 'conditionType': '3', 'conditionName': '<' },
    { 'conditionType': '4', 'conditionName': '>=' },
    { 'conditionType': '5', 'conditionName': '>' }];

    showClickable: boolean = false;
    initialFormArrayLength: number;

    withRcptCndts: any;
    withoutRcptCndts: any;

    fpList = [
        { id: 1, name: 'Fixed' },
        { id: 2, name: 'Percentage' }
    ]

    pdValues = [
        { id: 3, name: 'Next Day' },
        { id: 1, name: 'Same Day' },
        { id: 2, name: 'Per Hour' },
    ]

    @ViewChildren('inputElement', { read: ElementRef }) inputElements: QueryList<ElementRef>;
    @ViewChildren(MatAutocompleteTrigger) autocompleteTriggers: QueryList<MatAutocompleteTrigger>;

    closedPanel:boolean = false;
    constructor(private platformService: PlatformService,
        private router: NavController,
        private settingService: SettingsService,
        public formBuilder: FormBuilder,
        private modalService: BsModalService,
        private datechangeService: DatechangeService,
        private toastr: ToastrService,
        private commonService: CommonService,
        public sharedService: SharedService,
        public expenseService: ExpensesService,
        private translate: TranslateService,
        private ngZone: NgZone) {
        this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        // this.currencyList = JSON.parse(localStorage.getItem("currencyList"));
        console.log('currency list :', this.currencyList);
        this.selectedLegalEntity = JSON.parse(sessionStorage.getItem('selectedLegalEntity'));
        //this.baseCurrencyCode = this.totalPolcyModel?.policyCurrencyIcon ? this.totalPolcyModel?.policyCurrencyIcon:this.userInfo?.baseCurrency.currencyCode;
        this.baseCurrencyCode = this.userInfo?.baseCurrency.currencyCode;
        this.getClaimTypes();
    }

    /*initial set up */
    ngOnInit() {
        this.getCategorylist(1);
        this.getCategorylist(2);
        this.typeStr = parseInt(sessionStorage.getItem('typeStr'));
        // this.getstateList();
        this.getPerDiemTypesList();
        this.getCityGroupsList();
    }

    ionViewWillEnter() {
        this.setInitialData();
        this.commonService.googlescriptAdd();
    }

    ngAfterViewInit() {
        this.scrollChangeCallback = () => this.onContentScrolled(event);
        window.addEventListener('scroll', this.scrollChangeCallback, true);
    }
    ngOnDestroy() {
        window.removeEventListener('scroll', this.scrollChangeCallback, true);
    }

    setInitialData() {
        this.platformType = this.platformService.getPlatformType();
        this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

        this.getCategorylist(1);
        this.getCategorylist(2);
        this.getCurrencylist();
        this.settingService.getCountries({}).subscribe(data => {
            this.countryList = data.payload;
        });
        this.getMilageStates();

        this.getApprovalWorkflows();
        this.getFinacialWorkflows();

        this.expDataSource = new MatTableDataSource([1]);
        const date = new Date();
        this.minDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        this.maxExpire = new Date(date.getFullYear() + 40, 11);

        this.allUIInfo.rulesSubmitted = false;
        this.allUIInfo.policySubmitted = false;
        this.allUIInfo.trueFalList = [{ type: true, name: 'Yes' }, { type: false, name: 'No' }];
        this.allUIInfo.ruleTypeList = [{ type: 1, name: 'Daily Limit' }, { type: 2, name: 'Monthly Limit' }, { type: 3, name: 'Yearly Limit' }, { type: 4, name: 'Category Cap Limit' }];
        this.allUIInfo.limitTypeList = [[{ type: 1, name: 'Expense Count' }, { type: 3, name: 'Fixed Amount' }], [{ type: 2, name: 'Distance Limit' }]];
        this.allUIInfo.ruleValidations = [{ type: 1, name: 'Warn' }, { type: 2, name: 'Block' }];
        this.allUIInfo.entityList = [{
            hierarchyTypeId: 1, hierarchyTypeName: 'Legal Entity', hierarchyValue: [],
            hierarchyText: [],
            isActive: false, loader: false
        },
        { hierarchyTypeId: 2, hierarchyTypeName: 'Cost Center', hierarchyValue: [], hierarchyText: [], isActive: false, loader: false },
        { hierarchyTypeId: 3, hierarchyTypeName: 'Departments', hierarchyValue: [], hierarchyText: [], isActive: false, loader: false },
        { hierarchyTypeId: 4, hierarchyTypeName: 'Bands', hierarchyValue: [], hierarchyText: [], isActive: false, loader: false },
        { hierarchyTypeId: 5, hierarchyTypeName: 'Teams', hierarchyValue: [], hierarchyText: [], isActive: false, loader: false },
        { hierarchyTypeId: 6, hierarchyTypeName: 'Designations', hierarchyValue: [], hierarchyText: [], isActive: false, loader: false },
        { hierarchyTypeId: 7, hierarchyTypeName: 'User', hierarchyValue: [], hierarchyText: [], isActive: false, loader: false }];
        this.allUIInfo.labelArray = [
            { hierId: 2, mappedKey: 'costCenterList', idKey: 'costCenterId', nameKey: 'costCenterName' },
            { hierId: 3, mappedKey: 'departmentList', idKey: 'departmentId', nameKey: 'departmentName' },
            { hierId: 4, mappedKey: 'bandList', idKey: 'bandId', nameKey: 'bandName' },
            { hierId: 5, mappedKey: 'teamsList', idKey: 'teamId', nameKey: 'teamName' },
            { hierId: 6, mappedKey: 'designationList', idKey: 'designationId', nameKey: 'designationName' },
            { hierId: 7, mappedKey: 'userList', idKey: 'userId', nameKey: 'userName' }];
        this.totalPolcyForm = this.formBuilder.group({
            policyName: ["", Validators.required],
            startDate: ["", Validators.required],
            endDate: ["", Validators.required],
            policyDescription: ["", Validators.required],
            policyCurrency: ["", Validators.required],
            isAllowUncategorizedExpense: [""],
            expenseAmountLimit: ["", [Validators.maxLength(8)]],
            receiptRequiredLimit: ["", [Validators.maxLength(8)]],
            isDescriptionRequired: [""],
            policyCategoryList: this.formBuilder.array([]),
            policyMileageList: this.formBuilder.array([]),
            policyCategoryRuleList: this.formBuilder.array([]),
            policyMileageRuleList: this.formBuilder.array([]),
            entityMapList: this.formBuilder.array([]),
            policyPerDiemList: this.formBuilder.array([]),
            policyPerDiemCategoryList: this.formBuilder.array([]),
            approvalWorkflowId: [""],
            approvalWorkflowName: [""],
            financeApprovalWorkflowName: [""],
            financeApprovalWorkflowId: [""],
            claimTypeId: [""],
            claimTypeName: [""],
            countryIds: [],
            cityIds: []
        });

        this.entityMapForm = this.formBuilder.group({
            2: this.formBuilder.array([]),
            3: this.formBuilder.array([]),
            4: this.formBuilder.array([]),
            5: this.formBuilder.array([]),
            6: this.formBuilder.array([]),
            7: this.formBuilder.array([])
        });

        // criteria form Builder //

        this.criteriForm = this.formBuilder.group({
            items: this.formBuilder.array([])
        })

        this.totalPolcyModel.defaultMillageUnit = 1;
        this.totalPolcyModel.policyCategoryList = [];
        this.totalPolcyModel.policyMileageList = [];
        this.totalPolcyModel.policyCategoryRuleList = [];
        this.totalPolcyModel.policyMileageRuleList = [];
        this.totalPolcyModel.entityMapList = [];
        this.totalPolcyModel.policyPerDiemList = [];

        let navInfo = JSON.parse(sessionStorage.getItem("toPathInfo"));
        console.log('info data :',navInfo);
        if (navInfo && navInfo["url"] && (navInfo["url"] == 'settings/financepolicy')) {
            if (navInfo["policyId"] && navInfo["policyGUID"]) {
                this.isEditPolicy = true;
                this.showLoader = true;
                this.updatePolicy = "Update"
                this.getPolicyApi(navInfo);
            } else {
                this.showLoader = false;
                this.isEditPolicy = false;
                this.setCatgEntityData();
                this.getMappedEntityAPi();
            }
        }

        this.setRulesDatasource();
    }

    setCatgEntityData() {
        this.setDefaultEntityUI();
        this.addDefaultSubEntity();
        //set up general limit data
        /*   let categryList = [{
               "catName": 'Travel'
           },
               {
                   "catName": 'Food'
               },
               {
                   "catName": 'Others'
               }];
           for (let i = 0; i < 2; i++) {
               let catModel = new categryPolyModel();
               catModel.categoryName = categryList[i].catName;
               if (i == 0) {
                   catModel.categoryId = 93;
               } else {
                   catModel.categoryId = 94;
               }

               this.totalPolcyModel.policyCategoryList.push(catModel);
               this.addNewGenerlItem(catModel);
           } */
    }

    addDefaultSubEntity() {
        for (let i = 0; i < this.totalPolcyModel.entityMapList.length; i++) {

            if (i == 0) {
                // this.totalPolcyModel.entityMapList[0].hierarchyValue = this.userInfo?.legalEntityId;
                // this.totalPolcyModel.entityMapList[0].hierarchyText = this.userInfo?.companyName;
            } else {
                let hierId = (i + 1).toString();
                if (this.totalPolcyModel.entityMapList[i].hierarchyValue.length > 0) {
                    let idStrList = (this.totalPolcyModel.entityMapList[i].hierarchyValue).split(',');
                    this.totalPolcyModel.entityMapList[i].hierarchyValue = idStrList;
                    let nameList = (this.totalPolcyModel.entityMapList[i].hierarchyText).split(',');
                    this.totalPolcyModel.entityMapList[i].hierarchyText = nameList;

                    if ((nameList.length == idStrList.length) && (nameList.length != 0)) {

                        var j = 0;
                        idStrList.forEach(id => {
                            this.addSubEntityForm(nameList[j], hierId, id);
                            j = j + 1;
                        });
                    }
                } else {
                    this.totalPolcyModel.entityMapList[i].hierarchyValue = [];
                    this.totalPolcyModel.entityMapList[i].hierarchyText = [];
                    this.totalPolcyModel.entityMapList[i].hierarchyValue.push('');
                    this.totalPolcyModel.entityMapList[i].hierarchyText.push('');
                    this.addSubEntityForm('', hierId);
                }
            }
        }
    }

    ngOnDestory() {
        this.removeSucbr();
    }

    ionViewWillLeave() {
        this.removeSucbr();
    }

    removeSucbr() {
        sessionStorage.setItem('toPathInfo', null);
    }


    backToFinances() {
        this.router.navigateRoot(['settings/financepolicy']);
    }

    backToSettings() {
        this.router.navigateRoot(["/settings/accountconfig"]);
    }

    get categoryPolicyListForms() {
        return this.totalPolcyForm.get("policyCategoryList") as FormArray;
    }

    get mileagePolicyListForms() {
        return this.totalPolcyForm.get("policyMileageList") as FormArray;
    }

    get entityMapRequestForms() {
        return this.totalPolcyForm.get("entityMapList") as FormArray;
    }

    get perDiemListForms() {
        return this.totalPolcyForm.get("policyPerDiemList") as FormArray;
    }

    get perDiemExpensesForms() {
        return this.totalPolcyForm.get("policyPerDiemCategoryList") as FormArray;
    }

    get criteriFormArray() {
        return this.criteriForm.get('items') as FormArray;
    }


    addSubEntityForm(name: any, hierID: any, id?) {

        var frm: any;
        if (id) {
            frm = this.formBuilder.group({
                entityName: [name],
                entityId: [id]
            });
        } else {
            frm = this.formBuilder.group({
                entityName: [name],
                entityId: ['']
            });
        }
        (this.entityMapForm.get(hierID) as FormArray).push(frm);
        // return frm;
    }

    get f() {
        return this.rulesForm.controls;
    }

    setDefaultEntityUI() {
        this.entityMapRequestForms.removeAt(0);
        this.entityMapRequestForms.removeAt(0);
        this.entityMapRequestForms.removeAt(0);
        this.entityMapRequestForms.removeAt(0);
        this.entityMapRequestForms.removeAt(0);
        this.entityMapRequestForms.removeAt(0);
        this.entityMapRequestForms.removeAt(0);

        this.totalPolcyModel.entityMapList = [];

        for (let i = 0; i < 7; i++) {
            this.addNewEntityItem(this.allUIInfo.entityList[i], true);
        }
    }

    criteriaCatSelection(event, index, innexIndex?) {
        console.log('innex index :', innexIndex);
        this.criteriFormArray.at(index).get('categoryName').setValue(event.categoryName);
        this.criteriFormArray.at(index).get('categoryId').setValue(event.categoryId);

        // let findIndex = _.findIndex(this.allCategories,{categoryName:event.categoryName});
        // this.allCategories[findIndex].isdiable = true;
    }

    criteriaConSelection(event, index) {
        this.criteriFormArray.at(index).get('conditionType').setValue(event.conditionType);
        this.criteriFormArray.at(index).get('conditionName').setValue(event.conditionName);
    }

    criteriaWorkSelection(event, index) {
        this.criteriFormArray.at(index).get('approvalWorkFlowName').setValue(event.workflowName);
        this.criteriFormArray.at(index).get('approvalWorkFlowId').setValue(event.approvalWorkflowId);
    }

    addCriteriItem(criteria?: criteriaM) {
        console.log('incoming cate :', criteria);
        // let criteriM = new CriteriModel();
        let criteriaModel = this.formBuilder.group({
            categoryName: [criteria ? criteria.categoryName : '', Validators.required],
            categoryId: [criteria ? criteria.categoryId : ''],
            amount: [criteria ? criteria.amount : '', Validators.required],
            conditionType: [criteria ? criteria.conditionType : '', Validators.required],
            conditionName: [criteria ? criteria.conditionName : ''],
            approvalWorkFlowName: [criteria ? criteria.approvalWorkFlowName : '', Validators.required],
            approvalWorkFlowId: [criteria ? criteria.approvalWorkFlowId : ''],
            criteriaId: [criteria ? criteria.criteriaId : null],
            newItem: [criteria ? false : true],
            policyId: [this.totalPolcyModel.policyId]
        })
        this.criteriFormArray.push(criteriaModel);
        console.log('after :', this.criteriFormArray.value);
    }

    daleteCriteriaItem(item, index) {
        console.log('delete value :', item);
        if (item.value.categoryName != '') {
            // let findIndex = _.findIndex(this.allCategories,{categoryName:item.value.categoryName});
            // this.allCategories[findIndex].isdiable = false;
        }
        this.criteriFormArray.removeAt(index);

    }

    cancelCriteria() {
        // Hide the modal
        this.modalRef.hide();
        this.criteriFormArray.controls = this.criteriFormArray.controls.filter(element => {
            return element.get('newItem').value == false;
        });
        this.submitCriteria = false;
    }

    cretaeCriteri() {
        this.submitCriteria = true;
        if (this.criteriForm.valid) {
            _.forEach(this.criteriFormArray.controls, (control) => { control.patchValue({ 'newItem': false }); });
            this.totalPolcyModel.criteriaList = this.criteriFormArray.value;
            this.modalRef.hide();
        }
    }

    addNewGenerlItem(modelN?) {
        var model;
        if (modelN) {
            model = modelN;
        } else {
            model = new categryPolyModel();
            model.innerCatList = this.categoryList_one;
            this.totalPolcyModel.policyCategoryList.push(model);
        }
        let catgForm = this.formBuilder.group({
            id: [model?.id],
            categoryId: [model?.categoryId],
            categoryName: [model?.categoryName ? model?.categoryName : ''],
            expenseAmountLimit: [model?.expenseAmountLimit, [Validators.maxLength(8)]],
            receiptRequiredLimit: [model?.receiptRequiredLimit, [Validators.maxLength(8)]],
            isDescriptionRequired: [model?.isDescriptionRequired],
            violationType: [model?.violationType],
            startDate: [this.convertDateToUtc(model?.startDate)],
            endDate: [this.convertDateToUtc(model?.endDate)],
            countryIds: [model?.countryIds || []],
            countryNames: [model?.countryNames || []],
            cityIds: [model?.cityIds || []],
            cityNames: [model?.cityNames || []],
            proofOfPaymentLimit: [model?.proofOfPaymentLimit, [Validators.maxLength(8)]],
            cityGroupId: [model?.cityGroupId || null],
            cityGroupName: [model?.cityGroupName || null],
            // countryIds: [model?.countryIds ? model?.countryIds.split(',').map(item => item.trim()) : null],
            // countryNames: [model?.countryNames ? model?.countryNames.split(',').map(item => item.trim()) : null],
            // cityIds: [model?.cityIds ? model?.cityIds.split(',').map(item => item.trim()) : null],
            // cityNames: [model?.cityNames ? model?.cityNames.split(',').map(item => item.trim()) : null]
        });
        this.categoryPolicyListForms.push(catgForm);
    }

    addNewMileageItem(model?, index?) {
        var milgModel;
        if (model) {
            milgModel = model;
        } else {
            milgModel = new mileagePolyModel();
            this.totalPolcyModel.policyMileageList.push(milgModel);
        }

        let milgForm = this.formBuilder.group({
            id: [model?.id],
            categoryId: [model?.categoryId],
            categoryName: [model?.categoryName],
            mileageRate: [model?.mileageRate],
            locationName: [model?.stateNam],
            country: [model?.country],
            receiptRequiredLimit: [model?.receiptRequiredLimit],
            isDescriptionRequired: [model?.isDescriptionRequired],
            startDate: [this.convertDateToUtc(model?.startDate)]
        });
        this.mileagePolicyListForms.push(milgForm);
    }


    addNewEntityItem(entity: any, noModel?) {
        if (noModel) {
            var newModel = new polyEntityMapModel();
            newModel.id = entity?.id;
            newModel.hierarchyText = entity?.hierarchyText;
            newModel.hierarchyValue = entity?.hierarchyValue;
            newModel.hierarchyTypeId = entity?.hierarchyTypeId;
            newModel.hierarchyTypeName = entity?.hierarchyTypeName;
            newModel.isActive = entity?.isActive;
            this.totalPolcyModel.entityMapList.push(newModel);
        }

        let entityForm = this.formBuilder.group({
            hierarchyText: entity.isActive ? [entity.hierarchyText] : '',
            hierarchyValue: entity.isActive ? [entity?.hierarchyValue] : '',
            hierarchyTypeId: [entity.hierarchyTypeId],
            hierarchyTypeName: [entity.name],
            isActive: [entity?.isActive]
        });
        this.entityMapRequestForms.push(entityForm);
    }

    addNewPerDiemItem(model?) {
        var perDiemModl;
        if (model) {
            perDiemModl = model;
        } else {
            perDiemModl = new polyPerDiemModel();
            this.totalPolcyModel.policyPerDiemList.push(perDiemModl);
        }

        let perDiemForm = this.formBuilder.group({
            countryId: [perDiemModl?.countryId],
            displayName: [perDiemModl?.displayName],
            expenseAmountLimit: [perDiemModl?.expenseAmountLimit],
            receiptRequiredLimit: [perDiemModl?.receiptRequiredLimit],
            currencyId: [perDiemModl?.currencyId],
            startDate: [this.convertDateToUtc(perDiemModl?.startDate)],
            endDate: [this.convertDateToUtc(perDiemModl?.endDate)],
            isPerDiemSplit: [perDiemModl?.isPerDiemSplit],
        });
        this.perDiemListForms.push(perDiemForm);
    }

    addNewDiemExpenseItem(model?) {
        var expModel = new perDiemExpenseItem();
        if (model) {
            expModel = model;
        } else {
            this.rulesModel.policyPerDiemCategoryList.push(new perDiemExpenseItem());
        }

        let expForm = this.formBuilder.group({
            categoryId: [expModel?.categoryId],
            categoryName: [expModel?.categoryName],
            currencyId: [expModel?.currencyId],
            currencyCode: [expModel?.currencyCode],
            expenseAmountLimit: [expModel?.expenseAmountLimit],
        });

        this.perDiemExpensesForms.push(expForm);
    }

    convertDateToUtc(date) {
        let selectedDate = moment(moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD')).utc(true).toISOString(false);
        return selectedDate;
    }

    /*initial set up end*/

    /*api calls */
    getPolicyApi(info: PolyUpdateModel,clone?) {
        let polModel = new PolyUpdateModel();
        polModel.policyGUID = info["policyGUID"];
        polModel.policyId = info["policyId"];

        this.settingService.getFinancialPol(polModel).subscribe(res => {
            this.showLoader = false;
            if (res && res?.payload) {
                let allCountryIds = [];
                res.payload.policyCategoryList.forEach(p => {
                    p.cityIds = p.cityIds && p.cityIds != '' ? p.cityIds.split(',').map(item => item.trim()) : null;
                    p.cityNames = p.cityNames && p.cityNames != '' ? p.cityNames.split(',').map(item => item.trim()) : null;
                    p.countryIds = p.countryIds && p.countryIds != '' ? p.countryIds.split(',').map(item => item.trim()) : null;
                    p.countryNames = p.countryNames && p.countryNames != '' ? p.countryNames.split(',').map(item => item.trim()) : null;
                    p.countryIds?.forEach(c => {
                        allCountryIds.push(c);
                    })
                });
                res.payload.policyCategoryRuleList.forEach(p => {
                    p.cityIds = p.cityIds && p.cityIds != '' ? p.cityIds.split(',').map(item => item.trim()) : null;
                    p.cityNames = p.cityName && p.cityName != '' ? p.cityName.split(',').map(item => item.trim()) : null;
                    p.countryIds = p.countryIds && p.countryIds != '' ? p.countryIds.split(',').map(item => item.trim()) : null;
                    p.countryNames = p.countryName && p.countryName != '' ? p.countryName.split(',').map(item => item.trim()) : null;
                    p.countryIds?.forEach(c => {
                        allCountryIds.push(c);
                    })
                });

                this.getAllCities([...new Set(allCountryIds)]);

                this.totalPolcyModel = res.payload;
                if (this.totalPolcyModel.criteriaList?.length != 0) {
                    this.totalPolcyModel.criteriaList.forEach(element => {
                        let passM = new criteriaM();
                        passM.amount = element.amount,
                            passM.categoryName = element.categoryName,
                            passM.categoryId = element.categoryId,
                            passM.approvalWorkFlowName = element.approvalWorkFlowName,
                            passM.approvalWorkFlowId = element.approvalWorkFlowId,
                            passM.criteriaId = element.criteriaId,
                            passM.policyId = this.totalPolcyModel.policyId,
                            passM.conditionType = element.conditionType,
                            passM.conditionName = _.find(this.conditions, { conditionType: element.conditionType.toString() })?.conditionName
                        this.addCriteriItem(passM);

                    });
                    console.log('elele :', this.allCategories);
                } else {
                    // this.addCriteriItem(null);
                }
                this.dataSource = new MatTableDataSource(this.totalPolcyModel.policyCategoryList);
                this.mileageSource = new MatTableDataSource(this.totalPolcyModel.policyMileageList);
                // this.totalPolcyForm = this.formBuilder.group({
                //     startDate: [this.convertDateToUtc(this.totalPolcyModel?.startDate)],
                //     endDate: [this.convertDateToUtc(this.totalPolcyModel?.endDate)],

                // });
                this.totalPolcyForm.controls.startDate.patchValue(this.convertDateToUtc(this.totalPolcyModel?.startDate));
                this.minDate = new Date(this.convertDateToUtc(this.totalPolcyModel?.startDate));

                this.totalPolcyForm.controls.endDate.patchValue(
                    this.convertDateToUtc(this.totalPolcyModel?.endDate)
                );
                this.totalPolcyForm.controls.financeApprovalWorkflowName.patchValue(this.totalPolcyModel?.financeApprovalWorkflowName
                );

                this.isPerDiemEnabled = this.totalPolcyModel.isPerDiemEnabled;
                this.totalPolcyModel.policyCategoryList.forEach((item) => {
                    this.addNewGenerlItem(item);
                    item.innerCatList = this.categoryList_one;
                });
                this.totalPolcyModel.policyMileageList.forEach((item, index) => {
                    this.addNewMileageItem(item, index);
                });

                if (this.totalPolcyModel.policyPerDiemList) {
                    this.totalPolcyModel.policyPerDiemList.forEach((item) => {
                        this.addNewPerDiemItem(item);
                    });
                }

                if (this.totalPolcyModel.entityMapList.length == 0) {
                    this.setDefaultEntityUI();
                } else {
                    var list = this.totalPolcyModel.entityMapList;
                    list.sort(function (a, b) {
                        return a.hierarchyTypeId > b.hierarchyTypeId;
                    });

                    this.totalPolcyModel.entityMapList.forEach((item) => {
                        if (item.hierarchyTypeId == 1 && item.isActive == true) {//it means legalEntity is mapped
                            this.canEditLegalEntity = true;
                        }
                        this.addNewEntityItem(item, false);
                    });
                }
                let navInfo = JSON.parse(sessionStorage.getItem("toPathInfo"));
                this.totalPolcyModel.action = navInfo?.action ? navInfo?.action :'' 
                this.addDefaultSubEntity();

            } else {
                this.setCatgEntityData();
                this.toastr.error(res.errorMessage, 'Error');
            }

            this.getMappedEntityAPi(); //Note:call this api only after setting values for TotalModel
        });
    }

    allCategories: any;
    getCategorylist(type: any, searchTxt?) {
        let selectedLegalEntity = JSON.parse(sessionStorage.getItem('selectedLegalEntity'));
        var catgryModel = new CategoryAndMerchantModel();
        catgryModel.companyId = selectedLegalEntity?.companyId || this.userInfo?.companyId;
        catgryModel.companyGUID = selectedLegalEntity?.companyGuId || this.userInfo?.companyGuId;
        catgryModel.legalEntityId = selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId;
        catgryModel.legalEntityGUID = selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId;
        catgryModel.status = 1;
        catgryModel.categoryType = type;
        catgryModel.locationId = this.locationId;
        catgryModel.stateId = this.stateIds;
        catgryModel.searchText = searchTxt ? searchTxt : '';
        this.commonService
            .getCategorylist(catgryModel)
            .subscribe((res) => {
                if (!res.isError && res.errorCode == 0) {
                    let listarray = res.payload.categoryList;
                    if (type == 1) {
                        this.allCategories = res.payload.categoryList;
                        // this.allCategories = res.payload.categoryList.map(item => ({ ...item, isdiable: false}));
                        this.child_cats = this.settingService.getSubCategories(res.payload.categoryList);
                    }
                    if (type == 1) {
                        this.categoryList = listarray.map((list) => new CategoryList(list));
                        if (this.dummyCategoryList.length == 0) {
                            this.dummyCategoryList = listarray.map((list) => new CategoryList(list));
                        }
                        this.categoryList_one = listarray.map((list) => new CategoryList(list));
                        this.dummyCategoryList_one = listarray.map((list) => new CategoryList(list));
                        this.totalPolcyModel.policyCategoryList?.forEach((item) => {
                            this.addNewGenerlItem(item);
                            item.innerCatList = this.categoryList_one;
                        });
                    } else {
                        this.vehiclesList = listarray.map((list) => new CategoryList(list));
                    }

                } else if (res.isError == true) {
                    this.toastr.error(res.errorMessage, 'error');
                }

                // console.log('sdhjhsjdhsjd',this.allCategories);
            });
    }

    openSearchInput() {
        console.log('serach inut');
        this.categoryList = this.dummyCategoryList;
        console.log('serach inut', this.categoryList, this.dummyCategoryList, this.dummyCategoryList_one);
    }

    createPolicyAPI() {
        this.allUIInfo.policySubmitted = true;
        if (!this.totalPolcyForm.valid) {
            if (this.totalPolcyForm.controls.policyName.status == 'INVALID' ||
                this.totalPolcyForm.controls.startDate.status == 'INVALID' ||
                this.totalPolcyForm.controls.endDate.status == 'INVALID' ||
                this.totalPolcyForm.controls.expenseAmountLimit.status == 'INVALID' ||
                this.totalPolcyForm.controls.receiptRequiredLimit.status == 'INVALID') {
                return;
            }
        }
        var hasError = false;
        if (!this.categoryPolicyListForms.valid) {
            for (let item of (this.categoryPolicyListForms as FormArray).controls) {
                if (item.status == "INVALID") {
                    if ((item as FormGroup).controls.expenseAmountLimit.status == "INVALID") {
                        hasError = true;
                    }
                    if ((item as FormGroup).controls.receiptRequiredLimit.status == "INVALID") {
                        hasError = true;
                    }
                    if (hasError) {
                        break;
                    }
                }
            }
        }

        if (hasError) {
            return;
        }

        var isEntityValid = true;
        this.totalPolcyModel.entityMapList.every((item, indx) => {
            if (item?.hierarchyTypeId == 1) {
                //--ignore
            } else {
                (this.allUIInfo.entityList[indx])['error'] = false;
                if ((item.isActive == true) &&
                    ((item?.hierarchyValue.length == 1 && item?.hierarchyValue[0] == '') || (item?.hierarchyValue.length == 0))
                ) {
                    (this.allUIInfo.entityList[indx])['error'] = true;
                    isEntityValid = false;
                }
            }
            if (!isEntityValid) {
                return false;
            }
            return true;
        });

        if (!isEntityValid) {
            return;
        }
        this.totalPolcyModel.entityMapList.forEach((item) => {
            if (item?.hierarchyTypeId == 1) {
                item.hierarchyValue = (item?.hierarchyValue).toString();
            } else {
                var strList = '';
                var nameList = '';
                item.hierarchyValue = this.strToList(item?.hierarchyValue);
                item.hierarchyText = this.strToList(item?.hierarchyText);
                if (item?.hierarchyValue?.length > 0) {
                    item?.hierarchyValue?.forEach((entityId) => {
                        strList = strList + entityId + ',';
                    });
                    strList = strList.substring(0, (strList.length - 1));
                }
                if (item?.hierarchyText?.length > 0) {
                    item?.hierarchyText?.forEach((name) => {
                        nameList = nameList + name + ',';
                    });
                    nameList = nameList.substring(0, (nameList.length - 1));
                }
                item.hierarchyValue = strList;
                item.hierarchyText = nameList;
            }
        });

        this.totalPolcyModel.entityMapList.forEach(element => {
            if (element.hierarchyTypeId == 1 && !element.isActive) {
                element.hierarchyText = '';
                element.hierarchyValue = '';
            } else if (element.hierarchyTypeId == 1 && element.isActive) {
                element.hierarchyValue = this.selectedLegalEntity.legalEntityId || this.userInfo?.legalEntityId;
                element.hierarchyText = '';
            }
        });
        this.totalPolcyModel.legalEntityId = this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId;
        this.totalPolcyModel.legalEntityGUID = this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId;
        this.totalPolcyModel.recordType = 1;

        if (this.totalPolcyModel.financeApprovalWorkflowName == undefined || this.totalPolcyModel.approvalWorkflowName == undefined) {
            this.workFlowWarning = true;
            return false;
        } else {
            this.workFlowWarning = true;
            this.buttonDisabled = true;
            let countryNames = [];
            let countryIds = [];
            let cityNames = [];
            let cityIds = [];
            this.totalPolcyModel.policyCategoryList.forEach(p => {
                cityIds = [];
                cityNames = [];
                countryIds = [];
                countryNames = [];
                // p.countryIds = Array.isArray(p.countryIds) ? p.countryIds : p.countryIds?.toString().split(',');
                // p.cityIds = Array.isArray(p.cityIds) ? p.cityIds : p.cityIds?.toString().split(',');
                if (Array.isArray(p.cityIds)) {
                    // p.cityIds?.forEach(c => {
                    //     const index = this.citiesList?.findIndex(cl => cl.cityId == c);
                    //     if (index >= 0) {
                    //         cityIds.push(this.citiesList[index].cityId);
                    //         cityNames.push(this.citiesList[index].cityName);
                    //     }
                    // })
                    p.cityIds = p.cityIds?.join(',');
                    p.cityNames = p.cityNames?.join(',');
                }
                if (Array.isArray(p.countryIds)) {
                    // p.countryIds?.forEach(c => {
                    //     const index = this.countryList?.findIndex(cl => cl.countryId == c);
                    //     if (index >= 0) {
                    //         countryIds.push(this.countryList[index].countryId);
                    //         countryNames.push(this.countryList[index].countryName);
                    //     }
                    // })
                    p.countryIds = p.countryIds?.join(',');
                    p.countryNames = p.countryNames?.join(',');
                }
            })

            this.totalPolcyModel.policyCategoryRuleList.forEach(p => {
                cityIds = [];
                cityNames = [];
                countryIds = [];
                countryNames = [];
                if (Array.isArray(p.cityIds)) {
                    // p.cityIds?.forEach(c => {
                    //     const index = this.citiesList?.findIndex(cl => cl.cityId == c);
                    //     if (index >= 0) {
                    //         cityIds.push(this.citiesList[index].cityId);
                    //         cityNames.push(this.citiesList[index].cityName);
                    //     }
                    // })
                    p.cityIds = p.cityIds?.join(',');
                    p.cityNames = p.cityNames?.join(',');
                }
                if (Array.isArray(p.countryIds)) {
                    // p.countryIds?.forEach(c => {
                    //     const index = this.countryList?.findIndex(cl => cl.countryId == c);
                    //     if (index >= 0) {
                    //         countryIds.push(this.countryList[index].countryId);
                    //         countryNames.push(this.countryList[index].countryName);
                    //     }
                    // })
                    p.countryIds = p.countryIds?.join(',');
                    p.countryNames = p.countryNames?.join(',');
                }
                if (Array.isArray(p.cityNames)) {
                    p.cityNames = p?.cityNames?.join(',');
                }
                if (Array.isArray(p.countryNames)) {
                    p.countryNames = p?.countryNames?.join(',');
                }
            })

            this.totalPolcyModel.policyMileageList.forEach(p => {
                cityIds = [];
                cityNames = [];
                countryIds = [];
                countryNames = [];
                p.countryIds = Array.isArray(p.countryIds) ? p.countryIds : p.countryIds?.toString().split(',');
                p.cityIds = Array.isArray(p.cityIds) ? p.cityIds : p.cityIds?.toString().split(',');
                if (Array.isArray(p.cityIds)) {
                    p.cityIds?.forEach(c => {
                        const index = this.citiesList?.findIndex(cl => cl.cityId == c);
                        if (index >= 0) {
                            cityIds.push(this.citiesList[index].cityId);
                            cityNames.push(this.citiesList[index].cityName);
                        }
                    })
                    p.cityIds = cityIds.join(',');
                    p.cityNames = cityNames.join(',');
                }
                if (Array.isArray(p.countryIds)) {
                    p.countryIds?.forEach(c => {
                        const index = this.countryList?.findIndex(cl => cl.countryId == c);
                        if (index >= 0) {
                            countryIds.push(this.countryList[index].countryId);
                            countryNames.push(this.countryList[index].countryName);
                        }
                    })
                    p.countryIds = countryIds.join(',');
                    p.countryNames = countryNames.join(',');
                }
            })

            this.totalPolcyModel.policyMileageRuleList.forEach(p => {
                cityIds = [];
                cityNames = [];
                countryIds = [];
                countryNames = [];
                if (Array.isArray(p.cityIds)) {
                    p.cityIds?.forEach(c => {
                        const index = this.citiesList?.findIndex(cl => cl.cityId == c);
                        if (index >= 0) {
                            cityIds.push(this.citiesList[index].cityId);
                            cityNames.push(this.citiesList[index].cityName);
                        }
                    })
                    p.cityIds = cityIds.join(',');
                    p.cityNames = cityNames.join(',');
                }
                if (Array.isArray(p.countryIds)) {
                    p.countryIds?.forEach(c => {
                        const index = this.countryList?.findIndex(cl => cl.countryId == c);
                        if (index >= 0) {
                            countryIds.push(this.countryList[index].countryId);
                            countryNames.push(this.countryList[index].countryName);
                        }
                    })
                    p.countryIds = countryIds.join(',');
                    p.countryNames = countryNames.join(',');
                }
                if (Array.isArray(p.cityNames)) {
                    p.cityNames = p?.cityNames?.join(',');
                }
                if (Array.isArray(p.countryNames)) {
                    p.countryNames = p?.countryNames?.join(',');
                }
            })

            console.log(this.totalPolcyModel);
            this.settingService.createFinancialPol(this.totalPolcyModel).subscribe(res => {
                this.allUIInfo.policySubmitted = false;
                this.buttonDisabled = false;
                if (!res.isError) {
                    var alert = this.translate.instant('alerts.polCreate') + this.translate.instant('alerts.createsuccess');
                    if (this.isEditPolicy) {
                        alert = this.translate.instant('alerts.polEdit') + this.translate.instant('alerts.updateSuccess');
                    }
                    this.toastr.success(alert, this.translate.instant('alerts.succes'));
                    this.backToFinances();
                } else {
                    this.toastr.error(res.errorMessage, 'Error');
                }
            });
        }


    }

    setCitiesData(ev) {

        console.log('selected valeu :',ev);

        if(!this.newPerDiemModel.cityId) {
            this.newPerDiemModel.cityId = [];
        }

        if(!this.newPerDiemModel.cityName) {
            this.newPerDiemModel.cityName = [];
        }

        this.newPerDiemModel.cityName.push(ev.cityName);
        this.newPerDiemModel.cityId.push(ev.cityId);

        // // Iterate over selected city IDs
        // ev.forEach((id: number) => {
        //     // Find the city object in the citiesList array by its ID
        //     const city = this.citiesList.find(c => c.cityId === id);
        //     if (city) {
        //         // If city is found, add its ID and name to the model
        //         this.newPerDiemModel.cityId.push(city.cityId);
        //         this.newPerDiemModel.cityName.push(city.cityName);
        //     }
        // });

        // Remove duplicates from cityNameList array
        // this.newPerDiemModel.cityName = [...new Set(this.newPerDiemModel.cityName)];
        // console.log('serach :', this.pdRateEditIndex, this.newPerDiemModel);

    }

    // ONE
    createPerdiemSave() {
        console.clear();
        this.newPerDiemModel.perDiemConditions = this.withRcptCndts;
        // this.newPerDiemModel.withoutRecieptDiemConditions = this.withoutRcptCndts;
        // console.log(this.seperateStr, this.newPerDiemModel);
        // console.log('withRcptCndts _____ ',this.withRcptCndts);
        // console.log('withoutRcptCndts _____ ',this.withoutRcptCndts);

        // if (!this.newPerDiemModel.isAllowedWithReceipt && !this.newPerDiemModel.isAllowedWithoutReceipt) {
        //     this.toastr.error('Fill all Mandatory fields', 'Error');
        //     return;
        // }

        let addedWithRcptCndts = this.withRcptCndts.some(item => (item.conditionType == null || item.conditionType == '') || (item.value == null || item.value == '') || (item.perDiemType == null || item.perDiemType == ''));
        let addedWithoutRcptCndts = this.withoutRcptCndts.some(item => item.conditionType === null || item.value === null || item.perDiemType === null);

        // if (this.newPerDiemModel.isAllowedWithReceipt && addedWithRcptCndts) {
        //     this.toastr.error('Fill all Mandatory fields', 'Error');
        //     return;
        // }

        // if (this.newPerDiemModel.isAllowedWithoutReceipt && addedWithoutRcptCndts) {
        //     this.toastr.error('Fill all Mandatory fields', 'Error');
        //     return;
        // }

        // if (this.newPerDiemModel.countryName == '' || this.newPerDiemModel.countryName == undefined) {
        //     this.toastr.error('Fill all Manditory fields', 'Error');
        //     return;
        // }

        // EXISTS
        console.log(this.totalPolcyModel.policyPerDiemList);
        let countryExist = false;
        let cityExist = false;
        this.totalPolcyModel.policyPerDiemList?.forEach((p, i) => {
            if (p.countryId == this.newPerDiemModel.countryId && this.pdRateEditIndex != i && (!countryExist || !cityExist)) {
                countryExist = true;
                console.log({
                    cityId: this.newPerDiemModel.cityId,
                    p_cityId: p.cityId,
                    p_cityName: p.cityName
                });

                if (this.newPerDiemModel.cityId && p.cityId) {
                    let p_cityIds = Array.isArray(p.cityId) ? p.cityId : p.cityId?.split(',');
                    const cityIdSet = new Set(this.newPerDiemModel.cityId);
                    const pcityIdSet = new Set(p_cityIds);
                    cityExist = [...cityIdSet].some(id => pcityIdSet.has(id));
                } else if ((!this.newPerDiemModel.cityId || this.newPerDiemModel.cityId?.length == 0) && !p.cityId && !p.cityName) {
                    cityExist = true;
                } else {
                    cityExist = false;
                }
            }
        });


        if (this.seperateStr == 'new') {
            if (this.newPerDiemModel?.perDiemTypeIds?.length == 0) {
                this.toastr.error('Fill all Manditory fields', 'Error');
            } else {
                this.totalPolcyModel.policyPerDiemList.push(this.newPerDiemModel);
                this.modalRef.hide();
            }
        } else {
            if (this.newPerDiemModel?.perDiemTypeIds?.length == 0) {
                this.toastr.error('Fill all Manditory fields', 'Error');
            } else {
                this.totalPolcyModel.policyPerDiemList.splice(this.pdRateEditIndex, 1, this.newPerDiemModel);
                this.modalRef.hide();
            }
        }
    }
    editPerdiem(perdiem) {
    }
    removePerdiem(perdiem) {
    }

    openSelect(index){
        this.CategoryMerchantModel.legalEntityId = this.selectedLegalEntity?.legalEntityId || this.userInfo.legalEntityId;
        this.CategoryMerchantModel.legalEntityGUID = this.selectedLegalEntity?.legalEntityGuId || this.userInfo.legalEntityGuId;
        this.CategoryMerchantModel.stateId = this.totalPolcyModel.policyMileageList[index].stateId;
        this.CategoryMerchantModel.countryId = this.totalPolcyModel.policyMileageList[index].countryId;
        this.commonService
            .getCatgryMasterlist(this.CategoryMerchantModel)
            .subscribe((res) => {
                if (!res.isError && res.errorCode == 0) {
                    let listarray = res.payload;
                    this.totalPolcyModel.policyMileageList[index].mileagCategoryList = listarray.mileageCategoryList.map((list) => new CategoryList(list));
                   
                    // Focus the input field and manually trigger the autocomplete
                    setTimeout(() => {
                        const trigger = this.autocompleteTriggers.toArray()[index];
                        trigger.openPanel();
                      }, 0);

                } else if (res.isError == true) {
                    this.toastr.error(
                        res.errorMessage,
                        this.translate.instant("alerts.error")
                    );
                }
            });
    }

    onOptionSelected(value, index) {
        const control = (this.mileagePolicyListForms.at(index) as FormGroup).get('categoryName');
        control.setValue(value.categoryName);
        this.totalPolcyModel.policyMileageList[index].categoryName = value.categoryName;
        this.totalPolcyModel.policyMileageList[index].categoryId = value.categoryId;
      }

    showhideColumns(val: string) {
        let column = true;
        if (this.hideColumns.indexOf(val) > -1) {
            column = false;
        }
        return column;
    }
    toggleColumns(e, val: string) {
        if (e.target.checked == true) {
            this.hideColumns.push(val);
        } else {
            let index = this.hideColumns.indexOf(val);
            if (index > -1) {
                this.hideColumns.splice(index, 1);
            }
        }
    }

    // createPerdiePolicyAPI() {

    //     this.allUIInfo.policySubmitted = true;
    //     if (!this.totalPolcyForm.valid) {
    //         if (this.totalPolcyForm.controls.policyName.status == 'INVALID' ||
    //             this.totalPolcyForm.controls.expenseAmountLimit.status == 'INVALID' ||
    //             this.totalPolcyForm.controls.receiptRequiredLimit.status == 'INVALID') {
    //             return;
    //         }
    //     }

    //     var hasError = false;
    //     if (!this.categoryPolicyListForms.valid) {
    //         for (let item of (this.categoryPolicyListForms as FormArray).controls) {
    //             if (item.status == "INVALID") {
    //                 if ((item as FormGroup).controls.expenseAmountLimit.status == "INVALID") {
    //                     hasError = true;
    //                 }
    //                 if ((item as FormGroup).controls.receiptRequiredLimit.status == "INVALID") {
    //                     hasError = true;
    //                 }
    //                 if (hasError) {
    //                     break;
    //                 }
    //             }
    //         }
    //     }

    //     if (hasError) {
    //         return;
    //     }

    //     var isEntityValid = true;
    //     this.totalPolcyModel.entityMapList.every((item, indx) => {
    //         if (item?.hierarchyTypeId == 1) {
    //             //--ignore
    //         } else {
    //             (this.allUIInfo.entityList[indx])['error'] = false;
    //             if ((item.isActive == true) &&
    //                 ((item?.hierarchyValue.length == 1 && item?.hierarchyValue[0] == '') || (item?.hierarchyValue.length == 0))
    //             ) {
    //                 (this.allUIInfo.entityList[indx])['error'] = true;
    //                 isEntityValid = false;
    //             }
    //         }
    //         if (!isEntityValid) {
    //             return false;
    //         }
    //         return true;
    //     });

    //     if (!isEntityValid) {
    //         return;
    //     }

    //     this.totalPolcyModel.entityMapList.forEach((item) => {
    //         if (item?.hierarchyTypeId == 1) {
    //             item.hierarchyValue = (item?.hierarchyValue).toString();
    //         } else {
    //             var strList = '';
    //             var nameList = '';
    //             if (item?.hierarchyValue.length > 0) {
    //                 item?.hierarchyValue.forEach((entityId) => {
    //                     strList = strList + entityId + ',';
    //                 });
    //                 strList = strList.substring(0, (strList.length - 1));
    //             }
    //             if (item?.hierarchyText.length > 0) {
    //                 item?.hierarchyText.forEach((name) => {
    //                     nameList = nameList + name + ',';
    //                 });
    //                 nameList = nameList.substring(0, (nameList.length - 1));
    //             }
    //             item.hierarchyValue = strList;
    //             item.hierarchyText = nameList;
    //         }
    //     });

    //     this.totalPolcyModel.legalEntityId = this.userInfo?.legalEntityId;
    //     this.totalPolcyModel.legalEntityGUID = this.userInfo?.legalEntityGuId;
    //     this.totalPolcyModel.recordType = 1;
    //     this.totalPolcyModel.policyPerDiemList = [this.newPerDiemModel];
    //     this.totalPolcyModel.isPerDiemEnabled = this.isPerDiemEnabled;


    //     this.buttonDisabled = true;
    //     this.settingService.createFinancialPol(this.totalPolcyModel).subscribe(res => {
    //         this.allUIInfo.policySubmitted = false;
    //         this.buttonDisabled = false;
    //         if (!res.isError) {
    //             var alert = this.translate.instant('alerts.polCreate') + this.translate.instant('alerts.createsuccess');
    //             if (this.isEditPolicy) {
    //                 alert = this.translate.instant('alerts.polEdit') + this.translate.instant('alerts.updateSuccess');
    //             }
    //             this.toastr.success(alert, this.translate.instant('alerts.succes'));
    //             this.backToFinances();
    //         } else {
    //             this.toastr.error(res.errorMessage, 'Error');
    //         }
    //     });
    // }

    getMappedEntityAPi() {
        let apiModel = new UserRcrdModel();
        apiModel.userId = this.userInfo?.userId;
        apiModel.userGUID = this.userInfo?.userGuId;
        apiModel.recordType = 1;

        this.settingService.getMappedEntities(apiModel).subscribe(res => {
            var apiMappedList = new mappedModel();
            if (!res.isError && res?.payload) {
                apiMappedList = res?.payload;
                res?.payload?.legalEntityList.forEach(ele => {
                    if (ele?.legalEntityId == this.userInfo?.legalEntityId) {
                        this.legalEntityMapped = true;
                    }
                });
            }
            this.getCostCenterAPi(apiMappedList);
            this.getDepartmentAPi(apiMappedList);
            this.getbandAPi(apiMappedList);
            this.getTeamsApi(apiMappedList);
            this.getDesignationsAPi(apiMappedList);
            this.getUsersAPi(apiMappedList);
        });
    }

    getMapedPolyEntityLists(hierId: any) {
        var selectdList = [];
        if (this.isEditPolicy) {
            this.totalPolcyModel.entityMapList.forEach(ele => {
                if (ele.hierarchyTypeId == hierId) {
                    selectdList = JSON.parse(JSON.stringify(((ele.hierarchyValue).toString()).split(',')));
                }
            });
        }

        return selectdList;
    }

    setSelectdKey(list: any) {
        var i = 0;
        list?.forEach(element => {
            element['isSelected'] = false;
            list[i] = element;
            i = i + 1;
        });
    }

    getCostCenterAPi(mappedList: any) {
        let apiModel = new entityModels();
        apiModel.legalEntityId = this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId;
        apiModel.legalEntityGuid = this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId;
        apiModel.statusId = 1;
        apiModel.searchText = this.filterSearch;
        (this.allUIInfo.entityList[1])['loader'] = true;
        this.settingService.getCostCenterList(apiModel).subscribe(res => {
            (this.allUIInfo.entityList[1])['loader'] = false;
            if (!res.isError && res?.payload?.costCenterList) {
                var list = res?.payload?.costCenterList;
                this.setEntityDropDownList(this.allUIInfo.labelArray[0], list, mappedList);
            }
        });
    }

    getDepartmentAPi(mappedList: any) {
        let apiModel = new entityModels();
        apiModel.legalEntityId = this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId;
        apiModel.legalEntityGuid = this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId;
        apiModel.statusId = 1;
        apiModel.searchText = this.filterSearch,
            (this.allUIInfo.entityList[2])['loader'] = true;
        this.settingService.getDepartmentsist(apiModel).subscribe(res => {
            (this.allUIInfo.entityList[2])['loader'] = false;
            if (!res.isError && res?.payload?.departmentList) {
                var list = res?.payload?.departmentList;
                this.setEntityDropDownList(this.allUIInfo.labelArray[1], list, mappedList);
            } else {
            }
        });
    }

    getbandAPi(mappedList: any) {
        let apiModel = new entityModels();
        apiModel.legalEntityId = this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId;
        apiModel.legalEntityGuid = this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId;
        apiModel.statusId = 1;
        apiModel.pageSize = 1000;
        apiModel.searchText = this.filterSearch,
            (this.allUIInfo.entityList[3])['loader'] = true;
        this.settingService.getBrandsList(apiModel).subscribe(res => {
            (this.allUIInfo.entityList[3])['loader'] = false;
            if (!res.isError && res?.payload?.bandList) {
                var list = res?.payload?.bandList;
                this.setEntityDropDownList(this.allUIInfo.labelArray[2], list, mappedList);
            } else {
            }
        });
    }

    getTeamsApi(mappedList: any) {
        (this.allUIInfo.entityList[4])['loader'] = true;
        this.settingService.getTeamssList({
            "legalEntityId": this.selectedLegalEntity?.legalEntityId || this.userInfo.legalEntityId,
            "legalEntityGUID": this.selectedLegalEntity?.legalEntityGuId || this.userInfo.legalEntityGuId,
            "statusId": 1,
            "searchText": this.filterSearch,
        }).subscribe(res => {
            (this.allUIInfo.entityList[4])['loader'] = false;
            if (!res.isError && res?.payload?.teamsList) {
                var list = res?.payload?.teamsList;
                this.setEntityDropDownList(this.allUIInfo.labelArray[3], list, mappedList);
            } else {
            }
        });
    }

    getDesignationsAPi(mappedList: any) {
        let apiModel = new entityModels();
        apiModel.legalEntityId = this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId;
        apiModel.legalEntityGuid = this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId;
        apiModel.statusId = 1;
        apiModel.searchText = this.filterSearch,
            (this.allUIInfo.entityList[5])['loader'] = true;
        this.settingService.getDesignationsList(apiModel).subscribe(res => {
            (this.allUIInfo.entityList[5])['loader'] = false;
            if (!res.isError && res?.payload?.designationList) {
                var list = res?.payload?.designationList;
                this.setEntityDropDownList(this.allUIInfo.labelArray[4], list, mappedList);
            } else {
            }
        });
    }

    getUsersAPi(mappedList: any) {
        (this.allUIInfo.entityList[6])['loader'] = true;
        this.settingService.getUsersList(
            {
                "legalEntityId": this.selectedLegalEntity?.legalEntityId || this.userInfo.legalEntityId,
                "legalEntityGUID": this.selectedLegalEntity?.legalEntityGuId || this.userInfo.legalEntityGuId,
                "statusId": 1,
                "searchText": this.filterSearch,
            }).subscribe(res => {
                if (!res.isError) {
                    (this.allUIInfo.entityList[6])['loader'] = false;
                    var list = res?.payload?.users;
                    this.setEntityDropDownList(this.allUIInfo.labelArray[5], list, mappedList);
                } else {
                }
            });
    }

    setEntityDropDownList(labelDic: any, list: any, mappedList: any) {
        // let getApiList = this.getMapedPolyEntityLists(labelDic.hierId);
        this.setSelectdKey(list);
        // mappedList[labelDic.mappedKey].forEach(mapedEle => {
        //     let index = list.findIndex((item) => item[labelDic.idKey] == mapedEle[labelDic.idKey]);
        //     if (index > -1) {
        //         let matchIndx = getApiList.findIndex((item) => item == mapedEle[labelDic.idKey]);
        //         if (matchIndx > -1) {
        //             var editDic = list[index];
        //             editDic['isSelected'] = true;
        //             list[index] = editDic;
        //         } else {
        //             list.splice(index, 1);
        //         }
        //     }
        // });
        this.allEntitysLists[labelDic.hierId] = list;
        if (labelDic.hierId == 2) {
            this.dummyCostCenter = list;
        } else if (labelDic.hierId == 3) {
            this.dummyDepartment = list;
        } else if (labelDic.hierId == 4) {
            this.dummyBands = list;
        } else if (labelDic.hierId == 5) {
            this.dummyTeams = list;
        } else if (labelDic.hierId == 6) {
            this.dummyDesignations = list;
        } else if (labelDic.hierId == 7) {
            this.dummyUsers = list;
        }
    }


    getApprovalWorkflows() {
        let model = {
            "legalEntityId": this.selectedLegalEntity?.legalEntityId || this.userInfo.legalEntityId,
            "legalEntityGUID": this.selectedLegalEntity?.legalEntityGuId || this.userInfo.legalEntityGuId,
            "recordType": 1,
            "statusId": 1,
            "type": 1
        };
        this.settingService.getApprovalWorkflows(model).subscribe(res => {
            if (!res.isError) {
                this.allUIInfo.approvlFlowList = res?.payload;
            } else {
            }
        });
    }
    getFinacialWorkflows() {
        let model = {
            "legalEntityId": this.selectedLegalEntity?.legalEntityId || this.userInfo.legalEntityId,
            "legalEntityGUID": this.selectedLegalEntity?.legalEntityGuId || this.userInfo.legalEntityGuId,
            "recordType": 1,
            "statusId": 1,
            "type": 2
        };
        this.settingService.getApprovalWorkflows(model).subscribe(res => {
            if (!res.isError) {
                this.allUIInfo.finacialFlowList = res?.payload;
                //console.log(this.allUIInfo.finacialFlowList)
            } else {
            }
        });
    }

    /*api calls end */

    selectRate(rateType: any) {
        this.totalPolcyModel.defaultMillageUnit = rateType;
    }

    changeStartDate(e: any, i: any, formItem, isPerDiem?) {
        var date: any;
        if (this.platformType == 1) {
            date = this.datechangeService.convertCalendarToAPI(e.target.value);
        } else {
            // date = moment(new Date(e.detail.value)).format(Constants.mobileDateFormat);
        }
        if (isPerDiem) {
            this.rulesModel.startDate = date;
            this.rulesForm.controls.startDate.patchValue(this.convertDateToUtc(date));
        } else {
            this.totalPolcyModel.policyMileageList[i].startDate = date;
        }
    }
    selectSingleexpenses(e: any) {

    }
    selectAllexpenses() {

    }

    changePerStartDate(e: any) {
        var date: any;
        var dateStart: any;
        date = this.datechangeService.convertCalendarToAPI(e.target.value);
        this.minDate = new Date(e.target.value);
        this.totalPolcyModel.startDate = date;

        if (this.totalPolcyModel.policyPerDiemList.length > 0) {
            dateStart = this.totalPolcyModel.policyPerDiemList[0].startDate
        }

        //  new
        // this.totalPolcyForm = this.formBuilder.group({
        //         startDate: [this.convertDateToUtc(this.totalPolcyModel?.startDate)],
        //     });




        // if (this.cityStr == this.newPerDiemModel.cityName && this.countryNameStr == this.newPerDiemModel.countryName && dateStart == date) {
        //     this.toastr.error('Policy already created with same country and city with same date', 'Error');
        // this.newPerDiemModel.cityName = '';
        // this.newPerDiemModel.countryName = '';
        ///  this.newPerDiemModel.startDate = '';
        // this.newPerDiemForm

        // this.PerDiemNewForm = this.formBuilder.group({
        //     startDate: [''],
        // });

        //    } else {

        //  this.newPerDiemModel.startDate = date;
        // this.PerDiemNewForm = this.formBuilder.group({
        //     startDate: [this.convertDateToUtc(this.newPerDiemModel?.startDate)],
        //     endDate: [this.convertDateToUtc(this.newPerDiemModel?.endDate)]
        // });

        //    }


        this.minDate = new Date(e.target.value);


    }
    changePerEndDate(e: any) {

        var date: any;
        date = this.datechangeService.convertCalendarToAPI(e.target.value);
        // this.newPerDiemModel.endDate = date;
        this.totalPolcyModel.endDate = date;
        // this.minDate = new Date();
        // this.PerDiemNewForm = this.formBuilder.group({
        //     //startDate: [this.convertDateToUtc(this.newPerDiemModel?.startDate)],
        //     endDate: [this.convertDateToUtc(this.newPerDiemModel?.endDate)],
        // });



        // this.totalPolcyForm = this.formBuilder.group({
        //     endDate: [this.convertDateToUtc(this.totalPolcyModel.endDate)],
        // });
    }

    showHideToggle(i) {
        this.allUIInfo.showDotBtns = i;
    }


    onCurrencySearch(val) {
        let searchText = val.currentTarget.value.trim();
        if (this.currencytempList != null) {
            if (searchText != "") {
                let filterdata = this.currencytempList.filter((data) => {
                    if (data.currencyCode.toLowerCase().indexOf(searchText.toLowerCase()) >= 0) {
                        return data;
                    } else {
                    }
                });
                this.currencyList = filterdata;
            } else {
                this.currencyList = this.currencytempList;
            }
        }
    }

    selectCurrencyOption(value) {
    }
    selectedCurrencyValue(currnecy) {
        console.log('selected currency value :', currnecy);
        this.totalPolcyForm.controls.policyCurrency.patchValue(currnecy.currencyCode);
        this.totalPolcyModel.currencyName = currnecy.currencyCode;
        this.totalPolcyModel.currencyCode = currnecy.currencyCode;
        this.totalPolcyModel.currencyId = currnecy.currencyId;
        this.totalPolcyModel.currencyRate = currnecy.conversionRate;
        this.totalPolcyModel.currencyIcon = currnecy.currencyIcon;
        this.baseCurrencyCode = this.totalPolcyModel?.currencyCode ? this.totalPolcyModel?.currencyCode : this.userInfo?.baseCurrency.currencyCode;
    }

    onCategTabChange(e) {
        this.currentMatTab = e.index;
        this.setRulesDatasource();
    }
    setRulesDatasource() {
        if (this.currentMatTab == 0) {
            this.rulesSource = new MatTableDataSource(this.totalPolcyModel.policyCategoryRuleList);
        } else if (this.currentMatTab == 1) {
            this.rulesSource = new MatTableDataSource(this.totalPolcyModel.policyMileageRuleList);
        } else {
            this.rulesSource = new MatTableDataSource(this.totalPolcyModel.policyPerDiemList);
        }
    }

    hideMielageTabl() {
        this.hideMileage = !this.hideMileage;
    }

    selectedParentCategory(e, index) {
        if (this.totalPolcyModel.policyCategoryList.length > 1) {
            let ind = this.totalPolcyModel.policyCategoryList.findIndex((ele) => ele.categoryId == e.option.value?.categoryId);

            if (ind != -1) {
                this.totalPolcyModel.policyCategoryList[index].isExist = true;
                this.disableMode = true;
            } else {
                this.totalPolcyModel.policyCategoryList[index].isExist = false;
                this.disableMode = false;
            }
        }
        this.totalPolcyModel.policyCategoryList[index].categoryId = e.option.value?.categoryId;
        this.totalPolcyModel.policyCategoryList[index].categoryName = e.option.value?.categoryName;
    }


    // isExistingCategory(c_index?) {
    //     let e_index = this.totalPolcyModel.policyCategoryList.findIndex((ele, i) => (ele.categoryId == this.totalPolcyModel.policyCategoryList[c_index].categoryId) && (i != c_index));
    //     let current = this.totalPolcyModel.policyCategoryList[c_index];
    //     let existing = this.totalPolcyModel.policyCategoryList[e_index];
    //     let existingCat = false;
    //     let countryExist = false;
    //     let cityExist = false;

    //     if(e_index >= 0) {
    //         if(current.countryIds) {
    //             current.countryIds?.forEach(c => {
    //                 if(!countryExist) {
    //                     countryExist = existing.countryIds?.map(Number).includes(Number(c));
    //                 }
    //             });
    //         }

    //         if(current.cityIds) {
    //             current.cityIds?.forEach(c => {
    //                 if(!cityExist) {
    //                     cityExist = existing.cityIds?.map(Number).includes(Number(c));
    //                 }
    //             });
    //         }
    //         if(!current.countryIds && !current.cityIds) {
    //             existingCat = true;
    //         } else if (current.countryIds && current.cityIds) {
    //             existingCat = countryExist && cityExist ? true : false;
    //         } else if (current.countryIds && !current.cityIds) {
    //             existingCat = countryExist ? true : false;
    //         } else if (!current.countryIds && current.cityIds) {
    //             existingCat = cityExist ? true : false;
    //         }
    //     } else {
    //         existingCat = false;
    //     }
    //     this.totalPolcyModel.policyCategoryList[c_index].isExist = existingCat;
    //     this.disableMode = existingCat;
    // }

    isExistingCategory(c_index?) {
        let countryExist = false;
        let cityExist = false;
        let c_obj = this.totalPolcyModel.policyCategoryList[c_index];
        let e_list = this.totalPolcyModel.policyCategoryList.filter((ele, i) => (ele.categoryId == this.totalPolcyModel.policyCategoryList[c_index].categoryId) && (i != c_index));
        this.totalPolcyModel.policyCategoryList.forEach((e_obj, i) => {
            if (i != c_index && e_obj.categoryId == c_obj.categoryId) {
                e_obj.countryIds = e_obj.countryIds || [];
                e_obj.cityIds = e_obj.cityIds || [];
                if (!countryExist) {
                    // console.log('countryIds ___ ', e_obj.countryIds, c_obj.countryIds, (e_obj.countryIds?.length == 0) && (c_obj.countryIds?.length == 0), (e_obj.countryIds?.length == 0) && (c_obj.countryIds?.length == 0) ? true :  e_obj.countryIds?.some(c => c_obj.countryIds?.map(Number).includes(Number(c))));
                    countryExist = (e_obj.countryIds?.length == 0) && (c_obj.countryIds?.length == 0) ? true : e_obj.countryIds?.some(c => c_obj.countryIds?.map(Number).includes(Number(c)));
                }
                if (!cityExist) {
                    // console.log('cityIds ___ ', e_obj.cityIds, c_obj.cityIds, (e_obj.cityIds?.length == 0) && (c_obj.cityIds?.length == 0), (e_obj.cityIds?.length == 0) && (c_obj.cityIds?.length == 0) ? true : e_obj.cityIds?.some(c => c_obj.cityIds?.map(Number).includes(Number(c))));
                    cityExist = (e_obj.cityIds?.length == 0) && (c_obj.cityIds?.length == 0) ? true : e_obj.cityIds?.some(c => c_obj.cityIds?.map(Number).includes(Number(c)))
                }
            }
        })

        // console.log('cityExist ____ ', cityExist);
        // console.log('countryExist ____ ', countryExist);
        // console.log('Exist ___ ', countryExist && cityExist ? true : false);

        this.totalPolcyModel.policyCategoryList[c_index].isExist = countryExist && cityExist ? true : false;
        this.disableMode = countryExist && cityExist ? true : false;



        // let e_index = this.totalPolcyModel.policyCategoryList.findIndex((ele, i) => (ele.categoryId == this.totalPolcyModel.policyCategoryList[c_index].categoryId) && (i != c_index));
        // let current = this.totalPolcyModel.policyCategoryList[c_index];
        // let existing = this.totalPolcyModel.policyCategoryList[e_index];
        // let existingCat = false;
        // let countryExist = false;
        // let cityExist = false;

        // if(e_index >= 0) {
        //     if(current.countryIds) {
        //         current.countryIds?.forEach(c => {
        //             if(!countryExist) {
        //                 countryExist = existing.countryIds?.map(Number).includes(Number(c));
        //             }
        //         });
        //     }

        //     if(current.cityIds) {
        //         current.cityIds?.forEach(c => {
        //             if(!cityExist) {
        //                 cityExist = existing.cityIds?.map(Number).includes(Number(c));
        //             }
        //         });
        //     }
        //     // console.log('cityExist ____ ', cityExist);
        //     // console.log('countryExist ____ ', countryExist);

        //     if(!current.countryIds && !current.cityIds) {
        //         existingCat = true;
        //     } else if (current.countryIds && current.cityIds) {
        //         existingCat = countryExist && cityExist ? true : false;
        //     } else if (current.countryIds && !current.cityIds) {
        //         existingCat = countryExist ? true : false;
        //     } else if (!current.countryIds && current.cityIds) {
        //         existingCat = cityExist ? true : false;
        //     }
        // } else {
        //     existingCat = false;
        // }
        // // console.log('Exist ___ ', existingCat);

        // this.totalPolcyModel.policyCategoryList[c_index].isExist = existingCat;
        // this.disableMode = existingCat;
    }

    changeCatgName(e: any, index: any, item?, isPerDiemExp?) {
        if (isPerDiemExp) {
            this.rulesModel.policyPerDiemCategoryList[index].categoryId = item?.categoryId;
            this.rulesModel.policyPerDiemCategoryList[index].categoryName = item?.categoryName;
        } else {
            if (index > 0 || index == 0) {
                if (item?.categoryType == 1) {
                    this.totalPolcyModel.policyCategoryList[index].categoryId = item?.categoryId;
                    this.totalPolcyModel.policyCategoryList[index].categoryName = item?.categoryName;
                    if (this.totalPolcyModel.policyCategoryList.length > 1) {
                        let ind = this.totalPolcyModel.policyCategoryList.findIndex((ele) => ele.categoryId == item.categoryId);
                        if (ind != -1) {
                            this.isExistingCategory(index);
                            // this.totalPolcyModel.policyCategoryList[index].isExist = true;
                            // this.disableMode = true;
                        } else {
                            this.totalPolcyModel.policyCategoryList[index].isExist = false;
                            this.disableMode = false;
                        }
                    }
                    // this.totalPolcyModel.policyCategoryList[index].categoryId = item?.categoryId;
                    // this.totalPolcyModel.policyCategoryList[index].categoryName = item?.categoryName;
                    // this.categoryList = [...this.dummyCategoryList];

                } else {
                    this.totalPolcyModel.policyMileageList[index].categoryId = item?.categoryId;
                    this.totalPolcyModel.policyMileageList[index].categoryName = item?.categoryName;
                    this.totalPolcyModel.policyMileageList[index].mileageRate = item?.mileageRate;
                }

                this.showCategoryList = false;

            } else {
                this.rulesModel.categoryId = item?.categoryId;
                this.rulesModel.categoryName = item?.categoryName;
                this.categoryList = [...this.dummyCategoryList];
            }
        }
        this.claimedRulesAmt();
    }
    changeCatgNameone(e: any, index: any, item?, isPerDiemExp?) {
        // console.log("main method is called",item,isPerDiemExp);
        if (isPerDiemExp) {
            this.rulesModel.policyPerDiemCategoryList[index].categoryId = item?.categoryId;
            this.rulesModel.policyPerDiemCategoryList[index].categoryName = item?.categoryName;
        } else {
            if (index > 0 || index == 0) {
                if (item?.categoryType == 1) {
                    if (this.totalPolcyModel.policyCategoryList.length > 1) {
                        let ind = this.totalPolcyModel.policyCategoryList.findIndex((ele) => ele.categoryId == item.categoryId);
                        if (ind != -1) {
                            this.totalPolcyModel.policyCategoryList[index].isExist = true;
                            this.disableMode = true;
                        } else {
                            this.totalPolcyModel.policyCategoryList[index].isExist = false;
                            this.disableMode = false;
                        }
                    }
                    this.totalPolcyModel.policyCategoryList[index].categoryId = item?.categoryId;
                    this.totalPolcyModel.policyCategoryList[index].categoryName = item?.categoryName;
                    this.categoryList_one = [...this.dummyCategoryList_one];

                } else {
                    this.totalPolcyModel.policyMileageList[index].categoryId = item?.categoryId;
                    this.totalPolcyModel.policyMileageList[index].categoryName = item?.categoryName;
                    this.totalPolcyModel.policyMileageList[index].mileageRate = item?.mileageRate;
                }

                this.showCategoryList = false;

            } else {
                this.rulesModel.categoryId = item?.categoryId;
                this.rulesModel.categoryName = item?.categoryName;
                //this.categoryList = [...this.dummyCategoryList];
            }
        }
    }

    selectedApprovlType(e: any, item?) {
        this.totalPolcyModel.approvalWorkflowId = item?.approvalWorkflowId;
        this.totalPolcyModel.approvalWorkflowName = item?.workflowName;
    }
    selectedFinacialType(e: any, item?) {
        this.totalPolcyModel.financeApprovalWorkflowId = item?.approvalWorkflowId;
        this.totalPolcyModel.financeApprovalWorkflowName = item?.workflowName;
    }

    selectedEntityOption(e: any, i: any, subIndex: any, entity: any, hierId: any, entityListIndx?) {
        let valList = this.totalPolcyModel.entityMapList[i].hierarchyValue;
        let nameList = this.totalPolcyModel.entityMapList[i].hierarchyText;
        var list = this.allEntitysLists[(this.totalPolcyModel.entityMapList[i].hierarchyTypeId)];
        if (valList[subIndex]) {
            this.selectDeselectEntityList(i, subIndex);
        }
        (list[entityListIndx])['isSelected'] = true;
        let labelArrayIndx = this.allUIInfo.labelArray.findIndex((item) => item.hierId == hierId);
        let idKey = (this.allUIInfo.labelArray[labelArrayIndx]).idKey;
        let nameKey = (this.allUIInfo.labelArray[labelArrayIndx]).nameKey;
        valList[subIndex] = (entity[idKey]).toString();
        nameList[subIndex] = entity[nameKey];

        if (hierId == 2) {
            this.dummyCostCenter.forEach(element => {
                element.isSelected = false;
            });
            this.allEntitysLists[hierId] = [...this.dummyCostCenter];
        } else if (hierId == 3) {
            this.dummyDepartment.forEach(element => {
                element.isSelected = false;
            });
            this.allEntitysLists[hierId] = [...this.dummyDepartment];
        } else if (hierId == 4) {
            this.dummyBands.forEach(element => {
                element.isSelected = false;
            });
            this.allEntitysLists[hierId] = [...this.dummyBands];
        } else if (hierId == 5) {
            this.dummyTeams.forEach(element => {
                element.isSelected = false;
            });
            this.allEntitysLists[hierId] = [...this.dummyTeams];
        } else if (hierId == 6) {
            this.dummyDesignations.forEach(element => {
                element.isSelected = false;
            });
            this.allEntitysLists[hierId] = [...this.dummyDesignations];
        } else if (hierId == 7) {
            this.dummyUsers.forEach(element => {
                element.isSelected = false;
            });
            this.allEntitysLists[hierId] = [...this.dummyUsers];
        }
    }

    addNewSubEntity(ItemSelectTemp: TemplateRef<any>, i, type?) {
        this.globalIndex = i;
        this.filterSearch = '';
        var apiMappedList = new mappedModel();
        if (type == 'Cost Center') {
            this.getCostCenterAPi(apiMappedList);
        } else if (type == 'Departments') {
            this.getDepartmentAPi(apiMappedList);
        } else if (type == 'Teams') {
            this.getTeamsApi(apiMappedList);
        } else if (type == 'Bands') {
            this.getbandAPi(apiMappedList);
        } else if (type == 'Designations') {
            this.getDesignationsAPi(apiMappedList);
        } else if (type == 'User') {
            this.getUsersAPi(apiMappedList);
        }
        const config = {
            ignoreBackdropClick: true,
            class: "modal-md modal-dialog-centered",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(ItemSelectTemp, config);
        if (i == 5) {
            // if (this.totalPolcyModel.entityMapList[i].hierarchyText.length > 1) {
            //     console.log('pen called', this.allEntitysLists[i + 1], this.selectedDesignations);
            //     this.allEntitysLists[i + 1] = this.allEntitysLists[i + 1].filter(function(val) {
            //         return this.selectedDesignations.indexOf(val) == -1;
            //       });
            // }
            // const config = {
            //     ignoreBackdropClick: true,
            //     class: "modal-md modal-dialog-centered",
            //     keyboard: false,
            // };
            // this.modalRef = this.modalService.show(ItemSelectTemp, config);
        } else {
            // this.totalPolcyModel.entityMapList[i].hierarchyValue.push('');
            // this.totalPolcyModel.entityMapList[i].hierarchyText.push('');
            // let hierID = (i + 1).toString();
            // this.addSubEntityForm('', hierID);
        }
    }

    deleteSubEntity(entityIndx: any, index: any) {
        this.selectDeselectEntityList(entityIndx, index);
        this.totalPolcyModel.entityMapList[entityIndx].hierarchyValue.splice(index, 1);
        this.totalPolcyModel.entityMapList[entityIndx].hierarchyText.splice(index, 1);
        let hierID = (entityIndx + 1).toString();
        (this.entityMapForm.get(hierID) as FormArray).removeAt(index);
    }

    selectDeselectEntityList(entityIndex, index) {
        let hierId = (this.totalPolcyModel.entityMapList[entityIndex]).hierarchyTypeId;
        var list = this.allEntitysLists[hierId];
        let currentID = JSON.parse(JSON.stringify((this.totalPolcyModel.entityMapList[entityIndex].hierarchyValue)[index]));
        var idIndx = -1;

        let labelArrayIndx = this.allUIInfo.labelArray.findIndex((item) => item.hierId == hierId);
        let idKey = (this.allUIInfo.labelArray[labelArrayIndx]).idKey;
        idIndx = list.findIndex((item) => item[idKey] == currentID);

        if (idIndx > -1) {
            (list[idIndx])['isSelected'] = false;
        }
    }

    changedEntityToggle(e: any, index: any, id?) {
        this.totalPolcyModel.entityMapList[index].isActive = e?.detail.checked;
    }
    /*----Design view starts here---*/
    changeStage(isNext: Boolean) {
        if (this.currentStage == 0) {
            this.allUIInfo.policySubmitted = true;
            if (!this.totalPolcyForm.valid) {
                return;
            }
            this.allUIInfo.policySubmitted = false;
        }
        if (this.currentStage == 1) {
            if (!this.totalPolcyForm.valid) {
                if (this.totalPolcyForm.controls.expenseAmountLimit.status == 'INVALID' ||
                    this.totalPolcyForm.controls.receiptRequiredLimit.status == 'INVALID') {
                    return;
                }
            }

            var hasError = false;
            if (!this.categoryPolicyListForms.valid) {
                for (let item of (this.categoryPolicyListForms as FormArray).controls) {
                    if (item.status == "INVALID") {
                        if ((item as FormGroup).controls.expenseAmountLimit.status == "INVALID") {
                            hasError = true;
                        }
                        if ((item as FormGroup).controls.receiptRequiredLimit.status == "INVALID") {
                            hasError = true;
                        }
                        if (hasError) {
                            break;
                        }
                    }
                }
            }

            if (hasError) {
                return;
            }
        }

        if (isNext) {
            if (this.currentStage == 2) {
                //call api
            } else {
                this.currentStage = this.currentStage + 1;
            }

            if (this.currentStage == 1) {
                this.navigateToDesignView();
            } else if (this.currentStage == 2) {
                this.navigateToPublishView();
            }
        } else {
            this.currentStage = this.currentStage - 1;
            if (this.currentStage == 0) {
                this.backToCreateView();
            } else if (this.currentStage == 1) {
                this.backToDesignView();
            }
        }

        if (this.currentStage == 1) {
            this.currentMatTab = 0;
            this.setRulesDatasource();
        }
    }
    navigateToDesignView() {
        this.createView = false;
        this.designView = true;
        this.publishView = false;
    }

    navigateToPublishView() {
        this.createView = false;
        this.designView = false;
        this.publishView = true;
    }

    backToCreateView() {
        this.createView = true;
        this.designView = false;
        this.publishView = false;
    }

    backToDesignView() {
        this.createView = false;
        this.designView = true;
        this.publishView = false;
    }

    /*----Design view ends here---*/

    /*dummy functions */
    applyFilter(filterValue: string) {
        this.rulesSource.filter = filterValue.trim().toLowerCase();
        // if (this.dataSource.paginator) {
        //   this.dataSource.paginator.firstPage();
        // }
    }

    applyFiltercategories(e) {
        this.dataSource.filter = e.trim().toLowerCase();
        this.totalPolcyModel.policyCategoryList = [];
        this.totalPolcyModel.policyCategoryList = [...this.dataSource.filteredData]

    }
    applyFiltermileage(e) {
        this.mileageSource.filter = e.trim().toLowerCase();
        this.totalPolcyModel.policyMileageList = [];
        this.totalPolcyModel.policyMileageList = [...this.mileageSource.filteredData]
    }

    /*dummy functions */
    handlePage(e) {

    }

    navigateToAddExpense(t: any) {

    }

    hideDropdown() {
    }


    toggleStatusDropdown(t: any) {

    }

    toggleStatus(t, g) {

    }


    categoryListPageLimit: number[] = [5, 10, 25, 100];

    deleteRule(index: any) {
        if (this.currentMatTab == 0) {
            this.totalPolcyModel.policyCategoryRuleList.splice(index, 1);
        } else if (this.currentMatTab == 1) {
            this.totalPolcyModel.policyMileageRuleList.splice(index, 1);
        } else {
            this.totalPolcyModel.policyPerDiemList.splice(index, 1);
        }
        this.allUIInfo.showDotBtns = -1;
        this.setRulesDatasource();
    }

    disablePerDiem(index: any) {
        if (this.totalPolcyModel.policyPerDiemList[index].statusId && (this.totalPolcyModel.policyPerDiemList[index].statusId == 1)) {
            this.totalPolcyModel.policyPerDiemList[index].statusId = 2;
        } else {
            this.totalPolcyModel.policyPerDiemList[index].statusId = 1;
        }
        this.allUIInfo.showDotBtns = -1;
        this.setRulesDatasource();
    }

    editRuleForm(EditGenralForm: TemplateRef<any>, index: any) {
        this.categoryList.splice(0, 0, this.default_category);
        if (this.totalPolcyModel.policyCategoryRuleList.length != 0) {
            var groupped = _.groupBy(this.totalPolcyModel.policyCategoryRuleList, function (n) { return n.categoryName });
            var result = _.uniq(_.flatten(_.filter(groupped, function (n) { return n.length > 1 })));
            this.categoryList.forEach(element => {
                element.isSelected = false;
            });
            if (result) {
                result.forEach((ele, index) => {
                    this.categoryList.forEach(element => {
                        if (ele.categoryName == element.categoryName) {
                            element.isSelected = true;
                        }
                    });
                });
            }

        }
        // this.categoryList.unshift(this.default_category);
        this.allUIInfo.currentRuleIndex = index;
        this.rulesModel = new polyRulesModel();
        if (index == -1) {//if index is -1 then it is add rule
            if (this.currentMatTab == 0) {
                this.rulesModel.limitType = 1;
            } else {
                this.rulesModel.limitType = 2;
            }
        } else {
            if (this.currentMatTab == 0) {
                this.rulesModel = JSON.parse(JSON.stringify(this.totalPolcyModel.policyCategoryRuleList[index]));
            } else {
                this.rulesModel = JSON.parse(JSON.stringify(this.totalPolcyModel.policyMileageRuleList[index]));
            }
        }

        var limitType = '';
        if (this.rulesModel?.limitType == 1) {
            limitType = 'Expense Count';
        } else {
            limitType = 'Mileage Limit';
        }
        this.rulesForm = this.formBuilder.group({
            id: [this.rulesModel?.id],
            ruleName: [this.rulesModel?.ruleName, Validators.required],
            ruleType: [this.rulesModel?.ruleType, Validators.required],
            categoryId: [this.rulesModel?.categoryId],
            categoryName: [this.rulesModel?.categoryName],
            limitValue: [this.rulesModel?.limitValue, Validators.required],
            limitType: [limitType, Validators.required],
            violationType: [this.rulesModel?.violationType],
            cityIds: [this.rulesModel?.cityIds],
            cityNames: [this.rulesModel?.cityNames],
            countryIds: [this.rulesModel?.countryIds],
            countryNames: [this.rulesModel?.countryNames],
            cityGroupId: [this.rulesModel?.cityGroupId || null],
            cityGroupName: [this.rulesModel?.cityGroupName || null],
        });

        const config = {
            ignoreBackdropClick: true,
            class: "modal-lg modal-dialog-centered",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(EditGenralForm, config);
        this.allUIInfo.showDotBtns = -1;
    }

    cancelRuleAction() {
        this.categoryList = this.categoryList.slice(1);
        this.modalRef.hide();
    }


    isExistingRule(c_obj, e_list) {
        let countryExist = false;
        let cityExist = false;
        // let c_obj = this.totalPolcyModel.policyCategoryList[c_index];
        // let e_list = this.totalPolcyModel.policyCategoryList.filter((ele, i) => (ele.categoryId == this.totalPolcyModel.policyCategoryList[c_index].categoryId) && (i != c_index));
        // console.log('current ____ ', c_obj);
        // console.log('existing list ____ ', e_list);

        this.totalPolcyModel.policyCategoryRuleList.forEach((e_obj, i) => {
            if (e_obj.categoryId == c_obj.categoryId) {
                if (!countryExist) {
                    // console.log('countryIds ___ ', e_obj.countryIds, c_obj.countryIds, (e_obj.countryIds?.length == 0) && (c_obj.countryIds?.length == 0), (e_obj.countryIds?.length == 0) && (c_obj.countryIds?.length == 0) ? true :  e_obj.countryIds?.some(c => c_obj.countryIds?.map(Number).includes(Number(c))));
                    countryExist = (e_obj.countryIds?.length == 0) && (c_obj.countryIds?.length == 0) ? true : e_obj.countryIds?.some(c => c_obj.countryIds?.map(Number).includes(Number(c)));
                }
                if (!cityExist) {
                    // console.log('cityIds ___ ', e_obj.cityIds, c_obj.cityIds, (e_obj.cityIds?.length == 0) && (c_obj.cityIds?.length == 0), (e_obj.cityIds?.length == 0) && (c_obj.cityIds?.length == 0) ? true : e_obj.cityIds?.some(c => c_obj.cityIds?.map(Number).includes(Number(c))));
                    cityExist = (e_obj.cityIds?.length == 0) && (c_obj.cityIds?.length == 0) ? true : e_obj.cityIds?.some(c => c_obj.cityIds?.map(Number).includes(Number(c)))
                }
            }
        })

        // console.log('cityExist ____ ', cityExist);
        // console.log('countryExist ____ ', countryExist);
        // console.log('Exist ___ ', countryExist && cityExist ? true : false);

        return countryExist && cityExist ? true : false;

        // this.totalPolcyModel.policyCategoryList[c_index].isExist = countryExist && cityExist ? true : false;
        // this.disableMode = countryExist && cityExist ? true : false;
    }

    SaveRuleAction() {
        this.categoryList = this.categoryList.slice(1);
        this.allUIInfo.rulesSubmitted = true;
        if (!this.rulesForm.valid) {
            return;
        }
        this.allUIInfo.rulesSubmitted = false;
        this.rulesModel.limitValue = Number(this.rulesModel.limitValue);
        if (this.currentMatTab == 0) {
            if (this.allUIInfo.currentRuleIndex == -1) {//adding new rule if index = -1
                if (this.rulesModel.categoryId == null) {
                    this.rulesModel.isDefault = true;
                } else {
                    this.rulesModel.isDefault = false;
                }

                let lengthFind = this.totalPolcyModel.policyCategoryRuleList?.filter((element) => { return element.categoryId == this.rulesModel.categoryId });
                if (lengthFind && lengthFind.length == 0) {
                    this.totalPolcyModel.policyCategoryRuleList.push(this.rulesModel);
                } else if (lengthFind && lengthFind.length >= 1) {
                    let lengthFIND = this.totalPolcyModel.policyCategoryRuleList?.filter((element) => { return element.categoryId == this.rulesModel.categoryId && element.limitType == this.rulesModel.limitType });
                    if (lengthFIND.length == 0) {
                        this.totalPolcyModel.policyCategoryRuleList.push(this.rulesModel);
                        const result = _.findIndex(this.categoryList, (element) => element.categoryName === this.rulesModel.categoryName);
                        if (result >= 0) {
                            this.categoryList[result].isSelected = true;
                        }
                    } else {
                        if (this.isExistingRule(this.rulesModel, lengthFIND)) {
                            this.toastr.error('Error', 'Already Exist');
                        } else {
                            this.totalPolcyModel.policyCategoryRuleList.push(this.rulesModel);
                            const result = _.findIndex(this.categoryList, (element) => element.categoryName === this.rulesModel.categoryName);
                            if (result >= 0) {
                                this.categoryList[result].isSelected = true;
                            }
                        }



                        // let existingCat = false;
                        // let cityExist = false;
                        // let countryExist = false;
                        // let current = this.rulesModel
                        // let existing = lengthFIND.length > 0 ? lengthFIND[0] : null;

                        // if(current.countryIds) {
                        //     current.countryIds?.forEach(c => {
                        //         if(!countryExist) {
                        //             countryExist = existing.countryIds?.map(Number).includes(c);
                        //         }
                        //     });
                        // }
                        // if(current.cityIds) {
                        //     current.cityIds?.forEach(c => {
                        //         if(!cityExist) {
                        //             cityExist = existing.cityIds?.map(Number).includes(c);
                        //         }
                        //     });
                        // }
                        // if(!current.countryIds && !current.cityIds) {
                        //     existingCat = true;
                        // } else if (current.countryIds && current.cityIds) {
                        //     existingCat = countryExist && cityExist ? true : false;
                        // } else if (current.countryIds && !current.cityIds) {
                        //     existingCat = countryExist ? true : false;
                        // } else if (!current.countryIds && current.cityIds) {
                        //     existingCat = cityExist ? true : false;
                        // }

                        // if(existingCat) {
                        //     this.toastr.error('Error', 'Already Exist');
                        // } else {
                        //     this.totalPolcyModel.policyCategoryRuleList.push(this.rulesModel);
                        //     const result = _.findIndex(this.categoryList, (element) => element.categoryName === this.rulesModel.categoryName);
                        //     if(result >= 0) {
                        //         this.categoryList[result].isSelected = true;
                        //     }
                        // }
                    }
                }
            } else {
                // let count = 0;
                // this.totalPolcyModel.policyCategoryRuleList?.forEach((element, index) => {
                //     if (element.categoryId == this.rulesModel.categoryId && element.limitType == this.rulesModel.limitType && index != this.allUIInfo.currentRuleIndex) {
                //         count++;
                //     }
                // });
                // //  let lengthFIND = this.totalPolcyModel.policyCategoryRuleList?.filter((element, index) => { return element.categoryId == this.rulesModel.categoryId && element.limitType == this.rulesModel.limitType && index != this.allUIInfo.currentRuleIndex });
                // if (count == 0) {
                this.totalPolcyModel.policyCategoryRuleList[this.allUIInfo.currentRuleIndex] = this.rulesModel;
                this.toastr.success('Success', 'Updated successfully');
                // } else {
                //     this.toastr.error('Error', 'Already Exist');
                // }
            }
        } else {
            if (this.allUIInfo.currentRuleIndex == -1) {
                this.totalPolcyModel.policyMileageRuleList.push(this.rulesModel);
            } else {
                this.totalPolcyModel.policyMileageRuleList[this.allUIInfo.currentRuleIndex] = this.rulesModel;
            }
        }

        this.allUIInfo.showDotBtns = -1;
        this.setRulesDatasource();
        this.modalRef.hide();
    }


    openCriteriaPop(AddCriteria: TemplateRef<any>, type) {

        this.submitCriteria = false;
        this.showClickable = type;
        console.log('clickable :', this.showClickable);
        const config = {
            ignoreBackdropClick: true,
            class: "modal-xl modal-dialog-centered",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(AddCriteria, config);
    }

    numberValidation(event: any) {
        const pattern = /[0-9.,]/;
        let inputChar = String.fromCharCode(event.charCode);

        if (!pattern.test(inputChar)) {
            // invalid character, prevent input
            event.preventDefault();
        }
    }

    /* per diem start*/
    cityList = ['Hyderabad', 'Chennai', 'Mumbai', "Delhi"];

    changePerDiemSplit() {
        this.rulesModel.isPerDiemSplit = !this.rulesModel.isPerDiemSplit;
        if (this.rulesModel.policyPerDiemCategoryList.length == 0) {
            this.addNewDiemExpenseItem();
        }
    }
    // with reciet
    changePerDiemWithReciet() {
        this.newPerDiemModel.isAllowedWithReceipt = !this.newPerDiemModel.isAllowedWithReceipt;
    }
    changePerDay() {
        this.newPerDiemModel.perDayWithReceipt = !this.newPerDiemModel.perDayWithReceipt;
    }
    changePerHalfDay() {
        this.newPerDiemModel.perHalfDayWithReceipt = !this.newPerDiemModel.perHalfDayWithReceipt;
    }
    changePerHour() {
        this.newPerDiemModel.perHourWithReceipt = !this.newPerDiemModel.perHourWithReceipt;
    }
    // without reciet
    changePerDiemWithoutReciet() {
        this.newPerDiemModel.isAllowedWithoutReceipt = !this.newPerDiemModel.isAllowedWithoutReceipt;
    }
    changePerDayWithout() {
        this.newPerDiemModel.perDayWithoutReceipt = !this.newPerDiemModel.perDayWithoutReceipt;
    }
    changePerHalfDayWithout() {
        this.newPerDiemModel.perHalfDayWithoutReceipt = !this.newPerDiemModel.perHalfDayWithoutReceipt;
    }
    changePerHourWithout() {
        this.newPerDiemModel.perHourWithoutReceipt = !this.newPerDiemModel.perHourWithoutReceipt;
    }
    addMore() {
        this.addNewDiemExpenseItem();
    }

    removeDmExpenseItem(i: any) {
        this.rulesModel.policyPerDiemCategoryList.splice(i, 1);
        this.perDiemExpensesForms.removeAt(i);
    }

    editPerDiemForm(EditGenralForm: TemplateRef<any>, index: any, perDiemType?) {
        this.allUIInfo.currentRuleIndex = index;
        this.rulesModel = new polyPerDiemModel();
        this.rulesModel.policyPerDiemCategoryList = [];
        this.showCities = false;

        while (this.perDiemExpensesForms.length) {
            this.perDiemExpensesForms.removeAt(0);
        }

        if (index != -1) {//if index is -1 then it is add per Diem
            this.rulesModel = JSON.parse(JSON.stringify(this.totalPolcyModel.policyPerDiemList[index]));
            if (this.rulesModel.policyPerDiemCategoryList.length > 0) {
                this.rulesModel.policyPerDiemCategoryList.forEach(ele => {
                    this.addNewDiemExpenseItem(ele);
                });
            }
        } else {//---new per diem
            this.rulesModel.statusId = 1;
            this.rulesModel.perDiemType = perDiemType;
        }

        // if (this.rulesModel.perDiemType == 2) {
        //     this.rulesForm = this.formBuilder.group({
        //         countryName: [this.rulesModel?.countryName, Validators.required],
        //         cityName: [this.rulesModel?.cityName],
        //         currencyCode: [this.rulesModel?.currencyCode, Validators.required],
        //         displayName: [this.rulesModel?.displayName],
        //         startDate: [this.convertDateToUtc(this.rulesModel?.startDate)],
        //         expenseAmountLimit: [this.rulesModel?.expenseAmountLimit, Validators.required],
        //         receiptRequiredLimit: [this.rulesModel?.receiptRequiredLimit],
        //         isPerDiemSplit: [this.rulesModel?.isPerDiemSplit],
        //     });
        // } else {
        //     this.rulesForm = this.formBuilder.group({
        //         currencyCode: [this.rulesModel?.currencyCode, Validators.required],
        //         displayName: [this.rulesModel?.displayName],
        //         startDate: [this.convertDateToUtc(this.rulesModel?.startDate)],
        //         expenseAmountLimit: [this.rulesModel?.expenseAmountLimit, Validators.required],
        //         receiptRequiredLimit: [this.rulesModel?.receiptRequiredLimit],
        //         isPerDiemSplit: [this.rulesModel?.isPerDiemSplit],
        //     });
        // }

        this.PerDiemNewForm = this.formBuilder.group({
            countryName: [this.newPerDiemModel?.countryName, Validators.required],
            cityName: [this.newPerDiemModel?.cityName],
            currencyCode: [this.newPerDiemModel?.currencyCode, Validators.required],
            // startDate: [this.convertDateToUtc(this.newPerDiemModel?.startDate)],
            // endDate: [this.convertDateToUtc(this.newPerDiemModel?.endDate)],

        });

        const config = {
            ignoreBackdropClick: true,
            class: "modal-md modal-dialog-centered",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(EditGenralForm, config);
        this.allUIInfo.showDotBtns = -1;
    }
    deletePerdiemCall(index, perdiem) {
        this.newPerDiemModel = new newPerDiemForm();
        this.totalPolcyModel.policyPerDiemList.forEach(elm => {
            if (perdiem.perDiemId == elm.perDiemId) {
                this.totalPolcyModel.policyPerDiemList.splice(index, 1);
            }
        });
    }
    showDropDownCall(obj) {
        // this.totalPolcyModel.policyPerDiemList.forEach(element => {
        //     element.dropDownStr = false;
        //     if (obj.perDiemId == element.perDiemId) {
        //         element.dropDownStr = !element.dropDownStr;
        //     }
        // });
        this.totalPolcyModel.policyPerDiemList.forEach(element => {
            element.dropDownStr = false;
            if (obj.perDiemId) {
                if (obj.perDiemId == element.perDiemId) {
                    element.dropDownStr = true
                }
                else {
                    element.dropDownStr = false;
                }
            } else {
                obj.dropDownStr = true
            }
        });
    }
    onClickOutsidePerdiem() {
        this.totalPolcyModel.policyPerDiemList.forEach(element => {
            element.dropDownStr = false;
        })
    }


    // ONE
    // newPerDiemModel: any = {
    //     countryId: '',
    //     countryName: '',
    //     cityIds: '',
    //     cityNames: '',
    //     with: {
    //         allow: false,
    //         perDay: false,
    //         perHour: false,
    //         perDayCurreny: '',
    //         perDayRate: '',
    //         perHourCurrency: '',
    //         perHourRate: '',
    //         conditions: []
    //     },
    //     without: {
    //         allow: false,
    //         perDay: false,
    //         perHour: false,
    //         perDayCurreny: '',
    //         perDayRate: '',
    //         perHourCurrency: '',
    //         perHourRate: '',
    //         conditions: []
    //     }
    // }
    addWithRcptCndtns() {
        this.withRcptCndts = [
            { duration: 24, conditionType: '', value: '', perDiemType: '' },
            { duration: 23, conditionType: '', value: '', perDiemType: '' },
            { duration: 22, conditionType: '', value: '', perDiemType: '' },
            { duration: 21, conditionType: '', value: '', perDiemType: '' },
            { duration: 20, conditionType: '', value: '', perDiemType: '' },
            { duration: 19, conditionType: '', value: '', perDiemType: '' },
            { duration: 18, conditionType: '', value: '', perDiemType: '' },
            { duration: 17, conditionType: '', value: '', perDiemType: '' },
            { duration: 16, conditionType: '', value: '', perDiemType: '' },
            { duration: 15, conditionType: '', value: '', perDiemType: '' },
            { duration: 14, conditionType: '', value: '', perDiemType: '' },
            { duration: 13, conditionType: '', value: '', perDiemType: '' },
            { duration: 12, conditionType: '', value: '', perDiemType: '' },
            { duration: 11, conditionType: '', value: '', perDiemType: '' },
            { duration: 10, conditionType: '', value: '', perDiemType: '' },
            { duration: 9, conditionType: '', value: '', perDiemType: '' },
            { duration: 8, conditionType: '', value: '', perDiemType: '' },
            { duration: 7, conditionType: '', value: '', perDiemType: '' },
            { duration: 6, conditionType: '', value: '', perDiemType: '' },
            { duration: 5, conditionType: '', value: '', perDiemType: '' },
            { duration: 4, conditionType: '', value: '', perDiemType: '' },
            { duration: 3, conditionType: '', value: '', perDiemType: '' },
            { duration: 2, conditionType: '', value: '', perDiemType: '' },
            { duration: 1, conditionType: '', value: '', perDiemType: '' },
            { duration: 0, conditionType: '', value: '', perDiemType: '' },
        ]
    }

    addWithoutRcptCndtns() {
        this.withoutRcptCndts = [
            { duration: 24, conditionType: '', value: '', perDiemType: '' },
            { duration: 23, conditionType: '', value: '', perDiemType: '' },
            { duration: 22, conditionType: '', value: '', perDiemType: '' },
            { duration: 21, conditionType: '', value: '', perDiemType: '' },
            { duration: 20, conditionType: '', value: '', perDiemType: '' },
            { duration: 19, conditionType: '', value: '', perDiemType: '' },
            { duration: 18, conditionType: '', value: '', perDiemType: '' },
            { duration: 17, conditionType: '', value: '', perDiemType: '' },
            { duration: 16, conditionType: '', value: '', perDiemType: '' },
            { duration: 15, conditionType: '', value: '', perDiemType: '' },
            { duration: 14, conditionType: '', value: '', perDiemType: '' },
            { duration: 13, conditionType: '', value: '', perDiemType: '' },
            { duration: 12, conditionType: '', value: '', perDiemType: '' },
            { duration: 11, conditionType: '', value: '', perDiemType: '' },
            { duration: 10, conditionType: '', value: '', perDiemType: '' },
            { duration: 9, conditionType: '', value: '', perDiemType: '' },
            { duration: 8, conditionType: '', value: '', perDiemType: '' },
            { duration: 7, conditionType: '', value: '', perDiemType: '' },
            { duration: 6, conditionType: '', value: '', perDiemType: '' },
            { duration: 5, conditionType: '', value: '', perDiemType: '' },
            { duration: 4, conditionType: '', value: '', perDiemType: '' },
            { duration: 3, conditionType: '', value: '', perDiemType: '' },
            { duration: 2, conditionType: '', value: '', perDiemType: '' },
            { duration: 1, conditionType: '', value: '', perDiemType: '' },
            { duration: 0, conditionType: '', value: '', perDiemType: '' },
        ]
    }


    getCitiesList(ev) {
        console.log(ev);
        this.newPerDiemModel.countryId = ev?.map(e => `${e.countryId}`) || [];
        this.newPerDiemModel.countryName = ev?.map(e => `${e.countryName}`) || [];

        // console.log(this.newPerDiemModel.countryId);
        // console.log(this.newPerDiemModel.countryName);

        // this.newPerDiemModel.cityId = [13718, 6290];
        // this.newPerDiemModel.cityName = ['Port Blair', 'North and Middle Andaman'];
        let obj = {
            countryIds: ev?.map(e => `${e.countryId}`).join(',')
        };
        // console.log(this.newPerDiemModel.countryName);
        this.settingService.getCities(obj).subscribe((res: any) => {
            this.citiesList = res.payload || [];
        })
    }
    // const cityIds = ev.map(item => `${item.cityId}`).join(', ');
    // const cityNames = ev.map(item => `${item.cityName}`).join(', ');

    pdRateEditIndex = -1;
    newPerDiemForm(EditGenralForm: TemplateRef<any>, index: any, perDiemType?, obj?, editIndex?) {
        console.log(index);
        this.pdRateEditIndex = editIndex;

        // this.newPerDiemModel.with.perDay = true;
        // this.newPerDiemModel.with.perHour = true;
        // this.newPerDiemModel.with.perDayCurreny = 'INR';
        // this.newPerDiemModel.with.perDayRate = 20;
        // this.newPerDiemModel.with.perHourCurrency = 'USD';
        // this.newPerDiemModel.with.perHourRate = 10;
        // this.newPerDiemModel.with.conditions = this.withRcptCndts;

        // console.log(this.newPerDiemModel);

        this.newPerDiemModel = new newPerDiemForm();
        // this.addRcptCndtns();


        if (index == 1) {
            console.log(obj);
            this.getCitiesList([{
                countryId: obj.countryId,
                countryName: obj.countryName
            }]);
            // this.setCitiesData([{
            //     cityId: obj.countryId,
            //     countryName: obj.countryName
            // }])

            this.seperateStr = 'edit';
            this.editPerdiemObj = { ...obj };
            this.newPerDiemModel = { ...obj };
            // this.newPerDiemModel = obj;
            this.newPerDiemModel.cityId = obj?.cityId?.map(e => `${e}`) || [];
            this.newPerDiemModel.cityName = obj?.cityName?.map(e => `${e}`) || [];

            this.withRcptCndts = obj?.perDiemConditions || [];
            this.withoutRcptCndts = obj?.withoutRecieptDiemConditions || [];

            // console.log(this.newPerDiemModel.withRecieptDiemConditions);
            // console.log(this.newPerDiemModel.withoutRecieptDiemConditions);

            if (this.newPerDiemModel.perDiemConditions?.length == 0) {
                this.addWithRcptCndtns();
            }
            if (this.newPerDiemModel.withoutRecieptDiemConditions?.length == 0) {
                this.addWithoutRcptCndtns();
            }

            // console.log('cityId ____ ', this.newPerDiemModel.cityId);
            // console.log('cityName ____ ', this.newPerDiemModel.cityName);

            this.PerDiemNewForm = this.formBuilder.group({
                countryName: [obj?.countryName, Validators.required],
                cityName: [obj?.cityName],
                currencyCode: [obj?.currencyCode, Validators.required],
                startDate: [this.convertDateToUtc(obj?.startDate)],
                endDate: [this.convertDateToUtc(obj?.endDate)],
            });

        } else if (index == -2) {
            this.addWithRcptCndtns();
            this.addWithoutRcptCndtns();
            this.seperateStr = 'new';
            this.PerDiemNewForm = this.formBuilder.group({
                countryName: ['', Validators.required],
                cityName: [''],
                currencyCode: [this.newPerDiemModel?.currencyCode, Validators.required],
                startDate: [''],
                endDate: [''],
            });
        } else {
            this.addWithRcptCndtns();
            this.addWithoutRcptCndtns();
            this.seperateStr = 'new';
            this.newPerDiemModel = new newPerDiemForm();
            this.PerDiemNewForm = this.formBuilder.group({
                countryName: ['', Validators.required],
                cityName: [''],
                currencyCode: [this.newPerDiemModel?.currencyCode, Validators.required],
                startDate: [''],
                endDate: [''],
            });
        }
        this.allUIInfo.currentRuleIndex = index;
        this.rulesModel = new polyPerDiemModel();
        this.rulesModel.policyPerDiemCategoryList = [];
        this.showCities = false;

        // while (this.perDiemExpensesForms.length) {
        //     this.perDiemExpensesForms.removeAt(0);
        // }

        // if (index != -1) {//if index is -1 then it is add per Diem
        //     this.rulesModel = JSON.parse(JSON.stringify(this.totalPolcyModel.policyPerDiemList[index]));
        //     if (this.rulesModel.policyPerDiemCategoryList.length > 0) {
        //         this.rulesModel.policyPerDiemCategoryList.forEach(ele => {
        //             this.addNewDiemExpenseItem(ele);
        //         });
        //     }
        // } else {//---new per diem
        //     this.rulesModel.statusId = 1;
        //     this.rulesModel.perDiemType = perDiemType;
        // }

        // if (this.rulesModel.perDiemType == 2) {
        //     this.rulesForm = this.formBuilder.group({
        //         countryName: [this.rulesModel?.countryName, Validators.required],
        //         cityName: [this.rulesModel?.cityName],
        //         currencyCode: [this.rulesModel?.currencyCode, Validators.required],
        //         displayName: [this.rulesModel?.displayName],
        //         startDate: [this.convertDateToUtc(this.rulesModel?.startDate)],
        //         expenseAmountLimit: [this.rulesModel?.expenseAmountLimit, Validators.required],
        //         receiptRequiredLimit: [this.rulesModel?.receiptRequiredLimit],
        //         isPerDiemSplit: [this.rulesModel?.isPerDiemSplit],
        //     });
        // } else {
        //     this.rulesForm = this.formBuilder.group({
        //         currencyCode: [this.rulesModel?.currencyCode, Validators.required],
        //         displayName: [this.rulesModel?.displayName],
        //         startDate: [this.convertDateToUtc(this.rulesModel?.startDate)],
        //         expenseAmountLimit: [this.rulesModel?.expenseAmountLimit, Validators.required],
        //         receiptRequiredLimit: [this.rulesModel?.receiptRequiredLimit],
        //         isPerDiemSplit: [this.rulesModel?.isPerDiemSplit],
        //     });
        // }

        const config = {
            ignoreBackdropClick: true,
            class: "modal-xl modal-dialog-centered",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(EditGenralForm, config);
        this.allUIInfo.showDotBtns = -1;
    }

    dismissPerDiem() {
        this.modalRef.hide();
    }
    isPerdiemEnable(e) {
        this.isPerDiemEnabled = e.detail.checked;
        this.totalPolcyModel.isPerDiemEnabled = this.isPerDiemEnabled;
    }

    currencyOption(event, value) {
        if (value == 'w1') {
            this.newPerDiemModel.perDayCurrencyId = event.currencyId;
            this.newPerDiemModel.perDayCurrencyCode = event.currencyCode;
            this.newPerDiemModel.perDayCurrencyIcon = event.currencyIcon;
        } else if (value == 'w3') {
            this.newPerDiemModel.perHourCurrencyId = event.currencyId;
            this.newPerDiemModel.perHourCurrencyCode = event.currencyCode;
            this.newPerDiemModel.perHourCurrencyIcon = event.currencyIcon;
        } else if (value == 'w4') {
            this.newPerDiemModel.nextDayCurrencyId = event.currencyId;
            this.newPerDiemModel.nextDayCurrencyCode = event.currencyCode;
            this.newPerDiemModel.nextDayCurrencyIcon = event.currencyIcon;
        }
        //  else if (value == '1') {
        //     this.newPerDiemModel.perDayCurrencyIdWithoutReceipt = event.currencyId;
        //     this.newPerDiemModel.perDayCurrencyIdWithoutReceiptCode = event.currencyCode;
        //     this.newPerDiemModel.perDayCurrencyIdWithoutReceiptIcon = event.currencyIcon;
        // } else if (value == '2') {
        //     this.newPerDiemModel.perHalfDayCurrencyIdWithoutReceipt = event.currencyId;
        //     this.newPerDiemModel.perHalfDayCurrencyIdWithoutReceiptCode = event.currencyCode;
        //     this.newPerDiemModel.perHalfDayCurrencyIdWithoutReceiptIcon = event.currencyIcon;
        // } else if (value == '3') {
        //     this.newPerDiemModel.perHourCurrencyIdWithoutReceipt = event.currencyId;
        //     this.newPerDiemModel.perHourCurrencyIdWithoutReceiptCode = event.currencyCode;
        //     this.newPerDiemModel.perHourCurrencyIdWithoutReceiptIcon = event.currencyIcon;
        // } else if (value == '4') {
        //     this.newPerDiemModel.nextDayCurrencyIdWithoutReceipt = event.currencyId;
        //     this.newPerDiemModel.nextDayCurrencyIdWithoutReceiptCode = event.currencyCode;
        //     this.newPerDiemModel.nextDayCurrencyIdWithoutReceiptIcon = event.currencyIcon;
        // }
    }

    savePerDiem() {

        this.totalPolcyModel.policyPerDiemList.push(this.newPerDiemModel);
        this.modalRef.hide();
    }

    // savePerDiem() {
    //     this.allUIInfo.rulesSubmitted = true;
    //     if (!this.rulesForm.valid) {
    //         if (this.rulesModel.perDiemType == 1) {
    //             if (this.rulesForm.controls.currencyCode.status == 'INVALID'
    //                 || this.rulesForm.controls.expenseAmountLimit.status == 'INVALID') {
    //                 return;
    //             }
    //         } else {
    //             if (this.rulesForm.controls.countryName.status == 'INVALID'
    //                 || this.rulesForm.controls.currencyCode.status == 'INVALID'
    //                 || this.rulesForm.controls.expenseAmountLimit.status == 'INVALID') {
    //                 return;
    //             }
    //         }
    //     }
    //     this.allUIInfo.rulesSubmitted = false;
    //     this.rulesModel.expenseAmountLimit = Number(this.rulesModel.expenseAmountLimit);
    //     this.rulesModel.receiptRequiredLimit = Number(this.rulesModel.receiptRequiredLimit);
    //     this.rulesModel.policyPerDiemCategoryList.forEach(exp => {
    //         exp.expenseAmountLimit = Number(exp.expenseAmountLimit);
    //     });
    //     if (this.allUIInfo.currentRuleIndex == -1) {//adding new rule if index = -1
    //         this.totalPolcyModel.policyPerDiemList.push(this.rulesModel);
    //     } else {
    //         this.totalPolcyModel.policyPerDiemList[this.allUIInfo.currentRuleIndex] = this.rulesModel;
    //     }

    //     this.allUIInfo.showDotBtns = -1;
    //     this.setRulesDatasource();
    //     this.modalRef.hide();
    // }

    categoryAndMerchantModel = new CategoryAndMerchantModel();
    countryList: any;
    citiesList: any;
    currencytempList: any;

    getCurrencylist() {
        this.categoryAndMerchantModel.legalEntityId = this.selectedLegalEntity?.legalEntityId || this.userInfo.legalEntityId;
        this.categoryAndMerchantModel.legalEntityGUID = this.selectedLegalEntity?.legalEntityGuId || this.userInfo.legalEntityGuId;
        this.commonService.getCurrencylist(this.categoryAndMerchantModel).subscribe((res) => {
            if (!res.isError && res.errorCode == 0) {
                let listarray = res.payload;
                this.currencyList = listarray.map((list) => new CurrencyList(list));
                this.currencytempList = listarray.map((list) => new CurrencyList(list));
                //this.currencyList = _.uniqBy(this.currencyList, 'currencyId');
                this.currencyList.forEach((item, i) => {
                    if (item.currencyId === 64) {
                        this.currencyList.splice(i, 1);
                        this.currencyList.unshift(item);
                    }
                });
            } else if (!res.isError && res.errorCode == 2) {
                this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
            }
        });
    }

    changeCountryName(e: any, item?) {
        this.rulesModel.countryCode = item?.shortCode;
        this.rulesModel.countryId = item?.countryId;
        this.rulesModel.countryName = item?.countryName;
    }
    changePerdiemCountryName(e: any, item?) {

        this.newPerDiemModel.countryName = item?.countryName; // for old
        this.newPerDiemModel.countryId = item?.countryId;
        this.newPerDiemModel.countryCode = item?.shortCode;

        this.newPerDiemModel.perDayCurrencyIdWithReceiptCode = this.baseCurrencyCode;
        this.newPerDiemModel.perHalfDayCurrencyIdWithReceiptCode = this.baseCurrencyCode;
        this.newPerDiemModel.perHourCurrencyIdWithReceiptCode = this.baseCurrencyCode;
        this.newPerDiemModel.perDayCurrencyIdWithReceipt = item?.countryId;
        this.newPerDiemModel.perHalfDayCurrencyIdWithReceipt = item?.countryId;
        this.newPerDiemModel.perHourCurrencyIdWithReceipt = item?.countryId;

        this.newPerDiemModel.perDayCurrencyIdWithoutReceiptCode = this.baseCurrencyCode;
        this.newPerDiemModel.perHalfDayCurrencyIdWithoutReceiptCode = this.baseCurrencyCode;
        this.newPerDiemModel.perHourCurrencyIdWithoutReceiptCode = this.baseCurrencyCode;
        this.newPerDiemModel.perDayCurrencyIdWithoutReceipt = item?.countryId;
        this.newPerDiemModel.perHalfDayCurrencyIdWithoutReceipt = item?.countryId;
        this.newPerDiemModel.perHourCurrencyIdWithoutReceipt = item?.countryId;

    }

    changeCurrency(e: any, item: any, isPerDiemExp?, index?) {
        if (isPerDiemExp) {
            this.rulesModel.policyPerDiemCategoryList[index].currencyCode = item?.currencyCode;
            this.rulesModel.policyPerDiemCategoryList[index].currencyId = item?.currencyId;
        } else {
            this.rulesModel.currencyCode = item?.currencyCode;
            this.rulesModel.currencyId = item?.currencyId;
        }
    }

    /*google location */
    showCities = false;
    showCoutry = false;
    toCitiesList = [];
    showcitySearchListSpinner = false;
    private geocoder: any;
    sessionTokenEnable = true;
    sessionToken: any;
    requestType: any;
    @ViewChild('togoglemapCity', { static: false }) googleSearchAutoComplete: ElementRef;

    selectCountry() {
        this.showCoutry = true;
    }
    selectFromMap(e, value?) {
        for (let k = 0; k < this.totalPolcyModel.policyMileageList.length; k++) {
            if (k == value) {
                this.totalPolcyModel.policyMileageList[k].showList = true;
            } else {
                this.totalPolcyModel.policyMileageList[k].showList = false;
            }
        }
        this.showCities = true;
        this.toCitiesList = [];
        setTimeout(() => {
            this.googleSearchAutoComplete.nativeElement.focus();
        }, 100);
    }

    openCalen(e, value) {
        this.dateOfBirthUser.open();
    }

    onFromFocus(e, isFrom: boolean) {
        e.preventDefault();
        e.stopPropagation();
        this.toCitiesList = [];
    }

    public setFocusTest(input: string) {
        const targetElem = document.getElementById(input);
        setTimeout(function waitTargetElem() {
            if (document.body.contains(targetElem)) {
                targetElem.focus();
            } else {
                setTimeout(waitTargetElem, 100);
            }
        }, 500);
    }

    googleAutoCompleteSearch(val) {

        var searchText: any;
        searchText = val.currentTarget.value.trim();
        var citiesFilter = [];
        let domesticpredictions = [];
        if (searchText.length > 2) {
            this.showcitySearchListSpinner = true;
            const displaySuggestions = (predictions, status) => {
                // if (status == google.maps.places.PlacesServiceStatus.OK) {
                //     this.showcitySearchListSpinner = false;
                //     predictions.forEach((prediction) => {
                //         domesticpredictions.push(prediction);
                //     });

                //     var dcountry = this;
                //     dcountry.toCitiesList = domesticpredictions;
                // }
                ;
            };

            if (this.sessionTokenEnable) {
                // this.sessionToken = new google.maps.places.AutocompleteSessionToken();
                this.sessionTokenEnable = false;
            }
            let requestType = {
                input: searchText,
                types: ['(cities)'],
                componentRestrictions: { country: this.currentStage == 2 ? this.rulesModel.countryCode : '' },
                sessionToken: this.sessionToken,
            };

            // const service = new google.maps.places.AutocompleteService();
            // service.getPlacePredictions(requestType, displaySuggestions);
        }
    }

    addCityandCountry(event, element: any, index?: any) {
        event.preventDefault();
        event.stopPropagation();
        if (this.currentStage == 1) {
            this.locationId = element.place_id;
            this.totalPolcyModel.policyMileageList[index].stateName = element.description;
            this.totalPolcyModel.policyMileageList[index].stateId = element.place_id;
            this.totalPolcyModel.policyMileageList.forEach(element => {
                element.showList = false;
            });
            this.getCategorylist(1);
        }
        this.geocodeAddress(element.description, element.place_id);
    }

    addPerdiemCityandCountry(event, element: any, index?: any) {
        event.preventDefault();
        event.stopPropagation();
        if (this.currentStage == 1) {
            this.locationId = element.place_id;
            this.newPerDiemModel.cityName = element.terms[0].value;
            this.newPerDiemModel.cityId = this.locationId;
            this.getCategorylist(1);
        }

        this.geocodeAddress(element.description, element.place_id);
    }

    geocodeAddress(location: string, place_id?) {
        // this.geocoder = new google.maps.Geocoder();
        this.geocoder.geocode({ 'address': location }, (results, status) => {
            this.ngZone.run(() => {
                // if (status == google.maps.GeocoderStatus.OK) {

                //     this.showCities = false;
                //     this.cityStr = this.currentStage == 1 ? results[0].address_components[0].long_name : '';
                //     this.rulesModel.cityName = this.currentStage == 1 ? results[0].address_components[0].long_name : '';
                //     this.newPerDiemModel.cityName = this.currentStage == 1 ? results[0].address_components[0].long_name : '';

                //     // if (this.cityStr == this.newPerDiemModel.cityName && this.countryNameStr == this.newPerDiemModel.countryName) {
                //     //     this.toastr.error('Policy already created with same country and city', 'Error');
                //     //     this.newPerDiemModel.cityName = '';
                //     //     this.newPerDiemModel.countryName = '';
                //     // } else {
                //     //     this.rulesModel.cityName = this.currentStage == 1 ? results[0].address_components[0].long_name : '';
                //     //     this.newPerDiemModel.cityName = this.currentStage == 1 ? results[0].address_components[0].long_name : '';
                //     // }
                // }
            });
        });
    }

    removeGoogleocationdetails(i, fromOrTo) {
        this.rulesModel.cityName = "";
    }

    closeMatCalender(e) {
        // e.stopPropagation();
        // e.preventDefault();
        this.dateOfBirthUser?.close();
    }

    dimissDta(e) {
        // e.stopPropagation();
        // e.preventDefault();
        // this.totalPolcyModel.policyMileageList[index].showList = false;
        this.showCities = false;
    }

    onContentScrolled(e) {
        this.startPosition = e.srcElement.scrollTop;
        let scroll = e.srcElement.scrollTop;
        if (scroll > this.currentPosition) {
            this.descriptionRe?.close();
        } else {
            //this.descriptionRe.close();
        }
        this.currentPosition = scroll;
    }
    searchForCountry(value) {
        //console.log(value.data);
        var searchText: any;
        searchText = value.currentTarget.value.trim();
        this.searchCountyData = [];
        if (searchText.length > 0) {
            this.selectSearch(searchText);
        } else {
            this.settingService.getCountries({}).subscribe(data => {
                this.countryList = data.payload;
            });
        }

    }
    selectSearch(value: string) {
        let filter = value;
        for (let i = 0; i < this.countryList.length; i++) {
            let option = this.countryList[i];
            if (option.countryName.toLowerCase().indexOf(filter.toLowerCase()) >= 0) {
                this.searchCountyData.push(option);
            }
        }
        this.countryList = [];
        this.countryList.push(...this.searchCountyData);


    }
    selectCountryCall(item) {

        this.countryNameStr = item?.countryName;
        this.showCoutry = false;
        this.newPerDiemModel.countryName = item?.countryName; // for perdiem
        this.newPerDiemModel.countryId = item?.countryId;
        this.newPerDiemModel.countryCode = item?.shortCode;

        this.newPerDiemModel.perDayCurrencyIdWithReceiptCode = this.baseCurrencyCode;
        this.newPerDiemModel.perHalfDayCurrencyIdWithReceiptCode = this.baseCurrencyCode;
        this.newPerDiemModel.perHourCurrencyIdWithReceiptCode = this.baseCurrencyCode;
        this.newPerDiemModel.perDayCurrencyIdWithReceipt = item?.countryId;
        this.newPerDiemModel.perHalfDayCurrencyIdWithReceipt = item?.countryId;
        this.newPerDiemModel.perHourCurrencyIdWithReceipt = item?.countryId;

        this.newPerDiemModel.perDayCurrencyIdWithoutReceiptCode = this.baseCurrencyCode;
        this.newPerDiemModel.perHalfDayCurrencyIdWithoutReceiptCode = this.baseCurrencyCode;
        this.newPerDiemModel.perHourCurrencyIdWithoutReceiptCode = this.baseCurrencyCode;
        this.newPerDiemModel.perDayCurrencyIdWithoutReceipt = item?.countryId;
        this.newPerDiemModel.perHalfDayCurrencyIdWithoutReceipt = item?.countryId;
        this.newPerDiemModel.perHourCurrencyIdWithoutReceipt = item?.countryId;
        this.countryList = [];
        this.settingService.getCountries({}).subscribe(data => {
            this.countryList = data.payload;
        });

    }
    onClickedOutsidecountry() {
        this.showCoutry = false;
    }

    getstateList() {
        let requestObj = {
            countryId: this.selectedCoutry.countryId
        }
        this.commonService.getStates(requestObj).subscribe(data => {
            this.stateList = data.payload;
            let listarray = data.payload;
            this.stateTempList = listarray.map(
                (list) => new stateListModel(list)
            );
            this.stateList = listarray.map((list) => new stateListModel(list));
        });
    }

    getMilageStates(obj?) {
        let requestObj = {
            countryId: obj ? obj.countryId : null,
            legalEntityId: this.selectedLegalEntity?.legalEntityId || this.userInfo.legalEntityId,
            legalEntityGUID: this.selectedLegalEntity?.legalEntityGuId || this.userInfo.legalEntityGuId
        };
        this.expenseService.getMileageStatesList(requestObj).subscribe((data) => {
            let listarray = data.payload.mileageStateList;
            if (obj) {
                this.stateTempList = listarray.map((list) => new stateListModel(list));
                this.stateList = listarray.map((list) => new stateListModel(list));
            } else {
                //   console.log("final return data 123:",this.getUnique(listarray.map((list) => new stateListModel(list)),it => it.countryName));
                this.mileageCountryList = this.getUnique(listarray.map((list) => new stateListModel(list)), it => it.countryName);
            }
        });
    }

    getUnique(array, key) {
        return [... new Map(array.map(x => [key(x), x])).values()]
    }

    onstateSearch(val) {
        let searchText = val.currentTarget.value.trim();
        if (this.stateTempList != null) {
            if (searchText != "") {
                let filterdata = this.stateTempList.filter((data) => {
                    if (
                        data.stateName.toLowerCase().indexOf(searchText.toLowerCase()) >=
                        0
                    ) {
                        return data;
                    }

                });
                this.stateList = filterdata;
            }
            else {
                this.stateList = this.stateTempList;
            }
        }
    }

    @ViewChild("state", { static: false }) stateAutoCompleteReference: ElementRef;
    @ViewChild("country", { static: false }) countryAutoCompleteReference: ElementRef;
    @ViewChild("statefocusOff", { static: false }) statefocusOff: ElementRef;
    @ViewChild("countryfocusOff", { static: false }) countryfocusOff: ElementRef;
    showStateList: boolean = false;
    showCountryList: boolean = false;
    selectedState: any;

    onStateEventFocus() {
        setTimeout(() => {
            this.stateList = this.stateTempList;
        }, 0)
    }
    onClickedOutsideState() {
        this.showStateList = false;
    }
    onClickedOutsideCountry() {
        this.showCountryList = false;
        // this.totalPolcyModel.policyMileageList.forEach(element => {
        //     element.showCounties =false;
        // });
    }

    openStateDropDown(e, i) {
        // if(i){
        this.closeAll();
        e.preventDefault();
        e.stopPropagation();
        this.stateList = this.stateTempList;
        this.totalPolcyModel.policyMileageList.forEach(element => {
            element.showStates = false;
        });
        this.totalPolcyModel.policyMileageList[i].showStates = true;
        this.totalPolcyModel.policyMileageList[i].showCounties = false;
        this.showStateList = true;

        setTimeout(() => {
            this.stateAutoCompleteReference.nativeElement.focus();
        }, 0);
        //  }

    }
    openCounryDropDown(e, i) {
        this.totalPolcyModel.policyMileageList.forEach(element => {
            element.showCounties = false;
        });
        this.totalPolcyModel.policyMileageList[i].showCounties = true;
        this.showCountryList = true;
    }
    closeAll() {
        this.showStateList = false;
    }
    getStatesCall(obj, index) {
        this.stateIds = obj.stateId;
        this.countryId = obj.countryId;
        this.totalPolcyModel.policyMileageList[index].stateName = obj.stateName;
        this.totalPolcyModel.policyMileageList[index].stateId = obj.stateId;
        this.totalPolcyModel.policyMileageList[index].showStates = false;

        this.getmastCategorylist(index);
        // this.getCategorylist(2);
        // this.resetMileageForm();
    }

    getSelectedCountry(obj, index) {
        this.totalPolcyModel.policyMileageList[index].countryName = obj.countryName;
        this.totalPolcyModel.policyMileageList[index].countryId = obj.countryId;
        this.showCountryList = false;
        this.totalPolcyModel.policyMileageList[index].showCounties = false;
        this.getMilageStates(obj);
        //this.getstateList();

        // this.getmastCategorylist();
        // this.getCategorylist(2);
        // this.resetMileageForm();
    }

    getmastCategorylist(index?) {
        this.CategoryMerchantModel.legalEntityId = this.selectedLegalEntity?.legalEntityId || this.userInfo.legalEntityId;
        this.CategoryMerchantModel.legalEntityGUID = this.selectedLegalEntity?.legalEntityGuId || this.userInfo.legalEntityGuId;
        // this.CategoryMerchantModel.locationId = this.selectedLocationId;
        this.CategoryMerchantModel.stateId = this.stateIds;
        this.CategoryMerchantModel.countryId = this.countryId;
        this.commonService
            .getCatgryMasterlist(this.CategoryMerchantModel)
            .subscribe((res) => {
                if (!res.isError && res.errorCode == 0) {
                    let listarray = res.payload;
                    this.totalPolcyModel.policyMileageList[index].mileagCategoryList = listarray.mileageCategoryList.map((list) => new CategoryList(list));
                    //   if(index){
                    //     console.log("final result rajesh :123",this.milageCategoryList);

                    //   }else{
                    //     this.milageCategoryList = listarray.mileageCategoryList.map((list) => new CategoryList(list));
                    //   }
                    // if (this.stateIds && this.milageCategoryList.length == 0) {
                    //   //this.milageCategoryListWarning = true;
                    // } else {
                    //  // this.milageCategoryListWarning = false;
                    // }

                } else if (res.isError == true) {
                    this.toastr.error(
                        res.errorMessage,
                        this.translate.instant("alerts.error")
                    );
                }
            });
    }
    mileageExpenseCheckAll() {
    }
    selectEventState(e) {
        this.stateIds = e.stateId
        // if (this.platformType == 1) {
        //   this.mileageForm.controls.location.patchValue(e.stateName);
        // }
        // if (e != null) {
        //   this.expenseModel.stateId = e.stateId;
        //   this.expenseModel.countryId = e.countryId;
        //   this.expenseModel.stateName = e.stateName;
        //   this.expenseModel.statusCode = e.statusCode;
        // }
        // this.showStateList = false;
        // if (this.active == "middle") {
        //   this.resetMileageForm();

        // }

    }

    openCategoryDropDown(e, inner?) {
        e.preventDefault();
        e.stopPropagation();
        if (inner) {
            this.showInnerCategoryList = true;
            setTimeout(() => {
                this.innercategoryAutoCompleteReference.nativeElement.focus();
            }, 0);
        } else {
            this.showCategoryList = true;
            setTimeout(() => {
                this.categoryAutoCompleteReference.nativeElement.focus();
            }, 0);
        }
    }

    onCategorySearch_one(val, index?) {
        let searchText = val.target.value.toLowerCase();
        if (searchText != "" && searchText.length > 2) {
            this.getCategorylist(1, searchText);
            // let filterdata = this.totalPolcyModel.policyCategoryList[index].innerCatList.filter((data) => {
            //     if (data.categoryName.toLowerCase().indexOf(searchText.toLowerCase()) >= 0) {
            //         return data;
            //     }
            // });
            // this.totalPolcyModel.policyCategoryList[index].innerCatList = filterdata;

        } else {
            this.getCategorylist(1, '');
            // this.totalPolcyModel.policyCategoryList[index].innerCatList = this.dummyCategoryList_one;
        }

    }

    onCategorySearch(val) {
        let searchText = val.target.value.toLowerCase();
        if (searchText != "" && searchText.length > 2) {
            this.getCategorylist(1, searchText);
        } else {
            this.categoryList = this.dummyCategoryList;
        }

    }

    myteam() {
        console.log('rajesh');
    }

    onClickedOutsideCategory(inner?) {
        if (inner) {
            this.showInnerCategoryList = false;
        } else {
            this.showCategoryList = false;
        }
    }

    onKey(e, list) {
        this.allEntitysLists[list] = this.search(e, list);
    }

    // search(e, list) {
    //     let searchText = e.target.value.trim();
    //     let filterdata = this.allEntitysLists[list].filter((data) => {
    //         this.filterSearch = searchText;

    //         if (this.filterSearch.length > 3) {
    //             var apiMappedList = new mappedModel();

    //             switch (list) {
    //                 case 2:
    //                     this.getCostCenterAPi(apiMappedList);
    //                     break;
    //                 case 3:
    //                     this.getDepartmentAPi(apiMappedList);
    //                     break;
    //                 case 4:
    //                     this.getbandAPi(apiMappedList);
    //                     break;
    //                 case 5:
    //                     this.getTeamsApi(apiMappedList);
    //                     break;
    //                 case 6:
    //                     this.getDesignationsAPi(apiMappedList);
    //                     break;
    //                 case 7:
    //                     this.getUsersAPi(apiMappedList);
    //                     break;
    //                 default:
    //                     break;
    //             }
    //         }
    //     });




    //     return filterdata;
    // }
    search(e, list) {
        let searchText = e.target.value.trim();
        this.filterSearch = searchText; // Set filterSearch here

        if (this.filterSearch.length > 2) {
            var apiMappedList = new mappedModel();

            switch (list) {
                case 2:
                    this.getCostCenterAPi(apiMappedList);
                    break;
                case 3:
                    this.getDepartmentAPi(apiMappedList);
                    break;
                case 4:
                    this.getbandAPi(apiMappedList);
                    break;
                case 5:
                    this.getTeamsApi(apiMappedList);
                    break;
                case 6:
                    this.getDesignationsAPi(apiMappedList);
                    break;
                case 7:
                    this.getUsersAPi(apiMappedList);
                    break;
                default:
                    break;
            }
        }
        if (this.filterSearch.length == 0) {
            var apiMappedList = new mappedModel();

            switch (list) {
                case 2:
                    this.getCostCenterAPi(apiMappedList);
                    break;
                case 3:
                    this.getDepartmentAPi(apiMappedList);
                    break;
                case 4:
                    this.getbandAPi(apiMappedList);
                    break;
                case 5:
                    this.getTeamsApi(apiMappedList);
                    break;
                case 6:
                    this.getDesignationsAPi(apiMappedList);
                    break;
                case 7:
                    this.getUsersAPi(apiMappedList);
                    break;
                default:
                    break;
            }
        }

        let filterdata = this.allEntitysLists[list].filter((data) => {
            // Perform filtering here if needed
            // filterdata will contain the filtered result based on this.filterSearch
        });

        return filterdata;
    }


    deleteCategory(index) {
        this.categoryPolicyListForms.controls.splice(index, 1);
        this.totalPolcyModel.policyCategoryList.splice(index, 1);
    }

    categorySelection(value, index) {
        if (this.globalIndex + 1 == 4) {
            const indexOf = this.selectedBands.findIndex(x => x.bandId === value.bandId);
            if (indexOf != -1) {
                this.selectedBands.splice(indexOf, 1);
            } else {
                this.selectedBands.push(value);
            }
        } else if (this.globalIndex + 1 == 2) {
            const indexOf = this.selectedCostcenter.findIndex(x => x.costCenterId === value.costCenterId);
            if (indexOf != -1) {
                this.selectedCostcenter.splice(indexOf, 1);
            } else {
                this.selectedCostcenter.push(value);
            }
        } else if (this.globalIndex + 1 == 3) {
            const indexOf = this.selectedDepartments.findIndex(x => x.departmentId === value.departmentId);
            if (indexOf != -1) {
                this.selectedDepartments.splice(indexOf, 1);
            } else {
                this.selectedDepartments.push(value);
            }
        } else if (this.globalIndex + 1 == 5) {
            const indexOf = this.selectedteams.findIndex(x => x.designationId === value.designationId);
            if (indexOf != -1) {
                this.selectedteams.splice(indexOf, 1);
            } else {
                this.selectedteams.push(value);
            }
        } else if (this.globalIndex + 1 == 7) {
            const indexOf = this.selectedUsers.findIndex(x => x.designationId === value.designationId);
            if (indexOf != -1) {
                this.selectedUsers.splice(indexOf, 1);
            } else {
                this.selectedUsers.push(value);
            }
        } else if (this.globalIndex + 1 == 6) {
            const indexOf = this.selectedDesignations.findIndex(x => x.designationId === value.designationId);
            if (indexOf != -1) {
                this.selectedDesignations.splice(indexOf, 1);
            } else {
                this.selectedDesignations.push(value);
            }
        }
    }

    addDesignation() {
        if (this.totalPolcyModel.entityMapList[this.globalIndex].hierarchyText.length == 1 && this.totalPolcyModel.entityMapList[this.globalIndex].hierarchyText[0] == '') {
            this.totalPolcyModel.entityMapList[this.globalIndex].hierarchyText.splice(0, 1);
            this.totalPolcyModel.entityMapList[this.globalIndex].hierarchyValue.splice(0, 1);
            (this.entityMapForm.get((this.globalIndex + 1).toString()) as FormArray).removeAt(0);
        }
        console.log('selected bands :', this.selectedBands, this.globalIndex);
        if (this.globalIndex + 1 == 4) {
            this.selectedBands.forEach(element => {
                this.totalPolcyModel.entityMapList[this.globalIndex].hierarchyText.push(String(element.bandName));
                this.totalPolcyModel.entityMapList[this.globalIndex].hierarchyValue.push(String(element.bandId));
                let hierID = (this.globalIndex + 1).toString();
                this.addSubEntityForm(String(element.bandName), hierID, String(element.bandId));
                const FindIndex = this.allEntitysLists[this.globalIndex + 1].findIndex((val) => val.bandId === element.bandId);
                if (FindIndex != -1) {
                    var list = this.allEntitysLists[(this.totalPolcyModel.entityMapList[this.globalIndex].hierarchyTypeId)];
                    (list[FindIndex])['isSelected'] = true;
                }
            });
        } else if (this.globalIndex + 1 == 2) {
            this.selectedCostcenter.forEach(element => {
                this.totalPolcyModel.entityMapList[this.globalIndex].hierarchyText.push(String(element.costCenterName));
                this.totalPolcyModel.entityMapList[this.globalIndex].hierarchyValue.push(String(element.costCenterId));
                let hierID = (this.globalIndex + 1).toString();
                this.addSubEntityForm(String(element.costCenterName), hierID, String(element.costCenterId));
                const FindIndex = this.allEntitysLists[this.globalIndex + 1].findIndex((val) => val.costCenterId === element.costCenterId);
                if (FindIndex != -1) {
                    var list = this.allEntitysLists[(this.totalPolcyModel.entityMapList[this.globalIndex].hierarchyTypeId)];
                    (list[FindIndex])['isSelected'] = true;
                }
            });
        } else if (this.globalIndex + 1 == 3) {
            this.selectedDepartments.forEach(element => {
                this.totalPolcyModel.entityMapList[this.globalIndex].hierarchyText.push(String(element.departmentName));
                this.totalPolcyModel.entityMapList[this.globalIndex].hierarchyValue.push(String(element.departmentId));
                let hierID = (this.globalIndex + 1).toString();
                this.addSubEntityForm(String(element.departmentName), hierID, String(element.departmentId));
                const FindIndex = this.allEntitysLists[this.globalIndex + 1].findIndex((val) => val.departmentId === element.departmentId);
                if (FindIndex != -1) {
                    var list = this.allEntitysLists[(this.totalPolcyModel.entityMapList[this.globalIndex].hierarchyTypeId)];
                    (list[FindIndex])['isSelected'] = true;
                }
            });
        } else if (this.globalIndex + 1 == 5) {
            this.selectedteams.forEach(element => {
                this.totalPolcyModel.entityMapList[this.globalIndex].hierarchyText.push(String(element.teamName));
                this.totalPolcyModel.entityMapList[this.globalIndex].hierarchyValue.push(String(element.teamId));
                let hierID = (this.globalIndex + 1).toString();
                this.addSubEntityForm(String(element.teamName), hierID, String(element.teamId));
                const FindIndex = this.allEntitysLists[this.globalIndex + 1].findIndex((val) => val.teamId === element.teamId);
                if (FindIndex != -1) {
                    var list = this.allEntitysLists[(this.totalPolcyModel.entityMapList[this.globalIndex].hierarchyTypeId)];
                    (list[FindIndex])['isSelected'] = true;
                }
            });
        } else if (this.globalIndex + 1 == 7) {
            this.selectedUsers.forEach(element => {
                this.totalPolcyModel.entityMapList[this.globalIndex].hierarchyText.push(String(element.userName));
                this.totalPolcyModel.entityMapList[this.globalIndex].hierarchyValue.push(String(element.userId));
                let hierID = (this.globalIndex + 1).toString();
                this.addSubEntityForm(String(element.userName), hierID, String(element.userId));
                const FindIndex = this.allEntitysLists[this.globalIndex + 1].findIndex((val) => val.userId === element.userId);
                if (FindIndex != -1) {
                    var list = this.allEntitysLists[(this.totalPolcyModel.entityMapList[this.globalIndex].hierarchyTypeId)];
                    (list[FindIndex])['isSelected'] = true;
                }
            });
        } else if (this.globalIndex + 1 == 6) {
            this.selectedDesignations.forEach(element => {
                this.totalPolcyModel.entityMapList[this.globalIndex].hierarchyText.push(String(element.designationName));
                this.totalPolcyModel.entityMapList[this.globalIndex].hierarchyValue.push(String(element.designationId));
                let hierID = (this.globalIndex + 1).toString();
                this.addSubEntityForm(String(element.designationName), hierID, String(element.designationId));
                const FindIndex = this.allEntitysLists[this.globalIndex + 1].findIndex((val) => val.designationId === element.designationId);
                if (FindIndex != -1) {
                    var list = this.allEntitysLists[(this.totalPolcyModel.entityMapList[this.globalIndex].hierarchyTypeId)];
                    (list[FindIndex])['isSelected'] = true;
                }
            });
        }
        // this.selectedDesignations.forEach(element => {
        //     this.totalPolcyModel.entityMapList[5].hierarchyText.push(String(element.designationName));
        //     this.totalPolcyModel.entityMapList[5].hierarchyValue.push(String(element.designationId));
        //     let hierID = (5 + 1).toString();
        //     this.addSubEntityForm(String(element.designationName), hierID, String(element.designationId));
        //     const FindIndex = this.allEntitysLists[6].findIndex((val) => val.designationId === element.designationId);
        //     if (FindIndex != -1) {
        //         var list = this.allEntitysLists[(this.totalPolcyModel.entityMapList[5].hierarchyTypeId)];
        //         (list[FindIndex])['isSelected'] = true;
        //     }
        // });
        this.modalRef.hide();
        this.selectedDesignations = [];
        this.selectedBands = [];
        this.selectedteams = [];
        this.selectedDepartments = [];
        this.selectedUsers = [];
        this.selectedCostcenter = [];
    }

    setCatrgotyItemPosition(globalIn) {
        this.totalPolcyModel.entityMapList[globalIn].hierarchyValue.forEach(element => {
            if (this.globalIndex + 1 == 4) {
                this.allEntitysLists[globalIn + 1].forEach(inner => {
                    if (parseInt(element) == inner.bandId) {
                        inner.isSelected = true;
                    }
                });
            } else if ((this.globalIndex + 1) == 2) {
                this.allEntitysLists[globalIn + 1].forEach(inner => {
                    if (parseInt(element) == inner.costCenterId) {
                        inner.isSelected = true;
                    }
                });
            } else if (this.globalIndex + 1 == 3) {
                this.allEntitysLists[globalIn + 1].forEach(inner => {
                    if (parseInt(element) == inner.departmentId) {
                        inner.isSelected = true;
                    }
                });
            } else if (this.globalIndex + 1 == 5) {
                this.allEntitysLists[globalIn + 1].forEach(inner => {
                    if (parseInt(element) == inner.teamId) {
                        inner.isSelected = true;
                    }
                });
            } else if (this.globalIndex + 1 == 7) {
                this.allEntitysLists[globalIn + 1].forEach(inner => {
                    if (parseInt(element) == inner.userId) {
                        inner.isSelected = true;
                    }
                });
            } else if (this.globalIndex + 1 == 6) {
                this.allEntitysLists[globalIn + 1].forEach(inner => {
                    if (parseInt(element) == inner.designationId) {
                        inner.isSelected = true;
                    }
                });
            }
        });
        return this.allEntitysLists[globalIn + 1];
    }
    isChecked = false;
    changedStatus(e: any, value?) {
        this.isChecked = e?.detail.checked;
        if (value == 'isApprovalFlowRequired') {
            if (e?.detail.checked) {
                this.totalPolcyModel.isApprovalFlowRequired = true;
            } else {
                this.totalPolcyModel.isApprovalFlowRequired = false;
            }
        }
    }

    claimTypes: any = [];
    claimTypesTemp: any = [];
    getClaimTypes() {
        const obj = {
            "action": "claim_types",
            "legalEntityGuid": this.userInfo.legalEntityGuId,
            "legalEntityId": this.userInfo.legalEntityId,
            "companyId": this.userInfo.companyId,
            "companyGUID": this.userInfo.companyGuId,
        }
        this.expenseService.getClaimTypes(obj).subscribe(res => {
            this.claimTypes = res.payload || [];
            this.claimTypesTemp = [...this.claimTypes];
        })
    }

    selectedClaimType(type) {
        this.totalPolcyModel.claimTypeId = type.id;
        this.totalPolcyModel.claimTypeName = type.claimType;
        this.totalPolcyForm.controls.claimTypeId.patchValue(type.id);
        this.totalPolcyForm.controls.claimTypeName.patchValue(type.claimType);
    }

    strToList(text) {
        if (!Array.isArray(text)) {
            return text.split(',');
        } else {
            return text;
        }
    }

    onExpAmtChg(ev, i) {
        const index = this.allCategories.findIndex(a => a.categoryId == this.totalPolcyModel.policyCategoryList[i].categoryId)
        // if(!(this.totalPolcyModel.policyCategoryList[i].parentCategoryId) && index <= 0) {
        this.totalPolcyModel.policyCategoryList[i].parentCategoryId = this.allCategories[index].parentCategoryId;
        // }
        // console.log(i, this.totalPolcyModel.policyCategoryList);
        // console.clear();
        // console.log(index,'categories ', this.allCategories);
        // console.log(i,'policyCategoryList ', this.totalPolcyModel.policyCategoryList);
        // console.log('pID _'+ + this.allCategories[index].parentCategoryId);

        let currentAmt = 0;
        this.totalPolcyModel.policyCategoryList.forEach(p => {
            if (p.parentCategoryId == this.allCategories[index].parentCategoryId) {
                currentAmt += p.expenseAmountLimit;
            }
        })

        const p_index = this.totalPolcyModel.policyCategoryList.findIndex(p => p.categoryId == this.allCategories[index].parentCategoryId);

        // console.log('current __ ', this.totalPolcyModel.policyCategoryList[i].expenseAmountLimit);
        // console.log('limit ____ ', this.totalPolcyModel.policyCategoryList[p_index]?.expenseAmountLimit);
        // console.log('used ____ ', currentAmt);
        // console.log('available ____ ', this.totalPolcyModel.policyCategoryList[p_index]?.expenseAmountLimit - currentAmt);
        let available = this.totalPolcyModel.policyCategoryList[p_index]?.expenseAmountLimit - currentAmt;
        if (available < 0) {
            this.totalPolcyModel.policyCategoryList[i].limitExceeded = true;
        } else {
            this.totalPolcyModel.policyCategoryList[i].limitExceeded = false;
        }



        // this.allCategories.forEach(a => {
        //     const index = this.totalPolcyModel.policyCategoryList.findIndex(p => p.categoryId == this.totalPolcyModel.policyCategoryList[i].categoryId)
        //     // if(a.parentCategoryId == p.categoryId) {
        //         // console.log(a.parentCategoryId, p.categoryId);
        //         // console.log(p);
        //     // }
        // })

        // this.totalPolcyModel.policyCategoryList.forEach((p, ii) => {
        //     // if(i != ii) {
        //         this.allCategories.forEach(a => {
        //             if(a.parentCategoryId == p.categoryId) {
        //                 // console.log(a.parentCategoryId, p.categoryId);
        //                 // console.log(p);
        //             }
        //         })
        //         const p_index = this.allCategories.findIndex(a => a.parentCategoryId == p.categoryId);
        //         // console.log(ind, p.categoryId);

        //         if(p_index >= 0) {
        //             // console.log(p);
        //             // console.log(this.allCategories[ind]);
        //         }
        //     // }
        // })

        // if(this.allCategories[index].parentCategoryId) {
        //     const p_index = this.totalPolcyModel.policyCategoryList.findIndex(p => p.categoryId == this.allCategories[index].parentCategoryId);
        //     // console.log('current __ ', this.totalPolcyModel.policyCategoryList[i].expenseAmountLimit);
        //     // console.log('limit ____ ', this.totalPolcyModel.policyCategoryList[p_index].expenseAmountLimit);
        //     if(this.totalPolcyModel.policyCategoryList[i].expenseAmountLimit > this.totalPolcyModel.policyCategoryList[p_index].expenseAmountLimit) {
        //         this.totalPolcyModel.policyCategoryList[i].limitExceeded = true;
        //     } else {
        //         this.totalPolcyModel.policyCategoryList[i].limitExceeded = false;
        //     }
        //     // console.log(this.totalPolcyModel.policyCategoryList);
        // }

    }

    //   claimedRulesAmt() {
    //     if(this.rulesModel.limitType != 1 && this.rulesModel.categoryId) {
    //         const p_index = this.allCategories.findIndex(r => r.categoryId == this.rulesModel.categoryId);
    //         if(p_index>=0) {
    //             this.totalPolcyModel.policyCategoryRuleList.forEach(r => {
    //                 if(r.categoryId == this.allCategories[p_index].parentCategoryId && r.limitType != 1) {
    //                     this.rulesModel.limitExceeded = this.rulesModel.limitValue > r.limitValue ? true : false;
    //                 } else {
    //                     this.rulesModel.limitExceeded = false;
    //                 }
    //             })
    //         } else {
    //             this.rulesModel.limitExceeded = false;
    //         }
    //     } else {
    //         this.rulesModel.limitExceeded = false;
    //     }

    //   }


    claimedRulesAmt(e?) {
        if (this.rulesModel) {
            this.rulesModel.limitExceeded = false;
        }
        if (this.rulesModel?.limitType !== 1 && this.rulesModel?.categoryId) {
            const category = this.allCategories.find(r => r.categoryId === this.rulesModel.categoryId);
            if (category) {
                const parentRule = this.totalPolcyModel.policyCategoryRuleList.find(r =>
                    r.categoryId === category.parentCategoryId && r.limitType !== 1);
                if (parentRule) {
                    this.rulesModel.limitExceeded = this.rulesModel.limitValue > parentRule.limitValue;
                }
            }
        }
    }

    getCities(i, ev, rules?) {
        console.log(i, ev, rules);
        if (!rules) {
            this.totalPolcyModel.policyCategoryList[i].countryIds = ev;
            this.isExistingCategory(i);
        } else {
            this.rulesModel.countryIds = ev;
        }

        console.log(this.totalPolcyModel?.policyCategoryList[i]);
        let obj = {
            countryIds: rules ? ev.join(',') : this.totalPolcyModel?.policyCategoryList[i]?.countryIds?.join(','),
            searchText: ''
        };
        this.settingService.getCities(obj).subscribe((res: any) => {
            this.citiesList = res.payload || [];
        })
    }

    innerClick(event){
        console.log('sdsdsd',event);
    }
  

    removeCcEmails(ev, i,rules?) {

        // console.log('remoev :',this.rulesModel.cityIds,this.rulesModel.cityNames,i);
        if(!rules){
            const index = this.totalPolcyModel.policyCategoryList[i]?.cityNames?.findIndex(c => c == ev);
            console.log(index);
            if(index >= 0) {
                this.totalPolcyModel.policyCategoryList[i]?.cityNames?.splice(index, 1);
            }
            if(index>= 0 && this.totalPolcyModel.policyCategoryList[i]?.cityIds[index]) {
                this.totalPolcyModel.policyCategoryList[i]?.cityIds?.splice(index, 1);
            }
        }else{
            this.rulesModel.cityNames?.splice(i,1);
            this.rulesModel.cityIds?.splice(i,1);
        }
        
    }

    removePerdiemcity(index){
        this.newPerDiemModel.cityName?.splice(index,1);
        this.newPerDiemModel.cityId?.splice(index,1);
    }

    searchCities(ev, i, rules?) {
        console.log(i, ev);
        
        let obj = {
            countryIds: rules ? this.rulesModel?.countryIds?.join(',') : this.totalPolcyModel?.policyCategoryList[i]?.countryIds?.join(','),
            searchText: ev
        };
        this.settingService.getCities(obj).subscribe((res: any) => {
            this.citiesList = res.payload || [];
        })
        return true;
    }


    getAllCities(cities) {
        let obj = {
            countryIds: cities ? cities.join(',') : ''
        };
        this.settingService.getCities(obj).subscribe((res: any) => {
            this.citiesList = res.payload || [];
        })
    }

    @ViewChild('ccSelect') ccSelect: ElementRef<HTMLInputElement>;
    checkCities(i, ev?, rules?) {
        console.log(i, ev);
        
        

        if(ev && !rules) {

            if(!this.totalPolcyModel.policyCategoryList[i].cityIds) {
                this.totalPolcyModel.policyCategoryList[i].cityIds = [];
            }
            if(!this.totalPolcyModel.policyCategoryList[i].cityNames) {
                this.totalPolcyModel.policyCategoryList[i].cityNames = [];
            }
    
            console.log(this.totalPolcyModel.policyCategoryList[i].cityIds);
            console.log(this.totalPolcyModel.policyCategoryList[i].cityNames);
            this.totalPolcyModel.policyCategoryList[i].cityIds.push(ev.cityId);
            this.totalPolcyModel.policyCategoryList[i].cityNames.push(ev.cityName);

            this.totalPolcyModel.policyCategoryList[i].cityIds = [...ev.map(e => `${e.cityId}`)]
            this.totalPolcyModel.policyCategoryList[i].cityNames = [...ev.map(e => `${e.cityName}`)]
            this.isExistingCategory(i);
        } else if (ev && rules) {

            if(!this.rulesModel.cityIds) {
                this.rulesModel.cityIds = [];
            }
            if(!this.rulesModel.cityNames) {
                this.rulesModel.cityNames = [];
            }

            this.rulesModel.cityIds.push(ev.cityId);
            this.rulesModel.cityNames.push(ev.cityName);

            console.log('final model :',this.rulesModel);
        }
        if(!this.citiesList || this.citiesList?.length == 0) {
            this.getCities(i, rules ? ev : this.totalPolcyModel.policyCategoryList[i].countryIds);
        }
        
    }


    

    seatchedCitiesList(ev) {
        console.log('ev :',ev);
        let obj = {
            countryIds: this.newPerDiemModel?.countryId?.join(','),
            searchText: ev
        };
        this.settingService.getCities(obj).subscribe((res: any) => {
            this.citiesList = res.payload || [];
        })
    }

    copyConditions(ev, type) {
        let targetArray;
        if (type == 'with') {
            targetArray = this.withRcptCndts;
        } else if (type == 'without') {
            targetArray = this.withoutRcptCndts;
        }

        const referenceObject = targetArray.find(obj => obj.duration == 23);
        if (referenceObject) {
            targetArray.forEach(obj => {
                if (obj.duration != 23) {
                    obj.conditionType = ev ? referenceObject.conditionType : null;
                    obj.value = ev ? referenceObject.value : null;
                    obj.perDiemType = ev ? referenceObject.perDiemType : null;
                }
            });
        }
    }

    // setSubtractedCat(ev) {
    //     this.newPerDiemModel.subtractedCatIds = ev.map(e => `${e.categoryId}`) || [];
    //     this.newPerDiemModel.subtractedCatNames = ev.map(e => `${e.categoryName}`) || [];
    // }

    pdTypesList: any = [];
    getPerDiemTypesList() {
        this.showLoader = true;
        let obj = {
            legalEntityId: this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId,
            legalEntityGUID: this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId
        }
        this.settingService.getPerDiemTypesList(obj).subscribe(res => {
            this.pdTypesList = res?.payload || [];
        })
    }

    setPdTypeName(event) {
        this.newPerDiemModel.perDiemTypeIds = event?.map((item) => item.perDiemTypeId);
        this.newPerDiemModel.perDiemTypeNames = event?.map((item) => item.perDiemTypeName);
    }

    cityGroupsList: any = [];
    getCityGroupsList() {
        this.showLoader = true;
        let obj = {
            legalEntityId: this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId,
            legalEntityGUID: this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId
        }
        this.settingService.getCityGroupsList(obj).subscribe(res => {
            this.cityGroupsList = res?.payload || [];            
        })
    }

    setCG(i, ev) {
        this.totalPolcyModel.policyCategoryList[i].cityGroupId = ev.cityGroupId;
        this.totalPolcyModel.policyCategoryList[i].cityGroupName = ev.cityGroupName;
    }

    setRulesCG(i, ev) {
        this.rulesModel.cityGroupId = ev.cityGroupId;
        this.rulesModel.cityGroupName = ev.cityGroupName;
    }

    setPerdiemCG(i, ev) {
        this.newPerDiemModel.cityGroupId = ev.cityGroupId;
        this.newPerDiemModel.cityGroupName = ev.cityGroupName;
    }
}

