import { Injectable } from '@angular/core';
import { Constants } from '../Constants';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(public httpClient: HttpClient) { }

  getDashboard(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/dashboard/get";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getDashboardSummary(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/dashboard/summary";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getTravelDashboard(requestBody: any): Observable<any> {
    
   // const url = Constants.DOMAIN_URL + "v1/dashboard/get";
     const url = 'https://dev.api.eva.travel/tripbox/v1/dashboard/tripbox-stats' // dev
    // const url = 'https://uat.api.eva.travel/tripbox/v1/dashboard/tripbox-stats'  // uat
  //  const url = 'https://api.eva.travel/tripbox/v1/dashboard/tripbox-stats'  // prod
    const body = requestBody;
    return this.httpClient.post(url, body);
  }


}
