import { Component, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
// import { Storage } from '@ionic/storage';
import { changePasswordModel, SelectLegalEntity } from 'src/app/models/Settings/selecteLegalEntity.model';
import { SharedService } from 'src/app/services/common-services/shared.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { SettingsService } from '../../services/settings-services';
import { ToastrService } from 'ngx-toastr';
import { MustMatch } from '../../validations/custom-validation';
import { ThemeService } from 'src/app/services/theme.service';
import { NavController } from '@ionic/angular';
import { ReportsService } from 'src/app/services/reports.service';
import { Router } from "@angular/router";
import { AuthenticationService } from 'src/app/services/authentication.service';
import { environment } from 'src/environments/environment';
import { MatMenuTrigger } from '@angular/material/menu';
import { CommonService } from 'src/app/services/common-services/common.service';
import { PlatformService } from 'src/app/services/platformbase.service';

@Component({
    selector: 'app-main-header',
    templateUrl: './main-header.component.html',
    styleUrls: ['./main-header.component.scss'],
})
export class MainHeaderComponent implements OnInit {
    @ViewChildren(MatMenuTrigger) trigger: QueryList<MatMenuTrigger>;
    showService = false;
    showProfile = false;
    showPettyCashDropDown = false;
    pettyCashString = 'common.pc';
    window: any = window;
    userInfo: any;
    baseLanguageList = [{ value: 'ZH', text: 'Chinese (Mandarin)' }, { value: 'EN', text: 'English' }];
    dateFormatList = [{ value: '1/4/2017', format: 'M/d/yyyy' }, { value: '1/4/17', format: 'M/d/yy' }, {
        value: '01/04/17',
        format: 'MM/dd/yy'
    }, { value: '01/04/2017', format: 'MM/dd/yyyy' }, { value: '17/01/04', format: 'yy/MM/dd' }, {
        value: '2017-01-04',
        format: 'yyyy-MM-dd'
    }, { value: '04-Jan-17', format: 'dd-MMM-yy' }];
    currencyFormatList = [{ value: 'INR (1,10,000)', format: '#,##,###' }, { value: 'USD (1,000,00)', format: '#,###,##' }];
    selectLegalEntityModel = new SelectLegalEntity();
    changePasswordModelRef: any;
    changepwdForm: FormGroup;
    submitted: boolean;
    // public errorMessage: string | null;
    public show = false;
    public buttonDisabled = false;
    changePassModel = new changePasswordModel();
    path: string;
    profileImgPath: any;
    legalEntityName: any;
    personalInfo: any;
    managerusers = [];
    showEmulate:boolean = false;
    selectedEmulate:any;
    original_userInfo: any;
    original_personalInfo: any;
    isAdmin: boolean = false;
    fromEmail: any;
    avalAmt: any;
    isLocalConveyanceEnabled: boolean = false;
    isPettyCashEnabled: boolean = false;
    isTravelEnabled: boolean = false;
    productNamesArr = ["Dashboard", "Travel", "Savings", "GST"];
    allProductsArr: any[] = []
    legalEntityLogoPath:any;
    isUserToggle: any;
    mainheadehidden: boolean;
    pendingCount:number = 0;
    appLogo = 'expenses';
   // favIcon: HTMLLinkElement = document.querySelector('#appIcon');
   profileMouseover = false;

   isActionInProgress = false;

   modalRef: BsModalRef;
   tripsTabLabel = 'My TRIPS';
   claimTabLabel = 'My CLAIMS';
   isMobileDevice: boolean = false;
   companyConfig:any;



    constructor(
        private router: NavController,
        private angularRouter: Router,
        // public storage: Storage,
        private sharedService: SharedService,
        private modalService: BsModalService,
        private changepwdFormBuilder: FormBuilder,
        private settingService: SettingsService,
        private toastr: ToastrService,
        private theme: ThemeService,
        private commonservice: CommonService,
        private authenticationService: AuthenticationService,
        private reportsService: ReportsService,
        public platformService: PlatformService
    ) {
        this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        this.isMobileDevice = this.platformService.isMobileDevice();
        if(sessionStorage.getItem("m-users")) {
            this.managerusers = JSON.parse(sessionStorage.getItem("m-users"));
        } else {
            this.getMangerUsers();
        }
        this.getPersonalInfo();
        this.sharedService.getUserToggle().subscribe((value) =>{
            this.isUserToggle = value;
        })
        // this.allProductsArr = this.userInfo.appsUrl;
        if(environment.appLogo == "expenses"){
            this.allProductsArr = this.userInfo.appsUrl;
        }
        this.appLogo = environment.appLogo;

        if(this.userInfo.legalEntityLogoPath){
            this.legalEntityLogoPath = this.userInfo.legalEntityLogoPath;
        }else{
           this.legalEntityLogoPath = './assets/images/Logo-EP.svg'; 
        }
        this.tripsTabLabel = (this.userInfo?.roleId == 2 || this.userInfo?.roleId == 3 || this.userInfo.roleId == 4) && JSON.parse(sessionStorage.getItem('isadmin')) ? 'ALL TRIPS' : 'MY TRIPS';
        this.claimTabLabel = (this.userInfo?.roleId == 2 || this.userInfo?.roleId == 3 || this.userInfo.roleId == 4) && JSON.parse(sessionStorage.getItem('isadmin')) ? 'CLAIMS' : 'MY CLAIMS';
     
        // if(this.userInfo.appsUrl != null){
        //     let obj = {
        //         id : 5,
        //         key : 'Expenses',
        //         value : ''
        //     }
        //     this.allProductsArr.push(obj);
        // }
        this.authenticationService.setisLocalConveyanceEnabled(this.userInfo.isLocalConveyanceEnabled);
        this.authenticationService.setisPettyCashEnabled(this.userInfo.isPettyCashEnabled);
        this.authenticationService.setisTravelEnabled(this.userInfo.isTravelEnabled);
        // this.isLocalConveyanceEnabled = this.authenticationService.getisLocalConveyanceEnabled()
        // this.isPettyCashEnabled = this.authenticationService.getisPettyCashEnabled()
        // this.isTravelEnabled = this.authenticationService.getisTravelEnabled()

        this.authenticationService.getisLocalConveyanceEnabled().subscribe(status => {
            if (status === true) {
                this.isLocalConveyanceEnabled = true;
            } else if (status === false) {
                this.isLocalConveyanceEnabled = false
            }
        });

        this.authenticationService.getisPettyCashEnabled().subscribe(status => {
            if (status === true) {
                this.isPettyCashEnabled = true;
            } else if (status === false) {
                this.isPettyCashEnabled = false
            }
        });

        this.authenticationService.getisTravelEnabled().subscribe(status => {
            if (status === true) {
                this.isTravelEnabled = true;
            } else if (status === false) {
                this.isTravelEnabled = false
            }
        });

        this.sharedService.getUserInfo().subscribe((val) => {
            if (val) {
                this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
            } else {
                this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
            }
        });
        this.sharedService.getCompanyInfo().subscribe(data => {
            if (data) {
                this.legalEntityName = data.legalEntityName;
            } else {
                if (this.userInfo) {
                    this.legalEntityName = this.userInfo.companyName;
                }
            }
        });

        if (sessionStorage.getItem('isadmin') != null) {
            if (this.userInfo.roleId === 1) {
                this.path = 'settings/userpersonalInfo';
            } else if (this.userInfo.roleId === 3) {
                this.path = 'settings/personalInfo';
            } else {
                this.path = 'settings/personalInfo';
            }
            this.isAdmin = JSON.parse(sessionStorage.getItem('isadmin'));
        } else {
            if (this.userInfo.roleId === 1) {
                this.path = 'settings/userpersonalInfo';
                this.isAdmin = false;
                sessionStorage.setItem("isadmin", JSON.stringify(this.isAdmin));
            } else if (this.userInfo.roleId === 3) {
                this.path = 'settings/personalInfo';
                // is Admin
                this.isAdmin = false;
                sessionStorage.setItem("isadmin", JSON.stringify(this.isAdmin));

            } else {
                this.path = 'settings/personalInfo';
                // is Admin
                this.isAdmin = false;
                sessionStorage.setItem("isadmin", JSON.stringify(this.isAdmin));

            }
        }


        this.sharedService.setUserToggle(!this.isAdmin);

        this.sharedService.getPeningApprovalCount().subscribe(data =>{
            if(data != null){
                this.pendingCount = data;
            }else{
                this.pendingCount = 0;
            }
        })
       
    }

    ngOnInit() {
        sessionStorage.setItem('baseLanguageJson', JSON.stringify(this.baseLanguageList));
        sessionStorage.setItem('dateFormatJson', JSON.stringify(this.dateFormatList));
        sessionStorage.setItem('currencyFormatJson', JSON.stringify(this.currencyFormatList));
        this.mainheadehidden = JSON.parse(sessionStorage.getItem('mainheadehidden'));
        this.selectedEmulate = JSON.parse(sessionStorage.getItem('showEmulate'));
        this.original_userInfo = JSON.parse(sessionStorage.getItem('original_userInfo'));
        this.original_personalInfo = JSON.parse(sessionStorage.getItem('original_personalInfo'));
        this.getProfilePath();
        this.jsonFetach();
    }

    jsonFetach() {
        fetch('./assets/company-config.json').then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }
            return response.json();  // Parse the JSON from the response
        }).then(data => {
            const cci = data?.findIndex(d => window?.location?.origin?.includes(d?.company_url))
            if (cci >= 0) {
                this.companyConfig = data[cci]
            }

            // if(window.location.origin.includes(data?.company_url)){
            //     this.companyConfig.show_userManual = true
            // }else{
            //     this.companyConfig.show_userManual = false;
            // }
            // if(data){
            //     this.companyConfig = data;
            // }
        }).catch(error => {
            console.error('There was a problem with the fetch operation:', error);
        });
    }

    getPersonalInfo() {
        const requestObj = {
            "userId": this.userInfo.userId,
            "userGuid": this.userInfo.userGuId,
        }
        this.settingService.getSingleUser(requestObj).subscribe(data => {
            if (data.payload) {
            sessionStorage.setItem("personalInfo", JSON.stringify(data.payload))
            this.personalInfo = JSON.parse(sessionStorage.getItem("personalInfo"));
            this.managerusers = JSON.parse(sessionStorage.getItem("m-users"));
            // console.log('personla nfo:',this.managerusers);
            }
        });
      }

    getProfilePath() {
        this.sharedService.getUserLogo().subscribe((data => {
            if (!data) {
                if (this.personalInfo) {
                    this.profileImgPath = this.personalInfo.profilePicPath;
                } else {
                    if (this.userInfo.profilePicPath) {
                        this.profileImgPath = this.userInfo.profilePicPath;
                    } else if (!this.userInfo.profilePicPath) {
                        if (this.userInfo.gender) {
                            if (this.userInfo.gender === "Male") {
                                this.profileImgPath = "./assets/images/userdefaultmale.svg";
                            } else if (this.userInfo.gender === "Female") {
                                this.profileImgPath = "./assets/images/userdefaultfemale.svg";
                            }
                        } else if (!this.userInfo.gender) {
                            this.profileImgPath = "./assets/images/userdefaultmale.svg";
                        }
                    }
                }
            } else {
                this.profileImgPath = data;
            }
        }));
    }


    showMaintainance: any;
    ionViewWillEnter() {
        sessionStorage.setItem('baseLanguageJson', JSON.stringify(this.baseLanguageList));
        sessionStorage.setItem('dateFormatJson', JSON.stringify(this.dateFormatList));
        sessionStorage.setItem('currencyFormatJson', JSON.stringify(this.currencyFormatList));
        this.selectedEmulate = JSON.parse(sessionStorage.getItem('showEmulate'));
        this.original_userInfo = JSON.parse(sessionStorage.getItem('original_userInfo'));
        this.original_personalInfo = JSON.parse(sessionStorage.getItem('original_personalInfo'));
        //sessionStorage.setItem("mainheadehidden",this.mainheadehidden);
        this.mainheadehidden = JSON.parse(sessionStorage.getItem('mainheadehidden'));
        this.getProfilePath();
        sessionStorage.setItem("isadmin", JSON.stringify(this.isAdmin));
        this.sharedService.getMaintenanceflag().subscribe(value => {
            this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
             if(value || (this.userInfo && (this.userInfo?.maintenanceCode == 1 || this.userInfo?.maintenanceCode == 2))) this.showMaintainance = true;
        });
    }
    showMoreService() {
        this.showService = !this.showService;
    }

    showProfileSection(pettyCashDropdown?) {
        if (pettyCashDropdown) {
            this.showPettyCashDropDown = !this.showPettyCashDropDown;
        } else {
            this.showProfile = !this.showProfile;
        }
    }

    showPettyCashList() {
        setTimeout(() => {
            this.showPettyCashDropDown = !this.showPettyCashDropDown;
        }, 0)
    }

    

    navigateTo(redirectValue: any, pettyCash?: boolean) {

        sessionStorage.setItem('selectedValue',null);
        sessionStorage.setItem('selected-value',null);
        this.showProfile=false;
        sessionStorage.setItem('approvalData',null);
        const current_modalObj = {
            name: 'list',
            data: {}
        }
        sessionStorage.setItem('current_modal', JSON.stringify(current_modalObj));
        sessionStorage.setItem("isEmailApproval", JSON.stringify(false));
        // if (this.angularRouter.url.split('/')[1] == redirectValue) {
        //     window.location.reload();
        // }
       // this.favIcon.href = this.userInfo.legalEntityLogoPath;
        this.sharedService.setMapData(null);
        if (redirectValue === 'settings/accountconfig' || redirectValue === 'settings/personalInfo' || 'settings/userpersonalInfo') {
            this.selectLegalEntityModel.companyId = this.userInfo.companyId;
            this.selectLegalEntityModel.companyGuId = this.userInfo.companyGuId;
            this.selectLegalEntityModel.legalEntityId = this.userInfo.legalEntityId;
            this.selectLegalEntityModel.legalEntityGuId = this.userInfo.legalEntityGuId;
            this.selectLegalEntityModel.legalEntityName = this.userInfo.companyName;
            this.selectLegalEntityModel.legalEntityLogo = this.userInfo.legalEntityLogoPath;
            sessionStorage.setItem('selectedLegalEntity', JSON.stringify(this.selectLegalEntityModel));
            this.sharedService.setCompanyInfo(this.selectLegalEntityModel);
            if (pettyCash) {
                this.angularRouter.navigate([`/petty-cash/${redirectValue}`], { state: { fromPettyCash: true } }).then(() => {
                    this.sharedService.setSideBarFlag(true);
                });

                /*this.router.navigateRoot(["/" + redirectValue, 'id']).then(() => {
                    this.sharedService.setSideBarFlag(true);
                });*/
            } else {
                this.router.navigateRoot(["/" + redirectValue]).then(() => {
                    this.sharedService.setSideBarFlag(true);
                });
            }

        } else {
            this.router.navigateRoot(["/" + redirectValue]);
        }
    }

    logout() {
        this.showProfile = false;
        let domailUrl = this.userInfo.domainURL;
        if(domailUrl && domailUrl !=''){
            let myVal = domailUrl + 'login'
            window.location.href = myVal;
        }else{
            this.router.navigateRoot(["login"]);
        }

        sessionStorage.clear();
        localStorage.clear();
        this.sharedService.setUserInfo(false);
        this.sharedService.setRefreshData(false);
        this.sharedService.setExpenseData(null);
        this.sharedService.setCompanyInfo(null);
        this.sharedService.setMapData(null);
        this.sharedService.setPreCategory(null);
        this.sharedService.setPeningApprovalCount(null);
        this.theme.applyTheme(null);
       
    }

    navigateToChangePassword(content) {
        this.showProfile=false;
        this.changePasswordModelRef = this.modalService.show(content, {
            ariaLabelledBy: 'modal-basic-title', backdrop: 'static',
            keyboard: false,
        });
        this.changepwdForm = this.changepwdFormBuilder.group({
            oldPassword: ['', [Validators.required]],
            // newPassword: ['', [Validators.required, Validators.maxLength(8),
            // Validators.pattern('^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=[^0-9]*[0-9])(?=[^!@#\$%\^&\*]*[!@#\$%\^&\*]).{6,}$')]],
            newPassword: ['', [Validators.required, Validators.minLength(8)]],
            confirmPassword: ['', [Validators.required]],
        },
            {
                validators: MustMatch('newPassword', 'confirmPassword')
            });
    }

    changePassword(val: any) {
        this.submitted = true;
        if (this.changepwdForm.invalid) {
            this.show = true;
            // this.errorMessage = 'Please enter required Fileds';
            return false;
        } else {
            this.buttonDisabled = true;
            this.changePassModel.user_Id = this.userInfo.userId,
                this.changePassModel.guid = this.userInfo.userGuId,
                this.changePassModel.accessToken = this.userInfo.idToken,
                this.settingService.changePassword(JSON.stringify(this.changePassModel)).subscribe((res => {
                    if (res.isError === false) {
                        this.toastr.success(res.errorMessage, 'success');
                        this.closeChangePasswordwindow();
                        this.buttonDisabled = false;
                        // this.errorMessage = res.errorMessage;
                        this.show = true;

                    } else if (res.isError === true) {
                        this.toastr.error(res.errorMessage, 'error');
                        this.buttonDisabled = false;
                        this.closeChangePasswordwindow();
                        this.changepwdForm.reset(this.changepwdForm.value);
                        // this.errorMessage = res.errorMessage;
                        this.show = true;

                    }
                }));
        }
    }

    openEmulatePopup(emulateRef : TemplateRef<any>){
        this.managerusers = JSON.parse(sessionStorage.getItem("m-users"));
        const config = {
            ignoreBackdropClick: true,
            class: "modal-dialog-centered modal-md",
            keyboard: false,
          };
          this.modalRef = this.modalService.show(emulateRef, config);
    }

    closeChangePasswordwindow() {
        this.submitted = false;
        this.changepwdForm.reset();
        this.changePasswordModelRef.hide();
    }

    get f() {
        return this.changepwdForm.controls;
    }

    changeUser(admin) {
        this.isAdmin = admin;
        // setTimeout(() => {
        //     this.showProfile=false;
        // }, 500);
        sessionStorage.setItem("isadmin", JSON.stringify(this.isAdmin));
        this.tripsTabLabel = (this.userInfo?.roleId == 2 || this.userInfo?.roleId == 3 || this.userInfo.roleId == 4) && JSON.parse(sessionStorage.getItem('isadmin')) ? 'ALL TRIPS' : 'MY TRIPS';
        this.claimTabLabel = (this.userInfo?.roleId == 2 || this.userInfo?.roleId == 3 || this.userInfo.roleId == 4) && JSON.parse(sessionStorage.getItem('isadmin')) ? 'CLAIMS' : 'MY CLAIMS';
        this.sharedService.setUserToggle(!this.isAdmin);
        this.getApprovalStats();
        this.router.navigateRoot(['dashboard']);
    }

    goToTravel() {
        let index = this.allProductsArr?.findIndex(a => a.key == 'Travel');        
        if(index >= 0) {
            // remove emulated user data
            if(sessionStorage.getItem('showEmulate')) {
                this.selectedEmulate = null;
                sessionStorage.setItem('showEmulate',null);
                sessionStorage.removeItem('showEmulate');
                let originalUser = JSON.parse(sessionStorage.getItem('original_userInfo'));
                this.isAdmin = JSON.parse(sessionStorage.getItem('original_isadmin'));
                sessionStorage.setItem('userInfo',JSON.stringify(originalUser));
                sessionStorage.setItem('isadmin',JSON.stringify(this.isAdmin));
            }
            this.appsRedirect(this.allProductsArr[index]?.id, this.allProductsArr[index]?.value);
        }
    }

    appsRedirect(id, url) {
        this.showProfile=false;
        if(id == 5){
        }else{
            window.location.href = url;
        }
        
    }
    openMenu(index: number) {
        this.trigger.toArray().forEach((item: MatMenuTrigger, i: number) => {
            if (i !== index && item.menuOpen) {
                item.closeMenu()
            }
        });
    }
    getEmulatevalue(event){
        console.log(event);
        this.selectedEmulate = event;
        console.log('Original User _______ ', this.userInfo);
    }
    closeEmulate(){
        this.selectedEmulate = null;
        sessionStorage.setItem('showEmulate',null);
        sessionStorage.removeItem('showEmulate');
        let localuserInfo = JSON.parse(localStorage.getItem('userInfo'));

        let originalUser = JSON.parse(sessionStorage.getItem('original_userInfo'));
        this.isAdmin = JSON.parse(sessionStorage.getItem('original_isadmin'));
        this.personalInfo = JSON.parse(sessionStorage.getItem('original_personalInfo'));
        this.userInfo = JSON.parse(sessionStorage.getItem('original_userInfo'));
        originalUser.restrictClaim = 2;
        console.log('Original User _______ ', originalUser);
        
        sessionStorage.setItem('userInfo',JSON.stringify(originalUser));
        sessionStorage.setItem('isadmin',JSON.stringify(this.isAdmin));
        sessionStorage.setItem('isadmin',JSON.stringify(this.isAdmin));
        sessionStorage.removeItem('original_userInfo')
        // window.location.reload();
        this.modalRef?.hide();
        if(this.angularRouter.url == '/dashboard'){
            window.location.reload();
        }else{
            // this.navigateTo('/dashboard');
            window.location.href = '/dashboard';
        }
    }


    closeEmulateConfirmation(exitEmulatedAccount: TemplateRef<any>) {
        const config = {
            ignoreBackdropClick: false,
            class: "modal-md modal-dialog-centered",
            keyboard: false
        };
        this.modalRef = this.modalService.show(exitEmulatedAccount, config);
    }

    resetClose() {
        this.modalRef?.hide();
    }

    saveE(){
        if(!sessionStorage.getItem('original_userInfo')) {
            sessionStorage.setItem('original_userInfo',JSON.stringify(this.userInfo));
            sessionStorage.setItem('original_isadmin',JSON.stringify(this.isAdmin));
            sessionStorage.setItem('original_personalInfo',JSON.stringify(this.personalInfo));
        }
        sessionStorage.setItem('showEmulate',JSON.stringify(this.selectedEmulate));
        this.original_userInfo = JSON.parse(sessionStorage.getItem('original_userInfo'));
        this.original_personalInfo = JSON.parse(sessionStorage.getItem('original_personalInfo'));
        console.log('Original User _______ ', this.userInfo);
        console.log('Emulated User',this.selectedEmulate);
        // let emulatedObj = Object.assign(this.userInfo, this.selectedEmulate);

        this.updateUser(this.userInfo, this.selectedEmulate);

        console.log(this.userInfo);
        // console.log(Object.assign(this.userInfo, this.selectedEmulate));
        // this.userInfo.evaRefUserId = this.selectedEmulate?.evaRefUserId;
        // this.userInfo.ssoTokenForXray = this.selectedEmulate?.ssoTokenForXray;
        // this.userInfo.companyId = this.selectedEmulate?.companyId;
        // this.userInfo.companyGuId = this.selectedEmulate?.companyGuId;
        // this.userInfo.userGuId = this.selectedEmulate?.userGuid;
        // this.userInfo.userId = this.selectedEmulate?.userId;
        // this.userInfo.roleId = this.selectedEmulate?.roleId;
        // this.userInfo.legalEntityId = this.selectedEmulate?.legalEntityId;
        // this.userInfo.legalEntityGuId = this.selectedEmulate?.legalEntityGuId;
        this.userInfo.restrictClaim = this.selectedEmulate?.restrictClaim;
        sessionStorage.setItem('userInfo',JSON.stringify(this.userInfo));
        sessionStorage.setItem("isadmin", JSON.stringify(false));
        console.log('after Emulate _____ ',JSON.parse(sessionStorage.getItem('userInfo')));
        this.sharedService.setUserToggle(true);
        this.modalRef.hide();
        if(this.angularRouter.url == '/dashboard'){
            window.location.reload();
        }else{
            // this.navigateTo('/dashboard');
            window.location.href = '/dashboard';
        }

    }


    updateUser(originalUser, emulatedUser) {
        // Create a map of lowercase keys to original keys for originalUser
        let originalUserMap = {};
        for (let key in originalUser) {
            originalUserMap[key.toLowerCase()] = key;
        }
    
        // Iterate through emulatedUser and update originalUser
        for (let key in emulatedUser) {
            let lowerKey = key.toLowerCase();
            if (originalUserMap[lowerKey]) {
                originalUser[originalUserMap[lowerKey]] = emulatedUser[key];
            } else {
                originalUser[key] = emulatedUser[key];
            }
        }
    }

    getMangerUsers(searchText?) {
        let userData: any;
        if(sessionStorage.getItem('original_userInfo')) {
            userData = JSON.parse(sessionStorage.getItem('original_userInfo'));
        } else {
            userData = JSON.parse(sessionStorage.getItem('userInfo'));
        }
        let reqeust = {
            "companyId": userData?.companyId || this.userInfo?.companyId,
            "companyGuid": userData?.companyGuId || this.userInfo?.companyGuId,
            "searchText": searchText?.term || "",
            "pageNumber": 1,
            "pageSize": 100,
            "sortColumn": "",
            "sortType": "asc",
            "userId": userData?.userId || this.userInfo?.userId,
            "userGuid": userData?.userGuId || this.userInfo?.userGuId,
            "action": 'emulate-users'
        }
        this.commonservice.getUserManagerList(reqeust).subscribe(res => {
            if (res?.errorCode == 0 && res?.payload?.usersList?.length != 0) {
                sessionStorage.setItem('m-users', JSON.stringify(res?.payload?.usersList || []));
                this.managerusers = res?.payload?.usersList || [];
            }
        });
    }

    getApprovalStats() {
        let isadmin = JSON.parse(sessionStorage.getItem('isadmin'));
        let requestModel = {
            userId: this.userInfo?.userId,
            userGUID: this.userInfo?.userGuId,
            viewType: isadmin ? this.userInfo?.roleId : 1,
            legalEntityId: this.userInfo?.legalEntityId,
            recordType: null,
            legalEntityGUID: this.userInfo?.legalEntityGuId,
            isApprovalTab: true
        }
        this.reportsService.approvialStats(requestModel).subscribe((res) => {
            sessionStorage.setItem('approval-tabs', JSON.stringify(res.payload.mainMenu || []));
        })
    }
}
