export class CreatePettyCash {
    baseAmount:any;
    categoryType: number;
    expenseType: number;
    originalCurrencyId: number;
    originalAmount: number;
    originalCurrencyCode: string;
    userId: number;
    userGUID: string;
    legalEntityId: number;
    legalEntityGUID: string;
    action: number;	// 1 : save, 2 : submit
    recordType: number;	// 1 : Expense, 2 : Advance Expense
    reason: string;
    expensedDate: string;
    content: string;
    fileName: string;
    expenseAttachments = [];
    requestType: string;
    expenseTypeName: string;
    originalCurrencyIcon: string;
    unformattedBaseAmount: number; 
    unformattedOriginalAmount:number;
}