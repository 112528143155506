import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { TripFilterModal } from 'src/app/models/Common/common.model';
import { NewTripModel } from 'src/app/models/Expenses/expenses.model';
import { FetchTripInfo } from 'src/app/models/Reports/reports.model';
import { ReportsService } from 'src/app/services/reports.service';
import { ExpenseAndMileageComponent } from '../../dashboard/expenseandmileage/expenseandmileage.component';
import { PlatformService } from 'src/app/services/platformbase.service';
import { CommonService } from 'src/app/services/common-services/common.service';

@Component({
  selector: 'app-trips-popup',
  templateUrl: './trips-popup.component.html',
  styleUrls: ['./trips-popup.component.scss'],
})
export class TripsPopupComponent implements OnInit {
  @ViewChild("unableToRaiseTravelClaim", { static: false }) private unableToRaiseTravelClaim;
  @Input() fromNewReport: boolean;
  @Input() claimBasicData: any;
  tripsList: any = [];
  selectedTrips: any = [];
  userInfo: any;
  tripsSearchtxt: any = '';
  tripdetailviewenable: boolean = false;
  enableproceededbtn: boolean = false;
  tripfilterModal = new TripFilterModal();
  tripsdummyList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  nodatafound: boolean = false;

  constructor(
    private toastr: ToastrService,
    private navCtrl: NavController,
    public reportService: ReportsService,
    private modalService: BsModalService,
    public modalController: ModalController,
    private platformService: PlatformService,
    private commonService: CommonService
  ) {
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  }

  ngOnInit() {
    this.isMobileDevice = this.platformService.isMobileDevice();
    console.log('fromNewReport _______ ', this.fromNewReport);
    console.log('claimBasicData _______ ', this.claimBasicData);
    sessionStorage.removeItem('tripData')
    this.getTripsList();
    this.getCHTripsList();
  }

  searchBtn() {
    this.tripsList = [];
    this.selectedTrips = [];
    this.getTripsList();
    this.getCHTripsList();
  }

  transformTripArray(trips: any[]): any[] {
    return trips.map((trip, index) => ({
      tripId: index == 0 ? 156948 : trip.Trip_Id,
      tripGUID: '1b955e7cc6cde21e06f8938519f53c9c',
      // tripId: trip.Trip_Id,
      tripName: trip.Trip_Name,
      tripCost: trip.Trip_Cost,
      tripStartDate: moment(trip.Trip_Start_Date).format('DD-MM-YYYY'),
      tripEndDate: moment(trip.Trip_End_Date).format('DD-MM-YYYY'),
      customFields: trip.Custom_Fields,
      reasonTravel: trip.Reason_Travel,
      reasonForTravelComments: trip.Reason_For_Travel_Comments,
      tripEstimateCost: trip.Trip_Estimate_Cost,
      budgetRefNumber: trip.FRN_Number,
      isEmptyTrip: trip.Is_Empty_Trip,
      travelMode: trip.Custom_Trip_Travel_Type,
      isExpanded: false,
      isMultiService: true || trip.Is_Multi_Service,
      claimTypeId: trip.Claim_Type_Id,
      claimTypeName: trip.Claim_Type_Name,
    }));
  }

  getCHTripsList() {
    let isAdmin = JSON.parse(sessionStorage.getItem('isadmin'));
    let requestModel = {
      "bookingType": this.commonService.clickHouseViewTrips('unclaimed'),
      "userId": isAdmin ? null : this.userInfo?.userId,
      "fileType": 2,
      "fromBookingDate": null,
      "toBookingDate": null,
      "pageSize": 100,
      "pageNumber": 0,
      "searchText": this.tripsSearchtxt || null,
      "companyIds": [this.userInfo?.companyId]
    }

    this.reportService.clickHouseReport(requestModel).subscribe((res) => {
      console.log(res?.payload?.data);
      const transformedTrip = this.transformTripArray(res?.payload?.data);
      console.log(transformedTrip);
      this.nodatafound = false;
      this.tripsList = [];
      this.tripsList = this.tripsList.concat(
        transformedTrip?.map((list) => new NewTripModel(list))
      );
      if (this.tripsList.length == 0) {
        this.nodatafound = true;
      }
    }, err => {
      this.nodatafound = true;
      this.tripsList = [];
      this.toastr.error(err.errorMessage, "Error", { timeOut: 3000 });
    });
  }

  tripData: any = {
    bookingType: '',
    customFields: '',
    costCenterCode: '',
    reasonForTravel: '',
    bookingTypeName: ''
  }
  getTripsList() {
    let modl = new FetchTripInfo();
    modl.legalEntityId = this.userInfo.legalEntityId;
    modl.legalEntityGUID = this.userInfo.legalEntityGuId;
    modl.companyId = this.userInfo.companyId;
    modl.companyGUID = this.userInfo.companyGuId;
    modl.searchText = this.tripsSearchtxt;
    modl.tripStartDate = null;
    modl.tripEndDate = null;
    modl.filterBy = this.tripfilterModal;
    modl.pageSize = 100;
    modl.pageNumber = 1;
    modl.filterBy.statusIds = modl.filterBy.statusIds[0] ? modl.filterBy.statusIds : [];
    this.reportService.fetchTripDetails(modl).subscribe((res) => {
      console.log(res?.payload);
      
      // if (!res.isError && res.errorCode == 0 && res?.payload?.tripList) {
      //   this.nodatafound = false;
      //   this.tripsList = [];
      //   this.tripsList = this.tripsList.concat(
      //     res.payload.tripList.map((list) => new NewTripModel(list))
      //   );
      //   if (this.tripsList.length == 0) {
      //     this.nodatafound = true;
      //   }
      // } else {
      //   this.nodatafound = true;
      //   this.tripsList = [];
      //   this.toastr.error(res.errorMessage, "Error", { timeOut: 3000 });
      // }
    }, err => {
      // this.nodatafound = true;
      // this.tripsList = [];
      // this.toastr.error(err.errorMessage, "Error", { timeOut: 3000 });
    });
  }

  formatDate(date) {
    if (date) {
      return moment(date, 'DD-MM-YYYY').format('DD-MMM-YY');
    }
  }

  budgetRefNumber: any;
  // disablenextbtn: boolean = true;
  tripdetailview: any;
  tripTravellers = [];
  TravelAdvanceSummary = null;
  trip_estimate: any;
  claimTripCost: number;
  seletedTrip: any;

  toggleExpanded(index: number, item: any) {
    this.budgetRefNumber = item?.budgetRefNumber || null;
    // this.disablenextbtn = true;
    this.seletedTrip = item;
    if(item.isEmptyTrip) {
      this.seletedTrip.serviceList = [];
      this.seletedTrip.serviceList.push({
        tripCost: item.tripCost,
        tripEndDate: item.tripEndDate,
        tripGUID: item.tripGUID,
        tripId: item.tripId,
        tripName: item.tripName,
        tripStartDate: item.tripStartDate,
        budgetRefNumber: item.budgetRefNumber,
        claimTypeId: item.claimTypeId,
        claimTypeName: item.claimTypeName,
        isEmptyTrip: item.isEmptyTrip
      })
      this.tripdetailview = this.seletedTrip?.serviceList;
      // this.tripDetails(this.seletedTrip.serviceList[0])
    } else if (item.serviceList.length == 0) {
      let obj = {
        tripId: item.tripId, tripGUID: item.tripGUID,
        legalEntityGuid: this.userInfo?.legalEntityGuId,
        legalEntityId: this.userInfo?.legalEntityId,
      }
      this.reportService.getTripServiceList(obj).subscribe(response => {
        if (response.errorCode == 0) {
          let statusidcount: number = 0;
          response.payload.serviceList.forEach(element => {
            element.CompanyPaid = true;
            element.EmployeePaid = false;
          });
          response?.payload?.serviceList?.forEach(element => {
            element.claimTypeId = response?.payload?.claimTypeId;
            element.claimTypeName = response?.payload?.claimTypeName;
          });
          this.tripsList[index].serviceList = response.payload.serviceList;
          this.tripdetailview = response.payload.serviceList;
          this.tripsList[index].advanceSummary = response.payload.advanceSummary;
          this.tripTravellers = response?.payload.tripTravellers;
          this.TravelAdvanceSummary = response.payload.advanceSummary;
          this.tripData.customFields = response.payload.customFields || null;
          this.tripData.reasonForTravel = response.payload.reasonForTravel || null;
          this.tripData.bookingType = response.payload.bookingType || null;
          this.tripData.bookingTypeName = response.payload.bookingTypeName || null;
          this.tripData.costCenterCode = response.payload.costCenterCode || null;
          this.tripData.tripStartDate = response?.payload?.tripStartDate || null;
          this.tripData.tripEndDate = response?.payload?.tripEndDate || null;

          this.seletedTrip.tripEstimateDetails = response.payload.tripEstimateDetails;
          sessionStorage.setItem('tripData', JSON.stringify(this.tripData));

          this.tripdetailview.forEach(element => {
            if (element.statusId != 3 && element.statusId != 6 && element.statusId != 11 && element.statusId != 13) {
              statusidcount++;
              if (statusidcount > 0) {
                // this.disablenextbtn = false;
              }

            }
          });
        } else {
          if (response?.isError) {
            this.toastr.error(response.errorMessage, "Error", { timeOut: 3000 });
          }
        }
      }, err => {
        this.toastr.error(err.errorMessage, "Error", { timeOut: 3000 });
      });
    } else {
      // this.disablenextbtn = true;
      let statusidcount: number = 0;
      item.serviceList.forEach(element => {
        if (element.statusId != 3 && element.statusId != 6 && element.statusId != 11 && element.statusId != 13) {
          statusidcount++;
          if (statusidcount > 0) {
            // this.disablenextbtn = false;
          }

        }
      });
      this.tripdetailview = item?.serviceList;
    }

    if (this.tripsList[index].isMultiService == true) {
      if (this.tripsList[index].isExpanded == true) {
        this.tripsList[index].isExpanded = false;
        this.tripsList[index].isSelected = false;
        this.selectedTrips = [];
        this.claimTripCost = 0;
      } else {
        this.tripsList[index].isExpanded = true;
        this.tripsList[index].isSelected = true;
        this.selectedTrips = [];
        this.claimTripCost = 0;
        this.selectedTrips.push(item);
        this.claimTripCost = parseFloat(item?.tripCost?.toString());
      }
    } else {
      this.tripsList[index].isExpanded = false;
      this.tripsList[index].isSelected = true;
      this.selectedTrips = [];
      this.claimTripCost = 0;
      this.selectedTrips.push(item);
      this.claimTripCost = parseFloat(item?.tripCost?.toString());
    }

    if(!!item.isEmptyTrip) {
      this.tripsList[index].isExpanded = false;
    }

    this.tripsList.forEach((element, indexof) => {
      if (indexof != index) {
        element.isExpanded = false;
        element.isSelected = false;
      }
    });
  }

  nextExpenseview() {
    console.log(this.tripdetailview);
    if (this.tripdetailview) {
      this.tripdetailview.forEach(element => {
        if (element.isCompanyPaid) {
          element.CompanyPaid = true;
          element.EmployeePaid = false;
        } else {
          element.CompanyPaid = false;
          element.EmployeePaid = true;
        }
        this.enableproceededbtn = true;
      });

      this.tripdetailviewenable = true;
      // if((this.userInfo.isAssociatedExpenseEnabled && !this.userInfo.isServiceExpenseEnabled) || ( !this.userInfo.isAssociatedExpenseEnabled && this.userInfo.isServiceExpenseEnabled))
      // {
      //   this.enableproceededbtn = false;
      // }
      if ((this.userInfo.isAssociatedExpenseEnabled && !this.userInfo.isServiceExpenseEnabled)) {
        this.tripdetailview.forEach((element, i) => {

          element.CompanyPaid = true;
          element.EmployeePaid = false;
          this.enableproceededbtn = true;

        });
      }
      if ((this.userInfo.isServiceExpenseEnabled && !this.userInfo.isAssociatedExpenseEnabled)) {
        this.tripdetailview.forEach((element, i) => {
          element.EmployeePaid = true;
          element.CompanyPaid = false;
          this.enableproceededbtn = true;

        });
      }
    }
  }
  backtoExpenseSelection() {
    this.tripdetailviewenable = false;
  }
  onchange(index, event) {
    console.log(index, event);
    let allSelected = true;
    this.tripdetailview.forEach((element, i) => {
      if (i == index) {
        if (event == 'CompanyPaid') {
          element.CompanyPaid = true;
          element.EmployeePaid = false;
        }
        if (event == 'EmployeePaid') {
          element.EmployeePaid = true;
          element.CompanyPaid = false;
        }
      }
      if (!element.CompanyPaid && !element.EmployeePaid) {
        allSelected = false;
      }

    });
    console.log(this.tripdetailview);

    this.enableproceededbtn = allSelected;
  }

  hasValidInvoiceUrl(service: any): boolean {
    return service?.invoiceList?.some(
      (invoice: any) => invoice?.invoiceUrl && invoice?.invoiceUrl?.trim() !== ''
    );
  }

  areAllRequiredInvoicesPresent(): boolean {
    // [1: Flights] [2:	Hotel] [8: Bus]
    const requiredProductIds = ['1', '2', '8'];
    const filteredServices = this.seletedTrip?.serviceList?.filter((service) =>
      requiredProductIds?.includes(String(service?.productId))
    );

    return filteredServices?.every((service) => this.hasValidInvoiceUrl(service));
  }

  closeBsModal() {
    this.bsModalRef?.hide();
  }

  modalRef: BsModalRef;
  bsModalRef: BsModalRef;
  proceed() {
    if (!this.seletedTrip.isEmptyTrip && this.userInfo?.restrictTravelClaimInvoice && !this.areAllRequiredInvoicesPresent()) {
      this.modalRef?.hide();
      const config = {
        ignoreBackdropClick: true,
        class: "modal-dialog-centered modal-lg baa",
        keyboard: false,
      };
      this.bsModalRef = this.modalService.show(this.unableToRaiseTravelClaim, config);
      return;
    }
    
    this.closeModal();
    this.selectedTrips.forEach(element => {
      element.serviceList.forEach(ele => {
        ele.paidBy = ele.CompanyPaid ? 1 : 2;
      });
    });
    var currentIndx = 0;
    var url = 'newreport';
    let navInfo = {
      url: url,
      fromPage: 'viewSlide',
      openTabIndex: 0,
      isTravelClaim: true,
      // singleSelection: this.singleSelection,
      // multiSelection: this.multiSelection,
      singleExpense: this.selectedTrips,
      expenseList: this.selectedTrips,
      tripEstimateDetails: this.seletedTrip.tripEstimateDetails,
      // isAssociated: this.associated ? this.associated : false,
      isReadOnly: false,
      currentIndx: currentIndx,
      goToReportDetails: true,
      budgetRefNumber: this.budgetRefNumber || null,
      tripData: JSON.parse(sessionStorage.getItem('tripData')) || null,
      report: {
        fromMytrips: true,
        claimId: this.claimBasicData?.claimId || null,
        claimGUID: this.claimBasicData?.claimGUID || null,
        claimName: this.claimBasicData?.claimName || this.selectedTrips[0]?.tripName,
        claimEndDate: this.claimBasicData?.claimEndDate || this.selectedTrips[0]?.tripEndDate,
        claimStartDate: this.claimBasicData?.claimStartDate || this.selectedTrips[0]?.tripStartDate,
        claimTypeId: this.selectedTrips[0]?.serviceList[0]?.claimTypeId,
        claimTypeName: this.selectedTrips[0]?.serviceList[0]?.claimTypeName,
      },
      advanceSummary: this.seletedTrip.advanceSummary
    };
    sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
    console.log(navInfo);
    // this.navCtrl.navigateRoot(["newreport"]);
    // if(this.isMobileDevice) {
    //   this.navCtrl.navigateRoot(["newExpenseMileageBulk"]);
    // } else {
    //     this.openExpenseCreationForm();
    // }
  }


          // this.navCtrl.navigateRoot(["newExpenseMileageBulk"]);
        //   if(this.isMobileDevice) {
        //     this.navCtrl.navigateRoot(["newExpenseMileageBulk"]);
        // } else {
        //     this.openExpenseCreationForm();
        // }
    isMobileDevice: boolean = false;
    async openExpenseCreationForm() {
        const modal = await this.modalController.create({
            component: ExpenseAndMileageComponent,
            cssClass: "auto-height"
        });
        modal.onDidDismiss().then(res => {
        })
        return await modal.present();
    }



  closeModal() {
    for (let i = 1; i <= this.modalService?.getModalsCount(); i++) {
      this.modalService?.hide(i);
    }
  }

}
