import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { getAnalyticsModel } from 'src/app/models/Analytics/analytics.model';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common-services/common.service';
import { ToastrService } from 'ngx-toastr';
import { NavController } from '@ionic/angular';
import { DashboardModel, GetDashboardData } from 'src/app/models/Dashboard/dashboard.model';
import { DashboardService } from 'src/app/services/dashboard.service';
import { Platform } from '@ionic/angular';
import { getFullYear } from 'ngx-bootstrap/chronos';
import { AuthenticationService } from 'src/app/services/authentication.service';


@Component({
  selector: 'app-dashboard-fc365',
  templateUrl: './dashboard-fc365.component.html',
  styleUrls: ['./dashboard-fc365.component.scss'],
})
export class DashboardFc365Component implements OnInit {

  public lineEmpChartData: ChartDataSets[] = [{ data: [], label: '' }];
  public lineEmpChartLabels: Label[] = ['Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar']

  public lineEmpChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      display: true,
      labels: {
        fontColor: 'green'
      }
    },
    maintainAspectRatio: true,
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: '',

          },
          ticks: {
            callback: (value) => {
              return `₹ ${value.toLocaleString()}K`;
            },
            fontColor: '#003559'
          },
          gridLines: {
            drawOnChartArea: false,
            color: '#003559',
          }
        }],
      xAxes: [{
        gridLines: {
          drawOnChartArea: false,
          color: '#003559',
        },
        ticks: {
          fontColor: '#003559'
        }
      }]
    },
    hover: {
      mode: 'nearest',
      intersect: true
    },

  };
  public lineEmpChartColors: Color[] = [
    {
      backgroundColor: 'white',
      // backgroundColor: '#003559',
      borderColor: '#003559',
      pointBackgroundColor: '#003559',
      pointBorderColor: '#003559',
      pointHoverBackgroundColor: 'white',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
  ];
  public lineEmpChartLegend = false;
  public lineEmpChartType: ChartType = 'line';


  categoryChartOptions = {
    animationEnabled: true,
    title: {
      text: ""
    },
    data: [{
      type: "doughnut",
      indexLabel: "{name}",
      dataPoints: [
        { y: 0, name: "" },
        { y: 0, name: "" },
      ]
    }]
  }
  categoryLabels = [];
  categoryChartData = [];
  public CategoryLegend = false;
  public categoryoptions: ChartOptions = {
    responsive: true,
    legend: { display: false }
  }
  categoryAnalyList: ['1', '2', '3', '4'];
  productList = [];
  repData = [];
  heightPer = '68%'

  expenseList = [
    {
      title: 'Amount Being Processed',
      amount: '₹ 3,500',
      prodicon: 'assets/svg/totalamt.svg'
      // {{userDashboardDetail?.totalAmountProcessed ?
      //   userDashboardDetail?.totalAmountProcessed : 0}}
    }, {
      title: 'Amount Reimbursed',
      amount: '₹ 4,500',
      prodicon: 'assets/svg/amtreimb.svg'
    }, {
      title: 'Request Submitted',
      amount: '35',
      prodicon: 'assets/svg/reqpend.svg'
    }, {
      title: 'Request Pending Your Action',
      amount: '23',
      prodicon: 'assets/svg/reqpend.svg'
    },
  ];
  selectTravel: boolean = true;
  selectExp: boolean = true;
  selectVal = 'Travel'

  chartOptions = {
    animationEnabled: true,
    // theme: "dark1",
    title: {
      text: ""
    },
    data: [{
      // type: "pie",
      // startAngle: 45,
      // indexLabelPlacement: "inside",
      type: "doughnut",
      innerRadius: "60%",
      radius: this.heightPer,
      // yValueFormatString: "#,###.##'%'",
      //  yValueFormatString: "'₹ '#,###.##",
      indexLabel: "{name}: {y}",
      explodeOnClick: false,
      dataPoints: [
        // { y: 80, name: "Flight" },
        // { y: 90, name: "Hotel" },
        // { y: 70, name: "Bus" },
        // { y: 58, name: "Car" },
        // { y: 40, name: "Insurance" },
        // { y: 60, name: "Cab" },
        // { y: 98, name: "Visa" },
        // { y: 30, name: "Forex" }
      ]
    }]
  }
  getAnalyticsModel = new getAnalyticsModel()
  expenseData: any;
  dashboardModel = new DashboardModel();
  userDashboardDetail: any;
  tDate: string;
  mobMaxDate: string;
  maxDateStr: any = moment(new Date()).format('YYYY-MM-DD');
  minDateStr: any = moment(new Date().getTime()).subtract(2000, 'years').format('YYYY-MM-DD');

  currDateStr: any = moment(new Date()).format('DD-MM-YYYY');
  monthAgo: any = moment(new Date(
    new Date().getFullYear(),
    new Date().getMonth() - 1,
    new Date().getDate()
  )).format('DD-MM-YYYY');

  frmDate: string;
  minDate: string;
  userInfo: any;
  showChart: boolean = false;

  fromDataStr = moment(new Date(
    new Date().getFullYear(),
    new Date().getMonth() - 1,
    new Date().getDate()
  )).format('D MMM YY');
  toDataStr = 'Today'

  expFromDate: any;
  expToDate: any;

  amountClaimed: any;
  amountReibersed: any;
  reqSubmited: any;
  pendingActions: any;

  constructor(private commonService: CommonService, public router: NavController,
    private toastr: ToastrService, public authenticationService: AuthenticationService,
    private dashboardService: DashboardService, private platform: Platform) {
    this.selectTravel = true;
    this.authenticationService.setShowHeaderEV(false);
    platform.ready().then(() => {
      if (platform.height() > 800) {
        this.heightPer = '80%';
      } else if (platform.height() < 700) {
        this.heightPer = '50%';
      } else {
        this.heightPer = '65%';
      }
    });
  }

  ionViewWillEnter() {
    
    this.selectTravel = true;
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.getAnalyticsModel.userId = this.userInfo.userId;
    this.getAnalyticsModel.userGUID = this.userInfo.userGuId;
    this.getAnalyticsModel.legalEntityId = this.userInfo.legalEntityId;
    this.getAnalyticsModel.legalEntityGUID = this.userInfo.legalEntityGuId;
    // this.getAnalyticsModel.isAdminView = JSON.parse(sessionStorage.getItem('isadmin'));
    this.getAnalyticsModel.isAdminView = false;
    //this.getDashboard();
    this.expensesData();
    this.getTravelDashboard();
  }

  ngOnInit() {
    //this.showCategory();
  }

  selectTravelCall() {
    this.selectTravel = true;
   // this.fromDataStr = moment(new Date(new Date().setFullYear(new Date().getFullYear() - 1))).format('D MMM YY');
   this.fromDataStr = moment(new Date(
    new Date().getFullYear(),
    new Date().getMonth() - 1,
    new Date().getDate()
  )).format('D MMM YY');
   this.toDataStr = 'Today'
  }
  selectExpensesCall() {
    this.selectExp = true;
    this.selectTravel = false;
    this.fromDataStr = this.expFromDate;
    this.toDataStr = this.expToDate;
    //this.expensesData();
  }
  navigateCall() {
  }

  expensesData() {
    this.commonService.getExpenseOverviewData(this.getAnalyticsModel).subscribe(res => {
      if (!res.isError && res.errorCode === 0) {
        this.selectExp = false;
        var lineDataAmountVals = [];
        var lineDataMounthVals = [];
        this.expenseData = res.payload.overview;

        // this.amountClaimed = res.payload.totalAmountProcessed;
        // this.amountReibersed = res.payload.totalAmountReimbursed;
        this.reqSubmited = res.payload.noOfApprovalRequest;
        this.pendingActions = res.payload.noOfPendingRequest;

        var str = res.payload.totalAmountProcessed;
        var splitted = str.split(".");
        this.amountClaimed = splitted[0];

        var str1 = res.payload.totalAmountReimbursed;
        var splitted1 = str1.split(".");
        this.amountReibersed = splitted1[0];

        this.expFromDate = res.payload.fromDate;
        this.expToDate = res.payload.toDate;

        this.expenseData.forEach(obj => {
          lineDataAmountVals.push(obj.amount/1000);
          lineDataMounthVals.push(obj.monthName);
        });

        this.lineEmpChartData = [{ data: lineDataAmountVals, label: '' }];
        this.lineEmpChartLabels = lineDataMounthVals;

      } else {
        this.selectExp = true;
        // console.log("expenses failed******");
      }
    });

  }
  navToCall() {
    if (this.selectTravel) {
      this.router.navigateRoot(["/" + 'traveleva']);
    } else {
      this.router.navigateRoot(["/" + 'dashboard']);
    }

  }

  getDashboard() {

    this.dashboardModel.legalEntityId = this.userInfo.legalEntityId;
    this.dashboardModel.legalEntityGUID = this.userInfo.legalEntityGuId;
    this.dashboardModel.userId = this.userInfo.userId;
    this.dashboardModel.userGUID = this.userInfo.userGuId;
    //  this.dashboardModel.isAdminView = JSON.parse(sessionStorage.getItem('isadmin'));

    this.dashboardService.getDashboard(this.dashboardModel).subscribe(response => {
      if (!response.isError && response.errorCode === 0) {
        this.userDashboardDetail = new GetDashboardData(response.payload);
      }
      // this.showLoading = false;
    });
  }

  getTravelDashboard() {
    let reqBody = {
      // "fromDate": "01-01-2022",
      // "toDate": "31-12-2022",
      "fromDate": this.monthAgo,
      "toDate": this.currDateStr,
      "token": this.userInfo.tokenFor365,
      "action": 'NOEXPENSES'
    }
    this.dashboardService.getTravelDashboard(reqBody).subscribe(response => {
      if (!response.isError && response.errorCode === 0) {
        this.productList = [];
        this.repData = [];
        response?.payload?.data?.forEach(element => {
          if(element.product) {
            this.repData.push(element);
          }
        })
        var flightVal = '0'
        var hotelVal = '0'
        var busVal = '0'
        var cabVal = '0'
        var visaVal = '0'
        var forexVal = '0'
        var insuraVal = '0'
        var railVal = '0'

        this.repData.forEach(element => {
          if (element.productId == 1) {
            flightVal = element.status[0].count;
          }
          if (element.productId == 2) {
            hotelVal = element.status[0].count;
          }
          if (element.productId == 8) {
            busVal = element.status[0].count;
          }
          if (element.productId == 6) {
            cabVal = element.status[0].count;
          }
          if (element.productId == 3) {
            visaVal = element.status[0].count;
          }
          if (element.productId == 4) {
            forexVal = element.status[0].count;
          }
          if (element.productId == 7) {
            insuraVal = element.status[0].count;
          }
          if (element.productId == 9) {
            railVal = element.status[0].count;
          }
        });

        console.log(this.repData);

        let obj = {
          product: 'Flight',
          status: 'Flight Awaiting Booking',
          amount1: flightVal,
          prodicon: 'assets/svg/flight1.svg'
        }
        this.productList.push(obj);
        let obj1 = {
          product: 'Hotel',
          status: 'Hotel Awaiting Booking',
          amount1: hotelVal,
          prodicon: 'assets/svg/hotel1.svg'
        }
        this.productList.push(obj1);
        let obj2 = {
          product: 'Bus',
          status: 'Bus Awaiting Booking',
          amount1: busVal,
          prodicon: 'assets/svg/bus1.svg'
        }
        this.productList.push(obj2);
        let obj3 = {
          product: 'Cab',
          status: 'Cab Awaiting Booking',
          amount1: cabVal,
          prodicon: 'assets/svg/cab1.svg'
        }
        this.productList.push(obj3);

        let obj4 = {
          product: 'Visa',
          status: 'Visa Awaiting Booking',
          amount1: visaVal,
          prodicon: 'assets/svg/visa1.svg'
        }
        this.productList.push(obj4);
        let obj5 = {
          product: 'Forex',
          status: 'Forex Awaiting Booking',
          amount1: forexVal,
          prodicon: 'assets/svg/forex1.svg'
        }
        this.productList.push(obj5);
        let obj6 = {
          product: 'Insurance',
          status: 'Insurance Awaiting Booking',
          amount1: insuraVal,
          prodicon: 'assets/svg/insurance1.svg'
        }
        this.productList.push(obj6);
        let obj7 = {
          product: 'Rail',
          status: 'Rail Awaiting Booking',
          amount1: railVal,
          prodicon: 'assets/svg/rail1.svg'
        }
        this.productList.push(obj7);
        this.showCategory();

      } else {
        this.showChart = false;
      }
    });
  }

  showCategory() {
    this.categoryChartData = [];
    this.repData.forEach(elm => {
      if (elm.status[0].count > 0) {
        let obj = {
          y: elm.status[0].count,
          name: elm.product
        }
        this.categoryChartData.push(obj);
      }
    });
    if(this.categoryChartData.length > 0){
      this.showChart = true;
    }else{
      this.showChart = false;
    }

    // let obj = {
    //   y: '120000',
    //   name: 'Flight'
    // }
    // this.categoryChartData.push(obj);
    // let obj1 = {
    //   y: '12000',
    //   name: 'Hotel'
    // }
    // this.categoryChartData.push(obj1);
    // let obj2 = {
    //   y: '120000',
    //   name: 'Bus'
    // }
    // this.categoryChartData.push(obj2);
    // let obj3 = {
    //   y: '12000',
    //   name: 'Cab'
    // }
    // this.categoryChartData.push(obj3);

    // let obj4 = {
    //   y: '120000',
    //   name: 'Rail'
    // }
    // this.categoryChartData.push(obj4);

    // let obj5 = {
    //   y: '120000',
    //   name: 'Forex'
    // }
    // this.categoryChartData.push(obj5);

    // let obj6 = {
    //   y: '120000',
    //   name: 'Visa'
    // }
    // this.categoryChartData.push(obj6);

    // let obj7 = {
    //   y: '120000',
    //   name: 'Insurance'
    // }
    // this.categoryChartData.push(obj7);


    this.chartOptions = {
      animationEnabled: true,
      // theme: 'dark1',
      title: {
        text: ""
      },
      data: [{
        type: "doughnut",
        innerRadius: "60%",
        radius: this.heightPer,
        explodeOnClick: false,
        // type: "pie",
        // startAngle: 45,
        //  indexLabelPlacement: "inside",
        // yValueFormatString: "'₹  ' #,###.##",
        indexLabel: "{name}: {y}",
        dataPoints: this.categoryChartData

      }]
    }
  }
  segmentChangedType(event: any) {
    const valType = event.detail.value;
    if (valType == 'Travel') {
      this.selectTravel = true;
      this.fromDataStr = moment(new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 1,
        new Date().getDate()
      )).format('D MMM YY');
      //this.fromDataStr = moment(new Date(new Date().setFullYear(new Date().getFullYear() - 1))).format('D MMM YY');
      this.toDataStr = 'Today'
    } else {
      this.selectTravel = false;
      this.fromDataStr = this.expFromDate;
      this.toDataStr = this.expToDate;
    }
  }
}

