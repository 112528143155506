import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { Constants } from "../Constants";
import { HttpClient } from "@angular/common/http";
@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private httpClient: HttpClient) { }

  saveThemes(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/local-settings/theme/save";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getThemes(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/local-settings/theme/get";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
//banners urls
  saveAndUpdateBanners(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/local-settings/banners/save";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  getBanner(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/local-settings/banners/get";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  //   category Urls

  categoryList(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/category/list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  categoryListForFinanceCode(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/category/list-fin-codes";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getSingleCategory(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/category/get";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  saveCategory(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/category/create";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  enableCategory(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/category/enable";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  disableCategory(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/category/disable";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  //tax urls
  getSingletax(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/tax-code/get";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  enabletax(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/tax-code/enable";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  disabletax(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/tax-code/disable";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  saveTax(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/tax-code/create";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  taxList(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/tax-code/list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  //HSN code urls
  saveHsnCode(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/hsn-code/create";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getHsnCode(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/hsn-code/get";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  hsnCodeList(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/hsn-code/list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  enableHsnCode(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/hsn-code/enable";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  disablehsnCode(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/hsn-code/disable";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }



  //Approval policies urls
  saveOrUpdateApprovalPolicy(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/approval-policy/create";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  getApprovalPolicy(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/approval-policy/list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  getApprovalPolicyData(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/approval-policy/get";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  enableApprovalPolicy(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/approval-policy/enable";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  disableApprovalPolicy(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/approval-policy/disable";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }









  //finacial integration code urls
  savefinacialIntegration(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/finance-integration-code/create";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getfinacialIntegration(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/finance-integration-code/get";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  finacialIntegrationList(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/finance-integration-code/list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  enablefinacialIntegration(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/finance-integration-code/enable";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  disablefinacialIntegration(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/finance-integration-code/disable";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }


  //   mileage urls
  mileageList(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/mileage/list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getSingleMileage(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/mileage/get";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  saveMileage(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/mileage/create";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  enableMileage(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/mileage/enable";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  disableMileage(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/mileage/disable";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  updateMileageUnit(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/local-settings/update-mileage-type";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getMileageUnit(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/local-settings/get-mileage-type";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  /*merchant urls*/

  merchantList(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/merchant/list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getSingleMerchant(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/merchant/get";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  saveMerchant(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/merchant/create";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  enableMerchant(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/merchant/enable";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  disableMerchant(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/merchant/disable";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getTitle(requestBody): Observable<any> {
    const url = Constants.BASE_URL + "v1/common/pick-list-data";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  changePassword(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/user-auth/change-password";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getNationalities(requestBody): Observable<any> {
    const url = Constants.BASE_URL + "v1/common/nationality/list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getCountries(requestBody): Observable<any> {
    const url = Constants.BASE_URL + "v1/common/country/list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getCities(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/mileage/cities-list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getSingleUser(requestBody): Observable<any> {
    const url = Constants.BASE_URL + "v1/user-managment/get";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  createUser(requestBody): Observable<any> {
    const url = Constants.BASE_URL + "v1/user-managment/create";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getAllExchangRate(requestBody): Observable<any> {
    const url = Constants.BASE_URL + 'v1/currency-exchange/get';
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getExchangeRateList(requestBody): Observable<any> {
    const url = Constants.BASE_URL + 'v1/currency-exchange/list';
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  changeExchangeStatusToInactive(requestBody): Observable<any> {
    const url = Constants.BASE_URL + 'v1/currency-exchange/disable';
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  changeExchangeStatusToActive(requestBody): Observable<any> {
    const url = Constants.BASE_URL + 'v1/currency-exchange/enable';
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  createExchangeRate(requestBody): Observable<any> {
    const url = Constants.BASE_URL + 'v1/currency-exchange/create';
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getBaseCurrency(requestBody): Observable<any> {
    const url = Constants.BASE_URL + "v1/common/currency/list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  fileUpload(requestBody): Observable<any> {
    const url = Constants.BASE_URL + "v1/file-upload/upload";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  addApprovalWorkflow(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/approval-workflows/create";
    const body = requestBody;
    return this.httpClient.post(url, body);

  }

  getApprovalWorkflowTypes(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/approval-workflows/types";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getApprovalWorkflows(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/approval-workflows/list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  enableApproval(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/approval-workflows/enable";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  disableApproval(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/approval-workflows/disable";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  financialPoliciesList(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/policy/list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  createFinancialPol(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/policy/create";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getFinancialPol(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/policy/get";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  enableFinancialPol(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/policy/enable";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  disableFinancialPol(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/policy/disable";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getSingleApproval(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/approval-workflows/get";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getUsersList(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/corporate-users/list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getDepartmentsist(requestBody: any): Observable<any> {
    const url = Constants.BASE_URL + "v1/department/list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getTeamssList(requestBody: any): Observable<any> {
    const url = Constants.BASE_URL + "v1/teams/list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getDesignationsList(requestBody: any): Observable<any> {
    const url = Constants.BASE_URL + "v1/designation/list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  enablePolicy(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/policy/enable";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  disablePolicy(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/policy/disable";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getBrandsList(requestBody: any): Observable<any> {
    const url = Constants.BASE_URL + "v1/band/list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  getAllLocations(requestBody): Observable<any> {
    const url = Constants.BASE_URL + "v1/locations/list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getCostCenterList(requestBody: any): Observable<any> {
    const url = Constants.BASE_URL + "v1/cost-centers/list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  
  getProjectCodesList(requestBody: any): Observable<any> {
    const url = Constants.BASE_URL + "v1/project-code/list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  employmentTypeList(requestBody): Observable<any> {
    const url = Constants.BASE_URL + "v1/employment-types/list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getMappedEntities(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/recurrent/mapped/data";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  settingsLCEnable(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/local-settings/local-conveyance/enable";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  settingsLCDisable(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/local-settings/local-conveyance/disable";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  settingsTabsAccess(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/recurrent/tabs/access";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  settingsPettyCashEnable(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/local-settings/petty-cash/enable";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  settingsPettyCashDisable(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/local-settings/petty-cash/disable";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  generalPolicyCreate(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/general-policy/create";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  generalPolicyGet(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/general-policy/get";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getTravelRequestData(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/travel-settings/get";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  travelRequestEnable(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/local-settings/travel/enable";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  travelRequestDisable(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/local-settings/travel/disable";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  travelRequestSave(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/travel-settings/save";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  RecurrenteamsList(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/recurrent/team/list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  RecurrentFinancerList(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/recurrent/financer/list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getSubCategories(categories: any) {
    let subCategories = [];
    categories.forEach(c => {c.children = []});
    categories.forEach(c => {
      if(c.parentCategoryId) {
        const index = categories.findIndex(c_i => c_i.categoryId == c.parentCategoryId);
        if(index >= 0) {
          categories[index].children.push(c);
        }
      }
    })
    subCategories = categories.filter((c) => c.parentCategoryId == null);
    return subCategories;
  }

  searchSubCategories(categories: any) {
    categories.forEach(c => {
      c.children = [];
      c.isChild = false;
    });
    categories.forEach((c, i) => {
      if(c.parentCategoryId) {
        const index = categories.findIndex(c_i => c_i.categoryId == c.parentCategoryId);
        if(index >= 0) {
          categories[index].children.push(c);
          c.isChild = true;
        }
      }
    })    
    return categories.filter((c) => !c.isChild);
  }

  createBudgetActual(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/budget-vs-actuals/create-budget-vs-actuals";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getBudgetActual(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/budget-vs-actuals/get-budget-vs-actuals";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getBudgetActualList(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/budget-vs-actuals/list-budget-vs-actuals";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  enableBudgetActual(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/budget-vs-actuals/enable-budget-vs-actuals";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  disableBudgetActual(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/budget-vs-actuals/disable-budget-vs-actuals";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  
  getBudgetActualClaims(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/budget-vs-actuals/budget-vs-actuals-claim";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getBudgetActualHistory(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/budget-vs-actuals/budget-vs-actuals-history";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getSystemFieldsDropdownList(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/company-fields/list-dropdown-fields";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }


}