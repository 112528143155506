import { Component, OnInit, Renderer2 } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Injectable } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { OrganizationService } from '../../../services/organization.service';
import { Title } from '@angular/platform-browser'
import  { CommonService } from '../../../services/common-services/common.service';
import { PlatformService } from 'src/app/services/platformbase.service';
import { OrganizationStructure } from '../../../models/organization.model';
import { SelectLegalEntity } from '../../../models/Settings/selecteLegalEntity.model';
import { SharedService } from 'src/app/services/common-services/shared.service';
import { NavController } from '@ionic/angular';

const LOAD_MORE = 'LOAD_MORE';

export class LoadmoreNode {
  childrenChange = new BehaviorSubject<LoadmoreNode[]>([]);
  get children(): LoadmoreNode[] {
    return this.childrenChange.value;
  }

  constructor(public item: any,
    public hasChildren = false,
    public loadMoreParentItem: any | null = null) { }
}

/** Flat node with expandable and level information */
export class LoadmoreFlatNode {
  constructor(public item: any,
    public level = 1,
    public expandable = false,
    public loadMoreParentItem: any | null = null) { }
}

/**
 * A database that only load part of the data initially. After user clicks on the `Load more`
 * button, more data will be loaded.
 */
@Injectable()
export class LoadmoreDatabase {
  batchNumber = 20;
  dataChange = new BehaviorSubject<LoadmoreNode[]>([]);
  nodeMap = new Map<any, LoadmoreNode>();
  dataMap: any;

  initialize(rootLevel, childLevel) {
    if (childLevel) {
      this.dataMap = new Map<any, any[]>(childLevel);
    }
    const data = rootLevel.map(name => this._generateNode(name));
    this.dataChange.next(data);
  }

  /** Expand a node whose children are not loaded */
  loadMore(item: any, onlyFirstTime = false) {
    if (!this.nodeMap.has(item) || !this.dataMap.has(item)) {
      return;
    }
    const parent = this.nodeMap.get(item)!;
    const children = this.dataMap.get(item)!;
    if (onlyFirstTime && parent.children!.length > 0) {
      return;
    }
    const newChildrenNumber = parent.children!.length + this.batchNumber;
    const nodes = children.slice(0, children.length)
      .map(name => this._generateNode(name));
    if (newChildrenNumber < children.length) {
      // Need a new load more node
      //nodes.push(new LoadmoreNode(LOAD_MORE, false, item));
    }
    parent.childrenChange.next(nodes);
    this.dataChange.next(this.dataChange.value);
  }

  private _generateNode(item: any): LoadmoreNode {
    if (this.nodeMap.has(item)) {
      return this.nodeMap.get(item)!;
    }
    const result = new LoadmoreNode(item, this.dataMap.has(item));
    this.nodeMap.set(item, result);
    return result;
  }
}
/** Tree table structure code ends here */

@Component({
  selector: 'app-organization-structure',
  templateUrl: './organization-structure.component.html',
  styleUrls: ['./organization-structure.component.scss'],
  providers: [LoadmoreDatabase]

})
export class OrganizationStructureComponent implements OnInit {
  nodeMap = new Map<any, LoadmoreFlatNode>();
  treeControl: FlatTreeControl<LoadmoreFlatNode>;
  treeFlattener: MatTreeFlattener<LoadmoreNode, LoadmoreFlatNode>;
  // Flat tree data source
  dataSource: MatTreeFlatDataSource<LoadmoreNode, LoadmoreFlatNode>;
  legalEntityState: Observable<any>;
  isSideBarOpen: Boolean = true;
  displayStatus: Array<boolean> = [];
  isDisable: Array<boolean> = [];
  disabling: Array<boolean> = [];
  activating: Array<boolean> = [];
  isActive: Boolean = false;
  isDisableSelected: Boolean = false;
  isActiveSelected: Boolean = true;
  legalEntityData: any;
  showLoader: Boolean = true;
  hideLoader: Boolean = false;
  leftSidebarCollapsed: any;
  menuName: any;
  tempUserInfo: {};
  path: any;
  selectedLegalEntity: any;
  pageTitle: string = "Organisation Structure";
  customersPath: any;
  legalEntitiesBody: { "company_Id": any; "company_Guid": any; "searchText": string; "pageSize": number; "pageNumber": number; };
  userInfo: any;
  selectedLegalEntityInfo: any;
  platformType:number;
  selectLegalEntity = new OrganizationStructure();
  selectLegalEntityModel = new SelectLegalEntity();
  loaderList: any = [1,2,3];
  
  constructor( private title: Title, private platformService:PlatformService,
    private database: LoadmoreDatabase, private commonService : CommonService,
    private router: NavController, private sharedService: SharedService,
    private organizationService: OrganizationService,
    private renderer: Renderer2, private activatedRoute: ActivatedRoute) {
    this.selectedLegalEntity = JSON.parse(sessionStorage.getItem('selectedLegalEntity'))
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<LoadmoreFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    database.dataChange.subscribe(data => {
      this.dataSource.data = data;
    });

    this.renderer.addClass(document.body, "legalentitiespage");
  }

  ngOnDestroy() {}

  ionViewWillLeave(){
    this.renderer.removeClass(document.body, "legalentitiespage");
  }

  getChildren = (node: LoadmoreNode): Observable<LoadmoreNode[]> => node.childrenChange;


  transformer = (node: LoadmoreNode, level: number) => {
    const existingNode = this.nodeMap.get(node.item);
    if (existingNode) {
      return existingNode;
    }
    const newNode = new LoadmoreFlatNode(node.item, level, node.hasChildren, node.loadMoreParentItem);
    this.nodeMap.set(node.item, newNode);
    return newNode;
  }

  getLevel = (node: LoadmoreFlatNode) => node.level;

  isExpandable = (node: LoadmoreFlatNode) => node.expandable;

  hasChild = (_: number, _nodeData: LoadmoreFlatNode) => _nodeData.expandable;

  isLoadMore = (_: number, _nodeData: LoadmoreFlatNode) => _nodeData.item === LOAD_MORE;

  /** Load more nodes from data source */
  loadMore(item: any) {
    this.database.loadMore(item);
  }

  loadChildren(node: LoadmoreFlatNode) {
    this.database.loadMore(node.item, true);
  }

  array(n: number): any[] {
    return Array(n);
  }

  ngOnInit() {}

  ionViewWillEnter(){
    this.platformType = this.platformService.getPlatformType();
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.title.setTitle(this.pageTitle);
    this.path = sessionStorage.getItem("path");
    if (this.path === "orgStructure") {
      sessionStorage.setItem("path", "");
    }

    if (this.userInfo.companyId && this.userInfo.companyGuId) {
        this.selectLegalEntity.companyId = this.userInfo.companyId;
        this.selectLegalEntity.companyGuid = this.userInfo.companyGuId;
        this.selectLegalEntity.searchText= "";
        this.selectLegalEntity.pageSize= 25;
        this.selectLegalEntity.pageNumber= -1;
    } else {
      this.selectLegalEntity.companyId = this.userInfo.companyId;
      this.selectLegalEntity.companyGuid = this.userInfo.companyGuId;
      this.selectLegalEntity.searchText= "";
      this.selectLegalEntity.pageSize= 25;
      this.selectLegalEntity.pageNumber= -1
    }

    this.commonService.getAllLegalEntities(this.selectLegalEntity).subscribe(data => {
      if (data) {   
        this.legalEntityData = data.payload.legalEntityList;
        this.loadTableData(this.legalEntityData);
        this.showLoader = false;
        this.hideLoader = true;
      } else if(!data) {
        this.showLoader = false;
        this.hideLoader = true;
      }
    });
      this.selectLegalEntity.companyId = this.userInfo.companyId,
      this.selectLegalEntity.companyGuid = this.userInfo.companyGuId,
      this.selectLegalEntity.legalEntityId = this.userInfo.legalEntityId,
      this.selectLegalEntity.legalEntityGuid = this.userInfo.legalEntityGuId,
      this.selectLegalEntity.legalEntityCode = this.userInfo.companyCode,
      this.selectLegalEntity.legaEntityName = this.userInfo.companyName
  }

  showStatus(nodeData) {
    this.displayStatus[nodeData.legalEntityGuid] = !this.displayStatus[nodeData.legalEntityGuid];
  }

  companydetails(node) {
       this.selectLegalEntityModel.companyId = node.item.companyId,
       this.selectLegalEntityModel.companyGuId = node.item.companyGuid,
       this.selectLegalEntityModel.legalEntityId = node.item.legalEntityId,
       this.selectLegalEntityModel.legalEntityGuId = node.item.legalEntityGuid,
       this.selectLegalEntityModel.legalEntityName = node.item.legaEntityName,
       this.selectLegalEntityModel.legalEntityCode =  node.item.legalEntityCode,
       this.selectLegalEntityModel.legalEntityLogo = node.item.logoPath
    let info = {
      logo: node.item.logoPath,
      name: node.item.legaEntityName,
      legal_Entity_Count: node.item.subLegalEntityCount
    };
    sessionStorage.setItem("companyInfo", JSON.stringify(info));
    sessionStorage.setItem("selectedLegalEntity", JSON.stringify(this.selectLegalEntityModel));
    this.leftSidebarCollapsed = true;
    this.menuName = "company";
    this.router.navigateRoot(["/settings/accountconfig"]);
  }

  navigateToAddLegalEntity(event, node) {
    const nodeBody = {
      "selectedNode": node.item,
      "entityList": this.legalEntityData
    };
    if (nodeBody.selectedNode.parentId == null) {
      this.router.navigateRoot(["home/addLegalEntity", nodeBody.selectedNode.legalEntityId, nodeBody.selectedNode.legalEntityGuid]);
    } else {
      this.router.navigateRoot(["home/addLegalEntity", nodeBody.selectedNode.legalEntityId, nodeBody.selectedNode.legalEntityGuid, nodeBody.selectedNode.parentId ? nodeBody.selectedNode.parentId : null]);
    }
  }

  showDisable(nodeData) {
    this.isDisable[nodeData.legalEntityGuid] = !this.isDisable[nodeData.legalEntityGuid];

  }

  closeDropdown(e: any) {
    this.isDisable = [];
    this.displayStatus = [];
  }

  loadTableData(legalEntityData) {
    let rootLevelNodes: any = [];
    let firstLevelNodes: any = [];
    let secondLevelNodes: any = [];
    let subChildLevels = [];
    let firstChildTemp = [];
    let subChildFirstLevelTemp = [];
    let secondChildTemp = [];
    let subChildSecondLevelTemp = [];
    //legalEntityList
    legalEntityData.forEach(rootLevelData => {
      if (rootLevelData.parentId == null) {
        rootLevelData.childCount = 0;
        rootLevelNodes.push(rootLevelData);
      }
    });
    //legalEntityList
    legalEntityData.forEach(childLevelOneData => {
      if (childLevelOneData.parentId) {
        rootLevelNodes.forEach(data => {
          if (data.legalEntityId == childLevelOneData.parentId) {
            data.childCount++;
            childLevelOneData.childCount = 0;
            firstLevelNodes.push(childLevelOneData)
          }
        })
      }
    });
    //legalEntityList
    legalEntityData.forEach(childLevelTwoData => {
      if (childLevelTwoData.parentId) {
        firstLevelNodes.forEach(data => {
          if (data.legalEntityId == childLevelTwoData.parentId) {
            data.childCount++;
            childLevelTwoData.childCount = 0;
            secondLevelNodes.push(childLevelTwoData)
          }
        })
      }
    });

    for (let i = 0; i < rootLevelNodes.length; i++) {
      firstChildTemp = [];
      subChildFirstLevelTemp = [];
      for (let j = 0; j < firstLevelNodes.length; j++) {
        if (rootLevelNodes[i].legalEntityId == firstLevelNodes[j].parentId) {
          firstChildTemp.push(firstLevelNodes[j]);
        }
      }
      if (firstChildTemp.length != 0) {
        subChildFirstLevelTemp.push(rootLevelNodes[i]);
        subChildFirstLevelTemp.push(firstChildTemp);
        subChildLevels.push(subChildFirstLevelTemp);
      }
    }

    for (let i = 0; i < firstLevelNodes.length; i++) {
      secondChildTemp = [];
      subChildSecondLevelTemp = [];
      for (let j = 0; j < secondLevelNodes.length; j++) {
        if (firstLevelNodes[i].legalEntityId == secondLevelNodes[j].parentId) {
          secondChildTemp.push(secondLevelNodes[j]);
        }
      }
      if (secondChildTemp.length != 0) {
        subChildSecondLevelTemp.push(firstLevelNodes[i]);
        subChildSecondLevelTemp.push(secondChildTemp);
        subChildLevels.push(subChildSecondLevelTemp);
      }
    }
    this.database.initialize(rootLevelNodes, subChildLevels);
  }

  navigateToHome() {
     this.router.navigateRoot(["/settings/accountconfig"]);
     this.selectLegalEntityModel.companyId = this.userInfo.companyId;
     this.selectLegalEntityModel.companyGuId = this.userInfo.companyGuId;
     this.selectLegalEntityModel.legalEntityId = this.userInfo.legalEntityId;
     this.selectLegalEntityModel.legalEntityGuId = this.userInfo.legalEntityGuId;
     this.selectLegalEntityModel.legalEntityName = this.userInfo.companyName;
     this.selectLegalEntityModel.legalEntityLogo = this.userInfo.legalEntityLogoPath;
     sessionStorage.setItem("selectedLegalEntity", JSON.stringify(this.selectLegalEntityModel));
     this.sharedService.setCompanyInfo(this.selectLegalEntityModel);
  }


  
}
