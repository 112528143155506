export class Merchant {
    merchantId: number;
    merchantGUID: string;
    merchantName: string;
    taxPercentage:number;
    merchantCode:any;
    taxNumber:any;
    location:any;
    addressLine1:any;
    addressLine2: any;
    countryId: any;
    countryName: any;
    stateId: any;
    stateName: any;
    cityId:any;
    cityName:any;
    postalCode: any;
    categoryId: any;
    categoryName: any;
    categoryList = [];
    categoryType: any;
    legalEntityId: number;
    legalEntityGUID: string;
    description: any;
    status: any;
    pageSize:any;
    pageNumber:any;
}

export class MerchantList {
    merchantId: number;
    merchantGUID: string;
    merchantName: string;
    categoryId: any;
    categoryName: any;
    categoryList:any;
    legalEntityId: number;
    legalEntityGUID: string;
    description: any;
    status: any;
    categoryType: any;
    taxPercentage:number;
    location:any;
    addressLine1:any;
    addressLine2: any;
    countryId: any;
    countryName: any;
    stateId: any;
    stateName: any;
    cityId:any;
    cityName:any;
    postalCode: any;
    merchantCode:any;
    taxNumber:any;
    pageSize:any;
    pageNumber:any;
    constructor(list) {
        this.merchantId = list.merchantId;
        this.merchantGUID = list.merchantGUID;
        this.merchantName = list.merchantName;
        this.categoryId = list.categoryId;
        this.categoryName = list.categoryName;
        this.categoryList=list.categoryList;
        this.categoryType = list.categoryType;
        this.description = list.description;
        this.status = list.status;
        this.legalEntityId = list.legalEntityId;
        this.legalEntityGUID = list.legalEntityGUID;
        this.taxPercentage=list.taxPercentage;
        this.location=list.location;
        this.addressLine1 = list.addressLine1;
        this.addressLine2 = list.addressLine2;
        this.postalCode = list.postalCode;
        this.merchantCode=list.merchantCode;
        this.taxNumber=list.taxNumber;
        this.countryId=list.countryId;
        this.countryName=list.countryName;
        this.stateName=list.stateName;
        this.stateId=list.stateId;
        this.cityId = list.cityId;
        this.cityName =list.cityName;
        this.pageNumber = list.pageNumber;
        this.pageSize = list.pageSize;
    }
}