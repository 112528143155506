import { Injectable } from '@angular/core';
import { Constants } from '../Constants';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AccountConfigService {

  constructor(public httpClient: HttpClient) { }

  getAccountConfig(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/local-settings/account-config/get";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  saveAccountConfig(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/local-settings/account-config/save";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

}
