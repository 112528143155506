import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor() { }

  private sideBarFlag = new BehaviorSubject<boolean>(true);
  private expenseData = new BehaviorSubject<any>(null);
  private expensesList = new BehaviorSubject<any>(null);
  private reportData = new BehaviorSubject<boolean>(false);
  private refreshData = new BehaviorSubject<boolean>(false);
  private userInfo = new BehaviorSubject<boolean>(false);
  private personalInformation = new BehaviorSubject<any>('');
  private userLogo = new BehaviorSubject<any>('');
  private companyInformation = new BehaviorSubject<any>('');
  private refreshDashboard = new BehaviorSubject<boolean>(false);
  private AdvanceData = new BehaviorSubject<boolean>(false);
  private isUserToggle = new BehaviorSubject<boolean>(false);
  private EmailApproval = new BehaviorSubject<any>('');
  private shareLC = new Subject<any>();
  private shareMapData = new BehaviorSubject<any>('');
  private catgApi = new BehaviorSubject<any>('');
  private pettyCashData = new BehaviorSubject<boolean>(false);
  private roomsCount = new BehaviorSubject<any>('');
  private shareTravelTrip = new BehaviorSubject<any>('');
  private pre_category = new BehaviorSubject<any>('');
  private reportSummaryData = new BehaviorSubject<any>('');
  private pending_approvals = new BehaviorSubject<number>(0);
  public maintenanceFlag = new BehaviorSubject<boolean>(false);

  private selectedRecords: any[] = [];

  unsubscribeServic(service: any) {
    if (service) {
      service.unsubscribe();
    }
  }
  setSideBarFlag(flag: any) {
    this.sideBarFlag.next(flag);
  }

  getSideBarFlag(): Observable<any> {
    return this.sideBarFlag.asObservable();
  }

  setExpenseData(data: any) {
    this.expenseData.next(data);
  }

  getExpenseData(): Observable<any> {
    return this.expenseData.asObservable();
  }

  setExpenseListData(data: any) {
    this.expensesList.next(data);
  }

  getExpenseListData(): Observable<any> {
    return this.expensesList.asObservable();
  }

  setReportData(data: any) {
    this.reportData.next(data);
  }

  getReportData(): Observable<any> {
    return this.reportData.asObservable();
  }

  setRefreshData(flag: any) {
    this.refreshData.next(flag);
  }

  getRefreshData(): Observable<any> {
    return this.refreshData.asObservable();
  }

  setUserInfo(flag: any) {
    this.userInfo.next(flag);
  }

  getUserInfo(): Observable<any> {
    return this.userInfo.asObservable();
  }

  setPersonalInfo(value: any) {
    this.personalInformation.next(value);
  }

  getPersonalInfo(): Observable<any> {
    return this.personalInformation.asObservable();
  }

  setUserLogo(data: any) {
    this.userLogo.next(data);
  }

  getUserLogo(): Observable<any> {
    return this.userLogo.asObservable();
  }

  setCompanyInfo(value: any) {
    this.companyInformation.next(value);
  }


  getCompanyInfo(): Observable<any> {
    return this.companyInformation.asObservable();
  }

  setRefreshDashboard(value: any) {
    this.refreshDashboard.next(value);
  }

  getRefreshDashboard(): Observable<any> {
    return this.refreshDashboard.asObservable();
  }

  // setAdvanceData(data: any) {
  //   this.AdvanceData.next(data);
  // }

  // getAdvanceData(): Observable<any> {
  //   return this.AdvanceData.asObservable();
  // }

  setPettyCashData(data: any) {
    this.pettyCashData.next(data);
  }

  getPettyCashData(): Observable<any> {
    return this.pettyCashData.asObservable();
  }

  setPeningApprovalCount(data:any){
    this.pending_approvals.next(data);
  }

  getPeningApprovalCount(): Observable<any>{
    return this.pending_approvals.asObservable();
  }

  setUserToggle(flag: any) {
    this.isUserToggle.next(flag);
  }

  getUserToggle(): Observable<any> {
    return this.isUserToggle.asObservable();
  }

  setEmailApprovalInfo(obj: any) {
    this.EmailApproval.next(obj);
  }

  getEmailApprovalInfo(): Observable<any> {
    return this.EmailApproval.asObservable();
  }

  setLoadCatgryApi(flag: any) {
    this.catgApi.next(flag);
  }

  getLoadCatgryApi(): Observable<any> {
    return this.catgApi.asObservable();
  }

  setMapData(obj: any) {
    this.shareMapData.next(obj);
  }

  getMapData(): Observable<any> {
    return this.shareMapData.asObservable();
  }
  setTravelTrip(obj: any) {
    this.shareTravelTrip.next(obj);
  }
  getTravelTrip(): Observable<any> {
    return this.shareTravelTrip.asObservable();
  }
  setRoomCount(data: any) {
    this.roomsCount.next(data);
  }
  getRoomCount(): Observable<any> {
    return this.roomsCount.asObservable();
  }

  setPreCategory(obj: any) {
    this.pre_category.next(obj);
  }
  getPreCategory(): Observable<any> {
    return this.pre_category.asObservable();
  }

  setReportSummaryData(obj: any) {
    this.reportSummaryData.next(obj);
  }
  getReportSummaryData(): Observable<any> {
    return this.reportSummaryData.asObservable();
  }


  getSelectedRecords() {
    return this.selectedRecords;
  }

  setSelectedRecords(records: any[]) {
    this.selectedRecords = records;
  }

  addRecord(record: any) {
    this.selectedRecords.push(record);
  }

  removeRecord(record: any) {
    this.selectedRecords = this.selectedRecords.filter(r => r.transactionId !== record.transactionId);
  }

  setMaintenanceflag(value) {
    this.maintenanceFlag.next(value);
  }

  getMaintenanceflag() {
    return this.maintenanceFlag.asObservable();
  }



  findGoogleModule(): Promise<any> {
    let win = window as any;
    let promise = new Promise((resolve, reject) => {
      let googleModules = win['google'] ? win['google']['maps'] : '';
      if (googleModules) resolve(googleModules);
      else {
        let googlekey;
        let googleautoComplete = document.getElementById('googleAutoComplete') as HTMLScriptElement;
        let scriptfile = document.createElement('script');
        let user = JSON.parse(sessionStorage.getItem("userInfo"));
        if (user) googlekey = user.googleKey;
        scriptfile.src = "https://maps.googleapis.com/maps/api/js?libraries=places&language=en&key=" + googlekey;
        scriptfile.type = 'text/javascript';
        scriptfile.async = true;
        scriptfile.id = 'googleAutoComplete';
        if (!googleautoComplete) {
          document.getElementsByTagName('body')[0].appendChild(scriptfile);
          scriptfile.onload = () => {
            win = window as any;
            resolve(win['google']['maps']);
          }
        }
      }
    })
    return promise;
  }
}
