import { Injectable } from '@angular/core';
// import { Storage } from '@ionic/storage';
@Injectable()
export class AuthService {
  // authenticationFlag:any;
  constructor(
    // public storage: Storage
  ) { }
  public isAuthenticated(): boolean {
    const authenticationFlag = sessionStorage.getItem('isAuthenticated');
    // this.storage.get('isAuthenticated').then(auth=>{
    //   this.authenticationFlag = auth
    // })
    if (authenticationFlag == null) {
      return true;
    } else {
      // true or false
      return false;
    }
  }

  // isAuthenticate(): Promise<boolean> {
  //   this.storage.get('isAuthenticated').then(result =>{
  //    if(result == null){
  //        return true;
  //    }
  //    else {
  //      return false;
  //    }
  //  })
  //  return
  // }

}
