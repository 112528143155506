import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { createEmailConfig, getEmailConfigRequest, recipientList } from '../email-configurations/emailconfig';
import { EmailConfigurationService } from '../../../services/email-configuration.service';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { SettingsService } from 'src/app/services/settings-services';

@Component({
  selector: 'app-email-configurations-edit',
  templateUrl: './email-configurations-edit.component.html',
  styleUrls: ['./email-configurations-edit.component.scss']
})
export class EmailConfigurationsEditComponent implements OnInit {
  @ViewChild("intimateHead", { static: false }) intimateHeadAutoComplete: ElementRef;
  emailConfigurationForm: FormGroup;
  public fieldCCArray: Array<any> = [];
  fieldCCArraylength: number = 0;
  fieldIntimateArraylength: number = 0;
  approvalTypeList: any;
  showCCEmail: Array<Boolean> = [];
  userInfo: any;
  selectedLegalEntityInfo: any;
  createEmailConfigModel = new createEmailConfig();
  getEmailConfigRequestModel = new getEmailConfigRequest();
  IntimateListModel = new recipientList();
  pageTitle: string = "Settings | Email Configurations";
  showLoader: boolean = true;
  buttonDisabled: boolean = false;
  deleteCCEmail: boolean = false;
  readOnlyValue: boolean = false;
  deleteIntimateCCEmail: boolean = false;
  showOtherEmailMsg: boolean = false;
  showEmailPatternErr: Array<Boolean> = [] ;
  showCCEmailPatternErr: Array<Boolean> = [];
  loaderArray = [1,2,3,4];
  private emailPattern =  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  private commaEmailPattern = /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/g;
  approvalTypeData: any;
  emailConfigGetData: any;


  constructor(private fb: FormBuilder, private renderer: Renderer2, private router: Router, private settingsService: SettingsService, private title: Title, private emailConfigService: EmailConfigurationService, private activatedRoute: ActivatedRoute, private toastr: ToastrService) {
    this.renderer.addClass(document.body, 'editEmailConfigPage');
  }

  ngOnDestroy(){
    this.renderer.removeClass(document.body, 'editEmailConfigPage');
  }

  ngOnInit() {
    this.emailConfigGetData = JSON.parse(sessionStorage.getItem('emailConfigDetails'));
    this.title.setTitle(this.pageTitle);
    this.selectedLegalEntityInfo = JSON.parse(sessionStorage.getItem("selectedLegalEntity"));
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.emailConfigurationForm = this.fb.group({
      templateName: [''],
      emailDisplay: [''],
      replyToEmail: ['', Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]
    })
    this.settingsService.getApprovalWorkflowTypes('').subscribe(data => {
      if (data.payload) {
        this.approvalTypeList = data.payload;
        this.approvalTypeList.push({'approverTypeId': 0, 'approverTypeName': 'Others', 'status': 1});
      }
    });
    if(this.emailConfigGetData.emailTypeId && this.emailConfigGetData.emailTypeGuid){
      this.getEmailConfiguration();
    }
  }

  getEmailConfiguration(){
    this.getEmailConfigRequestModel.emailConfigurationId = this.emailConfigGetData.emailConfigId;
    this.getEmailConfigRequestModel.emailConfigurationGUID = this.emailConfigGetData.emailConfigGuid;
    this.getEmailConfigRequestModel.emailTypeId = this.emailConfigGetData.emailTypeId;
    this.getEmailConfigRequestModel.emailTypeGUID = this.emailConfigGetData.emailTypeGuid;
    this.getEmailConfigRequestModel.legalEntityId = this.selectedLegalEntityInfo?.legalEntityId || this.userInfo.legalEntityId;
    this.getEmailConfigRequestModel.legalEntityGUID = this.selectedLegalEntityInfo?.legalEntityGuId || this.userInfo.legalEntityGuId;
    this.emailConfigService.getSingleEmailConfiguration(this.getEmailConfigRequestModel).subscribe(data => {
      if(data.payload){
        this.showLoader = false;
        this.createEmailConfigModel = data.payload;
        this.createEmailConfigModel.recipientList.forEach((ele,i) => {
          if(ele.approverTypeId){
            this.showCCEmail[i] = false;
          }else{
            this.showCCEmail[i] = true;
          }
        })
          this.fieldCCArray = this.createEmailConfigModel.ccList;
          this.addCCEmailFieldValue();
          this.addIntimateEmailFieldValue();
      }
    })
  }

  backToEmailConfig(){
    this.router.navigate(['settings/emailconfigurations']);
    sessionStorage.removeItem("emailConfigDetails");
  }

  searchIntimateHead(val){
    let searchText = val.trim();
    let filterData = this.approvalTypeList.filter(data => {
          if (data.approverTypeName.toLowerCase().indexOf(searchText.toLowerCase()) >= 0) {
            return data;
          }
        });
      if(filterData && filterData.length > 0)
      this.approvalTypeData = filterData;
      else if(searchText == "")
        this.approvalTypeData = this.approvalTypeList;
  }

  onApproveEventFocus(){
    setTimeout(() => {
      this.approvalTypeData = this.approvalTypeList;
    }, 0);
  }

  addCCEmailFieldValue() {
    let ccEmailArr = _.filter(this.fieldCCArray, function(ele){ return ele.ccEmail.length == 0});
    if(ccEmailArr.length > 0){
      this.toastr.warning("Please enter CC Email to Add New");
    }else{
      this.readOnlyValue = true;
      this.deleteCCEmail = false;
      const addFile = {
        'ccEmail' : "",
        'id': null
       };
       this.fieldCCArray.push(addFile);
    }
  }

  deleteCCEmailValue(index) {
    this.fieldCCArray.splice(index, 1);
    this.fieldCCArraylength = this.fieldCCArray.length;
    this.deleteCCEmail = true;
    this.showCCEmailPatternErr.splice(index,1);
  }

  ccEmailInput(val, i) {
    this.fieldCCArray[i].ccEmail = val;
    if(this.fieldCCArray[i].ccEmail){
      this.showCCEmailPatternErr[i] = !this.emailPattern.test(this.fieldCCArray[i].ccEmail);
    }else{
      this.showCCEmailPatternErr[i] = false;
    }
  }

  addIntimateEmailFieldValue() {
    let intimateEmailArr = _.filter(this.createEmailConfigModel.recipientList, function(ele){ return ele.approverTypeName.length == 0});
    let intimateOthersArr = _.filter(this.createEmailConfigModel.recipientList, function(ele){
      if(ele.approverTypeName == 'Others'){
        return ele.otherEmailIds.length == 0
    }
  });

    if(intimateEmailArr.length > 0){
      this.toastr.warning("Please select Approver to Add New");
    }else if(intimateOthersArr.length > 0){
      this.toastr.warning("Please enter email to Add New");
    }else{
    this.deleteIntimateCCEmail = false;
    let intimateListAddFile =  new recipientList();
    intimateListAddFile.approverTypeId = '';
    intimateListAddFile.otherEmailIds = '';
    intimateListAddFile.approverTypeName = '';
    this.createEmailConfigModel.recipientList.push(intimateListAddFile);
  }
}

  ccIntimateEmailInput(val,i){
    this.createEmailConfigModel.recipientList[i].otherEmailIds = val;
    if(!this.createEmailConfigModel.recipientList[i].otherEmailIds){
      this.showOtherEmailMsg = true;
      this.showEmailPatternErr[i] = false;
    }else{
      this.showOtherEmailMsg = false;
      this.showEmailPatternErr[i] = !this.commaEmailPattern.test(this.createEmailConfigModel.recipientList[i].otherEmailIds);
    }
  }

  deleteIntimateEmailFieldValue(index){
    this.createEmailConfigModel.recipientList.splice(index,1);
    this.fieldIntimateArraylength = this.createEmailConfigModel.recipientList.length;
    this.deleteIntimateCCEmail = true;
    this.showCCEmail.splice(index,1);
    this.showOtherEmailMsg = false;
    this.showEmailPatternErr.splice(index,1);
  }
  selectedIntimateHead(e,i,event){
    this.createEmailConfigModel.recipientList[i].approverTypeId = e.approverTypeId;
    this.createEmailConfigModel.recipientList[i].approverTypeName = e.approverTypeName;
     if(this.createEmailConfigModel.recipientList[i].approverTypeId == 0){
      this.showCCEmail[i] = true;
      if(!this.createEmailConfigModel.recipientList[i].otherEmailIds){
        this.showOtherEmailMsg = true;
      }else{
        this.showOtherEmailMsg = false;
      }
    }
  }
  updateEmailConfig(){
    this.createEmailConfigModel.legalEntityId = this.selectedLegalEntityInfo.legalEntityId;
    this.createEmailConfigModel.legalEntityGUID = this.selectedLegalEntityInfo.legalEntityGuId;
    this.createEmailConfigModel.emailConfigurationId = this.emailConfigGetData.emailConfigId;
    this.createEmailConfigModel.emailConfigurationGUID = this.emailConfigGetData.emailConfigGuid;
    this.buttonDisabled = true;
    let ccarr = this.fieldCCArray.filter(ele => ele.ccEmail != "");
    this.createEmailConfigModel.ccList = ccarr;
    this.createEmailConfigModel.recipientList = this.createEmailConfigModel.recipientList.filter(ele => ele.approverTypeName!=="");
    this.emailConfigService.updateSingleEmailConfiguration(this.createEmailConfigModel).subscribe(data => {
      if(data.errorCode == 0){
        this.buttonDisabled = false;
        this.toastr.success(data.payload,'Success');
        this.router.navigate(['/settings/emailconfigurations']);
      }else{
        this.buttonDisabled = false;
        this.toastr.error(data.payload,'Error');
      }
    })
  }
  // validIntimateEmail(){
  //   let emailErrCount = this.showEmailPatternErr.filter(ele => ele == true);
  //   if(emailErrCount.length > 0 ){
  //     return true;
  //   }else{
  //     return false;
  //   }
  // }
  // validCCEmail(){
  //   let emailErrCount = this.showCCEmailPatternErr.filter(ele => ele == true);
  //   if(emailErrCount.length > 0 ){
  //     return true;
  //   }else{
  //     return false;
  //   }
  // }
}
