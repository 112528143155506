
export class hsnCodeIntegration {
    legalEntityGUID: string;
    legalEntityId: number;
    hsnId: number;
    hsnGUID: string;
    hsnName: string;
    hsncode:string;
    isDefault:boolean;
    mileageUnit: string;
    mileageRate: number;
    mileageRateCurrencyId: number;
    mileageRateCurrencyCode: string;
    mileageRateCurrencyIcon: string;
    pageNumber: number;
    pageSize: number;
    sortType: any;
    sortColumn: any;
    statusId: number;
    categoryType: any;
  }
  export class hsnIntegrationList {
    legalEntityGUID: string;
    legalEntityId: number;
    categoryType: any;
    hsnId: number;
    hsnGUID: string;
    hsnName: string;
    hsncode:string;
    showOnDashboard: boolean;
    mileageUnit: string;
    mileageRate: number;
    mileageRateCurrencyId: number;
    mileageRateCurrencyCode: string;
    mileageRateCurrencyIcon: string;
    pageNumber: number;
    isDefault:boolean;
    pageSize: number;
    sortType: any;
    sortColumn: any;
    statusId: number;
    constructor(list) {
      this.legalEntityGUID = list.legalEntityGUID;
      this.legalEntityId = list.legalEntityId;
      this.hsnId = list.hsnId;
      this.hsnGUID = list.hsnGUID;
      this.hsnName = list.hsnName;
      this.hsncode = list.hsncode;
      this.mileageUnit = list.mileageUnit;
      this.mileageRate = list.mileageRate;
      this.mileageRateCurrencyId = list.mileageRateCurrencyId;
      this.mileageRateCurrencyCode = list.mileageRateCurrencyCode;
      this.mileageRateCurrencyIcon = list.mileageRateCurrencyIcon;
      this.statusId = list.statusId;
      this.isDefault=list.isDefault;
      this.categoryType = list.categoryType;
    }
  }
  