import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { Location, isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class Constants {
  constructor(
    private http: HttpClient,
    private location: Location,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    Constants.DOMAIN_URL = environment.DOMAIN_URL;
  }
  public static BASE_URL;
  public static DOMAIN_URL;
  public static ENVIRONMENT;
  public static calendarDateFormat;
  public static apiDateFormat;
  public static mobileDateFormat;
  public static mobileCalenderFormat;
  public static required;
  public static EVA_URL;
  public static EVA_SSO_URL;

  load(): Promise<void> {
    return new Promise((resolve, reject) => {
      let jsonFile = '';
      if (isPlatformBrowser(this.platformId)) {
        jsonFile = this.location.prepareExternalUrl('/assets/config/config.json');
      } else {
        jsonFile = '/assets/config/config.json';
      }
      this.http.get(jsonFile).subscribe(
        response => {
          Constants.BASE_URL = environment.BASE_URL;
          Constants.DOMAIN_URL = environment.DOMAIN_URL;
          Constants.ENVIRONMENT = environment.Environment;
          Constants.calendarDateFormat = environment.calendarDateFormat;
          Constants.apiDateFormat = environment.apiDateFormat;
          Constants.mobileDateFormat = environment.mobileDateFormat;
          Constants.mobileCalenderFormat = environment.mobileCalenderFormat;
          Constants.EVA_URL = environment.eva_auth;
          Constants.EVA_SSO_URL = environment.eva_sso;
          resolve();
        },
        err => {
          alert('Data not loaded properly , please refresh the page');
          reject(`Could not load file '${jsonFile}': ${JSON.stringify(err)}`);
        }
      );
    });
  }
}
