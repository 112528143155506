import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-deeplink',
  templateUrl: './deeplink.component.html',
  styleUrls: ['./deeplink.component.scss'],
})
export class DeeplinkComponent implements OnInit {
  appName: any;
  ssoToken: any;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      // Extracting the last two values from the URL
      this.appName = params['app']; // Change 'param1' to the actual parameter name
      this.ssoToken = params['token']; // Change 'param2' to the actual parameter name

      // Now you can use these values as needed
      console.log('app Value:', this.appName);
      console.log('token Value:', this.ssoToken);

      var link = document.createElement('a');
      link.href = this.appName+'://login/'+this.ssoToken;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }

}
