import { Component, OnInit, NgZone, ViewChild, ElementRef, Input } from '@angular/core';
declare var $: any;
import * as CryptoJS from 'crypto-js';
import { FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { CustomDateService } from 'src/app/services/custom-date.service';


@Component({
  selector: 'app-custom-mat-datepicker',
  templateUrl: './custom-mat-datepicker.component.html',
  styleUrls: ['./custom-mat-datepicker.component.scss'],
})
export class CustomMatDatepickerComponent implements OnInit {


  @Input()
  openedFrom: string;
  @Input()
  date: any;

  @ViewChild('date') selectedDate: ElementRef;

  public showCalender: boolean = true;
  public closeCalender: boolean = false;
  public hours: any = [];
  public minutes: any = [];
  public currentDate: any;
  public timeForm: FormGroup;
  public getDate: any;
  public Date: any;
  public dateSelected: any;
  public diffrenceDays: any = 0;
  public showPrice: boolean = false;
  public maxDate: any = null;
  viewCartResponse: any;
  viewCart: boolean;
  showRightSide: boolean = false;

  constructor(
    private ngZone: NgZone,
    public datepipe: DatePipe,
    public customDate: CustomDateService
  ) {
  }

  ngOnInit() {
    this.showCalender = true;
    this.closeCalender = false;
    this.Date = new Date();
    if (!(this.openedFrom == "checkInDatePicker" || this.openedFrom == "checkOutDatePicker" || this.openedFrom == "addNewUserDatePicker" || this.openedFrom == "checkDateJoiningDatePicker" || this.openedFrom=="checkDateJoiningDatePicker")) {
      this.showPrice = true;
    } else {
      this.showPrice = false;
    }
    if (this.openedFrom == "addNewUserDatePicker" || this.openedFrom == "checkDateJoiningDatePicker") {
      this.diffrenceDays = null;
      this.maxDate = 0;
    } else {
        this.diffrenceDays = 0;
        this.maxDate = null;  
    }
    if (sessionStorage.getItem("selectedDate") != null) {
      this.dateSelected = sessionStorage.getItem("selectedDate");
        this.diffrenceDays = this.convertDate(this.dateSelected);
        this.selectedDate = this.dateSelected;
        this.currentDate = this.convertDateToUtc(sessionStorage.getItem("selectedDate"));
    }
    
    if(this.openedFrom != 'start' && sessionStorage.getItem('max_Date')) {
      this.maxDate = moment(sessionStorage.getItem('max_Date'), 'DD-MM-YYYY').diff(moment().format(), 'days');
      sessionStorage.removeItem('max_Date');
    }

    if(sessionStorage.getItem('datePickerRightSide')) {
      this.showRightSide = true;
    }

    var self = this
    $(
      function () {
        $("#startDate").datepicker({
          minDate: self.openedFrom == 'start'? null : self.diffrenceDays,
          maxDate: self.maxDate ? self.maxDate : 0,
          numberOfMonths: 2,
          firstDay: 1,
          autoClose: true,
          //The calendar is recreated OnSelect for inline calendar
          onSelect: function (date, dp) {
            var currentDate = $(this).val();
            this.currentDate = currentDate;
            sessionStorage.removeItem("date");
            //sessionStorage.setItem("date", JSON.stringify(this.currentDate));
            self.ngZone.run(() => {
              this.closeCalender = true;
              sessionStorage.setItem("close-calender", JSON.stringify(this.closeCalender));
              self.updateDatePickerCells();
            });
          },
          onChangeMonthYear: function (month, year, dp) {
            self.ngZone.run(() => {
              this.closeCalender = false;
              sessionStorage.setItem("close-calender", JSON.stringify(this.closeCalender));
              self.updateDatePickerCells();
            });
          },
          beforeShow: function (elem, dp) { //This is for non-inline datepicker
            self.ngZone.run(() => {
              this.closeCalender = false;
              sessionStorage.setItem("close-calender", JSON.stringify(this.closeCalender));
              self.updateDatePickerCells();
            });
          }
        });
        self.ngZone.run(() => {
          this.closeCalender = false;
          sessionStorage.setItem("close-calender", JSON.stringify(this.closeCalender));
          self.updateDatePickerCells();
        });
        if (self.date) {
          const formattedDate = moment(self.date, "DD-MM-YYYY").toDate();
          $('.date').datepicker('setDate', null);
          $('#startDate').datepicker("setDate", formattedDate);
        } else {
          const formattedDate = moment().toDate();
          $('.date').datepicker('setDate', null);
          $('#startDate').datepicker("setDate", formattedDate);
        }
      }
    );
  }

  convertDateToUtc(date) {
    return moment(moment(date, "DD-MM-YYYY").format("YYYY-MM-DD")).utc(true).toISOString(false);
  }

  //Funcation to insert text in calendar
  updateDatePickerCells() {
    if (this.showPrice) {
      setTimeout(function () {
        //Fill this with the data you want to insert (I use and AJAX request).  Key is day of month
        //NOTE* watch out for CSS special characters in the value
        var cellContents = { 1: '', 15: '', 28: '' };

        //Select disabled days (span) for proper indexing but // apply the rule only to enabled days(a)
        $('.ui-datepicker td > *').each(function (idx, elem) {
          var value = cellContents[idx + 1] || 0;

          // dynamically create a css rule to add the contents //with the :after
          //             selector so we don't break the datepicker //functionality
          var className = 'datepicker-content-' + CryptoJS.MD5(value).toString();          

          if (value == 0)
            addCSSRule('.ui-datepicker td a.' + className + ':after {content: "\\a0";}'); //&nbsp;
          else

            addCSSRule('.ui-datepicker td a.' + className + ':after {content: "' + value + '"; color: red;}');

          $(this).addClass(className);
        });
      }, 0);
    }

    var dynamicCSSRules = [];
    function addCSSRule(rule) {
      if ($.inArray(rule, dynamicCSSRules) == -1) {
        $('head').append('<style>' + rule + '</style>');
        dynamicCSSRules.push(rule);
      }
    }
    if (JSON.parse(sessionStorage.getItem("close-calender"))) {
      this.onSubmit();
    }
  }

  onSubmit() {
    if (sessionStorage.getItem("date") == null) {
      this.getDate = new Date();
    }
    let selectedDate = this.selectedDate.nativeElement.value;

    sessionStorage.setItem("selectedDate", moment(selectedDate, 'MM/DD/YYYY').format('DD-MM-YYYY'));
    this.customDate.setSelectedDate(moment(selectedDate, 'MM/DD/YYYY').format('DD-MM-YYYY'));
  }

  convertDate(date) {
    if(this.openedFrom != 'start' && sessionStorage.getItem('min_Date')) {
      date = sessionStorage.getItem('min_Date')
    }
    
    let dateObject: any
    let dateParts = date.split("-");
    dateObject = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
    if (date != '' && this.datepipe.transform(this.Date, 'dd-MM-yyyy') != this.datepipe.transform(dateObject, 'dd-MM-yyyy')) {
      const diffTime = Math.abs(dateObject - this.Date);
      const diffDays = Math.ceil(diffTime / (1000 * 3600 * 24));
      return moment(date, 'DD-MM-YYYY').isSameOrBefore(moment(this.Date)) ? -(diffDays - 1) : diffDays;
    } else {
      const diffDays = 0;
      return diffDays;
    }
  }
}