import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthService} from './services/auth.service';
import {AuthGuardService as AuthGuard} from './services/auth-guard.service';
import {LoginComponent} from './components/login/login.component';
import {ForgotPasswordComponent} from './components/forgot-password/forgot-password.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {ResetPasswordComponent} from './components/reset-password/reset-password.component';
import {CameraComponent} from './components/camera/camera.component';
import {AdvanceComponent} from './components/advance/advance.component';
import {ApprovalsComponent} from './components/approvals/approvals.component';
import {ExpensesComponent} from './components/expenses/expenses.component';
import {NewreportComponent} from './components/newreport/newreport.component';
import {BaseComponent} from './components/base/base.component';
import {OrganizationStructureComponent} from './components/settings/organization-structure/organization-structure.component';
import {NewAdvanceComponent} from './components/advance/new-advance/new-advance.component';
import {AnalyticsComponent} from './components/analytics/analytics.component';
import {ExpenseAndMileageComponent} from './components/dashboard/expenseandmileage/expenseandmileage.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { TripDetailsComponent } from './components/travel/trip-details/trip-details.component';
import { GpsTrackingComponent } from './components/gps-tracking/gps-tracking.component';
import { MileageDefaultsComponent } from './components/mileage-defaults/mileage-defaults.component';
import { DashboardFc365Component } from './components/dashboard-fc365/dashboard-fc365.component';
import { MoreOption365Component } from './components/more-option365/more-option365.component';
import { TravelEvaComponent } from './components/travel-eva/travel-eva.component';
import { DeeplinkComponent } from './components/deeplink/deeplink.component';
import { SettlementOtpVerificationComponent } from './components/settlement-otp-verification/settlement-otp-verification.component';
import { MytripsListComponent } from './components/expenses/mytrips-list/mytrips-list.component';
import { MycardsListComponent } from './components/expenses/mycards-list/mycards-list.component';

const routes: Routes = [
    {path: '', redirectTo: 'login', pathMatch: 'full'},
    {path: 'login', component: LoginComponent},
    {path: 'mobile-sso/:app/:token', component: DeeplinkComponent},
    {path: 'ssoLogin/:apiKey', component: LoginComponent},
    {path: 'forgotpassword', component: ForgotPasswordComponent},
    {path: 'changepassword', component: ChangePasswordComponent},
    {path: 'resetpassword/:forgotPasswordId/:guid', component: ResetPasswordComponent},
    {path: 'emailApproval/:claimId/:claimGUID/:userId/:userGuid/:roleId/:ssoToken', component:ApprovalsComponent },
    {path: 'nessoLogin/:ssoToken', component: LoginComponent},
    {path: 'settlement-otp-validation/:id/:advanceID/:amount', component: SettlementOtpVerificationComponent},

    {
        path: '', component: BaseComponent, canActivate: [AuthGuard],

        children: [
            {
                path: 'base',
                component: BaseComponent
            },
            {path: 'dashboard', component: DashboardComponent},
            {path: 'gpstracking', component: GpsTrackingComponent},
            {path: 'mileagedefaults', component: MileageDefaultsComponent},
            {path: 'camera', component: CameraComponent},
            {path: 'expenses', component: ExpensesComponent},
            {path: 'mytrips', component: MytripsListComponent},
            {path: 'mycards', component: MycardsListComponent},
            {path: 'reports', redirectTo: 'expenses', pathMatch: 'full'},
            {path: 'newreport', component: NewreportComponent},
            {path: 'approvals', component: ApprovalsComponent},
            {path: 'advance', component: AdvanceComponent},
            {path: 'analytics', component: AnalyticsComponent},
            {path: 'newAdvance', component: NewAdvanceComponent},
            {path: 'organization', component: OrganizationStructureComponent},
            {
                path: 'settings', loadChildren: './components/settings/setting.module#SettingModule',
                canActivate: [AuthGuard]
            },
            {path: 'newExpenseMileageBulk', component: ExpenseAndMileageComponent},
            {path: 'tripDetails', component: TripDetailsComponent},
            {path: 'dashboardfc365', component: DashboardFc365Component},
            {path: 'moreoptions', component: MoreOption365Component},
            {path: 'traveleva', component: TravelEvaComponent}
        ]
    },
];
@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    providers: [
        AuthGuard,
        AuthService
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
