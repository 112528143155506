import { Component, Input, OnInit } from '@angular/core';
import { GetExpenseModels } from 'src/app/models/Expenses/expenses.model';

@Component({
  selector: 'app-expense-detailview',
  templateUrl: './expense-detailview.component.html',
  styleUrls: ['./expense-detailview.component.scss'],
})
export class ExpenseDetailviewComponent implements OnInit {
  isFirstOpen:any;
  @Input() expenseId;
  @Input() expenseGUID;
  getExpenseModels = new GetExpenseModels();
  constructor() { }

  ngOnInit() {
  }


}
