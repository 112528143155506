import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { IonInput, IonSlides, ModalController, NavController } from "@ionic/angular";
import { PlatformService } from "../../../services/platformbase.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { SharedService } from "../../../services/common-services/shared.service";
import { CommonService } from "../../../services/common-services/common.service";
import { Camera, CameraOptions } from "@ionic-native/camera/ngx";
import { DatechangeService } from "../../../services/common-services/datechange.service";
import { ExpensesService } from "../../../services/expenses.service";
import { CustomDatePickerComponent } from "../../custom-date-picker/custom-date-picker.component";
import { CategoryAndMerchantModel, CurrencyList } from "../../../models/Common/common.model";
import { ImageViewComponent } from "../../dashboard/image-view/image-view.component";
import * as moment from "moment";
import * as _ from "lodash";
import { CapturedImageModal, ExpenseAttachmentsModel, ExpenseListModel, GetExpenseModels } from "../../../models/Expenses/expenses.model";
import { CustomSelectDropdownComponent } from "../../custom-select-dropdown/custom-select-dropdown.component";
import { Constants } from 'src/app/Constants';
import { CreatePettyCash } from "../../../models/PettyCash/petty-cash.model";

@Component({
    selector: 'app-add-petty-cash',
    templateUrl: './add-petty-cash.component.html',
    styleUrls: ['./add-petty-cash.component.scss'],
})
export class AddPettyCashComponent implements OnInit {
    platformType: any;
    modalRef: any;
    addPettyCash: FormGroup;
    // requestArray = [{name: 'Petty Cash', id: 4}];
    requestArray1 = [{ categoryName: 'Petty Cash', categoryId: 4 }];
    userInfo: any;
    currencytempList: any;
    currencyList: any;
    selectedBaseCurr: any;
    exchangeAmt: number;
    expcurrencyIcon: any;
    showTripform = false;
    maxDate: Date;
    saveBtnDisable = false;
    submitBtnDisabled = false;
    submitted = false;
    @ViewChild('fileUpload', { static: false }) fileUploadInput: ElementRef;
    @ViewChild("currency", { static: false }) public currencyAutoCompleteReference: ElementRef;
    fileName: any;
    loaded: boolean;
    imgContent: any;
    imageLoaded: boolean;
    editPettyCash: any;
    expense: any;
    FromselectedDate: string;
    ToselectedDate: string;
    selectedDate: any;
    activeIndex: any = 0;
    expcurrencySymbol: string;
    @ViewChild(IonSlides) slides: IonSlides;

    capturedImage: any;
    showText = false;
    expConvRate: any;

    editlist: any;
    total: number = 0;
    policyList: any;

    receiptWarning = false;
    receiptCatWarning = false;
    categDesc = false;
    expenseWarning = false;
    expenseCatWarning = false;
    showCurrencyList: boolean = false;
    baseCurrencyList: any;
    private scrollChangeCallback: () => void;


    slideOpts = {
        zoom: false,
        slidesPerView: 1,
        centeredSlides: true,
        spaceBetween: 10,
        initialSlide: 0,
    };
    sliderOpts = {
        zoom: false,
        slidesPerView: 1.2,
        centeredSlides: false,
        spaceBetween: 10,
        initialSlide: 0,
    };

    listLoaderDic = { noApi: true, loader: false };
    showLoading = false;

    constructor(private platformService: PlatformService, private modalService: BsModalService,
        private toastr: ToastrService,
        private router: NavController, private translate: TranslateService, public sharedService: SharedService,
        private commonService: CommonService,
        private camera: Camera,
        public modalController: ModalController,
        private formBuilder: FormBuilder, private datechangeService: DatechangeService, private expensesService: ExpensesService) {
    }

    ngOnInit() {
        this.platformType = this.platformService.getPlatformType();
        this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        this.expcurrencySymbol = this.userInfo.baseCurrency.currencyCode;
        this.getCurrencylist();
        this.form();
        const date = new Date();
        this.maxDate = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate()
        );

        this.getEditAdvData();
    }

    ngOnDestroy() {
        this.resetSubscrptns();
    }

    ionViewWillLeave() {
        this.resetSubscrptns();
    }

    ngAfterViewInit() {
        this.scrollChangeCallback = () => this.onContentScrolled(event);
        window.addEventListener('scroll', this.scrollChangeCallback, true);
    }

    resetSubscrptns() {
        if (this.editPettyCash) {
            sessionStorage.setItem('toPathInfo', null);
        }
    }

    getEditAdvData() {
        let val = JSON.parse(sessionStorage.getItem("toPathInfo"));
        if (val) {
            if (val["url"]) {
                if ((val["url"] == 'detailexpense') || (val["url"] == 'petty-cash')) {
                    if (val["advance"]) {
                        this.editPettyCash = val["advance"];
                        this.getExpenseSumary(this.editPettyCash);
                    }
                }
            }
        }
    }

    form() {
        this.addPettyCash = this.formBuilder.group({
            requestType: ["", Validators.required],
            originalCurrencyCode: [""],
            originalCurrencyId: [""],
            originalAmount: ["", [Validators.required, Validators.maxLength(8), Validators.min(1)]],
            reason: ["", Validators.required]
        });
    }

    get f() {
        return this.addPettyCash.controls;
    }

    ToReportModal(template: TemplateRef<any>) {

        const config = {
            ignoreBackdropClick: true,
            class: "modal-md modal-dialog-centered",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(template, config);
    }

    backToReport() {
        this.router.navigateRoot(["/petty-cash"]);
    }

    dismiss() {
        this.sharedService.setPettyCashData(null);
        this.router.navigateRoot(["petty-cash"]);
    }

    /*async openDateSheet(string: any) {
        const modal = await this.modalController.create({
            component: CustomDatePickerComponent,
            componentProps: {
                selectedDate: string == '1' ? (this.FromselectedDate ? this.FromselectedDate : new Date()) : (this.ToselectedDate ? this.ToselectedDate : new Date()),
                fromDate: new Date('1981-01-01'),
                toDate: new Date()
            },
            cssClass: 'custom-bottom-sheet'
        });
        modal.onDidDismiss().then(result => {
            if (result.role === 'changed' && result.data) {

                if (string == "1") {
                    this.FromselectedDate = result.data.displayDate;
                    this.createPettyCashModel.tripFromDate = result.data.formattedDate;
                } else {
                    this.ToselectedDate = result.data.displayDate;
                    this.createPettyCashModel.tripToDate = result.data.formattedDate;
                }

                //this.createPettyCashModel.tripToDate = result.data.formattedDate;
            }
        });
        return await modal.present();
    }*/

    selectrequestOption(e) {
        if (this.platformType == 1) {
            this.addPettyCash.controls.requestType.patchValue(e.categoryName);
            this.createPettyCashModel.expenseType = e.categoryId;
        }
    }

    openCurrencyDropDown(e) {
        e.preventDefault();
        e.stopPropagation();
        this.showCurrencyList = true;
        setTimeout(() => {
            this.currencyAutoCompleteReference.nativeElement.focus();
        }, 0);
    }

    onCurrencyEventFocus() {
        setTimeout(() => {
            this.currencyList = this.currencytempList;
        }, 0);
    }

    onCurrencySearch(val) {
        if (this.listLoaderDic?.noApi) {
            this.listLoaderDic.loader = true;
        }
        let searchText = val.currentTarget.value.trim();
        if (this.currencytempList != null) {
            if (searchText != "") {
                let filterdata = this.currencytempList.filter((data) => {
                    if (
                        data.currencyCode.toLowerCase().indexOf(searchText.toLowerCase()) >=
                        0
                    ) {
                        return data;
                    }
                });
                this.currencyList = filterdata;
            } else {
                this.currencyList = this.currencytempList;
            }
        }
    }

    categoryAndMerchantModel = new CategoryAndMerchantModel();

    getCurrencylist() {
        this.categoryAndMerchantModel.legalEntityId = this.userInfo.legalEntityId;
        this.categoryAndMerchantModel.legalEntityGUID = this.userInfo.legalEntityGuId;
        this.commonService.getCurrencylist(this.categoryAndMerchantModel).subscribe((res) => {
            if (this.listLoaderDic?.noApi) {
                this.listLoaderDic.noApi = false;
            }
            if (!res.isError && res.errorCode == 0) {
                let listarray = res.payload;
                this.currencytempList = listarray.map((list) => new CurrencyList(list));
                this.currencyList = listarray.map((list) => new CurrencyList(list));
                this.currencyList = _.uniqBy(this.currencyList, 'currencyId');
                this.currencyList.forEach((item, i) => {
                    if (item.currencyId === 64) {
                        this.currencyList.splice(i, 1);
                        this.currencyList.unshift(item);
                        if (!this.editPettyCash) {
                            this.addPettyCash.controls.originalCurrencyCode.patchValue(item.currencyCode);
                            this.expcurrencyIcon = item.currencyIcon;
                            this.createPettyCashModel.originalCurrencyId = item.currencyId;

                        }
                        //   this.createPettyCashModel.originalCurrencyId = item.currencyCode;


                    }
                });
            } else if (!res.isError && res.errorCode == 2) {
                this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
            }
        });
    }

    get expenseItemsArray() {
        return this.addPettyCash.get("expenseItems") as FormArray;
    }

    removeExpenseItems(index) {
        this.expenseItemsArray.removeAt(index);
        this.convertAmt();
    }

    convertAmt() {
        let userBaseCurr = this.userInfo.baseCurrency.currencyId;
        //edit
        if (this.editlist) {
            if (this.editlist.exchangeRate) {
                this.exchangeAmt = this.total * this.editlist.exchangeRate;
            }
            if (userBaseCurr && !this.editlist.exchangeRate) {
                this.exchangeAmt = this.total;
            }

        }
        // If we don't select and enter money direct
        if (userBaseCurr && !this.editlist) {
            this.exchangeAmt = this.total;

        }
        // if we select same currenct and enter
        if (this.selectedBaseCurr !== undefined) {
            if (userBaseCurr == this.selectedBaseCurr.currencyId) {
                this.exchangeAmt = this.total;
            }
            // if we select diffrent currency.
            if (this.selectedBaseCurr) {
                if (userBaseCurr !== this.selectedBaseCurr.currencyId) {
                    this.exchangeAmt = this.total * this.selectedBaseCurr.conversionRate;
                }
            }
            // for null conversion rate -- for temporary
            if (this.selectedBaseCurr.conversionRate == null) {
                this.exchangeAmt = this.total;
            }
        }
        if (this.exchangeAmt > this.policyList.receiptRequiredLimit) {
            this.receiptWarning = true;
        } else {
            this.receiptWarning = false;
        }
        if (this.exchangeAmt > this.policyList.expenseAmountLimit) {
            this.expenseWarning = true;
        } else {
            this.expenseWarning = false;
        }
    }

    selectCurrencyOption(e) {
        this.selectedBaseCurr = e;
        this.exchangeAmt = 0;
        if (this.platformType == 1) {
            this.addPettyCash.controls.originalCurrencyCode.patchValue(e.currencyCode);
        }
        if (e != null) {
            this.createPettyCashModel.originalCurrencyId = e.currencyId;
            //this.expCurrencyCode = e.currencyCode;
            this.expcurrencyIcon = e.currencyIcon;
            this.expcurrencySymbol = e.currencyCode;
            this.expConvRate = e.conversionRate ? e.conversionRate :0;
        }
        this.showCurrencyList = false;

    }

    attachProof() {

    }

    getIndex() {
        this.slides.getActiveIndex().then((index) => {
            this.activeIndex = index;
        });
    }

    openPreview() {
        this.modalController
            .create({
                component: ImageViewComponent,
                componentProps: { photo: this.caputredImageModal.capturedImages[this.activeIndex] },
            })
            .then((modal) => modal.present());
    }

    deletePhoto() {
        this.caputredImageModal.capturedImages.splice(this.activeIndex, 1);
    }

    resetFormControlAndBlur(form: FormGroup, formControlName: string, eleInput: IonInput) {
        form.controls[formControlName].reset();
        eleInput.getInputElement().then((ele) => ele.blur());
        if (formControlName === 'categoryName' && form.contains(formControlName)) {
            this.addPettyCash.controls.merchantName.patchValue(null);
        }
    }

    createPettyCashModel = new CreatePettyCash();
    caputredImageModal = new CapturedImageModal();

    takePhoto() {
        const options: CameraOptions = {
            quality: 50,
            targetWidth: 400,
            targetHeight: 500,
            destinationType: this.camera.DestinationType.DATA_URL,
            encodingType: this.camera.EncodingType.PNG,
            mediaType: this.camera.MediaType.PICTURE,
            sourceType: this.camera.PictureSourceType.CAMERA,
            correctOrientation: true,
        };
        this.camera.getPicture(options).then(
            (imageData) => {
                // this.capturedImage = (<any>window).Ionic.WebView.convertFileSrc(imgFileUri);
                let fileName = Math.random().toString(36).substr(2, 15) + ".png";
                this.capturedImage = "data:image/png;base64," + imageData;
                this.caputredImageModal.capturedImages.push(this.capturedImage);
                this.caputredImageModal.capturedImages.reverse();
                this.uploadImage(this.capturedImage, fileName);
            },
            (err) => {
                // Handle error
            }
        );
    }

    async openModal(value: string, type: string, isExpenseForm?: boolean) {
        const componentProps = this.getComponentProps(type);
        if (value && value !== '') {
            componentProps.value = value;
        }
        const searchItemModal = await this.modalController.create({
            component: CustomSelectDropdownComponent,
            componentProps,
            keyboardClose: false,
        });
        searchItemModal.onDidDismiss()
            .then((callback) => {
                if (callback && callback.data) {
                    if (typeof callback.data !== 'string') {
                        this[componentProps.listName] = callback.data;
                        callback.data.forEach((obj: any) => {
                            if (obj.isSelected) {
                                this.addPettyCash.controls[type].setValue(obj[componentProps.itemTextField]);
                                if (type === 'requestType') {
                                    this.createPettyCashModel.expenseType = obj.categoryId;
                                } else if (type === 'originalCurrencyCode') {
                                    this.createPettyCashModel[componentProps.itemTextField] = obj[componentProps.itemTextField];
                                    this.createPettyCashModel[componentProps.itemValueField] = obj[componentProps.itemValueField];
                                    this.selectCurrencyOption(obj);
                                }
                            }
                        });
                    } else {
                        this.addPettyCash.controls[type].setValue(callback.data);
                        this.createPettyCashModel[componentProps.itemTextField] = callback.data;
                    }
                }
            });
        return await searchItemModal.present();
    }

    getComponentProps(type: string) {
        //TODO check translations
        switch (type) {
            case 'requestType':
                return {
                    title: this.translate.instant('pettycash.requesttype'),
                    value: null,
                    mainList: this.requestArray1,
                    isMultiSelect: false,
                    itemValueField: 'categoryId',
                    itemTextField: 'categoryName',
                    listName: `requestArray1`,
                };
            case 'originalCurrencyCode':
                return {
                    title: this.translate.instant('common.currency'),
                    value: null,
                    mainList: this.currencyList,
                    isMultiSelect: false,
                    itemValueField: 'currencyId',
                    itemTextField: 'currencyCode',
                    listName: `currencyList`
                };
        }
    }

    createPettyCash(action) {
        this.submitted = true;
        if (!this.addPettyCash.valid) {
            return;
        }
        this.createPettyCashModel.userId = this.userInfo.userId;
        this.createPettyCashModel.userGUID = this.userInfo.userGuId;
        this.createPettyCashModel.legalEntityId = this.userInfo.legalEntityId;
        this.createPettyCashModel.legalEntityGUID = this.userInfo.legalEntityGuId;
        this.createPettyCashModel.originalAmount = this.addPettyCash.value.originalAmount;
        this.createPettyCashModel.reason = this.addPettyCash.value.reason;
        this.createPettyCashModel.recordType = 5;
        this.createPettyCashModel.action = action;
        this.createPettyCashModel.categoryType = null; // TODO check sridhar asked to pass 1

        if (action == 1) {//--save
            this.saveBtnDisable = true;
        } else {//--submit
            this.submitBtnDisabled = true;
        }
        this.expensesService.createExpenseandMileage(this.createPettyCashModel).subscribe(res => {

            if (!res.isError && res.errorCode == 0) {
                console.log('came to pcr----');

                var alert = this.translate.instant('alerts.createPCRequest');
                console.log(alert, action);
                if (action == 2) {
                    alert = alert + this.translate.instant('alerts.submitSuccess');
                } else {
                    if (this.editPettyCash) {
                        alert = alert + this.translate.instant('alerts.updateSuccess');
                    } else {
                        alert = alert + this.translate.instant('alerts.createsuccess');
                    }
                }
                this.toastr.success(alert, this.translate.instant('alerts.succes'));
                this.saveBtnDisable = false;
                this.submitBtnDisabled = false;
                this.router.navigateRoot(["petty-cash"]);
            } else if (res.isError && res.errorCode == 2) {
                this.toastr.error(res.errorMessage);
                this.saveBtnDisable = false;
                this.submitBtnDisabled = false;
            }
        });

    }

    resetInputFile() {
        this.fileUploadInput.nativeElement.value = "";
    }

    handleInputChange(event) {
        if (!event.target) {
            return;
        }
        if (!event.target.files) {
            return;
        }
        // if (event.target.files.length !== 1) {
        //     return;
        // }
        var file = event.target.files;
        this.createPettyCashModel.expenseAttachments = [];
        let filesCount = event.target.files.length;
        for (let i = 0; i < filesCount; i++) {
            var reader = new FileReader();
            var imgfile = event.dataTransfer ? event.dataTransfer.files[i] : file[i];
            var pattern = /image-*/;
            this.fileName = imgfile.name;

            if (!imgfile.type.match(pattern)) {
                alert("invalid format");
                return;
            }
            this.loaded = false;
            reader.onload = this._handleReaderLoaded.bind(this);
            reader.readAsDataURL(imgfile);

        }
    }

    _handleReaderLoaded(e) {
        var reader = e.target;
        this.imgContent = reader.result;
        this.loaded = true;
        this.uploadImage(this.imgContent, this.fileName);
    }


    uploadImage(img, name) {
        var expenseAttachmentsModel = new ExpenseAttachmentsModel();
        expenseAttachmentsModel.content = img;
        expenseAttachmentsModel.fileName = name;
        this.createPettyCashModel.expenseAttachments.push(expenseAttachmentsModel);
    }

    handleImageLoad() {
        this.imageLoaded = true;
    }

    getExpenseModels = new GetExpenseModels();

    getExpenseSumary(list) {
        this.showLoading = true;
        this.getExpenseModels.expenseId = list.expenseId;
        this.getExpenseModels.expenseGUID = list.expenseGUID;
        this.getExpenseModels.userId = this.userInfo.userId;
        this.getExpenseModels.userGUID = this.userInfo.userGuId;
        this.getExpenseModels.legalEntityId = this.userInfo.legalEntityId;
        this.getExpenseModels.legalEntityGUID = this.userInfo.legalEntityGuId;
        this.expensesService
            .expenseSummary(this.getExpenseModels)
            .subscribe((res) => {
                this.showLoading = false;
                if (!res.isError && res.errorCode === 0) {
                    const listarray = res.payload;
                    this.expense = new ExpenseListModel(listarray);
                    console.log("return data is :", this.expense);
                    this.createPettyCashModel = this.expense;
                    this.createPettyCashModel.originalAmount = this.expense?.unformattedOriginalAmount;
                    this.createPettyCashModel.baseAmount = this.expense?.unformattedBaseAmount;
                    this.expcurrencyIcon = this.createPettyCashModel.originalCurrencyIcon;

                    let originalAmount = this.expense?.originalCurrencyId;
                    let currencyDetails = _.find(this.currencyList, function (object) { return object.currencyId == originalAmount; });
                    this.expConvRate = currencyDetails.conversionRate;

                    this.addPettyCash.controls.requestType.patchValue(
                        this.createPettyCashModel.expenseTypeName
                    );
                    this.addPettyCash.controls.originalCurrencyCode.patchValue(
                        this.createPettyCashModel.originalCurrencyCode
                    );

                    this.createPettyCashModel.expenseAttachments.length ? this.imgContent = this.createPettyCashModel.expenseAttachments[0].url : undefined
                } else if (res.isError && res.errorCode === 2) {
                }
            });
    }

    convertDateToUtc(date) {
        this.selectedDate = moment(moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD')).utc(true).toISOString(false);
        return this.selectedDate;
    }

    numberValidation(event: any, isTime?) {
        const pattern = /[0-9.,]/;
        let inputChar = String.fromCharCode(event.charCode);

        if (!pattern.test(inputChar)) {
            // invalid character, prevent input
            event.preventDefault();
        }
    }

    onContentScrolled(e) {
        // this.showCurrencyList = false;
    }

    mainDivClick() {
        this.showCurrencyList = false;
    }

}
