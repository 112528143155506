export class SelectLegalEntity {
    companyGuId: any;
    companyId: any;
    legalEntityGuId: any;
    legalEntityId: any;
    legalEntityName: any;
    legalEntityCode: any;
    legalEntityLogo: any;
}

export class changePasswordModel {
    user_Id: any;
    guid: any;
    password: any;
    newPassword: any;
    accessToken: any;
    confirmPassword: any;
}