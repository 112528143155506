import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import * as moment from 'moment';

@Component({
  selector: 'app-custom-date-filter',
  templateUrl: './custom-date-filter.component.html',
  styleUrls: ['./custom-date-filter.component.scss'],
})
export class CustomDateFilterComponent implements OnInit {
  Durations = [
    { value: 'Today', viewValue: 'Today', isSelected: false },
    { value: 'Yesterday', viewValue: 'Yesterday', isSelected: false },
    { value: 'Week', viewValue: 'Week', isSelected: false },
    { value: 'This Month', viewValue: 'This Month', isSelected: false },
    { value: 'Last Month', viewValue: 'Last Month', isSelected: false },

  ];
  fromDate: any;
  toDate: any;

  maxLastDate: any;
  minToDate: any;
  maxToDate: any;

  selectedFromDate: any;
  selectedToDate: any;
  @Input() previousDates :any;
  @Output() public filterDates = new EventEmitter<object>();
  @Output() public returnDates = new EventEmitter<object>();
  @ViewChild('calendar', { static: false }) calendar: MatCalendar<Date>;
  @ViewChild('calendar1', { static: false }) calendar1: MatCalendar<Date>;
  smallCalendarStartAt: Date;
  selectedtype:any;

  constructor() { }

  ngOnInit() {
    this.maxLastDate = moment(new Date()).format(); //it should not change based on any clicks

    this.fromDate = moment(new Date()).subtract(1, 'months').format();
    this.selectedFromDate = moment(new Date()).subtract(1, 'months').format();
    this.toDate = moment(new Date()).format();
    this.selectedToDate = moment(new Date()).format();

    if(this.previousDates){
      const dateV = this.previousDates;
      var index = this.Durations.findIndex(function(person) {
        return person.value == dateV.value;
      });
      this.Durations[index].isSelected = true;
      this.setInitialDates(dateV.value);
    }
  }

  ionViewWillEnter() {
    this.selectDate(this.previousDates);
  }

  selectDate(event) {
    this.Durations.forEach(elm => {
      if (elm.value == event.value) {
        elm.isSelected = true;
      } else {
        elm.isSelected = false;
      }
    });

    switch (event.value) {

      case 'Today':
        this.fromDate = moment().format();
        this.toDate = moment().format();
        this.selectedFromDate = new Date(this.fromDate);
        this.selectedToDate = new Date(this.toDate);
        this.calendar._goToDateInView(this.selectedFromDate, 'month');
        this.calendar1._goToDateInView(this.selectedToDate, 'month');
        this.selectedtype = 'Today';
        break;
      case 'Yesterday':
        this.fromDate = moment().subtract(1, 'days').format();
        this.toDate = moment().subtract(1, 'days').format();
        this.selectedFromDate = new Date(this.fromDate);
        this.selectedToDate = new Date(this.toDate);
        this.calendar._goToDateInView(this.selectedFromDate, 'month');
        this.calendar1._goToDateInView(this.selectedToDate, 'month');
        this.selectedtype = 'Yesterday';
        break;
      case 'Week':
        this.fromDate = moment().subtract(7, 'd').format();
        this.toDate = moment().format();
        this.selectedFromDate = new Date(this.fromDate);
        this.selectedToDate = new Date(this.toDate);
        this.calendar._goToDateInView(this.selectedFromDate, 'month');
        this.calendar1._goToDateInView(this.selectedToDate, 'month');
        this.selectedtype = 'Week';
        break;
      case 'This Month':
        this.fromDate = moment().startOf('month').format();
        this.toDate = moment().format();
        this.selectedFromDate = new Date(this.fromDate);
        this.selectedToDate = new Date(this.toDate);
        this.calendar._goToDateInView(this.selectedFromDate, 'month');
        this.calendar1._goToDateInView(this.selectedToDate, 'month');
        this.selectedtype = 'This Month';
        break;
      case 'Last Month':
        this.fromDate = moment().subtract(1, 'months').startOf('month').format();
        this.toDate = moment().subtract(1, 'months').endOf('month').format();
        this.selectedFromDate = new Date(this.fromDate);
        this.selectedToDate = new Date(this.toDate);
        this.calendar._goToDateInView(this.selectedFromDate, 'month');
        this.calendar1._goToDateInView(this.selectedToDate, 'month');
        this.selectedtype = 'Last Month';
        break;
      default:
        this.fromDate = moment().startOf('month').format();
        this.toDate = moment().format();
        this.selectedFromDate = new Date(this.fromDate);
        this.selectedToDate = new Date(this.toDate);
        this.calendar._goToDateInView(this.selectedFromDate, 'month');
        this.calendar1._goToDateInView(this.selectedToDate, 'month');
        break;
    }

  } 

  fromInput(ev) {
    this.fromDate = moment(new Date(ev)).format();
    this.selectedFromDate = moment(new Date(ev)).format();
    this.minToDate = this.fromDate;
  }

  toInput(ev) {
    this.toDate = moment(new Date(ev)).format();
    this.selectedToDate = moment(new Date(ev)).format();
  }

  applyFilter() {
    let filterDatesobj = { to: '', from: '' };
    filterDatesobj.from = moment(new Date(this.fromDate)).format();
    filterDatesobj.to = moment(new Date(this.toDate)).format();
    let returnData = {value:this.selectedtype,selectedFromDate:moment(new Date(this.selectedFromDate)).format('DD-MM-yyyy'),
    selectedToDate:moment(new Date(this.selectedToDate)).format('DD-MM-yyyy')}
    this.filterDates.emit(filterDatesobj);
    this.returnDates.emit(returnData);
  }

  cancelFilter() {
    this.filterDates.emit(null);
  }

  fromDateValue(ev) {
    this.fromDate = ev;
    this.selectedFromDate = moment(new Date(ev)).format();
    this.minToDate = this.fromDate;
    this.checkSpecificSelectn(this.selectedtype);
  }

  toDateValue(ev) {
    this.toDate = ev;
    this.selectedToDate = moment(new Date(ev)).format();
    this.checkSpecificSelectn(this.selectedtype);
  }

  checkSpecificSelectn(type) {
    switch (type) {

      case 'Today':
        if((this.selectedFromDate != moment().format()) ||  (this.selectedToDate != moment().format())) {
          //console.log('clearing---', this.selectedtype)
          this.unselectAllDuration();
        } 
        break;
      case 'Yesterday':
        if((this.selectedFromDate != moment().subtract(1, 'days').format()) ||  (this.selectedToDate != moment().subtract(1, 'days').format())) {
          this.unselectAllDuration();
        } 
        break;
      case 'Week':
        if((this.selectedFromDate != moment().subtract(7, 'd').format()) ||  (this.selectedToDate != moment().format())) {
          this.unselectAllDuration();
        } 
        break;
      case 'This Month':
        if((this.selectedFromDate != moment().startOf('month').format()) ||  (this.selectedToDate != moment().format())) {
          this.unselectAllDuration();
        } 
        break;
      case 'Last Month':
        if((this.selectedFromDate != moment().subtract(1, 'months').startOf('month').format()) ||  (this.selectedToDate != moment().subtract(1, 'months').endOf('month').format())) {
          this.unselectAllDuration();
        } 
        break;
    }
  }

  unselectAllDuration() {
    this.selectedtype = '';
    this.Durations = [
      { value: 'Today', viewValue: 'Today', isSelected: false },
      { value: 'Yesterday', viewValue: 'Yesterday', isSelected: false },
      { value: 'Week', viewValue: 'Week', isSelected: false },
      { value: 'This Month', viewValue: 'This Month', isSelected: false },
      { value: 'Last Month', viewValue: 'Last Month', isSelected: false },
  
    ];
  }

  setInitialDates(type) {
    switch (type) {
      case 'Today':
        this.fromDate = moment().format();
        this.toDate = moment().format();
        this.selectedFromDate = new Date(this.fromDate);
        this.selectedToDate = new Date(this.toDate);
        this.selectedtype = 'Today';
        break;
      case 'Yesterday':
        this.fromDate = moment().subtract(1, 'days').format();
        this.toDate = moment().subtract(1, 'days').format();
        this.selectedFromDate = new Date(this.fromDate);
        this.selectedToDate = new Date(this.toDate);
        this.selectedtype = 'Yesterday';
        break;
      case 'Week':
        this.fromDate = moment().subtract(7, 'd').format();
        this.toDate = moment().format();
        this.selectedFromDate = new Date(this.fromDate);
        this.selectedToDate = new Date(this.toDate);
        this.selectedtype = 'Week';
        break;
      case 'This Month':
        this.fromDate = moment().startOf('month').format();
        this.toDate = moment().format();
        this.selectedFromDate = new Date(this.fromDate);
        this.selectedToDate = new Date(this.toDate);
        this.selectedtype = 'This Month';
        break;
      case 'Last Month':
        this.fromDate = moment().subtract(1, 'months').startOf('month').format();
        this.toDate = moment().subtract(1, 'months').endOf('month').format();
        this.selectedFromDate = new Date(this.fromDate);
        this.selectedToDate = new Date(this.toDate);
        this.selectedtype = 'Last Month';
        break;
      default:
        this.fromDate = moment().startOf('month').format();
        this.toDate = moment().format();
        this.selectedFromDate = new Date(this.fromDate);
        this.selectedToDate = new Date(this.toDate);
        break;
    }
  }
}
