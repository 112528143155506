export class LcMainModel {
    legalEntityId: number;
    legalEntityGUID: string;
    userId: number;
    userGUID: string;
    status: number;
    statusDescription:string;
    action: number;
    expenseId:number;
    //expenseGUID:string;
    claimId:number;
    //claimGUID:String;
    localConveyanceName:string;
    localConveyanceList=[];
    csvFileName:string;
  }
  
  export class LcItemModel { 
    localConveyanceId:number;
    localConveyanceGUID:string;
    categoryName:string;
    categoryId: number;
    categoryType:number;
    expensedDate: string;
    fromLocation: string;
    fromCity: string;
    fromCountry: string;
    fromLongitude:any;
    fromLatitude:any;
    toLocation: string;
    toCity:string;
    toCountry:string;
    toLongitude:any;
    toLatitude:any;
    fromMap: string;
    attachmentURL: string; 
    reason:string;
    originalCurrencyId: number;
    originalAmount: any;
    baseCurrencyId: number;
    baseAmount:number;
    exchangeRate:number;
    distance:number;
    mileageUnit:string;
    mileageRate:number;
    inTime:number;
    outTime:number;
    unformattedOriginalAmount:number;
  }

  export class LcItemBindModel {
    localConveyanceId:number;
    localConveyanceGUID:string;
    categoryName:string;
    categoryId: number;
    categoryType:number;
    expensedDate: string;
    fromLocation: string;
    fromCity: string;
    fromCountry: string;
    fromLongitude:any;
    fromLatitude:any;
    toLocation: string;
    toCity:string;
    toCountry:string;
    toLongitude:any;
    toLatitude:any;
    fromMap: string;
    attachmentURL: string;
    reason:string;
    originalCurrencyId: number;
    originalAmount: number;
    baseCurrencyId: number;
    baseAmount:number;
    exchangeRate:number;
    distance:number;
    mileageUnit:string;
    mileageRate:number;
    isSelected:false;
    inTime:number;
    outTime:number;
    unformattedOriginalAmount:number;
    constructor(list: any) {
      this.localConveyanceId = list.localConveyanceId;
      this.localConveyanceGUID = list.localConveyanceGUID;
      this.categoryName = list.categoryName;
      this.categoryId = list.categoryId;
      this.categoryType = list.categoryType;
      this.expensedDate = list.expensedDate;
      this.fromLocation = list.fromLocation;
      this.fromCity = list.fromCity;
      this.fromCountry = list.fromCountry;
      this.fromLongitude = list.fromLongitude;
      this.fromLatitude = list.fromLatitude;
      this.toLocation = list.toLocation;
      this.toCity = list.toCity;
      this.toCountry = list.toCountry;
      this.toLongitude = list.toLongitude;
      this.toLatitude = list.toLatitude;
      this.fromMap = list.fromMap;
      this.attachmentURL = list.attachmentURL;
      this.reason = list.reason;
      this.originalCurrencyId = list.originalCurrencyId;
      this.originalAmount = list.originalAmount;
      this.baseCurrencyId = list.baseCurrencyId;
      this.baseAmount = list.baseAmount;
      this.exchangeRate = list.exchangeRate;
      this.distance = list.distance;
      this.mileageUnit = list.mileageUnit;
      this.mileageRate = list.mileageRate;
      this.inTime = list.inTime;
      this.outTime = list.outTime;
      this.unformattedOriginalAmount = list.unformattedOriginalAmount;
    }
}
