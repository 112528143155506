import { Component, OnInit, ViewChild } from '@angular/core';
import { PlatformService } from 'src/app/services/platformbase.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { Category, CategoryList } from 'src/app/models/Settings/category.model';
import { SettingsService } from '../../../services/settings-services'
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Title } from "@angular/platform-browser";
import { NavController } from '@ionic/angular';
import { SharedService } from 'src/app/services/common-services/shared.service';
import { polEnableEntityModels } from 'src/app/models/Settings/policy.model';
import { AccountConfig } from 'src/app/models/Settings/accountconfig.model';
import { AccountConfigService } from 'src/app/services/account-config.service';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-expense-category',
  templateUrl: './expense-category.component.html',
  styleUrls: ['./expense-category.component.scss'],
})
export class ExpenseCategoryComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('searchInput', { static: false }) searchInput;
  platformType: number;
  showLoader: boolean = true;
  showTable: boolean = false;
  dataSource: any[] = [];
  paginatedData: any[] = [];
  filteredData: any[] = [];
  totalItems = 0;
  pageSize = 10;
  currentPage = 0;
  totalPages = 0;
  displayedColumns: any[] = ['categoryName', 'categoryTypeName', 'categoryDescription', 'status', 'disablestatus'];
  categoryModel = new Category();
  categoryList: any = [];
  tableDataList: any;
  loadersList: any = [1, 2, 3];
  selectedIndex: any = -1;
  pageNumber: number = 1;
  categoryListPageLimit: number[] = [5, 10, 25, 100, 500];
  pageSizeOptions = [5, 10, 25, 50, 100];
  filterValue: any = "";
  categoryListTotalLength: any;
  categoryObj: any;
  statusFlag: any;
  statusMessage: string;
  isDisable: boolean = false;
  statusDisable: boolean = false;
  modalRef: BsModalRef;
  selectedLegalEntity: any;
  isApicallinProgress: boolean = false;
  buttonDisabled: Array<boolean> = [];
  ind: any;
  showSearch: boolean = true;
  sharedSubsc: any;
  categoryType = 1;
  ispcMainEnabled = false;
  userInfo: any;
  hasConfigData = false;
  paginatorStatus: boolean = false;
  child_cats = [];

  constructor(private platformService: PlatformService,
    private router: NavController, private title: Title,
    private settingService: SettingsService,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private sharedService: SharedService,
    private accountConfigService: AccountConfigService,
    private authenticationService: AuthenticationService) {
    this.sharedSubsc = this.sharedService.getLoadCatgryApi().subscribe(catType => {
      if (catType) {
        if (this.selectedLegalEntity?.legalEntityGuId && this.categoryModel.categoryType != catType) {
          this.dataSource = [];
          this.paginatedData = [];
          this.pageSize = 10;
          this.currentPage = 0;
          this.totalPages = 0;
          this.getCategoryList();
        }
      }
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.sharedService.unsubscribeServic(this.sharedSubsc);
  }

  ionViewWillLeave() {
    this.sharedService.unsubscribeServic(this.sharedSubsc);
  }

  ionViewWillEnter() {
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.ispcMainEnabled = this.userInfo?.isPettyCashEnabled;
    this.platformType = this.platformService.getPlatformType();
    this.selectedLegalEntity = JSON.parse(sessionStorage.getItem('selectedLegalEntity'));
    this.getCategoryList();
    this.getAccountConfiguration();
    let applicationTitle = this.userInfo.applicationTitle;
    if(applicationTitle){
        this.title.setTitle('Expense Categories | Master Data | Settings' + ' | ' + applicationTitle);
    }else{ 
        this.title.setTitle("Expense Categories | Master Data | Settings | NoExpenses");
    }
  }

  getAccountConfiguration() {
    var modl = new AccountConfig();
    this.hasConfigData = false;
    let selectedEntity = JSON.parse(sessionStorage.getItem('selectedLegalEntity'));
    modl.legalEntityId = selectedEntity.legalEntityId;
    modl.legalEntityGUID = selectedEntity.legalEntityGuId;
    this.accountConfigService.getAccountConfig(modl).subscribe(res => {
      this.hasConfigData = true;
      if (!res.isError && res.payload) {
        this.ispcMainEnabled = res.payload?.isPettyCashEnabled;
      }
    })
  }

  loadMore(event) {
    if (!this.isApicallinProgress) {
      if (this.categoryList.length < this.pageSize && event) {
        event.target.disabled = true;
      } else {
        this.pageNumber++;
        this.getCategoryList(event);
      }
    }
  }

  allCategories:any;
  getCategoryList(event?) {
    let navInfo = JSON.parse(sessionStorage.getItem("toPathInfo"));
    this.categoryModel.categoryType = 1;
    if (navInfo && navInfo.categoryType) {
      this.categoryModel.categoryType = navInfo.categoryType;
    }
    this.categoryModel.legalEntityGUID = this.selectedLegalEntity?.legalEntityGuId;
    this.categoryModel.legalEntityId = this.selectedLegalEntity?.legalEntityId;
    this.categoryModel.pageNumber = this.pageNumber;
    this.categoryModel.pageSize = 500;
    this.settingService.categoryList(this.categoryModel).subscribe(data => {
      if (!data.isError && data.payload.categoryList.length > 0) {
      

        this.tableDataList = data.payload.categoryList;
        this.showLoader = false;
        this.showTable = true;
        this.child_cats = this.settingService.getSubCategories(data.payload.categoryList);
        this.allCategories = [...this.child_cats];
        console.log(this.child_cats);
        
        this.dataSource =  this.child_cats; // Assuming response contains an array of data
        this.filteredData = this.dataSource;
        this.totalItems = this.child_cats.length; // Assuming response contains total number of items
        this.totalPages = Math.ceil(this.totalItems / this.pageSize);
        this.updatePaginatedData();

      
        this.isApicallinProgress = false;
      } else {
        this.showLoader = false;
        this.showTable = true;
        this.categoryList.length != 0 ? this.tableDataList = this.categoryList : this.tableDataList = null;
        if (this.categoryList.length != 0) {
          this.categoryListTotalLength = this.categoryList.length;
        }
        if (event) event.target.disabled = true;
      }
    })
  }

  handlePage(event) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
  }

  applyFilter(filterValue: string) {
    let categories = [...this.allCategories];
    this.child_cats = categories.filter(item => 
      item.categoryName.trim().toLowerCase().includes(filterValue.trim().toLowerCase()) ||
      item.categoryTypeName.trim().toLowerCase().includes(filterValue.trim().toLowerCase())
    );
    this.dataSource =  this.child_cats; // Assuming response contains an array of data
    this.filteredData = this.dataSource;
    this.totalItems = this.child_cats.length; // Assuming response contains total number of items
    this.totalPages = Math.ceil(this.totalItems / this.pageSize);
    this.updatePaginatedData();
  }

  searchbar() {
    this.showSearch = !this.showSearch
  }

  selectedCatId: any;
  toggleStatusDropdown(index, categoryId?) {
    if (this.selectedCatId != categoryId) this.selectedCatId = categoryId;
    else this.selectedCatId = null;

    if (this.selectedIndex != index) this.selectedIndex = index;
    else this.selectedIndex = -1;
  }

  toggleStatus(data, content, flag) {
    this.categoryObj = data;
    this.statusFlag = flag;
    this.modalRef = this.modalService.show(content, { class: 'modal-md modal-dialog-centered' });
    switch (this.statusFlag) {
      case 1: this.statusMessage = "Are you sure you want to enable expense category ?";
        break;
      case 2: this.statusMessage = "Are you sure you want to disable expense category ?";
        break;
    }
    this.hideDropdown();
  }

  statusAction(status?, ind?) {
    this.isDisable = true;
    this.statusDisable = true;
    if (status) {
      this.buttonDisabled[ind] = true;
      this.categoryObj = status;
      this.ind = ind;
    }
    this.categoryModel.categoryId = this.categoryObj.categoryId;
    this.categoryModel.categoryGUID = this.categoryObj.categoryGUID;
    if (this.categoryObj.status == 1) {
      this.settingService.disableCategory(JSON.stringify(this.categoryModel)).subscribe(data => {
        if (!data.isError) {
          this.toastr.success(data.payload, 'Success');
          this.statusResponse();
        } else if (data.isError) {
          this.toastr.error(data.errorMessage, 'Error');
          this.statusResponse();
        }
      });
    } else {
      this.settingService.enableCategory(JSON.stringify(this.categoryModel)).subscribe(data => {
        if (!data.isError) {
          this.toastr.success(data.payload, 'Success');
          this.statusResponse();
        } else if (data.isError) {
          this.toastr.error(data.errorMessage, 'Error');
          this.statusResponse();
        }
      });
    }
  }

  statusResponse() {
    if (this.platformType == 1) {
      this.isDisable = false;
      this.statusDisable = false;
      this.closeWindow();
      this.searchInput.nativeElement.value = "";
    }
    if (this.platformType == 2) {
      this.buttonDisabled[this.ind] = false;
    }
    this.getCategoryList();
  }

  closeWindow() {
    this.modalRef.hide();
  }

  addNewExpense() {
    this.router.navigateRoot(['/settings/expensecategory/add']);
  }

  navigateToAddExpense(data) {
    this.router.navigateRoot(['/settings/expensecategory/update', data.categoryId, data.categoryGUID, data.parentCategoryId ? data.parentCategoryId : '']);
  }

  hideDropdown() {
    if (this.selectedIndex !== -1) {
      this.selectedIndex = -1
    }
  }

  pcMainPolicyToggle(e: any) {
    if (e?.detail.checked) {
      this.enablePCMainPolicy();
    } else {
      this.disablePCMainPolicy();
    }
  }


  enablePCMainPolicy() {
    let modl = new polEnableEntityModels();
    modl.legalEntityId = this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId;
    modl.legalEntityGUID = this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId;
    this.settingService.settingsPettyCashEnable(modl).subscribe(res => {
      if (!res.isError) {
        this.ispcMainEnabled = true;
        this.tabsccess();
      }
    });
  }



  tabsccess() {
    let modl = new polEnableEntityModels();
    modl.legalEntityId = this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId;
    modl.legalEntityGUID = this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId;
    this.settingService.settingsTabsAccess(modl).subscribe(res => {
      if (!res.isError) {
        this.userInfo.isLocalConveyanceEnabled = res.payload.isLocalConveyanceEnabled
        this.userInfo.isPettyCashEnabled = res.payload.isPettyCashEnabled
        this.userInfo.isTravelEnabled = res.payload.isTravelEnabled
        sessionStorage.setItem("userInfo", JSON.stringify(this.userInfo));
        // localStorage.setItem("userInfo", JSON.stringify(this.userInfo));
        this.authenticationService.setisLocalConveyanceEnabled(res.payload.isLocalConveyanceEnabled);
        this.authenticationService.setisPettyCashEnabled(res.payload.isPettyCashEnabled);
        this.authenticationService.setisTravelEnabled(res.payload.isTravelEnabled);

        // window.location.reload();


      }
    });
  }

  updatePaginatedData() {
    const start = this.currentPage * this.pageSize;
    const end = start + this.pageSize;
    this.paginatedData = this.filteredData.slice(start, end);
    console.log(this.paginatedData);
  }

  nextPage() {
    if (this.currentPage < this.totalPages - 1) {
      this.currentPage++;
      this.updatePaginatedData();
    }
  }

  previousPage() {
    if (this.currentPage > 0) {
      this.currentPage--;
      this.updatePaginatedData();
    }
  }

  changePageSize(event: any) {
    console.log('dddd',event);
    this.pageSize = +event.value;
    this.currentPage = 0; // Reset to first page whenever page size changes
    this.totalPages = Math.ceil(this.totalItems / this.pageSize);
    this.updatePaginatedData();
  }


  disablePCMainPolicy() {
    let modl = new polEnableEntityModels();
    modl.legalEntityId = this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId;
    modl.legalEntityGUID = this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId;
    this.settingService.settingsPettyCashDisable(modl).subscribe(res => {
      if (!res.isError) {
        this.ispcMainEnabled = false;
        this.tabsccess();
      }
    });
  }
}
