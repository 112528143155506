import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CategoryAndMerchantModel, SystemFieldsModel, customFieldM, systemFieldsModel, systemListModel } from 'src/app/models/Common/common.model';
import { Category, CategoryList } from 'src/app/models/Settings/category.model';
import { CommonService } from 'src/app/services/common-services/common.service';
import { SettingsService } from 'src/app/services/settings-services';
import * as _ from "lodash";
import { ExpensesService } from 'src/app/services/expenses.service';

@Component({
  selector: 'app-custom-fields',
  templateUrl: './custom-fields.component.html',
  styleUrls: ['./custom-fields.component.scss'],
})
export class CustomFieldsComponent implements OnInit {

  selectedTabIndex = 0;
  workflowListType = ['one', 'two', 'three'];
  fieldsForm: FormGroup;
  requestModel = new SystemFieldsModel();
  userInfo: any
  systemFieldArray = [];
  buttonDisabled: boolean = false;
  dummyList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  showLoading: boolean = true;
  searchTerm: string;
  selectedLegalEntity: any;

  categoryList:any;
  categoryModel = new Category();
  claimTypes: any;
  selectedClaimTypeId:any;
  selectedClaimTypeName:any;
  customFieldTypes = [
    {id: 1, label: 'DROPDOWN'},
    {id: 2, label: 'TEXTFIELD'},
    {id: 3, label: 'DATEPICKER'},
    {id: 4, label: 'FILEUPLOAD'},
    {id: 5, label: 'Google Places'},
  ]

  systemFieldsForm: FormGroup;
  constructor(private formBuilder: FormBuilder,
    private commonService: CommonService,
    private settingService:SettingsService,
    private activatedRouter: ActivatedRoute, private toastr: ToastrService,
    private expenseService: ExpensesService) {

  }

  ngOnInit() {
    this.selectedLegalEntity = JSON.parse(sessionStorage.getItem("selectedLegalEntity"));
    this.categoryList = JSON.parse(sessionStorage.getItem('parent-categoryList'));
    console.log('parent category name :',this.categoryList);
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.requestModel.legalEntityGUID = this.selectedLegalEntity.legalEntityGuId || this.userInfo.legalEntityGuId;
    this.requestModel.legalEntityId = this.selectedLegalEntity.legalEntityId || this.userInfo.legalEntityId;
    this.requestModel.companyId = this.userInfo.companyId;
    this.requestModel.companyGUID = this.userInfo.companyGuId;

    this.activatedRouter.params.subscribe(params => {
      this.requestModel.formType = parseInt(params.id);
    })
    this.getClaimTypes();
    this.getSysteFieldDropdownList();
    this.customFieldsValidation();
    this.systemFieldsFormValidation();
  }

  // form group initilization
  customFieldsValidation() {
    this.fieldsForm = this.formBuilder.group({
      customFieldsList: this.formBuilder.array([])
    });
    let customF = new customFieldM();
  }

  FirstNestedForm(model): FormGroup {
    return this.formBuilder.group({
      fieldName: [model?.fieldName],
      customFieldType: [model?.customFieldType],
      statusId: [model?.statusId],
      fieldId: [model?.fieldId],
      displayType: [model?.displayType],
      isMandatory: [model?.isMandatory],
      datePickerFormat: [model?.datePickerFormat],
      textFiledValidation: [model?.textFiledValidation],
      dropDownList: this.formBuilder.array([]),
      categoryIds:[model?.categoryIds],
      category:[model?.category],
    })
  }

  get FirstNestedFormArray(): FormArray {
    return this.fieldsForm.get("customFieldsList") as FormArray;
  }

  // adding first row into parent
  addFirstNestedArray(customF?: customFieldM) {
    this.requestModel.customFieldList.push(customF ? customF: new customFieldM());
    this.FirstNestedFormArray.push(this.FirstNestedForm(customF));
    if (customF?.customFieldType == '1') {
      customF.dropDownList.forEach(element => {
        this.AddDropDownNestedArray(this.FirstNestedFormArray.length - 1, element);
      });
    } else {
      this.AddDropDownNestedArray(this.FirstNestedFormArray.length - 1, null);
    }

  }

  SecondNestedForm(stringV): FormGroup {
    return this.formBuilder.group({
      field_names: [stringV],
    })
  }

  // dropdown type is adding
  DropDownNestedArray(index): FormArray {
    return this.FirstNestedFormArray.at(index).get("dropDownList") as FormArray
  }

  AddDropDownNestedArray(index, stringV) {
    this.DropDownNestedArray(index).push(this.SecondNestedForm(stringV));
  }
  removeDropDownNestedItem(parentIndex, childIndex) {
    this.DropDownNestedArray(parentIndex).removeAt(childIndex)
  }

  // common add option to dropdown,dateicker,textfield
  addOption(index, item) {
    this.AddDropDownNestedArray(index, '');
  }

  removeFirstNestedItem(index) {
    this.FirstNestedFormArray.removeAt(index);
    this.requestModel.customFieldList.splice(index,1);
  }

  removeSyetemFieldsItem(index) {
    this.GetSystemFields?.removeAt(index);
    // this.systemFieldsForm.controls.systemFieldList?.removeAt(index);
    this.systemFieldsList.splice(index,1);
    this.requestModel.systemFieldList?.splice(index,1);
  }


  onTabChanged(event) {
    this.selectedTabIndex = event.index;
    if (this.selectedTabIndex == 0) {
      this.systemFieldsForm.setControl('systemFieldList', new FormArray([]));
      this.getSystemFieldsList();
    } else {
      this.showLoading = true;
      this.fieldsForm.reset();
      this.fieldsForm.setControl('customFieldsList', new FormArray([]));
      this.getUserFieldsList();
    }
  }

  // system fields Form validation 

  systemFieldsFormValidation() {
    this.systemFieldsForm = this.formBuilder.group({
      systemFieldList: this.formBuilder.array([])
    })
  }

  systemFields(model): FormGroup {
    return this.formBuilder.group({
      fieldId: [model.fieldId],
      fieldName: [model.fieldName, [Validators.required]],
      displayType: [model.displayType],
      isMandatory: [model.isMandatory],
      isReadOnly: [model.isReadOnly],
      statusId: [model.statusId]
    })
  }


  // system fields adding  index 0
  get GetSystemFields(): FormArray {
    return this.systemFieldsForm.get('systemFieldList') as FormArray;
  }
  AddingSystemFieldsIntoArray(model: systemFieldsModel) {
    console.log(model);
    console.log(this.GetSystemFields.value);
    console.log(this.requestModel.systemFieldList);
  }

  systemFieldsList: any = [];

  getSystemFieldsList() {
    this.showLoading = true;
    this.requestModel = new SystemFieldsModel();
    this.requestModel.claimTypeId = this.selectedClaimTypeId;
    this.requestModel.claimTypeName = this.selectedClaimTypeName;
    this.requestModel.legalEntityGUID = this.selectedLegalEntity.legalEntityGuId || this.userInfo.legalEntityGuId;
    this.requestModel.legalEntityId = this.selectedLegalEntity.legalEntityId || this.userInfo.legalEntityId;
    this.requestModel.companyId = this.userInfo.companyId;
    this.requestModel.companyGUID = this.userInfo.companyGuId;
    this.activatedRouter.params.subscribe(params => {
      this.requestModel.formType = parseInt(params.id);
    })
    // const obj = {
    //   "searchText": this.searchTerm || null,
    //   "legalEntityGUID": this.requestModel.legalEntityGUID || null,
    //   "legalEntityId": this.requestModel.legalEntityId || null,
    //   "companyId": this.requestModel.companyId || null,
    //   "companyGUID": this.requestModel.companyGUID || null,
    //   "formType": this.requestModel.formType || null,
    //   "claimTypeId": this.requestModel.claimTypeId || null,
    //   "claimTypeName": this.requestModel.claimTypeName || null
    // }
    this.commonService.getSystemFieldsList(this.requestModel).subscribe((response) => {
      if (response.errorCode == 0) {
        this.systemFieldsForm.setControl('systemFieldList', new FormArray([]));
        console.log(response.payload.systemFieldList);
        const listarray = [];
        
        this.systemFieldsList = response?.payload?.systemFieldList || [];
                // Adding a violation flag to each item in the list
        this.systemFieldsList = this.systemFieldsList.map(item => ({...item,violationFlag: false}));
        response.payload?.systemFieldList?.forEach(element => {
          let prepareM = new systemFieldsModel();
          prepareM.fieldName = element.fieldName;
          prepareM.fieldId = element.fieldId;
          prepareM.isMandatory = element.isMandatory;
          prepareM.displayType = element.displayType;
          prepareM.isReadOnly = element.isReadOnly;
          prepareM.statusId = element.statusId == 1 ? true : false;
          prepareM.category = element.category;
          if (element.categoryIds != null) {
            // const secondArray = element.category.split(",");
            // const filteredArray = _.filter(this.categoryList, item => _.includes(secondArray, item.categoryName));
            // let selectedItems = [];
            // selectedItems = _.map(filteredArray, 'categoryId');
            // if (selectedItems.length != 0) {
            //   prepareM.categoryIds = selectedItems;
            // } else {
            //   prepareM.categoryIds = [];
            // }
            prepareM.categoryIds = element.categoryIds;

          }else{
            prepareM.categoryIds = [];
          }
          // this.AddingSystemFieldsIntoArray(prepareM);
          listarray.push(prepareM);
          this.GetSystemFields.push(this.systemFields(prepareM));
          console.log('dssds',this.GetSystemFields.value);
        });
        this.requestModel.systemFieldList = listarray;
      }
      this.showLoading = false;
    })

  }

  getUserFieldsList() {
    this.requestModel.systemFieldList = [];
    this.requestModel.customFieldList = [];
    this.FirstNestedFormArray.clear();
    this.commonService.getUserFieldsList(this.requestModel).subscribe(response => {

      if (response.errorCode == 0) {
        if(response.payload?.customFieldList?.length != 0){
          this.fieldsForm.setControl('customFieldsList', new FormArray([]));
          response.payload.customFieldList.forEach(element => {
            let customF = new customFieldM();
            // customF.fieldId = element.fieldId;
            customF.fieldId = null;
            customF.fieldName = element.fieldName;
            customF.displayType = element.displayType;
            customF.isMandatory = element.isMandatory;
            const ind = this.customFieldTypes?.findIndex(item => item?.label == element?.customFieldType);
            customF.customFieldType = this.customFieldTypes[ind]?.id;
            customF.textFiledValidation = element.textFiledValidation != '' ? (element.textFiledValidation == '4' ? 4 : (element.textFiledValidation == '5' ? 5 : 6)) : '';
            customF.datePickerFormat = element.datePickerFormat != '' ? (element.datePickerFormat == 'dd-mm-yyyy' ? 7 : 8) : '';
            customF.dropDownList = element.dropDownList.split(",");
            customF.category = element.category;
  
            if(element.category != null){
              const secondArray = element.category.split(",");
              const filteredArray = _.filter(this.categoryList, item => _.includes(secondArray, item.categoryName));
              let selectedItems = [];
              selectedItems = _.map(filteredArray, 'categoryId');    
              if(selectedItems.length != 0){
                customF.categoryIds = selectedItems;
              }else{
                customF.categoryIds = [];
              }
              }

              console.log('sdsd',customF);
  
            this.addFirstNestedArray(customF);
          });
        }
      } else {
        this.toastr.error(response?.errorMessage, 'Error');
      }
      this.showLoading = false;
    })
  }

  updateDetails() {
    if (this.selectedTabIndex == 0) {
      this.buttonDisabled = true;
      let requestlist = [];
      let listarray = this.GetSystemFields.value;
      requestlist = [...listarray];
      requestlist.forEach((element,index) => {
        this.requestModel.systemFieldList[index].statusId = (element?.statusId == true ? 1 : 2);
        this.requestModel.systemFieldList[index].isMandatory = (element?.isMandatory == true ? true : false);
        this.requestModel.systemFieldList[index].isReadOnly = false;
        this.requestModel.systemFieldList[index].displayType = element.displayType ? element.displayType : 1;
        this.requestModel.systemFieldList[index].fieldName = element?.fieldName;
        this.requestModel.systemFieldList[index].fieldId = element?.fieldId;
        this.requestModel.systemFieldList[index].claimTypeId = this.selectedClaimTypeId;
        this.requestModel.systemFieldList[index].claimTypeName = this.selectedClaimTypeName;
        this.requestModel.systemFieldList[index].categoryIds = this.systemFieldsList[index]?.categoryIds;
      });

      // if(this.requestModel.systemFieldList.length == 0){
      //   this.toastr.error('Please add atleast one system field','Error');
      //   return;

      // }

      this.commonService.saveSystemFields(this.requestModel).subscribe(response => {
        if (response.errorCode == 0) {
          this.buttonDisabled = false;
          this.requestModel.systemFieldList = [];
          this.systemFieldArray = [];
          this.systemFieldsForm.setControl('systemFieldList', new FormArray([]));
          this.systemFieldsForm.reset();
          this.toastr.success(response.payload, 'Success');
          this.getSystemFieldsList();
        }else{
          this.toastr.error(response.errorMessage, 'Error');
        }
      })
    } else {
      let customArray = [];
      customArray = this.fieldsForm.value.customFieldsList;
      customArray.forEach((element, index) => {
        const ind = this.customFieldTypes?.findIndex(item => item?.id == element?.customFieldType);
        element.customFieldTypeName = this.customFieldTypes[ind]?.label;
        element.textFiledValidationName = (element.textFiledValidationName != '' ? (element.textFiledValidation == 4 ? 'NUMERIC' : (element.textFiledValidation == 5 ? 'ALPHANUMERIC' : 'ALPHABET')) : '');
        element.datePickerFormatName = (element.datePickerFormat != '' ? (element.datePickerFormat == 7 ? 'dd-mm-yyyy' : 'mm-dd-yyyy') : '');

        if (element.customFieldType == '1') {
          element.dropDownList.forEach(innerEle => {
            let keysArray = element.dropDownList.map(item => item.field_names);
            let keysArray1 = keysArray.join(",")
            element.convertedString = `${keysArray1}`;
          });
        } else {
          element.convertedString = '';
        }
      });
      this.requestModel.systemFieldList = [];
      customArray.forEach((element,index) => {
      

        this.requestModel.customFieldList[index].customFieldType = element.customFieldTypeName;
        this.requestModel.customFieldList[index].displayType = element.displayType ? element.displayType : 1;
        this.requestModel.customFieldList[index].dropDownList = element.convertedString;
        // this.requestModel.customFieldList[index].convertedString = element.convertedString;
        this.requestModel.customFieldList[index].datePickerFormat = element.datePickerFormatName;
        this.requestModel.customFieldList[index].fieldName = element.fieldName;
        this.requestModel.customFieldList[index].textFiledValidation = element.textFiledValidationName;
        this.requestModel.customFieldList[index].isMandatory = element.isMandatory;

        this.requestModel.customFieldList[index].textFiledValidation = element.textFiledValidation;
        this.requestModel.customFieldList[index].fieldId = element.fieldId;
        
        // this.requestModel.customFieldList.push(customF);
      });

      // if(this.requestModel.customFieldList.length == 0){
      //   this.toastr.error('Please add atleast one custom field','Error');
      //   return;
      // }
      console.log('request model',this.requestModel);
      
      this.commonService.saveCustomFields(this.requestModel).subscribe(response => {
        if (response.errorCode == 0) {
          this.requestModel.customFieldList = [];
          this.fieldsForm.reset();
          this.fieldsForm.setControl('customFieldsList', new FormArray([]));
          this.toastr.success(response.payload, 'Success')
          setTimeout(() => {
            this.getUserFieldsList();
          }, 1000);
        }else{
          this.toastr.error(response.errorMessage,'Error');
        }
      })
    }
  }

  changeClaimcategoy(index,event?){

    const fieldName = this.systemFieldsList[index].fieldName;
    let selectedCategoryIds = [];
    if(event != null){
      selectedCategoryIds = event.map(item => item.value);
    }else{
      selectedCategoryIds = this.systemFieldsList[index].categoryIds;
    }
  
    console.log('sdsd',event,selectedCategoryIds);
    // Toggle violation flag based on category selection and duplication check
    if (selectedCategoryIds.length == 0) {
      this.systemFieldsList[index].violationFlag = false;
    } else {
      this.systemFieldsList[index].violationFlag = this.isDuplicateAssignment(fieldName, selectedCategoryIds, index);
    }
  
   
  }

  isDuplicateAssignment(fieldName, categoryIds, currentIndex) {
    // Iterate over each item in the list, excluding the current one
    for (let i = 0; i < this.systemFieldsList.length; i++) {
      if (i !== currentIndex && this.systemFieldsList[i].fieldName === fieldName) {
        // Check if there is any overlap in category IDs
        const commonCategories = this.systemFieldsList[i].categoryIds.filter(id => categoryIds.includes(id));
        if (commonCategories.length > 0) {
          return true; // Return true if there is any overlap, indicating a violation
        }
      }
    }
    return false; // No violations found
  }

  
  

  changeClaimcategoy1(ev,i){
    let selectedClaimCategory = ev || [];
    this.requestModel.customFieldList[i].category = ''
    this.requestModel.customFieldList[i].category = selectedClaimCategory.map(item => item.text).join(',');    
  }


  

  search() {
    if (this.searchTerm != '') {
      this.requestModel.searchText = this.searchTerm;
      this.requestModel.systemFieldList = [];
      this.requestModel.customFieldList = [];
      this.systemFieldArray = [];
      this.systemFieldsForm.reset();
      this.getSystemFieldsList();
    }

    // let itemArray = this.systemFieldsForm.get('systemFieldList') as FormArray;
    // console.log('search term :',this.searchTerm);
    // const filteredItems = this.systemFieldArray.filter(control => {
    //   const itemName = control.fieldName as string;
    //   return itemName.includes(this.searchTerm);
    // });

    // console.log('fill',filteredItems);

    // this.GetSystemFields.clear(); // Remove all existing items
    // // Add the filtered items back to the FormArray
    // filteredItems.forEach(item => {
    //   this.GetSystemFields.push(item);
    //   // this.GetSystemFields.push(item);
    // });
  }

  systemDropdownList: any = [];
  getSysteFieldDropdownList() {
    let obj = {
      "legalEntityGUID": this.userInfo?.legalEntityGuId,
      "legalEntityId": this.userInfo?.legalEntityId,
      "companyId": this.userInfo?.companyId,
      "companyGUID": this.userInfo?.companyGuId,
      'formType':this.requestModel.formType ? this.requestModel.formType : 1
    }
    this.settingService.getSystemFieldsDropdownList(obj).subscribe(res => {
      console.log(res);
      this.systemDropdownList = res?.payload || [];
      console.log(this.systemDropdownList);
    })
  }

  getClaimTypes() {
    const obj = {
      "action": "claim_types",
      "legalEntityGuid": this.userInfo?.legalEntityGuId,
      "legalEntityId": this.userInfo?.legalEntityId,
      "companyId": this.userInfo?.companyId,
      "companyGUID": this.userInfo?.companyGuId,
    }
    this.expenseService.getClaimTypes(obj).subscribe(res => {
      this.claimTypes = res?.payload || [];
      console.log(this.claimTypes);
      if(this.claimTypes?.length != 0 && this.userInfo?.isEnableClaimTypes){

        this.selectedClaimTypeId = this.claimTypes[0].id;
        this.selectedClaimTypeName = this.claimTypes[0].claimType;
      }
      this.getSystemFieldsList();
    })
  }

  setClaimType(event) {
    console.log(event);
    console.log(this.selectedTabIndex);
    this.selectedClaimTypeId = event.id;
    this.selectedClaimTypeName = event.claimType;
    this.requestModel.claimTypeId = event.id;
    this.requestModel.claimTypeName = event.claimType;
    if(this.selectedTabIndex == 0){
      this.getSystemFieldsList();
    }else{
      this.showLoading = true;
      this.getUserFieldsList();
    }
  }

  // systemFieldsList: any = [];
  addSystemField() {
    let obj = new systemFieldsModel();
    obj.fieldName = '';
    obj.fieldId = null;
    obj.displayType = 2;
    obj.isMandatory = false;
    obj.isReadOnly = false;
    obj.statusId = false;
    // this.AddingSystemFieldsIntoArray(obj);
    this.systemFieldsList.push(obj);
    this.GetSystemFields.push(this.systemFields(obj));
    this.requestModel.systemFieldList.push(obj);
  }
}