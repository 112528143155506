import {animate, style, transition, trigger} from '@angular/animations';

export const fadeInOut = [
    trigger('fadeInOut', [
        transition(':enter', [   // :enter is alias to 'void => *'
            style({opacity: 0}),
            animate('200ms', style({opacity: 1}))
        ]),
        transition(':leave', [   // :leave is alias to '* => void'
            animate('200ms', style({opacity: 0}))
        ])
    ])
];
