import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Constants } from "src/app/Constants";
@Injectable({
  providedIn: "root",
})
export class PerdiemService {
    constructor(public httpClient: HttpClient) { }

    perdiemCreation(requestBody): Observable<any> {
       // const url = "https://dev.api.noexpenses.com/ne/v1/perdiem/create";
        const url = Constants.DOMAIN_URL + "v1/perdiem/create";
        const body = requestBody;
        return this.httpClient.post(url, body);
      }
}
