import { IAppConfig } from "../config/iapp.config";

export const AppConfig: IAppConfig = {
  errorEmptyInput: "Please enter userName and password",
  errorLogin: "The E-mail or Password you entered is incorrect",
  validEmail: "Email must be a valid email address",
  email: "Email is required",
  password: "Password is required",
  confirmPassword: "Confirm password is required",
  minCharacters: "Minimum 8 characters required",
  otp: "Otp is required",
  phoneNumber: "Phone number is required",
  required: "Field is required",
  country: "Country is required",
  companyName: "Company name is required",
  firstName: "First name is required",
  merchant: "Merchant name is required",
  description: "Description is required",
  category: "Category is required",
  expectedDate: "Date is required",
  itemName: "Name is required",
  itemAmount: "Amount is required",
  amount: "Amount is required",
  report: "Claim is required",
  curency: "Currency is required",
  claimName: "Claim Name is required",
  claimId: "Claim ID is required",
  tripId: "Trip ID is required",
  emailId: "Email is required",
  errorMsg: 'Field is required'
};
