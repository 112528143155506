import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CategoryAndMerchantModel, CategoryList, FilterModal, StatusListModel } from 'src/app/models/Common/common.model';
import { CommonService } from 'src/app/services/common-services/common.service';
import { CustomSelectDropdownComponent } from '../custom-select-dropdown/custom-select-dropdown.component';

@Component({
    selector: 'app-common-filter',
    templateUrl: './common-filter.component.html',
    styleUrls: ['./common-filter.component.scss'],
})
export class CommonFilterComponent implements OnInit {
    @Input() mainList: any[];
    filterModal = new FilterModal();
    CategoryMerchantModel = new CategoryAndMerchantModel();
    userInfo: any;
    categoryName: any;
    status: any;
    categoryList: any;
    statusList: any;
    fromPettyCash: any;
    fromAnlyticsPage: any;
    expcurrencyIcon: any;
    tDate: string;
    mobMaxDate: string;

    maxDateStr: any = moment(new Date()).format('YYYY-MM-DD');
    minDateStr: any = moment(new Date().getTime()).subtract(2000, 'years').format('YYYY-MM-DD');
    frmDate: string;
    minDate: string;

    constructor(public modalController: ModalController,
        private commonService: CommonService,
        private translate: TranslateService,
        public toastr: ToastrService,) {
        this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        this.expcurrencyIcon = this.userInfo.baseCurrency.currencyIcon;

    }

    ngOnInit() {
     }
    ionViewWillEnter() {
        this.getCategorylist();
        this.getStatusList();
    }

    reportexpsearch(e) {
        this.filterModal.searchText = e.detail.value;
    }

    async openModal(value: string, type: string, isCategory?: boolean) {
        const componentProps = this.getComponentProps(type);
        if (value && value !== '') {
            componentProps.value = this.filterModal[componentProps.setValueKey];
        }
        const searchItemModal = await this.modalController.create({
            component: CustomSelectDropdownComponent,
            componentProps,
            keyboardClose: false,
        });
        searchItemModal.onDidDismiss()
            .then((callback) => {
                if (callback && callback.data) {
                    // TODO give onchange methods for status and category
                    if (typeof callback.data !== 'string') {
                        this[componentProps.listName] = callback.data;
                        const arr = [];
                        if (isCategory) {
                            this.categoryName = null;
                        } else {
                            this.status = null;
                        }
                        callback.data.forEach((obj: any) => {
                            if (obj.isSelected) {
                                arr.push(obj[componentProps.itemValueField]);
                                if (isCategory) {
                                    if (this.categoryName && this.categoryName !== '') {
                                        this.categoryName = `${this.categoryName}, ${obj[componentProps.itemTextField]}`;
                                    } else {
                                        this.categoryName = obj[componentProps.itemTextField];
                                    }
                                } else {
                                    if (this.status && this.status !== '') {
                                        this.status = `${this.status}, ${obj[componentProps.itemTextField]}`;
                                    } else {
                                        this.status = obj[componentProps.itemTextField];
                                    }
                                }
                            }
                        });
                        if (arr && arr.length) {
                            this.filterModal[componentProps.setValueKey] = arr;
                        }
                    }
                }
            });
        return await searchItemModal.present();
    }

    getComponentProps(type: string) {
        switch (type) {
            case 'categoryName':
                return {
                    title: 'Categories',
                    value: null,
                    mainList: this.categoryList,
                    isMultiSelect: true,
                    itemValueField: 'categoryId',
                    itemTextField: 'categoryName',
                    listName: `categoryList`,
                    setValueKey: 'categoryIds'
                };
            case 'status':
                return {
                    title: 'Status',
                    value: null,
                    mainList: this.statusList,
                    isMultiSelect: true,
                    itemValueField: 'statusId',
                    itemTextField: 'statusDescription',
                    listName: `statusList`,
                    setValueKey: 'statusIds'
                };
            // case 'claimName':
            //     return {
            //         title: 'Reports',
            //         value: null,
            //         mainList: this.reportList,
            //         isMultiSelect: false,
            //         itemValueField: 'claimId',
            //         itemTextField: 'cliamNamecode',
            //         listName: `reportList`,
            //         buttonName: this.translate.instant('common.save'),
            //         add: {
            //             buttonName: this.translate.instant('reports.addreportbutton'),
            //             textFieldName: 'Report',
            //             placeholder: this.translate.instant('reports.placeholdrepo')
            //         }
            //     };
        }
    }
    getCategorylist() {

        this.CategoryMerchantModel.legalEntityId = this.userInfo.legalEntityId;
        this.CategoryMerchantModel.legalEntityGUID = this.userInfo.legalEntityGuId;
        if (this.fromPettyCash) {
            this.CategoryMerchantModel.recordType = 4;
        } else {
            this.CategoryMerchantModel.recordType = 1;
        }
        // this.CategoryMerchantModel.status = 1;
        // this.CategoryMerchantModel.categoryType = null;
        this.commonService
            .getCatgryMasterlist(this.CategoryMerchantModel)
            .subscribe((res) => {
                if (!res.isError && res.errorCode === 0) {
                    const listarray = res.payload;
                    if (this.fromPettyCash) {
                        this.categoryList = listarray.pettyCashCategoryList.map((list) => new CategoryList(list));
                    } else {
                        this.categoryList = listarray.expenseCategoryList.map((list) => new CategoryList(list));
                    }

                } else if (res.isError === true) {
                    this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
                }
            });
    }
    getStatusList() {
        const type = { type: +1 };
        this.commonService.statusList(type).subscribe((res) => {
            if (!res.isError && res.errorCode === 0) {
                const listarray = res.payload;
                this.statusList = listarray.map((list) => new StatusListModel(list));
                // if (this.fromAnlyticsPage) {
                this.statusList.filter(res => {
                    if (res.statusId == this.fromAnlyticsPage) {
                        res.isSelected = true;
                    }
                });
                // }
            } else if (res.isError === true) {
                this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
            }
        });
    }

    closeCall(data) {
        this.modalController.dismiss(data ? this.filterModal : false);

    }

    resetAll() {
        this.filterModal = new FilterModal();
    }
    applyfilter() {
        this.modalController.dismiss({
            dismissed: true,
        });
    }
    getToDateCall(ev) {

        this.tDate = moment(ev.detail.value).format("D MMM YYYY");
        this.filterModal.toDate = moment(ev.detail.value).format("MM-DD-YYYY");
        this.mobMaxDate = moment(ev.detail.value).format("DD-MM-YYYY");
    }
    getFromDateCall(ev) {
        this.frmDate = moment(ev.detail.value).format("D MMM YYYY");
        this.filterModal.fromDate = moment(ev.detail.value).format("MM-DD-YYYY");
        this.minDate = moment(ev.detail.value).format("DD-MM-YYYY");
        this.minDateStr = moment(ev.detail.value).format("YYYY-MM-DD");
    }

}
