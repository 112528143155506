import { ChangeDetectorRef, Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { PlatformService } from 'src/app/services/platformbase.service';
import { UserList, UserListResponse } from 'src/app/models/Settings/personal-info.model';
// import { IonicSelectableComponent } from 'ionic-selectable';
import { SettingsService } from 'src/app/services/settings-services';
import { ToastrService } from 'ngx-toastr';
import { Camera, CameraOptions } from "@ionic-native/camera/ngx";
import { AppConfig } from 'src/app/config/app.config';
import { ActionSheetController, IonInput, ModalController, NavController } from "@ionic/angular";
import { CapturedImageModal, ExpenseAttachments, ExpenseAttachmentsModel } from "../../../models/Expenses/expenses.model";
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { FileUpload } from 'src/app/models/Settings/fileupload.model';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/services/common-services/shared.service';
import { CustomSelectDropdownComponent } from '../../custom-select-dropdown/custom-select-dropdown.component';
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'app-personal-information',
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.scss'],
})
export class PersonalInformationComponent implements OnInit {
  // @ViewChild("selectTitle") selectTitle: IonicSelectableComponent;
  // @ViewChild("selectNationality") selectNationality: IonicSelectableComponent;
  // @ViewChild("selectMobile") selectMobile: IonicSelectableComponent;
  @ViewChild('fileUpload', { static: false }) fileUploadInput: ElementRef;
  @ViewChild('mobileCode', { static: false }) mobileCodeAutoCompleteReference: ElementRef;
  @ViewChild('titleCode', { static: false }) TitleCodeAutoCompleteReference: ElementRef;
  @ViewChild('nationality', { static: false }) nationalCodeAutoCompleteReference: ElementRef;
  @ViewChild('costCenter', { static: false }) costCenterAutoCompleteReference: ElementRef;
  platformType: number;
  personalInfoForm: FormGroup;
  userModel = new UserList();
  titleList: any;
  nationalityList: any;
  request: any = {};
  countryList: any;
  userInfo: any;
  selecetedLegalEntity: any;
  showLoader = true;
  loaderList: any = [1, 2, 3, 4];
  submitted = false;
  buttonDisabled = false;
  validationMsg: any;
  profilePic: string;
  caputredImageModal = new CapturedImageModal();
  capturedImage: any;
  uploadUrl: any;
  croppedImage: any = '';
  showImageCropper: Boolean = false;
  imageChangedEvent: any = '';
  croppedImageToDisplay: any = '';
  imageCropper: boolean = false;
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  transform: ImageTransform = {};
  showTitleCodeList: boolean = false;
  showMobileCodeList: boolean = false;
  showNationalCodeList: boolean = false;
  fileUploadModel = new FileUpload();
  disableValue: any;
  personalInfoUrl: string;
  titleData: any;
  titleSelected: any;
  countryData: any;
  selectedCountryCode: any;
  mobileCodeVal: string;
  nationalityData: any;
  selectednationalityCode: any;
  employmentData: any;
  employmentList: any;
  bandData: any;
  departmentData: any;
  designationData: any;
  teamsdata: any;
  userList: any;
  selectedEmployment: any;
  showEmployment:boolean=false;
  userData: any;
  usersList: any;
  tempUserList: any;
  showUserList: boolean;
  showRoleList: boolean;
  showreportmanager: boolean;
  selectedUser: any;
  selectedRole: any;
  showRoleNameList: number;
  selectedRoleName: any;
  roleData: any;
  roleList: any;
  roleNameData: any;
  showTeamsList: boolean;
  showteams: boolean;
  selectedTeams: any;
  TeamsSearch: any;
  teamsList: any;
  showCostCenterList: boolean;
  showcostcenter: boolean;
  selectedCostCenter: any;
  costCenterdata: any;
  costCenterList: any;
  showDepartmentList: boolean;
  showdepartment: boolean;
  selectedDepartment: any;
  departmentList: any;
  showLocationList: boolean;
  selectedLocation: any;
  locationData: any;
  locationList: any;
  showBandList: boolean;
  showband: boolean;
  selectedBand: any;
  bandList: any;
  showDesignationList: boolean;
  selectedDesignation: any;
  showdesignation: boolean;
  designationList: any;
  showBillingList: boolean;
  costCenterData: any;
  selectedBillingEntity: any;
  billingLegalEntityData: any;
  billingLegalEntityList: any;
  isValidAge: boolean;
  showdoj: boolean;
  displaydate: any;
  showdob: boolean;
  dayOfBirthOfUser: any;
  dateofJoininguser: any;
  allowMax:Date;
  constructor(
    private fb: FormBuilder,
    private platformService: PlatformService,
    private settingService: SettingsService,
    private toastr: ToastrService,
    private camera: Camera,
    private actionSheetController: ActionSheetController,
    private changeDetectorRef: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private sharedService: SharedService,
    private modalController: ModalController,
    private title: Title,
    public router: NavController,
  ) {
    this.personalInfoForm = this.fb.group({
      title: ['', [Validators.required]],
      firstName: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      lastName: ['', [Validators.required, Validators.minLength(2), Validators.pattern('^[a-zA-Z ]*$')]],
      mobileCode: [''],
      mobileNumber: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.maxLength(10), Validators.minLength(10)]],
      businessEmail: ['', Validators.email],
      gender: [''],
      nationality: [''],
      legalEntityName:[''],
      companyEmployeeId:[''],
      designationName:[''],
      bandName:[''],
      dateOfJoing:[''],
      workLocationName:[''],
      departmentName:[''],
      costCenterName:[''],
      teamName:[''],
      dateOfBirth:[''],
      reportingManager:[''],
      userRole:[''],
      employeeType:['']
    });
    this.activatedRoute.url.subscribe((result) => {
      this.personalInfoUrl = result[0].path;
    });
  }
 
  ngOnInit() {
    let date = new Date();
    this.allowMax = new Date(date.getFullYear(), date.getMonth(), date.getDate());

   }

  ionViewWillEnter() {
    this.platformType = this.platformService.getPlatformType();
    this.selecetedLegalEntity = JSON.parse(sessionStorage.getItem("selectedLegalEntity"));
    this.sharedService.setSideBarFlag(true);
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.isDisableOnly();
    let applicationTitle = this.userInfo.applicationTitle;
    if(applicationTitle){
        this.title.setTitle('Personal Information | My Profile' + ' | ' + applicationTitle);
    }else{ 
      this.title.setTitle("Personal Information | My Profile | NoExpense")
    }

    this.settingService.getTitle({ pickListType: "Title_Type" }).subscribe(data => {
      this.titleList = data.payload;
    });
    this.settingService.getNationalities(this.request).subscribe(data => {
      this.nationalityList = data.payload;
    });
    this.settingService.getCountries(this.request).subscribe(data => {
      this.countryList = data.payload;
    });
    this.validationMsg = AppConfig;
    this.getPersonalInfo();
    this.sharedService.setUserLogo(this.userModel.profilePicPath);
  }

  getPersonalInfo() {
    this.userModel.userId = this.userInfo.userId;
    this.userModel.userGuid = this.userInfo.userGuId;
    this.settingService.getSingleUser(this.userModel).subscribe(data => {
      if (!data.isError && data.payload) {
        this.showLoader = false;
        this.userModel = new UserListResponse(data.payload);
        if (this.platformType === 2) {
          this.personalInfoForm.controls.title.patchValue(
            this.userModel.title
          );
          this.personalInfoForm.controls.mobileCode.patchValue(
            this.userModel.countryCode ? this.userModel.countryCode : 'IN(+91)'
          );
          this.personalInfoForm.controls.nationality.patchValue(
            this.userModel.nationalityName
          );
          this.capturedImage = this.userModel.profilePicPath;
        }
        if (this.platformType === 1) {
          this.croppedImage = this.userModel.profilePicPath;
          sessionStorage.setItem("personalInfo", JSON.stringify(this.userModel))
          // this.sharedService.setPersonalInfo(this.userModel);
        }
      }
    });
  }

  async presentActionSheet() {
    const actionSheet = await this.actionSheetController.create({
      header: 'Profile photo',
      cssClass: '',
      buttons: [
        {
          text: 'Gallery',
          icon: 'image-outline',
          handler: () => {
            this.getPhoto();
          }
        }, {
          text: 'Camera',
          icon: 'camera-outline',
          handler: () => {
            this.takePhoto();
          }
        }
      ]
    });
    await actionSheet.present();
  }


  takePhoto() {
    const options: CameraOptions = {
      quality: 50,
      targetWidth: 400,
      targetHeight: 500,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.PNG,
      mediaType: this.camera.MediaType.PICTURE,
      sourceType: this.camera.PictureSourceType.CAMERA,
      correctOrientation: true,
    };
    this.camera.getPicture(options).then(
      (imageData) => {
        const fileName = Math.random().toString(36).substr(2, 15) + ".png";
        this.capturedImage = "data:image/png;base64," + imageData;
        this.buttonDisabled = true;
        this.changeDetectorRef.detectChanges();
        this.fileUploadService(this.capturedImage);
      },
      (err) => {
        // Handle error
      }
    );
  }

  getPhoto() {
    const options: CameraOptions = {
      quality: 70,
      destinationType: this.camera.DestinationType.DATA_URL,
      sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
      saveToPhotoAlbum: false
    };
    this.camera.getPicture(options).then((imageData) => {
      // imageData is either a base64 encoded string or a file URI
      // If it's base64:
      this.capturedImage = 'data:image/jpeg;base64,' + imageData;
      this.buttonDisabled = true;
      this.changeDetectorRef.detectChanges();
      this.fileUploadService(this.capturedImage);
    }, (err) => {
      // Handle error
    });
  }

  fileUploadService(baseImg) {
    this.fileUploadModel.id = this.selecetedLegalEntity.companyId;
    this.fileUploadModel.fileName = `IMG-${Math.floor(Math.random() * 10000000) + 1}`;
    this.fileUploadModel.fileUploadFor = "PROFILE_PIC";
    this.fileUploadModel.base64Image = baseImg;
    this.uploadFile();
  }

  uploadFile() {
    this.settingService.fileUpload(this.fileUploadModel).subscribe(fileUpload => {
      if (!fileUpload.isError) {
        if (fileUpload.payload.uploadFlag) {
          this.toastr.success(fileUpload.payload.uploadMessage, 'Success');
          this.uploadUrl = fileUpload.payload.uploadUrl;
          this.buttonDisabled = false;
          this.showImageCropper = false;
          this.imageCropper = false;
          this.imageChangedEvent = "";
        } else {
          this.toastr.error(fileUpload.payload.uploadMessage, 'Error');
          this.buttonDisabled = false;
        }
      } else {
        this.buttonDisabled = false;
        this.toastr.error("We are facing issue to upload image...", 'Error');
        this.showImageCropper = false;
        this.imageCropper = false;
        this.imageChangedEvent = "";
      }
    });
  }

  titleChange(e) {
    if (e != null) {
      this.userModel.title = e.pickListValue;
    }
  }

  applyTitle() {
    // this.selectTitle.confirm();
    // this.selectTitle.close();
  }

  nationalityChange(e) {
    if (e != null) {
      this.userModel.nationalityId = e.countryId;
      this.userModel.nationalityName = e.nationality;
    }
  }

  applyNationality() {
    // this.selectNationality.confirm();
    // this.selectNationality.close();
  }

  mobileChange(e) {
    if (e != null) {
      this.userModel.countryCode = e.shortCode ? e.shortCode + '(' + '+' + e.countryCode + ')' : '+' + e.countryCode;
    }
  }

  applyMobile() {
    // this.selectMobile.confirm();
    // this.selectMobile.close();
  }

  /****  web view */
  fileChangeEvent(event: any): void {
    if (!event.target) {
      return;
    }
    if (!event.target.files) {
      return;
    }
    if (event.target.files.length !== 1) {
      return;
    }
    const file = event.target.files[0];
    if (
      file.type !== 'image/jpeg' &&
      file.type !== 'image/png' &&
      file.type !== 'image/jpg'
    ) {
      return;
    }
    if (file.size > 1048576) {
      return;
    }
    this.showImageCropper = true;
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImageToDisplay = event;
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  doneCropping() {
    this.imageCropper = true;
    let base64URL = this.croppedImage;
    base64URL = base64URL.split(",")
    let fileName = this.imageChangedEvent.target.files[0].name;
    this.fileUploadModel.id = this.selecetedLegalEntity.companyId;
    this.fileUploadModel.fileName = fileName;
    this.fileUploadModel.fileUploadFor = "PROFILE_PIC";
    this.fileUploadModel.base64Image = base64URL[1];
    this.uploadFile();
  }

  resetInputFile() {
    this.fileUploadInput.nativeElement.value = "";
  }

  changeImage() {
    this.croppedImageToDisplay = "";
    this.croppedImage = this.userModel.profilePicPath;
    this.imageChangedEvent = "";
    this.showImageCropper = false;
    this.imageCropper = false;
  }

  openTitleDropdown(e) {
    this.closeAll();
    if (!this.disableValue) {
      e.preventDefault();
      e.stopPropagation();
      this.showTitleCodeList = true;
      setTimeout(() => {
        this.TitleCodeAutoCompleteReference.nativeElement.focus();
      }, 0);
    } else {
      this.showTitleCodeList = false;

    }
  }

  onTitleEventFocus() {
    setTimeout(() => {
      this.titleData = this.titleList;
    }, 0);
  }

  onTitleCodeSearch(val: any) {
    let searchText = val.currentTarget.value.trim();
    let filterTitleCodeData = this.titleList.filter(titleCodeData => {
      if (titleCodeData.pickListValue.toLowerCase().indexOf(searchText.toLowerCase()) >= 0) {
        return titleCodeData;
      }
    });
    this.titleData = filterTitleCodeData;
  }

  selectTitleCode(selectedTitle) {
    this.titleSelected = selectedTitle;
    this.showTitleCodeList = false;
    this.personalInfoForm.patchValue({
      title: this.titleSelected.pickListValue,
      gender: this.titleSelected.pickListSubValue
    });
  }

  onClickedOutsideTitleCode() {
    this.showTitleCodeList = false;
  }

  openMobileCodeDropdown(e) {
    this.closeAll();
    e.preventDefault();
    e.stopPropagation();
    this.showMobileCodeList = true;
    setTimeout(() => {
      this.mobileCodeAutoCompleteReference.nativeElement.focus();
    }, 0);
  }

  onMobileEventFocus() {
    setTimeout(() => {
      this.countryData = this.countryList;
    }, 0);
  }

  onMobileCodeSearch(val: any) {
    let searchText = val.currentTarget.value.trim();
    let filterMobileCodeData = this.countryList.filter(mobileCodeData => {
      return ((mobileCodeData.shortCode !== null && mobileCodeData.shortCode.toLowerCase().includes(searchText)) ||
        (mobileCodeData.countryCode !== null && mobileCodeData.countryCode.toLowerCase().includes(searchText))
      );
    });
    this.countryData = filterMobileCodeData;
  }

  selectMobileCode(selectedMobileCode) {
    if (selectedMobileCode != undefined) {
      this.selectedCountryCode = selectedMobileCode;
      this.mobileCodeVal = this.selectedCountryCode.shortCode ? (this.selectedCountryCode.shortCode + ' (+' + this.selectedCountryCode.countryCode + ')') : '+' + this.selectedCountryCode.countryCode;
      this.showMobileCodeList = false;
      this.personalInfoForm.patchValue({
        mobileCode: this.mobileCodeVal
      });
      this.userModel.countryCode = this.mobileCodeVal;
    }
  }

  onClickedOutsideMobileCode() {
    this.showMobileCodeList = false;
  }

  openNationalCodeDropdown(e) {
    this.closeAll();
    if (!this.disableValue) {
      e.preventDefault();
      e.stopPropagation();
      this.showNationalCodeList = true;
      setTimeout(() => {
        this.nationalCodeAutoCompleteReference.nativeElement.focus();
      }, 0);
    } else {
      this.showNationalCodeList = false;

    }
  }

  onNationalEventFocus() {
    setTimeout(() => {
      this.nationalityData = this.nationalityList;
    }, 0);
  }

  onNationalCodeSearch(e) {
    let searchText = e.target.value.trim();
    let filterMobileCodeData = this.nationalityList.filter(mobileCodeData => {
      return (mobileCodeData.nationality.toLowerCase().indexOf(searchText.toLowerCase()) >= 0)
    });
    this.nationalityData = filterMobileCodeData;
  }

  selectNationalCode(selectedMobileCode) {
    this.selectednationalityCode = selectedMobileCode;
    this.showNationalCodeList = false;
    this.personalInfoForm.patchValue({
      nationality: this.selectednationalityCode.nationality
    });
    this.userModel.nationalityId = this.selectednationalityCode.countryId;
  }

  onClickedOutsideNationalCode() {
    this.showNationalCodeList = false;
  }

  closeAll() {
    this.showTitleCodeList = false;
    this.showNationalCodeList = false;
    this.showMobileCodeList = false;
  }

  isDisableOnly() {
    if (this.personalInfoUrl == 'personalInfo') {
      this.disableValue = true;
    } else if (this.personalInfoUrl == 'userpersonalInfo') {
      this.disableValue = true;
    }
  }

  /******* end */

  async updatePersonalInfo() {
    this.submitted = true;
    this.userModel.profilePicPath = this.uploadUrl ? this.uploadUrl : this.platformType == 1 ? this.croppedImage : this.capturedImage;
    if(!this.userModel.profilePicPath){
      if (this.userModel.gender == "Male") {
        this.userModel.profilePicPath = "assets/images/userdefaultmale.svg";
      } else if (this.userModel.gender == "Female") {
        this.userModel.profilePicPath = "assets/images/userdefaultfemale.svg";
      }
    }
    if (!this.personalInfoForm.valid) {
      return;
    } else {
      this.buttonDisabled = true;
      this.settingService.createUser(this.userModel).subscribe(data => {
        if (!data.isError) {
          this.buttonDisabled = false;
          this.toastr.success(data.payload, 'Success');
          if (this.platformType == 1) {
            sessionStorage.setItem("personalInfo", JSON.stringify(this.userModel));
            this.sharedService.setPersonalInfo(this.userModel);
            this.sharedService.setUserLogo(this.userModel.profilePicPath);
          }
        } else {
          this.buttonDisabled = false;
          this.toastr.error(data.errorMessage, 'Error');
        }
      });
    }
  }

  get f() {
    return this.personalInfoForm.controls;
  }

  async openModal(value: string, type: string) {
    const componentProps = this.getComponentProps(type);
    if (value && value !== '') {
      componentProps.value = value;
    }
    const searchItemModal = await this.modalController.create({
      component: CustomSelectDropdownComponent,
      componentProps,
      keyboardClose: false,
    });
    searchItemModal.onDidDismiss()
      .then((callback) => {
        if (callback && callback.data) {
          if (typeof callback.data !== 'string') {
            this[componentProps.listName] = callback.data;
            callback.data.forEach((obj: any) => {
              if (obj.isSelected) {
                this.personalInfoForm.controls[type].setValue(obj[componentProps.itemTextField]);
                if (componentProps.listName === 'countryList') {
                  this.userModel.countryCode = obj.shortCode ? obj.shortCode + '(' + '+' + obj.countryCode + ')' : '+' + obj.countryCode;
                } else {
                  this.userModel[componentProps.changedItemTextField] = obj[componentProps.itemTextField];
                  this.userModel[componentProps.changedItemValueField] = obj[componentProps.itemValueField];
                }
                if (componentProps.listName === 'titleList') {
                  this.userModel.gender = obj.pickListSubValue;
                } 
              }
            });
          }
        }
      });
    return await searchItemModal.present();
  }

  getComponentProps(type: string) {
    switch (type) {
      case 'title':
        return {
          title: 'Title',
          value: null,
          mainList: this.titleList,
          isMultiSelect: false,
          itemValueField: 'pickListValue',
          itemTextField: 'pickListValue',
          listName: `titleList`,
          changedItemValueField: 'title',
          changedItemTextField: 'title'
        };
      case 'mobileCode':
        return {
          title: 'Currency',
          value: null,
          mainList: this.countryList,
          isMultiSelect: false,
          itemValueField: 'countryCode',
          itemTextField: 'countryCode',
          listName: `countryList`,
          changedItemValueField: 'countryCode',
          changedItemTextField: 'countryCode'
        };
      case 'nationality':
        return {
          title: 'Nationality',
          value: null,
          mainList: this.nationalityList,
          isMultiSelect: false,
          itemValueField: 'countryId',
          itemTextField: 'nationality',
          listName: `nationalityList`,
          changedItemValueField: 'nationalityId',
          changedItemTextField: 'nationalityName'
        };
    }
  }

  resetFormControlAndBlur(form: FormGroup, formControlName: string, eleInput: IonInput) {
    form.controls[formControlName].reset();
    eleInput.getInputElement().then((ele) => ele.blur());
  }


  getUsers() {
    const departmentHeadRequestPayload = {
      "legalEntityId": this.userInfo.legalEntityId,
      "legalEntityGUID": this.userInfo.legalEntityGuId,
      "statusId": 1
    };
    this.settingService
      .getUsersList(departmentHeadRequestPayload)
      .subscribe((response) => {
        if (response?.payload?.users.length > 0) {
          this.userList = response.payload.users;
          // this.userList.unshift({ 'firstName': "All Users", 'lastName': '', 'userId': "All" })
        }
      });
  }

  getTeamsApi() {
    const requestObj = {
      "legalEntityId": this.userInfo.legalEntityId,
      "legalEntityGUID": this.userInfo.legalEntityGuId,
      "statusId": 1
    };
    this.settingService.getTeamssList(requestObj).subscribe(res => {
      if (res.errorCode == 0) {
        this.teamsdata = res.payload.teamsList;
        // this.userList.unshift({ 'firstName': "All Users", 'lastName': '', 'userId': "All" })
      }
    });
  }


  getDesignations() {
    const desigRequest = {
      "legalEntityId": this.userInfo.legalEntityId,
      "legalEntityGuid": this.userInfo.legalEntityGuId,
      "statusId": 1
    }
    this.settingService.getDesignationsList(JSON.stringify(desigRequest)).subscribe(data => {
      if (data.errorCode == 0) {
        this.designationData = data.payload.designationList;
        //this.designationData.unshift({ 'designationName': "All Designations", 'designationId': "All" })
      }
    });
  }
  getDepartment() {
    const deptRequest = {
      "legalEntityId": this.userInfo.legalEntityId,
      "legalEntityGuid": this.userInfo.legalEntityGuId,
      "statusId": 1
    };
    this.settingService.getDepartmentsist(JSON.stringify(deptRequest)).subscribe(data => {
      if (data.errorCode == 0) {
        this.departmentData = data.payload.departmentList;
        // this.departmentData.unshift({ 'departmentName': "All Departments", 'departmentId': "All" });
      }
    })
  }
  getCostCenter() {
    const costCenterRequest = {
      "legalEntityId": this.userInfo.legalEntityId,
      "legalEntityGuid": this.userInfo.legalEntityGuId,
      "statusId": 1
    }
    this.settingService.getCostCenterList(JSON.stringify(costCenterRequest)).subscribe(data => {

      if (data.errorCode == 0) {
        this.costCenterData = data.payload.costCenterList;;
        // this.costCenterData.unshift({'costCenterName':'All Cost Center','costCenterId':"All"})
      }
    })
  }
  getBands() {
    const bandRequest = {
      "legalEntityId": this.userInfo.legalEntityId,
      "legalEntityGuid": this.userInfo.legalEntityGuId,
      "statusId": 1
    };
    this.settingService.getBrandsList(JSON.stringify(bandRequest)).subscribe(data => {

      if (data.errorCode == 0) {
        this.bandData = data.payload.bandList;
        // this.bandData.unshift({ 'bandName': "All Bands", 'bandId': "All" });
      }
    })
  }
  showEmployList: boolean = false;
  @ViewChild('employeeType', { static: false }) employmentTypeAutoCompleteReference: ElementRef;
  onClickedOutsideEmploy() {
    this.showEmployList = false;
  }
  openEmployDropdown(e) {
    this.closeAll();
    if (!this.disableValue) {
      this.showEmployList = true;
      e.preventDefault();
      e.stopPropagation();
      setTimeout(() => {
        this.employmentTypeAutoCompleteReference.nativeElement.focus();
      }, 0);
    } else {
      this.showEmployList = false;
    }
  }
  onEmployEventFocus() {
    setTimeout(() => {
      this.employmentData = this.employmentList;
    }, 0);
  }
  getEmploymentTypes() {
    this.userModel.statusId = 1
    this.userModel.legalEntityId = this.userInfo.legalEntityId;
    this.userModel.legalEntityGuid = this.userInfo.legalEntityGuId;
    this.settingService
    .employmentTypeList(JSON.stringify(this.userModel)).subscribe(data => {
      if (!data.isError && data.payload.employementTypeList) {
        this.employmentList = data.payload.employementTypeList;
      }
    });
  }
  onEmploySearch(val: any) {
    this.userModel.searchText = val.target.value;
    this.settingService.employmentTypeList(JSON.stringify(this.userModel)).subscribe(data => {
      this.employmentData = data.payload.employementTypeList;
    });
  }
  selectEmploymentType(val) {
    this.showEmployment = true;
    this.selectedEmployment = val;
    this.showEmployList = false;
    this.personalInfoForm.patchValue({
      employeeType: val.employmentTypeName
    });
    this.userModel.employmentTypeId = this.selectedEmployment.employmentTypeId;
  }




  onClickedOutsideRole() {
    this.showRoleList = false;
  }


  @ViewChild('User', { static: false }) UserAutoCompleteReference: ElementRef;
  onClickedOutsideUser() {
    this.showUserList = false;
  }
  openUserDropdown(e) {
    this.closeAll();
    if (!this.disableValue) {
      e.preventDefault();
      e.stopPropagation();
      this.showUserList = true;
      setTimeout(() => {
        this.UserAutoCompleteReference.nativeElement.focus();
      }, 0);
    } else {
      this.showUserList = false;
    }
  }

  onUserEventFocus() {
    setTimeout(() => {
      this.userData = this.usersList;
    }, 0);
  }

  onUserSearch(val: any) {
    //  console.log("suman is calling you");
    this.userData = this.usersList;
    // if (this.userData?.length > 0) {
    //   console.log("suman is calling you");
    //   const searchTxt = val.target.value.toLowerCase();
    //   this.trimUsersSearch(searchTxt);
    // } else {
    // console.log("suman is calling you12345");
    this.userModel.searchText = val.target.value;
    this.settingService.getUsersList(JSON.stringify(this.userModel)).subscribe(data => {
      this.userData = data.payload.usersList;
      this.tempUserList = data.payload.usersList;
    });
    //}
  }
  trimUsersSearch(searchTxt: any) {
    let searchText = searchTxt.trim();
    if (this.userData != null && searchText != "") {
      if (searchText != "") {
        let filterdata = this.userData.filter((data) => {


          if (
            data.fullname.toLowerCase().indexOf(searchText.toLowerCase()) >= 0 ||
            data.employeeId.toLowerCase().indexOf(searchText.toLowerCase()) >= 0
          ) {
            return data;
          }
        });
        this.userData = filterdata;
      } else {
        // this.userData = this.userData;
      }
    }
  }

  selectUser(selectedUserCode) {
    this.showreportmanager = true;
    this.selectedUser = selectedUserCode
    this.showUserList = false;
    this.personalInfoForm.patchValue({
      reportingManager: this.selectedUser.firstName + ' ' + this.selectedUser.lastName
    });
    this.userModel.reportingManagerId = this.selectedUser.userId;
  }





  @ViewChild('userRole', { static: false }) roleNameAutoCompleteReference: ElementRef;
  @ViewChild('Role', { static: false }) RoleAutoCompleteReference: ElementRef;
  openRoleDropdown(e) {
    this.closeAll();
    if (!this.disableValue) {
      e.preventDefault();
      e.stopPropagation();
      this.showRoleList = true;
      setTimeout(() => {
        this.RoleAutoCompleteReference.nativeElement.focus();
      }, 0);
    } else {
      this.showRoleList = false;
    }
  }
  openRoleNameDropdown(e, i) {
    this.closeAll();
    if (!this.disableValue) {
      e.preventDefault();
      e.stopPropagation();
      this.showRoleNameList = i;
      setTimeout(() => {
        this.roleNameAutoCompleteReference.nativeElement.focus();
      }, 0);
    } else {
      this.showRoleNameList = -1;
    }
  }

  onRoleEventFocus() {
    setTimeout(() => {
      this.roleData = this.roleList;
    }, 0);
  }

  onRoleNameEventFocus() {
    setTimeout(() => {
      this.roleNameData = this.roleList;
    }, 0);
  }

  onRoleSearch(val: any) {
    let searchText = val.target.value;
    let filterselectedRoleData = this.roleList.filter(data => {
      return (data.roleDesc.toLowerCase().indexOf(searchText.toLowerCase()) >= 0)
    });
    this.roleData = filterselectedRoleData;
  }

  selectRole(selectedRoleCode) {
    this.selectedRole = selectedRoleCode;
    this.showRoleList = false;
    this.personalInfoForm.patchValue({
      userRole: this.selectedRole.roleDesc
    });
    this.userModel.roleId = this.selectedRole.roleId;
  }
  selectRoleName(selectedRoleCode, ind) {
    this.showRoleNameList = -1;
    this.selectedRoleName = selectedRoleCode;
    this.userModel.subscriptionList[ind].roleId = this.selectedRoleName.roleId;
    this.userModel.subscriptionList[ind].userRole = this.selectedRoleName.roleDesc;


  }
  onClickedOutsideTeams() {
    this.showTeamsList = false;
  }
  @ViewChild('teams', { static: false }) teamsAutoCompleteReference: ElementRef;
  openTeamsDropdown(e) {
    this.closeAll();
    if (!this.disableValue) {
      e.preventDefault();
      e.stopPropagation();
      this.showTeamsList = true
      setTimeout(() => {
        this.teamsAutoCompleteReference.nativeElement.focus();
      }, 0);
    } else {
      this.showTeamsList = false;
    }
  }

  onTeamsEventFocus() {
    setTimeout(() => {
      this.teamsdata = this.teamsList;
    }, 0);
  }

  onTeamsSearch(val: any) {
    this.TeamsSearch = val.target.value;
    this.settingService.getTeamssList(
      {
        legalEntityId: this.userInfo.parentLegalEntityId,
        legalEntityGUID: this.userInfo.parentLegalEntityGuid,
        searchText: this.TeamsSearch
      }
    ).subscribe(data => {
      this.teamsdata = data.payload.teamsList;

    });
  }

  selectTeams(selectedTeamsCode) {
    this.showteams = true;
    this.selectedTeams = selectedTeamsCode;
    this.showTeamsList = false;
    this.personalInfoForm.patchValue({
      teamName: this.selectedTeams.teamName
    });
    this.userModel.teamId = this.selectedTeams.teamId;
  }

  openCostCenterDropdown(e) {
    this.closeAll();
    if (!this.disableValue) {
      e.preventDefault();
      e.stopPropagation();
      this.showCostCenterList = true
      setTimeout(() => {
        this.costCenterAutoCompleteReference.nativeElement.focus();
      }, 0);
    } else {
      this.showCostCenterList = false;
    }
  }



  onCostCenterEventFocus() {
    setTimeout(() => {
      this.costCenterdata = this.costCenterList;
    }, 0);
  }

  onCostCenterSearch(val: any) {
    this.userModel.searchText = val.target.value;
    this.settingService.getCostCenterList(JSON.stringify(this.userModel)).subscribe(data => {
      data.payload.costCenterList.forEach(element => {
        element.costCenterCodeName = element.costCenterCode + ' - ' + element.costCenterName;
      });
      this.costCenterdata = data.payload.costCenterList;
    });
  }

  selectCostCenter(selectedCostCenterCode) {
    this.showcostcenter = true;
    this.selectedCostCenter = selectedCostCenterCode;
    this.showCostCenterList = false;
    this.personalInfoForm.patchValue({ costCenterName: this.selectedCostCenter.costCenterCodeName });
    this.userModel.costCenterId = this.selectedCostCenter.costCenterId;
  }

  onClickedOutsideCostCenter() {
    this.showCostCenterList = false;
  }

  onClickedOutsideDepartment() {
    this.showDepartmentList = false;
  }
  @ViewChild('Department', { static: false }) DepartmentAutoCompleteReference: ElementRef;
  openDepartmentDropdown(e) {
    this.closeAll();
    if (!this.disableValue) {
      e.preventDefault();
      e.stopPropagation();
      this.showDepartmentList = true;
      setTimeout(() => {
        this.DepartmentAutoCompleteReference.nativeElement.focus();
      }, 0);
    } else {
      this.showDepartmentList = false;
    }
  }

  onDepartmentEventFocus() {
    setTimeout(() => {
      this.departmentData = this.departmentList;
    }, 0);
  }

  onDepartmentSearch(val: any) {
    this.userModel.searchText = val.target.value;
    this.settingService.getDepartmentsist(JSON.stringify(this.userModel)).subscribe(data => {
      this.departmentData = data.payload.departmentList;
    });
  }

  selectDepartment(selectedDepartmentCode) {
    this.showdepartment = true;
    this.selectedDepartment = selectedDepartmentCode;
    this.showDepartmentList = false;
    this.personalInfoForm.patchValue({
      departmentName: this.selectedDepartment.departmentName
    });
    this.userModel.departmentId = this.selectedDepartment.departmentId;
  }

  @ViewChild('Location', { static: false }) LocationAutoCompleteReference: ElementRef;
  openLocationDropdown(e) {
    this.closeAll();
    if (!this.disableValue) {
      e.preventDefault();
      e.stopPropagation();
      this.showLocationList = true;
      setTimeout(() => {
        this.LocationAutoCompleteReference.nativeElement.focus();
      }, 0);
    } else {
      this.showLocationList = false;
    }
  }

  onLocationEventFocus() {
    setTimeout(() => {
      this.locationData = this.locationList;
    }, 0);
  }

  onLocationSearch(val: any) {
    this.userModel.searchText = val.target.value;
    this.settingService.getAllLocations(JSON.stringify(this.userModel)).subscribe(data => {
      data.payload.locationList.forEach(element => {

        if(element.businessAreaCode == null){
          element.locationCodeName = element.workLocationName;

        }else{
          element.locationCodeName = element.businessAreaCode  + ' - ' + element.workLocationName;
        }
       
      });
      this.locationData = data.payload.locationList;
    });
  }

  selectLocation(selectedLocationCode) {
    this.selectedLocation = selectedLocationCode;
    this.showLocationList = false;
    this.personalInfoForm.patchValue({
      workLocationName: this.selectedLocation.locationCodeName
    });
    this.userModel.locationId = this.selectedLocation.locationId;
  }

  onClickedOutsideLocation() {
    this.showLocationList = false;
  }
  @ViewChild('band', { static: false }) bandAutoCompleteReference: ElementRef;
  openBandDropdown(e) {
    this.closeAll();
    if (!this.disableValue) {
      e.preventDefault();
      e.stopPropagation();
      this.showBandList = true
      setTimeout(() => {
        this.bandAutoCompleteReference.nativeElement.focus();
      }, 0);
    } else {
      this.showBandList = false;
    }
  }

  onBandEventFocus() {
    setTimeout(() => {
      this.bandData = this.bandList;
    }, 0);
  }

  onBandSearch(val: any) {
    this.userList.searchText = val.target.value;
    this.settingService.getBrandsList(JSON.stringify(this.userModel)).subscribe(data => {
      this.bandData = data.payload.bandList;
    });
  }

  selectBand(selectedBandCode) {
    this.showband = true;
    this.selectedBand = selectedBandCode;
    this.showBandList = false;
    this.personalInfoForm.patchValue({
      bandName: this.selectedBand.bandName
    });
    this.userModel.bandId = this.selectedBand.bandId;
  }

  onClickedOutsideBand() {
    this.showBandList = false;
  }
  @ViewChild('Designation', { static: false }) DesignationAutoCompleteReference: ElementRef;
  openDesignationDropdown(e) {
    this.closeAll();
    if (!this.disableValue) {
      e.preventDefault();
      e.stopPropagation();
      this.showDesignationList = true;
      setTimeout(() => {
        this.DesignationAutoCompleteReference.nativeElement.focus();
      }, 0);
    } else {
      this.showDesignationList = false;
    }
  }

  onDesignationEventFocus() {
    setTimeout(() => {
      this.designationData = this.designationList;
    }, 0);
  }

  onDesignationSearch(val: any) {
    this.userModel.searchText = val.target.value;
    this.settingService.getDesignationsList(JSON.stringify(this.userModel)).subscribe(data => {
      this.designationData = data.payload.designationList;
    });
  }

  selectDesignation(val) {
    this.showdesignation = true;
    this.selectedDesignation = val;
    this.showDesignationList = false;
    this.personalInfoForm.patchValue({
      designationName: this.selectedDesignation.designationName,
    });
    this.userModel.designationId = this.selectedDesignation.designationId;
  }

  onClickedOutsideDesignation() {
    this.showDesignationList = false;
  }
  @ViewChild('billing', { static: false }) legalAutoCompleteReference: ElementRef;
  openLegalDropdown(e) {
    this.closeAll();
    if (!this.disableValue) {
      e.preventDefault();
      e.stopPropagation();
      this.showBillingList = true;
      setTimeout(() => {
        this.legalAutoCompleteReference.nativeElement.focus();
      }, 0);
    } else {
      this.showBillingList = false;
    }
  }

  onLegalEventFocus() {
    setTimeout(() => {
      this.billingLegalEntityData = this.billingLegalEntityList;
    }, 0);
  }

  onBillingEnititySearch(val: any) {
    let searchText = val.target.value;
    let filterData = this.billingLegalEntityList.filter(data => {
      if (data.legaEntityName.toLowerCase().indexOf(searchText.toLowerCase()) >= 0) {
        return data;
      }
    });
    this.billingLegalEntityData = filterData;
  }

  selectBillingEntity(val) {
    this.selectedBillingEntity = val;
    this.showBillingList = false;
    this.personalInfoForm.patchValue({
      legalEntityName: this.selectedBillingEntity.legaEntityName,
    });
    this.userModel.legalEntityId = this.selectedBillingEntity.legalEntityId;
    this.userModel.legalEntityId = this.selectedBillingEntity.legalEntityId;
    this.userList.legalEntityGuid = this.selectedBillingEntity.legalEntityGuid;
    this.getLocations();
    this.getCostCenter();
  }
  getLocations(id?) {
    this.userModel.statusId = 1
    this.settingService.getAllLocations(JSON.stringify(this.userModel)).subscribe(data => {
      if (!data.isError && data.payload.locationList) {
        data.payload.locationList.forEach(element => {

         
         // element.locationCodeName =  element.businessAreaCode  + ' - ' + element.workLocationName;
          if(element.businessAreaCode == null){
            element.locationCodeName = element.workLocationName;
  
          }else{
            element.locationCodeName = element.businessAreaCode  + ' - ' + element.workLocationName;
          }
        
        });
        this.locationList = data.payload.locationList;
        if (id) {
          let objectIndex = this.locationList.findIndex((e) => e.locationId == id);
          if (objectIndex != -1) {
            this.userModel.workLocationName = this.locationList[objectIndex].locationCodeName;
          }
        }
      }
    });
  }
  onClickedOutsideBillingEntity() {
    this.showBillingList = false;
  }
  removeOrganizationDetails(type) {
    switch (type) {
      case 'designation':
        this.personalInfoForm.controls.designationName.patchValue('');
        this.userModel.designationId = "";
        this.showdesignation = false;
        break;
      case 'band':
        this.personalInfoForm.controls.bandName.patchValue('');
        this.userModel.bandId = '';
        this.showband = false;
        break;
      case 'department':
        this.personalInfoForm.controls.departmentName.patchValue('');
        this.userModel.departmentId = '';
        this.showdepartment = false;
        break;
      case 'costcenter':
        this.personalInfoForm.controls.costCenterName.patchValue('');
        this.userModel.costCenterId = '';
        this.showcostcenter = false;
        break;
      case 'teams':
        this.personalInfoForm.controls.teamName.patchValue('');
        this.userModel.teamId = '';
        this.showteams = false;
        break;
      case 'reportmanager':
        this.personalInfoForm.controls.reportingManager.patchValue('');
        this.userModel.reportingManagerId = '';
        this.showreportmanager = false;
        break;
      case 'dob':
        this.dayOfBirthOfUser = null;
        this.personalInfoForm.controls.dateOfBirth.patchValue('');
        this.showdob = false;
        break;
      case 'doj':
        this.dateofJoininguser = null;
        this.personalInfoForm.controls.dateOfJoing.patchValue('');
        this.showdoj = false;
        break;
      case 'employment':
        this.personalInfoForm.controls.employeeType.patchValue('');
        this.userModel.employmentTypeId = '';
        this.showEmployment = false;
        break;
    }
  }

  dateOfJoing(formatDate) {
    this.showdoj = true;
    const joingDate = this.displaydate.convertAPIToCalendar(formatDate.target.value);
    this.personalInfoForm.patchValue({
      dateOfJoing: joingDate
    });
  }

  dateOfBirth(formatDate) {
    this.showdob = true;
    const dateOfBirth = this.displaydate.convertAPIToCalendar(formatDate.target.value);
    this.personalInfoForm.patchValue({
      dateOfBirth: dateOfBirth
    });
    let date = new Date();
    let latestDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    let birth = new Date(dateOfBirth)
    let birthyear = new Date(birth.getFullYear(), birth.getMonth(), birth.getDate());
    var time = (latestDate.getTime() - birth.getTime()) / 1000;
    var age_day = Math.abs(Math.round(time / (3600 * 24)));
    if (age_day < 6576) {
      this.isValidAge = true;
      this.isValidAge = true;
    }
    else {
      this.isValidAge = false;
    }
  }


  backTodashboard() {
    //this.router.navigateRoot(['dashboard']);
    let typeVal = JSON.parse(sessionStorage.getItem("fc365"));
    if (typeVal) {
      this.router.navigateRoot(["moreoptions"]);
    } else {
      this.router.navigateRoot(["dashboard"]);
    }
  }

}
