import { NULL_EXPR } from '@angular/compiler/src/output/output_ast';
import _ from 'lodash';



export class hierarchyInfo {
  id:any;
    hierarchyText: string;
    hierarchyValue: any;
    isManagerApprovalRequired: any;
    isFinanceApprovalRequired:any;
}

export class entityMapList {
    hierarchyTypeName: string;
    entityMapId:any;
    hierarchyTypeId: string;
    isActive:boolean=false;
    hierarchyInfo:any=[];
}



export class policyData {
   // products=[];
    recordTypeNames:any;
    recordTypes:any;
    approvalPolicyType= [];
    description=null;
    approvalPolicyName= "";
    approvalPolicyId:any;
    approvalPolicyGUID:any;
    legalEntityId=null;
    legalEntityGUID=null;
    statusId:any;
    entityMapList:any=[];
    constructor(list,id,type){
      let obj_hierachy=[{
        "id":null,
        "hierarchyText": null,
        "hierarchyValue": id,
        "isManagerApprovalRequired": false,
        "isFinanceApprovalRequired": false,
        "validationError": false
       }]
      if(type=='create'){ // create new on policy type - create/update
        let self=this;

        _.forEach(list, function (hierachy, key) {
             let obj=new entityMapList()
              obj.hierarchyTypeName= hierachy.hierarchyTypeName,
              obj.entityMapId-hierachy.entityMapId,
              obj.hierarchyTypeId= hierachy.hierarchyTypeId,
              obj.isActive=hierachy.hierarchyId==1?true:false,
              obj.hierarchyInfo=hierachy.hierarchyTypeId==1?obj_hierachy:[]
              self.entityMapList.push(obj)
         });
      }else{ // update 
       // this.products=list.products;
        this.recordTypeNames=list.recordTypeNames;
        this.recordTypes=list.recordTypes;
        this.approvalPolicyType=list.approvalPolicyType;
        this.description=list.description;
        this.approvalPolicyName=list.approvalPolicyName;
        this.approvalPolicyId=list.approvalPolicyId;
        this.approvalPolicyGUID=list.approvalPolicyGUID;
        this.legalEntityId=list.legalEntityId;
        this.legalEntityGUID=list.legalEntityGUID; 
        this.statusId=list.statusId;
        this.entityMapList=list.entityMapList;
      // _.forEach(this.entityMapList, function (hierachy, key) {
      //     if(hierachy.hierarchyInfo.length>0){
      //       hierachy.isActive=true;
      //     }
      //     if(hierachy.hierarchyTypeId==1 && hierachy.hierarchyInfo.length==0){
      //       hierachy.hierarchyInfo=obj_hierachy;
      //       hierachy.isActive=true;
      //     } 
      //})
      }
  }
}


export class legalEntityObject{
company_Id:any;
company_Guid:any;
legalEntity_Guid:any;
legalEntity_Id:any;
search_Type:any;
product_Id:any;
legal_Entity_Guid:any;
legal_Entity_Id:any;
searchText:string;
}

export class approvePolicyList {
  approvalPolicyId:any;
  approvalPolicyGUID:any;
  approvalPolicyName: any;
  legalEntityId: any;
  legalEntityGUID:any;
  approvalPolicyType:any;
  recordTypes:any;
  recordTypeNames: any;
  description: any;
  entityMapList: null;
  showOnDashboard: boolean;
  pageNumber: number;
  isDefault:boolean;
  pageSize: number;
  sortType: any;
  sortColumn: any;
  statusId: number;
  categoryType:any;
  approvalWorkflowId:any;
  constructor(list) {
    this.legalEntityGUID = list.legalEntityGUID;
    this.legalEntityId = list.legalEntityId;
    this.recordTypeNames=list.recordTypeNames;
    this.recordTypes=list.recordTypes;
    this.description=list.description;
    this.statusId = list.statusId;
    this.isDefault=list.isDefault;
    this.isDefault=list.isDefault;
    this.approvalPolicyId=list.approvalPolicyId;
    this.approvalPolicyGUID=list.approvalPolicyGUID;
    this.categoryType = list.categoryType;
    this.approvalPolicyName=list.approvalPolicyName
  }
}