export class UserList {
    companyId: number;
    companyGuid: string;
    pageNumber: number;
    pageSize: number;
    searchText: any;
    sortColumn: any;
    sortType: any;
    userId: number;
    userGuid: string;
    emailId: string;
    firstName: string;
    lastName: string;
    legalEntityId: number;
    statusId:any;
    legalEntityGuid: string;
    password: string;
    title: string;
    gender: string;
    countryCode: string;
    mobile: number;
    nationalityId: number;
    locationId: number;
    nationalityName: any;
    profilePicPath: any;
    employeeId: any;
    legalEntityName:any;
    companyEmployeeId:any;
    designationName:any;
    bandName:any;
    dateofJoining:any;
    workLocationName:any;
    departmentName:any;
    costCenterName:any;
    teamName:any;
    dateOfBirth:any;
    reportingManager:any;
    userRole:any;
    employeeType:any;
    employmentTypeId:any;
  reportingManagerId: any;
  roleId: any;
  subscriptionList: any;
  teamId: any;
  costCenterId: any;
  departmentId: any;
  bandId: any;
  designationId: any;
  employmentTypeName:any;
  reportingManagerName:any;
  dateOfJoing:any;
  
  }

  export class UserListResponse {
    companyId: number;
    countryCode: any;
    departmentName: string;
    designationName: string;
    emailId: string;
    firstName: string;
    gender: any;
    lastName: string;
    legalEntityGuid: string;
    legalEntityId: number;
    legalEntityName: string;
    mobile: number;
    nationalityId: number;
    nationalityName: string;
    roleId: number;
    statusId: number;
    title: string;
    userGuid: string;
    userId: number;
    companyGuid: string;
    pageNumber: number;
    pageSize: number;
    searchText: any;
    sortColumn: any;
    sortType: any;
    password: string;
    profilePicPath: any;
    locationId: number;
    employeeId: any;
    companyEmployeeId:any;

    bandName:any;
    dateofJoining:any;
    workLocationName:any;
    costCenterName:any;
    teamName:any;
    dateOfBirth:any;
    reportingManager:any;
    userRole:any;
    employeeType:any;
    employmentTypeId:any;
    reportingManagerId:any;
    subscriptionList:any;
    teamId:any;
    departmentId:any;
    costCenterId:any;
    bandId:any;
    employmentTypeName:any;
    reportingManagerName:any;
    designationId:any;
    dateOfJoing:any;
    constructor(list){
      this.reportingManagerId=list.reportingManagerId;
      this.employmentTypeId=list.employmentTypeId;
       this.companyId = list.companyId;
       this.countryCode = list.countryCode;
       this.departmentName = list.departmentName;
       this.designationName = list.designationName;
       this.emailId = list.emailId;
       this.firstName = list.firstName;
       this.gender = list.gender;
       this.lastName = list.lastName;
       this.legalEntityGuid = list.legalEntityGuid;
       this.legalEntityId = list.legalEntityId;
       this.legalEntityName = list.legalEntityName;
       this.mobile = list.mobile;
       this.nationalityId = list.nationalityId;
       this.nationalityName = list.nationalityName;
       this.roleId = list.roleId;
       this.statusId = list.statusId;
       this.title = list.title;
       this.userGuid = list.userGuid;
       this.userId = list.userId;
       this.employeeId = list.employeeId;
       this.profilePicPath = list.profilePicPath;
       this.employeeType=list.employeeType;
       this.userRole=list.userRole;
       this.reportingManager=list.reportingManager;
       this.dateOfBirth=list.dateOfBirth;
       this.teamName=list.teamName;
       this.costCenterName=list.costCenterName;
       this.workLocationName=list.workLocationName;
       this.dateofJoining=list.dateofJoining;
       this.bandName=list.bandName;
       this.employmentTypeName=list.employmentTypeName;
       this.reportingManagerName=list.reportingManagerName;
       this.teamName=list.teamName;
       this.dateOfJoing=list.dateOfJoing;

    }
   }
  