import { Component, OnInit, ViewChild } from '@angular/core';
import { PlatformService } from 'src/app/services/platformbase.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettingsService } from 'src/app/services/settings-services';
import { DatechangeService } from 'src/app/services/common-services/datechange.service';
import { ExchangeRate, ExchangeRateList } from 'src/app/models/Settings/exchange-rate.model';
import * as moment from 'moment';
import { Constants } from 'src/app/Constants';
// import { IonicSelectableComponent } from 'ionic-selectable';
import { AppConfig } from 'src/app/config/app.config';
import { CustomSelectDropdownComponent } from "../../custom-select-dropdown/custom-select-dropdown.component";
import { IonInput, ModalController, NavController } from "@ionic/angular";
import { CustomDatePickerComponent } from "../../custom-date-picker/custom-date-picker.component";

@Component({
    selector: 'app-add-exchange-rate',
    templateUrl: './add-exchange-rate.component.html',
    styleUrls: ['./add-exchange-rate.component.scss'],
})
export class AddExchangeRateComponent implements OnInit {
    // @ViewChild("selectCurrency") selectCurrency: IonicSelectableComponent;
    exchangeModel = new ExchangeRate();
    addExchangeRateForm: FormGroup;
    platformType: number;
    displayTitle = 'Add New';
    selectedLegalEntity: any;
    submitted: boolean = false;
    buttonDisabled: boolean = false;
    showLoader: boolean = false;
    minnDate: string;
    maxxDate: string;
    request = {};
    currencyList: any;
    loaderList = [1, 2, 3, 4];
    validationMsg: any;
    userInfo: any;
    frmDate: any;
    tDate: any;

    constructor(
        private platformService: PlatformService,
        private formBuilder: FormBuilder,
        private router: NavController,
        private displaydate: DatechangeService,
        private settingService: SettingsService,
        private modalController: ModalController,
        private activatedRoute: ActivatedRoute,
        private toastr: ToastrService
    ) {
        this.exchangeModel.currencyExchangeId = this.activatedRoute.snapshot.params['id'];
        this.exchangeModel.currencyExchangeGuid = this.activatedRoute.snapshot.params['guid'];
    }

    ngOnInit() {
    }

    ionViewWillEnter() {
        this.selectedLegalEntity = JSON.parse(sessionStorage.getItem("selectedLegalEntity"));
        this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        this.platformType = this.platformService.getPlatformType();
        this.minnDate = moment(new Date()).format(Constants.mobileCalenderFormat);
        this.maxxDate = moment(this.minnDate).add(40, 'y').format(Constants.mobileCalenderFormat);
        this.addExchangeRateForm = this.formBuilder.group({
            fromCurrency: ['', Validators.required],
            toCurrency: [''],
            value: ['', Validators.required],
            fromDate: ['', Validators.required],
            toDate: ['', Validators.required]
        });
        this.getCurrencies();
        this.validationMsg = AppConfig;
        this.exchangeModel.toCurrency = this.userInfo.baseCurrency.currencyCode;
        this.exchangeModel.toCurrencyId = this.userInfo.baseCurrency.currencyId;
        if (this.exchangeModel.currencyExchangeId && this.exchangeModel.currencyExchangeGuid) {
            this.getSingleExchange();
            this.displayTitle = 'Update';
        }
    }

    getCurrencies() {
        this.settingService.getBaseCurrency(this.request).subscribe(data => {
            this.currencyList = data.payload;
        });
    }

    getSingleExchange() {
        this.showLoader = true;
        this.settingService.getAllExchangRate(this.exchangeModel).subscribe(data => {
            if (data.payload) {
                this.showLoader = false;
                this.exchangeModel = new ExchangeRateList(data.payload);
                this.addExchangeRateForm.controls.fromCurrency.patchValue(
                    this.exchangeModel.fromCurrency
                );
                this.addExchangeRateForm.controls.fromDate.patchValue(
                    this.convertDateToUtc(this.exchangeModel.fromDate, true)
                );
                this.addExchangeRateForm.controls.toDate.patchValue(
                    this.convertDateToUtc(this.exchangeModel.toDate, false)
                );
            } else {
                this.toastr.error(data.errorMessage, "Error");
            }
        });
    }
    convertDateToUtc(date, isFromDate) {
        if (isFromDate) {
            // this.frmDate = moment(moment(date).utc(true).toISOString(false));
            this.frmDate = moment(new Date(date)).format('DD MMM YYYY');
            return this.frmDate;
        } else {
            this.tDate = moment(new Date(date)).format('DD MMM YYYY');
            return this.tDate;
        }
    }

    fromDate(formatDate) {
        this.exchangeModel.fromDate = moment(new Date(formatDate.detail.value)).format(Constants.mobileCalenderFormat);
    }

    toDate(formatDate) {
        this.exchangeModel.toDate = moment(new Date(formatDate.detail.value)).format(Constants.mobileCalenderFormat);
    }

    currencyChange(e) {
        if (e != null) {
            this.exchangeModel.fromCurrencyId = e.countryId;
            this.exchangeModel.fromCurrency = e.currencyCode;
        }
    }

    applyNationality() {
        // this.selectCurrency.confirm();
        // this.selectCurrency.close();
    }

    addOrUpdateExchangeRate() {
        this.submitted = true;
        if (!this.addExchangeRateForm.valid) {
            return;
        } else {
            this.buttonDisabled = true;
            this.exchangeModel.legalEntityId = this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId;
            this.exchangeModel.legalEntityGuid = this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId;
            this.settingService.createExchangeRate(this.exchangeModel).subscribe(data => {
                if (!data.isError) {
                    this.toastr.success(data.payload, 'Success');
                    this.buttonDisabled = false;
                    this.navigateToExchangRate();
                } else {
                    this.toastr.error(data.errorMessage, 'Error');
                    this.buttonDisabled = false;
                    this.navigateToExchangRate();

                }
            });
        }
    }

    navigateToExchangRate() {
        this.router.navigateRoot(["/settings/exchangeRate"]);
    }

    get f() {
        return this.addExchangeRateForm.controls;
    }

    async openModal(value: string, type: string) {
        const componentProps = {
            title: 'Currency',
            value: null,
            mainList: this.currencyList,
            isMultiSelect: false,
            itemValueField: 'countryId',
            itemTextField: 'currencyCode',
            listName: `currencyList`
        };
        if (value && value !== '') {
            componentProps.value = value;
        }
        const searchItemModal = await this.modalController.create({
            component: CustomSelectDropdownComponent,
            componentProps,
            keyboardClose: false,
        });
        searchItemModal.onDidDismiss()
            .then((callback) => {
                if (callback && callback.data) {
                    if (typeof callback.data !== 'string') {
                        this[componentProps.listName] = callback.data;
                        callback.data.forEach((obj: any) => {
                            if (obj.isSelected) {
                                this.addExchangeRateForm.controls[type].setValue(obj[componentProps.itemTextField]);
                                this.exchangeModel.fromCurrency = obj[componentProps.itemTextField];
                                this.exchangeModel.fromCurrencyId = obj[componentProps.itemValueField];
                            }
                        });
                    }
                }
            }
            );
        return await searchItemModal.present();
    }

    async openDateSheet(isFromDate: boolean) {
        const modal = await this.modalController.create({
            component: CustomDatePickerComponent,
            componentProps: {
                selectedDate: isFromDate ? this.frmDate : this.tDate,
                fromDate: isFromDate ? new Date('1981-01-01') : this.frmDate,
                toDate: isFromDate ? this.tDate ? this.tDate : new Date() : new Date()
            },
            cssClass: 'custom-bottom-sheet'
        });
        modal.onDidDismiss().then(result => {
            if (result.role === 'changed' && result.data) {
                if (isFromDate) {
                    this.frmDate = result.data.displayDate;
                    this.exchangeModel.fromDate = moment(result.data.displayDate).format('YYYY-MM-DD');
                } else {
                    this.tDate = result.data.displayDate;
                    this.exchangeModel.toDate = moment(result.data.displayDate).format('YYYY-MM-DD');
                }
            }
        });
        return await modal.present();
    }
    resetFormControlAndBlur(form: FormGroup, formControlName: string, eleInput: IonInput) {
        form.controls[formControlName].reset();
        eleInput.getInputElement().then((ele) => ele.blur());
    }
}
