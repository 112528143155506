import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ModalController } from "@ionic/angular";
// import { CalendarComponentOptions } from 'ion2-calendar';


@Component({
    selector: 'app-custom-date-picker',
    templateUrl: './custom-date-picker.component.html',
    styleUrls: ['./custom-date-picker.component.scss'],
})
export class CustomDatePickerComponent implements OnInit {

    @Input() selectedDate: string | Date;
    @Input() fromDate: Date | string;
    @Input() toDate: Date | string;

    @Input() checkingVariable: boolean = false;
    dateRange: { from: string; to: string; };

    type: string;

    calenderOptions: any = {
        showMonthPicker: true,
        showToggleButtons: true,
       // disableWeeks: [0, 6]
    };
    optionsRange: any = {
        pickMode: 'range'

    };

    constructor(private modalController: ModalController) {
    }

    ngOnInit() {
        

        this.fromDate = this.fromDate ? this.fromDate : new Date('1981-01-01');
        this.toDate = this.toDate ? this.toDate : new Date();
        this.selectedDate = moment(this.selectedDate).format('YYYY-MM-DD');
        const fromDateDate = parseInt(moment(this.fromDate).format('DD'));
        const fromDateMonth = parseInt(moment(this.fromDate).format('MM')) - 1;
        const fromDateYear = parseInt(moment(this.fromDate).format('YYYY'));

        const toDateDate = parseInt(moment(this.toDate).format('DD'));
        const toDateMonth = parseInt(moment(this.toDate).format('MM')) - 1;
        const toDateYear = parseInt(moment(this.toDate).format('YYYY'));

        if (this.checkingVariable) {
            this.calenderOptions.from = new Date(fromDateYear, fromDateMonth, fromDateDate);
            this.calenderOptions.to = new Date(toDateYear, toDateMonth, toDateDate);
        } else {
            this.optionsRange.from = new Date(fromDateYear, fromDateMonth, fromDateDate);
            this.optionsRange.to = new Date(toDateYear, toDateMonth, toDateDate);

        }



    }


    onChange($event) {
    }

    closeModal(role) {

        if (!this.checkingVariable) {
            const displayDate = moment(this.selectedDate).utc(true).toISOString(false);
            const formattedDate = moment(new Date(this.selectedDate)).format('DD-MM-YYYY');
            this.modalController.dismiss({
                displayDate,
                formattedDate
            }, role);
        } else {
            const fromDate = moment(this.dateRange.from).utc(true).toISOString(false);
            const toDate = moment(this.dateRange.to).utc(true).toISOString(false);
            this.modalController.dismiss({
                fromDate,
                toDate
            }, role);
        }

    }

    onSubmit() {
        this.closeModal('changed');
    }

}
