export class CreateApprovalModel{
    recordType = [];
    workFlowName:string;
    description:string
}

export class GetApprovalModel{
    approvalWorkflowId:number;
    approvalWorkflowGUID:string;
}

export class ApprovalsList{
    approvalWorkflowDetails:Array<ApprovalWorkflowDetails>;
    approvalWorkflows:Array<ApprovalWorkflows>;
    workflowNotificationSettings:Array<WorkflowNotificationSettings>;

}

export class EnableDisableModel {
    approvalWorkflowId:number;
    approvalWorkflowGUID:string;
    type:any;
}
export class ApprovalWorkflowDetails {
    approvalWorkflowDetailId: number;
    approvalWorkflowDetailGUID: string
    approvalWorkflowId: number;
    approvalWorkflowGUID:string;
    workflowDesc: number;
    level: number;
    approverType:number;
    approverId: string;
    legalEntityId: any;
    legalEntityGUID:string
    constructor(list:any){
        if(list){
            this.approvalWorkflowId = list.approvalWorkflowId;
            this.approvalWorkflowGUID = list.approvalWorkflowDetailGUID;
            this.approvalWorkflowDetailId = list.approvalWorkflowDetailId;
            this.approvalWorkflowDetailGUID = list.approvalWorkflowDetailGUID;
            this.approverId = list.approverId;
            this.approverType = list.approverType;
            this.legalEntityId = list.legalEntityId;
            this.legalEntityGUID = list.legalEntityGUID;

        }
    }
}

export class ApprovalWorkflows {
    approvalWorkflowId: number;
			approvalWorkflowGuid:string;
			workflowName: string;
			workflowDesc:  string;
			statusId:  number;
			legalEntityId:  number;
			approvalFlowType: any;
            type: any;
            constructor(list:any){
                if(list){
                    this.approvalWorkflowGuid = list.approvalWorkflowGuid;
                    this.workflowName = list.workflowName;
                    this.workflowDesc = list.workflowDesc;
                    this.statusId = list.statusId;
                    this.legalEntityId = list.legalEntityId;
                    this.approvalFlowType = list.approvalFlowType;
                    this.type = list.type;
                }
            }
}

export class WorkflowNotificationSettings {
    workflowSettingId: number;
			workflowSettingGUID: string;
			notifyApproverEmail: number;
			notifyApproverSms: number;
			notifyApproverWhatsapp: number;
			allowApproverEmail: number;
			allowApproverSms: number;
			allowApproverWhatsapp:number;
			sendReminderEmail: number;
			sendReminderSms: number;
			sendReminderWhatsapp: number;
			approvalTat: any;
			reminderIntervalTime: any;
			delegatingSupervisorApprove:number;
			sendReminderStartTime: number;
			sendReminderEndTime: number;
            constructor(list:any){
                if(list){
                    this.workflowSettingGUID = list.workflowSettingGUID;
			this.notifyApproverEmail = list.notifyApproverEmail;
			this.notifyApproverSms = list.notifyApproverSms;
			this.notifyApproverWhatsapp = list.number;
			this.allowApproverEmail = list.number;
			this.allowApproverSms = list.number;
			this.allowApproverWhatsapp = list.number;
			this.sendReminderEmail = list.number;
			this.sendReminderSms = list.number;
			this.sendReminderWhatsapp = list.number;
			this.approvalTat = list.approvalTat;
			this.reminderIntervalTime = list.reminderIntervalTime;
			this.delegatingSupervisorApprove = list.delegatingSupervisorApprove;
			this.sendReminderStartTime = list.sendReminderStartTime;
			this.sendReminderEndTime = list.sendReminderEndTime;
            }
            }
}