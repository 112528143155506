import { Injectable } from '@angular/core';
// import {
//   Plugins, CameraResultType, Capacitor, FilesystemDirectory,
//   CameraPhoto, CameraSource
// } from '@capacitor/core';

// const { Camera, Filesystem, Storage } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class PhotoService {
  public photos: Photo[] = [];
  public async addNewToGallery() {

    // Take a photo
    // const capturedPhoto = await Camera.getPhoto({
      // resultType: CameraResultType.Uri,
      // source: CameraSource.Camera,
    //   quality: 100,
    //   height: 1280,
    //   width: 1280
    // });
    // this.photos.unshift({
    //   filepath: "soon...",
    //   webviewPath: capturedPhoto.webPath
    // });
  }
  constructor() { }
}
interface Photo {
  filepath: string;
  webviewPath: string;
  base64?: string;
}
