import { Injectable } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private _snackBar: MatSnackBar) { }

  error() {
    return this._snackBar.open("No connection", null, {
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
      duration: 3000,
      panelClass: ['snackbar-error']
    });
  }

  success() {
    return this._snackBar.open("Back online", null, {
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
      duration: 3000,
      panelClass: ['snackbar-success']
    });
  }
}
