import { Component, OnInit } from '@angular/core';
import { PlatformService } from 'src/app/services/platformbase.service';
import { MatTableDataSource } from '@angular/material/table';
import { AccountConfig } from 'src/app/models/Settings/accountconfig.model';
import { AccountConfigService } from 'src/app/services/account-config.service';
import { ToastrService } from 'ngx-toastr';
import { SettingsService } from 'src/app/services/settings-services';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-travel-request',
  templateUrl: './travel-request.component.html',
  styleUrls: ['./travel-request.component.scss'],
})
export class TravelRequestComponent implements OnInit {

  platformType: number;
  loaderList = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  showLoader: boolean = true;
  userInfo: any;
  isTravelRequest: boolean = false
  travelRequestData: any;
  buttonDisabled: boolean = false;
  selectedLegalEntity: any;

  constructor(private platformService: PlatformService,
    private accountConfigService: AccountConfigService,
    private travelRequestService: SettingsService,
    private toastr: ToastrService,
    private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.platformType = this.platformService.getPlatformType();
    this.selectedLegalEntity = JSON.parse(sessionStorage.getItem('selectedLegalEntity'));
  }

  ionViewWillEnter() {
    this.platformType = this.platformService.getPlatformType();
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.getAccountConfiguration();
    this.getTravelRequestData();
  }

  getAccountConfiguration() {
    var modl = new AccountConfig();
    modl.legalEntityId = this.selectedLegalEntity?.legalEntityId || this.userInfo.legalEntityId;
    modl.legalEntityGUID = this.selectedLegalEntity?.legalEntityGuId || this.userInfo.legalEntityGuId;
    this.accountConfigService.getAccountConfig(modl).subscribe(res => {
      if (!res.isError && res.payload) {
        this.isTravelRequest = res.payload?.isTravelEnabled;
      }
    })
  }

  getTravelRequestData() {
    var modl = new AccountConfig();
    modl.legalEntityId = this.selectedLegalEntity?.legalEntityId || this.userInfo.legalEntityId;
    modl.legalEntityGUID = this.selectedLegalEntity?.legalEntityGuId || this.userInfo.legalEntityGuId;
    this.travelRequestService.getTravelRequestData(modl).subscribe(res => {
      if (!res.isError && res.payload) {
        this.showLoader = false;
        this.travelRequestData = res.payload;

      }
    })
  }

  isRequestToggle(value) {
    let selectedEntity = JSON.parse(sessionStorage.getItem('selectedLegalEntity'));
    if (value.detail.checked) {
      var modl = new AccountConfig();
      modl.legalEntityId = selectedEntity.legalEntityId;
      modl.legalEntityGUID = selectedEntity.legalEntityGuId;
      this.travelRequestService.travelRequestEnable(modl).subscribe(res => {
        if (!res.isError && res.payload) {
          // this.toastr.success(res.payload, "Success");
          this.tabsccess()
        } else {
          this.toastr.error('Error', "Error");
        }
      })
    } else {
      var modl = new AccountConfig();
      modl.legalEntityId = selectedEntity.legalEntityId;
      modl.legalEntityGUID = selectedEntity.legalEntityGuId;
      this.travelRequestService.travelRequestDisable(modl).subscribe(res => {
        if (!res.isError && res.payload) {
          // this.toastr.success(res.payload, "Success");
          this.tabsccess()
        } else {
          this.toastr.error('Error', "Error");
        }
      })
    }
  }

  tabsccess() {
    let modl = new AccountConfig();
    modl.legalEntityId = this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId;
    modl.legalEntityGUID = this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId;
    this.travelRequestService.settingsTabsAccess(modl).subscribe(res => {
      if (!res.isError) {
        this.userInfo.isLocalConveyanceEnabled = res.payload.isLocalConveyanceEnabled
        this.userInfo.isPettyCashEnabled = res.payload.isPettyCashEnabled
        this.userInfo.isTravelEnabled = res.payload.isTravelEnabled
        sessionStorage.setItem("userInfo", JSON.stringify(this.userInfo));
        // localStorage.setItem("userInfo", JSON.stringify(this.userInfo));
        this.authenticationService.setisLocalConveyanceEnabled(res.payload.isLocalConveyanceEnabled);
        this.authenticationService.setisPettyCashEnabled(res.payload.isPettyCashEnabled);
        this.authenticationService.setisTravelEnabled(res.payload.isTravelEnabled);

        // window.location.reload();


      }
    });
  }

  toggleChange(event, value) {
    console.log("click event :",event,value);
    switch (value) {
      case "flight":
        this.travelRequestData.isFlightEnabled = event.detail.checked;
        break;
      case "hotel":
        this.travelRequestData.isHotelEnabled = event.detail.checked;
        break;
      case "cab":
        this.travelRequestData.isCabEnabled = event.detail.checked;
        break;
      case "bus":
        this.travelRequestData.isBusEnabled = event.detail.checked;
        break;
      case "rail":
        this.travelRequestData.isRailEnabled = event.detail.checked;
        break;
      case "visa":
        this.travelRequestData.isVisaEnabled = event.detail.checked;
        break;
      case "forex":
        this.travelRequestData.isForexEnabled = event.detail.checked;
        break;
      case "insurance":
        this.travelRequestData.isInsuranceEnabled = event.detail.checked;
        break;
    }

  }

  updateData() {
    this.buttonDisabled = true;
    this.travelRequestData.legalEntityId = this.selectedLegalEntity?.legalEntityId || this.userInfo.legalEntityId;
    this.travelRequestData.legalEntityGUID = this.selectedLegalEntity?.legalEntityGuId || this.userInfo.legalEntityGuId;
    console.log("sdsdsd",this.travelRequestData);
    this.travelRequestService.travelRequestSave(this.travelRequestData).subscribe(data => {
      if (data && !data.isError) {
        this.toastr.success(data.payload, "success");
      } else {
        this.toastr.error(data.payload, "Error");
      }
      this.buttonDisabled = false;
    });
  }
}
