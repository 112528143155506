import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslateconfigService {

  constructor(private translate: TranslateService) { }

  getDefaultLanguage() {
    const language = this.translate.getBrowserLang();
    this.translate.setDefaultLang(language);
    return language;
  }

  setLanguage(setLang: string) {
    sessionStorage.setItem('current-lang', setLang);
    this.translate.use(setLang.toLowerCase());
  }
}
