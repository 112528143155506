import {Component, ElementRef, NgZone, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, FormArray, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {ExpensesService} from 'src/app/services/expenses.service';
import {PlatformService} from 'src/app/services/platformbase.service';
import * as moment from 'moment';
import {ModalController, NavController} from '@ionic/angular';
import {CommonService} from 'src/app/services/common-services/common.service';

declare var google: any;
import _ from 'lodash';
import {CategoryAndMerchantModel, CategoryList, UserRcrdModel} from 'src/app/models/Common/common.model';
import {LcMainModel, LcItemModel, LcItemBindModel} from 'src/app/models/Lc/LocalConveyance.model';
import {
    bulkOCRAttachment,
    ExpenseAttachments,
    ExpenseAttachmentsModel,
    ExpenseModel,
    FileURLModel,
    GetExpenseModels,
    RuleCheckModel
} from 'src/app/models/Expenses/expenses.model';
import {DatechangeService} from "src/app/services/common-services/datechange.service";
import {CustomDatePickerComponent} from '../../custom-date-picker/custom-date-picker.component';
import {CustomSelectDropdownComponent} from '../../custom-select-dropdown/custom-select-dropdown.component';
import {SelectLocationComponent} from '../../select-location/select-location.component';

@Component({
    selector: 'app-new-lc',
    templateUrl: './new-lc.component.html',
    styleUrls: ['./new-lc.component.scss'],
})
export class NewLcComponent implements OnInit {

    platformType: number;
    userInfo: any;
    showLoadr = true;
    submitLC: boolean = false;
    totalLCForm: FormGroup;
    totalLcModel = new LcMainModel();
    dummyList = [1, 2, 3, 4];
    maxDate: any;
    currentFrom = -1;
    currentTo = -1;
    showFromMapField = false;
    fromCitiesList = [];
    @ViewChild('togoglemapCity', {static: false}) googleSearchAutoComplete: ElementRef;
    showcitySearchListSpinner: boolean = false;
    sessionTokenEnable = true;
    sessionToken: any;
    requestType: any;
    private geocoder: any;
    showToMapField = false;
    toCitiesList = [];
    fromOrto: any;
    vehiclesList = [];
    categorytempList: any;
    fileName: any;
    attachmtsModel = new ExpenseAttachmentsModel();
    hasCitySearch = true;
    fromPathInfo: any;
    getExpenseModels = new GetExpenseModels();
    currentItemIndx = -1;
    isCSVUpload = false;
    policyForUserList: any;
    violationList = [];
    maxDateStr: any = moment(new Date()).format('YYYY-MM-DD');
    minDateStr: any = moment(new Date().getTime()).subtract(2000, 'years').format('YYYY-MM-DD');

    constructor(private platformService: PlatformService,
                private expenseService: ExpensesService,
                private toastr: ToastrService,
                private translate: TranslateService, private commonService: CommonService,
                public formBuilder: FormBuilder, private ngZone: NgZone,
                public navCtlr: NavController,
                private datechangeService: DatechangeService,
                public modalController: ModalController) {
        this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    }

    ngOnInit() {
        this.LcFormCreation(0);
    }

    ionViewWillEnter() {
        this.platformType = this.platformService.getPlatformType();
        let date = new Date();
        this.maxDate = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate()
        );
        this.setNavParams();
        this.policyForUserList = {};
        this.LcFormCreation(1);

        if (this.fromPathInfo && this.fromPathInfo?.expenseId && this.fromPathInfo?.expenseGUID) {
            this.showLoadr = true;
            this.getExpenseSumary();
        } else if (this.fromPathInfo.selectedDupArray) {
            this.bindLocalConveyanceList(this.fromPathInfo.selectedDupArray);
            this.showLoadr = false;
        } else {
            this.showLoadr = false;
        }

        this.dataFromApis();
        this.commonService.googlescriptAdd();
    }

    setNavParams() {
        let val = JSON.parse(sessionStorage.getItem("toPathInfo"));
        if (val && val["url"] && (val["url"] == '/localconveyance' || val["url"] == 'dashboard')) {
            this.fromPathInfo = val;
        }

        if (this.fromPathInfo.selectedDupArray) {
            //  this.totalLcModel.localConveyanceList = this.fromPathInfo.selectedDupArray;
            this.bindLocalConveyanceList(this.fromPathInfo.selectedDupArray);
        }
    }

    ngOnDestory() {
        this.removeSucbr();
    }

    ionViewWillLeave() {
        this.removeSucbr();
    }

    removeSucbr() {
        sessionStorage.setItem('toPathInfo', null);
    }


    LcFormCreation(count: any) {
        this.totalLCForm = this.formBuilder.group({
            localConveyanceName: ["", [Validators.required]],
            LcItems: this.formBuilder.array([]),
        });
        this.totalLcModel.localConveyanceList = [];
        while (count > 0) {
            this.addLcItem(null);
            count = count - 1;
        }
    }


    get LcItemsForms() {
        return this.totalLCForm.get("LcItems") as FormArray;
    }

    addLcItem(LcModel: any) {
        var model;
        if (LcModel) {
            model = LcModel;
        } else {
            model = new LcItemModel();
        }

        model.mileageUnit = 'km';
        model.categoryType = 2;
        model.originalCurrencyId = this.userInfo?.baseCurrency?.currencyId;
        if( model?.unformattedOriginalAmount) {
            model.originalAmount = model?.unformattedOriginalAmount; 
        }
        this.totalLcModel.localConveyanceList.push(model);
        this.LcItemsForms.push(this.addLcFormGroup(model));
        this.violationList.push({'isSelected': false});
    }

    addLcFormGroup(LcModel: LcItemModel): FormGroup {
        return this.formBuilder.group({
            expensedDate: [this.convertDateToUtc(LcModel?.expensedDate), [Validators.required]],
            fromLocation: [LcModel?.fromLocation, [Validators.required]],
            fromCity: [LcModel?.fromCity],
            fromCountry: [LcModel?.fromCountry],
            toLocation: [LcModel?.toLocation, [Validators.required]],
            toCity: [LcModel?.toCity],
            toCountry: [LcModel?.toCountry],
            fromLongitude: [LcModel?.fromLongitude],
            fromLatitude: [LcModel?.fromLatitude],
            toLongitude: [LcModel?.toLongitude],
            toLatitude: [LcModel?.toLatitude],
            fromMap: [LcModel?.fromMap],
            attachmentURL: [LcModel?.attachmentURL],
            reason: [LcModel?.reason],
            categoryName: [LcModel?.categoryName, [Validators.required]],
            categoryId: [LcModel?.categoryId],
            originalAmount: [LcModel?.originalAmount],
            distance: [LcModel?.distance, [Validators.required]],
            mileageUnit: [LcModel?.mileageUnit],
            mileageRate: [LcModel?.mileageRate, [Validators.maxLength(8), Validators.min(1)]],
            inTime: [LcModel?.inTime, [Validators.required]],
            outTime: [LcModel?.outTime, [Validators.required]],
            upload: [false]
        });
    }

    removeLcItem(index) {
        this.totalLcModel.localConveyanceList.splice(index, 1);
        this.LcItemsForms.removeAt(index);
    }

    dismissModal() {
        if (this.fromPathInfo && this.fromPathInfo["url"]) {
            if (this.fromPathInfo["url"] == '/localconveyance') {
                var status = -1;
                var claimId;
                if (this.fromPathInfo["status"]) {
                    status = this.fromPathInfo["status"];
                }
                if (this.fromPathInfo["claimId"]) {
                    claimId = this.fromPathInfo["claimId"];
                }
                let navInfo = { 
                    expenseId: this.fromPathInfo?.expenseId ? this.fromPathInfo?.expenseId : null,
                    expenseGUID: this.fromPathInfo?.expenseGUID ? this.fromPathInfo?.expenseGUID : null,
                    status: status,
                    claimId:claimId
                };
                sessionStorage.setItem('fromPathInfo', JSON.stringify(navInfo));
            }
            this.navCtlr.navigateRoot([this.fromPathInfo["url"]]);
        }
    }

    dateChanged(e, index: any, formItem: any) {
        var date: any;
        if (this.platformType == 1) {
            date = this.datechangeService.convertCalendarToAPI(e.target.value);
        } else {
            // date = moment(new Date(e.detail.value)).format(Constants.mobileDateFormat);
        }
        this.totalLcModel.localConveyanceList[index].expensedDate = date;
        formItem.controls.expensedDate.patchValue(this.convertDateToUtc(this.totalLcModel.localConveyanceList[index].expensedDate));
    }

    convertDateToUtc(date) {
        let selectedDate = moment(moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD')).utc(true).toISOString(false);
        return selectedDate;
    }

    changeCatgName(e: any, index: any, formItem, item?) {
        formItem.controls.categoryId.patchValue(item?.categoryId);
        this.totalLcModel.localConveyanceList[index].categoryName = item?.categoryName;
        this.totalLcModel.localConveyanceList[index].categoryId = item?.categoryId;
        this.totalLcModel.localConveyanceList[index].mileageRate = item?.mileageRate;
        this.automateAmount(index, item?.categoryId);
    }

    mapCategoryToIndx(catName: any, index: any) {
        let matchIndx = this.vehiclesList.findIndex(ele => {
            if (ele.categoryName == catName) {
                return ele;
            }
        });

        if (matchIndx != -1) {
            this.totalLcModel.localConveyanceList[index].categoryName = this.vehiclesList[matchIndx]?.categoryName;
            this.totalLcModel.localConveyanceList[index].categoryId = this.vehiclesList[matchIndx]?.categoryId;
            this.totalLcModel.localConveyanceList[index].mileageRate = this.vehiclesList[matchIndx]?.mileageRate;
            this.automateAmount(index, this.vehiclesList[matchIndx]?.categoryId);
        } else {
            this.totalLcModel.localConveyanceList[index].categoryName = '';
        }
    }

    onCategorySearch(val) {
        let searchText = val.currentTarget.value.trim();
        if (this.categorytempList != null) {
            if (searchText != "") {
                let filterdata = this.categorytempList.filter((data) => {
                    if (
                        data.categoryName.toLowerCase().indexOf(searchText.toLowerCase()) >=
                        0
                    ) {
                        return data;
                    }
                });
                this.vehiclesList = filterdata;
            } else {
                this.vehiclesList = this.categorytempList;
            }
        }
    }

    automateAmount(i: any, catId?) {
        if (this.totalLcModel.localConveyanceList[i].distance && this.totalLcModel.localConveyanceList[i].mileageRate) {
            this.totalLcModel.localConveyanceList[i].originalAmount = ((this.totalLcModel.localConveyanceList[i].distance) * (this.totalLcModel.localConveyanceList[i].mileageRate)).toFixed();
        }
        this.processRuleAPi(i);
    }

    addBulkCSV(event) {
        this.handleInputChange(event, null, true);
    }

    csvName: any;
item :any
    handleInputChange(event, item: any, isCSV?) {
        this.item = item;
        if (!event.target) {
            item.controls.upload.value = false;
            return;
        }
        if (!event.target.files) {
            item.controls.upload.value = false;
            return;
        }

        var file = event.target.files;
        let filesCount = event.target.files.length;
        for (let i = 0; i < filesCount; i++) {
            var reader = new FileReader();
            var imgfile = event.dataTransfer ? event.dataTransfer.files[i] : file[i];
            if (isCSV) {
                this.csvName = imgfile.name;
            }
            this.fileName = imgfile.name;
            reader.onload = this._handleReaderLoaded.bind(this);
            reader.readAsDataURL(imgfile);
        }
    }

    _handleReaderLoaded(e, item?) {
        var reader = e.target;
        let imgContent = reader.result;
        this.uploadImage(imgContent, this.fileName, this.item);
    }

    async openCityModal(i: any, isFrom: any) {
        const searchItemModal = await this.modalController.create({
            component: SelectLocationComponent,
            componentProps: {},
            keyboardClose: false,
        });
        searchItemModal.onDidDismiss()
            .then((callback) => {
                if (callback && callback.data) {
                    if (isFrom) {
                        this.fromOrto = 'from';
                    } else {
                        this.fromOrto = 'to';
                    }
                    this.geocodeAddress(callback.data, i);
                }
            });
        await searchItemModal.present();
    }

    async openMobileListModal(e: any, index: any, formItem: any) {
        const componentProps = {
            title: 'Mode',
            value: null,
            mainList: this.vehiclesList,
            isMultiSelect: false,
            itemValueField: 'categoryId',
            itemTextField: 'categoryName',
            listName: `categoryList`,
            setValueKey: 'categoryIds'
        };
        const searchItemModal = await this.modalController.create({
            component: CustomSelectDropdownComponent,
            componentProps,
            keyboardClose: false,
        });
        searchItemModal.onDidDismiss()
            .then((callback) => {
                if (callback && callback.data) {
                    if (typeof callback.data !== 'string') {
                        this[componentProps.listName] = callback.data;
                        callback.data.forEach((obj: any) => {
                            if (obj.isSelected) {
                                this.changeCatgName(e, index, formItem, obj);
                            }
                        });
                    }
                }
            });
        return await searchItemModal.present();
    }

    numberValidation(event: any, isTime?) {
        const pattern = /[0-9.,]/;
        let inputChar = String.fromCharCode(event.charCode);

        if (!pattern.test(inputChar)) {
            // invalid character, prevent input
            event.preventDefault();
        }
    }

    /* api start */
    expenseModel = new ExpenseModel();

    bindLocalConveyanceList(list: any) {
        this.LcFormCreation(0);
        var newListArry = [];
        newListArry = list.map((list) => new LcItemBindModel(list));
        newListArry.forEach(item => {
            this.addLcItem(item);
        });
    }

    UploadBillReceit(event, index: any, item: any) {
        item.controls.upload.value = true;
        this.currentItemIndx = index;
        this.handleInputChange(event, item);
    }

    uploadImage(img, name, item) {
        this.attachmtsModel.content = img;
        this.attachmtsModel.fileName = name;
        this.expenseModel.expenseAttachments.push(this.attachmtsModel);
        let arr = this.expenseModel.expenseAttachments;
        this.expenseModel.expenseAttachments = arr.map(item => new ExpenseAttachments(item));
        if (this.currentItemIndx != -1) {
            this.getOcrData(item);
        } else {
            this.getBulkFormOCR(item);
        }
    }

    getBulkFormOCR(item?) {
        let ocrAttch = new bulkOCRAttachment();
        let res = this.expenseModel.expenseAttachments[this.expenseModel.expenseAttachments.length - 1];
        ocrAttch.base64Image = res.content;
        ocrAttch.fileName = res.fileName;
        ocrAttch.uploadType = 'extract-csv-for-lc';
        this.expenseService.ocrDataPulling(ocrAttch).subscribe(res => {
            if (res?.payload?.bulkUploadLocalConveyanceDataList) {
                this.bindLocalConveyanceList(res?.payload?.bulkUploadLocalConveyanceDataList);
                this.totalLcModel.localConveyanceList.forEach((lcModel, index) => {
                    this.totalLcModel.localConveyanceList[index].originalCurrencyId = this.userInfo?.baseCurrency?.currencyId;
                    this.mapCategoryToIndx(lcModel.categoryName, index);
                    if (index == 0) {
                        this.fromOrto = 'from';
                        this.currentItemIndx = 0;
                        this.isCSVUpload = true;
                        this.geocodeAddress(lcModel.fromLocation, this.currentItemIndx);
                    }
                });
            } else {
                if (res.errorCode != 0) {
                    this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
                } else {
                    this.toastr.error('Error', this.translate.instant('alerts.error'));
                }
                this.LcFormCreation(1);
            }

        });
    }

    createLCAPi(action: any) {
        this.submitLC = true;
        if (!this.totalLCForm.valid) {
            return;
            /*var isInvalid = false;
            if(this.totalLCForm.controls.LcItems.status == "INVALID") {
                var i = 0;
                for(let item of (this.totalLCForm.controls.LcItems as FormArray).controls) {
                    if(item.status == "INVALID") {
                       isInvalid = true;
                        if((item as FormGroup).controls.expensedDate.status == "INVALID") {
                            if((item as FormGroup).controls.expensedDate.errors['matDatepickerParse']) {
                                // && (item as FormGroup).controls.date.errors['matDatepickerParse'].text == ''
                                isInvalid = false;
                            }
                        }
                    }
                    if(isInvalid == true) {
                        break;
                    }
                }
            }
            if(isInvalid == true){
                return;
            }  */
        }

        if (this.fromPathInfo) { 
            this.totalLcModel.expenseId = this.fromPathInfo?.expenseId;
            this.totalLcModel.status = this.fromPathInfo?.status;
            this.totalLcModel.statusDescription = this.fromPathInfo?.statusDescription;
            this.totalLcModel.claimId = this.fromPathInfo?.claimId;
        }
        this.totalLcModel.legalEntityId = this.userInfo.legalEntityId;
        this.totalLcModel.legalEntityGUID = this.userInfo.legalEntityGuId;
        this.totalLcModel.userId = this.userInfo.userId;
        this.totalLcModel.userGUID = this.userInfo.userGuId;
        this.totalLcModel.status = 1;
        this.totalLcModel.action = action; //-- 1:save, 2:submit

        this.expenseService
            .createLocalConvynace(this.totalLcModel)
            .subscribe((res) => {
                if (res.message) {
                    this.toastr.error(res.message, this.translate.instant('alerts.error'));
                    return;
                }
                this.totalLcModel.action = -1;
                if (!res.isError && res.errorCode == 0) {
                    var alert = this.translate.instant('alerts.createLC');
                    if(action == 2) {
                        alert = alert + this.translate.instant('alerts.submitSuccess');
                    } else {
                        if(this.fromPathInfo && this.fromPathInfo?.expenseId) {
                            alert = alert + this.translate.instant('alerts.updateSuccess');
                        } else {
                            alert = alert + this.translate.instant('alerts.createsuccess');
                        }
                    }
                    this.toastr.success(alert, this.translate.instant('alerts.succes'));
                    this.dismissModal();
                } else if (res.isError) {
                    this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
                }
                this.submitLC = false;
            });

    }

    getExpenseSumary() {
        this.getExpenseModels.expenseId = this.fromPathInfo.expenseId;
        this.getExpenseModels.expenseGUID = this.fromPathInfo.expenseGUID;
        this.getExpenseModels.userId = this.userInfo.userId;
        this.getExpenseModels.userGUID = this.userInfo.userGuId;
        this.getExpenseModels.legalEntityId = this.userInfo.legalEntityId;
        this.getExpenseModels.legalEntityGUID = this.userInfo.legalEntityGuId;
        this.expenseService
            .expenseSummary(this.getExpenseModels)
            .subscribe((res) => {
                this.showLoadr = false;
                if (!res.isError && res.errorCode == 0 && res?.payload &&
                    res?.payload?.localConveyanceList) {
                    this.totalLcModel.localConveyanceName = res?.payload?.description;
                    this.bindLocalConveyanceList(res?.payload?.localConveyanceList);
                } else {
                    if (res.errorCode != 0) {
                        this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
                    } else {
                        this.toastr.error('Api Error', this.translate.instant('alerts.error'));
                    }
                }
            });
    }

    getOcrData(item?) {
        var fileModel = new FileURLModel();
        let resp = this.expenseModel.expenseAttachments[this.expenseModel.expenseAttachments.length - 1];
        fileModel.fileName = resp.fileName;
        fileModel.base64Image = resp.content;
        fileModel.fileUploadFor = "EXPENSE-ATTACHMMENTS";

        this.expenseService.ocrDataPulling(fileModel).subscribe(res => {
            if (!res.isError) {
                item.controls.upload.value = false;
                let attachedFile = this.expenseModel.expenseAttachments[this.expenseModel.expenseAttachments.length - 1];
                attachedFile.url = res?.payload?.uploadUrl;
                this.totalLcModel.localConveyanceList[this.currentItemIndx].attachmentURL = res?.payload?.uploadUrl;
                delete attachedFile.content;
            } else {
                item.controls.upload.value = false;
                this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
            }
            this.currentItemIndx = -1;
        });
    }

    dataFromApis() {
        this.getmastCategorylist();
        this.policyForUserApi();
    }

    policyForUserApi() {
        let polModel = new UserRcrdModel();
        polModel.userId = this.userInfo.userId;
        polModel.userGUID = this.userInfo.userGuId;
        polModel.recordType = 3;
        this.commonService.getpolicyList(polModel).subscribe(res => {
            if (res && res?.payload) {
                this.policyForUserList = res.payload;

                this.policyForUserList?.policyMileageRuleList?.forEach(ele => {
                    ele.currentLimit = 0;
                });
            } else {
                this.toastr.error(res.errorMessage, 'Error');
            }
        });
    }

    getmastCategorylist() {
        var catgryModel = new CategoryAndMerchantModel();
        catgryModel.recordType = 3;
        catgryModel.legalEntityId = this.userInfo.legalEntityId;
        catgryModel.legalEntityGUID = this.userInfo.legalEntityGuId;

        this.commonService
            .getCatgryMasterlist(catgryModel)
            .subscribe((res) => {
                if (!res.isError && res.errorCode == 0) {
                    let listarray = res.payload;
                    this.vehiclesList = listarray.mileageCategoryList.map((list) => new CategoryList(list));
                    this.categorytempList = listarray.mileageCategoryList.map(
                        (list) => new CategoryList(list));
                } else {
                    this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
                }
            });
    }

    /*api end*/

    /*Google maps start*/
    selectFromMapM(e: any, isFrom: boolean, index: any) {
        this.currentFrom = -1;
        this.currentTo = -1;

        if (isFrom) {
            if (this.showFromMapField && isFrom) {
                this.showFromMapField = false;
                return;
            }
            this.fromOrto = "from";
            this.currentFrom = index;
            this.showToMapField = false;
            this.showFromMapField = true;
            // this.fromCitiesList = [];
        } else {
            if (this.showToMapField && !isFrom) {
                this.showToMapField = false;
                return;
            }
            this.fromOrto = "to";
            this.currentTo = index;
            this.showFromMapField = false;
            this.showToMapField = true;
            this.toCitiesList = [];
        }
    }

    selectFromMap(e, isFrom: boolean, index: any) {
        e.preventDefault();
        e.stopPropagation();
        this.currentFrom = -1;
        this.currentTo = -1;
        if (isFrom) {
            if (this.showFromMapField && isFrom) {
                this.showFromMapField = false;
                return;
            }
            this.fromOrto = "from";
            this.currentFrom = index;
            this.showToMapField = false;
            this.showFromMapField = true;
            this.fromCitiesList = [];
        } else {
            if (this.showToMapField && !isFrom) {
                this.showToMapField = false;
                return;
            }
            this.fromOrto = "to";
            this.currentTo = index;
            this.showFromMapField = false;
            this.showToMapField = true;
            this.toCitiesList = [];
        }

        setTimeout(() => {
            this.googleSearchAutoComplete.nativeElement.focus();
        }, 100);
    }

    onFromFocus(e, isFrom: boolean) {
        e.preventDefault();
        e.stopPropagation();
        if (isFrom) {
            this.fromCitiesList = [];
        } else {
            this.toCitiesList = [];
        }
    }

    googleAutoCompleteSearch(val) {
        var searchText = searchText = val.currentTarget.value.trim();
        let domesticpredictions = [];

        if (searchText.length > 2) {
            this.showcitySearchListSpinner = true;
            const displaySuggestions = (predictions, status) => {
                // if (status == google.maps.places.PlacesServiceStatus.OK) {
                //     this.showcitySearchListSpinner = false;

                //     predictions.forEach((prediction) => {
                //         domesticpredictions.push(prediction);
                //     });
                //     var dcountry = this;
                //     if (this.fromOrto == 'from') {
                //         dcountry.fromCitiesList = domesticpredictions;
                //     } else {
                //         dcountry.toCitiesList = domesticpredictions;
                //     }


                // }
                ;
            };
            if (this.sessionTokenEnable) {
                // this.sessionToken = new google.maps.places.AutocompleteSessionToken();
                this.sessionTokenEnable = false;
            }
            this.requestType = {
                input: searchText,
                types: ['(cities)'],
                componentRestrictions: {country: ''},
                sessionToken: this.sessionToken,
            };

            // const service = new google.maps.places.AutocompleteService();
            // service.getPlacePredictions(this.requestType, displaySuggestions);
        }


    }

    addCityandCountry(event, element: any, index: any) {
        event.preventDefault();
        event.stopPropagation();
        this.geocodeAddress(element.description, index, element.place_id);
    }

    geocodeAddress(location: string, index: any, place_id?) {
        this.geocoder = new google.maps.Geocoder();
        this.geocoder.geocode({'address': location}, (results, status) => {
            this.ngZone.run(() => {
                if (status == google.maps.GeocoderStatus.OK) {
                    if (this.fromOrto == "from") {
                        this.totalLcModel.localConveyanceList[index].fromLongitude = (results[0].geometry.location.lng()).toString();
                        this.totalLcModel.localConveyanceList[index].fromLatitude = (results[0].geometry.location.lat()).toString();
                    } else {
                        this.totalLcModel.localConveyanceList[index].toLongitude = (results[0].geometry.location.lng()).toString();
                        this.totalLcModel.localConveyanceList[index].toLatitude = (results[0].geometry.location.lat()).toString();
                    }

                    var city = '';
                    results[0].address_components.forEach((el, i) => {
                        if (this.fromOrto === "from") {
                            if (i == 0) {
                                this.totalLcModel.localConveyanceList[index].fromLocation = el.long_name;
                                this.totalLcModel.localConveyanceList[index].fromCity = el.long_name;
                                this.processRuleAPi(index);
                            } else {
                                if (city == '' && ((this.totalLcModel.localConveyanceList[index].fromCity).length == 0)) {
                                    if (el.types[0] == 'locality') {
                                        this.totalLcModel.localConveyanceList[index].fromCity = el.long_name;
                                    }
                                    if (el.types[0] == 'administrative_area_level_2') {
                                        this.totalLcModel.localConveyanceList[index].fromCity = el.long_name;
                                    }
                                    this.processRuleAPi(index);
                                }
                                if (el.types[0] == 'country') {
                                    this.totalLcModel.localConveyanceList[index].fromCountry = el.long_name;
                                }
                            }
                        }
                        if (this.fromOrto === "to") {
                            if (i == 0) {
                                this.totalLcModel.localConveyanceList[index].toLocation = el.long_name;
                                this.totalLcModel.localConveyanceList[index].toCity = el.long_name;
                            } else {
                                if (city == '' && ((this.totalLcModel.localConveyanceList[index].toCity).length == 0)) {
                                    if (el.types[0] == 'locality') {
                                        this.totalLcModel.localConveyanceList[index].toCity = el.long_name;
                                    }
                                    if (el.types[0] == 'administrative_area_level_2') {
                                        this.totalLcModel.localConveyanceList[index].toCity = el.long_name;
                                    }
                                }
                                if (el.types[0] == 'country') {
                                    this.totalLcModel.localConveyanceList[index].toCountry = el.long_name;
                                }
                            }
                        }
                        this.currentFrom = -1;
                        this.currentTo = -1;
                    });
                }

                //-new code
                if (this.isCSVUpload && (this.currentItemIndx < this.totalLcModel.localConveyanceList.length)) {
                    if (this.fromOrto == 'to' && (this.currentItemIndx == (this.totalLcModel.localConveyanceList.length - 1))) {
                        this.currentItemIndx = -1;
                        this.isCSVUpload = false;
                    } else if (this.fromOrto == 'from') {
                        this.fromOrto = 'to';
                        this.geocodeAddress(this.totalLcModel.localConveyanceList[this.currentItemIndx].toLocation, this.currentItemIndx);
                    } else if (this.fromOrto = 'to') {
                        this.fromOrto = 'from';
                        this.currentItemIndx = this.currentItemIndx + 1;
                        this.geocodeAddress(this.totalLcModel.localConveyanceList[this.currentItemIndx].fromLocation, this.currentItemIndx);
                    } else {
                        this.currentItemIndx = -1;
                        this.isCSVUpload = false;
                    }
                }

                //-new code
            });

        });

        setTimeout(() => {
            if (this.totalLcModel.localConveyanceList[index].toLatitude !== undefined) {
                this.distance(this.totalLcModel.localConveyanceList[index].fromLatitude, this.totalLcModel.localConveyanceList[index].toLatitude,
                    this.totalLcModel.localConveyanceList[index].fromLongitude, this.totalLcModel.localConveyanceList[index].toLongitude, index);
            }
        }, 1000);
    }

    removeGoogleocationdetails(i, fromOrTo) {
        if (fromOrTo == "from") {
            this.totalLcModel.localConveyanceList[i].fromLocation = "";
        } else {
            this.totalLcModel.localConveyanceList[i].toLocation = "";
        }
    }

    distance(lat1, lat2, lon1, lon2, i: any) {
        // The math module contains a function
        // named toRadians which converts from
        // degrees to radians.
        lon1 = lon1 * Math.PI / 180;
        lon2 = lon2 * Math.PI / 180;
        lat1 = lat1 * Math.PI / 180;
        lat2 = lat2 * Math.PI / 180;

        // Haversine formula
        let dlon = lon2 - lon1;
        let dlat = lat2 - lat1;
        let a = Math.pow(Math.sin(dlat / 2), 2)
            + Math.cos(lat1) * Math.cos(lat2)
            * Math.pow(Math.sin(dlon / 2), 2);

        let c = 2 * Math.asin(Math.sqrt(a));
        // Radius of earth in kilometers. Use 3956 for miles
        let r = 6371;
        // calculate the result
        this.totalLcModel.localConveyanceList[i].distance = (c * r).toFixed();
        this.automateAmount(i);
    }

    getDistance(i: any) {
        const matrix = new google.maps.DistanceMatrixService();
        return new Promise((resolve, reject) => {
            matrix.getDistanceMatrix({
                origins: [new google.maps.LatLng(this.totalLcModel.localConveyanceList[i].fromLatitude, this.totalLcModel.localConveyanceList[i].fromLongitude)],
                destinations: [new google.maps.LatLng(this.totalLcModel.localConveyanceList[i].toLatitude, this.totalLcModel.localConveyanceList[i].toLongitude)],
                travelMode: google.maps.TravelMode.DRIVING,
            }, function(response, status) {
                if (status === 'OK') {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
        });
    }

    escapeKeyPressed(e) {
        // this.currentFrom = -1;
        // this.currentTo = -1;
    }

    /*Google maps end*/

    /*mobile*/
    async openDateSheet(index: any, formItem: any) {
        const modal = await this.modalController.create({
            component: CustomDatePickerComponent,
            componentProps: {
                selectedDate: new Date(),
                fromDate: new Date('1981-01-01'),
                toDate: new Date()
            },
            cssClass: 'custom-bottom-sheet'
        });
        modal.onDidDismiss().then(result => {
            if (result.role === 'changed' && result.data) {
                this.totalLcModel.localConveyanceList[index].expensedDate = result.data.formattedDate;
                formItem.controls.expensedDate.patchValue(result.data.formattedDate);
            }
        });
        return await modal.present();
    }
    getDateCall(ev,index: any, formItem: any) {
       
        this.totalLcModel.localConveyanceList[index].expensedDate = moment(ev.detail.value).format("DD-MM-YYYY");
        formItem.controls.expensedDate.patchValue(moment(ev.detail.value).format("DD-MM-YYYY"));
    }


    processRuleAPi(ind: any) {
        let currentCatId = this.totalLcModel.localConveyanceList[ind].categoryId;
        let currentcity = this.totalLcModel.localConveyanceList[ind].fromCity;
        this.policyForUserList?.policyMileageRuleList?.forEach(ele => {
            if ((ele.categoryId == currentCatId) && (ele.cityName == currentcity)) {
                const lcItems = this.totalLcModel.localConveyanceList.filter(lcItem => lcItem.categoryId == currentCatId);
                ele.currentLimit = 0;
                lcItems.forEach(lcItem => {
                    ele.currentLimit = Number(ele.currentLimit) + Number(lcItem.mileageRate);
                });
                if (ele.limitValue < ele.currentLimit) {
                    let violation = {'isSelected': true};
                    this.violationList.splice(ind, 1, violation);
                } else {
                    this.violationList.forEach(violatn => {
                        violatn.isSelected = false;
                    });
                }
            }
        });
    }

}

