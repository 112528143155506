import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { AlertController, IonSlides, ModalController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { BulkItemModel, BulkModel, ExpenseListModel, ExpenseModel, GetExpenseModels } from 'src/app/models/Expenses/expenses.model';
import { GetReportModal, GetTripInfo, ReportSummaryRequestModal, ReportSummaryResponseModal } from 'src/app/models/Reports/reports.model';
import { SharedService } from 'src/app/services/common-services/shared.service';
import { ExpensesService } from 'src/app/services/expenses.service';
import { PlatformService } from 'src/app/services/platformbase.service';
import { ReportsService } from 'src/app/services/reports.service';
import * as _ from "lodash";
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ExpenseAndMileageComponent } from '../../dashboard/expenseandmileage/expenseandmileage.component';
@Component({
  selector: 'app-trip-details',
  templateUrl: './trip-details.component.html',
  styleUrls: ['./trip-details.component.scss'],
})
export class TripDetailsComponent implements OnInit {
  @ViewChild('slides', { static: false }) slider: IonSlides;
  @Input() detailReport;
  @Input() tripList: any;
  @Input() seletedTrip: any;
  @Input() selectedTripIndex: any;
  @Output() backto: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild("expenseSelection", { static: false }) private _expenseSelection;
  @ViewChild("claimSubmitConfirmation", { static: false }) private _claimSubmitConfirmation;


  platformType: number;
  isMobileplatform: boolean;
  userInfo: any;
  expSummary: any;
  showLoader = true;
  paxUITitles = ['Title*', 'First Name*', 'Last Name*', 'Primary Mobile Number*',
    'Primary Email ID*', 'DOB'];
  dummyList = [1, 2, 3, 4, 5, 6, 7];
  showJourneyTabl = true;
  showPaxTabl = true;
  isHistoryOpen = true;
  isReports = false;
  approveDisable = false;
  rejectDisable = false;
  //modalRef: any;
  modalRef: BsModalRef;
  rejectReasn: any;
  isTripOpen = true;
  isPaxOpen = true;
  isExpenseOpen = true;
  isProofsOpen = true;
  isFirstOpenproof=false;
  tripDetailsList: any;
  tripEstimateDetails:any;
  tripDetailsData: any;
  selectObj: any;
  serviceName: string;
  passengersDetailsData: any;
  locationStr: string;
  selectedTrips = [];
  multiSelectedTrips = [];
  tripID: string;
  tripStartDate: string;
  tripCost: any;
  totalTripCost: any;
  associated: boolean = false;

  singleSelectedTripDetails: any;
  singleSelection: boolean = false;
  multiSelection: boolean = false;
  selectedTripDetails: any;
  tripsTotalAmount = 0;
  notassociated: boolean;
  singleSelectVal: boolean = true;
  invoiceDetails: any;
  imagePath: any;
  isSelect: boolean = true;
  isShowAttachment: boolean = false;
  expenseList: any;
  pdfData: any;
  proofList = [];
  defaultIndex = 0;
  isPdf: boolean;
  selectedExpenseids = [];
  getExpenseModels = new GetExpenseModels();
  invoiceDetailsForDetails: any;
  url: string;
  dropdownFlagtrip: boolean;
  buttonDisabled:boolean = false;
  enableproceededbtn: boolean = false;
  disablenextbtn: boolean = true;



  constructor(public platformService: PlatformService, public modalController: ModalController,
    public toastr: ToastrService, private expenseService: ExpensesService,
    private translate: TranslateService, public reportsService: ReportsService,
    public sharedService: SharedService, public alertController: AlertController,
    private zone: NgZone, private modalService: BsModalService,
    private angularRouter: Router,
    private _sanitizer: DomSanitizer,
    private navCtrl: NavController) {
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if ( this.angularRouter.url == '/expenses') {
      this.userInfo.recordType =1;
      // this.router.navigateRoot(["petty-cash/new-report"]);
   } else if(this.angularRouter.url == '/advance'){
      this.userInfo.recordType =2;
      // this.router.navigateRoot(["newreport"]);
    }
  }
  ngOnInit() {

    this.platformType = this.platformService.getPlatformType();
    this.isMobileplatform = this.platformService.isMobileDevice();
    this.selectObj = this.seletedTrip;
    console.log('selectedTripIndex', this.selectObj,this.seletedTrip);
    this.tripList.forEach(element => {
      element.isSelected = false;
    });    
    this.tripList[this.selectedTripIndex]?.serviceList?.forEach(t => {
      let invoiceUrlCount = 0;
      t.invoiceList?.forEach(invoice => {
        if(invoice.invoiceUrl) {
          invoiceUrlCount++;
        }
      });
      t.invoicesAvailable = invoiceUrlCount>0?true:false;
    })
    const combinedInvoiceList = (this.selectObj?.serviceList ?? []).reduce((accumulator, service) => {
      const validInvoices = (service?.invoiceList ?? []).filter(invoice => {
        const invoiceUrl = invoice?.invoiceUrl;
        return typeof invoiceUrl === 'string' && invoiceUrl.trim() !== "";
      });
      return [...accumulator, ...validInvoices];
    }, []);
    this.invoiceDetails = combinedInvoiceList;
    this.tripList[this.selectedTripIndex]?.serviceList.forEach(element => {
      if(element.statusId != 3 && element.statusId != 6 && element.statusId != 11 && element.statusId != 13)
      {
        this.disablenextbtn =false;
       
      }
      else{
        this.disablenextbtn =true;
      }
    
   });

   this.getTripDetailsCall(this.selectObj);
  }

  clickBack() {
    this.backto.emit();
  }

  gotoExpense() {
    this.backto.emit();
  }
  currentExpenseIndex: number;
  budgetRefNumber: any;
  getTripDetailsCall(obj?) {
    this.currentExpenseIndex = this.tripList?.findIndex(e => e.tripId == obj.tripId);
    this.selectObj = obj;
    this.selectedTripDetails = this.selectObj;
    let getTripModl = new GetTripInfo();
    getTripModl.legalEntityId = this.userInfo.legalEntityId;
    getTripModl.legalEntityGUID = this.userInfo.legalEntityGuId;
    getTripModl.companyId = this.userInfo.companyId;
    getTripModl.companyGUID = this.userInfo.companyGuId;
    getTripModl.tripId = obj.tripId;
    getTripModl.tripGUID = obj.tripGUID;
    getTripModl.productId = this.selectObj?.serviceList[0]?.productId;
    getTripModl.requestId = this.selectObj?.serviceList[0]?.requestId;
    getTripModl.requestGUID = this.selectObj?.serviceList[0]?.requestGUID;


    this.reportsService.getTripDetails(getTripModl).subscribe((res) => {
      if (!res.isError && res.errorCode === 0 && res?.payload) {
        console.log('sdsd',res?.payload);
        this.expenseList = [];
        this.showLoader = false;
        this.dropdownFlagtrip = false;
        this.tripDetailsList = res?.payload;
        this.tripEstimateDetails = res?.payload?.tripEstimateDetails;
        this.budgetRefNumber = res?.payload?.budgetRefNumber || null;
        this.totalTripCost = this.tripDetailsList.tripCost;
        this.tripID = this.tripDetailsList?.tripId;
        this.tripStartDate = this.tripDetailsList?.tripStartDate;
        this.expenseList = this.tripDetailsList?.taggedExpenses;

        if (this.selectObj?.serviceList[0]?.productId == "1") {
          this.tripCost = this.tripDetailsList?.flightDetails?.subTotalPrice;
          this.serviceName = 'Flight';
          this.passengersDetailsData = this.tripList[this.selectedTripIndex]?.tripTravellers || [];
          let journeyDetails = this.tripDetailsList?.flightDetails?.flights[0].journeyDetails;
          if (journeyDetails?.length == 1) {
            this.locationStr = journeyDetails[0].fromCityCode + '-' + journeyDetails[0].toCityCode
          } else if (journeyDetails?.length == 2) {
            this.locationStr = journeyDetails[0].fromCityCode + '-' + journeyDetails[0].toCityCode + '-' + journeyDetails[1].toCityCode
          } else if (journeyDetails?.length == 3) {
            this.locationStr = journeyDetails[0].fromCityCode + 'via' + journeyDetails[1].fromCityCode + '-' + journeyDetails[journeyDetails.length - 1].toCityCode
          }
        } else if (this.selectObj?.serviceList[0]?.productId == "2") {
          this.serviceName = 'Hotel';
          this.tripCost = this.tripDetailsList.hotelDetails.subTotalPrice;
          this.locationStr = this.tripDetailsList.hotelDetails.hotels[0].cityName;
          this.passengersDetailsData = this.tripDetailsList.hotelDetails.hotels[0].roomDetails[0].guestDetails;
        } else if (this.selectObj.serviceList[0]?.productId == "7") {
          this.passengersDetailsData = this.tripDetailsList.insuranceDetails.insurancePlans[0].passengerDetails;
          this.serviceName = 'Insurance';
          this.tripCost = this.tripDetailsList.insuranceDetails.subTotalPrice;
          this.locationStr = this.tripDetailsList.insuranceDetails.insurancePlans[0].fromCity + '-' + this.tripDetailsList.insuranceDetails.insurancePlans[0].toCity;
        } else if (this.selectObj.serviceList[0]?.productId == "6") {
          this.passengersDetailsData = this.tripDetailsList.cabDetails.cabs[0].passengerDetails;
          this.serviceName = 'Cab';
          this.tripCost = this.tripDetailsList.cabDetails.subTotalPrice;
          this.locationStr = this.tripDetailsList.cabDetails.cabs[0].pickupLocation + '-' + this.tripDetailsList.cabDetails.cabs[0].dropLocation;
        }
        else if (this.selectObj.serviceList[0]?.productId == "8") {
          this.passengersDetailsData = this.tripDetailsList.busDetails.buses[0].passengerDetails;
          this.serviceName = 'Bus';
          this.tripCost = this.tripDetailsList.busDetails.subTotalPrice;
          this.locationStr = this.tripDetailsList.busDetails.buses[0].fromCity + '-' + this.tripDetailsList.busDetails.buses[0].toCity;
        }
        else if (this.selectObj.serviceList[0]?.productId == "3") {
          this.passengersDetailsData = this.tripDetailsList.visaDetails.visas[0].passengerDetails;
          this.serviceName = 'Visa';
          this.tripCost = this.tripDetailsList.visaDetails.subTotalPrice;
          this.locationStr = this.tripDetailsList.visaDetails.visas[0].travellingToCountry;
        }
        else if (this.selectObj.serviceList[0]?.productId == "9") {
          this.passengersDetailsData = this.tripDetailsList.railDetails.rails[0].passengerDetails;
          this.serviceName = 'Rail';
          this.tripCost = this.tripDetailsList.railDetails.subTotalPrice;
          this.locationStr = this.tripDetailsList.railDetails.rails[0].fromCity + '-' + this.tripDetailsList.railDetails.rails[0].toCity;
        }
        else if (this.selectObj.serviceList[0]?.productId == "4") {
          this.passengersDetailsData = this.tripDetailsList.forexDetails.forexs[0].passengerDetails;
          this.serviceName = 'Forex';
          this.tripCost = this.tripDetailsList.forexDetails.subTotalPrice;
          //this.locationStr = this.tripDetailsList.forexDetails.forexs[0].stateName;
          this.locationStr = this.tripDetailsList.forexDetails.forexs[0].country;
        }

        let str2 = this.invoiceDetails && this.invoiceDetails[0] ? this.invoiceDetails[0]?.base64Invoice : [];
        this.imagePath = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/pdf;base64,'
          + str2);

      } else if(res.isError && res.errorCode == 2) {
        this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
      }
    });
  }
  attachmentsPdf(){
    this.toastr.error('Attachemnts not found','error');
  }
  nextDetail() {
    if(this.currentExpenseIndex < this.tripList.length-1){
      ++this.currentExpenseIndex;
      this.getTripDetailsCall(this.tripList[this.currentExpenseIndex]);
    }
  }

  preDetail() {

    if(this.currentExpenseIndex >0){
      --this.currentExpenseIndex;
      this.getTripDetailsCall(this.tripList[this.currentExpenseIndex]);
    }
  }

  selectSingleTrip(list, e) {
    list.isSelected = e.target.checked
    if (list.isSelected) {
      this.isSelect = false;
      this.selectedTrips.push(list);
    } else {
      let index = _.indexOf(this.selectedTrips, list.tripId);
      this.selectedTrips.splice(index, 1);
    }

  }

  proceed() {
    this.selectedTrips.forEach(element => {
      element.serviceList.forEach(ele => {
        ele.paidBy = ele.CompanyPaid ? 1 : 2;
      });
    });
    this.modalRef.hide();
    var currentIndx = 0;
    var url = 'newreport';
    let navInfo = {
      url: url,
      fromPage: 'viewSlide',
      openTabIndex: 0,
      isTravelClaim: true,
      singleSelection: this.singleSelection,
      multiSelection: this.multiSelection,
      singleExpense: this.selectedTrips,
      expenseList: this.selectedTrips,
      tripEstimateDetails:this.seletedTrip.tripEstimateDetails,
      isAssociated: this.associated ? this.associated : false,
      isReadOnly: false,
      currentIndx: currentIndx,
      goToReportDetails: true,
      budgetRefNumber: this.budgetRefNumber || null,
      tripData: JSON.parse(sessionStorage.getItem('tripData')) || null,
      report: {
        fromMytrips: true,
        claimName: this.selectedTrips[0]?.tripName,
        claimEndDate: this.selectedTrips[0]?.tripEndDate,
        claimStartDate: this.selectedTrips[0]?.tripStartDate,
        claimTypeId: this.selectedTrips[0]?.serviceList[0]?.claimTypeId,
        claimTypeName: this.selectedTrips[0]?.serviceList[0]?.claimTypeName,
      },
      advanceSummary:this.selectObj.advanceSummary
    };
    sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
    this.navCtrl.navigateRoot(["newreport"]);
    // if(this.isMobileDevice) {
    //   this.navCtrl.navigateRoot(["newExpenseMileageBulk"]);
    // } else {
    //   this.openExpenseCreationForm();
    // }
  }

            // this.navCtrl.navigateRoot(["newExpenseMileageBulk"]);
        //   if(this.isMobileDevice) {
        //     this.navCtrl.navigateRoot(["newExpenseMileageBulk"]);
        // } else {
        //     this.openExpenseCreationForm();
        // }
        isMobileDevice: boolean = false;
        async openExpenseCreationForm() {
            const modal = await this.modalController.create({
                component: ExpenseAndMileageComponent,
                cssClass: "auto-height"
            });
            modal.onDidDismiss().then(res => {
            })
            return await modal.present();
        }


  proceedadvance(){
    var url = 'advance';
    let navInfo = {
      url: url,
      advance: {
        claimTaggedTripList: this.selectObj.serviceList
      },
    };
    sessionStorage.setItem('hideTripDetails', 'true');
    sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
    console.log(navInfo)
    this.navCtrl.navigateRoot(["newAdvance"]);
  }
  createNewExpense(input) {
    this.selectedTrips = [];
    if (this.isSelect) {
      this.selectedTrips.push(this.selectObj);
      this.selectedTripDetails = this.selectObj;
    }
    if (this.selectedTrips.length == 0) {
    } else if (this.selectedTrips.length == 1) {
      this.singleSelection = true;
      this.multiSelection = false;
      this.totalAmount();
      const config = {
        ignoreBackdropClick: true,
        class: "modal-dialog-centered modal-lg baa",
        keyboard: false,
      };
      this.modalRef = this.modalService.show(input == 1 ? this._expenseSelection : this._claimSubmitConfirmation, config);
    } else {
      this.singleSelection = false;
      this.multiSelection = true;
      this.totalAmount();
      const config = {
        ignoreBackdropClick: true,
        class: "modal-dialog-centered modal-lg baa",
        keyboard: false,
      };
      this.modalRef = this.modalService.show(input == 1 ? this._expenseSelection : this._claimSubmitConfirmation, config);
    }

    this.tripList[this.selectedTripIndex].serviceList.forEach(element => {
      if(element.isCompanyPaid){
       element.CompanyPaid = true;
       element.EmployeePaid = false;
      }else{
       element.CompanyPaid = false;
       element.EmployeePaid = true;
      }
      this.enableproceededbtn = true;
     });


      if((this.userInfo.isAssociatedExpenseEnabled && !this.userInfo.isServiceExpenseEnabled))
      {
        this.tripList[this.selectedTripIndex].serviceList.forEach((element,i) => {
        
          element.CompanyPaid = true;
          element.EmployeePaid = false;
          this.enableproceededbtn = true;
        });
      }
      if((this.userInfo.isServiceExpenseEnabled && !this.userInfo.isAssociatedExpenseEnabled ))
      {
        this.tripList[this.selectedTripIndex].serviceList.forEach((element,i) => {
          element.EmployeePaid = true;
          element.CompanyPaid = false;
          this.enableproceededbtn = true;
          
        });
      }
    // this.sharedService.setTravelTrip(this.tripsList[index]);
    // this.navCtrl.navigateRoot(['newExpenseMileageBulk']);
  }



  notassociatedClick() {
    this.notassociated = true;
    this.associated = false;
  }
  associatedClick() {
    this.associated = true;
    this.notassociated = false;
  }
  closeExpenseSelection() {
    this.defaultIndex = 0;
    this.modalRef.hide();
    // this.isSelect = false;
  }

  // pdfSelectionCall(pdfSelection: TemplateRef<any>) {

  //   let urlStr = this.invoiceDetails[this.defaultIndex]?.invoiceUrl;
  //   if (urlStr.split('?')[0].split('.').pop() == 'pdf') {
  //     this.isPdf = true;
  //     const linkSource = `data:application/pdf;base64,${this.invoiceDetails[this.defaultIndex].base64Invoice}`;
  //     this.pdfData = linkSource;
  //   } else {
  //     this.isPdf = false;
  //   }
  //   const config = {
  //     ignoreBackdropClick: true,
  //     class: "modal-dialog-centered modal-lg baa",
  //     keyboard: false,
  //   };
  //   this.modalRef = this.modalService.show(pdfSelection, config);
  // }
  pdfSelectionCall(pdfSelection: TemplateRef<any>, item?) {
    console.log('sdsdsd888',this.invoiceDetails,item);
    const config = {
      ignoreBackdropClick: false,
      class: "modal-dialog-centered modal-lg baa",
      keyboard: false,
    };
    if (item != null) {
      this.invoiceDetails = item.invoiceList || [];
    }
    if (Array.isArray(this.invoiceDetails) && this.invoiceDetails?.length > 0) {
      const urlStr = this.invoiceDetails[0]?.invoiceUrl;
      if (urlStr && urlStr?.split('?')[0]?.split('.').pop() === 'pdf') {
        this.getPdfAsBase64(urlStr).then((baseRes) => {
          const linkSource = `data:application/pdf;base64,${baseRes}`;
          this.pdfData = linkSource;
          this.isPdf = true;
        })
      } else {
        this.isPdf = false;
      }
    } else {
      this.isPdf = false;
    }
    try {
      this.modalRef = this.modalService.show(pdfSelection, config);
    } catch (error) {
      // Handle the error if needed.
    }
  }
  closeSelection() {
    this.defaultIndex = 0;
    this.modalRef.hide();
  }

  pdfSelectionExpCall(pdfSelection: TemplateRef<any>) {

    let urlStr = this.invoiceDetails[this.defaultIndex].invoiceUrl;
    if (urlStr.split('?')[0].split('.').pop() == 'pdf') {
      this.isPdf = true;
      const linkSource = `data:application/pdf;base64,${this.invoiceDetails[this.defaultIndex].base64Invoice}`;
      //   console.log(linkSource);
      this.pdfData = linkSource;
    } else {
      this.isPdf = false;
    }
    const config = {
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-lg baa",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(pdfSelection, config);
  }

  pdfSelectionAssciatExpCall(pdfSelection: TemplateRef<any>, obj) {

    this.getExpenseAttachments(obj);
    const config = {
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-lg baa",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(pdfSelection, config);
  }

  getExpenseAttachments(list) {
    this.selectedExpenseids.push(list?.expenseId);
    this.selectedExpenseids = _.uniq(this.selectedExpenseids);
    this.getExpenseModels.expenseIds = this.selectedExpenseids;
    this.getExpenseModels.legalEntityId = this.userInfo.legalEntityId;
    this.getExpenseModels.legalEntityGUID = this.userInfo.legalEntityGuId;

    this.expenseService
      .expenseAttachViewer(this.getExpenseModels)
      .subscribe((res) => {
        if (!res.isError && res.errorCode === 0) {
          this.invoiceDetails = [];
          res.payload.attachementList.forEach(element => {
            let finalObj = {
              base64Invoice: element.base64,
              invoiceUrl: element.url,
            }
            this.invoiceDetails.push(finalObj);
          });
          let urlStr = this.invoiceDetails[this.defaultIndex].invoiceUrl;
          if (urlStr.split('?')[0].split('.').pop() == 'pdf') {
            this.isPdf = true;
            const linkSource = `data:application/pdf;base64,${this.invoiceDetails[this.defaultIndex].base64Invoice}`;
            this.pdfData = linkSource;
          } else {
            this.isPdf = false;
          }
        } else if (res.isError && res.errorCode === 2) {
        }
      });
  }

  downloadPdf(base64String, fileName) {
    const source = `data:application/pdf;base64,${base64String}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${fileName}.pdf`
    link.click();
  }
  onClickDownloadPdf(item) {
    let base64String = item.base64Invoice;
    this.downloadPdf(base64String, "Invoice");
  }
  totalAmount() {
    this.tripsTotalAmount = this.tripList[this.selectedTripIndex]?.serviceList?.map(item => parseInt(item.tripCost))
      .reduce((prev, curr) => prev + curr, 0);
  }

  openViewOnlyExp(expList, currentIndx, item) {
    var url = 'expenses';
    // if (this.fromPage == 'approvePage') {
    //     url = 'approvals';
    // }
    let navInfo = {
      url: url,
      fromPage: 'viewSlide',
      expenseArray: expList,
      isReadOnly: true,
      currentIndx: currentIndx,
      goToReportDetails: true,
      detailReport: this.detailReport
    };

    sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
    console.log('sdsds 666');
    this.navCtrl.navigateRoot(["newExpenseMileageBulk"]);
  }

  prevBtn() {
    if (this.defaultIndex != 0) {
      this.defaultIndex = this.defaultIndex - 1;
      let urlStr = this.invoiceDetails[this.defaultIndex].invoiceUrl;
      if (urlStr.split('?')[0].split('.').pop() == 'pdf') {
        this.getPdfAsBase64(urlStr).then((baseRes) => {
          const linkSource = `data:application/pdf;base64,${baseRes}`;
          this.pdfData = linkSource;
          this.isPdf = true;
        })
      } else {
        this.isPdf = false;
      }
      this.zoom = 1.0;
    }
  }

  nextBtn() {
    if ((this.defaultIndex + 1) == this.invoiceDetails.length) {
      // this.defaultIndex = 0;
    } else {
      this.defaultIndex = this.defaultIndex + 1;
      let urlStr = this.invoiceDetails[this.defaultIndex].invoiceUrl;
      if (urlStr.split('?')[0].split('.').pop() == 'pdf') {
        this.getPdfAsBase64(urlStr).then((baseRes) => {
          const linkSource = `data:application/pdf;base64,${baseRes}`;
          this.pdfData = linkSource;
          this.isPdf = true;
        })
      } else {
        this.isPdf = false;
      }
      this.zoom = 1.0;
    }

  }

  downloadBtn() {
    let selectObj = this.invoiceDetails[this.defaultIndex].invoiceUrl;
    window.open(selectObj);
  }
  pdfchecking(value) {
   // console.log(value);
    if (value.invoiceUrl?.split('?')[0].split('.').pop() == 'pdf') {
      return 'pdf'
    } else {
      return 'png'
    }
  }

  formatDate(date) {
    if (date) {
      return moment(date, 'DD-MM-YYYY').format('DD-MMM-YY');
    }
  }
  submitClaim(){
    this.buttonDisabled = true;
    let expenseModel = new BulkModel();
    expenseModel.userId = this.userInfo.userId;
    expenseModel.userGUID = this.userInfo.userGUID;
    expenseModel.legalEntityId = this.userInfo.legalEntityId;
    expenseModel.legalEntityGUID = this.userInfo.legalEntityGuId;
    expenseModel.claimStartDate = '';
    expenseModel.claimEndDate = '';
    expenseModel.claimTaggedTripList = this.tripList[this.selectedTripIndex]?.serviceList;
    expenseModel.action = 10;
    expenseModel.isTripDirectSubmit = true;
    expenseModel.claimCategoryName = '';
    expenseModel.claimCategoryId = '';
    expenseModel.claimTypeName=''
    expenseModel.claimTypeId=''
    expenseModel.reasonForTravel=''
    expenseModel.advanceAmount=0;
    // expenseModel.claimName='Trip Claim';
    expenseModel.claimName=this.tripDetailsList?.tripName + ' - ' + this.tripDetailsList?.tripId;
    expenseModel.budgetRefNumber = this.budgetRefNumber || null;

    let bulkItem = new BulkItemModel();
    bulkItem.categoryType = '';
    bulkItem.categoryId = 0;
    bulkItem.categoryName=''
    bulkItem.expensedDate = this.tripDetailsList?.tripStartDate;
    bulkItem.merchantName='';
    bulkItem.description = 'Trip description';
    bulkItem.currencyCode = this.userInfo?.baseCurrency?.currencyCode;
    bulkItem.originalCurrencyId = this.userInfo?.baseCurrency?.currencyId;
    bulkItem.originalCurrencyIcon = this.userInfo?.baseCurrency?.currencyIcon;
    bulkItem.originalAmount=Number(this.tripDetailsList?.tripCost);
    bulkItem.expConvRate = 1;
    bulkItem.exchangeRate = 1;
    bulkItem.expenseType = 1;
    bulkItem.recordType = 1;
    bulkItem.paidBy = 1;
    expenseModel.expenseRequestDTOList = [];
    expenseModel.expenseRequestDTOList.push(bulkItem);
    expenseModel.claimTaggedTripList?.forEach(c => {
      c.paidBy = 1;
    });
    this.expenseService.createBulk(expenseModel).subscribe(res =>{
      this.buttonDisabled = false;
      this.modalRef.hide();
      this.clickBack();
      if(res.errorCode === 0){
        this.toastr.success('Claim created successfully','Success');
      }
    })

  }

  resetClose() {
    this.modalRef.hide();
  }
  onchange(index, event) {
    let allSelected = true;
    this.tripList[this.selectedTripIndex].serviceList.forEach((element, i) => {
      if (i == index) {
        if (event == 'CompanyPaid') {
          element.CompanyPaid = true;
          element.EmployeePaid = false;
        }
        if (event == 'EmployeePaid') {
          element.EmployeePaid = true;
          element.CompanyPaid = false;
        }
      }
      if (!element.CompanyPaid && !element.EmployeePaid) {
        allSelected = false;

      }

    });
    this.enableproceededbtn = allSelected;  
  }

  async getPdfAsBase64(url: string): Promise<string> {
    try {
        const response = await fetch(url);
        const buffer = await response.arrayBuffer();

        // Convert the response data to base64
        const base64 = btoa(
            new Uint8Array(buffer)
                .reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
        return base64;
    } catch (error) {
        throw error;
    }
  }

  async openTripEstimatePopup(tripEstimatePopOver?: TemplateRef<any>) {
    const config = {
        ignoreBackdropClick: true,
        class: "modal-dialog-centered modal-xl",
        keyboard: false,
    };
    this.modalRef = this.modalService.show(tripEstimatePopOver, config);
  }

  zoom = 1.0;
  zoomIn() {
    this.zoom += 0.25;
  }

  zoomOut() {
    if (this.zoom > 0.25) {
      this.zoom -= 0.25;
    }
  }

}


