import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Constants } from 'src/app/Constants';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
})
export class DateRangeComponent implements OnInit {
  downloadForm: FormGroup;
  maxDate: any;

  constructor(
    private toaster: ToastrService,
    public formBuilder: FormBuilder,
    public modalCtrl: ModalController
  ) { }

  ngOnInit() {
    this.maxDate = moment(new Date()).format(Constants.mobileCalenderFormat);
    this.downloadForm = this.formBuilder.group({
      fromDate: ['', [Validators.required]],
      toDate: ['', [Validators.required]]
    });
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  download() {
    if (this.downloadForm.invalid) {
      return;
    }

    let fDate = this.downloadForm.value?.fromDate;
    let tDate = this.downloadForm.value?.toDate;

    if (!moment.utc(fDate, 'DD-MM-YYYY', true).isValid() || moment.isMoment(fDate) || this.checkDateFormat(fDate)) {
      fDate = moment(fDate).format('DD-MM-YYYY');
    }
    if (!moment.utc(tDate, 'DD-MM-YYYY', true).isValid() || moment.isMoment(tDate) || this.checkDateFormat(tDate)) {
      tDate = moment(tDate).format('DD-MM-YYYY');
    }

    let start = moment(fDate, 'DD-MM-YYYY');
    let end = moment(tDate, 'DD-MM-YYYY');
    let daysCount = end?.diff(start, "days");
    if (daysCount > 60) {
      this.toaster.error("You can\'t download reports more than 60 days.", 'Error');
      return;
    }

    let dates = {
      fDate: fDate,
      tDate: tDate,
    }
    this.modalCtrl.dismiss(dates);
  }

  checkDateFormat(date) {
    let cleanedDate = date?.toString().replace(/\(.*\)/, '').trim();
    return (!moment.utc(cleanedDate, 'DD-MM-YYYY', true).isValid() && !moment(cleanedDate, 'ddd MMM DD YYYY HH:mm:ss ZZ', true).isValid())
  }

}
