import { Component, Input, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActionSheetController, AlertController, ModalController, NavController } from '@ionic/angular';
import { ToastrService } from 'ngx-toastr';
import { GetReportModal, RemoveReportModal, ReportListResModel } from 'src/app/models/Reports/reports.model';
import { SharedService } from 'src/app/services/common-services/shared.service';
import { PlatformService } from 'src/app/services/platformbase.service';
import { ReportsService } from 'src/app/services/reports.service';
import { ExpenseAndMileageComponent } from '../../dashboard/expenseandmileage/expenseandmileage.component';


@Component({
  selector: 'app-detailreport',
  templateUrl: './detailreport.component.html',
  styleUrls: ['./detailreport.component.scss'],
})
export class DetailreportComponent implements OnInit {
  platformType: number;
  @Input() claimId;
  @Input() claimGUID;
  @Input() claimName;
  @Input() fromPage;
  getReport: any;
  userInfo: any;
  addExpense: string;
  addDelete: string;
  showLoading = true;
  dummyList = [1, 2, 3, 4];
  showMobileDetails = true;
  detailReport: any;

  getReportModal = new GetReportModal();
  removeReportModal = new RemoveReportModal();


  constructor(
    public modalController: ModalController,
    private platformService: PlatformService,
    public actionSheetController: ActionSheetController,
    private reportService: ReportsService,
    public alertController: AlertController,
    public reportsService: ReportsService,
    public sharedService: SharedService,
    public toastr: ToastrService,
    private router: NavController,
    private zone: NgZone
  ) {
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  }

  ngOnInit() {
    this.platformType = this.platformService.getPlatformType();
    this.getReportDetails();
  }

  getReportDetails() {
    this.getReportModal.claimId = this.claimId;
    this.getReportModal.claimGUID = this.claimGUID;
    this.getReportModal.legalEntityId = this.userInfo.legalEntityId;
    this.getReportModal.legalEntityGUID = this.userInfo.legalEntityGuId;
    this.getReportModal.userId = this.userInfo.userId;
    this.getReportModal.userGUID = this.userInfo.userGuId;
    this.reportService.getReportDetails(this.getReportModal).subscribe(data => {
      if (!data.isError && data.errorCode === 0) {
        this.showLoading = false;
        this.getReport = new ReportListResModel(data.payload);
      } else {
        this.showLoading = false;
        this.toastr.error(data.errorMessage, 'Error');
      }
    });

  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  async editDetailReport() {
    if (this.getReport.status === 2) {
      this.addExpense = "disabled";
    } else {
      this.addExpense = "";
    }
    if (this.getReport.status !== 1) {
      this.addDelete = "disabled";
    } else {
      this.addDelete = "";
    }

    const actionSheet = await this.actionSheetController.create({
      // header: "Details",
      cssClass: "my-custom-class",
      buttons: [
        {
          text: "Add expense",
          cssClass: this.addDelete,
          icon: 'add-outline',
          handler: () => {
            this.addExpenseFromReport(1);
          }
        },
        {
          text: "Edit",
          icon: "create-outline",
          handler: () => {
            actionSheet.dismiss();

            this.zone.run(() => this.openNewReport());

            // this.openNewReport();

          }
        },
        {
          text: "Delete",
          cssClass: this.addDelete,
          icon: 'trash-outline',
          handler: () => {
            this.removeReportPopUp();

          }
        },
        {
          text: "Submit",
          cssClass: this.addDelete,
          icon: 'arrow-forward-circle-outline',
          handler: () => {
            this.submitSingleReport();
          }
        },
      ]
    });
    await actionSheet.present();
  }
  openNewReport(editreport?) {
    let navInfo = {
      url:'detailreport',
      report:editreport
    };
  sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
    this.router.navigateRoot(["newreport"]);
    this.modalController.dismiss({}, '', 'detailreportModal');
  }

  async removeReportPopUp() {
    const alert = await this.alertController.create({
      mode: "md",
      message: "Are you sure you want to <b>delete</b> this <b>report</b>?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          handler: () => { },
        },
        {
          text: "Delete",
          handler: () => {
            const claimId = [];
            claimId.push(this.getReport.claimId);
            this.removeReportModal.legalEntityId = this.userInfo.legalEntityId;
            this.removeReportModal.legalEntityGUID = this.userInfo.legalEntityGuId;
            this.removeReportModal.userId = this.userInfo.userId;
            this.removeReportModal.userGUID = this.userInfo.userGuId;
            this.removeReportModal.claimIds = claimId;
            this.reportsService
              .removeReport(this.removeReportModal)
              .subscribe((data) => {
                if (!data.isError && data.errorCode === 0) {
                  this.sharedService.setRefreshData(true);
                  this.toastr.success(data.payload, "Success");
                  this.dismiss();
                } else if (data.isError && data.errorCode === 2) {
                  this.toastr.error(data.errorMessage, "Error");
                }
              });
          },
        },
      ],
    });

    await alert.present();
  }

  async addExpenseFromReport(type) {
    const modal = await this.modalController.create({
      component: ExpenseAndMileageComponent,
      cssClass: "my-custom-class",
      componentProps: {
        claimId: this.claimId,
        claimGUID: this.claimGUID,
        claimName: this.claimName,
        type
      },
    });
    return await modal.present();
  }

  reportDetailView() {
    this.detailReport = this.getReport;
    this.fromPage = this.fromPage;
    this.showMobileDetails = false;
  }

  async submitSingleReport() {
    const alert = await this.alertController.create({
      mode: "md",
      message: "Are you sure you want to <b>Submit</b> this <b>report</b>?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          handler: () => { },
        },
        {
          text: "Submit",
          handler: () => {
            const claimId = [];
            claimId.push(this.getReport.claimId);
            this.removeReportModal.userId = this.userInfo.userId;
            this.removeReportModal.userGUID = this.userInfo.userGuId;
            this.removeReportModal.legalEntityId = this.userInfo.legalEntityId;
            this.removeReportModal.legalEntityGUID = this.userInfo.legalEntityGUID;
            this.removeReportModal.claimIds = claimId;
            this.reportsService.draftReport(this.removeReportModal).subscribe((res) => {
              if (!res.isError && res.errorCode === 0) {
                this.sharedService.setRefreshData(true);
                this.toastr.success(res.payload, "Success");
              } else if (res.isError === true) {
                this.toastr.error(res.errorMessage, "Error");
              }
            });
          },
        },
      ],
    });

    await alert.present();
  }
  backToreport() {
    this.showMobileDetails = true;
  }

}
