export class PerdiemModel {
    originalApproveAmount:any;
    PDlocation:any;
    PDlocationId:any;
    PDrate:any;
    PDtype:any;
    PDradioSelection:any;
    PDradioSelectionName:any;
    PDradioStatus:boolean = false;
    pdstatus:boolean =false;
    PDstartDate:any;
    PDstartDateFormate:any
    PDendDate:any;
    PDendDateFormate:any;
    PDstartTime:any;
    PDendTime:any;
    PDperDay:boolean = false;
    PDperHalfDay:boolean = false;
    PDperHour:boolean = false;
    PDclaimName:any;
    PDclaimId:any;
    expenseAttachments = [];
    PDdescription:any
    PDcategory:any;
    PDoriginalCurrency:any;
    PDoriginalCurrencyConvertedRate:any;
    PDoriginalCurrencyIcon:any;
    PDoriginalCurrencyId:any;
    PDamount:any;
    PDexpenseDate:any;
    PDspentLocation:any;
    PDspentLocationId:any;
    PDcategoryId:any;
    PDinvoiceNumber:any;
    PDtaxNumner:any;
    PDtaxAmount:any;
    PDtax:any;
    PDtaxPercentage: any;
    PDhsnCode: any;
   // PDclaimId:any
   customFieldList: any;
   bandId: any;
   departmentId: any;
   costCenterId: any;
   projectCodeId: any;
   bandName: any;
   departmentName: any;
   costCenterName: any;
   projectCodeName: any;
   showAdditionalFields = false;
   PDCity: any;
   PDCityId: any;
   perDiemId: any;
   policyId: any;
   exchangeRate: any;
}