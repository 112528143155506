import { ChangeDetectorRef, Component, NgZone, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActionSheetController, AlertController, IonInput, IonSlides, ModalController, NavController, PopoverController } from '@ionic/angular';
import { ChangeContext, Options } from 'ng5-slider';
import { AppConfig } from 'src/app/config/app.config';
import { CategoryAndMerchantModel, CategoryList, DownloadModel, ExpenseAndReportModel, FilterModal, StatusListModel } from 'src/app/models/Common/common.model';
import { PlatformService } from 'src/app/services/platformbase.service';
import * as _ from "lodash";
import { CommonService } from 'src/app/services/common-services/common.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ExpensesService } from 'src/app/services/expenses.service';
import { DeleteExpenses, ExpenseAttachmentsModel, ExpenseListModel, GetExpenseModels } from 'src/app/models/Expenses/expenses.model';
import { RemoveReportModal } from 'src/app/models/Reports/reports.model';
import { ReportsService } from 'src/app/services/reports.service';
import { LcService } from 'src/app/services/localConveyance.service';
import * as moment from 'moment';
import { Constants } from 'src/app/Constants';
import { DatechangeService } from 'src/app/services/common-services/datechange.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';
import { LcItemBindModel } from 'src/app/models/Lc/LocalConveyance.model';
import { CustomPopOverComponent } from '../custom-pop-over/custom-pop-over.component';
import { ImageViewComponent } from '../dashboard/image-view/image-view.component';
import { CustomSelectDropdownComponent } from '../custom-select-dropdown/custom-select-dropdown.component';
import { CustomDatePickerComponent } from '../custom-date-picker/custom-date-picker.component';
import { SharedService } from 'src/app/services/common-services/shared.service';
@Component({
  selector: 'app-lc-list',
  templateUrl: './lc-list.component.html',
  styleUrls: ['./lc-list.component.scss'],
})
export class LcListComponent implements OnInit {
  userInfo: any;
  pageNumber = 1;
  pageSize = 50;
  platformType: number;
  tableHide = true;
  validationMsg: any;
  localConvList = [];
  isPageLoad = true;
  showLoading = true;
  showFilter: boolean;
  dropdownFlag = false;
  hideColumns = [];
  indeterminateState: boolean;
  indeterminateLcState: boolean;
  checkAll: boolean;
  showSearch = false;
  selectedExpense = [];
  showSummaryLoading = true;
  expBtns = false;
  isApicallinProgress = false;
  selectedLc: any;
  searchlctext: string;
  downloadBtn = false;
  minPrice = 0;
  maxPrice = 0;
  statusArray = [];
  buttonDisabled = false;
  maxDate: any;
  minDate: any;
  currentday: any;
  frmDate: any;
  tDate: any;
  amount: any;
  statusState: boolean;
  checkAllStatus: any;
  statusList = [];
  selectedStatus = [];
  options: any;
  isFirstOpen = true;
  isSecondOpen = true;
  dummyList = [1, 2, 3];
  categoryList: any;
  categorytempList: any;
  checkAllCat: any;
  checkAllDup: any;
  selectedcategory = [];
  categoryState: boolean;
  expenseAndReportModel = new ExpenseAndReportModel();
  filterModal = new FilterModal();
  ExpenseorReport: string;
  segmentModel = "draft";
  draft = true;
  totalRecords: any;
  isExpenseCreated: boolean;
  minAndMax: any;
  showExpList = true;
  showExpFilter = false;
  selectedReport = [];
  downloadModel = new DownloadModel();
  reportDraftArray = [];
  hideNewRepBtn = true;
  buttonSubmit = false;
  nextvalue: any;
  totalAmt: any;
  editreport: any;
  mobMaxDate: any;
  private SearchListText: Subject<string> = new Subject();
  searchExpense: string;
  downloadTime: any;
  downloadActionPoolCount = 0;
  modalRef: any;
  lcDupArray = [];
  addExpenseHide = true;
  addExpenseListHide = false;
  showExpSearch = false;
  showExpDetail = false;
  showMobileDetails: boolean = true;
  status: string;
  selectedDupReport = [];
  expSummary: any = "expense";
  @ViewChild('slides', { static: false }) slider: IonSlides;
  @ViewChild("downloadLoader", { static: false }) private downloadLoader;
  duplicatePage = false;
  public manualRefresh = new Subject<void>();
  proofid: any;
  downloadProof: any;
  imgNum = 1;
  getExpenseModels = new GetExpenseModels();
  lcAttachments = [];
  proofList: any;
  openExpense: any;
  maxDateStr: any = moment(new Date()).format('YYYY-MM-DD');
  minDateStr: any = moment(new Date().getTime()).subtract(2000, 'years').format('YYYY-MM-DD');
  sharedSubsc: any;
  isUserToggle = true;


  constructor(private router: NavController, private title: Title, private commonService: CommonService,
    private platformService: PlatformService, private datechangeService: DatechangeService, private expenseService: ExpensesService, private zone: NgZone, public alertController: AlertController,
    public toastr: ToastrService, private modalService: BsModalService, private translate: TranslateService, private reportsService: ReportsService,
    private popoverController: PopoverController, private lcService: LcService, private changeDetectorRef: ChangeDetectorRef, public modalController: ModalController,
    public actionSheetController: ActionSheetController, private sharedService: SharedService) {
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  }

  ngOnInit() {
    this.showLoading = false;
    this.isPageLoad = false;
    if ((this.userInfo.roleId == 2 || this.userInfo.roleId == 3) && JSON.parse(sessionStorage.getItem('isadmin'))) {
      this.hideNewRepBtn = false
    }
    this.getStatusList();

    this.SearchListText.pipe(
      debounceTime(500),
      distinctUntilChanged()).subscribe((term: string) => {
        this.ExpenseorReport = term;
        this.showLoading = true;
        this.getExpenseList(false);
      });

    this.sharedSubsc = this.sharedService.getUserToggle().subscribe((val) => {
      this.isUserToggle = val;
    });
  }

  ionViewWillEnter() {
    this.pageNumber = 1;
    this.platformType = this.platformService.getPlatformType();
    this.getExpenseList(true);
    this.validationMsg = AppConfig;
    this.tableHide = true;
    let applicationTitle = this.userInfo.applicationTitle;
    if(applicationTitle){
        this.title.setTitle('Local Conveyance ' + ' | ' + applicationTitle);
    }else{ 
     this.title.setTitle("Local Conveyance | NoExpenses");
    }

    let val = JSON.parse(sessionStorage.getItem("fromPathInfo"));
    sessionStorage.setItem("fromPathInfo", null);
    if (val) {
      if (val["expenseId"] && val["expenseGUID"] && val["status"]) {
        this.showExpenseDetail(val);
      }
    }
  }

  ngOnDestroy() {
    this.resetSubscriptions();
  }

  ionViewWillLeave() {
    this.resetSubscriptions();
  }

  resetSubscriptions() {
    this.sharedService.unsubscribeServic(this.sharedSubsc);
  }

  getExpenseList(isPageLoad, event?) {
    this.isPageLoad = isPageLoad;
    if (this.pageNumber == 1) {
      this.showLoading = true;
      this.localConvList = [];
    }
    this.expenseAndReportModel.isAdminView = JSON.parse(sessionStorage.getItem('isadmin'));
    if ((JSON.parse(sessionStorage.getItem('isadmin')) == true) && (this.userInfo.roleId == 2)) {
      this.expenseAndReportModel.viewType = this.userInfo.roleId;
    } else {
      this.expenseAndReportModel.viewType = 1;
    }
    this.expenseAndReportModel.recordType = 3;
    this.expenseAndReportModel.legalEntityId = this.userInfo.legalEntityId;
    this.expenseAndReportModel.legalEntityGUID = this.userInfo.legalEntityGuId;
    this.expenseAndReportModel.userId = this.userInfo.userId;
    this.expenseAndReportModel.userGUID = this.userInfo.userGuId;
    this.expenseAndReportModel.pageNumber = this.pageNumber;
    this.expenseAndReportModel.filterBy = this.filterModal;
    this.expenseAndReportModel.filterBy.searchText = this.ExpenseorReport;
    this.expenseAndReportModel.filterBy.isDraft = this.platformType == 2 ? this.draft : null;
    this.expenseAndReportModel.pageSize = this.pageSize;
    this.isApicallinProgress = true;
    this.expenseService
      .getExpenseList(this.expenseAndReportModel)
      .subscribe((res) => {

        if (!res.isError && res.errorCode === 0) {
          if (this.pageNumber == 1) {
            this.localConvList = [];
          }
          this.showLoading = false;
          this.totalRecords = res.payload.expensesCount;
          this.isExpenseCreated = res.payload.isExpenseCreated;
          this.minAndMax = res.payload;
          this.reportDraftArray = [];
          this.selectedReport = [];
          this.selectedStatus = [];
          this.selectedcategory = [];
          this.indeterminateState = false;
          this.changeOptions(
            res.payload.expenseMinAmount,
            res.payload.expenseMaxAmount
          );
          const listarray = res.payload.expenseResponseList;
          this.localConvList = this.localConvList.concat((listarray.map((list) => new ExpenseListModel(list))));
          if (
            this.pageNumber === 1 &&
            this.isPageLoad === true &&
            this.isExpenseCreated === true
          ) {
            this.isPageLoad = false;
          }
          this.returnExpSegment();

          if (event) {
            event.target.complete();
          }
          if (res.payload.expenseResponseList.length < this.pageSize && event) {
            event.target.disabled = true;
          }
          this.isApicallinProgress = false;
        } else {
          this.showLoading = false;
          this.isApicallinProgress = false;
          this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
          if (event) {
            event.target.disabled = true;
          }
        }
      });

  }

  getStatusList() {
    const type = { type: +1 };
    this.commonService.statusList(type).subscribe((res) => {
      if (!res.isError && res.errorCode === 0) {
        const listarray = res.payload;
        this.statusList = listarray.map((list) => new StatusListModel(list));
      } else if (res.isError === true) {
        this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
      }
    });
  }

  returnExpSegment() {
    this.showExpFilter = false;
    this.showExpList = true;
  }

  showFiltersection() {
    this.showFilter = !this.showFilter;
    this.dropdownFlag = false;
  }

  editLcDetail(list?, duplicate?) {
    if (list) {
      this.selectedLc = list
    }

    let navInfo = {
      url: '/localconveyance',
      duplicate: duplicate ? duplicate : false,
      expenseId: this.selectedLc ? this.selectedLc?.expenseId : null,
      expenseGUID: this.selectedLc ? this.selectedLc?.expenseGUID : null,
      status: this.selectedLc ? this.selectedLc?.status : null,
      statusDescription: this.selectedLc ? this.selectedLc?.statusDescription : null,
      claimId: this.selectedLc ? this.selectedLc?.claimId : null,
      claimGuid: this.selectedLc ? this.selectedLc?.claimGuid : null,
      recordType: this.selectedLc ? this.selectedLc?.recordType : null,
    };

    sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
    this.router.navigateRoot(['new-lc'])
  }

  toggleColumns(e, val: string) {
    if (e.target.checked === true) {
      this.hideColumns.push(val);
    } else {
      const index = this.hideColumns.indexOf(val);
      if (index > -1) {
        this.hideColumns.splice(index, 1);
      }
    }
  }

  showhideColumns(val: string) {
    let column = true;
    if (this.hideColumns.indexOf(val) > -1) {
      column = false;
    }
    return column;
  }

  onsearchExpense(val) {
    this.ExpenseorReport = val.target.value;
    this.checkAll = false;
    if (this.ExpenseorReport?.length >= 0) {
      this.resetPaging();
      this.SearchListText.next(this.ExpenseorReport);
    }
    if (this.searchExpense === '') {
      this.resetPaging();
      this.getExpenseList(false);
    }
    this.expenseAndReportModel.filterBy.searchText = this.ExpenseorReport;
  }

  clearSearch() {
    this.searchExpense = "";
    this.ExpenseorReport = "";
    if (this.expenseAndReportModel.filterBy.searchText?.length >= 3) {
      this.getExpenseList(false);
    }
  }

  searchbar() {
    this.showSearch = !this.showSearch;
  }

  showExpenseDetail(list) {
    this.selectAllexpenses();
    this.editreport = list;
    this.indeterminateState = false;
    this.selectedExpense = [];
    this.tableHide = false;
    this.showSummaryLoading = true;
    this.getExpenseSumary(list);
    this.selectedLc = list;
    if (list.status === 1) {
      this.expBtns = true;
    } else {
      this.expBtns = false;
    }
    const reportArry = [];
    reportArry.push(list.claimId);
    this.removeReportModal.claimIds = reportArry;
    const expDeleteArry = [];
    expDeleteArry.push(list.expenseId);
    this.deleteExpenses.expenseIds = expDeleteArry;
    if (this.platformType == 1) {
      this.nextvalue = this.localConvList.indexOf(list);
    }

  }

  loadMore(event) {
    if (!this.isApicallinProgress) {
      if (this.localConvList.length < this.pageSize && event) {
        event.target.disabled = true;
      } else {
        this.pageNumber++;
        this.selectAllexpenses(event);
        //   this.getExpenseList(false, event);
      }
    }
  }

  selectAllexpenses(event?) {
    setTimeout(() => {
      this.localConvList.forEach((item) => {
        item.isSelected = event ? false : this.checkAll;
      });
    });
  }

  selectAllDupLc(event?) {
    setTimeout(() => {
      this.lcDupArray.forEach((item) => {
        item.isSelected = event ? false : this.checkAllDup;
      });
    });
  }

  selectSingleDup(list, e) {
    list.isSelected = e.target.checked;
    if (list.isSelected) {
      this.selectedDupReport.push(list);
    } else {
      let index = _.indexOf(this.selectedDupReport, list.localConveyanceId);
      this.selectedDupReport.splice(index, 1);
    }

    if (
      this.lcDupArray.length != this.selectedDupReport.length &&
      this.selectedDupReport.length > 0
    ) {
      this.checkAllDup = false;
      this.indeterminateLcState = true;
    } else if (this.selectedDupReport.length == 0) {
      this.checkAllDup = false;
      this.indeterminateLcState = false;
    } else {
      this.checkAllDup = true;
      this.indeterminateLcState = false;
    }
  }
  removeReportModal = new RemoveReportModal();
  selectSingleLC(list, e) {
    list.isSelected = e.target.checked;
    if (list.isSelected) {
      this.selectedReport.push(list.claimId);
    } else {
      let index = _.indexOf(this.selectedReport, list.claimId);
      this.selectedReport.splice(index, 1);
    }

    this.downloadModel.referenceIds = this.selectedReport.toString();
    if (list.isSelected && list.status == 1) {
      this.reportDraftArray.push(list.claimId);
      this.selectedExpense.push(list.expenseId)
      this.removeReportModal.claimIds = this.reportDraftArray;
    } else {
      let index = _.indexOf(this.reportDraftArray, list.claimId);
      this.reportDraftArray.splice(index, 1);
      let index2 = _.indexOf(this.selectedExpense, list.expenseId);
      this.selectedExpense.splice(index2, 1);
    }
    if (
      this.localConvList.length != this.selectedReport.length &&
      this.selectedReport.length > 0
    ) {
      this.checkAll = false;
      this.indeterminateState = true;
    } else if (this.selectedReport.length == 0) {
      this.checkAll = false;
      this.indeterminateState = false;
    } else {
      this.checkAll = true;
      this.indeterminateState = false;
    }
    this.deleteExpenses.expenseIds = this.selectedExpense;
    this.removeReportModal.claimIds = this.selectedReport;
  }

  newLc() {
    let navInfo = {
      url: '/localconveyance'
    };
    sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
    this.router.navigateRoot(['new-lc'])
  }

  resetDates() {
    this.frmDate = undefined;
    this.tDate = undefined;
    this.filterModal.fromDate = undefined;
    this.filterModal.toDate = undefined;
    this.minDate = undefined;
    this.maxDate = new Date();
  }

  getfromDate(e) {
    this.frmDate = e.target.value;
    const date = this.datechangeService.convertCalendarToAPI(e.target.value);
    this.filterModal.fromDate = date;
    this.minDate = new Date(this.frmDate);
  }

  gettodate(e) {
    this.tDate = e.target.value;
    const date = this.datechangeService.convertCalendarToAPI(e.target.value);
    this.filterModal.toDate = date;
    this.maxDate = new Date(this.tDate);

  }

  fromDateMobile(e) {
    if (e.detail.value !== "") {
      this.frmDate = e.detail.value;
      this.filterModal.fromDate = moment(new Date(this.frmDate)).format(Constants.mobileDateFormat);
      this.minDate = moment(new Date(this.frmDate)).format(Constants.mobileCalenderFormat);
    } else {
      this.filterModal.fromDate = undefined;
    }
  }

  toDateMobile(e) {
    if (e.detail.value !== "") {
      this.tDate = e.detail.value;
      this.filterModal.toDate = moment(new Date(this.tDate)).format(Constants.mobileDateFormat);
      this.mobMaxDate = moment(new Date(this.tDate)).format(Constants.mobileCalenderFormat);
    } else {
      this.filterModal.fromDate = undefined;
    }

  }

  resetFilters(list) {
    list.forEach((item) => {
      item.isSelected = false;
    });
  }

  applyfilter() {
    this.showFilter = false;
    this.resetPaging();
    if (this.frmDate !== undefined && this.tDate === undefined) {
      this.tDate = this.mobMaxDate;
      this.filterModal.toDate = moment(new Date()).format(Constants.mobileDateFormat);
    }
    if (this.frmDate === undefined && this.tDate !== "") {
      this.frmDate = this.tDate;
      this.filterModal.fromDate = this.tDate;
    }
    // this.createFilters();
    this.filterModal.fromDate = this.frmDate ? moment(new Date(this.frmDate)).format(Constants.mobileDateFormat) : undefined;
    this.filterModal.toDate = this.tDate ? moment(new Date(this.tDate)).format(Constants.mobileDateFormat) : undefined;

    this.getExpenseList(false);
    this.returnExpSegment();
    // this.maxDate = new Date();

    //  this.mobMaxDate = moment(new Date()).format(Constants.mobileCalenderFormat);
  }


  resetPaging() {
    this.pageNumber = 1;
    this.localConvList = [];
    this.localConvList.length = 0;
  }

  selectSingleStatus(list) {
    if (list.isSelected) {
      this.selectedStatus.push(list.statusId);
      this.filterModal.statusIds = this.selectedStatus;
    } else {
      const index = _.indexOf(this.selectedStatus, list.statusId);
      this.selectedStatus.splice(index, 1);
      this.filterModal.statusIds = this.selectedStatus;
    }
    this.filterModal.statusIds = this.selectedStatus;
    if (
      this.statusList.length !== this.selectedStatus.length &&
      this.selectedStatus.length > 0
    ) {
      this.checkAllStatus = false;
      this.statusState = true;
    } else if (this.selectedStatus.length === 0) {
      this.checkAllStatus = false;
      this.statusState = false;
    } else {
      this.checkAllStatus = true;
      this.statusState = false;
    }
  }

  resetAmount() {
    this.filterModal.minPrice = this.minAndMax.expenseMinAmount;
    this.filterModal.maxPrice = this.minAndMax.expenseMaxAmount;
  }

  priceSlider(changeContext: ChangeContext) {
    this.filterModal.minPrice = changeContext.value;
    this.filterModal.maxPrice = changeContext.highValue;
  }

  backToLCList() {
    this.tableHide = true;
  }
  selectAllStatus() {
    setTimeout(() => {
      this.statusList.forEach((item) => {
        item.isSelected = this.checkAllStatus;
      });
    });
  }
  selectSingleCategory(list) {
    if (list.isSelected) {
      this.selectedcategory.push(list.categoryId);
    } else {
      const index = _.indexOf(this.selectedcategory, list.categoryId);
      this.selectedcategory.splice(index, 1);
    }
    this.filterModal.categoryIds = this.selectedcategory;
    if (
      this.categoryList.length !== this.selectedcategory.length &&
      this.selectedcategory.length > 0
    ) {
      this.checkAllCat = false;
      this.categoryState = true;
    } else if (this.selectedcategory.length === 0) {
      this.checkAllCat = false;
      this.categoryState = false;
    } else {
      this.checkAllCat = true;
      this.categoryState = false;
    }

  }

  selectAllCategory() {
    setTimeout(() => {
      this.categoryList.forEach((item) => {
        item.isSelected = this.checkAllCat;
      });
    });
  }
  CategoryMerchantModel = new CategoryAndMerchantModel();
  getCategorylist() {
    this.CategoryMerchantModel.companyId = this.userInfo.companyId;
    this.CategoryMerchantModel.companyGUID = this.userInfo.companyGuId;
    this.CategoryMerchantModel.legalEntityId = this.userInfo.legalEntityId;
    this.CategoryMerchantModel.legalEntityGUID = this.userInfo.legalEntityGuId;
    this.CategoryMerchantModel.status = 1;
    this.CategoryMerchantModel.categoryType = null;
    this.commonService
      .getCategorylist(this.CategoryMerchantModel)
      .subscribe((res) => {
        if (!res.isError && res.errorCode === 0) {
          const listarray = res.payload.categoryList;
          this.categoryList = listarray.map((list) => new CategoryList(list));
        } else if (res.isError === true) {
          this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
        }
      });
  }

  changeOptions(minValue, maxValue) {
    const newOptions: Options = Object.assign({}, this.options);
    newOptions.floor = minValue;
    newOptions.ceil = maxValue;
    this.minPrice = minValue;
    this.maxPrice = maxValue;
    this.options = newOptions;
  }

  submitReport() {
    this.reportSubmitPopUp();
  }
  async presentAlert() {
    const alert = await this.alertController.create({
      mode: "md",
      cssClass: 'my-custom-class text-center submitOk',
      message: `<img src="assets/svg/success-icon.svg" class="reportsuccess"/> <br>${this.translate.instant('alerts.lcsuccess')}`,
      buttons: [{
        text: this.translate.instant('common.ok'),
        cssClass: 'primary text-center',
      }],
    });

    await alert.present();
  }

  async reportSubmitPopUp() {
    const alert = await this.alertController.create({
      mode: "md",
      /*  message: `<b>${this.translate.instant('alerts.submitto')} <hr class="mt-1"> ${this.userInfo?.reportingManagerId === null ? `` : `[${this.userInfo?.reportingManagerId}]` }  ${this.userInfo?.reportingManager ? this.userInfo?.reportingManager : this.translate.instant('alerts.manager')} </b>?<br>
        <small>${this.userInfo?.reportingManagerEmail ? this.userInfo?.reportingManagerEmail : '' }</small>
        <hr class="mt-2 mb-3">`, */
      message: this.translate.instant('alerts.confirmSubmit'),
      backdropDismiss: false,
      buttons: [
        {
          text: this.translate.instant('common.cancel'),
          role: "cancel",
          handler: () => { },
        },
        {
          text: this.translate.instant('common.submit'),
          handler: () => {
            this.zone.run(() => {
              this.removeReportModal.userId = this.userInfo.userId;
              this.removeReportModal.userGUID = this.userInfo.userGuId;
              this.removeReportModal.legalEntityId = this.userInfo.legalEntityId;
              this.removeReportModal.legalEntityGUID = this.userInfo.legalEntityGuId;
              this.removeReportModal.recordType = 3;
              this.buttonSubmit = true;
              this.reportsService
                .draftReport(this.removeReportModal)
                .subscribe((res) => {
                  this.buttonSubmit = false;
                  if (!res.isError && res.errorCode == 0) {
                    this.backToLCList();
                    this.getExpenseList(true);
                    this.toastr.success(res.payload, this.translate.instant('alerts.succes'));
                  } else if (res.isError == true) {
                    this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
                  }
                });
            });
          },
        },
      ],
    });

    await alert.present();
  }
  submitSingleReport(claimId) {
    this.subSingReportalet(claimId);
  }
  async subSingReportalet(claimId) {
    const alert = await this.alertController.create({
      mode: "md",
      message: this.translate.instant('alerts.confirmSubmit'),
      backdropDismiss: false,
      buttons: [
        {
          text: this.translate.instant('common.cancel'),
          role: "cancel",
          handler: () => { },
        },
        {
          text: this.translate.instant('common.submit'),
          handler: () => {
            this.zone.run(() => {
              this.selectedReport.push(claimId);
              this.removeReportModal.userId = this.userInfo.userId;
              this.removeReportModal.userGUID = this.userInfo.userGuId;
              this.removeReportModal.legalEntityId = this.userInfo.legalEntityId;
              this.removeReportModal.legalEntityGUID = this.userInfo.legalEntityGuId;
              this.removeReportModal.claimIds = this.selectedReport;
              this.removeReportModal.recordType = 3;
              this.reportsService.draftReport(this.removeReportModal).subscribe((res) => {
                if (!res.isError && res.errorCode == 0) {
                  this.reportDraftArray = [];
                  this.selectedReport = [];
                  this.indeterminateState = false;
                  this.checkAll = false;
                  this.backToLCList();
                  this.getExpenseList(true);
                  this.toastr.success(res.payload, this.translate.instant('alerts.succes'));
                } else if (res.isError == true) {
                  this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
                }
              });
            });
          },
        },
      ],
    });

    await alert.present();
  }


  deleteExpenses = new DeleteExpenses()
  async removeExpensePopUp(list?) {

    if (list) {
      const expDeleteArry = [];
      expDeleteArry.push(list.expenseId);
      this.deleteExpenses.expenseIds = expDeleteArry;
    }

    const alert = await this.alertController.create({
      mode: "md",
      message: this.translate.instant('alerts.confmlcdelete'),
      backdropDismiss: false,
      buttons: [
        {
          text: this.translate.instant('common.cancel'),
          role: "cancel",
        },
        {
          text: this.translate.instant('common.delete'),
          handler: () => {
            this.zone.run(() => {
              this.buttonDisabled = true;
              this.deleteExpenses.legalEntityId = this.userInfo.legalEntityId;
              this.deleteExpenses.legalEntityGUID = this.userInfo.legalEntityGuId;
              this.deleteExpenses.userId = this.userInfo.userId;
              this.deleteExpenses.userGUID = this.userInfo.userGuId;
              this.deleteExpenses.recordType = 3;
              this.lcService.deleteLcApi(this.deleteExpenses)
                .subscribe((data) => {
                  if (!data.isError && data.errorCode === 0) {
                    this.buttonDisabled = false;
                    this.toastr.success(data?.payload, this.translate.instant('alerts.succes'));
                    this.backToLCList();
                    this.getExpenseList(true);
                    /* if (!this.tableHide) {
                       this.showSummaryLoading = true;
                       this.localConvList.splice(this.nextvalue,1)
                      // this.getExpenseSumary(this.expenseList[0]);
                       this.getExpenseSumary( this.localConvList[this.nextvalue]);
                       this.showExpenseDetail( this.localConvList[this.nextvalue]);
                     } else{
                       this.getExpenseList(false)
                     }
 
                     this.selectedExpense = [];
                     this.indeterminateState = false;
                     this.checkAll = false;*/

                  } else if (data.isError && data.errorCode === 2) {
                    this.buttonDisabled = false;
                    this.toastr.error(data.errorMessage, this.translate.instant('alerts.error'));
                  }
                });
            });

          },

        },
      ],
    });
    await alert.present();
  }

  getExpenseSumary(list) {
    this.getExpenseModels.expenseId = list.expenseId;
    this.getExpenseModels.expenseGUID = list.expenseGUID;
    this.getExpenseModels.userId = this.userInfo.userId;
    this.getExpenseModels.userGUID = this.userInfo.userGuId;
    this.getExpenseModels.legalEntityId = this.userInfo.legalEntityId;
    this.getExpenseModels.legalEntityGUID = this.userInfo.legalEntityGuId;
    this.expenseService
      .expenseSummary(this.getExpenseModels)
      .subscribe((res) => {
        if (!res.isError && res.errorCode === 0) {
          const listarray = res.payload;
          this.selectedLc = new ExpenseListModel(listarray);

          if (this.selectedLc?.status != 1 && this.expBtns) {
            this.expBtns = false;
          }
          //  this.showaddNewMileage = true;

          var dummyLcAttachmts = [];
          this.lcAttachments = [];
          this.selectedLc.localConveyanceList.forEach((item) => {
            if (item?.attachmentURL) {
              dummyLcAttachmts.push(item?.attachmentURL);
            }
          });

          dummyLcAttachmts.forEach((url) => {
            let attachmtModl = new ExpenseAttachmentsModel();
            attachmtModl.url = url;
            this.lcAttachments.push(attachmtModl);
          });

          this.showSummaryLoading = false;
          this.totalAmt = this.selectedLc.expenseItems
            .map((t) => t.amount)
            .reduce((a, value) => a + value, 0);
        } else if (res.isError && res.errorCode === 2) {
          this.showSummaryLoading = false;
        }
      });
  }
 
  bulkDownload(list?) {
    const config = {
      ignoreBackdropClick: true,
      class: "modal-md modal-dialog-centered",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(this.downloadLoader, config);
    this.downloadBtn = true;
    if (this.selectedExpense.length) {
      this.downloadModel.referenceIds = this.selectedExpense.toString();
    } else {
      this.downloadModel.referenceIds = null;
    }
    if (list) {
      this.downloadModel.referenceIds = list.expenseId;
      this.downloadBtn = false;
    }

    this.filterModal.isDraft = null;
    this.downloadModel.filterBy = this.filterModal;
    this.downloadModel.userId = this.userInfo.userId;
    this.downloadModel.userGUID = this.userInfo.userGuId;
    this.downloadModel.entityType = 1;
    this.downloadModel.recordType = 3;
    this.downloadModel.isAdminView = JSON.parse(sessionStorage.getItem('isadmin'));
    this.downloadModel.legalEntityId = this.userInfo.legalEntityId;
    this.downloadModel.legalEntityGUID = this.userInfo.legalEntityGuId;
    // this.downloadModel.viewType = this.userInfo.roleId;
    this.downloadModel.viewType = this.expenseAndReportModel.viewType;
    this.downloadModel.isApprover = false;
    this.commonService.downloadList(this.downloadModel).subscribe(res => {
      if (!res.error && res.payload) {
        this.downloadPoolingStart(res.payload, 3000)
        

      }
     
    })
  }
  downloadCancel() {
    this.modalRef.hide();
  }
  downloadPoolingStart(request, time) {
    this.downloadActionPoolCount++;
    this.downloadModel.jobId = request.jobId;
    this.downloadModel.jobGUID = request.jobGUID;
    this.commonService.downloadpoolStart(this.downloadModel).subscribe(res => {
      if (!res.isError && res.payload.status != 0 && this.downloadActionPoolCount < 20) {
        this.downloadTime = setTimeout(() => {
          this.downloadPoolingStart(res.payload, time);
        }, time);
      } else if (!res.isError && res.payload.status == 3) {
        this.downloadBtn = false;
        window.open(res['payload']['resultFileURL'], '_self');
        this.toastr.success(this.translate.instant('alerts.downloadsucces'), this.translate.instant('alerts.succes'));
        this.downloadActionPoolCount = 0;
        this.modalRef.hide();
      } else {
        this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
        this.downloadBtn = false;
        this.downloadActionPoolCount = 0;
        this.modalRef.hide();
      }
    })
  }

  opendupModal(duplicateModal: TemplateRef<any>, list) {
    this.lcDupArray = list.localConveyanceList
    const config = {
      ignoreBackdropClick: true,
      class: "modal-lg modal-dialog-centered",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(duplicateModal, config);

  }


  /* mobile */

  expFilter() {
    this.showExpFilter = true;
    this.showExpList = false;
    this.showExpDetail = false;
    this.showExpSearch = false;
    this.addExpenseHide = false;
    this.selectedExpense = [];
    this.showLoading = false;
  }

  returnExpense() {
    this.addExpenseHide = true;
    this.addExpenseListHide = false;
  }

  returnExpList() {
    this.showExpList = true;
    this.showExpSearch = false;
  }

  expenseSegment(e) {
    this.segmentModel = e.target.value;
    if (e.target.value === "draft") {
      this.draft = true;
      this.pageNumber = 1;
      this.getExpenseList(false);
      this.selectedcategory = [];
      this.selectedStatus = [];
      this.resetAll();
    } else {
      this.draft = false;
      this.pageNumber = 1;
      this.selectedExpense = [];
      this.getExpenseList(false);
      this.selectedcategory = [];
      this.selectedStatus = [];
      this.resetAll();
    }
  }

  resetAll() {
    this.ExpenseorReport = "";
    this.frmDate = undefined;
    this.tDate = null;
    this.filterModal.fromDate = null;
    this.filterModal.toDate = null;
    this.status = "";
    this.minDate = "";
    this.mobMaxDate = moment(new Date()).format(Constants.mobileCalenderFormat);
    this.resetAmount();
  }

  returnDasbord() {
    this.router.navigateRoot(["dashboard"]);
  }

  async openPopOver(ev: any) {
    let obj = [];
    obj = [
      {
        name: 'common.edit',
        action: 1,
        canDisabled: this.selectedLc.status == 1 ? false : true,
      },
      {
        name: 'expenses.duplicate',
        action: 2,
        canDisabled: (this.selectedLc.status == 4 || this.selectedLc.status == 5) ? true : false
      }
    ]

    const popover = await this.popoverController.create({
      component: CustomPopOverComponent,
      componentProps: {
        actionsObj: obj
      },
      cssClass: 'custom-popover',
      event: ev,
      translucent: true
    });
    popover.onDidDismiss().then(result => {
      if (result.data && result.role !== 'backdrop') {
        switch (result.data.action) {
          case 1:
            this.editLcDetail();
            break;
          case 2:
            this.dupMobileModal()
            break;
        }

      }
    });
    await popover.present();
  }

  dupMobileModal() {
    this.lcDupArray = this.selectedLc.localConveyanceList;
    this.duplicatePage = true;
    /*  show and hide mobile modal */

  }
  async segmentChanged($event: any) {
    this.expSummary = $event.detail.value;
    let index;
    if (this.expSummary === 'expense') {
      index = 0;
    } else if (this.expSummary === 'proofs') {
      index = 1;
    } else if (this.expSummary === 'history') {
      index = 2;
    } else if (this.expSummary === 'tripDetail') {
      index = 3;
    }
    this.focusSegment($event['srcElement']['children'][index]['id']);
    await this.slider.slideTo(index);
    await this.slider.update();
    this.changeDetectorRef.detectChanges();
  }

  async slideChanged() {
    const index = await this.slider.getActiveIndex();
    if (index === 0) {
      this.expSummary = 'expense';
    } else if (index === 1) {
      this.expSummary = 'proofs';
    } else if (index === 2) {
      this.expSummary = 'history';
    } else if (index === 3) {
      this.expSummary = 'tripDetail';
    }
    this.changeDetectorRef.detectChanges();
  }

  focusSegment(segmentId) {
    document.getElementById(segmentId).scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    });
  }

  createDuplLcs() {
    this.selectedDupReport.forEach(lc => {
      lc.localConveyanceId = null;
    });
    let navInfo = {
      url: '/localconveyance',
      selectedDupArray: this.selectedDupReport
    };
    sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
    this.router.navigateRoot(['new-lc']);
    this.modalRef.hide()
  }

  backToExpDetails() {
    this.duplicatePage = false;
  }

  openPreview(pic) {
    this.modalController
      .create({
        component: ImageViewComponent,
        componentProps: {
          photo: pic,
          frompage: 'expDetails',
        },
      })
      .then((modal) => modal.present());
  }

  resetBlur(eleInput: IonInput) {
    eleInput.getInputElement().then((ele) => ele.blur());
  }

  resetSinglefilter(type) {
    if (type) {
      switch (type) {
        case 1:
          this.frmDate = undefined;
          this.filterModal.fromDate = this.frmDate;
          this.minDate = undefined;
          this.maxDate = new Date();
          break;
        case 2:
          this.tDate = undefined;
          this.filterModal.toDate = this.tDate;
          this.minDate = undefined;
          this.maxDate = new Date();
          break;
        case 3:
          this.status = "";
          this.selectedStatus = [];
          this.filterModal.statusIds = this.selectedStatus;
          break;

      }
    }
  }

  async openModal(value: string, type: string, isCategory?: boolean) {
    const componentProps = this.getComponentProps(type);
    if (value && value !== '') {
      componentProps.value = this.filterModal[componentProps.setValueKey];
    }
    const searchItemModal = await this.modalController.create({
      component: CustomSelectDropdownComponent,
      componentProps,
      keyboardClose: false,
    });
    searchItemModal.onDidDismiss()
      .then((callback) => {
        if (callback && callback.data) {
          // TODO give onchange methods for status and category
          if (typeof callback.data !== 'string') {
            this[componentProps.listName] = callback.data;
            const arr = [];

            this.status = null;

            callback.data.forEach((obj: any) => {
              if (obj.isSelected) {
                arr.push(obj[componentProps.itemValueField]);
                if (this.status && this.status !== '') {
                  this.status = `${this.status} , ${obj[componentProps.itemTextField]}`;
                } else {
                  this.status = obj[componentProps.itemTextField];
                }

              }
            });
            if (arr && arr.length) {
              this.filterModal[componentProps.setValueKey] = arr;
            }
          }
        }
      });
    return await searchItemModal.present();
  }

  getComponentProps(type: string) {
    switch (type) {
      case 'status':
        return {
          title: 'Status',
          value: null,
          mainList: this.statusList,
          isMultiSelect: true,
          itemValueField: 'statusId',
          itemTextField: 'statusDescription',
          listName: `statusList`,
          setValueKey: 'statusIds'
        };
    }
  }

  async openDateSheet(isFromDate: boolean) {
    const modal = await this.modalController.create({
      component: CustomDatePickerComponent,
      componentProps: {
        selectedDate: isFromDate ? this.frmDate : this.tDate,
        fromDate: isFromDate ? new Date('1981-01-01') : this.frmDate,
        toDate: isFromDate ? this.tDate ? this.tDate : new Date() : new Date()
      },
      cssClass: 'custom-bottom-sheet'
    });
    modal.onDidDismiss().then(result => {
      if (result.role === 'changed' && result.data) {
        if (isFromDate) {
          this.frmDate = result.data.displayDate;
        } else {
          this.tDate = result.data.displayDate;
        }
      }
    });
    return await modal.present();
  }

  getFromDateCall(ev) {

    this.frmDate = moment(ev.detail.value).format("D MMM YYYY");
    this.filterModal.fromDate = moment(ev.detail.value).format("MM-DD-YYYY");
    this.minDate = moment(ev.detail.value).format("DD-MM-YYYY");
    this.minDateStr = moment(ev.detail.value).format("YYYY-MM-DD");
  }

  getToDateCall(ev) {
    this.tDate = moment(ev.detail.value).format("D MMM YYYY");
    this.filterModal.toDate = moment(ev.detail.value).format("MM-DD-YYYY");
    this.mobMaxDate = moment(ev.detail.value).format("DD-MM-YYYY");
  }


  reportexpsearch(e) {
    this.filterModal.searchText = e.detail.value;
  }

  modalClose() {
    this.modalRef.hide();
  }

  downloadImage() {
    if (!this.proofid) {
      this.proofid = this.selectedLc.localConveyanceList[0]?.localConveyanceId;
    }
    let attachObj = { attachmentId: null, localConveyanceId: this.proofid };
    this.expenseService.downloadAttachment(attachObj).subscribe(res => {
      if (!res.isError && res.errorCode === 0) {
        this.downloadProof = res['payload']['url'];
        window.open(this.downloadProof);
        this.toastr.success(this.translate.instant('alerts.downloadsucces'), this.translate.instant('alerts.succes'));
      } else {
        this.toastr.error(res.errorMessage, 'Error');
      }
    });

  }

  onSlide(item) {
    const imageIndex = parseInt(item.current.replace("slideId_", ""), 10);
    this.proofid = this.selectedLc.localConveyanceList[parseInt(item.current.replace("slideId_", ""), 10)]?.localConveyanceId;
    this.imgNum = imageIndex + 1;
  }

  openProofModal(proofPopup: TemplateRef<any>, list, lcIndex?) {
    if (lcIndex) {
      this.proofList = list;
      this.imgNum = 0;
      this.proofid = this.selectedLc.localConveyanceList[lcIndex - 1]?.localConveyanceId;
    } else {
      this.imgNum = 1;
      this.proofList = list;
    }
    const config = {
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-lg",
      keyboard: false,

    };
    this.modalRef = this.modalService.show(proofPopup, config);
  }

}
