import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-foreign-currency',
  templateUrl: './foreign-currency.component.html',
  styleUrls: ['./foreign-currency.component.scss'],
})
export class ForeignCurrencyComponent implements OnInit {
  fCurrencies: any = [];
  @Input() amount: any;
  @Input() originalIcon: any;
  @Input() originalCode: any;
  @Input() baseIcon: any;
  @Input() mr: any;

  constructor() {}

  ngOnInit() {
    this.fCurrencies = JSON.parse(sessionStorage.getItem('foreignCurrencies') || '[]');
  }

  getFC(exchangeRate, amount) {
    const result = this.strToNum(exchangeRate) * this.strToNum(amount);
    return parseFloat(result.toFixed(2));
  }

  strToNum(str) {
    return parseFloat(str?.toString().replace(/,/g, '') || 0);
  }

}
