import { Component, Input, NgZone, OnInit } from '@angular/core';
import { of, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { NavParams } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/services/common-services/common.service';
import { ExpenseModel } from 'src/app/models/Expenses/expenses.model';
declare var google: any;

@Component({
  selector: 'app-select-location',
  templateUrl: './select-location.component.html',
  styleUrls: ['./select-location.component.scss'],
})
export class SelectLocationComponent implements OnInit {
  isDomestic: any;
  @Input("from") backendValue;

  allGoogleMapCitiesList = [];
  sessionTokenEnable = true;
  sessionToken: any;
  requestType: any;
  allHotelCitiesList: any[];
  showcitySearchListSpinner: boolean = false;
  hotelSearchForm: FormGroup;
  geocoder: any;
  selectedCountryName: string;
  cityNameStr: any;
  showGoogleLocation: any;
  userInfo: any;
  searchText: any;
  fromCitiesList = [];

  constructor(
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private ngZone: NgZone, private commonService: CommonService,
    public navParams: NavParams
  ) { }

  ngOnInit() {
    this.showGoogleLocation = true;
    this.commonService.googlescriptAdd();
  }
  googleAutoCompleteSearch(val) {
    let searchText = val.currentTarget.value.trim();

    let domesticpredictions = [];

    if (searchText.length > 2) {
      this.showcitySearchListSpinner = true;
      const displaySuggestions = (predictions, status) => {
        // if (status == google.maps.places.PlacesServiceStatus.OK) {
        //   this.showcitySearchListSpinner = false;

        //   predictions.forEach((prediction) => {
        //     domesticpredictions.push(prediction);
        //   });
        //   var dcountry = this;
        //   dcountry.fromCitiesList = domesticpredictions;
      
        // };
      };
      if (this.sessionTokenEnable) {
        // this.sessionToken = new google.maps.places.AutocompleteSessionToken();
        this.sessionTokenEnable = false;
      }
      this.requestType = {
        input: searchText,
        types: ['(cities)'],
        componentRestrictions: { country: 'IN' },
        sessionToken: this.sessionToken,
      };

      // const service = new google.maps.places.AutocompleteService();
      // service.getPlacePredictions(this.requestType, displaySuggestions);
    }


  }

  selectLocation(location) {
    if (this.showGoogleLocation) {
      this.geocodeAddress(location.description, location.place_id,location);
    } else {
      this.modalCtrl.dismiss(location ? location : false);
    }

  }
  expenseModel = new ExpenseModel();
  geocodeAddress(location: string, place_id?,fulllocation?:any) {
    this.geocoder = new google.maps.Geocoder();
    this.geocoder.geocode({ 'address': location }, (results, status) => {
      this.ngZone.run(() => {
        if (status == google.maps.GeocoderStatus.OK) {

          this.expenseModel.location = results[0].address_components[0].long_name;
          if (this.navParams.get("from")) {
            console.log('full address and latitude and longitude 123:',fulllocation ,location);
            this.closeModal(fulllocation);
          } else {
            console.log('full address and latitude and longitude :',results );
            this.closeModal(results);
          }

        }
      });
    })
  }
  closeModal(data) {
    this.modalCtrl.dismiss(data);
  }

}
