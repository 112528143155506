import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Banner, BannerObject, GetBanner } from 'src/app/models/Settings/banner.model';
import { ExpensesService } from 'src/app/services/expenses.service';
import { SettingsService } from 'src/app/services/settings-services';

@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss'],
})
export class BannersComponent implements OnInit {
  @ViewChild("fileUpload") fileUploadInput: ElementRef;
  @ViewChild("fileUploadRe") fileUploadInputRe: ElementRef;

  showLoader: boolean = false;
  dummyList = [1, 2, 3];
  imageError: string;
  isImageSaved: boolean;
  cardImageBase64: string;
  bannerForm: FormGroup;
  serviceTypeList = [];
  iataGroupList = [];
  bannersObject = new BannerObject();
  getBanner = new GetBanner();
  userInfo: any
  bannerCss: boolean = true;
  buttonDisabled: boolean = false
  params;
  bannerObject = new BannerObject();
  setBanner = new Banner();
  banners = [];
  displayBannerForCorporate: boolean = false;
  isSpinnerbuttonDisabled: boolean = false;
  isImangeChange = [];
  removedBanners = [];
  selectedLegalEntity: any;

  constructor(private toaster: ToastrService, private formBuilder: FormBuilder, private route: Router, private translate: TranslateService,
    private activatedRoute: ActivatedRoute, private settingService: SettingsService, private expensesService: ExpensesService, private renderer: Renderer2,) { }


  ngOnInit() {
    this.params = this.activatedRoute.snapshot.url;
    if (this.params.length > 0 && this.params[0].path === "bannersLegalEntity") { this.bannerCss = false; }
    else this.bannerCss = true;
    this.userInfo = JSON.parse(sessionStorage.getItem("selectedLegalEntity"));
    this.selectedLegalEntity = JSON.parse(sessionStorage.getItem('selectedLegalEntity'));
    this.getBanners();
    this.bannerForm = this.formBuilder.group({})
    this.renderer.addClass(document.body, "banners");
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, "banners");
  }

  fileChangeEvent(event: any, index?, imageChange?) {
    if (!event.target.files) {
      return;
    }

    if (event.target.files.length !== 1) {
      return;
    }

    const file = event.target.files[0];
    if (
      file.type !== "image/jpeg" &&
      file.type !== "image/png" &&
      file.type != "image/jpg"
    ) {
      this.toaster.error("Selected Format Not Supported", "Error");
      return;
    }
    if (file.size > 1048576) {
      this.toaster.error("File exceeds the maximum upload limit of 1MB");
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      const imgBase64Path = reader.result;
      const imageBody = {
        "id": this.userInfo.companyId,
        "fileName": file.name,
        "fileUploadFor": this.bannerCss ? "COMPANY-BANNERS" : "LEGAL-ENTITY-BANNERS",
        "base64Image": imgBase64Path
      }
      if (imageChange == 'imageChange') { this.isImangeChange[index] = true; } else { this.isSpinnerbuttonDisabled = true; }
      this.expensesService.ocrDataPulling(imageBody).subscribe(fileUpload => {
        if (fileUpload.errorCode == 0 && !fileUpload.isError) {
          if (fileUpload.payload.uploadFlag) {
            this.toaster.success(fileUpload.payload.uploadMessage, "Success");
            if (imageChange == 'imageChange') {
              this.banners[index].url = fileUpload.payload.uploadUrl;
              this.banners[index].isDeleted = false;
            } else {
              this.banners.push({});
              this.bannerForm.addControl('url' + (this.banners.length - 1), new FormControl(null));
              this.bannerForm.controls['url' + (this.banners.length - 1)].setValue(null);
              this.bannerForm.addControl('redirectionURL' + (this.banners.length -1),new FormControl(null));
              this.bannerForm.controls['redirectionURL' + (this.banners.length -1)].setValue(null);
              this.banners[this.banners.length - 1].url = fileUpload.payload.uploadUrl;
              this.banners[this.banners.length - 1].isDeleted = false;
              this.isImangeChange[this.banners.length - 1] = false;
            }
          } else {
            this.toaster.error(fileUpload.payload.uploadMessage, "Error");
          }
        } else {
          this.toaster.error(
            "Details are not updated please try again...",
            "Error"
          );
        }
        this.isSpinnerbuttonDisabled = false;
        this.isImangeChange[index] = false;
      });



    }
  }

  onPaymentmodeChange(e) {
    e.value.find(val => val == "1")
  }



  getBanners() {
    this.showLoader = true;
    this.getBanner.legalEntityId = this.selectedLegalEntity?.legalEntityId || this.userInfo.legalEntityId,
      this.getBanner.legalEntityGUID = this.selectedLegalEntity?.legalEntityGuId || this.userInfo.legalEntityGuId

    this.settingService.getBanner(this.getBanner).subscribe(res => {
      if (res.errorCode == 0 && !res.isError) {
        this.banners = res.payload.banners;
        this.showLoader = false;
        this.bannerObject.showBanner = res.payload.showBanner;
        res.payload.banners.forEach((val: Banner, index) => {
          this.bannerForm.addControl('url' + index, new FormControl(null));
          this.bannerForm.controls["url" + index].setValue(val.url);
          this.bannerForm.addControl('redirectionURL' + index,new FormControl(null));
          this.bannerForm.controls["redirectionURL"+ index].setValue(val.redirectionURL);
          this.isImangeChange[index] = false;

        });
      }
    })
  }


  saveAndUpdateBanner() {
    this.bannerObject.legalEntityId = this.selectedLegalEntity?.legalEntityId || this.userInfo.legalEntityId;
    this.bannerObject.legalEntityGuId = this.selectedLegalEntity?.legalEntityGuId || this.userInfo.legalEntityGuId;
    this.buttonDisabled = true;
    this.banners.forEach((element, index) => {
      let setBanner = new Banner();
      setBanner.bannerId = element.bannerId ? element.bannerId : null;
      setBanner.url = element.url ? element.url : null;
      setBanner.redirectionURL =  this.bannerForm.get("redirectionURL" + index).value;
      if(setBanner.redirectionURL) {
        if (setBanner.redirectionURL && (setBanner.redirectionURL.indexOf("http://") != -1 || setBanner.redirectionURL.indexOf("https://") != -1)) setBanner.redirectionURL = setBanner.redirectionURL;
        else setBanner.redirectionURL = "http://"+setBanner.redirectionURL;
      }
      this.bannerObject.banners.push(setBanner);
    });
    this.removedBanners.forEach(element => {
      element.bannerId = element.bannerId ? element.bannerId : null;
      element.bannerUrlLink = element.redirectionURL ? element.redirectionURL : null;
    });
    this.bannerObject.banners = this.bannerObject.banners.concat(this.removedBanners);
    this.settingService.saveAndUpdateBanners(this.bannerObject).subscribe(res => {
      if (res.errorCode == 0 && !res.isError) {
        this.removedBanners = [];
        this.bannerObject = new BannerObject();
        this.bannerForm.reset();
        this.toaster.success(res.payload, this.translate.instant('alerts.succes'));
        this.getBanners();
        this.buttonDisabled = false;
      }
    });
  
  }
  validateDisable() {
    if (this.removedBanners.find(banner => banner.bannerId != null)) {
      return false;
    }else {
      return true;
    }
  }
  selectedImageUrl: string;
  viewImage(item) {
     window.open(item.url);
  }
  removeBanner(i: number) {
     this.banners[i].isDeleted =  true;
     this.banners.splice(i, 1)
  }

  navigateToSettings() {
    this.route.navigate(["/settingsMain"])
  }

  isDispaly(e) {
    this.displayBannerForCorporate = e.checked;
  }

  disableWhenImageChange() {
    let isDisable = false;
    if (this.isImangeChange.find(e => e == true)) {
      isDisable = true;
    }
    return isDisable;
  }
}
