export class AccountConfig {
  legalEntityId: number;
  legalEntityGUID: string;
  autogenrateExpenseId: boolean;
  expensePrefix: string;
  autogenrateReportId: boolean;
  reportPrefix: string;
  autogenrateAdvanceId: boolean;
  advancePrefix: string;
  allowSelfReportApprove: boolean;
  allowdeligation: boolean;
  baseLanguage: string;
  numberFormate: string;
  dateFormate: string;
  advanceExpensePrefix:string;
  localConveyancePrefix:string;
  autogenerateAdvanceExpenseId:boolean;
  pettyCashExpensePrefix:string;
  pettyCashRequestPrefix:string;
  pettyCashReportPrefix:string;
  travelPrefix:string;
  allowItemizedExpenses:boolean;
  showAdvanceOptions:any;
  viewAdvanceOptions:boolean =false;;
}

export class AccountConfigResponse {
  legalEntityId: number;
  legalEntityGUID: string;
  autogenrateExpenseId: boolean;
  expensePrefix: string;
  autogenrateReportId: boolean;
  reportPrefix: string;
  autogenrateAdvanceId: boolean;
  advancePrefix: string;
  advanceExpensePrefix:string;
  localConveyancePrefix:string;
  pettyCashExpensePrefix:string;
  pettyCashRequestPrefix:string;
  pettyCashReportPrefix:string
  autogenerateAdvanceExpenseId:boolean;
  allowSelfReportApprove: boolean;
  allowdeligation: boolean;
  baseLanguage: string;
  numberFormate: string;
  dateFormate: string;
  travelPrefix:string;
  allowItemizedExpenses:boolean;
  showAdvanceOptions:boolean;
  viewAdvanceOptions:boolean;
  constructor(list){
      this.legalEntityId = list.legalEntityId;
      this.legalEntityGUID = list.legalEntityGUID;
      this.autogenrateExpenseId = list.autogenrateExpenseId;
      this.expensePrefix = list.expensePrefix;
      this.autogenrateReportId = list.autogenrateReportId;
      this.autogenerateAdvanceExpenseId = list.autogenerateAdvanceExpenseId;
      this.reportPrefix = list.reportPrefix;
      this.autogenrateAdvanceId = list.autogenrateAdvanceId;
      this.advancePrefix = list.advancePrefix;
      this.advanceExpensePrefix = list.advanceExpensePrefix;
      this.localConveyancePrefix = list.localConveyancePrefix;
      this.pettyCashExpensePrefix = list.pettyCashExpensePrefix;
      this.pettyCashRequestPrefix = list.pettyCashRequestPrefix;
      this.pettyCashReportPrefix = list.pettyCashReportPrefix;
      this.allowSelfReportApprove = list.allowSelfReportApprove;
      this.allowdeligation = list.allowdeligation;
      this.baseLanguage = list.baseLanguage;
      this.numberFormate = list.numberFormate;
      this.dateFormate = list.dateFormate;
      this.travelPrefix = list.travelPrefix;
      this.allowItemizedExpenses=list.allowItemizedExpenses;
      this.showAdvanceOptions=list.showAdvanceOptions;
      this.viewAdvanceOptions=list.viewAdvanceOptions;
  }
}