import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NavController } from '@ionic/angular';
import { PlatformService } from 'src/app/services/platformbase.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SettingsService } from 'src/app/services/settings-services';
import { SharedService } from 'src/app/services/common-services/shared.service';
import { PolicyListModel, PolyUpdateModel } from 'src/app/models/Settings/policy.model';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-finance-policies',
  templateUrl: './finance-policies.component.html',
  styleUrls: ['./finance-policies.component.scss'],
})
export class FinancePoliciesComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  platformType: number;
  showLoader: boolean = true;
  currentPage = 0;
  pageSize = 25;
  tableDataList:any;
  dataSource: MatTableDataSource<any>;
  displyColumns: any[] = ['Policy Name', 'Description','Start Date','End Date', 'Status','disablestatus'];
  loadersList: any = [1,2,3,4];
  selectedIndex = -1;policyObj: any;
  statusMessage: string;
  modalRef: any;
  categoryListPageLimit: number[] = [5, 10, 25, 100];
  showingStatus: boolean = false;
  status: Array<number> = [];
  searchTxt:any;
  policiesList = [];
  polyEnablModel = new PolyUpdateModel();
  paginatorStatus:boolean = false;
  pageNumber: number = 1;
  userInfo: any;
  selectedLegalEntity: any;

  constructor(private platformService:PlatformService, 
    private router: NavController,private title: Title,
    private settingService: SettingsService, private modalService: BsModalService,
    public sharedService: SharedService) { }

  ngOnInit() {
    this.selectedLegalEntity = JSON.parse(sessionStorage.getItem('selectedLegalEntity'));
  }

  ionViewWillEnter(){
    this.platformType = this.platformService.getPlatformType();
    this.dataSource = new MatTableDataSource();
    this.financialPolAPI();
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    let applicationTitle = this.userInfo.applicationTitle;
    if(applicationTitle){
        this.title.setTitle('Finance Policies| Settings' + ' | ' + applicationTitle);
    }else{ 
      this.title.setTitle("Finance Policies| Settings | NoExpenses");
    }
  }

  financialPolAPI(){
    this.showLoader = true;
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    let modl = new PolicyListModel();
    modl.legalEntityId = this.selectedLegalEntity?.legalEntityId || userInfo?.legalEntityId;
    modl.legalEntityGUID = this.selectedLegalEntity?.legalEntityGuId || userInfo?.legalEntityGuId;
    modl.searchText = this.searchTxt;
    modl.pageNumber = this.pageNumber;
    modl.pageSize = 1000;
    modl.recordType = 1;
    this.settingService.financialPoliciesList(modl).subscribe(res=>{
       this.showLoader = false;
      if(!res.isError && res?.payload?.policyResponseDTOList){
        if(this.paginatorStatus){
          let listitems = res?.payload?.policyResponseDTOList;
          listitems.forEach(element => {this.policiesList.push(element);});
          this.dataSource = new MatTableDataSource(this.policiesList);
          this.dataSource.paginator = this.paginator;
        }else{
          this.policiesList = res?.payload?.policyResponseDTOList;
          this.dataSource = new MatTableDataSource(this.policiesList);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;

          // if(this.pageNumber == 1){
          //   this.paginatorStatus = true;
          //   this.pageNumber++;
          //   this.financialPolAPI();
          // }
        }
        this.pageNumber != 2 ? this.paginatorStatus = false : '';
      }
    });
  }

  hideDropdown(){
    if (this.selectedIndex !== -1) {
      this.selectedIndex = -1
    }
  }

  addNewFinance(){
    let navInfo = {
      url:'settings/financepolicy',
      policyId:null,
      policyGUID:null
    };
    sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
    this.router.navigateRoot(['/settings/financepolicy/add']); 
  }

  toggleStatusDropdown(index,row?) { 
    if (this.selectedIndex != index)
      this.selectedIndex = index;
    else
      this.selectedIndex = -1;
  }

  handlePage(e) {
    console.log("page index :",e);
    this.currentPage = e.pageIndex;
    // this.pageSize = e.pageSize;
    if(!this.dataSource.paginator.hasNextPage()){
      this.paginatorStatus = true;
      this.pageNumber++;
      this.financialPolAPI();
    }
  }

  toggleStatus(data,content) {
    this.policyObj = data;
      this.modalRef = this.modalService.show(content,  {class: 'modal-md modal-dialog-centered'});
      switch(this.policyObj.statusId){
        case 1: this.statusMessage = "Are you sure you want to disable policy?";
        break;
        case 2: this.statusMessage = "Are you sure you want to enable policy?";
        break;
      }
    this.hideDropdown();
  }

  applyFilter(filterValue: string) { 
    this.searchTxt = filterValue;
    this.financialPolAPI();
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.policiesList = this.dataSource.filteredData;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  statusAction() {
    this.showingStatus = true;
    this.polyEnablModel.policyId = this.policyObj.policyId;
    this.polyEnablModel.policyGUID = this.policyObj.policyGUID;
    this.selectedIndex = -1;
    if (this.policyObj.statusId == 2) {  
      this.settingService.enablePolicy(JSON.stringify(this.polyEnablModel)).subscribe(data => {
        if (data.errorCode == 0) {
          this.policyObj.statusId = 1;
          this.showingStatus = false;
          this.closeWindow();
        }else{
          this.showingStatus = false;
          this.closeWindow();
        }
      });
    } else {
     
    
      this.settingService.disablePolicy(JSON.stringify(this.polyEnablModel)).subscribe(data => {
        if (data.errorCode == 0) {
          this.policyObj.statusId = 2;
          this.showingStatus = false;
          this.closeWindow();
        }else{
          this.showingStatus = false;
          this.closeWindow();
        }
      });

    }
  }

  closeWindow() {
    this.modalRef.hide();
  }

  editPolicy(pol:any,clone?) {
    let navInfo = {
      url:'settings/financepolicy',
      policyId:pol?.policyId,
      policyGUID:pol?.policyGUID,
      action:clone ? clone :''
    };
    sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
    this.router.navigateRoot(['/settings/financepolicy/add']);
  }

    /* api calls */

}
