import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UtcDate} from './utcDate.pipe';

@NgModule({
    declarations: [
        UtcDate
    ],
    exports: [UtcDate],
    imports: [
        CommonModule
    ]
})
export class MyPipesModule {
}
