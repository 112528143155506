export class getAnalyticsModel{
    userId:number;
	userGUID: string;
	legalEntityId: number;
	legalEntityGUID: string;
	recordType: number;
	fromDate:string;
	toDate:string;
	isAdminView:boolean;
	action:string;
	viewType:number;
	actionName : any;
}

export class AdvanceModel{
	isAdminView: boolean;
	userId: number;
	userGUID: string;
	legalEntityId: number
	legalEntityGUID: string;
	recordType: number;
}