import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  private apiKey: string = '';

  constructor(private http: HttpClient) { }

  loadGoogleMapsApi(userApiKey: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.apiKey) {
        this.apiKey = userApiKey;
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?libraries=places&key=${userApiKey}`;
        script.async = true;
        script.defer = true;
        script.onload = () => resolve();
        script.onerror = (error: any) => reject(error);
        document.body.appendChild(script);
      } else {
        resolve(); // API already loaded
      }
    });
  }

  getCompanyData(): Observable<any[]> {
    return this.http.get<any[]>('./assets/company-config.json');
  }
}
