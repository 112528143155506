import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { ToastrService } from 'ngx-toastr';
import { financeIntegration, financeIntegrationList } from 'src/app/models/Settings/finacial-integration.model';
import { hsnCodeIntegration, hsnIntegrationList } from 'src/app/models/Settings/hsn-code.model';
import { PlatformService } from 'src/app/services/platformbase.service';
import { SettingsService } from 'src/app/services/settings-services';

@Component({
  selector: 'app-add-hsn-code',
  templateUrl: './add-hsn-code.component.html',
  styleUrls: ['./add-hsn-code.component.scss'],
})
export class AddHsnCodeComponent implements OnInit {

  platformType: number;
  hsnCodeForm: FormGroup;
  displayTitle: string = "Add New";
  displayTitlle: string = "Add"
  hsnModel = new hsnCodeIntegration();
  showLoader: boolean = false;
  submitted: boolean = false;
  buttonDisabled: boolean = false;
  validationMsg: any;
  loaderList: any = [1, 2, 3];
  selectedLegalEntity: any;
  userInfo: any;
  selectedStatusId: number;

  constructor(
    private platformService: PlatformService,
    private router: NavController,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private settingsService: SettingsService,
    private toastr: ToastrService
  ) {
    this.hsnCodeForm = this.fb.group({
      hsnName: ["", Validators.required],
      hsncode: [""],
    });
  }

  ngOnInit() { }

  ionViewWillEnter() {
    this.platformType = this.platformService.getPlatformType();
    this.selectedLegalEntity = JSON.parse(sessionStorage.getItem('selectedLegalEntity'));
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.hsnModel.hsnId = this.activatedRoute.snapshot.params["id"];
    this.hsnModel.hsnGUID = this.activatedRoute.snapshot.params["guid"];
    this.selectedStatusId = this.activatedRoute.snapshot.params["statusId"];
    this.hsnModel.legalEntityGUID = this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId;
    this.hsnModel.legalEntityId = this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId;
    if (this.hsnModel.hsnId) {
      this.displayTitle = "Update";
      this.displayTitlle = "Update"
      this.getSingleHsnCode();
    }
  }

  getSingleHsnCode() {
    this.showLoader = true;
    this.settingsService.getHsnCode(this.hsnModel).subscribe((data) => {
      if (!data.isError) {
        this.showLoader = false;
        this.hsnModel = new hsnIntegrationList(data.payload);
      } else {
        this.toastr.error(data.errorMessage, "Error");
      }
    });
  }

  addorUpdatHsncode() {
    this.hsnModel.statusId = this.selectedStatusId;
    // this.hsnModel.isDefault=false,
    this.submitted = true;
    if (!this.hsnCodeForm.valid) {
      return;
    } else {
      this.buttonDisabled = true;
      this.settingsService.saveHsnCode(this.hsnModel).subscribe((data) => {
        if (!data.isError) {
          this.buttonDisabled = false;
          this.toastr.success(data.payload, 'Success');
          this.backToHsncode();
        } else {
          this.buttonDisabled = false;
          this.toastr.error(data.errorMessage, 'Error');
          this.backToHsncode();
        }
      });
    }
  }

  backToHsncode() {
    this.router.navigateRoot(["/settings/hsn-code"]);
  }

  get f() {
    return this.hsnCodeForm.controls;
  }

}
