import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingController, ModalController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CategoryAndMerchantModel, CategoryList } from 'src/app/models/Common/common.model';
import { stateListModel } from 'src/app/models/Expenses/expenses.model';
import { CommonService } from 'src/app/services/common-services/common.service';
import { ExpensesService } from 'src/app/services/expenses.service';
import { CustomSelectDropdownComponent } from '../custom-select-dropdown/custom-select-dropdown.component';

@Component({
  selector: 'app-mileage-defaults',
  templateUrl: './mileage-defaults.component.html',
  styleUrls: ['./mileage-defaults.component.scss'],
})
export class MileageDefaultsComponent implements OnInit {

  stateList: any;
  stateTempList: any;
  CategoryMerchantModel = new CategoryAndMerchantModel();
  categoryList: any;
  userInfo: any;
  milageCategoryList: any;
  milageCategoryListTemp: any;

  selectedState: any;
  selectedStateID: any;
  selectedCountry: any;
  selectedCountryID: any;
  selectedVehicle: any;
  selectedVehicleID: any;
  setDefault: boolean = true;
  canDisabled: boolean = true;
  isLoading = false;
  isStateShow: boolean = false;
  isCatShow: boolean = false;

  userPreferanceData: any;
  fromType: any;
  isFromPage: boolean = false;
  isCheckDisable: boolean = false; 
  mileageForm: FormGroup;
  submitted: boolean;
  @ViewChild("locationfocusOff", { static: false }) locationfocus: ElementRef;
  @ViewChild("vehiclefocusOff", { static: false }) vehiclefocus: ElementRef;

  constructor(private translate: TranslateService,
    private expenseService: ExpensesService, public modalController: ModalController,
    public navCtrl: NavController,
    public commonService: CommonService,
    public toastr: ToastrService,
    public formBuilder: FormBuilder,
    private loadingController: LoadingController) {
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.fromType = localStorage.getItem("fromUP");
    if(this.fromType == 'camera'){
      this.isFromPage = true;
      this.isCheckDisable = false;
    }else if (this.fromType == 'dashboard'){
      this.isCheckDisable = false;
      this.isFromPage = true;
    }else{
      this.isCheckDisable = true;
      this.isFromPage = false;
    }
  }

  ngOnInit() {
    this.getMilageStates();
    this.getUserPreferance();
    this.mileageForm = this.formBuilder.group({
      selectedState: ['', [Validators.required]],
      selectedVehicle: ['', [Validators.required]],

  });
  }
  
  get f() {
    return this.mileageForm.controls;
}
  returncamera() {
    if(this.isFromPage){
      if(this.fromType == 'camera'){
        this.navCtrl.navigateRoot(["camera"]);
      }else{
        this.navCtrl.navigateRoot(["dashboard"]);
      }
    }else{
      //this.navCtrl.navigateRoot(["dashboard"]);
      let typeVal = JSON.parse(sessionStorage.getItem("fc365"));
      if (typeVal) {
          this.navCtrl.navigateRoot(["moreoptions"]);
      } else {
          this.navCtrl.navigateRoot(["dashboard"]);
      }
    }
  }
  setDefaltsVal() {
    this.setDefault = !this.setDefault;
  }

  async openModal(value: string, type: string, isExpenseForm?: boolean) {
    const componentProps = this.getComponentProps(type);
    if (value && value !== "") {
      componentProps.value = value;
    }
    const searchItemModal = await this.modalController.create({
      component: CustomSelectDropdownComponent,
      componentProps,
      keyboardClose: false,
    });
    searchItemModal.onDidDismiss().then((callback) => {
      if (callback && callback.data) {
        if (typeof callback.data !== "string") {
          this[componentProps.listName] = callback.data;
          //console.log('selected value is',callback.data);
          callback.data.forEach((obj: any) => {
            if (type == 'location') {
              if (obj.isSelected) {
                this.selectedState = obj.stateName;
                this.selectedStateID = obj.stateId;
                this.selectedCountry = obj.countryName;
                this.selectedCountryID = obj.countryId
                this.selectedVehicle = '';
                this.selectedVehicleID = '';
                this.getmastCategorylist();
              }
            }
            if (type === "categoryName") {
              // this.selectCategoryMileageOption(obj);
              //  console.log('selected cat',obj);
              if (obj.isSelected) {
                this.selectedVehicle = obj.categoryName;
                this.selectedVehicleID = obj.categoryId;
              }
              if (this.selectedVehicle && this.selectedState) {
                this.canDisabled = false;
              }
            }

          });
        } else {
        }
      }
    });
    return await searchItemModal.present();
  }
  getComponentProps(type: string,) {
    switch (type) {
      case "categoryName":
        var catList = this.milageCategoryList;
        return {
          title: this.translate.instant("common.category"),
          value: null,
          mainList: catList,
          isMultiSelect: false,
          itemValueField: "categoryId",
          itemTextField: "categoryName",
          listName: `categoryList`,
        };

      case "location":
        return {
          title: 'Location',
          value: null,
          mainList: this.stateList,
          isMultiSelect: false,
          itemValueField: "stateId",
          itemTextField: "stateName",
          itemStateField: "countryId",
          listName: `location`,
        };

    }
  }

  getMilageStates() {
    // this.present();
    let requestObj = {
      countryId: null,
      legalEntityId: this.userInfo.legalEntityId,
      legalEntityGUID: this.userInfo.legalEntityGuId
    };
    this.expenseService.getMileageStatesList(requestObj).subscribe((data) => {
      this._dismiss();
      this.stateList = data.payload.mileageStateList;
      let listarray = data.payload.mileageStateList;
      this.stateTempList = listarray.map((list) => new stateListModel(list));
      this.stateList = listarray.map((list) => new stateListModel(list));
    });
  }

  getmastCategorylist() {
    // this.present();
    this.CategoryMerchantModel.legalEntityId = this.userInfo.legalEntityId;
    this.CategoryMerchantModel.legalEntityGUID = this.userInfo.legalEntityGuId;
    this.CategoryMerchantModel.locationId = null;
    this.CategoryMerchantModel.stateId = this.selectedStateID;
    this.CategoryMerchantModel.countryId = this.selectedCountryID;

    this.commonService
      .getCatgryMasterlist(this.CategoryMerchantModel)
      .subscribe((res) => {
        if (!res.isError && res.errorCode == 0) {
          this._dismiss();
          let listarray = res.payload;
          this.milageCategoryList = listarray.mileageCategoryList.map((list) => new CategoryList(list));
          this.milageCategoryListTemp = listarray.mileageCategoryList.map((list) => new CategoryList(list));
          if (this.selectedStateID && this.milageCategoryList.length == 0) {
            //this.milageCategoryListWarning = true;
          } else {
            // this.milageCategoryListWarning = false;
          }
          // this.merchantListForDuplicate();          
        } else if (res.isError == true) {
          this._dismiss();
          this.toastr.error(
            res.errorMessage,
            this.translate.instant("alerts.error")
          );
        }
      });
  }
  saveUserPreferance() {
    this.submitted = true;
    if (!this.mileageForm.valid) {
        return;
    } else {
    let requestObj = {
      id: this.userPreferanceData.id,
      legalEntityId: this.userInfo.legalEntityId,
      legalEntityGUID: this.userInfo.legalEntityGuId,
      userId: this.userInfo.userId,
      userGUID: this.userInfo.userGuId,
      mileageCountryId: this.selectedCountryID,
      mileageCountryName: this.selectedCountry,
      mileageStateId: this.selectedStateID,
      mileageStateName: this.selectedState,
      mileageCategoryId: this.selectedVehicleID,
      mileageCategoryName: this.selectedVehicle
    }
    this.commonService.saveUserPreferance(requestObj).subscribe((res) => {
      if (!res.isError && res.errorCode === 0) {
        // console.log('user preferance setting data is',res);
        if(this.isFromPage){
          this.navCtrl.navigateForward(['gpstracking']);
        }else{
          this.navCtrl.navigateForward(['moreoptions']);
          this.toastr.success('User Preferances Updated Successfully', "Sucess");
        }
      } else if (res.isError && res.errorCode === 2) {
        this.toastr.error(res.errorMessage, "Fail");
      }
    });
  }
  }

  async present() {
    this.isLoading = true;
    return await this.loadingController.create({
      // duration: 5000,
      message: 'Loading...'
    }).then(a => {
      a.present().then(() => {
        if (!this.isLoading) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
  }
  async _dismiss() {
    this.isLoading = false;
    return await this.loadingController.dismiss().then(() => console.log('dismissed'));
  }
  selectState() {
    this.isStateShow = !this.isStateShow;
  }
  selectStateName(obj) {
    this.isStateShow = false;
    this.selectedState = obj.stateName;
    this.selectedStateID = obj.stateId;
    this.selectedCountry = obj.countryName;
    this.selectedCountryID = obj.countryId
    this.selectedVehicle = '';
    this.selectedVehicleID = '';
    this.canDisabled = true;
    this.getmastCategorylist();
  }
  selectVehicle()
  {
    this.isCatShow = !this.isCatShow;
  }
  selectVehicleName(obj){
    this.isCatShow = false;
    this.selectedVehicle = obj.categoryName;
    this.selectedVehicleID = obj.categoryId;
  if (this.selectedVehicle && this.selectedState) {
    this.canDisabled = false;
  }
  }

  getUserPreferance() {
   // this.present();
    let requestObj = {
      userId: this.userInfo.userId,
      userGUID: this.userInfo.userGuId,
    }
    this.commonService.getUserPreferance(requestObj).subscribe((res) => {
      if (!res.isError && res.errorCode === 0) {
        this._dismiss();
        this.userPreferanceData = res.payload;
        this.selectedState = this.userPreferanceData.mileageStateName ? this.userPreferanceData.mileageStateName : this.userPreferanceData.mileageCountryName;
        this.selectedVehicle = this.userPreferanceData.mileageCategoryName;
        this.selectedStateID = this.userPreferanceData.mileageStateId;
        this.selectedCountryID = this.userPreferanceData.mileageCountryId;
        this.selectedVehicleID = this.userPreferanceData.mileageCategoryId;
        if(this.userPreferanceData.mileageCountryId){
          this.getmastCategorylist();
        }
        if (this.selectedVehicle && this.selectedState) {
          this.canDisabled = false;
        }
      } else if (res.isError && res.errorCode === 2) {
        this._dismiss();
        this.toastr.error(res.errorMessage, "Fail");
      }
    });
  }

  trackMileageCall() {
    if(this.setDefault){
      this.saveUserPreferance();
    }else{
      let localData = {
      mileageCountryId: this.selectedCountryID,
      mileageCountryName: this.selectedCountry,
      mileageStateId: this.selectedStateID,
      mileageStateName: this.selectedState,
      mileageCategoryId: this.selectedVehicleID,
      mileageCategoryName: this.selectedVehicle
      }
      localStorage.setItem('mileageDataLocal', JSON.stringify(localData));

      this.navCtrl.navigateForward(['gpstracking']);
    }
   
  }


  backToMore() {
    this.navCtrl.navigateRoot(["moreoptions"]);
  }

  onLocationSearch(val) {
    let searchText = val.currentTarget.value.trim();
    if (this.stateTempList != null) {
      if (searchText != "") {
        let filterdata = this.stateTempList.filter((data) => {
          if (
            data.stateName.toLowerCase().indexOf(searchText.toLowerCase()) >=
            0
          ) {
            return data;
          }
        });
        this.stateList = filterdata;
      } else {
        this.stateList = this.stateTempList;
      }
    }
  }

  showDropdown = false;
  showDropdownText: any;
  showSubMenu(text) {
    this.showDropdown = true;
    this.showDropdownText = text;
    setTimeout(() => {
      if(text == 'location') {
        this.locationfocus?.nativeElement.focus();
      } else if (text == 'vehicle') {
        this.vehiclefocus?.nativeElement.focus();
      }
    }, 100);
  }

  selectLocationOption(obj) {
    console.log(obj);
    this.selectedState = obj.stateName;
    this.selectedStateID = obj.stateId;
    this.selectedCountry = obj.countryName;
    this.selectedCountryID = obj.countryId
    this.selectedVehicle = '';
    this.selectedVehicleID = '';
    this.getmastCategorylist();
  }

  selectVehicleOption(obj) {
    this.selectedVehicle = obj.categoryName;
    this.selectedVehicleID = obj.categoryId;
  }

  outsideClickDropdown() {
    this.showDropdown = false;
    this.showDropdownText = '';
  }

  onVehicleSearch(val) {
    let searchText = val.currentTarget.value.trim();
    if (this.milageCategoryListTemp != null) {
      if (searchText != "") {
        let filterdata = this.milageCategoryListTemp.filter((data) => {
          if (
            data.categoryName.toLowerCase().indexOf(searchText.toLowerCase()) >=
            0
          ) {
            return data;
          }
        });
        this.milageCategoryList = filterdata;
      } else {
        this.milageCategoryList = this.milageCategoryListTemp;
      }
    }
  }
}

