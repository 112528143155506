import { Injectable, RendererFactory2, Inject, Renderer2 } from '@angular/core';
import { inject } from '@angular/core/testing';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  renderer: Renderer2;
  constructor(private rendererFactory: RendererFactory2, @Inject(DOCUMENT) private document: Document) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  setTheme(themename: string) {
    sessionStorage.setItem('current-Theme', themename);
    this.renderer.addClass(this.document.body, themename);
  }

  removeTheme(themename: string) {
    sessionStorage.removeItem('current-Theme');
    this.renderer.removeClass(this.document.body, themename);
  }

  applyTheme(obj) {
    document.documentElement.style.setProperty('--primary-color', obj == null ? '#000000' : (obj.primary ? obj.primary : "#000000"));
    document.documentElement.style.setProperty('--secondary-color', obj == null ? '#0770E8' : (obj.secondary ? obj.secondary : "#0770E8"));
    document.documentElement.style.setProperty('--ion-font-family', obj == null ?
     '-apple-system, BlinkMacSystemFont, sans-serif' : (obj.fontFamily ? obj.fontFamily : '-apple-system, BlinkMacSystemFont, sans-serif'));
  }
}

