import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'utcDate'
})
export class UtcDate implements PipeTransform {

    transform(value: string): any {

        if (!value) {
            return '';
        }

        const dateValue = moment(new Date(value)).format('DD MMM YYYY') ;

        /*const dateWithNoTimezone = new Date(
            dateValue.getUTCFullYear(),
            dateValue.getUTCMonth(),
            dateValue.getUTCDate(),
            dateValue.getUTCHours(),
            dateValue.getUTCMinutes(),
            dateValue.getUTCSeconds()
        );*/
        return dateValue;
    }

}
