import { Directive, Input, Output, EventEmitter, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[clickOutside]',
})
export class ClickOutsideDirective {

  @Output() clickOutside = new EventEmitter<void>();

  constructor(private elementRef: ElementRef) { }

  @HostListener('document:click', ['$event.target'])
  public onClick(target) {
    const clickedInside = this.elementRef.nativeElement.contains(target);
    if (!clickedInside) {
      this.clickOutside.emit();
    }
  }  
}

@Directive({
selector: '[appAutoFocus]'
})
export class AutoFocusDirective {

@Input() public appAutoFocus: AutoFocusOptions;

  constructor(private el: ElementRef) {
  this.appAutoFocus = new AutoFocusOptions();
  setTimeout(() => {
  this.el.nativeElement.setFocus();
  }, this.appAutoFocus.delay);
}

@HostListener('focusout') onHover(event) {
    if (this.appAutoFocus && this.appAutoFocus.preventKeyboardClose) {
    this.el.nativeElement.setFocus();
    }
  }
}

class AutoFocusOptions {
  preventKeyboardClose?: boolean;
  delay?: number;
  constructor() {
  this.preventKeyboardClose = false;
  this.delay = 300;
  }
}
