import { Component, OnInit, ViewChild } from '@angular/core';
import { PlatformService } from 'src/app/services/platformbase.service';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Mileage, MileageList } from 'src/app/models/Settings/mileage.model';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SettingsService } from '../../../services/settings-services'
import { Title } from "@angular/platform-browser";
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-mileage',
  templateUrl: './mileage.component.html',
  styleUrls: ['./mileage.component.scss'],
})
export class MileageComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('searchInput', {static: false}) searchInput;
  platformType: number;
  dataSource: MatTableDataSource<any>;
  displayedColumns: any[] = ['vehicleType','location', 'rate', 'startDate','endDate', 'status','disablestatus'];
  userInfo: any;
  loadersList: any = [1,2,3];
  selectedIndex: any = -1;
  pageNumber: number = 1;
  pageSize: number = 25;
  currentPage: number = 0;
  mileageListPageLimit: number[] = [5,10,25, 100];
  mileageModel = new Mileage();
  selectedLegalEntity: any;
  mileageListTotalLength: any;
  showLoader: boolean = true;
  mileageList: any = [];
  tableDataList: any;
  showTable: boolean = false;
  statusFlag: any;
  statusMessage: string;
  isDisable: boolean = false;
  statusDisable: boolean = false;
  modalRef: BsModalRef;
  mileageObj: any;
  isApicallinProgress: boolean = false;
  ind: any;
  buttonDisabled: Array<boolean> = [];
  showSearch: boolean = true;
  paginatorStatus:boolean = false;

  constructor(private platformService: PlatformService,private title: Title,
     private router: NavController, private modalService: BsModalService, private toastr: ToastrService, private settingsService: SettingsService) { }

  ngOnInit() {}

  ionViewWillEnter(){
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.platformType = this.platformService.getPlatformType();
    this.selectedLegalEntity = JSON.parse(sessionStorage.getItem('selectedLegalEntity'));
    this.mileageModel.legalEntityId = this.selectedLegalEntity.legalEntityId;
    this.mileageModel.legalEntityGUID = this.selectedLegalEntity.legalEntityGuId;
    let applicationTitle = this.userInfo.applicationTitle;
    if(applicationTitle){
        this.title.setTitle('Mileage Categories | Master Data | Settings' + ' | ' + applicationTitle);
    }else{ 
      this.title.setTitle("Mileage Categories | Master Data | Settings | NoExpenses")
    }
    this.getMileageUnit();
    this.getMileageList();
  }

  getMileageUnit(){
    this.settingsService.getMileageUnit(this.mileageModel).subscribe(data => {
      if(!data.isError && data.payload){
        this.mileageModel = new MileageList(data.payload);
        sessionStorage.setItem('MileageUnit', this.mileageModel.mileageType)
      }
    })
  }

  loadMore(event){
    if(!this.isApicallinProgress){
      if( this.mileageList.length < this.pageSize && event){
        event.target.disabled = true;
      }else{
        this.pageNumber++;
        this.getMileageList(event);
      }
    }
  }


  getMileageList(event?){
    this.mileageModel.legalEntityId = this.selectedLegalEntity.legalEntityId;
    this.mileageModel.legalEntityGUID = this.selectedLegalEntity.legalEntityGuId;
    this.mileageModel.pageNumber = this.pageNumber;
    this.mileageModel.pageSize = 25;
    this.settingsService.mileageList(this.mileageModel).subscribe(data => {
      if(!data.isError && data.payload.mileageList.length > 0){
        // this.mileageListTotalLength = data.payload.noOfRecords;
        // this.showLoader = false;
        // let mileageArr = data.payload.mileageList;
        // this.mileageList = mileageArr.map((list) => new MileageList(list));
        // this.dataSource = new MatTableDataSource(this.mileageList);
        // this.dataSource.paginator = this.paginator;
        // this.dataSource.sort = this.sort;
        // this.tableDataList = data.payload;
        // this.showLoader = false;
        // this.showTable = true;

        this.tableDataList = data.payload;
        this.showLoader = false;
        this.showTable = true;
        if(this.paginatorStatus){
          let mileageArr = data?.payload?.mileageList;
          let mileageList = mileageArr.map((list) => new MileageList(list));
          mileageList.forEach(element => {this.mileageList.push(element);});
          this.dataSource = new MatTableDataSource(this.mileageList);
          this.dataSource.paginator = this.paginator;
          this.mileageListTotalLength = this.mileageList;
        }else{
          let mileageArr = data?.payload?.mileageList;
          this.mileageList = mileageArr.map((list) => new MileageList(list));
          this.dataSource = new MatTableDataSource(this.mileageList);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.mileageListTotalLength = this.mileageList;

          if(this.pageNumber == 1){
            this.paginatorStatus = true;
            this.pageNumber++;
            this.getMileageList();
          }
        }
        this.pageNumber != 2 ? this.paginatorStatus = false : '';

        if(event){
          event.target.complete();
        }
        if( this.mileageList.length < this.pageSize && event){
          event.target.disabled = true;
        }
        this.isApicallinProgress = false;
      }else {
        this.showLoader = false;
        this.showTable = true;
        this.mileageList.length != 0 ? this.tableDataList = this.mileageList : this.tableDataList = null;
        if(event) event.target.disabled = true;
      }
    })
  }

  handlePage(e) {
    this.currentPage = e.pageIndex;
    // this.pageSize = e.pageSize;
    if(!this.dataSource.paginator.hasNextPage()){
      this.paginatorStatus = true;
      this.pageNumber++;
      this.getMileageList();
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.mileageList = this.dataSource.filteredData;
  }

  searchbar(){
    this.showSearch = !this.showSearch
  }

  toggleStatusDropdown(index) {
    if (this.selectedIndex != index) this.selectedIndex = index;
    else this.selectedIndex = -1;
  }

  toggleStatus(data,content,flag) {
    this.mileageObj = data;
    this.statusFlag = flag;
      this.modalRef = this.modalService.show(content,  {class: 'modal-md modal-dialog-centered'});
      switch(this.statusFlag){
        case 1: this.statusMessage = "Are you sure you want to enable mileage ?";
        break;
        case 2: this.statusMessage = "Are you sure you want to disable mileage ?";
        break;
      }
    this.hideDropdown();
  }

  statusAction(status?,ind?) {
    this.isDisable = true;
    this.statusDisable = true;
    if(status){
      this.mileageObj = status;
      this.buttonDisabled[ind] = true;
      this.ind = ind;
    }
    this.mileageModel.mileageRateId = this.mileageObj.mileageRateId;
    this.mileageModel.mileageRateGUID = this.mileageObj.mileageRateGUID;
    if (this.mileageObj.status == 1) {
      this.settingsService.disableMileage(JSON.stringify(this.mileageModel)).subscribe(data => {
        if (!data.isError) {
          this.toastr.success(data.payload, 'Success');
          this.statusResponse();
        }else if (data.isError) {
          this.toastr.error(data.errorMessage, 'Error');
          this.statusResponse();
        }
      });
    } else {
      this.settingsService.enableMileage(JSON.stringify(this.mileageModel)).subscribe(data => {
        if (!data.isError) {
          this.toastr.success(data.payload, 'Success');
          this.statusResponse();
        }else if (data.isError) {
          this.toastr.error(data.errorMessage, 'Error');
          this.statusResponse();
        }
      });
    }
  }

  statusResponse(){
    if(this.platformType == 1){
      this.isDisable = false;
      this.statusDisable = false;
      this.closeWindow();
      this.searchInput.nativeElement.value = "";
    }
    if(this.platformType == 2){
      this.buttonDisabled[this.ind] = false;
    }
    this.getMileageList();
  }

  closeWindow() {
    this.modalRef.hide();
  }

  addNewMileage(){
    this.router.navigateRoot(['/settings/mileage/add']);
  }

  navigateToAddMileage(data){
    this.router.navigateRoot(['/settings/mileage/update', data.mileageRateId, data.mileageRateGUID]);
  }

  hideDropdown(){
    if (this.selectedIndex !== -1) {
      this.selectedIndex = -1
    }
  }

  selectRate(e){
    this.mileageModel.legalEntityId = this.selectedLegalEntity.legalEntityId;
    this.mileageModel.legalEntityGUID = this.selectedLegalEntity.legalEntityGuId;
    this.mileageModel.mileageType = e;
    this.settingsService.updateMileageUnit(this.mileageModel).subscribe(data => {
      if(!data.isError){
        this.toastr.success(data.payload, 'Success');
        sessionStorage.setItem('MileageUnit', e)
      } else {
        this.toastr.error(data.errorMessage, 'Error');
      }
    })
  }
  appendLocation(country, state) {
    let locationString = "";
    if (country) {
      locationString = locationString.concat(country + ", ");
    }
    if (state) {
      locationString = locationString.concat(state + " ");
    }
    return locationString;
  }
}
