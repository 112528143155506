import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Constants } from '../Constants';
@Injectable({
  providedIn: 'root'
})
export class EmailConfigurationService {

  constructor(private http: HttpClient) { }
  //API call to create new User Group
//   addNewUserGroup(requestBody): Observable<any> {
//     const url = Constants.DOMAIN_URL + "v1/settings/user-groups/create";
//     const body = requestBody;
//     return this.http.post(url, body);
//   }
  //API call to get All Email Configurations
  getEmailConfigurations(requestBody): Observable<any> {
    // const url = Constants.DOMAIN_URL + "v1/settings/legal-entity-email/list";
    const url = Constants.DOMAIN_URL + "v1/email-configuration/list";
    const body = requestBody;
    return this.http.post(url, body);
  }
  //API to update Email Configuration
  updateSingleEmailConfiguration(requestBody): Observable<any> {
    // const url = Constants.DOMAIN_URL + "v1/settings/legal-entity-email/update ";
    const url = Constants.DOMAIN_URL + "v1/email-configuration/save";
    const body = requestBody;
    return this.http.post(url, body);
  }
  //API to get single Email Configuration
  getSingleEmailConfiguration(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/email-configuration/get";
    const body = requestBody;
    return this.http.post(url, body);
  }

  
  //API call to change the status to InActive
  changeStatusToInactive(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/email-configuration/disable";
    const body = requestBody;
    return this.http.post(url, body);
  }
  //API call to change the status to Active
  changeStatusToActive(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/email-configuration/enable";
    const body = requestBody;
    return this.http.post(url, body);
  }
}