import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { EnableDisableModel } from 'src/app/models/Settings/approval-workflow.model';
import { approvePolicyList } from 'src/app/models/Settings/approvalpolicies.modal';
import { PlatformService } from 'src/app/services/platformbase.service';
import { SettingsService } from 'src/app/services/settings-services';

@Component({
  selector: 'app-approvalpolicies',
  templateUrl: './approvalpolicies.component.html',
  styleUrls: ['./approvalpolicies.component.scss'],
})
export class ApprovalpoliciesComponent implements OnInit {

  platformType: number;
  // workflowForm: FormGroup;
  buttonDisabled: boolean = false;
  showLoader: boolean = true;
  showTable: boolean = false;
  loadersList = [1, 2, 3, 4];
  userInfo: any;
  status: Array<number> = [];
  currentPage = 0;
  pageSize = 25;
  selectedIndex: any = -1;
  displayedColumns: any[] = ['servicetype', 'policyname', 'description', 'status', 'disablestatus'];
  dataSource: MatTableDataSource<any>;
  tableDataList: any;
  statusDisable: boolean = false;
  selectedLegalEntity: any;
  requestBody: { legalEntityId: any; legalEntityGUID: any; type: any };
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  showingStatus: boolean = false;
  approvalObj: any;
  statusFlag: any;
  modalRef: BsModalRef;
  statusMessage: string;
  isApicallinProgress: boolean = false;
  pageNumber: number = 1;
  PageLimit: number[] = [5, 10, 25, 100];
  typeStr: string;
  paginatorStatus: boolean = false;
  policiesList: any;
  categoryListTotalLength: any;
  approvePolicyList: any = [];


  constructor(private platformService: PlatformService, private modalService: BsModalService,
    private title: Title, private router: NavController, private settingsService: SettingsService, private routerL: Router

  ) {
  }

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.platformType = this.platformService.getPlatformType();
    this.pageNumber = 1;
    this.paginatorStatus = true;
    this.approvePolicyList = [];
    this.dataSource = new MatTableDataSource(this.approvePolicyList);
    this.getApprovalFlow();
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    let applicationTitle = this.userInfo.applicationTitle;
    if(applicationTitle){
        this.title.setTitle('Approval Policies| Settings' + ' | ' + applicationTitle);
    }else{ 
      this.title.setTitle("Approval Policies| Settings | NoExpenses");
    }

  }
  addNewFlow() {
    this.routerL.navigate(['/settings/approvalpolicies/add'])
  }


  getApprovalFlow(event?) {
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.selectedLegalEntity = JSON.parse(sessionStorage.getItem('selectedLegalEntity'));
    let requestBody = {
      legalEntityId: this.selectedLegalEntity.legalEntityId,
      legalEntityGUID: this.selectedLegalEntity.legalEntityGuId,
      type: this.typeStr,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize
    }
    this.settingsService.getApprovalPolicy(requestBody).subscribe(data => {
      if (!data.isError && data.payload.approvalPolicyList.length > 0) {
        this.showLoader = false;
        this.tableDataList = data.payload.approvalPolicyList;
        this.showLoader = false;
        this.showTable = true;

        if (this.paginatorStatus) {
          let categoryArr = data.payload.approvalPolicyList;
          let approvePolicyListarr = categoryArr.map((list) => new approvePolicyList(list));
          approvePolicyListarr.forEach(list => { this.approvePolicyList.push(list); });
          this.dataSource = new MatTableDataSource(this.approvePolicyList);
          this.dataSource.paginator = this.paginator;
          this.categoryListTotalLength = this.approvePolicyList.length;
        } else {
          let categoryArr = data.payload.approvalPolicyList;
          this.approvePolicyList = categoryArr.map((list) => new approvePolicyList(list));
          this.dataSource = new MatTableDataSource(this.approvePolicyList);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.categoryListTotalLength = this.approvePolicyList.length;

          if (this.pageNumber == 1) {
            this.paginatorStatus = true;
            this.pageNumber++;
            this.getApprovalFlow();
          }
        }
        this.pageNumber != 2 ? this.paginatorStatus = false : '';

        if (event) {
          event.target.complete();
        }
        if (this.approvePolicyList.length < this.pageSize && event) {
          event.target.disabled = true;
        }
        this.isApicallinProgress = false;
      } else {
        this.showLoader = false;
        this.showTable = true;
        this.approvePolicyList.length != 0 ? this.tableDataList = this.approvePolicyList : this.tableDataList = null;
        if (this.approvePolicyList.length != 0) {
          this.dataSource = new MatTableDataSource(this.approvePolicyList);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.categoryListTotalLength = this.approvePolicyList.length;
        }
        if (event) event.target.disabled = true;
      }
    });
  }

  hideDropdown() {
    if (this.selectedIndex !== -1) {
      this.selectedIndex = -1;
    }
  }

  navigateToUpdateWorkflow(row: any) {
    // this.router.navigateRoot(['/settings/approvalpolicies/update', row.approvalPolicyId, row.approvalPolicyGUID,row.statusId]);
    this.routerL.navigate(['/settings/approvalpolicies/update', row.approvalPolicyId, row.approvalPolicyGUID, row.statusId]);
  }
  toggleStatusDropdown(index) {
    if (this.selectedIndex != index)
      this.selectedIndex = index;
    else
      this.selectedIndex = -1;
  }

  handlePage(e) {
    this.currentPage = e.pageIndex;
    // this.pageSize = e.pageSize;
    if (!this.dataSource.paginator.hasNextPage()) {
      this.paginatorStatus = true;
      this.pageNumber++;
      this.getApprovalFlow();
    }
  }

  loadMore(event) {
    if (!this.isApicallinProgress) {
      if (this.tableDataList.length < this.pageSize && event) {
        event.target.disabled = true;
      } else {
        this.pageNumber++;
        this.getApprovalFlow();
      }
    }
  }

  toggleStatus(data, content, flag) {
    this.approvalObj = data;
    this.modalRef = this.modalService.show(content, { class: 'modal-md modal-dialog-centered' });
    switch (this.approvalObj.statusId) {
      case 1: this.statusMessage = "Are you sure you want to disable Approval?";
        break;
      case 2: this.statusMessage = "Are you sure you want to enable Approval?";
        break;
    }
    this.hideDropdown();
  }

  statusAction() {
    this.statusDisable = true;
    this.showingStatus = true;
    let requestObj = {
      "approvalPolicyId": this.approvalObj.approvalPolicyId,
      "approvalPolicyGUID": this.approvalObj.approvalPolicyGUID
    }
    this.selectedIndex = -1;
    if (this.approvalObj.statusId == 2) {
      this.settingsService.enableApprovalPolicy(JSON.stringify(requestObj)).subscribe(data => {
        if (data.errorCode == 0) {
          this.approvalObj.statusId = 1;
          this.showingStatus = false;
          this.closeWindow();
          this.statusDisable = false;
        } else {
          this.showingStatus = false;
          this.closeWindow();
        }
      });
    } else {

      this.settingsService.disableApprovalPolicy(JSON.stringify(requestObj)).subscribe(data => {
        if (data.errorCode == 0) {
          this.approvalObj.statusId = 2;
          this.closeWindow();
          this.showingStatus = false;
        } else {
          this.closeWindow();
          this.showingStatus = false;
        }
      });

    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  closeWindow() {
    this.modalRef.hide();
  }
}
