import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomDateService {
  private date = new BehaviorSubject<any>('');

  constructor() { }

  setSelectedDate(d: any,) {
    this.date.next(d);
  }

  getSelectedDate(): Observable<any> {
    return this.date.asObservable();
  }
}
