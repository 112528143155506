
export class PolyCreateModel {
    policyId: number;
    policyGUID: string;
    legalEntityId: number;
    legalEntityGUID: string;
    policyName: string;
    policyDescription: string;
    currencyId:string;
    currencyName:string;
    currencyIcon:string;
    currencyCode:string;
    currencyRate:string;
    isAllowUncategorizedExpense: boolean;
    expenseAmountLimit: any;
    receiptRequiredLimit: any;
    isDescriptionRequired: boolean;
    defaultMillageUnit: number;
    policyCategoryList: any;
    policyMileageList: any;
    policyCategoryRuleList: any;
    policyMileageRuleList: any;
    entityMapList: any;
    approvalWorkflowId: number;
    approvalWorkflowName: string;
    policyPerDiemList: any;
    recordType: number;
    showList: boolean = false;
    isPerDiemEnabled: boolean = false;
    isSendBackEnabled:boolean =false;
    financeApprovalWorkflowName: string;
    financeApprovalWorkflowId: number;
    startDate:any;
    endDate:any;
    isCombination:boolean;
    isApprovalFlowRequired:boolean;
    claimTypeId: any;
    claimTypeName: any;

    criteriaList:Array<criteriaM> = [];
    action:any;
}

export class criteriaM{
    policyId:any;
    criteriaId:any;
    conditionType:any;
    conditionName:any;
    categoryId:any;
    amount:any;
    approvalWorkFlowName:any;
    approvalWorkFlowId:any;
    categoryName:any;
    newItem:boolean = false;
}
export class PolyUpdateModel {
    policyId: number;
    policyGUID: string;
}

export class categryPolyModel {
    id: number;
    categoryId: number;
    categoryName: string;
    expenseAmountLimit: string;
    receiptRequiredLimit: string;
    isDescriptionRequired: boolean;
    isActionTypeRequired: boolean;
    innerCatList:any;
    country:any;
    city:any;
    countryIds:any = [];
    cityIds:any = [];
    countryNames:any = [];
    cityNamess:any = [];
    proofOfPaymentLimit:any;
}

export class mileagePolyModel {
    id: number;
    categoryId: number;
    categoryName: string;
    mileageRate: string;
    receiptRequiredLimit: string;
    isDescriptionRequired: boolean;
    startDate: string;
    locationName: string;
    locationId: string;
    stateName:string;
    stateId:string;
    showList: boolean = false;
    showCal: boolean = false;
    country:string;
    showStates:boolean = false;
    showCounties:boolean = false;
    countryName:string;
    countryId:string;
    locationsList:any;
    mileagCategoryList:any;
    isExist:boolean = false;
}

export class polyRulesModel {
    id: number;
    ruleName: string;
    ruleType: number;
    categoryId: number;
    categoryName: string;
    limitType: number;
    limitValue: any;
    violationType: number;
    parentCategoryId: number;
    limitExceeded: boolean;
    cityIds: boolean;
    cityNames: boolean;
    countryIds: boolean;
    countryNames: boolean;
}

// export class polyEntityMapModel {
//     id: number;
//     hierarchyText: any;
//     hierarchyTypeId: number;
//     hierarchyTypeName: string;
//     hierarchyValue: any;
//     isActive: any;
// }
// export class polyInternlModel {
//     showLoader: any;
//     loadersList: any;
//     isEditPolicy: any;
//     currentPage: any;
//     currentStage: any;
//     currentMatTab: any;
//     minDate: Date;
//     maxExpire: Date;
//     categoryList: any;
//     currentRuleIndex: any;
//     showDotBtns: any;
//     rulesSubmitted: any;
//     policySubmitted: any;
//     ruleTypeList: any;
//     limitTypeList: any;
//     ruleValidations: any;
//     trueFalList: any;
//     approvlFlowList: any;
//     entityList: any;
//     labelArray: any;
//     //  isCurrencyValid:any
// }

export class polyEntityMapModel{
    id:number;
    hierarchyText:any;
    hierarchyTypeId:number;
    hierarchyTypeName:string;
    hierarchyValue:any;
    isActive:any;
}
export class polyInternlModel{
    showLoader:any;
    loadersList:any;
    isEditPolicy:any;
    currentPage:any;
    currentStage:any;
    currentMatTab:any;
    minDate:Date;
    maxExpire:Date;
    categoryList:any;
    currentRuleIndex:any;
    showDotBtns:any;
    rulesSubmitted:any;
    policySubmitted:any;
    ruleTypeList:any;
    limitTypeList:any;
    ruleValidations:any;
    trueFalList:any;
    approvlFlowList:any;
    entityList:any;
    labelArray:any;
    finacialFlowList: any;
}
  //  isCurrencyValid:any
export class entityModels {
    legalEntityGuid: string;
    legalEntityId: number;
    statusId: number;
    searchText:any;
    pageSize:any;

}

export class polEnableEntityModels {
    legalEntityGUID: string;
    legalEntityId: number;
}
export class mappedModel {
    userList = [];
    bandList = [];
    designationList = [];
    departmentList = [];
    costCenterList = [];
    legalEntityList = [];
}
export class polyPerDiemModel {
    perDiemId: number;
    perDiemGUID: any;
    perDiemType: number;
    countryId: number;
    countryCode: string;
    countryName: string;
    cityName: string;
    displayName: number;
    startDate: any;
    endDate: any;
    // generalRate:number;
    expenseAmountLimit: number;
    // rateWithoutReceipt:number;
    receiptRequiredLimit: number;
    currencyId: number;
    statusId: number;
    isPerDiemSplit: any;
    // perDmExpenseList:any;
    policyPerDiemCategoryList: any;
}
export class perDiemExpenseItem {
    id: number;
    perDiemId: number;
    categoryName: string; // expenseCategoryName:string;
    categoryId: number;// expenseCategoryId:number;
    currencyCode: any;
    currencyId: number;
    expenseAmountLimit: number; //rateAmount:number;
}
export class PolicyListModel {
    legalEntityId: number;
    legalEntityGUID: string;
    recordType: number;
    searchText: any;
    pageNumber:any;
    pageSize:any;
}

export class LCMileageRule {
    id: number;
    ruleName: string;
    ruleType: number;
    limitType: number;
    limitValue: number;
    statusId: number;
    isDefault: any;
    countryName: string;
    countryId: number;
    cityName: string;
    currencyId: number;
    categoryName: string;
    categoryId: number;
}

export class SettingsGeneralPolicyCreate {
    legalEntityId: number;
    legalEntityGUID: string;
    policyConfigurationId: number;
    policyConfigurationGUID: string;
    dayDifference: number;
    systemGeneratedKmValue:number;
    maxAttachementCount:number;
    attachmentFormat:any;
    attachmentFormats:any;
    dayDifferenceStatus: any;
    isDeviateGeneratedKmEnabled:any;
    isOcrEnabled: boolean;
    isTaxCodeEnabled:boolean;
    isFinanceCodeEnabled:boolean;
    isHsnCodeEnabled:boolean;
    isAttachmentMandatory:boolean;
    isBlockDuplicateExpense:boolean;
    isCategoryBasedEnabled:boolean;
    isRejectReasonMandatory:boolean
    isMileageEnabled:boolean
    isPerDiemEnabled:boolean;
    isSendBackEnabled:boolean;
    isAssociatedExpenseEnabled:boolean;
    isServiceExpenseEnabled:boolean;
    isLinkedToAdvanceMandatory:boolean;
    isEditExchangeRateEnabled:boolean;
    isSettledAdvance:boolean;
    isMultiClaimsLinkedToAdvance:boolean
    isEnableBudgetCheck:boolean
    isRestrictFinanceOnInsufficientBudget:boolean
    isRestrictManagerOnInsufficientBudget:boolean
    isRestrictUserOnInsufficientBudget:boolean
    isEnableDimensions:boolean
    isEnableTrackMileage:boolean
    showReferenceDocument:boolean;
    referenceDocumentUrls: any;
    isEnableBudgetUser:boolean;
    isEnableBudgetFinance:boolean;
    isEnableBudgetManager:boolean;
    isEnableAdvanceEditApprover:boolean;
    isEnableAdvanceTab:boolean;
    isEnableClaimTypes:boolean;
    isEnableAdvanceTypes:boolean;
    sendNotificationSettleAdvance:boolean;
    daysToSettleAdvance:boolean;
    isStatusEnabled:boolean;
    statusDesc: any;
    financeYearStartMonth: any;
    isMultipleClaimsAllowed:boolean = false;
    isEnableAdvanceCreation: boolean;
    isFinanceCodeEnabledAdvance: boolean;
    makeCityManadatoryCountry = false;
    makeCityManadatoryCountryIds: any;
    makeCityManadatoryCountryNames: any;
    claimTypeNames:any;
    enableMechantCodeAtFinance: any = false;
    enableOnlyParentCategory: any = false;
    isEnableCategorySelectionAtClaim: any = false;
    isAllowUserToEditAtExpenseLevel: any = false;
    enableDirectTripSubmission = false;
    enableBillingEntity = false;
    enableClaimStartTimeEndTime = false;
    statusNames: any = [];
    statusIds: any = [];
    perDiemClaimTypeNames: any = [];
    perDiemClaimTypeIds: any = [];
    mileageClaimTypeNames: any = [];
    mileageClaimTypeIds: any = [];
    displayTripIdUnderApprovals: any = false;
    showTripsOnClickNewClaim: any = false;
    enableRecordPaymentWithSAP: any = false;
    showTripEstimateAmount: any = false;
    restrictMobileBillMissMatch: any = false;
    restrictGSTNumberMissMatch: any = false;
    isEnableCategorySelectionAtExpense: any = false;
    modifiedNameOfNonTravel: any;
    showModifiedNameOfNonTravel: any = false;
    skipIfApproverNotAvailable:any = false;
    isAttachmentMandatoryForAdvance: any = false;
    isDescriptionMandatory: any = false;
    prepopulateTypeOfClaim: boolean = false;
    prepopulateTypeOfClaimId: any;
    prepopulateTypeOfClaimName: string;
    enableTravelExpenseType: boolean = false;
    travelExpenseTypeIds: any = [];
    travelExpenseTypeNames: any = [];
    enableNonTravelExpenseType: boolean = false;
    nonTravelExpenseTypeIds: any = [];
    nonTravelExpenseTypeNames: any = [];
    enableCardExpenseType: boolean = false;
    cardExpenseTypeIds: any = [];
    cardExpenseTypeNames: any = [];
    isAllowClaimFromSpecificDate: boolean = false;
    claimFormStartsFrom: string;
}

export class SettingsGeneralPolicyGet {
    legalEntityId: number;
    legalEntityGUID: string;
}

export class newPerDiemForm {
    countryName: any;
    countryId: any;
    cityName: any;
    cityNameList = [];
    cityId: any;
    startDate: any;
    endDate: any;
    currencyCode: any;
    countryCode: any;
    legalEntityId: any;
    legalEntityGUID:any;
    recordType:any;
    dropDownStr:boolean = false;
    copyWithCndtns = false;
    copyWithoutCndtns = false;
    // subtractedCatIds: any =[];
    // subtractedCatNames: any =[];


    isAllowedWithReceipt: boolean = false;

    perDayWithReceipt: boolean;
    perDayCurrencyIdWithReceipt: any;
    perDayCurrencyIdWithReceiptCode: any;
    perDayCurrencyIdWithReceiptIcon: any;
    perDayAmountWithReceipt: any;

    nextDayCurrencyIdWithReceipt: any;
    nextDayCurrencyIdWithReceiptCode: any;
    nextDayCurrencyIdWithReceiptIcon: any;
    nextDayAmountWithReceipt: any;

    perHalfDayWithReceipt: boolean;
    perHalfDayCurrencyIdWithReceipt: any;
    perHalfDayCurrencyIdWithReceiptCode: any;
    perHalfDayCurrencyIdWithReceiptIcon: any;
    perHalfDayAmountWithReceipt: any;

    perHourWithReceipt: boolean;
    perHourCurrencyIdWithReceipt: any;
    perHourCurrencyIdWithReceiptCode: any;
    perHourCurrencyIdWithReceiptIcon: any;
    perHourAmountWithReceipt: any;


    isAllowedWithoutReceipt: boolean = false;

    perDayWithoutReceipt: boolean;
    perDayCurrencyIdWithoutReceipt: any;
    perDayCurrencyIdWithoutReceiptCode: any;
    perDayCurrencyIdWithoutReceiptIcon: any;
    perDayAmountWithoutReceipt: any;

    nextDayCurrencyIdWithoutReceipt: any;
    nextDayCurrencyIdWithoutReceiptCode: any;
    nextDayCurrencyIdWithoutReceiptIcon: any;
    nextDayAmountWithoutReceipt: any;

    perHalfDayWithoutReceipt: boolean;
    perHalfDayCurrencyIdWithoutReceipt: any;
    perHalfDayCurrencyIdWithoutReceiptCode: any;
    perHalfDayCurrencyIdWithoutReceiptIcon: any;
    perHalfDayAmountWithoutReceipt: any;

    perHourWithoutReceipt: boolean;
    perHourCurrencyIdWithoutReceipt: any;
    perHourCurrencyIdWithoutReceiptCode: any;
    perHourCurrencyIdWithoutReceiptIcon: any;
    perHourAmountWithoutReceipt: any;

    enableWithRecieptConditons: boolean;
    enableWitoutRecieptConditons: boolean;
    withRecieptDiemConditions: any = [];
    withoutRecieptDiemConditions: any = [];
}

export class BudgetPolicyStepOne {
    policyId: any;
    policyGUID: any;
    policyName: any;
    startDate: any;
    endDate: any;
    policyDescription: any;
    currencyId: any;
    currencyName: any;
    currencyCode: any;
    currencyIcon: any;
    claimTypeId: any;
    claimTypeName: any;
    action: any;
    emulatedUserId: any;
    emulatedUserGuId: any;
    statusId: any;
    legalEntityId: any;
    legalEntityGUID: any;
    recordType: any;
    isAllowUncategorizedExpense: any
}