import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { Constants } from "../Constants";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ExpensesService {
  cameraImageFile: any;
  private messageSource = new BehaviorSubject("default message");

  constructor(public httpClient: HttpClient) {}

  getExpenseList(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/expense/list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  createExpenseandMileage(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/expense/create";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  createAdvance(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/expense/createAdvance";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getExpense(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/expense/get";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  createMileage(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/mileage/create";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  deleteExpense(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/expense/delete";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  addToReport(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/report/link-expenses";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getUnassignedExpenseList(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/expense/unassigned-list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  expenseSummary(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/expense/expense-summary";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  settlement(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/expense/settlement";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  expenseAttachViewer(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/viewer/expenses/attachment/get";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  downloadAttachment(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/file/download";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  downloadTripAttachment(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/file/trip-download";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  createImageExpense(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/expense/initiate";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  ocrDataPulling(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/common/file-upload";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  
  extractImageOCR(requestBody: any): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/extract-image-api";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  createBulk(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/bulk-expense/create";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  chatGPTDistance(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/extract-image-api";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }


  deleteClaimTrip(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/bulk-expense/delete-claim-trip";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  createLocalConvynace(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/local-conveyance/create";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getRecurrentCodesList(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/recurrent/codes/list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getMileageStatesList(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/mileage/state-list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getMileageCitiesList(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/mileage/cities-list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getFromLocationMapmIndia(Obj): Observable<any> {
    const url = "http://apis.mapmyindia.com/advancedmaps/v1/c3afc146e426bbe352ff98ee04ac88e3/geo_code?addr=" + Obj;
    return this.httpClient.get(url);
  }
  getMileageAuditList(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/mileage-audit/save";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  getMileageAuditDiscard(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/mileage-audit/end-trip";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getSendBackRequest(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/report/resend";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getEmailSendBackRequest(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/email-approve/resend";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }

  getUploadUrl(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL +  "/v1/presigned-url/get";
    return this.httpClient.post(url,requestBody);
  }

  uploadFileInS3(file, uploadPath): Observable<any> {
    let headers= new HttpHeaders();
    headers.set('Content-Type',undefined);
    return this.httpClient.put(uploadPath,file,{observe:'response',headers:headers}).pipe(
      map((response:HttpResponse<any>)=>{
        return response;
      })
    );
  }

  getIntegrationHistory(requestBody): Observable<any>{
    const url = Constants.DOMAIN_URL + 'v1/report/get-integration-history'
    return this.httpClient.post(url,requestBody);
  }

  repushRequest(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + 'v1/report/sap-repush';
    return this.httpClient.post(url,requestBody)
  }

  modifyRepush(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + 'v1/report/modify-repush';
    return this.httpClient.post(url,requestBody)
  }
  
  getMyCards(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + 'v1/expense/list-cards';
    return this.httpClient.post(url,requestBody)
  }

  getClaimedCards(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + 'v1/expense/claimed-cards';
    return this.httpClient.post(url,requestBody)
  }

  getUnClaimedCards(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + 'v1/expense/unclaimed-cards';
    return this.httpClient.post(url,requestBody)
  }

  getClaimTypes(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + 'v1/common/common-lookup';
    return this.httpClient.post(url,requestBody)
  }
  advanceSettlement(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + 'v1/expense/submit-advance-for-settlement';
    return this.httpClient.post(url,requestBody)
  }
  saveStatusRequest(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/report/save-status";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
  updateTripService(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/trip-details/update-service-list";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
}
