import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-travel-eva',
  templateUrl: './travel-eva.component.html',
  styleUrls: ['./travel-eva.component.scss'],
})
export class TravelEvaComponent implements OnInit {
  xrayUrl : any;
  constructor(private sanitizer: DomSanitizer
    ) { }
  ngOnInit() {
   this.xrayUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
    JSON.parse(localStorage.getItem('fc365url'))
  );
    
  }
} 
