import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from 'ngx-toastr';
import { ReportsService } from 'src/app/services/reports.service';

@Component({
  selector: 'app-settlement-otp-verification',
  templateUrl: './settlement-otp-verification.component.html',
  styleUrls: ['./settlement-otp-verification.component.scss'],
})
export class SettlementOtpVerificationComponent implements OnInit {


  modalRef: BsModalRef;  
  disableBtn:boolean = false;
  enteredotp:any;
  @ViewChild("optVerfication", { static: false }) otpTemp : TemplateRef<any>;

  constructor(private active_router: Router,
    public activtedRouter: ActivatedRoute,
    private navCtrl: NavController,
    private modalService: BsModalService,
    private toast:ToastrService,
    private reportService: ReportsService) {
    if (this.active_router.url.includes('/settlement-otp-validation/')) {
      console.log('rajesh :', this.activtedRouter.snapshot.params['id'], this.activtedRouter.snapshot.params['advanceID'], this.activtedRouter.snapshot.params['amount']);
    }
  }

  ngOnInit() {

  }

  apiCall(){
    this.disableBtn = true;
    let request = {
      'otp':this.enteredotp,
      'claimId':this.activtedRouter.snapshot.params['id']
    }
    this.reportService.settlementOptVerfiction(request).subscribe(res =>{
      console.log('sdsdsd',res);
      this.disableBtn = false;

      if(res.errorCode == 0){
        this.toast.success(res.payload,'Success');
        this.active_router.navigate(['/']);
      }else{
        this.toast.error(res.errorMessage,'Error');
      }
      // window.close();
    })
  }

}
