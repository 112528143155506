import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {PlatformService} from 'src/app/services/platformbase.service';
import {Router} from '@angular/router';
import {Merchant, MerchantList} from 'src/app/models/Settings/merchant.model';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ToastrService} from 'ngx-toastr';
import {SettingsService} from '../../../services/settings-services';
import { Title } from "@angular/platform-browser";
import { NavController } from '@ionic/angular';
import { SharedService } from 'src/app/services/common-services/shared.service';
import { polEnableEntityModels } from 'src/app/models/Settings/policy.model';
import { AccountConfig } from 'src/app/models/Settings/accountconfig.model';
import { AccountConfigService } from 'src/app/services/account-config.service';
@Component({
    selector: 'app-merchants',
    templateUrl: './merchants.component.html',
    styleUrls: ['./merchants.component.scss'],
})
export class MerchantsComponent implements OnInit {
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    @ViewChild('searchInput', {static: false}) searchInput;
    platformType: number;
    showLoader: boolean = true;
    showTable: boolean = false;
    dataSource: MatTableDataSource<any>;
    displayedColumns: any[] = ['merchantname','code','taxPercentage','taxNumber', 'categoryname', 'description', 'status', 'disablestatus'];
    merchantModel = new Merchant();
    tableDataList: any;
    merchantListTotalLength: any;
    merchantList: any = [];
    loadersList: any = [1, 2, 3, 4];
    selectedIndex: any = -1;
    pageNumber: number = 1;
    pageSize: number = 25;
    currentPage: number = 0;
    merchantListPageLimit: number[] = [5, 10, 25, 100];
    statusFlag: any;
    statusMessage: string;
    isDisable: boolean = false;
    statusDisable: boolean = false;
    modalRef: BsModalRef;
    merchantObj: any;
    selectedLegalEntity: any;
    isApicallinProgress = false;
    buttonDisabled: Array<boolean> = [];
    ind: any;
    showSearch: boolean = true;
    sharedSubsc:any;
    ispcMainEnabled = false;
    userInfo:any;
    hasConfigData = false;
    paginatorStatus:boolean = false;

    constructor(private platformService: PlatformService,private title: Title,
         private router: NavController, private settingService: SettingsService, 
         private modalService: BsModalService, private toastr: ToastrService,
         private sharedService: SharedService, private accountConfigService: AccountConfigService) {
            this.sharedSubsc =  this.sharedService.getLoadCatgryApi().subscribe(catType => {
                if(catType) {
                  if(this.selectedLegalEntity?.legalEntityGuId && this.merchantModel.categoryType != catType){
                    /*1.if legalEntityExists then it means category is changed and same component is called again, so call api - as in this case component won't be relaoded again
                    2.if legalEntity does not exists then component is caled for first time, so don't cal api as it is caled in ionView. */
                    this.getMerchantList();
                  }
                }
              });
    }

    ngOnInit() {}

    ionViewWillEnter(){
        this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
       // this.ispcMainEnabled = this.userInfo?.isPettyCashEnabled;
        this.platformType = this.platformService.getPlatformType();
        this.selectedLegalEntity = JSON.parse(sessionStorage.getItem('selectedLegalEntity'));
        this.getMerchantList();
        this.getAccountConfiguration();
        let applicationTitle = this.userInfo.applicationTitle;
        if(applicationTitle){
            this.title.setTitle('Merchants | Master Data | Settings' + ' | ' + applicationTitle);
        }else{ 
          this.title.setTitle("Merchants | Master Data | Settings | NoExpenses");
        }
    }

    ngOnDestroy() {
        this.sharedService.unsubscribeServic(this.sharedSubsc);
      }
    
      ionViewWillLeave() {
        this.sharedService.unsubscribeServic(this.sharedSubsc);
      }
    

    getMerchantList(event?) {
        this.merchantModel.legalEntityId = this.selectedLegalEntity.legalEntityId;
        this.merchantModel.legalEntityGUID = this.selectedLegalEntity.legalEntityGuId;
        this.merchantModel.categoryId = null;
        this.merchantModel.pageSize = this.pageSize;
        this.merchantModel.pageNumber = this.pageNumber;
        let navInfo = JSON.parse(sessionStorage.getItem("toPathInfo"));
        if(navInfo && navInfo.categoryType) {
          this.merchantModel.categoryType = navInfo.categoryType;
        }

        this.settingService.merchantList(this.merchantModel).subscribe(data => {
            if (!data.isError && data.payload.merchantList.length > 0) {
                // this.merchantListTotalLength = data.payload.length;
                // this.showLoader = false;
                // let merchantArr = data.payload.merchantList;
                // this.merchantList = merchantArr.map((list) => new MerchantList(list));
                // this.dataSource = new MatTableDataSource(this.merchantList);
                // this.dataSource.paginator = this.paginator;
                // this.dataSource.sort = this.sort;

                if(this.paginatorStatus){
                    let merchantArr = data?.payload?.merchantList;
                    let merchantList = merchantArr.map((list) => new MerchantList(list));
                    merchantList.forEach(element => {this.merchantList.push(element);});
                    this.dataSource = new MatTableDataSource(this.merchantList);
                    this.dataSource.paginator = this.paginator;
                    this.merchantListTotalLength = this.merchantList;
                  }else{
                    let merchantArr = data?.payload?.merchantList;
                    this.merchantList = merchantArr.map((list) => new MerchantList(list));
                    this.dataSource = new MatTableDataSource(this.merchantList);
                    this.dataSource.paginator = this.paginator;
                    this.dataSource.sort = this.sort;
                    this.merchantListTotalLength = this.merchantList;
          
                    if(this.pageNumber == 1){
                      this.paginatorStatus = true;
                      this.pageNumber++;
                      this.getMerchantList();
                    }
                  }
                  this.pageNumber != 2 ? this.paginatorStatus = false : '';

                this.tableDataList = data.payload;
                this.showLoader = false;
                this.showTable = true;
                if (event) {
                    event.target.complete();
                }
                if (this.merchantList.length < this.pageSize && event) {
                    event.target.disabled = true;
                }
                this.isApicallinProgress = false;
            } else {
                this.showLoader = false;
                this.showTable = true;
                this.merchantList.length != 0 ? this.tableDataList = this.merchantList : this.tableDataList = null;
                if (event) {
                    event.target.disabled = true;
                }
            }
        });
    }

    getAccountConfiguration(){    
        var modl = new AccountConfig();
        this.hasConfigData = false;
        let selectedEntity = JSON.parse(sessionStorage.getItem('selectedLegalEntity'));
        modl.legalEntityId = selectedEntity.legalEntityId;
        modl.legalEntityGUID = selectedEntity.legalEntityGuId;
        this.accountConfigService.getAccountConfig(modl).subscribe(res => {
            this.hasConfigData = true;
          if(!res.isError && res.payload){
            this.ispcMainEnabled = res.payload?.isPettyCashEnabled;
          } 
        })
      }

    loadMore(event) {
        if (!this.isApicallinProgress) {
            if (this.merchantList.length < this.pageSize && event) {
                event.target.disabled = true;
            } else {
                this.pageNumber++;
                this.getMerchantList(event);
            }
        }
    }

    handlePage(e) {
        this.currentPage = e.pageIndex;
        // this.pageSize = e.pageSize;
        if(!this.dataSource.paginator.hasNextPage()){
            this.paginatorStatus = true;
            this.pageNumber++;
            this.getMerchantList();
          }
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
        this.merchantList = this.dataSource.filteredData;
    }

    searchbar(){
        this.showSearch = !this.showSearch
    }

    toggleStatusDropdown(index) {
        if (this.selectedIndex != index) {
            this.selectedIndex = index;
        } else {
            this.selectedIndex = -1;
        }
    }

    toggleStatus(data, content, flag) {
        this.merchantObj = data;
        this.statusFlag = flag;
        this.modalRef = this.modalService.show(content, {class: 'modal-md modal-dialog-centered'});
        switch (this.statusFlag) {
            case 1:
                this.statusMessage = "Are you sure you want to enable merchant ?";
                break;
            case 2:
                this.statusMessage = "Are you sure you want to disable merchant ?";
                break;
        }
        this.hideDropdown();
    }

    statusAction(status?, ind?) {
        this.isDisable = true;
        this.statusDisable = true;
        if (status) {
            this.buttonDisabled[ind] = true;
            this.ind = ind;
            this.merchantObj = status;
        }
        this.merchantModel.merchantId = this.merchantObj.merchantId;
        this.merchantModel.merchantGUID = this.merchantObj.merchantGUID;
        if (this.merchantObj.status == 1) {
            this.settingService.disableMerchant(JSON.stringify(this.merchantModel)).subscribe(data => {
                if (!data.isError) {
                    this.toastr.success(data.payload, 'Success');
                    this.statusResponse();
                } else if (data.isError) {
                    this.toastr.error(data.errorMessage, 'Error');
                    this.statusResponse();
                }
            });
        } else {
            this.settingService.enableMerchant(JSON.stringify(this.merchantModel)).subscribe(data => {
                if (!data.isError) {
                    this.toastr.success(data.payload, 'Success');
                    this.statusResponse();
                } else if (data.isError) {
                    this.toastr.error(data.errorMessage, 'Error');
                    this.statusResponse();
                }
            });
        }
    }

    statusResponse() {
        if(this.platformType == 1){
        this.isDisable = false;
        this.statusDisable = false;
        this.closeWindow();
        this.searchInput.nativeElement.value = "";
        }
        if(this.platformType == 2){
          this.buttonDisabled[this.ind] = false;
        }
        this.getMerchantList();
    }

    closeWindow() {
        this.modalRef.hide();
    }

    addNewMerchant() {
        this.router.navigateRoot(['/settings/merchant/add']);
    }

    navigateToAddMerchant(data) {
        this.router.navigateRoot(['/settings/merchant/update', data.merchantId, data.merchantGUID,data.status]);
    }

    hideDropdown() {
        if (this.selectedIndex !== -1) {
            this.selectedIndex = -1;
        }
    }


    pcMainPolicyToggle(e:any) {
        if(e?.detail.checked) {
          this.enablePCMainPolicy();
        } else {
          this.disablePCMainPolicy();
        }
      }
    
     enablePCMainPolicy() {
        let modl = new polEnableEntityModels();
        modl.legalEntityId = this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId;
        modl.legalEntityGUID = this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId;
        this.settingService.settingsPettyCashEnable(modl).subscribe(res=>{
          if(!res.isError){
            this.ispcMainEnabled = true;
          }
        });
      }
    
    disablePCMainPolicy() {
        let modl = new polEnableEntityModels();
        modl.legalEntityId = this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId;
        modl.legalEntityGUID = this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId;
        this.settingService.settingsPettyCashDisable(modl).subscribe(res=>{
          if(!res.isError){
            this.ispcMainEnabled = false;
          }
        });
      }

}
