import { Component, OnInit, ViewChild, TemplateRef, NgZone, ElementRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NavController } from '@ionic/angular';
import { PlatformService } from 'src/app/services/platformbase.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SettingsService } from 'src/app/services/settings-services';
import {
    entityModels,
    LCMileageRule,
    mappedModel,
    mileagePolyModel,
    PolyCreateModel,
    polyEntityMapModel,
    polyInternlModel,
    PolyUpdateModel
} from 'src/app/models/Settings/policy.model';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DatechangeService } from 'src/app/services/common-services/datechange.service';
import { ToastrService } from "ngx-toastr";
import { CategoryAndMerchantModel, CategoryList, CurrencyList, UserModel, UserRcrdModel } from 'src/app/models/Common/common.model';
import { CommonService } from "src/app/services/common-services/common.service";
import { SharedService } from 'src/app/services/common-services/shared.service';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-add-lc',
    templateUrl: './add-lc.component.html',
    styleUrls: ['./add-lc.component.scss'],
})

export class AddLCComponent implements OnInit {
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    platformType: number;

    pageSize = 25;
    tableDataList: any;
    pageLimit: number[] = [5, 10, 25, 100];
    createView: boolean = true;
    designView: boolean = false;
    publishView: boolean = false;
    rulesSource: MatTableDataSource<any>;
    rulesCols: any[] = ['Name', 'Rate', 'Duration', 'Location', 'Start Date', 'Status', 'Disable'];
    allEntitysLists = [];
    mappedList = new mappedModel();

    showLoader: boolean = false; //
    currentPage = 0;//
    currentStage = 0;//
    loadersList: any = [1, 2, 3, 4];
    modalRef: any;
    currentMatTab = 0;  //
    isEditPolicy: any;//
    fromPathSubscr: any;
    minDate: Date;//
    maxExpire: Date;//
    userInfo: any;
    categoryList: any;//
    vehiclesList: any;//
    totalPolcyForm: FormGroup;
    totalPolcyModel = new PolyCreateModel();
    rulesModel: any; // = new polyRulesModel();
    rulesForm: FormGroup;
    allUIInfo = new polyInternlModel();
    entityMapForm: FormGroup;
    legalEntityMapped = false;
    canEditLegalEntity = true;
    buttonDisabled = false;
    hasVal = true;
    selectedLegalEntity: any;

    setInitialData1() {
        this.allUIInfo.showLoader = true;
        this.allUIInfo.loadersList = [1, 2, 3, 4];
        this.allUIInfo.currentPage = 0;
        this.allUIInfo.currentStage = 0;
        this.allUIInfo.currentMatTab = 0;
        this.allUIInfo.currentRuleIndex = -1;
        this.allUIInfo.showDotBtns = -1;
    }

    constructor(private platformService: PlatformService,
        private router: NavController,
        private settingService: SettingsService,
        public formBuilder: FormBuilder,
        private modalService: BsModalService,
        private datechangeService: DatechangeService,
        private toastr: ToastrService,
        private commonService: CommonService,
        public sharedService: SharedService,
        private translate: TranslateService,
        private ngZone: NgZone) {
    }

    /*initial set up */
    ngOnInit() {
    }

    ionViewWillEnter() {
        this.setInitialData();
        this.commonService.googlescriptAdd();
    }

    setInitialData() {
        this.platformType = this.platformService.getPlatformType();
        this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        this.selectedLegalEntity = JSON.parse(sessionStorage.getItem('selectedLegalEntity'));
        this.getCategorylist(2);
        this.getCurrencylist();
        this.settingService.getCountries({}).subscribe(data => {
            this.countryList = data.payload;
        });

        this.getApprovalWorkflows();

        const date = new Date();
        this.minDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        this.maxExpire = new Date(date.getFullYear() + 40, 11);

        this.allUIInfo.rulesSubmitted = false;
        this.allUIInfo.policySubmitted = false;
        this.allUIInfo.trueFalList = [{ type: true, name: 'Yes' }, { type: false, name: 'No' }];
        this.allUIInfo.ruleTypeList = [{ type: 1, name: 'Daily Limit' }, { type: 2, name: 'Monthly Limit' }, { type: 3, name: 'Yearly Limit' }];
        this.allUIInfo.ruleValidations = [{ type: 1, name: 'Warn' }, { type: 2, name: 'Block' }];
        this.allUIInfo.entityList = [{
            hierarchyTypeId: 1, hierarchyTypeName: 'Legal Entity', hierarchyValue: [],
            hierarchyText: [],
            isActive: false, loader: false
        },
        { hierarchyTypeId: 2, hierarchyTypeName: 'Cost Center', hierarchyValue: [], hierarchyText: [], isActive: false, loader: false },
        { hierarchyTypeId: 3, hierarchyTypeName: 'Departments', hierarchyValue: [], hierarchyText: [], isActive: false, loader: false },
        { hierarchyTypeId: 4, hierarchyTypeName: 'Bands', hierarchyValue: [], hierarchyText: [], isActive: false, loader: false },
        { hierarchyTypeId: 5, hierarchyTypeName: 'Teams', hierarchyValue: [], hierarchyText: [], isActive: false, loader: false },
        { hierarchyTypeId: 6, hierarchyTypeName: 'Designations', hierarchyValue: [], hierarchyText: [], isActive: false, loader: false },
        { hierarchyTypeId: 7, hierarchyTypeName: 'User', hierarchyValue: [], hierarchyText: [], isActive: false, loader: false }];
        this.allUIInfo.labelArray = [
            { hierId: 2, mappedKey: 'costCenterList', idKey: 'costCenterId', nameKey: 'costCenterName' },
            { hierId: 3, mappedKey: 'departmentList', idKey: 'departmentId', nameKey: 'departmentName' },
            { hierId: 4, mappedKey: 'bandList', idKey: 'bandId', nameKey: 'bandName' },
            { hierId: 5, mappedKey: 'teamsList', idKey: 'teamId', nameKey: 'teamName' },
            { hierId: 6, mappedKey: 'designationList', idKey: 'designationId', nameKey: 'designationName' },
            { hierId: 7, mappedKey: 'userList', idKey: 'userId', nameKey: 'userName' }];
        this.totalPolcyForm = this.formBuilder.group({
            policyName: ["", Validators.required],
            policyDescription: [""],
            isAllowUncategorizedExpense: [""],
            expenseAmountLimit: [""],
            receiptRequiredLimit: [""],
            isDescriptionRequired: [""],
            policyMileageList: this.formBuilder.array([]),
            policyMileageRuleList: this.formBuilder.array([]),
            entityMapList: this.formBuilder.array([]),
            policyPerDiemCategoryList: this.formBuilder.array([]),
            approvalWorkflowId: [""],
            approvalWorkflowName: [""]
        });

        this.entityMapForm = this.formBuilder.group({
            2: this.formBuilder.array([]),
            3: this.formBuilder.array([]),
            4: this.formBuilder.array([]),
            5: this.formBuilder.array([]),
            6: this.formBuilder.array([]),
            7: this.formBuilder.array([])
        });

        this.totalPolcyModel.defaultMillageUnit = 1;
        this.totalPolcyModel.policyMileageList = [];
        this.totalPolcyModel.policyMileageRuleList = [];
        this.totalPolcyModel.entityMapList = [];

        let navInfo = JSON.parse(sessionStorage.getItem("toPathInfo"));
        if (navInfo && navInfo["url"] && (navInfo["url"] == 'settings/localconveyancepolicy')) {
            if (navInfo["policyId"] && navInfo["policyGUID"]) {
                this.isEditPolicy = true;
                this.showLoader = true;
                this.getPolicyApi(navInfo);
            } else {
                this.showLoader = false;
                this.isEditPolicy = false;
                this.setCatgEntityData();
                this.getMappedEntityAPi();
            }
        }

        this.setRulesDatasource();

    }

    setCatgEntityData() {
        this.setDefaultEntityUI();
        this.addDefaultSubEntity();
    }

    addDefaultSubEntity() {
        for (let i = 0; i < this.totalPolcyModel.entityMapList.length; i++) {

            if (i == 0) {
                this.totalPolcyModel.entityMapList[0].hierarchyValue = this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId;
                this.totalPolcyModel.entityMapList[0].hierarchyText = this.userInfo?.companyName;
            } else {

                let hierId = (i + 1).toString();
                if (this.totalPolcyModel.entityMapList[i].hierarchyValue.length > 0) {
                    let idStrList = (this.totalPolcyModel.entityMapList[i].hierarchyValue).split(',');
                    this.totalPolcyModel.entityMapList[i].hierarchyValue = idStrList;
                    let nameList = (this.totalPolcyModel.entityMapList[i].hierarchyText).split(',');
                    this.totalPolcyModel.entityMapList[i].hierarchyText = nameList;

                    if ((nameList.length == idStrList.length) && (nameList.length != 0)) {

                        var j = 0;
                        idStrList.forEach(id => {
                            this.addSubEntityForm(nameList[j], hierId, id);
                            j = j + 1;
                        });
                    }
                } else {
                    this.totalPolcyModel.entityMapList[i].hierarchyValue = [];
                    this.totalPolcyModel.entityMapList[i].hierarchyText = [];
                    this.totalPolcyModel.entityMapList[i].hierarchyValue.push('');
                    this.totalPolcyModel.entityMapList[i].hierarchyText.push('');
                    this.addSubEntityForm('', hierId);
                }
            }
        }
    }

    ngOnDestory() {
        this.removeSucbr();
    }

    ionViewWillLeave() {
        this.removeSucbr();
    }

    removeSucbr() {
        sessionStorage.setItem('toPathInfo', null);
    }


    backToFinances() {
        this.router.navigateRoot(['settings/localconveyancepolicy']);
    }

    backToSettings() {
        this.router.navigateRoot(["/settings/accountconfig"]);
    }

    get mileagePolicyListForms() {
        return this.totalPolcyForm.get("policyMileageList") as FormArray;
    }

    get entityMapRequestForms() {
        return this.totalPolcyForm.get("entityMapList") as FormArray;
    }

    addSubEntityForm(name: any, hierID: any, id?) {

        var frm: any;
        if (id) {
            frm = this.formBuilder.group({
                entityName: [name],
                entityId: [id]
            });
        } else {
            frm = this.formBuilder.group({
                entityName: [name],
                entityId: ['']
            });
        }

        (this.entityMapForm.get(hierID) as FormArray).push(frm);
        // return frm;
    }

    get f() {
        return this.rulesForm.controls;
    }

    setDefaultEntityUI() {
        this.entityMapRequestForms.removeAt(0);
        this.entityMapRequestForms.removeAt(0);
        this.entityMapRequestForms.removeAt(0);
        this.entityMapRequestForms.removeAt(0);
        this.entityMapRequestForms.removeAt(0);
        this.entityMapRequestForms.removeAt(0);
        this.entityMapRequestForms.removeAt(0);

        this.totalPolcyModel.entityMapList = [];

        for (let i = 0; i < 7; i++) {
            this.addNewEntityItem(this.allUIInfo.entityList[i], true);
        }
    }


    addNewMileageItem(model?) {
        var milgModel;
        if (model) {
            milgModel = model;
        } else {
            milgModel = new mileagePolyModel();
            this.totalPolcyModel.policyMileageList.push(milgModel);
        }

        let milgForm = this.formBuilder.group({
            id: [model?.id],
            categoryId: [model?.categoryId],
            categoryName: [model?.categoryName],
            mileageRate: [model?.mileageRate],
            receiptRequiredLimit: [model?.receiptRequiredLimit],
            isDescriptionRequired: [model?.isDescriptionRequired],
            startDate: [this.convertDateToUtc(model?.startDate)]
        });
        this.mileagePolicyListForms.push(milgForm);
    }


    addNewEntityItem(entity: any, noModel?) {
        if (noModel) {
            var newModel = new polyEntityMapModel();
            newModel.id = entity?.id;
            newModel.hierarchyText = entity?.hierarchyText;
            newModel.hierarchyTypeId = entity?.hierarchyTypeId;
            newModel.hierarchyTypeName = entity?.hierarchyTypeName;
            newModel.hierarchyValue = entity?.hierarchyValue;
            newModel.isActive = entity?.isActive;
            this.totalPolcyModel.entityMapList.push(newModel);
        }

        let entityForm = this.formBuilder.group({
            hierarchyText: [entity.hierarchyText],
            hierarchyTypeId: [entity.hierarchyTypeId],
            hierarchyTypeName: [entity.name],
            hierarchyValue: [entity?.hierarchyValue],
            isActive: [entity?.isActive]
        });
        this.entityMapRequestForms.push(entityForm);
    }

    convertDateToUtc(date) {
        let selectedDate = moment(moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD')).utc(true).toISOString(false);
        return selectedDate;
    }

    /*initial set up end*/

    /*api calls */
    getPolicyApi(info: PolyUpdateModel) {
        let polModel = new PolyUpdateModel();
        polModel.policyGUID = info["policyGUID"];
        polModel.policyId = info["policyId"];

        this.settingService.getFinancialPol(polModel).subscribe(res => {
            this.showLoader = false;
            if (res && res?.payload) {
                this.totalPolcyModel = res.payload;

                this.totalPolcyModel.policyMileageList.forEach((item) => {
                    this.addNewMileageItem(item);
                });

                //   if (this.totalPolcyModel.policyMileageRuleList) {
                //       this.totalPolcyModel.policyMileageRuleList.forEach((item) => {
                //           this.addNewPerDiemItem(item);
                //       });
                //   }

                if (this.totalPolcyModel.entityMapList.length == 0) {
                    this.setDefaultEntityUI();
                } else {
                    var list = this.totalPolcyModel.entityMapList;
                    list.sort(function (a, b) {
                        return a.hierarchyTypeId > b.hierarchyTypeId;
                    });

                    this.totalPolcyModel.entityMapList.forEach((item) => {
                        if (item.hierarchyTypeId == 1 && item.isActive == true) {//it means legalEntity is mapped
                            this.canEditLegalEntity = true;
                        }
                        this.addNewEntityItem(item, false);
                    });
                }
                this.addDefaultSubEntity();

            } else {
                this.setCatgEntityData();
                this.toastr.error(res.errorMessage, 'Error');
            }

            this.getMappedEntityAPi(); //Note:call this api only after setting values for TotalModel
        });
    }

    getCategorylist(type: any) {
        var catgryModel = new CategoryAndMerchantModel();
        catgryModel.companyId = this.userInfo?.companyId;
        catgryModel.companyGUID = this.userInfo?.companyGuId;
        catgryModel.legalEntityId = this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId;
        catgryModel.legalEntityGUID = this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId;
        catgryModel.status = 1;
        catgryModel.categoryType = type;
        this.commonService
            .getCategorylist(catgryModel)
            .subscribe((res) => {
                if (!res.isError && res.errorCode == 0) {
                    let listarray = res.payload.categoryList;
                    if (type == 1) {
                        this.categoryList = listarray.map((list) => new CategoryList(list));
                    } else {
                        this.vehiclesList = listarray.map((list) => new CategoryList(list));
                    }

                } else if (res.isError == true) {
                    this.toastr.error(res.errorMessage, 'error');
                }
            });
    }

    createPolicyAPI() {
        this.allUIInfo.policySubmitted = true;
        if (!this.totalPolcyForm.valid) {
            if (this.totalPolcyForm.controls.policyName.status == 'INVALID') {
                return;
            }
        }
        var isEntityValid = true;
        this.totalPolcyModel.entityMapList.every((item, indx) => {
            if (item?.hierarchyTypeId == 1) {
                //--ignore
            } else {
                (this.allUIInfo.entityList[indx])['error'] = false;
                if ((item.isActive == true) &&
                    ((item?.hierarchyValue.length == 1 && item?.hierarchyValue[0] == '') || (item?.hierarchyValue.length == 0))
                ) {
                    (this.allUIInfo.entityList[indx])['error'] = true;
                    isEntityValid = false;
                }
            }
            if (!isEntityValid) {
                return false;
            }

            return true;
        });

        if (!isEntityValid) {
            return;
        }

        this.totalPolcyModel.entityMapList.forEach((item) => {
            if (item?.hierarchyTypeId == 1) {
                item.hierarchyValue = (item?.hierarchyValue).toString();
            } else {
                var strList = '';
                var nameList = '';
                if (item?.hierarchyValue.length > 0) {
                    item?.hierarchyValue.forEach((entityId) => {
                        strList = strList + entityId + ',';
                    });
                    strList = strList.substring(0, (strList.length - 1));
                }
                if (item?.hierarchyText.length > 0) {
                    item?.hierarchyText.forEach((name) => {
                        nameList = nameList + name + ',';
                    });
                    nameList = nameList.substring(0, (nameList.length - 1));
                }
                item.hierarchyValue = strList;
                item.hierarchyText = nameList;
            }
        });

        this.totalPolcyModel.legalEntityId = this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId;
        this.totalPolcyModel.legalEntityGUID = this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId;
        this.totalPolcyModel.recordType = 3;

        this.buttonDisabled = true;
        this.settingService.createFinancialPol(this.totalPolcyModel).subscribe(res => {
            this.allUIInfo.policySubmitted = false;
            this.buttonDisabled = false;
            if (!res.isError) {
                var alert = this.translate.instant('alerts.polCreate') + this.translate.instant('alerts.createsuccess');
                if (this.isEditPolicy) {
                    alert = this.translate.instant('alerts.polEdit') + this.translate.instant('alerts.updateSuccess');
                }
                this.toastr.success(alert, this.translate.instant('alerts.succes'));
                this.backToFinances();
            } else {
                this.toastr.error(res.errorMessage, 'Error');
            }
        });
    }

    getMappedEntityAPi() {
        let apiModel = new UserRcrdModel();
        apiModel.userId = this.userInfo?.userId;
        apiModel.userGUID = this.userInfo?.userGuId;
        apiModel.recordType = 3;
        this.settingService.getMappedEntities(apiModel).subscribe(res => {
            var apiMappedList = new mappedModel();
            if (!res.isError && res?.payload) {
                apiMappedList = res?.payload;
                res?.payload?.legalEntityList.forEach(ele => {
                    if (ele?.legalEntityId == this.selectedLegalEntity?.legalEntityId) {
                        this.legalEntityMapped = true;
                    }
                });
            }
            this.getCostCenterAPi(apiMappedList);
            this.getDepartmentAPi(apiMappedList);
            this.getbandAPi(apiMappedList);
            this.getTeamsApi(apiMappedList);
            this.getDesignationsAPi(apiMappedList);
            this.getUsersAPi(apiMappedList);
        });
    }

    getMapedPolyEntityLists(hierId: any) {
        var selectdList = [];
        if (this.isEditPolicy) {
            this.totalPolcyModel.entityMapList.forEach(ele => {
                if (ele.hierarchyTypeId == hierId) {
                    selectdList = JSON.parse(JSON.stringify(((ele.hierarchyValue).toString()).split(',')));
                }
            });
        }

        return selectdList;
    }

    setSelectdKey(list: any) {
        var i = 0;
        list.forEach(element => {
            element['isSelected'] = false;
            list[i] = element;
            i = i + 1;
        });
    }

    getCostCenterAPi(mappedList: any) {
        let apiModel = new entityModels();
        apiModel.legalEntityId = this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId;
        apiModel.legalEntityGuid = this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId;
        apiModel.statusId = 1;
        (this.allUIInfo.entityList[1])['loader'] = true;
        this.settingService.getCostCenterList(apiModel).subscribe(res => {
            (this.allUIInfo.entityList[1])['loader'] = false;
            if (!res.isError && res?.payload?.costCenterList) {
                var list = res?.payload?.costCenterList;
                this.setEntityDropDownList(this.allUIInfo.labelArray[0], list, mappedList);
            }
        });
    }

    getDepartmentAPi(mappedList: any) {
        let apiModel = new entityModels();
        apiModel.legalEntityId = this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId;
        apiModel.legalEntityGuid = this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId;
        apiModel.statusId = 1;
        (this.allUIInfo.entityList[2])['loader'] = true;
        this.settingService.getDepartmentsist(apiModel).subscribe(res => {
            (this.allUIInfo.entityList[2])['loader'] = false;
            if (!res.isError && res?.payload?.departmentList) {
                var list = res?.payload?.departmentList;
                this.setEntityDropDownList(this.allUIInfo.labelArray[1], list, mappedList);
            } else {
            }
        });
    }

    getbandAPi(mappedList: any) {
        let apiModel = new entityModels();
        apiModel.legalEntityId = this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId;
        apiModel.legalEntityGuid = this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId;
        apiModel.statusId = 1;
        (this.allUIInfo.entityList[3])['loader'] = true;
        this.settingService.getBrandsList(apiModel).subscribe(res => {
            (this.allUIInfo.entityList[3])['loader'] = false;
            if (!res.isError && res?.payload?.bandList) {
                var list = res?.payload?.bandList;
                this.setEntityDropDownList(this.allUIInfo.labelArray[2], list, mappedList);
            } else {
            }
        });
    }

    getTeamsApi(mappedList: any) {
        (this.allUIInfo.entityList[4])['loader'] = true;
        this.settingService.getTeamssList({
            "legalEntityId": this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId,
            "legalEntityGUID": this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId,
            "statusId": 1,
        }).subscribe(res => {
            (this.allUIInfo.entityList[4])['loader'] = false;
            if (!res.isError && res?.payload?.teamsList) {
                var list = res?.payload?.teamsList;
                this.setEntityDropDownList(this.allUIInfo.labelArray[3], list, mappedList);
            } else {
            }
        });
    }

    getDesignationsAPi(mappedList: any) {
        let apiModel = new entityModels();
        apiModel.legalEntityId = this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId;
        apiModel.legalEntityGuid = this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId;
        apiModel.statusId = 1;
        (this.allUIInfo.entityList[5])['loader'] = true;
        this.settingService.getDesignationsList(apiModel).subscribe(res => {
            (this.allUIInfo.entityList[5])['loader'] = false;
            if (!res.isError && res?.payload?.designationList) {
                var list = res?.payload?.designationList;
                this.setEntityDropDownList(this.allUIInfo.labelArray[4], list, mappedList);
            } else {
            }
        });
    }

    getUsersAPi(mappedList: any) {
        (this.allUIInfo.entityList[6])['loader'] = true;
        this.settingService.getUsersList(
            {
                "legalEntityId": this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId,
                "legalEntityGUID": this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId,
                "statusId": 1,
            }).subscribe(res => {
                if (!res.isError) {
                    (this.allUIInfo.entityList[6])['loader'] = false;
                    var list = res?.payload?.users;
                    this.setEntityDropDownList(this.allUIInfo.labelArray[5], list, mappedList);
                } else {
                }
            });
    }

    setEntityDropDownList(labelDic: any, list: any, mappedList: any) {
        let getApiList = this.getMapedPolyEntityLists(labelDic.hierId);
        this.setSelectdKey(list);
        mappedList[labelDic.mappedKey].forEach(mapedEle => {
            let index = list.findIndex((item) => item[labelDic.idKey] == mapedEle[labelDic.idKey]);
            if (index > -1) {
                let matchIndx = getApiList.findIndex((item) => item == mapedEle[labelDic.idKey]);
                if (matchIndx > -1) {
                    var editDic = list[index];
                    editDic['isSelected'] = true;
                    list[index] = editDic;
                } else {
                    list.splice(index, 1);
                }
            }
        });

        this.allEntitysLists[labelDic.hierId] = list;
    }


    getApprovalWorkflows() {
        let model = {
            "legalEntityId": this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId,
            "legalEntityGUID": this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId,
            "recordType": 3,
            "statusId": 1,
        };
        this.settingService.getApprovalWorkflows(model).subscribe(res => {
            if (!res.isError) {
                this.allUIInfo.approvlFlowList = res?.payload;
            } else {
            }
        });
    }

    /*api calls end */

    selectRate(rateType: any) {
        this.totalPolcyModel.defaultMillageUnit = rateType;
    }

    changeStartDate(e: any, i: any, formItem, isPerDiem?) {
        var date: any;
        if (this.platformType == 1) {
            date = this.datechangeService.convertCalendarToAPI(e.target.value);
        } else {
            // date = moment(new Date(e.detail.value)).format(Constants.mobileDateFormat);
        }

        if (isPerDiem) {
            this.rulesModel.startDate = date;
            this.rulesForm.controls.startDate.patchValue(this.convertDateToUtc(date));
        } else {
            this.totalPolcyModel.policyMileageList[i].startDate = date;
            formItem.controls.startDate.patchValue(this.convertDateToUtc(this.totalPolcyModel.policyMileageList[i].startDate));
        }
    }

    showHideToggle(i) {
        this.allUIInfo.showDotBtns = i;
    }

    onCategTabChange(e) {
        this.currentMatTab = e.index;
        this.setRulesDatasource();
    }

    setRulesDatasource() {
        this.rulesSource = new MatTableDataSource(this.totalPolcyModel.policyMileageRuleList);
    }

    changeCatgName(e: any, index: any, item?) {
        if (index > 0 || index == 0) {
            this.totalPolcyModel.policyMileageList[index].categoryId = item?.categoryId;
            this.totalPolcyModel.policyMileageList[index].categoryName = item?.categoryName;
            this.totalPolcyModel.policyMileageList[index].mileageRate = item?.mileageRate;
        } else {
            this.rulesModel.categoryId = item?.categoryId;
            this.rulesModel.categoryName = item?.categoryName;
        }
    }

    selectedApprovlType(e: any, item?) {
        this.totalPolcyModel.approvalWorkflowId = item?.approvalWorkflowId;
        this.totalPolcyModel.approvalWorkflowName = item?.workflowName;
    }

    selectedEntityOption(e: any, i: any, subIndex: any, entity: any, hierId: any, entityListIndx?) {
        let valList = this.totalPolcyModel.entityMapList[i].hierarchyValue;
        let nameList = this.totalPolcyModel.entityMapList[i].hierarchyText;
        var list = this.allEntitysLists[(this.totalPolcyModel.entityMapList[i].hierarchyTypeId)];
        if (valList[subIndex]) {
            this.selectDeselectEntityList(i, subIndex);
        }

        (list[entityListIndx])['isSelected'] = true;
        let labelArrayIndx = this.allUIInfo.labelArray.findIndex((item) => item.hierId == hierId);
        let idKey = (this.allUIInfo.labelArray[labelArrayIndx]).idKey;
        let nameKey = (this.allUIInfo.labelArray[labelArrayIndx]).nameKey;
        valList[subIndex] = (entity[idKey]).toString();
        nameList[subIndex] = entity[nameKey];
    }

    addNewSubEntity(i: any) {
        this.totalPolcyModel.entityMapList[i].hierarchyValue.push('');
        this.totalPolcyModel.entityMapList[i].hierarchyText.push('');
        let hierID = (i + 1).toString();
        this.addSubEntityForm('', hierID);
    }

    deleteSubEntity(entityIndx: any, index: any) {
        this.selectDeselectEntityList(entityIndx, index);
        this.totalPolcyModel.entityMapList[entityIndx].hierarchyValue.splice(index, 1);
        this.totalPolcyModel.entityMapList[entityIndx].hierarchyText.splice(index, 1);
        let hierID = (entityIndx + 1).toString();
        (this.entityMapForm.get(hierID) as FormArray).removeAt(index);
    }

    selectDeselectEntityList(entityIndex, index) {
        let hierId = (this.totalPolcyModel.entityMapList[entityIndex]).hierarchyTypeId;
        var list = this.allEntitysLists[hierId];
        let currentID = JSON.parse(JSON.stringify((this.totalPolcyModel.entityMapList[entityIndex].hierarchyValue)[index]));
        var idIndx = -1;

        let labelArrayIndx = this.allUIInfo.labelArray.findIndex((item) => item.hierId == hierId);
        let idKey = (this.allUIInfo.labelArray[labelArrayIndx]).idKey;
        idIndx = list.findIndex((item) => item[idKey] == currentID);

        if (idIndx > -1) {
            (list[idIndx])['isSelected'] = false;
        }
    }

    changedEntityToggle(e: any, index: any) {
        this.totalPolcyModel.entityMapList[index].isActive = e?.detail.checked;
    }

    /*----Design view starts here---*/
    changeStage(isNext: Boolean) {
        if (this.currentStage == 0) {
            this.allUIInfo.policySubmitted = true;
            if (!this.totalPolcyForm.valid) {
                return;
            }
            this.allUIInfo.policySubmitted = false;
        }

        if (isNext) {
            if (this.currentStage == 2) {
                //call api
            } else {
                this.currentStage = this.currentStage + 1;
            }

            if (this.currentStage == 1) {
                this.navigateToDesignView();
            } else if (this.currentStage == 2) {
                this.navigateToPublishView();
            }
        } else {
            this.currentStage = this.currentStage - 1;
            if (this.currentStage == 0) {
                this.backToCreateView();
            } else if (this.currentStage == 1) {
                this.backToDesignView();
            }
        }

        if (this.currentStage == 1) {
            this.currentMatTab = 0;
            this.setRulesDatasource();
        }
    }

    navigateToDesignView() {
        this.createView = false;
        this.designView = true;
        this.publishView = false;
    }

    navigateToPublishView() {
        this.createView = false;
        this.designView = false;
        this.publishView = true;
    }

    backToCreateView() {
        this.createView = true;
        this.designView = false;
        this.publishView = false;
    }

    backToDesignView() {
        this.createView = false;
        this.designView = true;
        this.publishView = false;
    }

    /*----Design view ends here---*/

    applyFilter(filterValue: string) {
        this.rulesSource.filter = filterValue.trim().toLowerCase();
    }

    handlePage(e) { }

    deleteRule(index: any) {
        this.totalPolcyModel.policyMileageRuleList.splice(index, 1);
        this.allUIInfo.showDotBtns = -1;
        this.setRulesDatasource();
    }

    disablePerDiem(index: any) {
        if (this.totalPolcyModel.policyMileageRuleList[index].statusId && (this.totalPolcyModel.policyMileageRuleList[index].statusId == 1)) {
            this.totalPolcyModel.policyMileageRuleList[index].statusId = 2;
        } else {
            this.totalPolcyModel.policyMileageRuleList[index].statusId = 1;
        }
        this.allUIInfo.showDotBtns = -1;
        this.setRulesDatasource();
    }

    cancelRuleAction() {
        this.modalRef.hide();
    }

    dismissPerDiem() {
        this.modalRef.hide();
    }

    numberValidation(event: any) {
        const pattern = /[0-9.,]/;
        let inputChar = String.fromCharCode(event.charCode);

        if (!pattern.test(inputChar)) {
            // invalid character, prevent input
            event.preventDefault();
        }
    }

    /*Mileage rule start*/

    editPerDiemForm(EditGenralForm: TemplateRef<any>, index: any, isDefault?) {
        this.allUIInfo.currentRuleIndex = index;
        this.rulesModel = new LCMileageRule();
        //   this.rulesModel.categoryName = 'Four Wheeler';
        //   this.rulesModel.categoryId = 96;
        this.showCities = false;

        if (index != -1) {//if index is -1 then it is add per Diem
            this.rulesModel = JSON.parse(JSON.stringify(this.totalPolcyModel.policyMileageRuleList[index]));
        } else if (isDefault) {//---new per diem
            this.rulesModel.statusId = 1;
            this.rulesModel.isDefault = isDefault;
        } else {//---new per diem
            this.rulesModel.statusId = 1;
            this.rulesModel.isDefault = false;
        }

        if (!this.rulesModel.isDefault) {
            this.rulesForm = this.formBuilder.group({
                countryName: [this.rulesModel?.countryName, Validators.required],
                cityName: [this.rulesModel?.cityName],
                ruleName: [this.rulesModel?.ruleName],
                currencyCode: [this.rulesModel?.currencyCode, Validators.required],
                limitValue: [this.rulesModel?.limitValue, Validators.required],
                ruleType: [this.rulesModel?.ruleType],
                categoryName: [this.rulesModel?.categoryName],
                categoryId: [this.rulesModel?.categoryId]
                // startDate: [this.convertDateToUtc(this.rulesModel?.startDate)]
            });
        } else {
            this.rulesForm = this.formBuilder.group({
                ruleName: [this.rulesModel?.ruleName],
                currencyCode: [this.rulesModel?.currencyCode, Validators.required],
                limitValue: [this.rulesModel?.limitValue, Validators.required],
                ruleType: [this.rulesModel?.ruleType],
                categoryName: [this.rulesModel?.categoryName],
                categoryId: [this.rulesModel?.categoryId]
                // startDate: [this.convertDateToUtc(this.rulesModel?.startDate)]
            });
        }

        const config = {
            ignoreBackdropClick: true,
            class: "modal-md modal-dialog-centered",
            keyboard: false,
        };
        this.modalRef = this.modalService.show(EditGenralForm, config);
        this.allUIInfo.showDotBtns = -1;
    }

    savePerDiem() {
        this.allUIInfo.rulesSubmitted = true;
        if (!this.rulesForm.valid) {
            return;
        }
        this.allUIInfo.rulesSubmitted = false;
        this.rulesModel.limitValue = Number(this.rulesModel.limitValue);
        if (this.allUIInfo.currentRuleIndex == -1) {//adding new rule if index = -1
            this.totalPolcyModel.policyMileageRuleList.push(this.rulesModel);
        } else {
            this.totalPolcyModel.policyMileageRuleList[this.allUIInfo.currentRuleIndex] = this.rulesModel;
        }

        this.allUIInfo.showDotBtns = -1;
        this.setRulesDatasource();
        this.modalRef.hide();
    }

    categoryAndMerchantModel = new CategoryAndMerchantModel();
    currencyList: any;
    countryList: any;

    getCurrencylist() {
        this.categoryAndMerchantModel.legalEntityId = this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId;
        this.categoryAndMerchantModel.legalEntityGUID = this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId;
        this.commonService.getCurrencylist(this.categoryAndMerchantModel).subscribe((res) => {
            if (!res.isError && res.errorCode == 0) {
                let listarray = res.payload;
                this.currencyList = listarray.map((list) => new CurrencyList(list));
                //this.currencyList = _.uniqBy(this.currencyList, 'currencyId');
                this.currencyList.forEach((item, i) => {
                    if (item.currencyId === 64) {
                        this.currencyList.splice(i, 1);
                        this.currencyList.unshift(item);
                    }
                });
            } else if (!res.isError && res.errorCode == 2) {
                this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
            }
        });
    }

    changeCountryName(e: any, item?) {
        this.rulesModel.countryCode = item?.shortCode;
        this.rulesModel.countryId = item?.countryId;
        this.rulesModel.countryName = item?.countryName;
    }

    changeCurrency(e: any, item: any) {
        this.rulesModel.currencyCode = item?.currencyCode;
        this.rulesModel.currencyId = item?.currencyId;
    }

    /*google location */
    showCities = false;
    toCitiesList = [];
    showcitySearchListSpinner = false;
    private geocoder: any;
    sessionTokenEnable = true;
    sessionToken: any;
    requestType: any;
    @ViewChild('togoglemapCity', { static: false }) googleSearchAutoComplete: ElementRef;

    selectFromMap(e) {
        e.preventDefault();
        e.stopPropagation();
        this.showCities = true;
        this.toCitiesList = [];
        // const targetElem = document.getElementById("test11");
        //   setTimeout(function waitTargetElem() {
        //    if (document.body.contains(targetElem)) {
        //      targetElem.focus();
        //    }
        //   //  else {
        //   //    setTimeout(waitTargetElem, 100);
        //   //  }
        //  }, 100);

        setTimeout(() => {
            this.googleSearchAutoComplete.nativeElement.focus();
        }, 100);
    }

    onFromFocus(e, isFrom: boolean) {
        e.preventDefault();
        e.stopPropagation();
        this.toCitiesList = [];
    }

    public setFocusTest(input: string) {
        const targetElem = document.getElementById(input);
        setTimeout(function waitTargetElem() {
            if (document.body.contains(targetElem)) {
                targetElem.focus();
            } else {
                setTimeout(waitTargetElem, 100);
            }
        }, 500);
    }

    googleAutoCompleteSearch(val) {
        var searchText = searchText = val.currentTarget.value.trim();
        let domesticpredictions = [];
        if (searchText.length > 2) {
            this.showcitySearchListSpinner = true;
            const displaySuggestions = (predictions, status) => {
                // if (status == google.maps.places.PlacesServiceStatus.OK) {
                //     this.showcitySearchListSpinner = false;

                //     predictions.forEach((prediction) => {
                //         domesticpredictions.push(prediction);
                //     });
                //     var dcountry = this;
                //     dcountry.toCitiesList = domesticpredictions;
                // }
                ;
            };

            if (this.sessionTokenEnable) {
                // this.sessionToken = new google.maps.places.AutocompleteSessionToken();
                // this.sessionTokenEnable = false;
            }
            let requestType = {
                input: searchText,
                types: ['(cities)'],
                componentRestrictions: { country: this.rulesModel.countryCode },
                sessionToken: this.sessionToken,
            };

            // const service = new google.maps.places.AutocompleteService();
            // service.getPlacePredictions(requestType, displaySuggestions);
        }
    }

    addCityandCountry(event, element: any, index?: any) {
        event.preventDefault();
        event.stopPropagation();
        this.geocodeAddress(element.description, element.place_id);
    }

    geocodeAddress(location: string, place_id?) {
        // this.geocoder = new google.maps.Geocoder();
        this.geocoder.geocode({ 'address': location }, (results, status) => {
            // this.ngZone.run(() => {
            //     if (status == google.maps.GeocoderStatus.OK) {
            //         this.rulesModel.cityName = results[0].address_components[0].long_name;
            //         this.showCities = false;
            //     }
            // });
        });
    }

    removeGoogleocationdetails(i, fromOrTo) {
        this.rulesModel.cityName = "";
    }

    /*google locatin end*/

    openedChange(opened: boolean) {
    }
    onSubmit(e: any) {
    }
}
