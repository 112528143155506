import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { PlatformService } from "src/app/services/platformbase.service";
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Category, CategoryList } from "src/app/models/Settings/category.model";
import { ToastrService } from "ngx-toastr";
import { AppConfig } from 'src/app/config/app.config';
import { SettingsService } from '../../../services/settings-services'
import { NavController } from '@ionic/angular';
import { ExpensesService } from "src/app/services/expenses.service";
import * as _ from "lodash";

@Component({
  selector: "app-add-master-category",
  templateUrl: "./add-master-category.component.html",
  styleUrls: ["./add-master-category.component.scss"],
})
export class AddMasterCategoryComponent implements OnInit {

  categoryList:any;
  selectedItems: any;
  restrictedCategories = [];
  restrictedServices = [];
  platformType: number;
  expenseCategoryForm: FormGroup;
  displayTitle: string = "Add New";
  categoryModel: any = new Category();
  showLoader: boolean = false;
  submitted: boolean = false;
  buttonDisabled: boolean = false;
  validationMsg: any;
  loaderList: any = [1,2,3];
  selectedLegalEntity: any;
  userInfo: any;
  @ViewChild("categoryfocusOff", { static: false }) categoryfocus: ElementRef;

  showCatOptions = false;
  addSubCategory = false;

  child_cats = [];
  child_cats_temp = [];

  productsList = [
    { productId: 1, productName: 'Flights' },
    { productId: 2, productName: 'Hotel' },
    { productId: 3, productName: 'Visa' },
    { productId: 4, productName: 'Forex' },
    { productId: 5, productName: 'Calling Card' },
    { productId: 6, productName: 'Cabs' },
    { productId: 7, productName: 'Insurance' },
    { productId: 8, productName: 'Bus' },
    { productId: 9, productName: 'Rail' }
  ];

  open_dropdown() {
    this.showCatOptions = true;
    setTimeout(() => {
      this.categoryfocus?.nativeElement?.focus();
    }, 100);
  }
  addToSubCategory(ev) {
    this.addSubCategory = ev.detail.checked;
    // console.log(this.addSubCategory);
    if(!this.addSubCategory) {
      this.categoryModel.parentCategory = null;
      this.categoryModel.parentCategoryId = null;
      this.expenseCategoryForm.controls.parentCategoryId.reset();
    }
  }

  onCategorySearch(e) {
    let cats = JSON.parse(sessionStorage.getItem('parent-categoryList'));
    let categoriesList = cats?.filter((c) => c.categoryName?.toLowerCase().includes(e.target.value?.toLowerCase()))

    if(!e.target.value || e.target.value == '') {
      this.child_cats = this.settingsService.getSubCategories(categoriesList);
    } else {
      this.child_cats = this.settingsService.searchSubCategories(categoriesList);
    }
  }


  constructor(
    private platformService: PlatformService,
    private router: NavController,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private settingsService: SettingsService,
    private toastr: ToastrService,
    private expenseService: ExpensesService
  ) {
    this.expenseCategoryForm = this.fb.group({
      categoryName: ["", Validators.required],
      description: [""],
      parentCategory: [""],
      parentCategoryId: [""],
      claimTypeIds: [""],
      claimTypeNames: [""]
    });
  }

  ngOnInit() {}

  ionViewWillEnter(){

    this.categoryList = JSON.parse(sessionStorage.getItem('parent-categoryList'));
    console.log('category list :',this.categoryList);

    this.platformType = this.platformService.getPlatformType();
    this.selectedLegalEntity = JSON.parse(sessionStorage.getItem('selectedLegalEntity'));
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.categoryModel.categoryId = this.activatedRoute.snapshot.params["id"];
    this.categoryModel.categoryGUID = this.activatedRoute.snapshot.params["guid"];
    let pId = this.activatedRoute.snapshot.params["pId"]; 
    this.categoryModel.parentCategoryId = pId != ''? pId : null;
    this.categoryModel.legalEntityGUID = this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId;
    this.categoryModel.legalEntityId = this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId;
    this.getSubCategories(pId != ''? pId : null);
    if (this.categoryModel.categoryId) {
      this.displayTitle = "Update";      
      this.getSingleCategory(pId != ''? pId : null);
    }
    this.getClaimTypes();
    this.getExpenseTypes();
    this.getMileageCategories();
  }

  getSubCategories(pId) {
    let categoriesList = JSON.parse(sessionStorage.getItem('parent-categoryList'));
    // if(pId) {
      // this.addSubCategory = true;
      // let index = categoriesList.findIndex(c => c.parentCategoryId == pId);
      // this.categoryModel.parentCategory = categoriesList[index]?.categoryName;
      // this.categoryModel.parentCategoryId = categoriesList[index]?.categoryId;
      // this.expenseCategoryForm.controls.parentCategoryId.patchValue(categoriesList[index]?.categoryId);
    // }
    this.child_cats = this.settingsService.getSubCategories(categoriesList);
    this.child_cats_temp = [...this.child_cats];
  }

  getSingleCategory(pId?) {
    this.showLoader = true;
    console.log('rajesh :',this.categoryModel);
    this.settingsService.getSingleCategory(this.categoryModel).subscribe((data) => {
        if (!data.isError) {
          this.showLoader = false;
          this.categoryModel = new CategoryList(data.payload);
          this.restrictedCategories = data.payload?.restrictedCategories || [];
          this.restrictedServices = data.payload?.restrictedServices || [];
          if(this.restrictedCategories?.length == 0) {
            this.addRestrictedCategories();
          }
          console.log('sdsdsds',this.categoryModel,pId);
          if(pId) {
            this.addSubCategory = true;
            let categoriesList = JSON.parse(sessionStorage.getItem('parent-categoryList'));
            let index = categoriesList.findIndex(c => c.categoryId == pId);
            this.categoryModel.parentCategory = categoriesList[index]?.categoryName;
            this.categoryModel.parentCategoryId = categoriesList[index]?.categoryId;
            this.expenseCategoryForm.controls.parentCategoryId.patchValue(categoriesList[index]?.categoryId);

          }
          this.categoryModel.selectedClaimTypeIds = data.payload?.claimTypeIds?.split(',')?.map(m => parseInt(m, 10)) || [];
          this.categoryModel.selectedExpenseTypeIds = data.payload?.expenseTypeOptionIds?.split(',')?.map(m => parseInt(m, 10)) || [];
          

          // VERY IMPORT FILTERS
          // const result = _.flatMap(this.categoryList, item => {
          //   // Split the categoryResricted string into an array
          //   const restrictedCategories = item.categoryResricted ? item.categoryResricted.split(", ") : [];
          //   console.log('one',restrictedCategories);
          //   // Find the intersection between the two arrays
          //   const commonCategories = _.intersection(restrictedCategories, secondList);
          //   console.log('two',commonCategories);
          //   // Return an array of objects containing categoryId and categoryName
          //   return commonCategories.map(categoryName => ({
          //     categoryId: item.categoryId,
          //     categoryName
          //   }));
          // });

        

          console.log('model is :',this.categoryModel);
          if(this.categoryModel.categoryRestricted != null){
            // const newArray = this.categoryModel.categoryRestricted.map(item => item.trim());
            const secondArray = this.categoryModel.categoryRestricted.split(",");
            // Filter the first array based on the category names in the second array
            const filteredArray = _.filter(this.categoryList, item => _.includes(secondArray, item.categoryName));

            // Extract the categoryId values from the filtered array
            this.selectedItems = [];
            this.selectedItems = _.map(filteredArray, 'categoryId');

          }
         
        } else {
          this.toastr.error(data.errorMessage, "Error");
        }
      });
  }

  selectedParentCategory(ev) {
    this.categoryModel.parentCategory = ev?.option?.value?.categoryName;
    this.categoryModel.parentCategoryId = ev?.option?.value?.categoryId;
    this.expenseCategoryForm.controls.parentCategoryId.patchValue(ev?.option?.value?.categoryId);
  }

  addorUpdateCategory() {
    let navInfo = JSON.parse(sessionStorage.getItem("toPathInfo"));
    this.categoryModel.categoryType = 1;
    if(navInfo && navInfo.categoryType) {
      this.categoryModel.categoryType = navInfo.categoryType;
    }
    this.categoryModel.status = 1;
    this.submitted = true;
    if (!this.expenseCategoryForm.valid) {
      return;
    } else {
      this.buttonDisabled = true;

      this.categoryModel.claimTypeIds = this.selectedClaimTypes?.map(obj => obj.value).join(',') || null;
      this.categoryModel.claimTypeNames = this.selectedClaimTypes?.map(obj => obj.text).join(',') || null;
      this.categoryModel.expenseTypeOptionIds = this.selectedExpenseTypes?.map(obj => obj.value).join(',') || null;
      this.categoryModel.expenseTypeOptionNames = this.selectedExpenseTypes?.map(obj => obj.text).join(',') || null;
      // this.categoryModel.categoryRestricted = this.selectedItems.join(',');
      
      this.categoryModel.restrictedCategories = this.restrictedCategories;
      this.categoryModel.restrictedServices = this.restrictedServices;
      console.log(this.categoryModel);

      this.settingsService.saveCategory(this.categoryModel).subscribe((data) => {
        if(!data.isError){
          this.buttonDisabled = false;
          this.toastr.success(data.payload, 'Success');
          this.backToExpense();
        }else {
          this.buttonDisabled = false;
          this.toastr.error(data.errorMessage, 'Error');
          this.backToExpense();
        }
      });
    }
  }

  backToExpense() {
    this.router.navigateRoot(["/settings/expensecategory"]);
  }

  get f() {
    return this.expenseCategoryForm.controls;
  }

  claimTypes: any = [];
  enableCategoryFor: any = [];
  claimTypesTemp: any = [];
  getClaimTypes() {
    const obj = {
      "action": "claim_types",
      "legalEntityGuid": this.userInfo.legalEntityGuId,
      "legalEntityId": this.userInfo.legalEntityId,
      "companyId": this.userInfo.companyId,
      "companyGUID": this.userInfo.companyGuId,
    }
    this.expenseService.getClaimTypes(obj).subscribe(res => {
      this.claimTypes = res.payload;
      this.claimTypesTemp = [...this.claimTypes];
      console.log(this.categoryModel.selectedClaimTypeIds);
      
    })
  }
  getExpenseTypes() {
    const obj = {
      "action": "expense_Type_Options",
      "legalEntityGuid": this.userInfo.legalEntityGuId,
      "legalEntityId": this.userInfo.legalEntityId,
      "companyId": this.userInfo.companyId,
      "companyGUID": this.userInfo.companyGuId,
    }
    this.expenseService.getClaimTypes(obj).subscribe(res => {
      // console.log(res.payload)
      this.enableCategoryFor = res.payload;
      // this.claimTypesTemp = [...this.claimTypes];
      // console.log(this.categoryModel.selectedClaimTypeIds);
    })
  }

  selectedClaimTypes: any = [];
  selectedExpenseTypes: any = [];
  selectedClaimTypeIds: any = [];
  changeClaimTypes(ev) {
    this.selectedClaimTypes = ev || [];
  }
  changeExpenseTypes(ev) {
    this.selectedExpenseTypes = ev || [];
  }

  selectedClaimCategory: any = [];


  changeClaimcategoy(ev){
    this.selectedClaimCategory = ev || [];
    console.log('category id :',this.selectedClaimCategory);
    this.categoryModel.categoryRestricted = '';
    this.categoryModel.categoryRestricted = this.selectedClaimCategory.map(item => item.text).join(',');
    console.log('concatnate values :',this.categoryModel.categoryRestricted);
  }

  addRestrictedCategories() {
    this.restrictedCategories.push({
      categoryId: null,
      categoryName: null,
      claimTypeIds: null,
      claimTypeNames: null,
    })
  }

  removeRestrictedCategories(i) {
    this.restrictedCategories.splice(i, 1);
  }

  addRestrictedServices() {
    this.restrictedServices.push({
      productId: null,
      productName: null,
      claimTypeIds: null,
      claimTypeNames: null,
    })
  }

  removeRestrictedServices(i) {
    this.restrictedServices.splice(i, 1);
  }

  changeRstrctdCat(ev, index, type) {
    if(type=='category') {
      this.restrictedCategories[index].categoryId = ev.categoryId;
      this.restrictedCategories[index].categoryName = ev.categoryName;
    } else if (type=='claimType') {
      this.restrictedCategories[index].claimTypeIds =  ev.map(e => `${e.id}`);
      this.restrictedCategories[index].claimTypeNames =  ev.map(e => `${e.claimType}`);
    }
    
  }

  mileageCategoriesList: any = [];
  getMileageCategories() {
    let payload = {
      legalEntityId: this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId,
      legalEntityGUID: this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId
    }
    this.settingsService.mileageList(payload).subscribe((data: any) => {
      this.mileageCategoriesList = data?.payload?.mileageList || [];
      this.categoryList = this.categoryList?.concat(this.mileageCategoriesList);
    });
  }

  changeRestrictedServices(ev, index, type) {
    if(type=='service') {
      this.restrictedServices[index].productId = ev.productId;
      this.restrictedServices[index].productName = ev.productName;
    } else if (type=='claimType') {
      this.restrictedServices[index].claimTypeIds =  ev.map(e => `${e.id}`);
      this.restrictedServices[index].claimTypeNames =  ev.map(e => `${e.claimType}`);
    }
    
  }
}
