import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { Constants } from "../Constants";
import { HttpClient } from "@angular/common/http";


@Injectable({
  providedIn: "root",
})
export class LcService {

  constructor(public httpClient: HttpClient) {}

  deleteLcApi(requestBody): Observable<any> {
    const url = Constants.DOMAIN_URL + "v1/local-conveyance/delete";
    const body = requestBody;
    return this.httpClient.post(url, body);
  }
}