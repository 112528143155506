import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ChangePwdModel } from 'src/app/models/Login/login.model';
import { AppConfig } from 'src/app/config/app.config';
import { SharedService } from 'src/app/services/common-services/shared.service';
import { PlatformService } from 'src/app/services/platformbase.service';
import { changePasswordModel } from 'src/app/models/Settings/selecteLegalEntity.model';
import { SettingsService } from 'src/app/services/settings-services';
import { NavController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {

  passwordForm: FormGroup;
  submitted: boolean;
  buttonDisabled: boolean = false;
  platformType: number;
  validationMsg: any;
  userInfo: any;
  fromString: string = 'not assign';

  changePwdModel = new ChangePwdModel();
  changeRequestModel = new changePasswordModel();
  applogo: string;
  pathlogo: any;

  constructor(public formBuilder: FormBuilder,
    public toaster: ToastrService,
    public platformService: PlatformService,
    private sharedService: SharedService,
    public router: NavController,
    private activatedRoute: ActivatedRoute,
    private settingService: SettingsService,) {

    // this.activatedRoute.params.subscribe(data => {
    //   this.fromString = data.fromString;
    // })
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.sharedService.getUserInfo().subscribe((val) => {
      if (val) {
        this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      } else {
        this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      }
    });

  }

  ngOnInit() {

    if (environment.appLogo == "expenses") {
      this.applogo = "noexp";
      this.pathlogo = './assets/images/Logo-EP.svg';
    } else if (environment.appLogo == "fc365") {
      this.applogo = "FC365logo";
      this.pathlogo = './assets/svg/FC365logo.svg';
    } else {
      this.applogo = "noexp";
      this.pathlogo = './assets/images/Logo-EP.svg';
    }
    let path = window.location.origin;
    // (path == 'http://localhost:8100')
    if (path == 'https://www.byjusezclaim.com') {
        this.pathlogo = './assets/images/byjuslogo.svg';
    } else {
        // this.pathlogo = './assets/images/Logo-EP.svg';
    }

    this.validationMsg = AppConfig;
    this.platformType = this.platformService.getPlatformType();

    this.passwordForm = this.formBuilder.group({
      temppassword: [''],
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
    });
  }

  backTodashboard() {
    //this.router.navigateRoot(['dashboard']);
    let typeVal = JSON.parse(sessionStorage.getItem("fc365"));
    if (typeVal) {
      this.router.navigateRoot(["moreoptions"]);
    } else {
      this.router.navigateRoot(["dashboard"]);
    }
  }


  get f() { return this.passwordForm.controls; }

  FormSubmit(val) {

    this.submitted = true;
    if (!this.passwordForm.valid) {
      return;
    } else {
      if (val.password === val.confirmPassword) {
        this.buttonDisabled = true;
        this.changeRequestModel.password = this.changePwdModel.tempPassword || '';
        this.changeRequestModel.newPassword = this.changePwdModel.password,
          this.changeRequestModel.confirmPassword = this.changePwdModel.confirmPassword
        this.changeRequestModel.user_Id = this.userInfo.userId,
          this.changeRequestModel.guid = this.userInfo.userGuId,
          this.changeRequestModel.accessToken = this.userInfo.idToken
        this.settingService.changePassword(JSON.stringify(this.changeRequestModel)).subscribe((res => {
          if (res.isError === false) {
            this.toaster.success(res.errorMessage, 'success');
            this.buttonDisabled = false;
            this.router.navigateRoot(['dashboard']);
          } else if (res.isError === true) {
            this.toaster.error(res.errorMessage, 'error');
            this.buttonDisabled = false;
            this.passwordForm.reset(this.passwordForm.value);

          }
        }));
      } else {
        this.toaster.error(
          "Create password and Confirm password should be same",
          "Error"
        );
      }
    }

  }

}
