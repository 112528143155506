export class SignUpModel {
     email: string;
     password: string;
     companyName: string;
     country: string;
     phoneNumber: number;
     otp: number;
}

export class SignUpEmail {
     emailId: string;
}
export class SignUpOTP {
     registrationId: number;
     otp: any;
}

export class SignUpCompanyCreate {
     emailId: string;
     password: string;
     baseCurrency: number;
     companyName: string;
}
export class PostSignupModel {
     firstName: string;
     lastName: string;
     emailId: string;
     password: string;
     baseCurrency: number;
     companyName: string;
}

export class LoginModel {
     email: string;
     password: string;
     actionURL:string;
     token:string;
     ssoToken: string;
     action: string;
     isEncrypted: boolean;
}

export class ForgotModel {
     email: any;
}

export class RestPwdModel {
     forgot_Password_Id: number;
     forgot_Password_Guid: string;
     password: string;
     confirmPassword: string;
}

export class ChangePwdModel{
     tempPassword:string;
     password:string;
     confirmPassword:string;
}