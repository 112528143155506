import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { NavController } from '@ionic/angular';
import { log } from 'console';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AccountConfig } from 'src/app/models/Settings/accountconfig.model';
import { financeIntegration, financeIntegrationList } from 'src/app/models/Settings/finacial-integration.model';
import { polEnableEntityModels } from 'src/app/models/Settings/policy.model';
import { tax } from 'src/app/models/Settings/tax.model';
import { AccountConfigService } from 'src/app/services/account-config.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { SharedService } from 'src/app/services/common-services/shared.service';
import { PlatformService } from 'src/app/services/platformbase.service';
import { SettingsService } from 'src/app/services/settings-services';

@Component({
  selector: 'app-finance-integration-code',
  templateUrl: './finance-integration-code.component.html',
  styleUrls: ['./finance-integration-code.component.scss'],
})
export class FinanceIntegrationCodeComponent implements OnInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('searchInput', { static: false }) searchInput;
  platformType: number;
  showLoader: boolean = true;
  showTable: boolean = false;
  dataSource: MatTableDataSource<any>;
  displayedColumns: any[] = ['financeIntegrationName', 'financeIntegrationCode', 'categoryname', 'statusId', 'disablestatus'];
  finacialIntegrationModal = new financeIntegration();
  finacialIntegrationList: any = [];
  tableDataList: any;
  loadersList: any = [1, 2, 3];
  selectedIndex: any = -1;
  pageNumber: number = 1;
  pageSize: number = 25;
  currentPage: number = 0;
  categoryListPageLimit: number[] = [5, 10, 25, 100];
  filterValue: any = "";
  categoryListTotalLength: any;
  categoryObj: any;
  statusFlag: any;
  statusMessage: string;
  isDisable: boolean = false;
  statusDisable: boolean = false;
  modalRef: BsModalRef;
  selectedLegalEntity: any;
  isApicallinProgress: boolean = false;
  buttonDisabled: Array<boolean> = [];
  ind: any;
  showSearch: boolean = true;
  sharedSubsc: any;
  categoryType = 1;
  ispcMainEnabled = false;
  userInfo: any;
  hasConfigData = false;
  paginatorStatus: boolean = false;

  constructor(private platformService: PlatformService,
    private router: NavController, private title: Title,
    private settingService: SettingsService,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private sharedService: SharedService,
    private accountConfigService: AccountConfigService,
    private authenticationService: AuthenticationService) {
    // this.sharedSubsc = this.sharedService.getLoadCatgryApi().subscribe(catType => {
    //   if (catType) {
    //     this.taxmodal.categoryType = "3";
    //     if (this.selectedLegalEntity?.legalEntityGuId && this.taxmodal.categoryType != catType) {
    //       /*1.if legalEntityExists then it means category is changed and same component is called again, so call api - as in this case component won't be relaoded again
    //       2.if legalEntity does not exists then component is caled for first time, so don't cal api as it is caled in ionView. */

    //     }
    //   }
    // });
    // this.getFinanceList();
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.sharedService.unsubscribeServic(this.sharedSubsc);
  }

  ionViewWillLeave() {
    this.sharedService.unsubscribeServic(this.sharedSubsc);
  }

  ionViewWillEnter() {
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.ispcMainEnabled = this.userInfo?.isPettyCashEnabled;
    this.platformType = this.platformService.getPlatformType();
    this.selectedLegalEntity = JSON.parse(sessionStorage.getItem('selectedLegalEntity'));
    this.getFinanceList();
    this.getAccountConfiguration();
    let applicationTitle = this.userInfo.applicationTitle;
    if(applicationTitle){
        this.title.setTitle('Finance Integration Code | Master Data | Settings' + ' | ' + applicationTitle);
    }else{ 
      this.title.setTitle("Finance Integration Code | Master Data | Settings | NoExpenses");
    }
  }

  getAccountConfiguration() {
    var modl = new AccountConfig();
    this.hasConfigData = false;
    let selectedEntity = JSON.parse(sessionStorage.getItem('selectedLegalEntity'));
    modl.legalEntityId = selectedEntity.legalEntityId;
    modl.legalEntityGUID = selectedEntity.legalEntityGuId;
    this.accountConfigService.getAccountConfig(modl).subscribe(res => {
      this.hasConfigData = true;
      if (!res.isError && res.payload) {
        this.ispcMainEnabled = res.payload?.isPettyCashEnabled;
      }
    })
  }

  loadMore(event) {
    if (!this.isApicallinProgress) {
      if (this.finacialIntegrationList.length < this.pageSize && event) {
        event.target.disabled = true;
      } else {
        this.pageNumber++;
        this.getFinanceList(event);
      }
    }
  }

  getFinanceList(event?) {
   
    this.finacialIntegrationModal.legalEntityGUID = this.selectedLegalEntity?.legalEntityGuId;
    this.finacialIntegrationModal.legalEntityId = this.selectedLegalEntity?.legalEntityId;
    this.finacialIntegrationModal.pageNumber = this.pageNumber;
    this.finacialIntegrationModal.pageSize = 100;
    this.dataSource = new MatTableDataSource();
    this.settingService.finacialIntegrationList(this.finacialIntegrationModal).subscribe(data => {
      if (!data.isError && data.payload.length > 0) {
        this.categoryListTotalLength = data.payload.length;
        this.showLoader = false;
        this.tableDataList = data.payload;
        this.showLoader = false;
        this.showTable = true;
        if (this.paginatorStatus) {
          let categoryArr = data.payload;
          let finacialIntegrationList = categoryArr.map(list => new financeIntegrationList(list));
          finacialIntegrationList.forEach(element => { this.finacialIntegrationList.push(element); });
          this.dataSource = new MatTableDataSource(this.finacialIntegrationList);
          this.dataSource.paginator = this.paginator;
          this.categoryListTotalLength = this.finacialIntegrationList.length;
        } else {
          let categoryArr = data.payload;
          this.finacialIntegrationList = categoryArr.map((list) => new financeIntegrationList(list));
          this.dataSource = new MatTableDataSource(this.finacialIntegrationList);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.categoryListTotalLength = this.finacialIntegrationList.length;

          // if (this.pageNumber == 1) {
          //   this.paginatorStatus = true;
          //   this.pageNumber++;
          //   this.getFinanceList();
          // }
        }
        this.pageNumber != 2 ? this.paginatorStatus = false : '';

        if (event) {
          event.target.complete();
        }
        if (this.finacialIntegrationList.length < this.pageSize && event) {
          event.target.disabled = true;
        }
        this.isApicallinProgress = false;
      } else {
        this.showLoader = false;
        this.showTable = true;
        
        this.finacialIntegrationList.length != 0 ? this.tableDataList = this.finacialIntegrationList : this.tableDataList = null;
        if (this.finacialIntegrationList.length != 0) {
          this.dataSource = new MatTableDataSource(this.finacialIntegrationList);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.categoryListTotalLength = this.finacialIntegrationList.length;
        }
        if (event) event.target.disabled = true;
      }
    })
  }

  handlePage(e) {
    this.currentPage = e.pageIndex;
    // this.pageSize = e.pageSize;
    if (!this.dataSource.paginator.hasNextPage()) {
      this.paginatorStatus = true;
      this.pageNumber++;
      this.getFinanceList();
    }
  }



  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.finacialIntegrationList = this.dataSource.filteredData
  }

  searchbar() {
    this.showSearch = !this.showSearch
  }

  toggleStatusDropdown(index) {
    if (this.selectedIndex != index) this.selectedIndex = index;
    else this.selectedIndex = -1;
  }

  toggleStatus(data, content, flag) {
    this.categoryObj = data;
    this.statusFlag = flag;
    this.modalRef = this.modalService.show(content, { class: 'modal-md modal-dialog-centered' });
    switch (this.statusFlag) {
      case 1: this.statusMessage = "Are you sure you want to enable GL Code ?";
        break;
      case 2: this.statusMessage = "Are you sure you want to disable GL Code ?";
        break;
    }
    this.hideDropdown();
  }

  statusAction(statusId?, ind?) {
    this.isDisable = true;
    this.statusDisable = true;
    if (statusId) {
      this.buttonDisabled[ind] = true;
      this.categoryObj = statusId;
      this.ind = ind;
    }
    this.finacialIntegrationModal.financeIntegrationId = this.categoryObj.financeIntegrationId;
    this.finacialIntegrationModal.financeIntegrationGUID = this.categoryObj.financeIntegrationGUID;
    if (this.categoryObj.statusId == 1) {
      this.settingService.disablefinacialIntegration(JSON.stringify(this.finacialIntegrationModal)).subscribe(data => {
        if (!data.isError) {
          this.toastr.success(data.payload, 'Success');
          this.statusResponse();
        } else if (data.isError) {
          this.toastr.error(data.errorMessage, 'Error');
          this.statusResponse();
        }
      });
    } else {
      this.settingService.enablefinacialIntegration(JSON.stringify(this.finacialIntegrationModal)).subscribe(data => {
        if (!data.isError) {
          this.toastr.success(data.payload, 'Success');
          this.statusResponse();
        } else if (data.isError) {
          this.toastr.error(data.errorMessage, 'Error');
          this.statusResponse();
        }
      });
    }
  }

  statusResponse() {
    this.pageNumber = 1;
    this.dataSource = new MatTableDataSource([]);
    this.paginatorStatus = false;
    console.log('data :',this.paginatorStatus);
    if (this.platformType == 1) {
      this.isDisable = false;
      this.statusDisable = false;
      this.closeWindow();
      this.searchInput.nativeElement.value = "";
    }
    if (this.platformType == 2) {
      this.buttonDisabled[this.ind] = false;
    }
    this.getFinanceList();
  }

  closeWindow() {
    this.modalRef.hide();
  }

  addNewfinance() {
    this.router.navigateRoot(['/settings/financeintegration/add']);
  }

  navigateToAddFinance(data) {
    this.router.navigateRoot(['/settings/financeintegration/update', data.financeIntegrationId, data.financeIntegrationGUID, data.statusId]);
  }

  hideDropdown() {
    if (this.selectedIndex !== -1) {
      this.selectedIndex = -1
    }
  }

  pcMainPolicyToggle(e: any) {
    if (e?.detail.checked) {
      this.enablePCMainPolicy();
    } else {
      this.disablePCMainPolicy();
    }
  }


  enablePCMainPolicy() {
    let modl = new polEnableEntityModels();
    modl.legalEntityId = this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId;
    modl.legalEntityGUID = this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId;
    this.settingService.settingsPettyCashEnable(modl).subscribe(res => {
      if (!res.isError) {
        this.ispcMainEnabled = true;
        this.tabsccess();
      }
    });
  }



  tabsccess() {
    let modl = new polEnableEntityModels();
    modl.legalEntityId = this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId;
    modl.legalEntityGUID = this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId;
    this.settingService.settingsTabsAccess(modl).subscribe(res => {
      if (!res.isError) {
        this.userInfo.isLocalConveyanceEnabled = res.payload.isLocalConveyanceEnabled
        this.userInfo.isPettyCashEnabled = res.payload.isPettyCashEnabled
        this.userInfo.isTravelEnabled = res.payload.isTravelEnabled
        sessionStorage.setItem("userInfo", JSON.stringify(this.userInfo));
        // localStorage.setItem("userInfo", JSON.stringify(this.userInfo));
        this.authenticationService.setisLocalConveyanceEnabled(res.payload.isLocalConveyanceEnabled);
        this.authenticationService.setisPettyCashEnabled(res.payload.isPettyCashEnabled);
        this.authenticationService.setisTravelEnabled(res.payload.isTravelEnabled);

        // window.location.reload();


      }
    });
  }


  disablePCMainPolicy() {
    let modl = new polEnableEntityModels();
    modl.legalEntityId = this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId;
    modl.legalEntityGUID = this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId;
    this.settingService.settingsPettyCashDisable(modl).subscribe(res => {
      if (!res.isError) {
        this.ispcMainEnabled = false;
        this.tabsccess();
      }
    });
  }
}
