import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-image-view',
  templateUrl: './image-view.component.html',
  styleUrls: ['./image-view.component.scss'],
})
export class ImageViewComponent implements OnInit {
  img: any;
  images: any;
  @Input() frompage;

  @ViewChild('slider', { read: ElementRef }) slider: ElementRef;
  sliderOpts = {
    zoom: {
      maxRatio: 3
    }
  }
  isPdf: boolean;
  pdfData: string;
  constructor(public navParams: NavParams, public modalController: ModalController) { }

  ngOnInit() {

    this.img = this.navParams.get('photo');
    console.log("image path before", this.img);
    let urlStr = this.img.fileName
    if (urlStr.split('?')[0].split('.').pop() == 'pdf') {
      this.isPdf = true;
        const linkSource = `data:application/pdf;base64,${this.img.base64}`;
        this.pdfData = linkSource;
      console.log('pdf is their');
    } else {
      this.isPdf = false;
      console.log('not pdf is their');
    }
    console.log("image path after", urlStr);

    //let urlStr = this.invoiceDetails[this.defaultIndex].url;
    // if (this.img.split('?')[0].split('.').pop() == 'pdf') {
    //     this.isPdf = true;
    //     const linkSource = `data:application/pdf;base64,${this.invoiceDetails[this.defaultIndex].base64}`;
    //     this.pdfData = linkSource;
    // } else {
    //     this.isPdf = false;
    // }
  }
  zoom(zoomIn: boolean) {
    let zoom = this.slider.nativeElement.swiper.zoom;
    if (zoomIn) {
      zoom.in();
    } else {
      zoom.out();
    }
  }
  close() {
    this.modalController.dismiss();
  }
}
