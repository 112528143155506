import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { ToastrService } from 'ngx-toastr';
import { PlatformService } from 'src/app/services/platformbase.service';
import { SettingsService } from 'src/app/services/settings-services';
import * as _ from "lodash";
import { Options, LabelType } from 'ng5-slider';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ApprovalsList, GetApprovalModel } from 'src/app/models/Settings/approval-workflow.model';
import { ExpensesService } from 'src/app/services/expenses.service';
@Component({
  selector: 'app-add-approval-workflow',
  templateUrl: './add-approval-workflow.component.html',
  styleUrls: ['./add-approval-workflow.component.scss'],
})
export class AddApprovalWorkflowComponent implements OnInit {

  platformType: number;

  CreateForm: FormGroup;
  displayTitle: string;
  createView: boolean = true;
  designView: boolean = false;
  publishView: boolean = false;
  showLoader: Boolean = false;
  loaderList = [1, 2, 3, 4];
  recordTypeList = [{ name: "Claim", recordType: 1 }, { name: "Advance", recordType: 2 }, { name: "Local Conveyance", recordType: 3 }
    , { name: "Petty Cash Claim", recordType: 4 }, { name: "Petty Cash Request", recordType: 5 }, { name: "Travel", recordType: 6 }]

  selectedRecords = [];
  @ViewChild('recordInput', { static: false }) serviceInput: ElementRef<HTMLInputElement>;
  @ViewChild('recordTypeAutoComplete', { static: false }) matAutocomplete: MatAutocomplete;
  designWorkflowForm: FormGroup;
  publishWorkflowForm: FormGroup;
  removable: boolean = true;
  selectedRadioBtn: number = -1;
  enableDisableNext = true;
  checking: Boolean = true;
  displayOptions: any = "-1";
  selectedIndex: any = "-1";
  showEdit: Array<boolean> = [];
  showDone: Array<boolean> = [];
  dataSource = [];
  showPredefinedApprovalOptions: Array<boolean> = [];
  showManualApproverOptions: Array<boolean> = [];
  replaceSelectedIndex: number = null;
  selectedOptionArray: any = [];
  currentindex: number = -1;
  status: boolean = false;
  showUserlist: Array<boolean> = [];
  departureOptions: Options = {
    floor: 0,
    ceil: 1410,
    step: 30,
    noSwitching: true,
    translate: (value: number, label: LabelType): string => {
      let min = value / 60;
      let minuteStr = "";
      let hrString = "";
      if (min % 1 != 0) {
        min = min - 0.5;
        minuteStr = "30";
        if (min < 10) {
          hrString = "0" + min.toString();
        } else {
          hrString = min.toString();
        }
      } else {
        minuteStr = "00";
        if (min < 10) {
          hrString = "0" + min.toString();
        } else {
          hrString = min.toString();
        }
      }

      switch (label) {
        case LabelType.Low:
          return hrString + ':' + minuteStr;
        case LabelType.High:
          return hrString + ':' + minuteStr;
        default:
          return hrString + ':' + minuteStr;
      }
    }
  };
  selectedHours: any = 0;
  hoursData: any;
  hoursList: any;
  minsData: any;
  minsList: any;
  selectedMins: any = 0;
  selectedReminderIntervalHrs: any = 0;
  selectedReminderIntervalMins: any = 0;
  replacingText: boolean = false;
  approvalTypeList: any;
  ListReplace: any = [];
  showList: boolean;
  buttonDisabled: boolean = false;
  disabledPublish: boolean = false;
  reminderToggle: boolean = true;
  departureMinValue: number = 0;
  departureMaxvalue: number = 0;
  checkedSendReminderEmail: boolean = false;
  checkedAllowApproverEmail: boolean = true;
  departmentHeadData: any;
  showSearchInput: boolean = false;
  tempdepartmentHeadData: any;
  listIndexValue: number = null;
  isUpdate: boolean = false;
  selectedApproverName: any;
  userInfo: any;
  selectedOptionIndex: any;
  showNotifierList: boolean = false;
  @ViewChild('usersList', { static: false }) usersListAutoCompleteReference: ElementRef;
  indexForDelete: number = -1;
  indexDeleteStatus: boolean = false;
  modalOptions: NgbModalOptions;
  removedGetItems: any[] = [];
  checkedNotifyApprovalEmail: boolean = true;
  checkedNotifyApprovalSms: boolean = false;
  checkedNotifyApprovalWhatsapp: boolean = true;
  checkedAllowApproverSms: boolean = true;
  checkedAllowApproverWhatsapp: boolean = false;
  checkedSendReminderSms: boolean = true;
  checkedSendReminderWhatsapp: boolean = true;
  delegatingSupervisorApprove: boolean = true;
  workflowSettingId: any;
  workflowSettingGuid: any;
  workFlowId: any;
  workFlowGuid: any;
  productIdArray: string;
  productNameArray: string;
  selectedLegalEntity: any;
  recordIdArray: any;
  recordNameArray: any;
  showOptionCondtion: any;
  showSelectedApprover: Array<boolean> = [];
  manualApproverOptionSelected: Array<boolean> = [];
  showSelectedManualApprover: Array<boolean> = [];
  selectedManualApproverList: any = [];
  approvalModel = new GetApprovalModel()
  approvalListModel: ApprovalsList;
  individualWorkflowData: any;
  hideLoader: Boolean = true;
  selectedRecordsNames: any;
  searchFreetext: any;
  @ViewChild('gstState', { static: false }) gstStateAutoCompleteReference: ElementRef;
  workflowListType = [{ id: 1, name: "Default" }, { id: 2, name: "Custom" }]
  showWorkLocationList: boolean;
  workFlowType: any;
  typeStr: any;
  selectedStatusId: any;
  noOfDays: number = 0;


  bandsList = [];
  constructor(private platformService: PlatformService,
    private router: NavController,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private settingsService: SettingsService, private renderer: Renderer2,
    private toastr: ToastrService, private modalService: NgbModal,
    private expenseService: ExpensesService) {
    this.renderer.addClass(document.body, "approvalworkflowspage");
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop',
      keyboard: false
    };
    this.selectedLegalEntity = JSON.parse(sessionStorage.getItem('selectedLegalEntity'));
  }

  ngOnInit() {
    this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
    this.bandsList = JSON.parse(sessionStorage.getItem('bands'));
    
    console.log('bands :',JSON.parse(sessionStorage.getItem('bands')));
    this.typeStr = parseInt(sessionStorage.getItem('typeStr'));
   // console.log(this.typeStr)
    this.publishWorkflowForm = this.fb.group({
      notifyApproverEmailControl: [true],
      notifyApproverSmsControl: [''],
      notifyApproverWhatsappControl: [''],
      allowApproverEmailControl: [''],
      allowApproverSmsControl: [''],
      allowApproverWhatsappControl: [''],
      sendReminderEmailControl: [''],
      sendReminderSmsControl: [''],
      sendReminderWhatsappControl: [''],
      approvalTatControl: [''],
      reminderIntervalTime: [''],
      delegatingSupervisorApproveControl: [''],
      sendReminderStartTime: [''],
      sendReminderEndTime: [''],
      selectedReminderIntervalHrs: ['', [ Validators.max(23), Validators.min(0)]],
       selectedReminderIntervalMins: ['', [ Validators.max(59), Validators.min(0)]],
      // selectedReminderIntervalHrs: ['', [Validators.required, Validators.max(23), Validators.min(0)]],
      // selectedReminderIntervalMins: ['', [Validators.required, Validators.max(59), Validators.min(0)]],
      noOfDays:['',[Validators.required,Validators.min(0)]]

    });
    this.CreateForm = this.fb.group({
      recordType: ["", Validators.required],
      workFlowName: ["", Validators.required],
      description: [""],
      workflowType: ["", Validators.required],
      advanceTypeId: ["", []],
      advanceTypeName: ["", []],
    });

    this.designWorkflowForm = this.fb.group({
      approvalName: ['', Validators.required],
      workflowDescription: ['', Validators.required]
    });

    this.publishWorkflowForm.patchValue({
      "notifyApproverEmail": this.convertValueToFlag(1),
      "notifyApproverSms": this.convertValueToFlag(1),
      "notifyApproverWhatsapp": this.convertValueToFlag(1),
      "allowApproverEmail": this.convertValueToFlag(1),
      "allowApproverSms": this.convertValueToFlag(1),
      "allowApproverWhatsapp": this.convertValueToFlag(1),
      "sendReminderEmail": this.convertValueToFlag(1),
      "sendReminderSms": this.convertValueToFlag(1),
      "sendReminderWhatsapp": this.convertValueToFlag(1),
      // "approvalTat": "10:10",//this.individualWorkflowData.workflowNotificationSettings.approvalTat,
      "reminderIntervalTime": "10:10",//this.individualWorkflowData.workflowNotificationSettings.reminderIntervalTime,
      "delegatingSupervisorApprove": this.convertValueToFlag(1),
      "sendReminderStartTime": 1,
      "sendReminderEndTime": 1
    });



    this.displayTitle = 'Add'
  }
  get errorControl() {
    return this.publishWorkflowForm.controls;
  }
  ionViewWillEnter() {

    this.platformType = this.platformService.getPlatformType();
    this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
    this.approvalModel.approvalWorkflowId = this.activatedRoute.snapshot.params["id"];
    this.approvalModel.approvalWorkflowGUID = this.activatedRoute.snapshot.params["guid"];
    this.selectedStatusId = this.activatedRoute.snapshot.params["statusId"];
    if (this.approvalModel.approvalWorkflowId) {
      this.getSingleApproval();
      this.isUpdate = true;
    }
    this.getClaimTypes();
    this.getWorkflowTypes();
  }
  get f() {
    return this.CreateForm.controls;

  }

  getSingleApproval() {
    this.showLoader = true;
    this.settingsService.getSingleApproval(this.approvalModel).subscribe((data) => {
      if (!data.isError) {
        this.showLoader = false;

        if (data.payload !== null) {
          this.individualWorkflowData = data.payload;
          this.enableDisableNext = false;

          this.workFlowId = this.individualWorkflowData.approvalWorkflows.approvalWorkflowId;
          this.workFlowGuid = this.individualWorkflowData.approvalWorkflows.approvalWorkflowGuid;
          this.workflowSettingId = this.individualWorkflowData.workflowNotificationSettings.workflowSettingId;
          this.workflowSettingGuid = this.individualWorkflowData.workflowNotificationSettings.workflowSettingGuid;
          this.noOfDays=this.individualWorkflowData.workflowNotificationSettings?.noOfDays||0;
          Object.assign(this.dataSource, this.individualWorkflowData.approvalWorkflowDetails);
          this.publishWorkflowForm.patchValue({
            "notifyApproverEmail": this.convertValueToFlag(this.individualWorkflowData.workflowNotificationSettings.notifyApproverEmail),
            "notifyApproverSms": this.convertValueToFlag(this.individualWorkflowData.workflowNotificationSettings.notifyApproverSms),
            "notifyApproverWhatsapp": this.convertValueToFlag(this.individualWorkflowData.workflowNotificationSettings.notifyApproverWhatsapp),
            "allowApproverEmail": this.convertValueToFlag(this.individualWorkflowData.workflowNotificationSettings.allowApproverEmail),
            "allowApproverSms": this.convertValueToFlag(this.individualWorkflowData.workflowNotificationSettings.allowApproverSms),
            "allowApproverWhatsapp": this.convertValueToFlag(this.individualWorkflowData.workflowNotificationSettings.allowApproverWhatsapp),
            "sendReminderEmail": this.convertValueToFlag(this.individualWorkflowData.workflowNotificationSettings.sendReminderEmail),
            "sendReminderSms": this.convertValueToFlag(this.individualWorkflowData.workflowNotificationSettings.sendReminderSms),
            "sendReminderWhatsapp": this.convertValueToFlag(this.individualWorkflowData.workflowNotificationSettings.sendReminderWhatsapp),
            // "approvalTat": "10:10",//this.individualWorkflowData.workflowNotificationSettings.approvalTat,
            "reminderIntervalTime": "10:10",//this.individualWorkflowData.workflowNotificationSettings.reminderIntervalTime,
            "delegatingSupervisorApprove": this.convertValueToFlag(this.individualWorkflowData.workflowNotificationSettings.delegatingSupervisorApprove),
            "sendReminderStartTime": this.individualWorkflowData.workflowNotificationSettings.sendReminderStartTime,
            "sendReminderEndTime": this.individualWorkflowData.workflowNotificationSettings.sendReminderEndTime,
          });
          // let tat = this.individualWorkflowData.workflowNotificationSettings.approvalTat.split(':')
          let remainInter = this.individualWorkflowData.workflowNotificationSettings.reminderIntervalTime.split(':') ? this.individualWorkflowData.workflowNotificationSettings.reminderIntervalTime.split(':') : '00:00:00'
          let tat = this.individualWorkflowData.workflowNotificationSettings.reminderIntervalTime.split(':') ? this.individualWorkflowData.workflowNotificationSettings.reminderIntervalTime.split(':')
            : '00:00:00'
          this.selectedHours = tat[0];
          this.selectedMins = tat[1];
          this.selectedReminderIntervalHrs = remainInter[0];
          this.selectedReminderIntervalMins = remainInter[1];
          this.departureMinValue = JSON.parse(this.individualWorkflowData.workflowNotificationSettings.sendReminderStartTime);
          this.departureMaxvalue = JSON.parse(this.individualWorkflowData.workflowNotificationSettings.sendReminderEndTime);
          this.checkedNotifyApprovalEmail = this.convertValueToFlag(this.individualWorkflowData.workflowNotificationSettings.notifyApproverEmail),
            this.checkedNotifyApprovalSms = this.convertValueToFlag(this.individualWorkflowData.workflowNotificationSettings.notifyApproverSms),
            this.checkedNotifyApprovalWhatsapp = this.convertValueToFlag(this.individualWorkflowData.workflowNotificationSettings.notifyApproverWhatsapp),
            this.checkedAllowApproverEmail = this.convertValueToFlag(this.individualWorkflowData.workflowNotificationSettings.allowApproverEmail),
            this.checkedAllowApproverSms = this.convertValueToFlag(this.individualWorkflowData.workflowNotificationSettings.allowApproverSms),
            this.checkedAllowApproverWhatsapp = this.convertValueToFlag(this.individualWorkflowData.workflowNotificationSettings.allowApproverWhatsapp),
            this.checkedSendReminderEmail = this.convertValueToFlag(this.individualWorkflowData.workflowNotificationSettings.sendReminderEmail),
            this.checkedSendReminderSms = this.convertValueToFlag(this.individualWorkflowData.workflowNotificationSettings.sendReminderSms),
            this.checkedSendReminderWhatsapp = this.convertValueToFlag(this.individualWorkflowData.workflowNotificationSettings.sendReminderWhatsapp),
            this.delegatingSupervisorApprove = this.convertValueToFlag(this.individualWorkflowData.workflowNotificationSettings.delegatingSupervisorApprove)
          this.reminderToggle = !this.checkedSendReminderEmail;
          for (let i = 0; i < this.dataSource.length; i++) {
            this.showEdit[i] = true;
            this.showDone[i] = false;
          }

          this.findFirstOne();
          this.checkPreviousStatus(-1);
          this.findIntiator();
          this.addValueIniator();

          this.patchingFormDetails();

        }
      } else {
        this.toastr.error(data.errorMessage, "Error");
      }
    });
  }

  patchingFormDetails() {

    this.selectedRecordsNames = this.individualWorkflowData.approvalWorkflows.recordTypeNames.split(',');
    for (let i = 0; i < this.recordTypeList.length; i++) {
      for (let j = 0; j < this.selectedRecordsNames.length; j++) {
        if (this.recordTypeList[i].name == this.selectedRecordsNames[j]) {
          this.selectedRecords.push(this.recordTypeList[i]);

        }
      }
    }
    this.CreateForm.patchValue({
      recordType: this.individualWorkflowData.approvalWorkflows.recordTypeNames,
      workFlowName: this.individualWorkflowData.approvalWorkflows.workflowName,
      description: this.individualWorkflowData.approvalWorkflows.workflowDesc,
      workflowType: this.individualWorkflowData.approvalWorkflows.workflowType,
      advanceTypeId: (this.individualWorkflowData?.approvalWorkflows?.advanceTypeId) ? parseInt(this.individualWorkflowData?.approvalWorkflows?.advanceTypeId) : '',
      advanceTypeName: (this.individualWorkflowData?.approvalWorkflows?.advanceTypeName) ? parseInt(this.individualWorkflowData?.approvalWorkflows?.advanceTypeName) : ''
    });
  }

  addValueIniator() {
    let obj
    this.dataSource.forEach(element => {
      if (element.approverType !== null && element.assignedTo !== "") {
        obj = {
          "approverTypeName": element.assignedTo,
          "approverTypeId": element.approverType,
          "level": element.level
        }
      } else if (element.approverId !== null && element.assignedTo !== "") {
        obj = {
          "approverTypeName": element.assignedTo,
          "approverTypeId": element.approverId,
          "level": element.level
        }
      }
      else if (element.approverType !== null) {
        obj = {
          "approverTypeName": element.assignedTo,
          "approverTypeId": element.approverType,
          "level": element.level
        }
      }
      else if (element.approverId !== null) {
        obj = {
          "approverTypeName": element.assignedTo,
          "approverTypeId": element.approverId,
          "level": element.level
        }
      }
      this.selectedOptionArray.push(obj);
    })
  }
  findFirstOne() {
    const firstIndex = this.dataSource.findIndex(element => element.isDeviationApproval == true, 1);
    if (firstIndex != -1) {
      this.activateChild(firstIndex, true);
    }
    this.showLoader = false;
    this.hideLoader = true;
  }

  convertValueToFlag(value) {

    if (value == 1) {
      return true;
    }
    else {
      return false;
    }
  }

  getWorkflowTypes() {

    this.settingsService.getApprovalWorkflowTypes({
      "legalEntityId": this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId,
      "legalEntityGUID": this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId,
    }).subscribe(data => {
      if (data) {
        this.approvalTypeList = data.payload;
        // this.tempApprovalTypeList = data.payload;
        data.payload.forEach(element => {
          if (element.approverTypeId != 1) {
            this.ListReplace.push(element);
          }
        });
        if (this.isUpdate) {
          if (this.listIndexValue == -1) {
            this.findIntiator();
          }
        }
      }
    });
  }

  checkSelectedOption(approver, index: any) {
    let value;
    if (this.selectedOptionArray.length > 0) {
      value = this.selectedOptionArray.find(element => { return element?.approverTypeName == approver.approverTypeName });
      if (value !== undefined) {
        if (value.level == index) {
          return true;
        } else {
          return false
        };
      }
      else if (value == undefined) {
        return true;
      }
    }
    else {
      return true;
    }

  }

  bandSelection(event,index){
    console.log('selected band ',event,index);
    this.dataSource[index].bandId = event.bandId;
    this.dataSource[index].bandName = event.bandName;
  }

  activateSameapprover(index: number, event: any) {
    let status = event;
    if (status) {
      this.dataSource[index].isSameApprover = true;
    } else {
      this.removeSameApprover(index);
    }

  }

  removeSameApprover(index) {
    this.tempdepartmentHeadData = [];
    this.showUserlist[index] = false;
    this.dataSource[index].isSameApprover = false;
    this.dataSource[index].sameApproverUserName = null;
    this.dataSource[index].sameApproverUser = null;
    this.dataSource[index].sameApproverType = null;
  }

  findIntiator() {
    this.listIndexValue = this.dataSource.findIndex(element => {
      if (element.assignedTo == "Initiator's Manager") {
        return element;
      }
    });
    if (this.listIndexValue !== -1) {
      this.checkIniatorManager(this.listIndexValue, false);
    }
    else if (this.listIndexValue == -1) {
      this.replaceSelectedIndex == null;
      this.replacingText == false;
    }
  }

  checkIniatorManager(index: any, conditions: boolean) {
    if (conditions == false || this.selectedApproverName.approverTypeName == "Initiator's Manager") {
      if (this.replacingText == false) {
        this.replaceSelectedIndex = index;
        let find = this.ListReplace.findIndex(element => { return element.approverTypeName == "Hierarchical Manager" })
        if (this.replaceSelectedIndex != null || this.replaceSelectedIndex !== -1) {
          if (find == -1) {
            let obj = {
              "approverTypeId": 1,
              "approverTypeName": "Hierarchical Manager",
              "statusId": 1
            }
            this.ListReplace.unshift(obj);
            this.replacingText = true;
          }
          else if (find != -1) {
            this.replacingText = true;
          }
        }
        else if (this.replaceSelectedIndex == -1) {
          this.replaceSelectedIndex = null
        }
      }
    }

  }
  addorUpdatePolicy() {

  }
  backTotable() {
    this.router.navigateRoot(["settings/approvalworkflow"]);
  }

  nextTab() { }

  navigateToDesignView() {
    this.createView = false;
    this.designView = true;
    this.publishView = false;
  }

  getProductsNames() {
    return _.map(this.selectedRecords, 'name').join(',');
  }
  selected(event: MatAutocompleteSelectedEvent): void {
    if (this.selectedRecords.indexOf(event.option.value) == -1) this.selectedRecords.push(event.option.value);
    this.CreateForm.patchValue({
      recordType: this.getProductsNames()
    });
    if(this.userInfo?.isEnableAdvanceTypes && this.selectedRecords?.length == 1 && this.selectedRecords[0]?.name == 'Advance') {
      this.CreateForm.get('advanceTypeId').setValidators([Validators.required])
    } else {
      this.CreateForm.get('advanceTypeId').clearValidators();
    }
    this.CreateForm.get('advanceTypeId').updateValueAndValidity();
    this.serviceInput.nativeElement.blur();
  }

  add(event: MatChipInputEvent): void {

    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.selectedRecords.push(value.trim());
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.CreateForm.patchValue({
      requestType: null
    })
  }

  remove(e: string): void {
    const index = this.selectedRecords.indexOf(e);
    if (index >= 0) {
      this.selectedRecords.splice(index, 1);
    }
    if(this.userInfo?.isEnableAdvanceTypes && this.selectedRecords?.length == 1 && this.selectedRecords[0]?.name == 'Advance') {
      this.CreateForm.get('advanceTypeId').setValidators([Validators.required])
    } else {
      this.CreateForm.get('advanceTypeId').clearValidators();
    }
    this.CreateForm.get('advanceTypeId').updateValueAndValidity();
  }

  addNewTask(OpenFrom, level) {
    if (OpenFrom == "New Task") {

      this.selectedRadioBtn = -1;
      let obj = {
        'approverTypeName':'',
        "workflowDesc": "",
        "level": level,
        "conditions": '',
        "approverType": '',
        "approverId": '',
        "assignedTo": "",
        "childApprovalWorkflowDetails": null,
        "isDeviationApproval": false,
        "isSameApprover": false,
        "sameApproverUser": null,
        "sameApproverUserName": null,
        "sameApproverType": null,
        "isParallelApproverType": null,
        "parallelApproverUser": null,
        "parallelApproverType": null,
        "parallelApproverName": null,
        "isParallelNotifier": false,
        "showNotifierUser": false,
        'showBands':false,
        'bandName':'',
        'bandId':''
      };
      this.enableDisableNext = true;
      let index = level;
      if (this.displayOptions.indexOf('middle') > -1 || this.displayOptions.indexOf('left') > -1 || this.displayOptions.indexOf('right') > -1) {
        index++;
      }
      if (this.dataSource.length > 0 && this.dataSource[this.dataSource.length - 1].level == index - 1) {
        // if (this.currentindex <= index && this.status == true) {
        //   obj['isDeviationApproval'] = true;
        //   this.dataSource.push(obj);
        // }
        // else {
        this.dataSource.push(obj);
        // }
      }
      else {
        // if (this.currentindex >= index && this.status == true) {
        //   this.currentindex = ++this.currentindex;
        // }
        // if (this.currentindex < index && this.status == true) {
        //   obj.isDeviationApproval = true;
        // }
        this.dataSource.splice(index, 0, obj);
        this.showEdit[index + 1] = true;
        this.showDone[index + 1] = false;
      }
      this.checkingSelcetdOption(index);
      this.updateLevels(index + 1, 1);
      this.increament(index);
      if (this.checking == true) {
        this.selectedIndex = index;
        this.showPredefinedApprovalOptions[index] = true;
        this.showManualApproverOptions[index] = false;
        // this.selectApprover(this.approvalTypeList[index], index);
        this.showEdit[index] = false;
        this.showDone[index] = true;
        this.checkNextStatus(index);
      }
      else {
        this.showEdit[index] = true;
        this.showDone[index] = false;
        this.checkNextStatus(index);
      }

    }
    else {
      let objLeft = {
        "workflowDesc": "",
        "level": level,
        "conditions": "require both approvals",
        "approverType": '',
        "approverId": '',
        "assignedTo": "",
        "childApprovalWorkflowDetails": null
      };
      let objRight = {
        "workflowDesc": "",
        "level": level,
        "conditions": "require both approvals",
        "approverType": '',
        "approverId": '',
        "assignedTo": "",
        "childApprovalWorkflowDetails": null
      };
      let index = level;
      if (this.displayOptions.indexOf('middle') > -1 || this.displayOptions.indexOf('left') > -1 || this.displayOptions.indexOf('right') > -1) {
        index++;
      }

      if (this.dataSource.length > 0 && this.dataSource[this.dataSource.length - 1].level == index - 1) {
        this.dataSource.push(objLeft);
        this.dataSource.push(objRight);
      }
      else {
        this.dataSource.splice(index, 0, objRight);
        this.dataSource.splice(index, 0, objLeft);
        this.updateLevels(index + 2, 1);
      }

      this.showEdit[index] = true;
      this.showEdit[index + 1] = true;
      this.showDone[index] = false;
      this.showDone[index + 1] = false;
    }
    this.displayOptions = "-1";
  }

  checkingSelcetdOption(index: number) {
    for (let i = 0; i < this.selectedOptionArray.length; i++) {
      if (i >= index) {
        this.selectedOptionArray[i].level = this.selectedOptionArray[i].level + 1;
      }
    }
    let replacedobj = {
      "approverTypeName": null,
      "approverTypeId": null,
      "level": index
    }
    this.selectedOptionArray.splice(index, 0, replacedobj);
  }
  updateLevels(index, increment) {
    for (let i = index; i < this.dataSource.length; i++) {
      if (increment == 1) {
        this.dataSource[i].level = this.dataSource[i].level + 1;
        // this.showEdit[this.dataSource[i].level] = true;
        // this.showDone[this.dataSource[i].level] = false;
      }
      else {
        this.dataSource[i].level--;
        this.showEdit[this.dataSource[i].level] = true;
        this.showDone[this.dataSource[i].level] = false;
      }
    }

  }

  selectApprover(approverInfo, sourceIndex) {
    console.log('selected type is :',approverInfo,this.dataSource);

    if(approverInfo.approverTypeId == 18){
      this.dataSource[sourceIndex].showBands = true;
    }else{
      this.dataSource[sourceIndex].showBands = false;
      this.dataSource[sourceIndex].bandId = '';
      this.dataSource[sourceIndex].bandName = '';
    }
    this.selectedOptionIndex = sourceIndex;
    this.showDone[sourceIndex] = true;
    this.showEdit[sourceIndex] = false;
    this.designWorkflowForm.patchValue({
      //  approvalName: approverInfo.approverTypeName
    });
    this.selectedApproverName = approverInfo;
    this.dataSource[sourceIndex].approverTypeName = approverInfo.approverTypeName
    this.checkIniatorManager(sourceIndex, true);
    this.checkSelectedValue(approverInfo, sourceIndex, 'Predefined');
  }

  checkNextStatus(index: number) {
    for (let i = 0; i < this.dataSource.length; i++) {
      if (this.dataSource[i].assignedTo == '') {
        this.showPredefinedApprovalOptions[i] = true;
        this.showEdit[i] = false;
        this.showDone[i] = true;
      }
      else if (this.dataSource[i].assignedTo !== '') {
        this.showPredefinedApprovalOptions[i] = false;
        this.showManualApproverOptions[i] = false;
        this.showEdit[i] = true;
        this.showDone[i] = false;
      }
    }
  }

  increament(index) {
    if (this.replaceSelectedIndex != null) {
      if (this.replaceSelectedIndex >= index) {
        this.replaceSelectedIndex = this.replaceSelectedIndex + 1;
      }
    }
  }

  backToCreateView() {
    this.createView = true;
    this.designView = false;
    this.publishView = false;
  }

  navigateToPublishView() {
    this.createView = false;
    this.designView = false;
    this.publishView = true;
  }



  workflowDescBind(index, event) {
    // this.designWorkflowForm.patchValue({
    //   workflowDescription: this.workflowDescriptionModel
    // });
    this.dataSource[index].workflowDesc = event.target.value;
  }

  activateNotifier(index: number, event: any) {
    let status = event
    if (status) {
      this.dataSource[index].isParallelNotifier = true;
      this.dataSource[index].isParallelApproverType = 1;
    } else {
      this.removeNotifers(index);
    }
  }

  removeNotifers(index) {
    this.tempdepartmentHeadData = [];
    this.dataSource[index].showNotifierUser = false;
    this.dataSource[index].isParallelNotifier = false;
    this.dataSource[index].isParallelApproverType = null;
    this.dataSource[index].parallelApproverUser = null;
    this.dataSource[index].parallelApproverType = null;
    this.dataSource[index].parallelApproverName = null;
  }

  selectNotifer(index, selectedUser) {
    this.dataSource[index].parallelApproverName = selectedUser.userName;
    this.dataSource[index].parallelApproverUser = selectedUser.userId;
    this.showNotifierList = false;
  }

  openSearchInput(e) {
    this.departmentHeadData = [];
    this.showSearchInput = true;
    e.preventDefault();
    e.stopPropagation();
    setTimeout(() => {
      this.gstStateAutoCompleteReference.nativeElement.focus();
    }, 0);
  }

  changeNotifier(type, index) {
    if (type == 0) {
      this.dataSource[index].parallelApproverType = type;
      this.dataSource[index].showNotifierUser = true;
    } else {
      this.dataSource[index].showNotifierUser = false;
      this.dataSource[index].parallelApproverType = type;
      this.dataSource[index].parallelApproverName = null;
      this.dataSource[index].parallelApproverUser = null;
    }
  }

  changeNotifierToogle(event: any, index) {
    this.dataSource[index].isParallelApproverType = event;
  }


  checkSelectedValue(approverInfo: any, sourceIndex: any, from: any) {
    let valuCheck = this.selectedOptionArray.find(element => {
      if (element?.approverTypeName == approverInfo.approverTypeName || element?.level == sourceIndex) {
        return element;
      }
    });

    if (valuCheck == undefined) {
      let obj;
      if (from == "Predefined") {
        obj = {
          "approverTypeName": approverInfo.approverTypeName,
          "approverTypeId": approverInfo.approverTypeId,
          "level": sourceIndex
        }
      }
      else if (from == "Manual") {
        obj = {
          "approverTypeName": approverInfo.approverTypeName,
          "approverTypeId": approverInfo.userId,
          "level": sourceIndex
        }
      }
      this.selectedOptionArray.push(obj);
    } else if (valuCheck !== undefined) {
      let replacedobj;
      let index = this.selectedOptionArray.findIndex(element => {
        return element?.level == sourceIndex;
      });
      if (from == "Predefined") {
        replacedobj = {
          "approverTypeName": approverInfo.approverTypeName,
          "approverTypeId": approverInfo.approverTypeId,
          "level": sourceIndex
        }
      }
      else if (from == "Manual") {
        replacedobj = {
          "approverTypeName": approverInfo.approverTypeName,
          "approverTypeId": approverInfo.userId,
          "level": sourceIndex
        }
      }
      this.selectedOptionArray.splice(index, 1, replacedobj);
    }
    for (let i = 0; i < this.selectedOptionArray.length; i++) {
      if (this.selectedOptionArray[i]?.level == null) {
        this.selectedOptionArray.splice(i, 1);
      }
    }
  }

  selectedNotifer(e, index) {
    this.tempdepartmentHeadData = [];
    this.showNotifierList = true;
    e.preventDefault();
    e.stopPropagation();
    setTimeout(() => {
      const el = document.getElementById(`selectedNotifer${index}`) as HTMLElement;
      el.focus();
    }, 0);
  }

  btnManualApproval(index) {
    this.showManualApproverOptions[index] = true;
    this.showPredefinedApprovalOptions[index] = false;
    this.dataSource[index].assignedTo = '';
    this.selectedApproverName = null;
    this.showEdit[index] = false;
    this.showDone[index] = true;
  }

  changeSameapprover(type, index) {
    if (type == 0) {
      this.dataSource[index].sameApproverType = type;
      this.showUserlist[index] = true;
    } else {
      this.showUserlist[index] = false;
      this.dataSource[index].sameApproverType = type;
    }
  }

  selectedUserlist(e) {
    this.tempdepartmentHeadData = [];
    this.showList = true;
    e.preventDefault();
    e.stopPropagation();
    setTimeout(() => {
      this.usersListAutoCompleteReference.nativeElement.focus();
    }, 0);
  }
  onEventFocus() {
    setTimeout(() => {
      this.hoursData = this.hoursList;
      this.minsData = this.minsList;
      this.tempdepartmentHeadData = [];
    }, 0);
    this
  }
  //TAT Approval Mins functions
  selectMins(selectedMins) {
    if (selectedMins != undefined) {
      this.selectedMins = selectedMins;
    }
  }

  backToDesignView() {
    this.createView = false;
    this.designView = true;
    this.publishView = false;
  }

  deletedIndexItem() {
    let index = this.indexForDelete;
    this.updatingSelectedOption(index);
    this.deleteGetItem(index);
    this.removeSameApprover(index);
    this.removeNotifers(index);
    if (this.indexDeleteStatus == true) {
      if (index == 0 && (this.dataSource.length > 1 && this.dataSource[1].isDeviationApproval == true)) {
        this.activateChild(1, false);
      }
      // if (index == this.currentindex) {
      //   if (index < this.dataSource[this.dataSource.length - 1].level) {
      //     this.currentindex = ++this.currentindex;
      //     this.activateChild(this.currentindex, true);
      //   }
      //   else if (index >= this.dataSource[this.dataSource.length - 1].level) {
      //     this.currentindex = index;
      //     this.activateChild(this.currentindex, false);
      //   }
      // }
      this.dataSource.splice(index, 1);
      // if (this.currentindex > index && this.status == true) {
      //   this.currentindex = --this.currentindex;
      // }
      this.updateLevels(index, 0);
      if (this.dataSource.length == 0) {
        this.enableDisableNext = true;
      }
      else {
        this.enableDisableNext = false;
      }
      this.checkNextStatus(index);
    }
    this.modalService.dismissAll()
  }

  deleteGetItem(index: number) {
    if (this.approvalModel.approvalWorkflowId && this.dataSource[index].approvalWorkflowDetailId) {
      this.dataSource[index]['action'] = "delete";
      this.removedGetItems.push(this.dataSource[index]);
    }

  }
  updatingSelectedOption(index: number) {
    let findIndexValue = this.selectedOptionArray.findIndex(element => {
      if (element?.level == index) {
        return element;
      }
    });
    if (findIndexValue != -1) {
      for (let i = 0; i < this.selectedOptionArray.length; i++) {
        if (i > findIndexValue) {
          this.selectedOptionArray[i].level = this.selectedOptionArray[i].level - 1;
        }
      }
      this.selectedOptionArray.splice(findIndexValue, 1);

    }
    if (index == this.replaceSelectedIndex) {
      this.replaceSelectedIndex = null;
      this.replacingText = false;
    }
  }
  deleteSingleItem(content: any, index) {
    this.indexForDelete = index;
    this.indexDeleteStatus = true;
    this.modalService.open(content, this.modalOptions);
  }

  btnDone(index, selectedApprover) {
    console.log('selected :',this.dataSource[index]);
    let valInArray;
    let valFind = this.selectedOptionArray.find(element => { return element?.level == index });
    if (valFind !== undefined) {
      valInArray = this.getFindValue(valFind);
    }
    if ((valFind !== undefined && valInArray == true) && (valFind.approverTypeName && valFind.approverTypeId)) {
      this.dataSource[index].approverType = valFind.approverTypeId;
      this.dataSource[index].approverId = "";
      this.dataSource[index].assignedTo = valFind.approverTypeName;
      this.active(index, true);
    }
    else if ((valFind !== undefined && valInArray == false) && (valFind.approverTypeName && valFind.approverTypeId)) {
      this.dataSource[index].approverType = "";
      this.dataSource[index].approverId = valFind.approverTypeId;
      this.dataSource[index].assignedTo = valFind.approverTypeName;
      this.active(index, true);
    }
    else if (this.selectedApproverName && this.selectedApproverName.approverTypeName) {
      this.dataSource[index].approverType = "";
      this.dataSource[index].approverId = this.selectedApproverName.approverTypeId;
      this.dataSource[index].assignedTo = this.selectedApproverName.approverTypeName;
      this.active(index, true);
    }
    else if (this.selectedApproverName) {
      this.dataSource[index].approverId = this.selectedApproverName.userId;
      this.dataSource[index].approverType = '';
      this.dataSource[index].assignedTo = this.selectedApproverName.approverTypeName;
      this.active(index, true);
    }
    else {
      this.active(index, false);
    }
  }

  // getFindValue(valFind: any) {
  //   let find = this.ListReplace.findIndex(element => {
  //     if (element.approverTypeName == valFind.approverTypeName) {
  //       return element;
  //     }
  //   });
  //   if (find !== -1) {
  //     return true;
  //   }
  //   else if (find == -1) {
  //     let secondOne = this.approvalTypeList.findIndex(element => {
  //       if (element.approverTypeName == valFind.approverTypeName) {
  //         return element;
  //       }
  //     })
  //     if (secondOne !== -1) {
  //       return true;
  //     }
  //     else {
  //       return false;
  //     }
  //   }
  // }
  getFindValue(valFind: any) {
    let find = this.ListReplace.findIndex(element => {
      if (element.approverTypeName == valFind.approverTypeName) {
        return element;
      }
    });
    if (find !== -1) {
      return true;
    }
    else if (find == -1) {
      let secondOne = this.approvalTypeList.findIndex(element => {
        if (element.approverTypeName == valFind.approverTypeName) {
          return element;
        }
      })
      if (secondOne !== -1) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  active(index, condition) {
    if (condition == true) {
      this.checkIniatorIndex(index, this.selectedApproverName);
      this.showEdit[index] = true;
      this.showDone[index] = false;
      this.showManualApproverOptions[index] = false;
      this.showPredefinedApprovalOptions[index] = false;
      // this.checkIniatorManager(index, true);
      this.selectedApproverName = null;
      this.selectedIndex == '-1';
      this.checkPreviousStatus(index);
    }
    else if (condition == false) {
      this.toastr.error('Select Assign To');
    }
  }

  checkPreviousStatus(index: number) {
    let breakCondtion = false;
    this.dataSource.forEach(element => {
      if (breakCondtion == false) {
        if (element.assignedTo == '') {
          breakCondtion = true;
          this.enableDisableNext = true;
        }
        else {
          this.enableDisableNext = false;
        }
      }
    })


  }

  checkIniatorIndex(index: number, approverName: any) {
    if (this.replaceSelectedIndex == index) {
      if (this.dataSource[index].assignedTo !== "Initiator's Manager") {
        this.replaceSelectedIndex = null;
        this.replacingText = false;
      }
    }
  }

  changeNotifyApprovalEmail() {
    this.checkedNotifyApprovalEmail = !this.checkedNotifyApprovalEmail;
    this.publishWorkflowForm.patchValue({
      notifyApproverEmailControl: this.checkedNotifyApprovalEmail
    });
  }

  changeNotifyApprovalSms() {
    this.checkedNotifyApprovalSms = !this.checkedNotifyApprovalSms;
    this.publishWorkflowForm.patchValue({
      notifyApproverSmsControl: this.checkedNotifyApprovalSms
    });
  }
  changeNotifyApprovalWhatsapp() {
    this.checkedNotifyApprovalWhatsapp = !this.checkedNotifyApprovalWhatsapp;
    this.publishWorkflowForm.patchValue({
      notifyApproverWhatsappControl: this.checkedNotifyApprovalWhatsapp
    });
  }

  changeAllowApproverEmail() {
    this.checkedAllowApproverEmail = !this.checkedAllowApproverEmail;
    this.publishWorkflowForm.patchValue({
      allowApproverEmailControl: this.checkedAllowApproverEmail
    });
  }
  changeAllowApproverSms() {
    this.checkedAllowApproverSms = !this.checkedAllowApproverSms;
    this.publishWorkflowForm.patchValue({
      allowApproverSmsControl: this.checkedAllowApproverSms
    });
  }
  changeAllowApproverWhatsapp() {
    this.checkedAllowApproverWhatsapp = !this.checkedAllowApproverWhatsapp;
    this.publishWorkflowForm.patchValue({
      allowApproverWhatsappControl: this.checkedAllowApproverWhatsapp
    });
  }

  changeSendReminderEmail(event: any) {

    this.checkedSendReminderEmail = !this.checkedSendReminderEmail;
    this.publishWorkflowForm.patchValue({
      sendReminderEmailControl: this.checkedSendReminderEmail
    });
    if (this.checkedSendReminderEmail == false) {
      this.reminderToggle = true;
      this.selectedHours = this.selectedHours;
      this.selectedMins = this.selectedMins;
      this.departureMinValue = this.departureMinValue;
      this.departureMaxvalue = this.departureMaxvalue;
      this.selectedReminderIntervalHrs = this.selectedReminderIntervalHrs;
      this.selectedReminderIntervalMins = this.selectedReminderIntervalMins;
    } if (this.checkedSendReminderEmail == true) {
      this.reminderToggle = false;
    }
  }
  changeSendReminderSms() {
    this.checkedSendReminderSms = !this.checkedSendReminderSms;
    this.publishWorkflowForm.patchValue({
      sendReminderSmsControl: this.checkedSendReminderSms
    });
  }
  changeSendReminderWhatsapp() {
    this.checkedSendReminderWhatsapp = !this.checkedSendReminderWhatsapp;
    this.publishWorkflowForm.patchValue({
      sendReminderWhatsappControl: this.checkedSendReminderWhatsapp
    });
  }

  changeDelegatingSupervisorApprove() {
    this.delegatingSupervisorApprove = !this.delegatingSupervisorApprove;
    this.publishWorkflowForm.patchValue({
      delegatingSupervisorApproveControl: this.delegatingSupervisorApprove
    });
  }

  btnListedApproval(index) {
    this.showPredefinedApprovalOptions[index] = true;
    this.showManualApproverOptions[index] = false;
    this.selectedApproverName = null;
    this.showEdit[index] = true;
    this.showDone[index] = false;
  }

  selectedValue(index) {
    let value;
    let livalue;
    let val = this.dataSource[index].assignedTo;
    value = this.ListReplace.findIndex(element => {
      if (element.approverTypeName == val) {
        return element;
      }
    });
    if (value !== -1) {
      this.selectApprover(this.ListReplace[value], index);
      this.showOptionCondtion = true;
    }
    else if (value == -1) {
      livalue = this.approvalTypeList.findIndex(element => {
        if (element.approverTypeName == val) {
          return element;
        }
      })
      if (livalue !== -1) {
        this.selectApprover(this.approvalTypeList[livalue], index);
      }
      value = livalue;
    }
    if (livalue !== -1) {
      this.selectedRadioBtn = value;
      this.showOptionCondtion = true;
    }
    else if (livalue == -1) {
      let splite = val.split(' ');
      let obj = {
        "firstName": splite[0],
        "lastName": splite[1],
        "userId": this.dataSource[index].approverId
      };

      this.showPredefinedApprovalOptions[index] = false;
      this.showManualApproverOptions[index] = true;
      this.selectedApproverName = obj;
      this.showOptionCondtion = false;
    }
    else {
      value = -1;
      this.selectedRadioBtn = -1;
      this.showOptionCondtion = true;
    }
  }
  btnEdit(index) {
    this.selectedValue(index);
    this.showDone[index] = true;
    this.showEdit[index] = false;
    this.showPredefinedApprovalOptions[index] = false;
    this.showSelectedApprover = [];
    this.showSelectedManualApprover = [];
    this.dataSource[index].assignedTo = '';
    this.selectedManualApproverList = [];
    if (this.showOptionCondtion) {
      this.showManualApproverOptions[index] = false;
      this.showPredefinedApprovalOptions[index] = true;
    }
    else if (!this.showOptionCondtion) {
      this.showManualApproverOptions[index] = true;
      this.showPredefinedApprovalOptions[index] = false;
    }
    // this.selectedApproverName = null;
    this.checkPreviousStatus(-1);

    if (this.dataSource[index].sameApproverType != null) {
      this.dataSource[index].isSameApprover = true;
      if (this.dataSource[index].sameApproverType == 0) {
        this.showUserlist[index] = true;
      }
    }
    if (this.dataSource[index].parallelApproverType != null) {
      this.dataSource[index].isParallelNotifier = true;
      if (this.dataSource[index].parallelApproverType == 0) {
        this.dataSource[index].showNotifierUser = true;
      }
    }

    console.log('rajesh :',this.dataSource[index]);
  }

  getManualValue(index: number) {
    let find = this.selectedOptionArray.find(element => { return element?.level == index });
    if (find !== undefined) {
      let findInArray = this.getFindValue(find);
      if (findInArray == false) {
        return find.approverTypeName;
      }
      else {
        return null
      }
    }
  }

  removeManualApprover(index) {
    this.selectedApproverName = null;
    this.selectedOptionArray[index].approverTypeName = "";
    this.selectedOptionArray[index].approverTypeId = ""
    this.selectedOptionArray[index].level = null;
  }

  activateChild(index: number, event: any) { 
    let status = event;
    if (status) {
      this.dataSource[index].isDeviationApproval = true;
    } else {
      this.dataSource[index].isDeviationApproval = false;
    }
  };


  selectManualApproverOption(index, value) {
    let convUserObj = { approverTypeName: value.userName, approverTypeId: value.userId }
    this.showSearchInput = false;
    this.selectedApproverName = convUserObj;
    this.departmentHeadData = [];
    this.manualApproverOptionSelected[index] = true;
    this.showEdit[index] = false;
    this.showDone[index] = true;
    this.checkSelectedValue(convUserObj, index, 'Manual');
    this.removeSameApprover(index);
    this.removeNotifers(index);
  }
  onClickedOutsideManualApprover(e) {
    this.showSearchInput = false;
  }


  selectSameApproverOption(index, selectedUser) {
    this.dataSource[index].sameApproverUserName = selectedUser.userName;
    this.dataSource[index].sameApproverUser = selectedUser.userId;
    this.showNotifierList = false;
  }

  submitted: boolean = false;
  publish() {
    this.submitted = true;
    this.recordIdArray = Array.prototype.map.call(this.selectedRecords, function (item) { return item.recordType; }).join();
    this.recordNameArray = Array.prototype.map.call(this.selectedRecords, function (item) { return item.name; }).join();
    this.disabledPublish = true;
    this.buttonDisabled = true;
    if (this.removedGetItems.length > 0) {
      this.removedGetItems.forEach(element => {
        this.dataSource.push(element);
      })
    }
    let body = {
      "approvalWorkflowDetails": this.dataSource,
      "approvalWorkflows": {
        "approvalWorkflowId": this.isUpdate ? this.workFlowId : null,
        "approvalWorkflowGuid": this.isUpdate ? this.workFlowGuid : null,
        // "serviceTypeId": this.serviceTypeList.find(x => x.productDesc == this.CreateForm.value.serviceType).productId,
        "recordTypes": this.recordIdArray,
        "recordTypeNames": this.recordNameArray,
        "workflowName": this.CreateForm.value.workFlowName,
        "workflowDesc": this.CreateForm.value.description,
        "workflowType": this.CreateForm.value.workflowType,
        "advanceTypeId": this.CreateForm.value.advanceTypeId,
        "advanceTypeName": this.CreateForm.value.advanceTypeName,
        "statusId": this.selectedStatusId,
        "legalEntityId": this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId,
        "legalEntityGUID":this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId,
        "approvalFlowType": this.CreateForm.value.workFlowType,
        "type": this.typeStr,


      },
      "workflowNotificationSettings": {
        "notifyApproverEmail": this.convertFlagToValue(this.publishWorkflowForm.value.notifyApproverEmailControl),
        "notifyApproverSms": this.convertFlagToValue(this.publishWorkflowForm.value.notifyApproverSmsControl),
        "notifyApproverWhatsapp": this.convertFlagToValue(this.publishWorkflowForm.value.notifyApproverWhatsappControl),
        "allowApproverEmail": this.convertFlagToValue(this.publishWorkflowForm.value.allowApproverEmailControl),
        "allowApproverSms": this.convertFlagToValue(this.publishWorkflowForm.value.allowApproverSmsControl),
        "allowApproverWhatsapp": this.convertFlagToValue(this.publishWorkflowForm.value.allowApproverWhatsappControl),
        "sendReminderEmail": this.convertFlagToValue(this.publishWorkflowForm.value.sendReminderEmailControl),
        "sendReminderSms": this.convertFlagToValue(this.publishWorkflowForm.value.sendReminderSmsControl),
        "sendReminderWhatsapp": this.convertFlagToValue(this.publishWorkflowForm.value.sendReminderWhatsappControl),
        "approvalTat": this.selectedHours + ':' + this.selectedMins,
        "reminderIntervalTime": this.selectedReminderIntervalHrs + ':' + this.selectedReminderIntervalMins,
        "delegatingSupervisorApprove": this.convertFlagToValue(this.publishWorkflowForm.value.delegatingSupervisorApproveControl),
        "sendReminderStartTime": this.departureMinValue,
        "sendReminderEndTime": this.departureMaxvalue,
        "workflowSettingId": this.isUpdate ? this.workflowSettingId : null,
        "workflowSettingGuid": this.isUpdate ? this.workflowSettingGuid : null,
        "noOfDays":this.publishWorkflowForm.value.noOfDays
      }
    }
    if (this.publishWorkflowForm.valid) {
      console.log(body)
      this.settingsService.addApprovalWorkflow(body).subscribe(data => {
        //Add a toast and then navigate
        if (!data.isError) {
          this.router.navigateRoot(["settings/approvalworkflow"]);
          this.buttonDisabled = false;
          this.toastr.success(data.payload, 'Success');
          this.disabledPublish = false;
        } else {
          this.toastr.error(data.errorMessage, 'Error');
          this.buttonDisabled = false;
          this.disabledPublish = false;
        }
      },
        err => {
          //Display error
          this.buttonDisabled = false;
          this.toastr.error("workflow is not added/updated", 'Error');
          this.disabledPublish = false;
        }
      );
    } else {
      this.disabledPublish = false;
      this.buttonDisabled = false;
    }

  }

  convertFlagToValue(flag) {
    if (flag == true) {
      return 1;
    }
    else {
      return 0;
    }
  }

  searchUser(e: any) {
    this.searchFreetext = e.target.value;
    const departmentHeadRequestPayload = {
      "legalEntityId":this.selectedLegalEntity?.legalEntityId || this.userInfo?.legalEntityId,
      "legalEntityGUID": this.selectedLegalEntity?.legalEntityGuId || this.userInfo?.legalEntityGuId,
      "searchText": this.searchFreetext

    }

    if (this.searchFreetext && this.searchFreetext.length >= 3) {
      this.settingsService.getUsersList(JSON.stringify(departmentHeadRequestPayload)).subscribe(response => {
        if (response.payload?.users?.length > 0) {
          this.departmentHeadData = response.payload?.users;
          this.tempdepartmentHeadData = response.payload?.users;
        }
      });
    }
    else {
      this.departmentHeadData = [];
      this.tempdepartmentHeadData = [];
    }
  }

  claimTypes: any = [];
  claimTypesTemp: any = [];
  getClaimTypes() {
    const obj = {
      "action": "claim_types",
      "legalEntityGuid": this.userInfo.legalEntityGuId,
      "legalEntityId": this.userInfo.legalEntityId,
      "companyId": this.userInfo.companyId,
      "companyGUID": this.userInfo.companyGuId,
    }
    this.expenseService.getClaimTypes(obj).subscribe(res => {
      this.claimTypes = res.payload || [];
      this.claimTypesTemp = [...this.claimTypes];
    })
  }

  selectedAdvanceType(type) {
    this.CreateForm.controls.advanceTypeId.patchValue(type.id);
    this.CreateForm.controls.advanceTypeName.patchValue(type.claimType);
  }
}
