export class emailConfig {
    "emailConfigurationId": number;
    "emailConfigurationGuid": string;
    "legalEntityId": number;
    "legalEntityGuid": string;
    "emailTypeId": number;
    "fromEmailName": string;
    "replyTo": string;
    "cc": string;
    "updated_By": string;
    "created_By": string;
}

  export class getEmailConfigRequest {
    emailConfigurationId;
    emailConfigurationGUID;
    legalEntityId;
    legalEntityGUID;
    emailTypeId;
    emailTypeGUID;
  }
  export class  createEmailConfig {
    emailConfigurationId: number;
    emailConfigurationGUID: string;
    emailType;
    fromEmailName;
    emailTypeId;
    replyTo;
    ccList: any = [];
    recipientList = [];
    legalEntityId;
    legalEntityGUID;
  }
  
  export class recipientList {
    approverTypeId;
    otherEmailIds: string;
    approverTypeName;
  }
