import {
    Component,
    OnInit,
    TemplateRef,
    Pipe,
    PipeTransform,
    Injectable, Input, ViewChild, NgZone
} from "@angular/core";

import {
    Report,
    ReportReqlistModel,
    ReportListResModel, RemoveReportModal
} from "src/app/models/Reports/reports.model";
import { Title } from "@angular/platform-browser";
import { PlatformService } from "src/app/services/platformbase.service";
import { ReportsService } from "src/app/services/reports.service";
import { ToastrService } from "ngx-toastr";
import { AlertController, IonInput, ModalController, NavController } from '@ionic/angular';
import { DetailreportComponent } from './detailreport/detailreport.component';
import * as _ from "lodash";
import {
    CategoryAndMerchantModel,
    CategoryList,
    DownloadModel,
    ExpenseAndReportModel,
    FilterModal,
    StatusListModel
} from 'src/app/models/Common/common.model';
import { DatechangeService } from 'src/app/services/common-services/datechange.service';
import { CommonService } from 'src/app/services/common-services/common.service';
import { ChangeContext, LabelType, Options } from 'ng5-slider';
import { Subject } from 'rxjs';
import * as moment from 'moment';
// import { IonicSelectableComponent } from 'ionic-selectable';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Constants } from 'src/app/Constants';
import { SharedService } from 'src/app/services/common-services/shared.service';
import { CustomDatePickerComponent } from '../custom-date-picker/custom-date-picker.component';
import { CustomSelectDropdownComponent } from '../custom-select-dropdown/custom-select-dropdown.component';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { Router } from "@angular/router";

@Component({
    selector: "app-reports",
    templateUrl: "./reports.component.html",
    styleUrls: ["./reports.component.scss"],
})
export class ReportsComponent implements OnInit {
    reportReqlistModel = new ReportReqlistModel();
    @Input() type;
    reportSearch: any;
    platformType: number;
    showFilter = false;
    userInfo: any;
    dummyList = [1, 2, 3];
    reportList = [];
    isPageLoad = true;
    totalRecords: number = 0;
    addReportHide = true;
    showReportHide = false;
    segmentModel = "draft";
    showRepFilter = false;
    showRepList = true;
    dropdownflag: boolean = false;
    hideColumns = [];
    selectedReport = [];
    selectedList = [];
    selectcatarraymob = [];
    checkAll: boolean;
    indeterminateState: boolean;
    categoryList: any;
    categorytempList: any;
    checkedAll: boolean;
    indeterminateStates: boolean;
    showLoading: boolean = true;
    statusList = [];
    statusAll: any;
    selectedStatus = [];
    selectedMobStatus = [];
    indeterminateStatus: boolean;
    minPrice: number = 0;
    maxPrice: number = 0;
    options: any;
    draft: boolean = true;
    pageNumber: number = 1;
    pageSize: number = 50;
    public manualRefresh = new Subject<void>();
    maxxDate: any;
    ExpenseorReport: any;
    status: any;
    categoryName: any;
    // @ViewChild("statusComponent") statusComponent: IonicSelectableComponent;
    // @ViewChild("categoryComponent") categoryComponent: IonicSelectableComponent;
    @ViewChild("downloadLoader", { static: false }) private downloadLoader;
    claimId: any;
    claimGUID: any;
    modalRef: BsModalRef;
    claimName: any;
    reportDraftArray = [];
    submitBtn = false;
    showDetailreport: boolean = false;
    hideReporttable: boolean = true;
    detailReport: any;
    fromPage: string;
    maxDate: any;
    minAndMax: any;
    frmDate: any;
    tDate: any;
    isReportCreated: boolean;
    buttonDisabled: boolean = false;
    deltbuttonDisabled = false;
    showMobileDetails: boolean = true;
    isApicallinProgress: any = false;
    minDate: any;
    showSearch = false;
    currentday: any;
    downloadTime: any;
    downloadActionPoolCount = 0;
    downloadBtn = false;
    private SearchListText: Subject<string> = new Subject();
    hideNewRepBtn = true;
    fromPettyCash = false;
    fromAnalytics = false;
    refreshDataSubcr: any;
    sharedSubsc: any;
    isUserToggle: any;
    showDownloadOptions: boolean = false;
    dropdownValue: any;
    incomingVariable: any;
    draftCount = 1;
    submittedCount = 1;

    maxDateStr: any = moment(new Date()).format('YYYY-MM-DD');
    minDateStr: any = moment(new Date().getTime()).subtract(2000, 'years').format('YYYY-MM-DD');
    expcurrencyIcon: any;

    constructor(
        private title: Title,
        private platformService: PlatformService,
        private reportsService: ReportsService,
        private toaster: ToastrService,
        private router: NavController,
        private angularRouter: Router,
        public modalController: ModalController,
        public alertController: AlertController,
        public toastr: ToastrService,
        private datechangeService: DatechangeService,
        private commonService: CommonService,
        private modalService: BsModalService,
        public sharedService: SharedService,
        private zone: NgZone,
        private translate: TranslateService,
    ) {
        if (this.angularRouter.url.includes('petty-cash/report')) {
            this.fromPettyCash = true;
        }
        this.fromPage = this.fromPettyCash ? 'petty-cash' : 'report';
        this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        this.expcurrencyIcon = this.userInfo.baseCurrency.currencyIcon;
        this.refreshDataSubcr = this.sharedService.getRefreshData().subscribe((value) => {
            if (value) {
                if (this.segmentModel === 'draft') {
                    this.draft = true;
                    this.segmentModel = 'draft';
                    this.pageNumber = 1;
                    this.getreportList(true);
                }
                else if (this.segmentModel === 'submitted') {
                    this.draft = false;
                    this.segmentModel = 'submitted';
                    this.pageNumber = 1;
                    this.getreportList(true);
                }
            }
        });
    }

    ngOnInit() {
        this.SearchListText.pipe(
            debounceTime(500),
            distinctUntilChanged()).subscribe((term: string) => {
                this.ExpenseorReport = term;
                this.showLoading = true;
                this.getreportList(false);
            });

        if ((this.userInfo.roleId == 2 || this.userInfo.roleId == 3) && JSON.parse(sessionStorage.getItem('isadmin'))) {
            this.hideNewRepBtn = false;
        }
        this.incomingVariable = sessionStorage.getItem('toPathInfo');
        let val = JSON.parse(sessionStorage.getItem("toPathInfo"));
        if (val) {
            sessionStorage.setItem('toPathInfo', null);
            if (val["url"] && (val["url"] == 'analytics')) {
                this.fromAnalytics = true;
            }
        }

        this.sharedSubsc = this.sharedService.getUserToggle().subscribe((val) => {
            this.isUserToggle = val;
        });
    }

    ionViewWillEnter() {
        this.pageNumber = 1;
        this.platformType = this.platformService.getPlatformType();
        this.hideReporttable = true;
        this.title.setTitle("Reports | NoExpenses");
        let date = new Date();
        this.maxDate = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate()
        );

        this.maxxDate = moment(new Date()).format(Constants.mobileCalenderFormat);
        this.currentday = moment(new Date()).format(Constants.mobileCalenderFormat);
        this.getreportList(true);
        this.getCategroyList();
        this.getStatusList();
        if (this.platformType == 2) {
            this.showSearch = true;
        }

    }

    resetSubscriptions() {
        if (this.refreshDataSubcr) {
            this.refreshDataSubcr.unsubscribe();
        }
        this.sharedService.setRefreshData(false);
        let val = JSON.parse(sessionStorage.getItem("toPathInfo"));
        if (val && val["url"] && (val["url"] == 'analytics')) {
            sessionStorage.setItem('toPathInfo', null);
        }
        this.sharedService.unsubscribeServic(this.sharedSubsc);
    }

    showFiltersection() {
        this.showFilter = !this.showFilter;
        this.dropdownflag = false;
    }

    filterModal = new FilterModal();
    expenseAndReportModel = new ExpenseAndReportModel();

    applyFilter() {
        this.showFilter = false;
        this.showRepList = true;
        this.showRepFilter = false;
        this.resetPaging();
        if (this.frmDate !== undefined && this.tDate == undefined) {
            this.tDate = this.maxxDate;
            this.filterModal.toDate = moment(new Date()).format(Constants.mobileDateFormat);
        }
        if (this.frmDate == undefined && this.tDate !== undefined) {
            this.frmDate = this.tDate;
            this.filterModal.fromDate = this.tDate;
        }
        this.filterModal.fromDate = this.frmDate ? moment(new Date(this.frmDate)).format(Constants.mobileDateFormat) : undefined;
        this.filterModal.toDate = this.tDate ? moment(new Date(this.tDate)).format(Constants.mobileDateFormat) : undefined;
        this.getreportList(false);
        this.maxDate = new Date();
    }

    fromDate(event) {
        this.frmDate = event.target.value;
        let date = this.datechangeService.convertCalendarToAPI(event.target.value);
        this.filterModal.fromDate = date;
        this.minDate = new Date(this.frmDate);
    }

    toDate(event) {
        this.tDate = event.target.value;
        let date = this.datechangeService.convertCalendarToAPI(event.target.value);
        this.filterModal.toDate = date;
        this.maxDate = new Date(this.tDate);
    }

    resetDates() {
        this.frmDate = undefined;
        this.tDate = undefined;
        this.filterModal.fromDate = undefined;
        this.filterModal.toDate = undefined;
        this.minDate = undefined;
        this.maxDate = new Date();

    }

    getFromDateCall(ev) {

        this.frmDate = moment(ev.detail.value).format("D MMM YYYY");
        this.filterModal.fromDate = moment(ev.detail.value).format("MM-DD-YYYY");
        this.minDate = moment(ev.detail.value).format("DD-MM-YYYY");
        this.minDateStr = moment(ev.detail.value).format("YYYY-MM-DD");

    }

    getToDateCall(ev) {
        this.tDate = moment(ev.detail.value).format("D MMM YYYY");
        this.filterModal.toDate = moment(ev.detail.value).format("MM-DD-YYYY");
        this.maxxDate = moment(ev.detail.value).format("DD-MM-YYYY");
    }

    fromDateMobile(e) {
        if (e.detail.value != "") {
            this.frmDate = e.detail.value;
            this.filterModal.fromDate = moment(new Date(e.detail.value)).format(Constants.mobileDateFormat);
            this.minDate = new Date(this.frmDate);
        } else {
            this.filterModal.fromDate = null;
        }
        // this.maxxDate = moment(new Date()).format(Constants.mobileCalenderFormat);
    }

    toDateMobile(e) {
        if (e.detail.value != "") {
            this.tDate = e.detail.value;
            this.filterModal.toDate = moment(new Date(e.detail.value)).format(Constants.mobileDateFormat);
            this.maxxDate = moment(new Date(this.tDate)).format(Constants.mobileCalenderFormat);

        } else {
            this.filterModal.fromDate = null;
        }
    }

    statusDetails(event) {
    }

    selectCategoryFilters() {
        setTimeout(() => {
            this.categoryList.forEach((item) => {
                item.isSelected = this.checkedAll;
            });
        });
    }

    selectSingleCategory(catList, e) {
        catList.isSelected = e.target.checked;
        if (catList.isSelected) {
            this.selectedList.push(catList.categoryId);
            this.filterModal.categoryIds = this.selectedList;
        } else {
            let index = _.indexOf(this.selectedList, catList.categoryId);
            this.selectedList.splice(index, 1);
            this.filterModal.categoryIds = this.selectedList;
        }
        if (
            this.categoryList.length != this.selectedList.length &&
            this.selectedList.length > 0
        ) {
            this.checkedAll = false;
            this.indeterminateStates = true;
        } else if (this.selectedList.length == 0) {
            this.checkedAll = false;
            this.indeterminateStates = false;
        } else {
            this.checkedAll = true;
            this.indeterminateStates = false;
        }
    }

    selectMultiStatus() {
        setTimeout(() => {
            this.statusList.forEach((status) => {
                status.isSelected = this.statusAll;
            });
        });
    }

    selectSingleStatus(list, e) {
        list.isSelected = e.target.checked;
        if (list.isSelected) {
            this.selectedStatus.push(list.statusId);
            this.filterModal.statusIds = this.selectedStatus;
        } else {
            let index = _.indexOf(this.selectedStatus, list.statusId);
            this.selectedStatus.splice(index, 1);
            this.filterModal.statusIds = this.selectedStatus;
        }
        if (
            this.statusList.length != this.selectedStatus.length &&
            this.selectedStatus.length > 0
        ) {
            this.statusAll = false;
            this.indeterminateStatus = true;
        } else if (this.selectedStatus.length == 0) {
            this.statusAll = false;
            this.indeterminateStatus = false;
        } else {
            this.statusAll = true;
            this.indeterminateStatus = false;
        }

    }

    applyStatus() {
        // this.statusComponent.confirm();
        // this.statusComponent.close();
    }

    clearStatus() {
    }

    selectStatus(event) {
        if (event.isSelected) {
            this.selectedMobStatus.push(event.item.statusId);
            this.filterModal.statusIds = this.selectedMobStatus;
        } else {
            let index = _.indexOf(this.selectedMobStatus, event.item.statusId);
            this.selectedMobStatus.splice(index, 1);
            this.filterModal.statusIds = this.selectedMobStatus;
        }

    }

    selectCategory(event) {
        if (event.isSelected) {
            this.selectcatarraymob.push(event.item.categoryId);
            this.filterModal.categoryIds = this.selectcatarraymob;
        } else {
            let index = _.indexOf(this.selectcatarraymob, event.item.categoryId);
            this.selectcatarraymob.splice(index, 1);
            this.filterModal.categoryIds = this.selectcatarraymob;
        }
    }

    resetFilters(list) {
        list.forEach((item) => {
            item.isSelected = false;
        });
    }

    resetAmount() {
        this.filterModal.minPrice = this.minAndMax.reportMinAmount;
        this.filterModal.maxPrice = this.minAndMax.reportMaxAmount;
    }

    resetFilter() {
        this.ExpenseorReport = "";
        this.frmDate = undefined;
        this.tDate = undefined;
        this.filterModal.fromDate = undefined;
        this.filterModal.toDate = undefined;
        this.filterModal.minPrice = undefined;
        this.filterModal.maxPrice = undefined;
        this.status = "";
        this.categoryName = "";
        this.maxxDate = moment(new Date()).format(Constants.mobileCalenderFormat);
        // this.resetAmount();
    }

    resetSinglefilter(type) {
        if (type) {
            switch (type) {
                case 1:
                    this.frmDate = undefined;
                    this.filterModal.fromDate = this.frmDate;
                    this.minDate = undefined;
                    this.maxDate = new Date();
                    break;
                case 2:
                    this.tDate = undefined;
                    this.filterModal.toDate = this.tDate;
                    this.minDate = undefined;
                    this.maxDate = new Date();
                    break;
                case 3:
                    this.status = "";
                    this.selectedStatus = [];
                    this.filterModal.statusIds = this.selectedStatus;
                    break;
                case 4:
                    this.categoryName = "";
                    this.selectedList = [];
                    this.filterModal.categoryIds = this.selectedList;
                    break;
            }
        }
    }

    applyCategory() {
        // this.categoryComponent.confirm();
        // this.categoryComponent.close();
    }

    clearCategory() {
    }

    changeOptions(minValue, maxValue) {
        const newOptions: Options = Object.assign({}, this.options);
        newOptions.floor = minValue;
        newOptions.ceil = maxValue;
        this.minPrice = minValue;
        this.maxPrice = maxValue;
        // this.filterModal.minPrice = this.minPrice;
        // this.filterModal.maxPrice = this.maxPrice;
        this.options = newOptions;
    }

    priceSlider(changeContext: ChangeContext) {
        this.filterModal.minPrice = changeContext.value;
        this.filterModal.maxPrice = changeContext.highValue;
    }

    categoryMerchantModel = new CategoryAndMerchantModel();

    getCategroyList() {
        this.categoryMerchantModel.companyId = this.userInfo.companyId;
        this.categoryMerchantModel.companyGUID = this.userInfo.companyGuId;
        this.categoryMerchantModel.legalEntityId = this.userInfo.legalEntityId;
        this.categoryMerchantModel.legalEntityGUID = this.userInfo.legalEntityGuId;
        this.categoryMerchantModel.categoryType = null;
        this.commonService
            .getCategorylist(this.categoryMerchantModel)
            .subscribe((res) => {
                if (!res.isError && res.errorCode == 0) {
                    let listarray = res.payload.categoryList;
                    this.categoryList = listarray.map((list) => new CategoryList(list));
                } else if (res.isError == true) {
                    this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
                }
            });
    }


    loadMore(event) {
        if (!this.isApicallinProgress) {
            if (this.reportList.length < this.pageSize && event) {
                event.target.disabled = true;
            } else {
                this.pageNumber++;
                this.selectAllreports(event);
                this.getreportList(false, event);
            }
        }
    }

    getreportList(isPageLoad, event?) {
        this.isPageLoad = isPageLoad;
        if (this.pageNumber == 1) {
            this.showLoading = true;
            this.reportList = [];
        }

        this.expenseAndReportModel.recordType = this.fromPettyCash ? 4 : 1;
        // this.expenseAndReportModel.isAdminView = JSON.parse(sessionStorage.getItem('isadmin'));
        if ((JSON.parse(sessionStorage.getItem('isadmin')) == true) && (this.userInfo.roleId == 2)) {
            this.expenseAndReportModel.viewType = this.userInfo.roleId;
        } else {
            this.expenseAndReportModel.viewType = 1;
        }
        //this.expenseAndReportModel.isAdminView = JSON.parse(sessionStorage.getItem('isadmin'));
        if (this.userInfo.roleId == 2 && this.expenseAndReportModel.viewType == 2) {
            this.expenseAndReportModel.isAdminView = JSON.parse(sessionStorage.getItem('isadmin'));
        } else {
            this.expenseAndReportModel.isAdminView = false;
        }

        this.expenseAndReportModel.legalEntityId = this.userInfo.legalEntityId;
        this.expenseAndReportModel.legalEntityGUID = this.userInfo.legalEntityGuId;
        this.expenseAndReportModel.userId = this.userInfo.userId;
        this.expenseAndReportModel.userGUID = this.userInfo.userGuId;
        this.expenseAndReportModel.filterBy = this.filterModal;
        this.expenseAndReportModel.filterBy.searchText = this.ExpenseorReport;
        this.expenseAndReportModel.filterBy.isDraft = this.platformType == 2 ? this.draft : null;
        this.expenseAndReportModel.pageNumber = this.pageNumber;
        this.expenseAndReportModel.pageSize = this.pageSize;
        this.isApicallinProgress = true;
        this.reportsService
            .reportList(this.expenseAndReportModel)
            .subscribe((res) => {
                if (!res.isError && res.errorCode === 0) {
                    if (this.pageNumber == 1) {
                        this.reportList = [];
                    }
                    this.showLoading = false;
                    this.totalRecords = res.payload.reportsCount;
                    this.isReportCreated = res.payload.isReportCreated;
                    this.minAndMax = res.payload;
                    this.changeOptions(
                        res.payload.reportMinAmount,
                        res.payload.reportMaxAmounts
                    );
                    const listarray = res.payload.reportResponseList;
                    this.reportList = this.reportList.concat(listarray.map((list) => new ReportListResModel(list)));

                    if (this.platformType == 1) {
                        if (this.pageNumber == 1 && this.isPageLoad == true && this.isReportCreated == true) {
                            this.isPageLoad = false;
                        }
                    } else {
                        this.isPageLoad = false;
                    }
                    if (this.draft) {
                        this.draftCount = res.payload.reportsCount;
                    } else {
                        this.submittedCount = res.payload.reportsCount;
                    }
                    this.selectedReport = [];
                    this.selectedStatus = [];
                    this.selectedList = [];
                    if (event) {
                        event.target.complete();
                    }
                    if (res.payload.reportResponseList.length < this.pageSize && event) {
                        event.target.disabled = true;
                    }
                    this.isApicallinProgress = false;

                    let val = JSON.parse(sessionStorage.getItem("fromPathInfo"));
                    if (val?.url && val?.url == 'reports' && val?.goToReportDetails == true && val?.detailReport) {
                        let ind = this.reportList.findIndex(ele => ele.claimId == (val?.detailReport?.claimId));
                        if (ind != -1) {
                            this.showReportDetail(this.reportList[ind]);
                        }
                        sessionStorage.setItem('fromPathInfo', null);
                    }
                } else {
                    this.selectedList = [];
                    this.selectedStatus = [];
                    this.selectedReport = [];
                    this.isApicallinProgress = false;
                    this.showLoading = false;
                    this.toaster.error(res.errorMessage, this.translate.instant('alerts.error'));
                    if (event) {
                        event.target.disabled = true;
                    }
                }
            });
    }

    openNewReport(editreport?) {
        let navInfo = {
            url: 'reports',
            report: editreport
        };
        sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
        sessionStorage.setItem('fromPettyCash', String(this.fromPettyCash));
        if (this.fromPettyCash) {
            this.router.navigateRoot(["petty-cash/new-report"]);
        } else {
            this.router.navigateRoot(["newreport"]);
        }
    }

    addNewReport() {
        this.showReportHide = true;
        sessionStorage.setItem('fromPettyCash', String(this.fromPettyCash));
        if (this.fromPettyCash) {
            this.router.navigateRoot(["petty-cash/new-report"]);
        } else {
            this.router.navigateRoot(["newreport"]);
        }
    }

    expFilter() {
        this.showRepFilter = true;
        this.showRepList = false;
        this.addReportHide = false;
        this.selectedReport = [];
        this.showLoading = false;
    }

    returnRepSegment() {
        this.showRepFilter = false;
        this.showRepList = true;
    }

    reportSegment(e) {
        this.segmentModel = e.target.value;
        if (e.target.value == "draft") {
            this.draft = true;
            this.pageNumber = 1;
            this.getreportList(false);
            //this.resetFilter();
            this.selectcatarraymob = [];
            this.selectedMobStatus = [];
        } else {
            this.draft = false;
            this.pageNumber = 1;
            this.getreportList(false);
            //this.resetFilter();
            this.selectcatarraymob = [];
            this.selectedMobStatus = [];
        }
    }

    returnDasbord() {
       // this.router.navigateRoot(["dashboard"]);
        let typeVal = JSON.parse(sessionStorage.getItem("fc365"));
        if (typeVal) {
            this.router.navigateRoot(["moreoptions"]);
        } else {
            this.router.navigateRoot(["dashboard"]);
        }
    }

    removeReportModal = new RemoveReportModal();

    selectSinglereport(list, e) {
        list.isSelected = e.target.checked;
        if (list.isSelected) {
            this.selectedReport.push(list.claimId);
        } else {
            let index = _.indexOf(this.selectedReport, list.claimId);
            this.selectedReport.splice(index, 1);
        }

        this.downloadModel.referenceIds = this.selectedReport.toString();
        if (list.isSelected && list.status == 1) {
            this.submitBtn = true;
            this.reportDraftArray.push(list.claimId);
            this.removeReportModal.claimIds = this.reportDraftArray;
        } else {
            let index = _.indexOf(this.reportDraftArray, list.claimId);
            this.reportDraftArray.splice(index, 1);
        }
        if (
            this.reportList.length != this.selectedReport.length &&
            this.selectedReport.length > 0
        ) {
            this.checkAll = false;
            this.indeterminateState = true;
        } else if (this.selectedReport.length == 0) {
            this.checkAll = false;
            this.indeterminateState = false;
        } else {
            this.checkAll = true;
            this.indeterminateState = false;
        }
    }

    selectAllreports(event?) {
        setTimeout(() => {
            this.reportList.forEach((item) => {
                item.isSelected = event ? false : this.checkAll;
            });
        });
    }

    removeReport(list?) {

        this.removeReportPopUp(list);
    }

    async removeReportPopUp(list?) {

        const alert = await this.alertController.create({
            mode: "md",
            message: this.translate.instant('alerts.reportdelete'),
            backdropDismiss: false,
            buttons: [
                {
                    text: this.translate.instant('common.cancel'),
                    role: "cancel",
                    handler: () => {
                    },
                },
                {
                    text: this.translate.instant('common.delete'),
                    handler: () => {
                        this.zone.run(() => {
                            this.removeReportModal.legalEntityId = this.userInfo.legalEntityId;
                            this.removeReportModal.legalEntityGUID = this.userInfo.legalEntityGuId;
                            this.removeReportModal.userId = this.userInfo.userId;
                            this.removeReportModal.userGUID = this.userInfo.userGuId;
                            this.removeReportModal.recordType = list?.recordType;
                            this.removeReportModal.categoryType = 3;
                            if (list) {
                                this.selectedReport.push(list.claimId);
                            }
                            this.removeReportModal.claimIds = this.selectedReport;
                            this.deltbuttonDisabled = true;
                            this.reportsService
                                .removeReport(this.removeReportModal)
                                .subscribe((data) => {
                                    if (!data.isError && data.errorCode == 0) {
                                        this.deltbuttonDisabled = false;
                                        this.checkAll = false;
                                        this.indeterminateState = false;

                                        this.toastr.success(data?.payload, this.translate.instant('alerts.succes'));
                                        this.reportDraftArray = [];
                                        this.getreportList(false);
                                    } else if (data.isError && data.errorCode == 2) {
                                        this.deltbuttonDisabled = false;
                                        this.toastr.error(data.errorMessage, this.translate.instant('alerts.error'));
                                    }
                                });
                        });
                    },
                },
            ],
        });

        await alert.present();
    }

    async getRepDetail(repList) {
        const modal = await this.modalController.create({
            component: DetailreportComponent,
            cssClass: "my-custom-class",
            id: 'detailreportModal',
            componentProps: {
                claimId: repList.claimId,
                claimGUID: repList.claimGUID,
                claimName: repList.claimName,
                fromPage: this.fromPage,
            },
        });
        return await modal.present();
    }

    toggleColumns(e, val: string) {
        if (e.target.checked == true) {
            this.hideColumns.push(val);
        } else {
            let index = this.hideColumns.indexOf(val);
            if (index > -1) {
                this.hideColumns.splice(index, 1);
            }
        }
    }

    showhideColumns(val: string) {
        let column = true;
        if (this.hideColumns.indexOf(val) > -1) {
            column = false;
        }
        return column;
    }

    addExpenseModal(addExpensetemp, report) {
        let navInfo = {
            url: 'reports',
            report: report
        };
        sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
        this.router.navigateRoot(['newExpenseMileageBulk']);
    }

    // addExpenseModal(addExpensetemp, report) {
    //     (this.claimId = report.claimId),
    //         (this.claimGUID = report.claimGUID),
    //         (this.claimName = report.claimName);
    //     const config = {
    //         ignoreBackdropClick: true,
    //         class: "modal-xl modal-dialog-centered",
    //         keyboard: false,
    //     };
    //     this.modalRef = this.modalService.show(addExpensetemp, config);
    // }

    showReportDetail(list) {
        this.selectAllreports();
        this.indeterminateState = false;
        this.selectedReport = [];
        this.detailReport = list;
        this.showDetailreport = true;
        this.hideReporttable = false;

    }

    oncancelClick() {
        if (this.platformType == 1) {
            this.modalRef.hide();
        }
    }

    onSaveClick() {
        this.pageNumber = 1;
        this.getreportList(true);
        this.oncancelClick();
    }

    submitReport() {
        this.reportSubmitPopUp();
    }

    async presentAlert() {
        const alert = await this.alertController.create({
            mode: "md",
            cssClass: 'my-custom-class text-center submitOk',
            message: `<img src="assets/svg/success-icon.svg" class="reportsuccess"/> <br>${this.translate.instant('alerts.reportsuccess')}`,
            buttons: [{
                text: this.translate.instant('common.ok'),
                cssClass: 'primary text-center',
            }],
        });

        await alert.present();
    }

    async reportSubmitPopUp() {
        const alert = await this.alertController.create({
            mode: "md",
            //         message: `<b>${this.translate.instant('alerts.submitto')} <hr class="mt-1"> ${this.userInfo?.reportingManagerId === null ? `` : `[${this.userInfo?.reportingManagerId}]`}  ${this.userInfo?.reportingManager ? this.userInfo?.reportingManager : this.translate.instant('alerts.manager')} </b>?<br>
            //   <small>${this.userInfo?.reportingManagerEmail ? this.userInfo?.reportingManagerEmail : ''}</small>
            //   <hr class="mt-2 mb-3">`,
            message: this.translate.instant('alerts.confirmSubmit'),
            backdropDismiss: false,
            buttons: [
                {
                    text: this.translate.instant('common.cancel'),
                    role: "cancel",
                    handler: () => {
                    },
                },
                {
                    text: this.translate.instant('common.submit'),
                    handler: () => {
                        this.zone.run(() => {
                            this.removeReportModal.userId = this.userInfo.userId;
                            this.removeReportModal.userGUID = this.userInfo.userGuId;
                            this.removeReportModal.legalEntityId = this.userInfo.legalEntityId;
                            this.removeReportModal.legalEntityGUID = this.userInfo.legalEntityGuId;
                            this.removeReportModal.claimIds = this.selectedReport;
                            this.buttonDisabled = true;
                            this.reportsService
                                .draftReport(this.removeReportModal)
                                .subscribe((res) => {
                                    if (!res.isError && res.errorCode == 0) {
                                        this.buttonDisabled = false;
                                        this.reportDraftArray = [];
                                        this.selectedReport = [];
                                        this.indeterminateState = false;
                                        // if (this.platformType == 2) {
                                        //     this.presentAlert();
                                        // }
                                        this.checkAll = false;
                                        this.getreportList(false);

                                        this.toastr.success(res?.payload, this.translate.instant('alerts.succes'));
                                    } else if (res.isError == true) {
                                        this.buttonDisabled = false;
                                        this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
                                    }
                                });
                        });
                    },
                },
            ],
        });

        await alert.present();
    }

    submitSingleReport(claimId) {
        this.subSingReportalet(claimId);
    }

    async subSingReportalet(claimId) {
        const alert = await this.alertController.create({
            mode: "md",
            //         message: `<b>${this.translate.instant('alerts.submitto')} <hr class="mt-1"> ${this.userInfo?.reportingManagerId === null ? `` : `[${this.userInfo?.reportingManagerId}]`}  ${this.userInfo?.reportingManager ? this.userInfo?.reportingManager : this.translate.instant('alerts.manager')} </b>?<br>
            //   <small>${this.userInfo?.reportingManagerEmail ? this.userInfo?.reportingManagerEmail : ''}</small>
            //   <hr class="mt-2 mb-3">`,
            message: this.translate.instant('alerts.confirmSubmit'),
            backdropDismiss: false,
            buttons: [
                {
                    text: this.translate.instant('common.cancel'),
                    role: "cancel",
                    handler: () => {
                    },
                },
                {
                    text: this.translate.instant('common.submit'),
                    handler: () => {
                        this.zone.run(() => {
                            this.selectedReport.push(claimId);
                            this.removeReportModal.userId = this.userInfo.userId;
                            this.removeReportModal.userGUID = this.userInfo.userGuId;
                            this.removeReportModal.legalEntityId = this.userInfo.legalEntityId;
                            this.removeReportModal.legalEntityGUID = this.userInfo.legalEntityGuId;
                            this.removeReportModal.claimIds = this.selectedReport;

                            this.reportsService.draftReport(this.removeReportModal).subscribe((res) => {
                                if (!res.isError && res.errorCode == 0) {
                                    this.reportDraftArray = [];
                                    this.selectedReport = [];
                                    this.indeterminateState = false;
                                    this.checkAll = false;
                                    this.getreportList(false);
                                    this.toastr.success(res?.payload, this.translate.instant('alerts.succes'));
                                } else if (res.isError == true) {
                                    this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
                                }
                            });
                        });
                    },
                },
            ],
        });

        await alert.present();
    }


    backToreport() {
        this.hideReporttable = true;
        this.showDetailreport == false;
        this.showMobileDetails = true;

    }

    showReportDetailView(getReport) {
        this.detailReport = getReport;
        this.fromPage = this.fromPettyCash ? 'petty-cash' : this.fromPage;
        this.showMobileDetails = false;
        this.selectAllreports();
    }

    getStatusList() {
        let type = { type: +2 };
        this.commonService.statusList(type).subscribe((res) => {
            if (!res.isError && res.errorCode == 0) {
                let listarray = res.payload;
                this.statusList = listarray.map((list) => new StatusListModel(list));
            } else if (res.isError == true) {
                this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
            }
        });
    }

    reportVal(e) {
        this.ExpenseorReport = e.target.value;
        this.checkAll = false;
        if (this.ExpenseorReport?.length >= 0) {
            this.resetPaging();
            this.SearchListText.next(this.ExpenseorReport);
        }
        if (this.ExpenseorReport === "") {
            this.resetPaging();
            this.getreportList(false);
        }
    }

    searchReport() {
        this.getreportList(false);
    }

    searchbar() {
        this.showSearch = !this.showSearch;
    }

    ngOnDestroy() {
        if (this.SearchListText) {
            this.SearchListText.unsubscribe();
        }
        this.resetSubscriptions();
    }

    ionViewWillLeave() {
        clearInterval(this.downloadTime);
        if (this.SearchListText) {
            this.SearchListText.unsubscribe();
        }
        this.resetSubscriptions();
    }

    async openDateSheet(isFromDate: boolean) {
        const modal = await this.modalController.create({
            component: CustomDatePickerComponent,
            componentProps: {
                selectedDate: isFromDate ? this.frmDate : this.tDate,
                fromDate: isFromDate ? new Date('1981-01-01') : this.frmDate,
                toDate: isFromDate ? this.tDate ? this.tDate : new Date() : new Date()
            },
            cssClass: 'custom-bottom-sheet'
        });
        modal.onDidDismiss().then(result => {
            if (result.role === 'changed' && result.data) {
                if (isFromDate) {
                    this.frmDate = result.data.displayDate;
                } else {
                    this.tDate = result.data.displayDate;
                }
            }
        });
        return await modal.present();
    }

    async openModal(value: string, type: string, isCategory?: boolean) {
        const componentProps = this.getComponentProps(type);
        if (value && value !== '') {
            componentProps.value = this.filterModal[componentProps.setValueKey];
        }
        const searchItemModal = await this.modalController.create({
            component: CustomSelectDropdownComponent,
            componentProps,
            keyboardClose: false,
        });
        searchItemModal.onDidDismiss()
            .then((callback) => {
                if (callback && callback.data) {
                    // TODO give onchange methods for status and category
                    if (typeof callback.data !== 'string') {
                        this[componentProps.listName] = callback.data;
                        const arr = [];
                        if (isCategory) {
                            this.categoryName = null;
                        } else {
                            this.status = null;
                        }
                        callback.data.forEach((obj: any) => {
                            if (obj.isSelected) {
                                arr.push(obj[componentProps.itemValueField]);
                                if (isCategory) {
                                    if (this.categoryName && this.categoryName !== '') {
                                        this.categoryName = `${this.categoryName} , ${obj[componentProps.itemTextField]}`;
                                    } else {
                                        this.categoryName = obj[componentProps.itemTextField];
                                    }
                                } else {
                                    if (this.status && this.status !== '') {
                                        this.status = `${this.status} , ${obj[componentProps.itemTextField]}`;
                                    } else {
                                        this.status = obj[componentProps.itemTextField];
                                    }
                                }
                            }
                        });
                        if (arr && arr.length) {
                            this.filterModal[componentProps.setValueKey] = arr;
                        }
                        /* callback.data.forEach((obj: any) => {
                          if (obj.isSelected) {
                            if (isExpenseForm) {
                              this.expenseForm.controls[type].setValue(obj[componentProps.itemTextField]);
                              this.expenseModel[componentProps.itemTextField] = obj[componentProps.itemTextField];
                              this.expenseModel[componentProps.itemValueField] = obj[componentProps.itemValueField];
                              if (type === 'categoryName') {
                                this.getMerchantlist(obj);
                              }
                            } else {
                              this.mileageForm.controls[type].setValue(obj[componentProps.itemTextField]);
                              this.expenseModel[componentProps.itemTextField] = obj[componentProps.itemTextField];
                              this.expenseModel[componentProps.itemValueField] = obj[componentProps.itemValueField];
                              if (type === 'categoryName') {
                                this.selectCategoryMileageOption(obj);
                              }
                            }
                          }
                        });
                       } else {
                        if (isExpenseForm) {
                          this.expenseForm.controls[type].setValue(callback.data);
                          this.expenseModel[componentProps.itemTextField] = callback.data;
                        } else {
                          this.mileageForm.controls[type].setValue(callback.data);
                          this.mileageForm[componentProps.itemTextField] = callback.data;
                        }*/
                    }
                }
            });
        return await searchItemModal.present();
    }

    getComponentProps(type: string) {
        switch (type) {
            case 'categoryName':
                return {
                    title: 'Categories',
                    value: null,
                    mainList: this.categoryList,
                    isMultiSelect: true,
                    itemValueField: 'categoryId',
                    itemTextField: 'categoryName',
                    listName: `categoryList`,
                    setValueKey: 'categoryIds'
                };
            case 'status':
                return {
                    title: 'Status',
                    value: null,
                    mainList: this.statusList,
                    isMultiSelect: true,
                    itemValueField: 'statusId',
                    itemTextField: 'statusDescription',
                    listName: `statusList`,
                    setValueKey: 'statusIds'
                };
        }
    }

    resetBlur(eleInput: IonInput) {
        eleInput.getInputElement().then((ele) => ele.blur());
    }
    downloadCancel() {
        this.modalRef.hide();
    }
    downloadModel = new DownloadModel();

    bulkDownload(downloadOptTemp?: TemplateRef<any>, list?) {
        if (list) {
            this.dropdownValue = list;
            const config = {
                ignoreBackdropClick: true,
                class: "modal-md modal-dialog-centered",
                keyboard: false,
            };
            this.modalRef = this.modalService.show(downloadOptTemp, config);

        } else {
            if (this.selectedReport.length == 0 || this.selectedReport.length > 1) {
                const config = {
                    ignoreBackdropClick: true,
                    class: "modal-md modal-dialog-centered",
                    keyboard: false,
                };
                this.modalRef = this.modalService.show(this.downloadLoader, config);
                this.downloadApiCall();
            } else {
                const config = {
                    ignoreBackdropClick: true,
                    class: "modal-md modal-dialog-centered",
                    keyboard: false,
                };
                this.modalRef = this.modalService.show(downloadOptTemp, config);
            }
        }


    }

    downloadPoolingStart(request, time) {
        this.downloadActionPoolCount++;
        this.downloadModel.jobId = request.jobId;
        this.downloadModel.jobGUID = request.jobGUID;
        this.commonService.downloadpoolStart(this.downloadModel).subscribe(res => {
            if (!res.isError && !res.payload?.resultFileURL && this.downloadActionPoolCount < 20) {
                this.downloadTime = setTimeout(() => {
                    this.downloadPoolingStart(res.payload, time);
                }, time);
            } else if (!res.isError && res.payload.status == 3) {
                this.downloadBtn = false;
                this.showDownloadOptions = false;
                this.selectedReport = [];
                this.dropdownValue = null;
                this.reportList.forEach(element => {
                    element.isSelected = false;
                });
                this.downloadActionPoolCount = 0;
                window.open(res['payload']['resultFileURL'], '_self');
                this.toaster.success('Download successfully', 'Success');
                this.modalRef.hide();
            } else {
                this.downloadBtn = false;
                this.showDownloadOptions = false;
                this.selectedReport = [];
                this.dropdownValue = null;
                this.reportList.forEach(element => {
                    element.isSelected = false;
                });
                this.modalRef.hide();
                this.toaster.error(res.errorMessage, 'Error');
                this.downloadActionPoolCount = 0;
            }
        });
    }

    resetPaging() {
        this.pageNumber = 1;
        this.reportList = [];
        this.reportList.length = 0;
    }

    goToAnalytics() {
        this.router.navigateRoot(['analytics']);
    }

    closeDownloadOptTemp() {
        this.modalRef.hide();
    }

    downloadOptSelector(value) {
        switch (value) {
            case 'excel':
                this.showDownloadOptions = true;
                this.downloadApiCall('NULL');
                break;
            case 'pdf':
                this.showDownloadOptions = true;
                this.downloadApiCall('PDF');
                break;
        }
    }

    downloadApiCall(value?, list?) {
        this.downloadBtn = true;
        if (this.selectedReport.length) {
            this.downloadModel.referenceIds = this.selectedReport.toString();
        } else {
            this.downloadModel.referenceIds = null;
        }
        if (this.dropdownValue) {
            this.downloadModel.referenceIds = this.dropdownValue.claimId;
            this.downloadBtn = false;
        }
        this.filterModal.isDraft = null;
        this.filterModal.recordTypes = value ? [] : (this.fromPettyCash ? [4] : [1]);
        this.downloadModel.filterBy = this.filterModal;
        this.downloadModel.userId = this.userInfo.userId;
        this.downloadModel.userGUID = this.userInfo.userGuId;
        this.downloadModel.entityType = 2;
        // if (this.userInfo.roleId == 2){
        //     this.downloadModel.isAdminView = JSON.parse(sessionStorage.getItem('isadmin'));
        // }else{
        // }
        //  this.downloadModel.isAdminView = JSON.parse(sessionStorage.getItem('isadmin'));
        this.downloadModel.legalEntityId = this.userInfo.legalEntityId;
        this.downloadModel.legalEntityGUID = this.userInfo.legalEntityGuId;
        this.downloadModel.docType = value;
        this.downloadModel.viewType = this.expenseAndReportModel.viewType;
        this.downloadModel.isSummaryView = false;
        this.downloadModel.isApprover = false;
        this.commonService.downloadList(this.downloadModel).subscribe(res => {
            if (!res.error && res.payload) {
                this.downloadPoolingStart(res.payload, 3000);
            }
        });
    }
}

