import { LcItemModel } from "../Lc/LocalConveyance.model";
import { paymentModesModel, reportPaymentDetailsModel } from "../Reports/reports.model";

export class ExpenseModel {
    expenseId: number;
    expenseGUID: string;
    recordType: number;
    categoryId: number;
    categoryType: number;
    categoryName: string;
    merchantName: string;
    merchantCode: string;
    description: string;
    discription: string;
    employeeId: string;
    expensedDate: string;
    expenseCode: string;
    claimId: number;
    claimName: string;
    claimCode: string;
    expenseType: number;
    originalCurrencyId: number;
    originalCurrencyCode: any;
    originalCurrencyIcon: any;
    originalAmount: any;
    invoiceNumber: any;
    distance: number;
    originalDistance: number;
    mileageUnit: string;
    mileageRate: number;
    mileageRateCurrencyId: number;
    originalCurrencyName: string;
    expenseItems = [];
    expenseAttachments = [];
    userId: number;
    userGUID: string;
    legalEntityId: number;
    legalEntityGUID: string;
    status: number;
    taxAmount: number;
    taxPercentage: number;
    taxNumber: any;
    dummy: any;
    location: any;
    stateName: any;
    countryId: any;
    stateId: any
    statusCode: any
    hasExpenseSplit: boolean;
    splitType: any;
    expenseUserSplitList = [];
    expenseUserSplitExList = [];
    expenseUserSplitExPerList = [];
    tripId: any;
    tripName: any;
    tripFromDate: any;
    tripEndDate: any;
    expenseTaggedTripList: any;
    claimTaggedTripList: any;
    isAssociated: any;
    url: any;
    fileName: any;
    exchangeRate: any;
    perDiemType: any;
    perDiemTypeName: any;
    perDiemRate: any;
    perDiemRateName: any;
    startTime: any;
    endTime: any;
    baseAmount: any;
    baseCurrencyCode: any;
    baseCurrencyIcon: any;
    baseCurrencyId: any;
    createdDate: any;
    expenseTypeName: any;
    hasAttach: any;
    hasViolation: any;
    statusDescription: any;
    unformattedBaseAmount: any;
    unformattedOriginalAmount: any;
    taxCode: any;
    taxCodeId: any;
    hsnCode: any;
    hsnId: any;
    financeIntegrationCode: any;
    financeIntegrationId: any;
    originalApproveAmount: any;
    unformattedOriginalApproveAmount: any
    approveAmount: any;
    fromLocationName: any;
    toLocationName: any;
    fromLatitude: any;
    fromLangitude: any;
    toLatitude: any;
    toLangitude: any;
    Flocation: any;
    Tlocation: any;
    financeCodeName: any;
    financeIntegrationName: any;
    mapMyIndiaReferenceIds: any;
    checkPointsList: any;
    action: any;
    mileageSessionId: any;
    allowItemizedExpenses: any;
    isSelected: boolean = false;
    claimStartDate: any;
    claimEndDate: any;
    approvedDistance: any;
    customFieldList: any
    categoryCode: any;
}

export class expenseUserSplitListModel {
    amount: number;
    baseAmount: number;
    baseCurrencyId: number;
    exchangeRate: number;
    id: number;
    originalCurrencyId: number;
    userId: number;
    userName: string;

}

export class ExpenseItemsModel {
    name: string;
    amount: string;
    itemId: any;
    taxAmount: any;
    taxPercentage: any;
    constructor(list) {
        this.name = list.name;
        this.amount = list.amount;
        this.itemId = list.itemId;
        this.taxAmount = list.taxAmount;
        this.taxPercentage = list.taxPercentage;

    }
}

export class ExpenseAttachmentsModel {
    fileName: string;
    content: string;
    url: string;
    isDeleted: number;


}

export class ExpenseAttachments {
    fileName: string;
    content: string;
    url: string;
    isDeleted: number;

    constructor(expAttachments: any) {
        if (expAttachments) {
            this.fileName = expAttachments.fileName;
            this.content = expAttachments.content;
            this.url = expAttachments.url;
            this.isDeleted = expAttachments.isDeleted;
        }
    }
}

export class ExpenseItemWiseModel {
    name: string;
    amount: number;
    unformattedAmount: number;
    itemId: any;
    taxAmount: any;
    taxPercentage: any;


    constructor(expItems: any) {
        if (expItems) {
            this.name = expItems.name;
            this.amount = expItems.amount;
            this.unformattedAmount = expItems.unformattedAmount;
            this.itemId = expItems.itemId;
            this.taxAmount = expItems.taxAmount;
            this.taxPercentage = expItems.taxPercentage;
        }
    }
}


export class stateListModel {
    stateName: string;
    stateId: any;
    countryId: any;
    statusCode: any;
    countryName: any;
    constructor(list: any) {
        if (list) {
            this.stateName = list.stateName
            this.stateId = list.stateId
            this.countryId = list.countryId
            this.statusCode = list.statusCode
            this.countryName = list.countryName;
        }
    }
}

export class CitiesModel{
    stateId:any;
    stateName:any;
    cityId:any;
    cityName:any;
    gstNumber: any;
    stateTin: any;
    constructor(item){
        this.stateId = item.stateId;
        this.stateName = item.stateName;
        this.cityId = item.cityId;
        this.cityName = item.cityName;
        this.gstNumber = item.gstNumber;
        this.stateTin = item.stateTin;
    }
}


export class ExpenseListModel {
    userDesignation:any;
    userLocationName:any;
    userDepartment:any;
    expenseId: number;
    expenseGUID: string;
    recordType: number;
    expenseCode: string;
    userId: number;
    userGUID: string;
    legalEntityId: number;
    legalEntityGUID: string;
    categoryId: number;
    categoryName: string;
    categoryType: number;
    merchantName: string;
    merchantCode: any;
    description: string;
    employeeId: string;
    expensedDate: string;
    submittedDate: string;
    claimId: number;
    claimGUID: any;
    claimName: string;
    claimCode: string;
    paymentReferenceNumber: string;
    expenseType: number;
    originalCurrencyId: number;
    originalCurrencyCode: string;
    originalCurrencyIcon: string;
    originalAmount: number;
    baseCurrencyId: number;
    baseCurrencyCode: string;
    baseCurrencyIcon: string;
    totalBaseAmount: string;
    baseAmount: number;
    distance: number;
    mileageUnit: string;
    mileageRate: number;
    mileageRateCurrencyId: number;
    mileageRateCurrencyCode: string;
    mileageRateCurrencyIcon: string;
    status: number;
    statusDescription: string;
    createdDate: string;
    expenseItems: Array<ExpenseItemWiseModel>;
    expenseAttachmentsmodel: Array<ExpenseAttachmentsModels>;
    expenseAttachments: Array<ExpenseAttachmentsModels>;
    expenseHistoryList: Array<ExpenseHistoryList>;
    approversList: Array<approversList>;

    reportPaymentDetails: reportPaymentDetailsModel;
    settlementList:[];
    linkedClaimList:[];
    filterBy: {};
    pageNumber: number;
    pageSize: number;
    sortType: number;
    sortColumn: string;
    isSelected = false;
    expenseMinAmount: number;
    expenseMaxAmount: number;
    unformattedBaseAmount: number;
    unformattedOriginalAmount: number;
    exchangeRate: number;
    reason: string;
    tripId: number;
    tripName: string;
    tripFromDate: string;
    tripToDate: string;
    expenseTypeName: string;
    userName: string;
    hasViolation: any;
    violationList: Array<ViolationModel>;
    localConveyanceList: Array<LcItemModel>;
    showWarning = false;
    taxAmount: number;
    taxPercentage: number;
    taxNumber: any;
    invoiceNumber: any;
    count: number;
    location: string;
    expenseUserSplitList: Array<SplitExpenseListModel>;
    hasExpenseSplit: any;
    isPrevious: any;
    requestFormTypeName: any;
    requestFormType: any;
    travelDate: any;
    flightDetails: any;
    hotelDetails: any;
    advanceAmount: any;
    approvedAmount: any;
    expenseTaggedTripList: any;
    claimTaggedTripList=[];
    pettyCashAmount: any;
    approverName: any;
    locationName: any;
    perDiemRateName: any;
    perDiemTypeName: any;
    startTime: any;
    endTime: any;
    startDate: any;
    endDate: any;
    perDiemType: any;
    hasAttach: any;
    isAssociated: any;
    splitType: any;
    taxCode: any;
    taxCodeId: any;
    financeIntegrationCode: any;
    financeIntegrationId: any;
    hsnCode: any;
    hsnId: any;
    isSELECT: any;
    approveAmount: any;
    rejectedAmount: any;

    originalApproveAmount: any;
    unformattedOriginalApproveAmount: any;


    fromLocationName: any;
    toLocationName: any;
    fromLatitude: any;
    fromLangitude: any;
    toLatitude: any;
    toLangitude: any

    baseRejectedAmount: any;
    baseApproveAmount: any;

    financeIntShow: any;
    taxIntShow: any;
    hsnIntShow: any;
    finaceIntCodeName: any;
    financeIntegrationName: any;
    checkPointsList: any;
    loggedInUserActionType: any
    approvedDistance: any;
    editedExchangeRate:number
    customFieldList: any;
    bandId: any;
    departmentId: any;
    costCenterId: any;
    projectCodeId: any;
    bandName: any;
    departmentName: any;
    costCenterName: any;
    projectCodeName: any;
    settlement:any;
    availableTripAdvanceAmount:any;
    showSettlementButton:boolean;
    tripTravellersList =[];
    totalAdvanceAmount:any;
    totalBaseAmounts:any;
    approverFirstName: any;
    productId: any;
    coUserId: any;
    coUserName: any;
    isPerDayEnabled: any;
    categoryCode: any;
    customGlcode: any;
    expenseEndDate: any;
    countryName: any;
    cityId: any;
    cityName: any;
    countryId: any;
    tripEstimateDetails: any;
    gstMissMatch: any = false;
    mobileMissMatch: any = false;
    claimLevelVoilation: any;
    parentCategoryId: any;
    parentCategoryName: any;
    paidBy: any;
    paidByName: any;
    canEditClaim: boolean;
    showProofofpayment: boolean;
    proofOfPaymentFileUrl: any;
    isAutoCalculateDistanceAmount: boolean;
    mandateFromToLocations: boolean;
    restrictClaimOnStartEndDates: boolean;
    amountAutoCalculated: boolean
    isBillNumberManditory: boolean
    perDiemId: any;
    policyId: any;
    duration: any;
    isAttachmentMandatory: boolean;
    isAlcohol: boolean = false;
    showMultiCurrencies: boolean = false;
    validateGSTAmount: boolean = false;
    constructor(list: any) {
        if (list) {
            this.showSettlementButton =list.showSettlementButton;
            this.availableTripAdvanceAmount=list.availableTripAdvanceAmount;
            this.settlement =list.settlement;
            this.editedExchangeRate=list.editedExchangeRate;
            this.userDepartment=list.userDepartment;
            this.userDesignation=list.userDesignation;
            this.userLocationName=list.userLocationName;
            this.expenseId = list.expenseId;
            this.expenseGUID = list.expenseGUID;
            this.recordType = list.recordType;
            this.expenseCode = list.expenseCode;
            this.userId = list.userId;
            this.userGUID = list.userGUID;
            this.userName = list.userName;
            this.legalEntityId = list.legalEntityId;
            this.legalEntityGUID = list.legalEntityId;
            this.categoryId = list.categoryId;
            this.expenseTypeName = list.expenseTypeName;
            this.categoryName = list.categoryName;
            this.categoryType = list.categoryType;
            this.merchantName = list.merchantName;
            this.merchantCode = list.merchantCode;
            this.description = list.description;
            this.employeeId = list.employeeId;
            this.expensedDate = list.expensedDate;
            this.submittedDate = list.submittedDate;
            this.claimId = list.claimId;
            this.claimName = list.claimName;
            this.claimCode = list.claimCode;
            this.expenseTaggedTripList = list.expenseTaggedTripList;
            this.claimTaggedTripList = list.claimTaggedTripList;
            this.paymentReferenceNumber = list.paymentReferenceNumber;
            this.expenseType = list.expenseType;
            this.originalCurrencyId = list.originalCurrencyId;
            this.originalCurrencyCode = list.originalCurrencyCode;
            this.originalCurrencyIcon = list.originalCurrencyIcon;
            this.originalAmount = list.originalAmount;
            this.baseCurrencyId = list.baseCurrencyId;
            this.baseCurrencyCode = list.baseCurrencyCode;
            this.baseCurrencyIcon = list.baseCurrencyIcon;
            this.baseAmount = list.baseAmount;
            this.distance = list.distance;
            this.mileageUnit = list.mileageUnit;
            this.mileageRate = list.mileageRate;
            this.mileageRateCurrencyId = list.mileageRateCurrencyId;
            this.mileageRateCurrencyCode = list.mileageRateCurrencyCode;
            this.mileageRateCurrencyIcon = list.mileageRateCurrencyIcon;
            this.status = list.status;
            this.reason = list.reason;
            this.tripId = list.tripId;
            this.count = list.count;
            this.tripName = list.tripName;
            this.tripFromDate = list.tripFromDate;
            this.tripToDate = list.tripToDate;
            this.statusDescription = list.statusDescription;
            this.createdDate = list.createdDate;
            this.userLocationName = list.userLocationName;
            this.userDesignation = list.userDesignation;
            this.userDepartment = list.userDepartment;
            this.expenseItems = list.expenseItems
                ? list.expenseItems.map(
                    (expItems) => new ExpenseItemWiseModel(expItems)
                )
                : [];
                this.approversList = list.approversList
                ? list.approversList.map(
                    (approverList) => new approversList(approverList)
                )
                : [];
                this.settlementList = list.settlementList || [];
                this.linkedClaimList=list.linkedClaimList ||[];
            this.expenseAttachmentsmodel = list.expenseAttachments
                ? list.expenseAttachments.map(
                    (expAttachments) => new ExpenseAttachmentsModels(expAttachments)
                )
                : [];
            this.expenseAttachments = list.expenseAttachments
                ? list.expenseAttachments.map(
                    (expAttachments) => new ExpenseAttachmentsModels(expAttachments)
                )
                : [];
            this.filterBy = list.FilterBy;
            this.pageNumber = list.pageNumber;
            this.pageSize = list.pageSize;
            this.sortType = list.sortType;
            this.sortColumn = list.sortColumn;
            this.expenseMaxAmount = list.expenseMaxAmount;
            this.expenseMinAmount = list.expenseMinAmount;
            this.unformattedBaseAmount = list.unformattedBaseAmount;
            this.unformattedOriginalAmount = list.unformattedOriginalAmount;
            this.exchangeRate = list.exchangeRate;
            this.expenseCode = list.expenseCode;
            this.expenseHistoryList = list.expenseHistoryList
                ? list.expenseHistoryList.map(
                    (expItems) => new ExpenseHistoryList(expItems)
                )
                : [];
            this.hasViolation = list.hasViolation;
            this.localConveyanceList = list.localConveyanceList;
            this.violationList = list.violationList
                ? list.violationList.map(
                    (violatn) => new ViolationModel(violatn)
                )
                : [];
            this.taxAmount = list.taxAmount;
            this.taxPercentage = list.taxPercentage;
            this.taxNumber = list.taxNumber;
            this.invoiceNumber = list.invoiceNumber;
            this.location = list.location;
            this.expenseUserSplitList = list.expenseUserSplitList;
            this.hasExpenseSplit = list.hasExpenseSplit;
            this.isPrevious = list.isPrevious;
            this.requestFormTypeName = list.requestFormTypeName;
            this.requestFormType = list.requestFormType;
            this.travelDate = list.travelDate;
            this.flightDetails = list.flightDetails;
            this.hotelDetails = list.hotelDetails;
            this.advanceAmount = list.advanceAmount;
            this.approvedAmount = list.approvedAmount;
            this.pettyCashAmount = list.pettyCashAmount;
            this.approverName = list.approverName;

            this.locationName = list.locationName;
            this.perDiemRateName = list.perDiemRateName;
            this.perDiemTypeName = list.perDiemTypeName;
            this.startTime = list.startTime;
            this.endTime = list.endTime;
            this.startDate = list.startDate;
            this.endDate = list.endDate;
            this.perDiemType = list.perDiemType;
            this.hasAttach = false;
            this.splitType = list.splitType;
            this.isAssociated = list.isAssociated;

            this.taxCode = list.taxCode;
            this.taxCodeId = list.taxCodeId;
            this.financeIntegrationCode = list.financeIntegrationCode;
            this.financeIntegrationName = list.financeIntegrationName;
            this.financeIntegrationId = list.financeIntegrationId;
            this.hsnCode = list.hsnCode;
            this.hsnId = list.hsnId;
            this.isSELECT = null;
            this.approveAmount = list.approveAmount;
            this.rejectedAmount = list.rejectedAmount;

            this.unformattedOriginalApproveAmount = list.unformattedOriginalApproveAmount;
            this.originalApproveAmount = list.unformattedOriginalApproveAmount;
            this.reportPaymentDetails = list.reportPaymentDetails;

            this.fromLocationName = list.fromLocationName;
            this.toLocationName = list.toLocationName;
            this.fromLatitude = list.fromLatitude;
            this.fromLangitude = list.fromLangitude;
            this.toLatitude = list.toLatitude;
            this.toLangitude = list.toLangitude;
            this.baseRejectedAmount = list.baseRejectedAmount;
            this.baseApproveAmount = list.unformattedApproveBaseAmount;

            this.financeIntShow = list.financeIntShow;
            this.taxIntShow = list.taxIntShow;
            this.hsnIntShow = list.hsnIntShow;
            this.finaceIntCodeName = list.finaceIntCodeName;
            this.checkPointsList = list.checkPointsList;
            this.loggedInUserActionType = list.loggedInUserActionType;
            this.approvedDistance = list.approvedDistance;
            this.customFieldList = list.customFieldList || [];
            this.tripTravellersList=list.tripTravellersList ||[];
            this.bandId = list.bandId || '';
            this.bandName = list.bandName || '';
            this.departmentId = list.departmentId || '';
            this.departmentName = list.departmentName || '';
            this.costCenterId = list.costCenterId || '';
            this.costCenterName = list.costCenterName || '';
            this.projectCodeId = list.projectCodeId || '';
            this.projectCodeName = list.projectCodeName || '';
            this.totalBaseAmount = list.totalBaseAmount;
            this.totalAdvanceAmount = list.totalAdvanceAmount || '';
            this.claimGUID = list.claimGUID || '';
            this.totalBaseAmounts = list.totalBaseAmounts || '';
            this.approverFirstName = list.approverFirstName || null;
            this.productId = list.productId || null;
            this.coUserId = list.coUserId || null;
            this.coUserName = list.coUserName || null;
            this.isPerDayEnabled = list.isPerDayEnabled;
            this.categoryCode = list.categoryCode;
            this.customGlcode = list.customGlcode;
            this.expenseEndDate = list.expenseEndDate;
            this.cityId = list.cityId;
            this.cityName = list.cityName;
            this.countryId = list.countryId;
            this.countryName = list.countryName;
            this.tripEstimateDetails = list.tripEstimateDetails || null;
            this.gstMissMatch = list.gstMissMatch;
            this.mobileMissMatch = list.mobileMissMatch;
            this.claimLevelVoilation = list.claimLevelVoilation;
            this.parentCategoryId = list.parentCategoryId;
            this.parentCategoryName = list.parentCategoryName;
            this.paidBy = list.paidBy;
            this.paidByName = list.paidByName;
            this.canEditClaim = list.canEditClaim;
            this.showProofofpayment = list.showProofofpayment;
            this.proofOfPaymentFileUrl = list.proofOfPaymentFileUrl;
            this.isAutoCalculateDistanceAmount = list.isAutoCalculateDistanceAmount;
            this.mandateFromToLocations = list.mandateFromToLocations;
            this.restrictClaimOnStartEndDates = list.restrictClaimOnStartEndDates;
            this.amountAutoCalculated = list.amountAutoCalculated;
            this.isBillNumberManditory = list.isBillNumberManditory;
            this.perDiemId = list.perDiemId;
            this.policyId = list.policyId;
            this.duration = list.duration;
            this.isAttachmentMandatory = list.isAttachmentMandatory;
            this.isAlcohol = list.isAlcohol;
            this.showMultiCurrencies = list.showMultiCurrencies;
            this.validateGSTAmount = list.validateGSTAmount;
        }
    }
}
export class TripsListModel {
    tripId: any;
    tripName: any;
    tripStartDate: any;
    tripGUID: any;
    tripEndDate: any;
    tripDetails: any;
    tripCost: any;
    formattedTripCost: any;
    currencyCode: any;
    createdDate: any;
    productId: any;
    productName: any;
    statusDescription: any;
    paymentMode: any;
    paymentModeId: any;
    statusId: any;
    requestGUID: any;
    requestId: any;
    isTagged: any;
    isDisable = false;
    taggedExpenses: Array<any>
    isSelected = false;
    baseCurrencyIcon: string;
    serviceList:Array<any>
    supplierName:any;
    isMultiService:boolean;
    serviceName:any;
    currencyIcon:any;
    constructor(list) {
        this.currencyIcon=list.currencyIcon;
        this.serviceName=list.serviceName
        this.supplierName =list.supplierName;
        this.tripId = list.tripId;
        this.tripName = list.tripName;
        this.tripStartDate = list.tripStartDate;
        this.tripEndDate = list.tripEndDate;
        this.tripDetails = list.tripDetails;
        this.tripCost = list.tripCost;
        this.formattedTripCost = list.formattedTripCost;
        this.currencyCode = list.currencyCode;
        this.createdDate = list.createdDate;
        this.productId = list.productId;
        this.productName = list.productName;
        this.statusDescription = list.statusDescription;
        this.paymentMode = list.paymentMode;
        this.paymentModeId = list.paymentModeId;
        this.statusId = list.statusId;
        this.tripGUID = list.tripGUID;
        this.isSelected = list.isSelected;
        this.requestId = list.requestId;
        this.requestGUID = list.requestGUID;
        this.isTagged = list.isTagged;
        this.taggedExpenses = list.taggedExpenses;
        this.isDisable = list.isDisable;
        this.baseCurrencyIcon =list.baseCurrencyIcon;
        this.serviceList = list.serviceList;
        this.isMultiService=list.isMultiService
    }
}

export class SplitExpenseListModel {
    amount: any;
    baseAmount: any;
    baseCurrencyId: number;
    exchangeRate: number;
    id: number;
    originalCurrencyId: number;
    userId: number;
    userName: string;
    userLogo: string;
    percentage: number;
    employeeId: any;
    constructor(list: any) {
        this.amount = list.amount;
        this.baseAmount = list.baseAmount;
        this.baseCurrencyId = list.baseCurrencyId;
        this.exchangeRate = list.exchangeRate;
        this.id = list.id;
        this.originalCurrencyId = list.originalCurrencyId;
        this.userId = list.userId;
        this.userName = list.userName;
        this.userLogo = list.userLogo;
        this.percentage = list.percentage;
        this.employeeId = list.employeeId
    }
}

export class violationListModel {
    violationId: number;
    referenceId: number;
    referenceType: number;
    violationText: string;
    statusId: number;
}

export class ExpenseHistoryList {
    userName: string;
    comments: string;
    employeeId: string;
    createdDate: string;
    statusDescription: string;
    referenceId: string;

    constructor(list) {
        this.userName = list.userName;
        this.comments = list.comments;
        this.employeeId = list.employeeId;
        this.createdDate = list.createdDate;
        this.statusDescription = list.statusDescription;
        this.referenceId = list.referenceId;
    }

}
export class approversList {
    approverId: number;
    employeeId: number;
    approverName: any
    approverTypeName:any
    categoryName: any
    level: number
    statusDescription: any
    claimId: number
    categoryId:number
    statusId: number

    constructor(list) {
       this.approverId=list.approverId;
       this.employeeId=list.employeeId;
       this.approverName=list.approverName;
       this.approverTypeName=list.approverTypeName;
       this.categoryName=list.categoryName;
       this.level=list.level;
       this.statusDescription=list.statusDescription;
       this.claimId=list.claimId;
       this.categoryId=list.categoryId;
       this.statusId=list.statusId
    }

}
export class ExpenseAttachmentsModels {
    attachmentId: number;
    fileName: string;
    content: string;
    url: string;
    base64: any;
    isDeleted: number;
    remarks: string;
    referenceId: any;
    attachmentType: any;
    isSelected:boolean = false;

    constructor(expAttachments: any) {
        if (expAttachments) {
            this.attachmentId = expAttachments.attachmentId;
            this.fileName = expAttachments.fileName;
            this.content = expAttachments.content;
            this.url = expAttachments.url;
            this.base64 = expAttachments.base64;
            this.isDeleted = expAttachments.isDeleted;
            this.remarks = expAttachments.remarks;
            this.referenceId = expAttachments.referenceId;
            this.attachmentType = expAttachments.attachmentType;
            this.isSelected = expAttachments.isSelected;
        }
    }
}

export class ExpenseItemWiseModels {
    name: string;
    amount: number;

    constructor(expItems: any) {
        if (expItems) {
            this.name = expItems.name;
            this.amount = expItems.amount;
        }
    }
}

export class GetExpenseModels {
    expenseId: number;
    expenseGUID: string;
    userId: number;
    userGUID: string;
    legalEntityId: string;
    legalEntityGUID: string;
    expenseIds: Array<number>;
    claimId:any;
    claimGuid:any;
    claimGUID:any;
    recordType:any;
}
export class settlementmodal {
    expenseId:any;
    expenseGUID:string;
    claimId:any;
    claimGuid: any;
    modeOfSettlement:any;
    availableTripAdvanceAmount:any
    settlementAttachments=[];
    settlementDate: string;
    comments:any;
}
export class CheckPointsList {
    checkpoint = [];
    lat: any;
    lang: any;
    location: string
}
export class checkpointModel {
    checkPointLocationName: string = '';
    id: any;
    expenseId = null;
}

export class checkpointCon {
    checkPointLocationName: string = '';
    id: any;
    expenseId = null;
    constructor(list) {
        this.checkPointLocationName = list.checkPointLocationName;
        this.id = list.id;
        this.expenseId = list.expenseId;
    }
}
export class CapturedImageModal {
    capturedImages = [];
}

export class FilterBy {
    unassigned: boolean;
    categoryIds: Array<number>;
    statusIds: Array<number>;
    categoryType: number;
    searchText: string;
    isDraft: boolean;
    fromDate: string;
    toDate: string;
    minPrice: number;
    maxPrice: number;

    constructor(list) {
        if (list) {
            this.unassigned = list.unassigned;
            this.categoryIds = list.categoryIds;
            this.statusIds = list.statusIds;
            this.categoryType = list.categoryType;
            this.searchText = list.searchText;
            this.isDraft = list.isDraft;
            this.fromDate = list.fromDate;
            this.toDate = list.toDate;
            this.minPrice = list.minPrice;
            this.maxPrice = list.maxPrice;
        }
    }
}

export class UnassignedExpList {
    expenseGUID: string;
    userId: number;
    userGUID: string;
    legalEntityId: number;
    legalEntityGUID: string;
    FilterBy: {};
    pageNumber: number;
    pageSize: number;
    sortType: number;
    sortColumn: string;

    constructor(list: any) {
        if (list) {
            this.expenseGUID = list.expenseId;
            this.userId = list.userId;
            this.userGUID = list.userGUID;
            this.legalEntityId = list.legalEntityGUID;
            this.legalEntityGUID = list.legalEntityGUID;
            this.FilterBy = list.FilterBy;
            this.pageNumber = list.pageNumber;
            this.pageSize = list.pageSize;
            this.sortType = list.sortType;
            this.sortColumn = list.sortColumn;
        }
    }
}

export class DeleteExpenses {
    legalEntityId: number;
    legalEntityGUID: string;
    userId: number;
    userGUID: string;
    expenseIds: Array<number>;
    recordType: number;
    categoryType: number;
    claimIds:Array<number>;
}

export class AddReportExpense {
    legalEntityId: number;
    legalEntityGUID: string;
    userId: number;
    userGUID: string;
    claimId: number;
    claimName: string;
    expenseIds: Array<number>;
}

export class ocrReqModel {
    attachments = [];
    uploadType: string;
    extract: boolean;
}

export class ocrAttachment {
    fileName: string;
    base64Image: string;
}

export class bulkOCRAttachment {
    fileName: string;
    base64Image: string;
    uploadType: string;
}

export class FileURLModel {
    fileName: string;
    base64Image: string;
    fileUploadFor: string;
}

export class BulkModel {
    legalEntityId: number;
    legalEntityGUID: string;
    userId: number;
    userGUID: string;
    claimId: number;
    claimName: string;
    isLinkedToAdvancePayment:boolean;
    isLinkedToPettyCashPayment:boolean;
    tripTravellers=[];
    advanceAmount:number;
    action: number;
    expenseRequestDTOList: any;
    claimTaggedTripList: any;
    currentIndex: any;
    isCSVFile: any;
    csvFileName: any;
    loaderBoolean: boolean = false;
    showList: boolean = false;
    allowItemizedExpenses: any;
    categoryType: any;
    claimStartDate: any;
    claimEndDate: any;
    perdiemRequestDTO: any;
    claimCategoryName: any;
    claimCategoryId: any;
    claimTypeId: any;
    claimTypeName: any;
    customFields: any;
    reasonForTravel: any;
    bookingType: any;
    bookingTypeName: any;
    costCenterCode: any;
    isTripDirectSubmit: any;
    showRestrictMessage: boolean = false;
    expenseList: any;
    budgetRefNumber: any;
    claimStartTime: any;
    claimEndTime: any;
    billingEntityName: any;
    tripEstimateCost: any;
    tripEstimateDetails:any;
    tripEstimateInUSD: any;
    tripStartDate: any;
    tripEndDate: any;
    nonTravelAdvanceRefId: any;
}

export class BulkItemModel {
    isSelected: boolean;
    date: any;
    amount: any;
    cardTrasactionId: any;
    exchangeRate:any;
    parentCategoryId: any;
    parentCategoryName: string;
    categoryId: any;
    categoryName: string;
    categoryType: any;
    description: string;
    expensedDate: string;
    expenseItems = [];
    expenseType: number;
    expenseAttachments = [];
    merchantName: string;
    merchantCode: any;
    currencyCode: string;
    originalCurrencyName: any;
    originalCurrencyId: number;//
    originalCurrencyIcon: any;
    originalAmount: number;//
    distance: number;
    mileageUnit: string;
    mileageRate: number;
    bulkImgUpload: any;
    merchantList: any;
    userlists: any;
    hasExpenseSplit: boolean
    merchantList_temp: any;
    loaderBoolean: any;
    invoiceNumber: string;
    taxNumber: any;
    taxPercentage: any;
    taxAmount: any;
    location: any;
    expConvRate: any;
    policyLimitAmountWarning: false;
    categoryLimitAmountWarning: false;
    policyLimitReceiptWarning: false;
    categoryLimitReceiptWarning: false;
    showOptions: boolean = false;
    expenseDateExceed: boolean = false;
    reportexpenseDateExceed: boolean = false;
    expenseDateExist: boolean = false;
    violationType: any = 1;
    limitType: any = 1;
    limitValue: any = 0;
    ruleType: any = 1;
    categoryCountLimitWarning: boolean = false;
    showCategoryList: boolean;
    showMerchantList: boolean;
    approversList: any;
    deviatedApproversList: any;
    monthlyDataList: any;
    expenseUserSplitList: any = [];
    // deviatedApproversList: any;
    expenseId: any;
    expenseGUID: any;
    expenseCode: any;
    recordType: any;
    status: any;
    financeIntegrationCode: any;
    financeIntegrationId: any;
    taxCode: any;
    taxCodeId: any;
    hsnCode: any;
    hsnCodeId: any;
    originalApproveAmount: any
    editedExchangeRate:number;
    customFieldList = [];
    showCustomFields= false;
    bandId: any;
    departmentId: any;
    costCenterId: any;
    projectCodeId: any;
    bandName: any;
    departmentName: any;
    costCenterName: any;
    projectCodeName: any;
    showAdditionalFields = false;
    paidBy: any;
    paidByName: any;
    showpaidByList = false;
    financeCodeName: any;
    financeIntegrationName: any;
    isStartEndDateEnabled: boolean = false;
    isSpentLocationManditory:boolean = false;
    isPolicyCalcPerNight: boolean = false;
    cityName:any;
    cityId:any;
    expenseEndDate:any;
    productId:any;
    designationId: any;
    designationName: any;
    coUserId: any;
    coUserName: any;
    countryName:any;
    countryId:any;
    isPerDayEnabled = false;
    isAlcohol = false;
    stateId: any;
    stateName: any;
    restrictMessage:any;
    showRestrictMessage:any;
    merchantId: any;
    categoryCode: any;
    coEmployeeId: any;
    stateTin: any;
    gstMissMatch: any = false;
    mobileMissMatch: any = false;
    proofOfPaymentFileUrl: any;
    proofOfPaymentLoader: any;
    isProofOfPaymentAttachmentRequired: any = false;
    isCardExpense: any = false;
    showProofofpayment: any;
    restrictClaimOnStartEndDates: any;
}

export class ViolationModel {
    violationId: number;
    referenceId: number;
    referenceType: number;
    violationCode: number;
    violationMessage: string;
    statusId: number;

    constructor(list) {
        this.violationId = list.violationId;
        this.referenceId = list.referenceId;
        this.referenceType = list.referenceType;
        this.violationCode = list.violationCode;
        this.violationMessage = list.violationMessage;
        this.statusId = list.statusId;
    }
}

export class RuleCheckModel {
    categoryId: number;
    userId: number;
    userGUID: string;
    ruleType: number;
    limitValue: number;
}

export class DistanceobjetModel {
    lat: any;
    lang: any;
    location: string;
}

export class flightDetailsModel {
    requestId: any;
    travelType: any;
    travelMode: any;
    cabinClass: any;
    departureDate: any;
    returnDate: any;
    adultCount: any;
    childCount: any;
    infantCount: any;
    statusId: any;
    reasonForTravel: any;
    passengerList: Array<PaxDetailsModel>;
    journeyDetails: Array<journeyDetailsModel>;
    constructor(list) {
        this.requestId = list.requestId;
        this.travelType = list.travelType;
        this.travelMode = list.travelMode;
        this.cabinClass = list.cabinClass;
        this.departureDate = list.departureDate;
        this.returnDate = list.returnDate;
        this.adultCount = list.adultCount;
        this.childCount = list.childCount;
        this.infantCount = list.infantCount;
        this.statusId = list.statusId;
        this.reasonForTravel = list.reasonForTravel;
        this.passengerList = list.passengerList;
        this.journeyDetails = list.journeyDetails;

        this.passengerList = list.passengerList
            ? list.passengerList.map(
                (pax) => new PaxDetailsModel(pax)
            )
            : [];
        this.journeyDetails = list.journeyDetails
            ? list.journeyDetails.map(
                (journey) => new journeyDetailsModel(journey)
            )
            : [];
    }
}

export class hotelDetailsModel {
    requestId: any;
    travelMode: any;
    travelModeName: any;
    checkInDate: any;
    checkOutDate: any;
    totalRooms: any;
    paxCount: any;
    adultCount: any;
    childCount: any;
    infantCount: any;
    reasonForTravel: any;
    nationality: any;
    hotelCategory: any;
    travelling: any;
    cityName: any;
    hotelCityName: any;
    guestList: Array<PaxDetailsModel>;
    constructor(list) {
        this.requestId = list.requestId;
        this.travelMode = list.travelMode;
        this.travelModeName = list.travelModeName;
        this.checkInDate = list.checkInDate;
        this.checkOutDate = list.checkOutDate;
        this.totalRooms = list.totalRooms;
        this.paxCount = list.paxCount;
        this.adultCount = list.adultCount;
        this.childCount = list.childCount;
        this.infantCount = list.infantCount;
        this.reasonForTravel = list.reasonForTravel;
        this.nationality = list.nationality;
        this.hotelCategory = list.hotelCategory;
        this.travelling = list.travelling;
        this.cityName = list.cityName;;
        this.hotelCityName = list.hotelCityName;
        this.guestList = list.guestList ? list.guestList.map((pax) => new PaxDetailsModel(pax)) : [];

    }


}

export class journeyDetailsModel {
    fromAirportCode: any;
    toAirportCode: any;
    fromCityCountryCode: any;
    toCityCountryCode: any;
    departureDate: any;
    returnDate: any;
    arrivalDate: any;
    fromCityId: any;
    toCityId: any;
    fromCityName: any;
    fromAirportName: any;
    toCityName: any;
    toAirportName: any;
    constructor(list) {
        this.fromAirportCode = list.fromAirportCode;
        this.toAirportCode = list.toAirportCode;
        this.fromCityCountryCode = list.fromCityCountryCode;
        this.toCityCountryCode = list.toCityCountryCode;
        this.departureDate = list.departureDate;
        this.departureDate = list.departureDate;
        this.returnDate = list.returnDate;
        this.arrivalDate = list.arrivalDate;
        this.fromCityId = list.fromCityId;
        this.toCityId = list.toCityId;
        this.fromCityName = list.fromCityName;
        this.fromAirportName = list.fromAirportName;
        this.toCityName = list.toCityName;
        this.toAirportName = list.toAirportName;
    }
}

export class PaxDetailsModel {
    userType: any;
    userId: any;
    title: any;
    firstName: any;
    lastName: any;
    gender: any;
    primaryContactNumber: any;
    primaryEmailId: any;
    dateOfBirth: any;
    roomNumber: any
    constructor(list) {
        this.userType = list.userType;
        this.userId = list.userId;
        this.title = list.title;
        this.firstName = list.firstName;
        this.lastName = list.lastName;
        this.gender = list.gender;
        this.primaryContactNumber = list.primaryContactNumber;
        this.primaryEmailId = list.primaryEmailId;
        this.dateOfBirth = list.dateOfBirth;
        this.roomNumber = list.roomNumber;
    }
}

export class FinanceIntCodeModel {
    financeIntegrationId: any;
    financeIntegrationCode: any;
    financeIntegrationName: any;
    financeCodeName: any;
    dimensions=[];
    constructor(list) {
        this.financeIntegrationId = list.financeIntegrationId;
        this.financeIntegrationCode = list.financeIntegrationCode;
        this.financeIntegrationName = list.financeIntegrationName;
        this.financeCodeName = list.financeCodeName;
        this.dimensions =list.dimensions ||[];
    }
}

export class TaxCodeModel {
    taxCode: any;
    taxCodeId: any;
    taxName: any;
    isDefault: boolean;
    constructor(list) {
        this.taxCode = list.taxCode;
        this.taxCodeId = list.taxCodeId;
        this.taxName = list.taxName;
        this.isDefault = list.isDefault;
    }
}

export class HsnCodeModel {
    hsnCode: any;
    hsnId: any;
    hsnName: any;
    isDefault: boolean;
    constructor(list) {
        this.hsnCode = list.hsnCode;
        this.hsnId = list.hsnId;
        this.hsnName = list.hsnName;
        this.isDefault = list.isDefault;
    }
}

export class MileageBulkItemModel {
    isSelected: boolean;
    categoryId: any;
    categoryType: any;
    categoryName: any;
    categoryCode: any;
    claimCode: any;
    claimName: any;
    exchangeRate:any;
    claimId: any;
    countryId: any;
    description: any;
    distance: any;
    expenseAttachments: any;
    expensedDate: any;
    expenseType: any;
    fromLocationName: any;
    toLocationName: any;
    location: any;
    mileageRate: any;
    mileageUnit: any;
    originalAmount: any;
    originalCurrencyCode: any;
    originalCurrencyId: any;
    originalCurrencyIcon: any;
    originalCurrencyName: any;
    expConvRate: any;
    showOptions: boolean = false;
    showStateList: boolean = false;
    loaderBoolean: boolean = false;
    bulkImgUpload: boolean = false;
    vehicalList: any;
    financeIntegrationCode: any;
    taxCode: any;
    hsnCode: any;
    status: number;
    claimStartDate: any;
    claimEndDate: any;
    checkPointsList = [];
    categoryRuleLimitAmountWarning: boolean = false;
    categoryLimitAmountWarning: boolean = false;
    categoryLimitReceiptWarning: boolean = false;
    financeIntegrationId: any;
    taxCodeId: any;
    hsnId: any;
    violationType: any = 1;
    limitType: any = 1;
    limitValue: any = 0;
    monthlyDataList: any;
    expenseId: any;
    expenseGUID: any;
    recordType: any;
    approvedDistance: any;
    customFieldList = [];
    showCustomFields= false;
    bandId: any;
    departmentId: any;
    costCenterId: any;
    projectCodeId: any;
    bandName: any;
    departmentName: any;
    costCenterName: any;
    projectCodeName: any;
    showAdditionalFields = false;
    financeCodeName: any;
    financeIntegrationName: any;
    isSelfDriveCabRequest: any;
    unformattedOriginalApproveAmount: any;
    proofOfPaymentLoader: boolean = false;
    isAutoCalculateDistanceAmount: boolean = true;
    mandateFromToLocations: boolean = false;
    invoiceNumber: any;
    isAttachmentMandatory: boolean = false;
}
export class MileageBulk {
    legalEntityId: number;
    legalEntityGUID: string;
    userId: number;
    userGUID: string;
    claimId: number;
    claimName: string;
    action: number;
    expenseRequestDTOList: any;
}

export class SendBack {
    claimId: any;
    claimGUID: any;
    approverId: any;
    approverGUID: any;
    sendEmailOnSendBack: boolean;
    description: any
}

export class NewTripModel{
    tripId:string;
    tripGUID:string;
    tripName:string;
    statusDescription:string;
    tripStartDate:any;
    tripEndDate:any;
    isSelected:boolean = false;
    isExpanded:boolean = false;
    serviceList:Array<any> = [];
    isMultiService:boolean = false;
    serviceName:string;
    tripCost:any;
    currencyIcon:any;
    advanceSummary:any;
    formattedTripCost: any;
    claimStatus:any
    budgetRefNumber: any;
    isEmptyTrip: any;
    claimTypeId: any;
    claimTypeName: any;
    constructor(list){
        this.tripId = list.tripId;
        this.tripGUID = list.tripGUID;
        this.tripName = list.tripName;
        this.statusDescription = list.statusDescription;
        this.tripStartDate = list.tripStartDate;
        this.tripEndDate = list.tripEndDate;
        this.isMultiService = list.isMultiService;
        this.serviceName = list.serviceName;
        this.tripCost = list.tripCost;
        this.currencyIcon = list.currencyIcon;
        this.formattedTripCost = list.formattedTripCost;
        this.claimStatus = list.claimStatus;
        this.budgetRefNumber = list.budgetRefNumber;
        this.isEmptyTrip = list.isEmptyTrip;
        this.claimTypeId = list.claimTypeId;
        this.claimTypeName = list.claimTypeName;
    }

}

export class MyCardModel{
    transactionId:any;
    transactionDate:any;
    accountNumber:any;
    cardNumber:any;
    description:any;
    amount:any;
    status:any;
    userId:any;
    showCheckBox:any;
    visibility:any;
    transactionReference: any;
    expensedDate: any;
    cardTrasactionId: any;
    transactionType: any;
    currencyIcon: any;
    formattedAmount: any;
    constructor(list){
        this.transactionId = list.transactionId;
        this.transactionDate = list.transactionDate;
        this.accountNumber = list.accountNumber;
        this.cardNumber = list.cardNumber;
        this.description = list.description;
        this.amount = list.amount;
        this.status = list.status;
        this.userId = list.userId;
        this.visibility = list.visibility;
        this.transactionReference = list.transactionReference;
        this.cardTrasactionId = list.id;
        this.transactionType = list.transactionType;
        this.currencyIcon = list.currencyIcon;
        this.formattedAmount = list.formattedAmount;
        this.expensedDate = list.expensedDate;
    }
}

export class advanceNewModel {
    action: any;
	claimName: any;
	claimId: any;
	claimGUID: any;
	advanceTypeId: any;
    userId: any;
    userGUID: any;
    legalEntityId: any;
    legalEntityGUID: any;
	advanceTypeName: any;
	claimTaggedTripList: Array<advanceClaimTaggedTripList> = [];
    advanceList: Array<advanceList> = [];
}

export class advanceClaimTaggedTripList {
    requestId: any;
    requestGUID: any;
    tripId: any;
    tripGUID: any;
    startDate: any;
    endDate: any;
    fromLocation: any;
    toLocation: any;
    totalAmount: any;
    paymentMode: any;
    productId: any;
    isAssociated: any;
    productName: any;
    tripServiceAttachments: any = [];
}

export class advanceList {
    expenseId: any;
    expenseGUID: any;
    originalCurrencyId: any;
    originalAmount: any;
    exchangeRate: any;
    reason: any;
    editetedExchangeRate: any;
    expenseAttachments: any = [];
    requestType: any;
}

export class MultiAdvanceModel{
    multipleAdvanceItem:Array<advanceItemModel> = [];
}
export class advanceItemModel{
    originalCurrencyCode:any;
    originalCurrencyId:any;
    originalCurrencyIcon:any;
    originalAmount: any;
    expConvRate: any;
    editedExchangeRate: any;
    beforeExchangeRate: any;
    reason: any;
    isTrip: any;
    tripId: any;
    tripName: any;
    description:any;
    fromDate:any;
    toDate: any;
    showCurrencyList:boolean = false;
    imgUpload:boolean = false;
    expenseAttachments:Array<any> = [];
    userId: number;
	userGUID: string;
	legalEntityId: number;
	legalEntityGUID:string;
	action: number;	// 1 : save, 2 : submit
	recordType: number;	// 1 : Expense, 2 : Advance Expense
    claimTaggedTripList:any;
	tripTravellersList:any;
	claimIds: any;
	expenseId:any;
	expenseGUID:any;
    claimId:any;
    claimGuid:any;
    claimGUID:any;
    claimCode:any;
	claimName:any;
	exchangeRate:any;
    categoryType:any;
    advanceTypeId: any;
    advanceTypeName: any;
    unformattedOriginalAmount: any;
    requestType: any;
}
export class claimViolationsModel {
    claimId: any;
	claimUserId: any; // on update claimUserId ? claimUserId : userId
	legalEntityId: any;
    legalEntityGUID: any;
	claimTypeId: any;
	claimEndDate: any;
	claimStartDate: any;
	parentCategoryId: any;
    isPerDiem: any;
    perDiem: any;
    expenseList: Array<vioalationExpenseList> = [];
    mileageList: Array<vioalationMileageList> = [];
    perDiemList: Array<vioalationPerdiemList> = [];
    tripList:any;
}

export class vioalationExpenseList {
    expenseId: any;
    categoryId: any;
    cityId: any;
    originalAmount: any;
    expenseEndDate: any;
    expenseStartDate: any;
    originalCurrencyId: any;
}

export class vioalationMileageList {
    expenseId: any;
    categoryId: any;
    cityId: any;
    distance: any;
    originalAmount: any;
    expenseEndDate: any;
    expenseStartDate: any;
    originalCurrencyId: any;
}

export class vioalationPerdiemList {
    expenseId: any;
    categoryId: any;
    cityId: any;
    originalAmount: any;
    expenseEndDate: any;
    expenseStartDate: any;
    originalCurrencyId: any;
}

export class CriteriModel{
    category:any;
    amount:any;
    condition:any;
    workFlow:any;
}